import { API_URL } from 'environment';
import gql from 'graphql-tag';
import client from 'client';
import { showConfirm } from './popups';
import Swal from 'swal';

const CLOSE_COMPANY = gql`
  mutation ConnectionCloseCompany {
    setCompany(companyId: 0) @client
  }
`;

function getCompanyParam(companyId) {
  if (!companyId) return '';
  return `&companyId=${companyId}`;
}

export function connectQbo(groupId, workspaceId, companyId) {
  window.location.href = `${API_URL}/qb/qbo/connect?groupId=${groupId}&workspaceId=${workspaceId}${getCompanyParam(
    companyId
  )}`;
}

export function connectGusto(groupId, workspaceId, companyId, demo) {
  window.location.href = `${API_URL}/gusto/connect?groupId=${groupId}&workspaceId=${workspaceId}${getCompanyParam(
    companyId
  )}${demo ? '&demo=true' : ''}`;
}

export function reconnectGusto(companyId) {
  window.location.href = `${API_URL}/gusto/connect?companyId=${companyId}&reconnect=true`;
}

export function connectXero(groupId, workspaceId, companyId) {
  window.location.href = `${API_URL}/xero/connect?groupId=${groupId}&workspaceId=${workspaceId}${getCompanyParam(
    companyId
  )}`;
}
export function connectAcs(groupId, workspaceId, companyId) {
  window.location.href = `${API_URL}/acs/acs/connect?groupId=${groupId}&workspaceId=${workspaceId}${getCompanyParam(
    companyId
  )}`;
  // window.location.href = `${API_URL}/acs/acs/connect?groupId=${groupId}&workspaceId=${workspaceId}`;
  // window.location.href = `https://oauth.accountingsuite.com/authorize?client_id=CI_5aoshbgbm7ftq66hz8en&state=test&redirect_uri=https://app.reachreporting.com/accounting-suite/connect/callback&response_type=code`
}

export function connectMyob(groupId, workspaceId, companyId) {
  window.location.href = `${API_URL}/myob/connect?groupId=${groupId}&workspaceId=${workspaceId}${getCompanyParam(
    companyId
  )}`;
}

export function reconnect(groupId, workspaceId, integrationId, companyId) {
  switch (integrationId) {
    case 'QB':
      return connectQbo(groupId, workspaceId);
    case 'XERO':
      return connectXero(groupId, workspaceId);
    case 'ACS':
      return connectAcs(groupId, workspaceId);
    case 'GUSTO':
      return reconnectGusto(companyId);
  }
}

export function connectionLost(groupId, workspaceId, integrationId) {
  return showConfirm({
    title: 'Connection Lost',
    text: 'Would you like to restore the connection to your accounting software?',
    icon: 'info',
    confirmButtonText: 'Yes',
    cancelButtonText: 'Back',
    showCancelButton: true,
    confirmButtonColor: '#2fbfa0',
    cancelButtonColor: '#ff5c75',
    allowOutsideClick: false,
  }).then((result) => {
    if (result.value) {
      return reconnect(groupId, workspaceId, integrationId);
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      window.sessionStorage.removeItem('currentCompanyId');
      return client.mutate({ mutation: CLOSE_COMPANY });
    }
  });
}
