/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes in binary (base 2).
 * @param precision Number of decimal places to display.
 *
 * @return Formatted string in decimal (SI) units.
 */
const fileSize = (bytes: number, precision = 1): string => {
  const binaryUnit = 1024;

  if (Math.abs(bytes) < binaryUnit) {
    return bytes + ' B';
  }

  const siUnit = 1000;
  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let u = -1;
  const r = 10 ** precision;

  do {
    bytes /= binaryUnit;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= siUnit &&
    u < units.length - 1
  );

  const formatted = bytes.toFixed(precision);
  const rounded = Math.round(bytes * r) / r;

  return (
    (rounded === parseFloat(formatted) ? rounded : formatted) + ' ' + units[u]
  );
};

export default fileSize;
