import styled from 'styled-components';

import Avatar from '@material-ui/core/Avatar';

import Button from 'components/Button';

import theme from 'styled-theming';
import PercentInput from 'components/PercentInput';
import Checkbox from 'components/Checkbox';
import ToggleBase from 'react-toggle';

import media from 'media';

const containerBackground = theme('mode', {
  dark: '#222532',
  light: 'white',
});

const tableHeaderBackground = theme('mode', {
  light: '#EAEBEF',
  dark: '#494d5b',
});

const backgroundColor2 = theme('mode', {
  light: '#EAEBEF',
  dark: '#494D5B',
});

const colorFont1 = theme('mode', {
  light: '#181922',
  dark: '#fcfdff',
});

const rowHoverColor = theme('mode', {
  light: '#f4f4f7',
  dark: '#35394a',
});

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  width: 30%;
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  justify-content: flex-start;
  width: 100%;
`;

export const Label = styled.div`
  margin-right: 10px;
`;

export const ParentDiv = styled.div`
  justify-content: space-around;
  width: 100%;

  background-color: ${containerBackground};
  padding-top: ${(props) => props.theme.workspaceHeader.height};
`;

export const AvatarCustom = styled(Avatar)`
  height: 30px !important;
  width: 30px !important;
  font-size: medium !important;
  padding-left: 1px !important;
  margin-right: 3px !important;
  background-color: ${backgroundColor2} !important;
  color: ${colorFont1} !important;
`;

export const GroupItem1 = styled.div`
  height: 29px;
`;

export const GroupItem3 = styled.div`
  height: 29px;
  padding-right: 10px;
  padding-left: 20px;
  padding-top: 2px;
  cursor: pointer;
`;

export const SubTitle = styled.div`
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
`;

export const InputGroupName = styled.div`
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  width: 80%;
`;

export const LogoHolder = styled.div`
  border-radius: 5px;
  border: 1px solid gray;
  width: 150px;
  position: relative;
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const FolderIconComponent = styled.div`
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
  cursor: pointer;
`;

export const Title = styled.div`
  font-size: 15px;
  font-weight: bold;
`;

export const GroupContainerTitle = styled.div`
  height: 41px;
  background-color: #222532;
  font-size: 15px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 42px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
`;

export const CustomInput = styled.input`
  border: none;
  color: ${colorFont1};
  background: ${backgroundColor2};
  border-top-right-radius: 0px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 0px;
  margin-left: 10px;
  padding-left: 10px;
  height: 30px;
  font-size: 13px;
`;

export const TeamAccessButtonC = styled(Button)`
  background-color: ${backgroundColor2};
  height: 30;
  min-width: 122;
  color: ${colorFont1} !important;
  white-space: nowrap;
  &:hover {
    color: white !important;
  }
`;
export const CustomInputIcon = styled.button`
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  color: ${colorFont1};
  background: ${backgroundColor2} !important;
  border-top-right-radius: 15px;
  height: 30px;
  padding-left: 10px;
  padding-top: 3px;
  padding-right: 10px;
  border-bottom-right-radius: 15px;
`;

export const ActionButtonsContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  width: 100%;
`;
export const DivRow = styled.div`
  display: inline-flex;
  justify-content: space-between;
`;

export const DivFull = styled.div`
  display: inline-flex;
  ${({ column }) => column && 'flex-direction: column;'}
  justify-content: ${({ justify }) => justify || 'space-between'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  width: 100%;
  min-height: 35px;
  font-size: 12px;
  div {
    display: flex;
  }
  padding-top: ${({ paddingTop }) => paddingTop || '0'};
  background-color: ${({ background }) => background && tableHeaderBackground};
`;

export const CreateSettings = styled(DivFull)`
  padding-left: 0.5rem;
  justify-content: flex-start;
`;

export const DivItem = styled.div`
  display: inline-flex;
  justify-content: ${({ justify }) => justify || 'space-between'};
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 1rem;
`;

export const GroupNameTitle = styled.div`
  font-size: 1rem;
  margin-right: 1rem;
  flex-shrink: 0;
`;

export const PrimaryButtonStyle = styled(Button)`
  margin-left: 0.25rem;
`;

export const SecondaryButtonStyle = styled(Button)`
  margin-left: 10px;
`;

export const TableStyle = styled.div`
  padding: 1rem 0 0 0;
  max-height: calc(100vh - ${({ hasFooter }) => (hasFooter ? 155 : 68)}px);
  min-height: 50vh;
  overflow-y: auto;

  table {
    font-size: 12px;
    border-spacing: 0;
    table-layout: fixed;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    /* ${media.tablet`
      td:first-child,
      th:first-child {
        width: 2rem;
        padding-left: 1rem;
      }
    `} */

    thead {
      background-color: ${tableHeaderBackground};
    }

    th,
    td {
      padding: 0.5rem;
      white-space: nowrap;
      :last-child {
        border-right: 0;
      }
    }

    tbody tr:hover {
      background-color: ${rowHoverColor};
    }
  }
`;

export const ExpandedTableRow = styled.tr`
  background-color: ${rowHoverColor};
`;

export const ExpandedStyleForGroupName = styled.div`
  color: ${colorFont1};
  font-size: 18px;
  font-weight: bold;
`;

export const CustomSelectClass = styled.select`
  font-size: 12px;
  color: ${colorFont1};
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  & option {
    color: #333;
  }
`;

export const DataPickerContainer = styled.div`
  margin-top: 10px;
`;

export const GrowthRateInput = styled(PercentInput)`
  && input {
    width: 60px;
  }
`;

export const ExcelIcon = styled.i.attrs({
  className: 'mdi-set mdi-file-excel',
})`
  font-size: 18px;
  vertical-align: middle;
  height: 29px;
  margin-top: -5px;
`;

export const AlertIcon = styled.i.attrs({
  className: 'mdi-set mdi-alert',
})`
  color: ${({ theme }) => theme.colors.warning} !important;
  margin-left: 0.25rem;
`;

export const HiddenFileInput = styled.input.attrs({
  type: 'file',
})`
  display: none;
`;

export const FileInputLabel = styled.label`
  font-size: inherit;
`;

export const FileInput = styled.input.attrs({
  type: 'file',
  accept: '.xlsx',
})`
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 0.775rem;
  display: block;
`;

export const ErrorWrapper = styled.div`
  max-height: 10rem;
  overflow-y: auto;
`;

export const ErrorDiv = styled.div`
  color: ${(props) => props.theme.colors.danger};
  font-size: 0.75rem;
`;

export const MissingLabel = styled.div`
  font-size: 0.75rem;
`;

export const Bottom = styled.div`
  border-top: 1px solid #dddddd;
  margin-top: 1rem;
  padding-top: 1rem;
  text-align: right;
`;

export const CreateButton = styled(Button)`
  margin-left: 4px;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-bottom: unset;
`;

export const InfoText = styled.span`
  font-size: 0.9rem;
`;

export const Toggle = styled(ToggleBase)`
  & .react-toggle-track {
    background-color: #4d4d4d;
  }
  &.react-toggle--checked .react-toggle-track {
    background: ${({ theme: _theme }) => _theme.colors.success};

    .react-toggle-track-check {
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        color: white !important;
        font-size: 1.1rem;
      }
    }
  }
  &.react-toggle--checked:hover:not(.react-toggle--disabled)
    .react-toggle-track {
    background: #000000;
  }
  &.react-toggle {
    transform: scale(0.65);
  }
`;

export const CenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
