import styled from 'styled-components';
import { Bar } from '../styledComponents';

export const DefaultLogo = styled.div<{
  showFull?: boolean;
  fullWidth?: number;
}>`
  ${({ onClick }) => !!onClick && 'cursor: pointer;'}
  height: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  transition: opacity 0.15s ease-out;

  img:first-child {
    margin-right: 10px;
    height: 100%;
  }

  img:last-child {
    opacity: ${({ showFull }) => (showFull ? 1 : 0)};
    width: ${({ fullWidth }) => (fullWidth ? `${fullWidth}px` : '80%')};
  }

  ${Bar}:hover & > img:last-child {
    opacity: 1;
  }

  overflow: hidden;
`;

export const WhiteLabelLogo = styled.div<{
  minimal?: boolean;
  showFull?: boolean;
  fullWidth?: number;
}>`
  ${({ onClick }) => !!onClick && 'cursor: pointer;'}
  display: flex;
  align-items: center;
  flex-direction: row;
  transition: opacity 0.15s ease-out;
  box-sizing: border-box;
  height: ${({ theme }) => theme.header.height};

  img {
    max-height: 100%;
  }

  img:first-child {
    opacity: ${({ showFull }) => (showFull ? 0 : 1)};
    margin-right: ${({ showFull }) => (showFull ? '0px' : '10px')};
    height: 100%;
    width: ${({ showFull }) => (showFull ? '0%' : 'auto')};
  }

  img:last-child {
    object-fit: contain;
    opacity: ${({ showFull }) => (showFull ? 1 : 0)};
    ${({ fullWidth }) => fullWidth && `width: ${fullWidth}px;`}
    pointer-events: none;
  }

  ${Bar}:hover & > img:first-child {
    opacity: 0;
    width: 0%;
    margin-right: 0px;
  }

  ${Bar}:hover & > img:last-child {
    opacity: 1;
  }
`;
