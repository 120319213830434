import React from 'react';
import styled, { css } from 'styled-components';
import { Portal } from 'react-portal';

const Wrapper = styled.div<{ independent: boolean }>`
  width: 250px;
  height: 50px;
  ${({ independent }) =>
    !independent &&
    css`
      position: fixed;
      top: calc(50% - 25px);
      left: calc(50% - 25px);
      text-align: center;
    `}
`;

const LoaderDiv = styled.div`
  width: 50px;
  height: 50px;
  margin: 0 auto;
`;

const Loader = styled.div<{ color?: string }>`
  & > div {
    background-color: ${({ color }) => color || '#bbb'};
  }
`;

const LoadingIndicator: React.FC<{
  error?: string;
  independent?: boolean;
  color?: string;
}> = ({ children, error, independent = false, color }) => {
  if (error) {
    console.log(error);
  }

  if (independent) {
    return (
      <Wrapper independent={independent}>
        <LoaderDiv className="loader">
          <Loader color={color} className="loader-inner line-scale">
            <div />
            <div />
            <div />
            <div />
            <div />
          </Loader>
        </LoaderDiv>
        {children}
      </Wrapper>
    );
  } else {
    return (
      <Portal>
        <Wrapper independent={independent} color={color}>
          <LoaderDiv className="loader">
            <Loader className="loader-inner line-scale">
              <div />
              <div />
              <div />
              <div />
              <div />
            </Loader>
          </LoaderDiv>
          {children}
        </Wrapper>
      </Portal>
    );
  }
};

export default LoadingIndicator;
