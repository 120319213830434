import React from 'react';
import SelectBase from 'components/Select';
import { FilterTypes } from '.';

interface Props {
  handleTypeChange: (type: FilterTypes) => void;
  type?: FilterTypes;
  disabled?: boolean;
}

const Select = ({
  handleTypeChange,
  type,
  disabled,
}: Props): React.ReactElement => {
  return (
    <SelectBase
      options={[
        {
          label: 'Classes',
          value: 'CLASSES',
        },
        {
          label: 'Departments',
          value: 'DEPARTMENTS',
        },
      ]}
      onChange={handleTypeChange}
      value={type}
      portal
      isDisabled={disabled}
    />
  );
};

export default Select;
