/**
 *
 * Login
 *
 */

import React from 'react';
import styled from 'styled-components';

import AuthenticationPanel from 'components/AuthenticationPanel';
import Link from 'components/Link';

import Register from 'routes/Register/Loadable';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Div = styled.div`
  width: 90%;
  max-width: 390px;
`;

const RegisterText = styled.p`
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
`;

function IntuitEmailNotVerified() {
  return (
    <Container>
      <Div>
        <AuthenticationPanel title="Email not confirmed with Intuit">
          <p>
            You cannot sign in with Intuit because your email has not been
            confirmed with your Intuit account.
          </p>
          <p>
            To finish verifying your email with Intuit, please first visit{' '}
            <a
              href="https://accounts.intuit.com/app/account-manager/security"
              target="__blank"
            >
              https://accounts.intuit.com/app/account-manager/security
            </a>
          </p>
        </AuthenticationPanel>
      </Div>

      <RegisterText>
        <Link to="/login" preload={Register}>
          Back to login
        </Link>
      </RegisterText>
    </Container>
  );
}

export default IntuitEmailNotVerified;
