/**
 *
 * IconButton
 *
 */
import React from 'react';
import styled from 'styled-components';
import theme from 'styled-theming';
import { Link } from 'react-router-dom';

const linkColor = theme('mode', {
  light: '#617181',
  dark: '#d7d7d8',
});

const Button = styled.button`
  display: block;
  font-size: 14px;
  padding: 0;
  margin-bottom: 0.5rem;
  color: ${linkColor};
  font-weight: bold;
  i {
    margin-right: 0.5rem;
    color: ${linkColor};
  }
  &:hover {
    color: #40a9ff;
  }
`;

interface IconButtonProps {
  iconClass: string;
  text: string;
  onClick?: () => void;
  href?: string;
  target?: string;
  id?: string;
  to?: string;
}

const IconButton: React.FunctionComponent<IconButtonProps> = ({
  onClick,
  iconClass,
  text,
  href,
  target,
  id,
  to,
}) => {
  if (to) {
    return (
      <Button as={Link} to={to} id={id}>
        <i className={iconClass} />
        {text}
      </Button>
    );
  }

  if (href) {
    return (
      <Button onClick={onClick} href={href} target={target} id={id} as="a">
        <i className={iconClass} />
        {text}
      </Button>
    );
  }

  return (
    <Button onClick={onClick} id={id}>
      <i className={iconClass} />
      {text}
    </Button>
  );
};

export default IconButton;
