import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useMemo } from 'react';
import useCompanyId from './useCompanyId';

const QUERY = gql`
  query XeroNeedsReauth($companyId: Int!) {
    company(id: $companyId) {
      id
      accounting {
        xeroNeedsReauth
      }
    }
  }
`;

export default function useXeroNeedsReauth() {
  const companyId = useCompanyId();

  const { data } = useQuery(QUERY, {
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  return useMemo(() => {
    return (
      data &&
      data.company &&
      data.company.accounting &&
      data.company.accounting.xeroNeedsReauth
    );
  }, [data]);
}
