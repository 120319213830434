/**
 *
 * GoogleSheetErrors
 *
 */
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { CREATE_GOOGLE_SHEET } from '../mutations';
import {
  BottomGoogleSheet,
  Scroll,
  Table,
  Tr,
} from '../common-styled-components';
import { TableStyle } from '../styledComponents';
import { ConfirmButton } from './styled-components';
import { useMutation } from '@apollo/client';
import useCreateGoogleSheet from './useCreateGoogleSheet';
import { captureException } from 'utils/sentry';
import useSyncGoogleSheet from './useSyncGoogleSheet';

const Th = styled.th`
  color: ${(props) =>
    props.missing ? props.theme.colors.danger : props.theme.body.color};
`;

const ErrorTd = styled.td`
  color: ${(props) => props.theme.colors.danger};
  font-weight: 700;
`;

function Header({ name, missingColumns }) {
  const missing = missingColumns && missingColumns.includes(name);

  return (
    <Th missing={missing}>
      {name} {missing ? '(Missing)' : null}
    </Th>
  );
}

function ErrorCell({ name, errors, actualData }) {
  const columnError = errors.find((item) => item.column === name);

  if (!columnError) {
    return <td>{actualData[name] === undefined ? '' : actualData[name]}</td>;
  }
  return (
    <ErrorTd>
      {`${columnError.actual || ''} (${columnError.message || ''})`}
    </ErrorTd>
  );
}

const GoogleSheetErrors = (props) => {
  const {
    dispatch,
    googleSpreadsheetErrors: {
      errors: { missingColumns, rowErrors },
      fileId,
      googleAccountId,
      googleSpreadsheetId,
      sync,
      email,
    },
  } = props;

  const { onCreate, loading: createLoading } = useCreateGoogleSheet();
  const { onSync, loading: syncLoading } = useSyncGoogleSheet({ dispatch });

  const handleSkip = useCallback(async () => {
    try {
      if (sync) {
        await onSync(googleSpreadsheetId, true);
        dispatch({
          type: 'DATASHEET_LIST',
        });
      } else {
        await onCreate({
          fileId,
          googleAccountId,
          email,
          dispatch,
          ignoreErrors: true,
        });
      }
    } catch (err) {
      captureException(err, true);
    }
  }, [
    dispatch,
    email,
    fileId,
    googleAccountId,
    googleSpreadsheetId,
    onCreate,
    onSync,
    sync,
  ]);

  const loading = createLoading || syncLoading;

  let duplicateError = false;

  if (rowErrors && rowErrors.length > 0) {
    duplicateError = rowErrors.find((rowError) => {
      return rowError.errors.find((error) => {
        return error.message === 'duplicate';
      });
    });
  }

  return (
    <div>
      <Scroll>
        <TableStyle hasFooter={true}>
          <Table>
            <thead>
              <Tr>
                <th>Line</th>
                <Header name="Id" missingColumns={missingColumns} />
                <Header name="Name" missingColumns={missingColumns} />
              </Tr>
            </thead>
            <tbody>
              {rowErrors &&
                rowErrors.map((rowError, i) => (
                  <Tr key={i}>
                    <td>{rowError.line}</td>
                    <ErrorCell
                      name="Id"
                      errors={rowError.errors}
                      actualData={rowError.actualData}
                    />
                    <ErrorCell
                      name="Name"
                      errors={rowError.errors}
                      actualData={rowError.actualData}
                    />
                  </Tr>
                ))}
            </tbody>
            <tfoot></tfoot>
          </Table>
        </TableStyle>
      </Scroll>
      <BottomGoogleSheet>
        {(!missingColumns || missingColumns.length === 0) &&
          !duplicateError && (
            <div>
              <ConfirmButton
                onClick={handleSkip}
                disabled={loading}
                loading={loading}
              >
                Ignore Errors
              </ConfirmButton>
            </div>
          )}
        {missingColumns && missingColumns.length !== 0 && (
          <div style={{ fontSize: '10pt' }}>
            Missing ID/Name Errors Must Be Resolved
          </div>
        )}
        {duplicateError && (
          <div style={{ fontSize: '10pt' }}>
            Duplicate Errors Must Be Resolved
          </div>
        )}
      </BottomGoogleSheet>
    </div>
  );
};

export default GoogleSheetErrors;
