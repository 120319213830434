import React, { ReactElement } from 'react';

const ReportsIcon = ({
  color = '#f9fafc',
  width = '100%',
  height = '100%',
  cursor,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  cursor?: string;
  onClick?: (any) => void;
}): ReactElement => {
  return (
    <>
      <svg
        {...props}
        width={width}
        height={height}
        cursor={cursor}
        viewBox="3 1 18 21"
      >
        <path
          fill={color}
          d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M7,20H9V14H7V20M11,20H13V12H11V20M15,20H17V16H15V20Z"
        />
      </svg>
    </>
  );
};

export default ReportsIcon;
