/**
 *
 * Help
 *
 */

import React, { useMemo } from 'react';
import Tooltip from 'components/Tooltip';
import styled from 'styled-components';

const I = styled.i`
  font-size: ${({ scale }) => scale * 16}px;
`;
function Help(props) {
  const { children, information, scale = 1, iconClass, style, ...rest } = props;

  const className = useMemo(() => {
    if (iconClass) {
      return iconClass;
    }
    return information
      ? 'mdi-set mdi-information-outline'
      : `zmdi zmdi-help-outline zmdi-hc-fw`;
  }, [iconClass, information]);

  return (
    <Tooltip content={children} {...rest}>
      <I className={className} scale={scale} style={style} />
    </Tooltip>
  );
}

export default Help;
