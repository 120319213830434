import React, { useCallback, useMemo } from 'react';

import Modal, { ModalButton, ModalProps } from 'components/Modal';
import TwoFactorAuthForm from 'components/TwoFactorAuthForm';
import useWorkspaceId from 'hooks/useWorkspaceId';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

const SWITCH_WORKSPACE = gql`
  mutation SwitchWorkspace {
    setWorkspace(workspaceId: 0) @client
    setCompany(companyId: 0) @client
  }
`;

interface Props extends Omit<ModalProps, 'children'> {
  preface?: string;
  workspaceControls?: boolean;
}

const TwoFactorAuthModal = ({
  show,
  preface,
  workspaceControls,
}: Props): React.ReactElement => {
  const history = useHistory();
  const workspaceId = useWorkspaceId();
  const [switchWorkspace, { loading }] = useMutation(SWITCH_WORKSPACE);

  const handleSwitch = useCallback(() => {
    switchWorkspace();
    history.push('/workspaces');
  }, [switchWorkspace, history]);

  const buttons = useMemo(() => {
    const _buttons: ModalButton[] = [];
    if (workspaceControls && workspaceId) {
      _buttons.push({
        text: 'Switch Workspace',
        color: 'secondary',
        outline: true,
        onClick: handleSwitch,
        disabled: loading,
        loading,
      });
    } else if (!workspaceId) {
      _buttons.push({
        text: 'Go Back',
        color: 'secondary',
        outline: true,
        onClick: () => history.goBack(),
      });
    }
    return _buttons;
  }, [handleSwitch, history, loading, workspaceControls, workspaceId]);

  return (
    <Modal
      show={show}
      width={500}
      title="Two Factor Authentication Required"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      buttons={buttons}
    >
      <TwoFactorAuthForm preface={preface} />
    </Modal>
  );
};

export default TwoFactorAuthModal;
