/**
 *
 * LoginModal
 *
 */
import { gql, useApolloClient, useReactiveVar } from '@apollo/client';
import { logout } from 'auth';
import LoginForm from 'components/LoginForm';
import Modal from 'components/Modal';
import OauthButtons from 'components/OauthButtons';
import { showLoginModal } from 'graphql/variables';
import useMaintenanceMode from 'hooks/useMaintenanceMode';
import usePrevious from 'hooks/usePrevious';
import useWhitelabel from 'hooks/useWhitelabel';
import React, { useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { loginModalZIndex } from 'utils/zIndex';

const USER_EMAIL = gql`
  query UserEmail {
    currentUser {
      email
    }
  }
`;

const Bottom = styled.div`
  margin-top: 1.5rem;
  text-align: center;
  font-size: 0.75rem;
`;

const Button = styled.button`
  color: ${(props) => props.theme.colors.link};
`;

const LoginModal = (props) => {
  const { isLoggedIn } = props;
  const { down, loading: maintenanceLoading } = useMaintenanceMode();
  const { whiteLabel } = useWhitelabel();
  const show = useReactiveVar(showLoginModal);
  const client = useApolloClient();
  const prevShow = usePrevious(show);

  const email = useMemo(() => {
    if (!isLoggedIn) return '';
    if (show && !prevShow) {
      try {
        const data = client.cache.readQuery({
          query: USER_EMAIL,
        });

        return data.currentUser.email;
      } catch {
        return null;
      }
    }
  }, [client.cache, isLoggedIn, prevShow, show]);

  const handleOauthFinished = useCallback((event) => {
    if (event.data && event.data.success) {
      showLoginModal(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleOauthFinished);

    return () => window.removeEventListener('message', handleOauthFinished);
  }, [handleOauthFinished]);

  const handleClose = useCallback(() => {
    logout().then(() => {
      window.location.pathname = '/';
    });
  }, []);

  if (!isLoggedIn || maintenanceLoading || down) return null;

  return (
    <Modal show={show} hideHeader title="Login" zIndex={loginModalZIndex}>
      <p>Your session has timed out. Please log in to continue.</p>
      <LoginForm
        email={email}
        onSuccess={() => showLoginModal(false)}
        hideForgotLink
      />
      {!whiteLabel && <OauthButtons />}
      <Bottom>
        Not you? <Button onClick={handleClose}>Click here to log in.</Button>
      </Bottom>
    </Modal>
  );
};

export default LoginModal;
