import React, { useMemo } from 'react';
import {
  AvatarCustom,
  CustomInput,
  CustomInputIcon,
  DivRow,
  TableStyle,
  TeamAccessButtonC,
  ExpandedTableRow,
  ExpandedStyleForGroupName,
} from './styledComponents';

import AccountingBasis from './AccountingBasis';

import SearchIcon from '@material-ui/icons/Search';
import { useTable, useExpanded } from 'react-table';

import GroupCompanyListItem from '../GroupCompanyListItem';

import { FaAngleRight, FaAngleDown } from 'react-icons/fa';

export const AvatarLetter = ({ firstName, lastName }) => (
  <AvatarCustom>
    {' '}
    {(firstName || '')[0] || ''}
    {(lastName || '')[1] || ''}{' '}
  </AvatarCustom>
);

export const TeamAccessButton = ({ onClick, content = 'Team Access' }) => {
  return <TeamAccessButtonC onClick={onClick}>{content}</TeamAccessButtonC>;
};

export const SearchBar = ({ onChange, value, minimal }) => {
  return (
    <DivRow minimal={minimal}>
      <CustomInput
        onChange={onChange}
        value={value}
        minimal={minimal}
        autoComplete="off" data-1p-ignore
      />
      <CustomInputIcon aria-label="search">
        <SearchIcon style={{ width: '0.8em' }} />
      </CustomInputIcon>
    </DivRow>
  );
};

export const ZoomSection = () => <h4>ZoomSection</h4>;

const TdCell = ({ row, cell, j }) => {
  let cellValue = cell.render('Cell'); // row.isExpanded && cell.column.id !== 'expander' cell.render('Cell');

  if (cell.column.id === 'companyName') {
    if (row.isExpanded) {
      cellValue = (
        <ExpandedStyleForGroupName>
          {' '}
          {cell.render('Cell')}
        </ExpandedStyleForGroupName>
      );
    }
  } else if (cell.column.id === 'accountingBasis') {
    if (row.original.hasAccountingBasis) {
      cellValue = (
        <AccountingBasis
          companyId={row.original.id}
          accountingBasis={cell.value}
        />
      );
    } else {
      cellValue = null;
    }
  }

  return (
    <td key={j} {...cell.getCellProps()}>
      {cellValue}
    </td>
  );
};

const Table = ({
  dataColumns,
  data,
  renderRowSubComponent,
  items,
  groupId,
  phone,
  expanded,
}) => {
  const toExpandColumn = useMemo(
    () => ({
      // Make an expander cell
      Header: () => null, // No header
      id: 'expander', // It needs an ID
      // eslint-disable-next-line react/display-name
      Cell: ({ row }) =>
        // Use Cell to render an expander for each row.
        // We can use the getToggleRowExpandedProps prop-getter
        // to build the expander.
        phone ? (
          <></>
        ) : (
          <span>{row.isExpanded ? <FaAngleDown /> : <FaAngleRight />}</span>
        ),
    }),
    []
  );

  const _columns = useMemo(
    () => [toExpandColumn, ...dataColumns],
    [dataColumns, toExpandColumn]
  );

  const {
    getTableProps,
    getTableBodyProps,
    columns,
    rows,
    prepareRow,
    toggleRowExpanded,
    // visibleColumns
  } = useTable(
    {
      columns: _columns,
      data,
      autoResetExpanded: false,
      initialState: {
        expanded,
      },
    },
    useExpanded
  );

  const handleOnSelect = () => {
    // set the state for the selected;
  };
  return (
    <table {...getTableProps()}>
      <colgroup>
        <col span={1} style={{ width: '25px' }} />
        {phone && (
          <>
            <col span={1} />
            <col span={1} style={{ width: '8rem' }} />
          </>
        )}
      </colgroup>
      <thead>
        <tr>
          {columns.map((column, j) => (
            <th key={j} {...column.getHeaderProps()}>
              {column.render('Header')}
            </th>
          ))}
        </tr>
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <React.Fragment key={i}>
              <tr
                {...row.getRowProps()}
                {...(phone ? {} : row.getToggleRowExpandedProps())}
                className="company-table-row"
              >
                {row.cells.map((cell, j) => {
                  return (
                    <TdCell
                      key={j}
                      row={row}
                      cell={cell}
                      j={j}
                      handleOnSelect={handleOnSelect}
                      items={items}
                    />
                  );
                })}
              </tr>
              {!phone && row.isExpanded && (
                <ExpandedTableRow>
                  {renderRowSubComponent({ row, groupId, toggleRowExpanded })}
                </ExpandedTableRow>
              )}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

export const ReactTable = ({
  data,
  dataColumns,
  items,
  workspaceId,
  groupId,
  tablet,
  phone,
  expanded,
}) => {
  const renderRowSubComponent = React.useCallback(
    ({ row, groupId: _groupId, toggleRowExpanded }) => (
      <GroupCompanyListItem
        row={row}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        handleOnAction={() => {}}
        workspaceId={workspaceId}
        groupId={_groupId}
        toggleRowExpanded={toggleRowExpanded}
        tablet={tablet}
        phone={phone}
      />
    ),
    [phone, tablet, workspaceId]
  );

  return (
    <TableStyle>
      <Table
        data={data}
        workspaceId={workspaceId}
        groupId={groupId}
        dataColumns={dataColumns}
        items={items}
        expanded={expanded}
        renderRowSubComponent={renderRowSubComponent}
        phone={phone}
      ></Table>
    </TableStyle>
  );
};
