/**
 *
 * GroupUserModal
 *
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import gql from 'graphql-tag';

import UserListModal from 'components/UserListModal';
import { useQuery, useMutation } from '@apollo/client';
import produce from 'immer';
import { captureException } from 'utils/sentry';
import {
  BULK_UPDATE_INVITES,
  getWorkspaceUserQuery,
  INVITE_USER,
  REMOVE_INVITE,
  RESEND_WORKSPACE_INVITE,
} from 'queries/workspaceUsers';
import { Workspace, WorkspaceInvite } from 'interfaces/workspace';
import { CurrentUser, User } from 'interfaces/user';
import {
  removeCachedInvite,
  updateCachedInvitedUsers,
} from 'cache/workspaceUsers';
import { showError, showSuccess, notificationToast } from 'utils/popups';

const GROUP = gql`
  query GroupUserModal($groupId: Int!) {
    group(id: $groupId) {
      id
      name
      owner {
        id
      }
      managers {
        id
        fullName
        email
      }
    }
  }
`;

const COMPANY_QUERY = gql`
  query GroupUserModalCompany($companyId: Int!) {
    company(id: $companyId) {
      id
      users {
        id
        fullName
        email
      }
    }
  }
`;

const BULK_UPDATE_GROUP = gql`
  mutation SetBulkGroupAccess(
    $groupId: Int!
    $userAccessList: [UserHasAccess!]!
  ) {
    bulkSetWorkspaceGroupAccess(
      groupId: $groupId
      userAccessList: $userAccessList
    )
  }
`;
const BULK_UPDATE_COMPANY = gql`
  mutation SetBulkCompanyAccess(
    $companyId: Int!
    $userAccessList: [UserHasAccess!]!
  ) {
    bulkSetUserCompanyAccess(
      companyId: $companyId
      userAccessList: $userAccessList
    )
  }
`;

type NewClient = {
  firstName: string;
  lastName: string;
  email: string;
};

type Invite = {
  newClient: NewClient;
  selectedFolders?: number[];
};

interface GroupUserModalProps {
  show: boolean;
  groupId: number;
  workspaceId: number;
  companyId: number;
  onClose: () => void;
  handleCloseGroupUserModal: () => void;
}

const defaultSelected = [];

const WORKSPACE_QUERY = getWorkspaceUserQuery(false, false, true);

const GroupUserModal: React.FunctionComponent<GroupUserModalProps> = (
  props
) => {
  const {
    show,
    groupId,
    workspaceId,
    companyId,
    onClose,
    handleCloseGroupUserModal,
  } = props;

  const [error, setError] = useState<string | null>(null);

  const { data: workspaceData, loading: workspaceLoading } = useQuery<
    { workspace: Workspace; currentUser: CurrentUser },
    { workspaceId: number; groupId?: number; companyId?: number }
  >(WORKSPACE_QUERY, {
    variables: {
      workspaceId,
      groupId,
      companyId,
    },
    skip: !show,
  });
  const { data: groupData, loading: groupLoading } = useQuery(GROUP, {
    variables: {
      groupId,
    },
    skip: !show || !groupId,
  });

  const { data: companyData, loading: companyLoading } = useQuery(
    COMPANY_QUERY,
    {
      variables: {
        companyId,
      },
      skip: !show || !companyId,
    }
  );

  const allUsers = useMemo(
    () =>
      (workspaceData &&
        workspaceData.workspace &&
        workspaceData.workspace.users) ||
      ([] as User[]),
    [workspaceData]
  );

  const currentUserId =
    workspaceData &&
    workspaceData.currentUser &&
    workspaceData.currentUser.userId;

  const ownerId =
    groupData &&
    groupData.group &&
    groupData.group.owner &&
    groupData.group.owner.id;

  const users = useMemo(() => {
    if (allUsers) {
      return allUsers.filter((user) => user.id !== ownerId);
    }
    return null;
  }, [allUsers, ownerId]);

  const invites = useMemo(() => {
    if (workspaceData) {
      return workspaceData.workspace.workspaceInvites;
    }
    return null;
  }, [workspaceData]);

  const selectedUserIds = useMemo(() => {
    if (companyId) {
      return (
        companyData &&
        companyData.company &&
        companyData.company.users.map((item) => item.id)
      );
    } else {
      return (
        groupData &&
        groupData.group &&
        groupData.group.managers.map((item) => item.id)
      );
    }
  }, [companyData, companyId, groupData]);

  const [selectedIdsState, setSelectedIds] =
    useState<number[]>(defaultSelected);
  const [selectedInviteIds, setSelectedInviteIds] =
    useState<number[]>(defaultSelected);

  const handleSelect = useCallback(
    (itemId: number, checked: boolean, type: 'invite' | 'user') => {
      if (checked) {
        type === 'user'
          ? setSelectedIds((prev) => [...prev, itemId])
          : setSelectedInviteIds((prev) => [...prev, itemId]);
      } else {
        type === 'user'
          ? setSelectedIds((prev) => prev.filter((id) => id !== itemId))
          : setSelectedInviteIds((prev) => prev.filter((id) => id !== itemId));
      }
    },
    []
  );

  const defaultSelectedInvites = useMemo(() => {
    if (invites) {
      return invites.reduce<WorkspaceInvite[]>((inviteIds, currentInvite) => {
        if (groupId !== undefined && companyId === undefined) {
          if (
            currentInvite.groupIds &&
            currentInvite.groupIds.includes(groupId)
          ) {
            inviteIds.push(currentInvite);
          }
        } else if (groupId === undefined && companyId !== undefined) {
          if (
            currentInvite.companyIds &&
            currentInvite.companyIds.includes(companyId)
          ) {
            inviteIds.push(currentInvite);
          }
        }

        return inviteIds;
      }, []);
    } else {
      return [];
    }
  }, [companyId, groupId, invites]);

  useEffect(() => {
    if (companyId) {
      setSelectedIds(
        (companyData &&
          companyData.company &&
          companyData.company.users.map((item) => item.id)) ||
          defaultSelected
      );
    } else {
      setSelectedIds(
        (groupData &&
          groupData.group &&
          groupData.group.managers.map((item) => item.id)) ||
          defaultSelected
      );
    }
    setSelectedInviteIds(defaultSelectedInvites.map((invite) => invite.id));
  }, [companyData, companyId, defaultSelectedInvites, groupData, groupId]);

  const canAccessWorkspaceSettings =
    workspaceData &&
    workspaceData.workspace &&
    workspaceData.workspace.canAccessWorkspaceSettings;
  const restrictRemoveGroupAccess =
    workspaceData &&
    workspaceData.workspace &&
    workspaceData.workspace.restrictRemoveGroupAccess;

  const [inviteUser, { loading: loadingInvite }] = useMutation<
    {
      inviteWorkspaceUser: { error: string; workspaceInvite: WorkspaceInvite };
    },
    {
      workspaceId: number;
      email: string;
      firstName: string;
      lastName: string;
      groupId: number;
      companyId: number;
    }
  >(INVITE_USER);

  const [resendInvite] = useMutation<
    {
      resendWorkspaceInvite: { error: string; success: boolean };
    },
    {
      workspaceId: number;
      userId: number;
    }
  >(RESEND_WORKSPACE_INVITE);

  const [removeInvite] = useMutation<
    {
      __typename: 'Mutation';
      removeWorkspaceInvite: boolean;
    },
    { inviteId: number }
  >(REMOVE_INVITE);

  const [setBulkGroupAccess, { loading: loadingBulkGroupAccess }] =
    useMutation(BULK_UPDATE_GROUP);
  const [setBulkCompanyAccess] = useMutation(BULK_UPDATE_COMPANY);
  const [bulkSetInviteAccess, { loading: loadingBulkInviteAccess }] =
    useMutation<
      {
        bulkSetWorkspaceInviteAccess: {
          success: boolean;
          workspaceInvites?: WorkspaceInvite[];
          error?: string;
        };
      },
      {
        workspaceId: number;
        inviteUpdates: {
          inviteId: number;
          groupIds: number[] | null;
          companyIds: number[] | null;
        }[];
      }
    >(BULK_UPDATE_INVITES);

  const handleClose = useCallback(async () => {
    setError(null);
    const op = companyId ? setBulkCompanyAccess : setBulkGroupAccess;
    const responseName = companyId
      ? 'bulkSetUserCompanyAccess'
      : 'bulkSetWorkspaceGroupAccess';

    try {
      if (!users || !selectedUserIds || !invites || !selectedInviteIds) {
        return onClose();
      }

      const updated = users.filter((u) => {
        const wasSelected = selectedUserIds.includes(u.id);
        const nowSelected = selectedIdsState.includes(u.id);
        return (wasSelected && !nowSelected) || (!wasSelected && nowSelected);
      });

      const updatedInvites = invites.filter((i) => {
        const wasSelected = selectedInviteIds.includes(i.id);
        const nowSelected = defaultSelectedInvites
          .map((x) => x.id)
          .includes(i.id);
        return (wasSelected && !nowSelected) || (!wasSelected && nowSelected);
      });

      if (!updated.length && !updatedInvites.length) {
        return onClose();
      }

      if (updated.length) {
        await op({
          variables: {
            groupId,
            companyId,
            userAccessList: updated.map((u) => ({
              userId: u.id,
              hasAccess: selectedIdsState.includes(u.id),
            })),
          },
          optimisticResponse: {
            __typename: 'Mutation',
            [responseName]: true,
          },
          update: (cache) => {
            const query = companyId ? COMPANY_QUERY : GROUP;

            const queryData = cache.readQuery({
              query,
              variables: {
                workspaceId,
                companyId,
                groupId,
              },
            });

            cache.writeQuery({
              query,
              variables: {
                workspaceId,
                companyId,
                groupId,
              },
              data: produce(queryData, (draft: any) => {
                if (companyId) {
                  const currentUser = draft.company.users.find(
                    (u) => u.id === currentUserId
                  );

                  const result = currentUser ? [currentUser] : [];

                  result.push(
                    ...allUsers
                      .filter((user) => selectedIdsState.includes(user.id))
                      .map((u) => ({ ...u }))
                  );

                  draft.company.users = result;
                } else {
                  const currentUser = draft.group.managers.find(
                    (u) => u.id === currentUserId
                  );

                  const result = currentUser ? [currentUser] : [];

                  result.push(
                    ...allUsers
                      .filter((user) => selectedIdsState.includes(user.id))
                      .map((u) => ({ ...u }))
                  );

                  draft.group.managers = result;
                }
              }),
            });
          },
        });
      }

      if (invites) {
        const inviteUpdates: {
          inviteId: number;
          groupIds: number[] | null;
          companyIds: number[] | null;
        }[] = [];
        const removeInviteAccess = defaultSelectedInvites.filter(
          (x) => !selectedInviteIds.includes(x.id)
        );
        const addInviteAccess = invites.filter((x) =>
          selectedInviteIds
            .filter(
              (y) =>
                !defaultSelectedInvites.map((invite) => invite.id).includes(y)
            )
            .includes(x.id)
        );

        for (const remove of removeInviteAccess) {
          if (groupId !== undefined && companyId === undefined) {
            inviteUpdates.push({
              inviteId: remove.id,
              groupIds: remove.groupIds
                ? remove.groupIds?.filter((id) => id !== groupId)
                : [groupId],
              companyIds: remove.companyIds || null,
            });
          } else if (groupId === undefined && companyId !== undefined) {
            inviteUpdates.push({
              inviteId: remove.id,
              groupIds: remove.groupIds || null,
              companyIds: remove.companyIds
                ? remove.companyIds?.filter((id) => id !== companyId)
                : [companyId],
            });
          }
        }

        for (const add of addInviteAccess) {
          if (groupId !== undefined && companyId === undefined) {
            inviteUpdates.push({
              inviteId: add.id,
              groupIds: add.groupIds ? [...add.groupIds, groupId] : [groupId],
              companyIds: add.companyIds || null,
            });
          } else if (groupId === undefined && companyId !== undefined) {
            inviteUpdates.push({
              inviteId: add.id,
              groupIds: add.groupIds || null,
              companyIds: add.companyIds
                ? [...add.companyIds, companyId]
                : [companyId],
            });
          }
        }

        if (inviteUpdates.length) {
          await bulkSetInviteAccess({
            variables: {
              workspaceId,
              inviteUpdates,
            },
            update: (cache, results) => {
              if (results.data?.bulkSetWorkspaceInviteAccess.success) {
                const cacheData = cache.readQuery({
                  query: getWorkspaceUserQuery(false, false, true),
                  variables: {
                    workspaceId,
                    groupId,
                    companyId,
                  },
                });

                if (cacheData) {
                  cache.writeQuery({
                    query: getWorkspaceUserQuery(false, false, true),
                    variables: {
                      workspaceId,
                      groupId,
                      companyId,
                    },
                    data: produce(
                      cacheData,
                      (draft: {
                        workspace: Workspace;
                        currentUser: CurrentUser;
                      }) => {
                        if (
                          results.data?.bulkSetWorkspaceInviteAccess.success &&
                          results.data?.bulkSetWorkspaceInviteAccess
                            .workspaceInvites
                        ) {
                          const updatedInviteIds =
                            results.data.bulkSetWorkspaceInviteAccess.workspaceInvites.map(
                              (invite) => invite.id
                            );
                          const filteredInvites = invites.filter(
                            (invite) => !updatedInviteIds.includes(invite.id)
                          );
                          draft.workspace.workspaceInvites = [
                            ...filteredInvites,
                            ...results.data.bulkSetWorkspaceInviteAccess
                              .workspaceInvites,
                          ];
                        }
                      }
                    ),
                  });
                }
              } else {
                if (results.data?.bulkSetWorkspaceInviteAccess.error)
                  showError({
                    text:
                      results.data?.bulkSetWorkspaceInviteAccess.error ||
                      'There was an error updating ',
                  });
              }
            },
          });
        }
      }
      onClose();
      if (!companyId) {
        notificationToast({
          title: 'Group settings saved',
          position: 'center',
          timer: 2000,
        });
      } else {
        notificationToast({
          title: 'Additional user settings saved',
          position: 'center',
          timer: 2000,
        });
      }
    } catch (err) {
      captureException(err, true);
    }
  }, [
    allUsers,
    bulkSetInviteAccess,
    companyId,
    currentUserId,
    defaultSelectedInvites,
    groupId,
    invites,
    onClose,
    selectedIdsState,
    selectedInviteIds,
    selectedUserIds,
    setBulkCompanyAccess,
    setBulkGroupAccess,
    users,
    workspaceId,
  ]);

  const handleInvite = useCallback(
    (invite: Invite) => {
      const { newClient } = invite;
      setError(null);
      return inviteUser({
        variables: {
          workspaceId,
          email: newClient.email,
          firstName: newClient.firstName,
          lastName: newClient.lastName,
          groupId,
          companyId,
        },
        update: (cache, result) => {
          if (result.data?.inviteWorkspaceUser.error) {
            setError(result.data.inviteWorkspaceUser.error);
          } else {
            updateCachedInvitedUsers(
              cache,
              { workspaceId, groupId, companyId },
              result,
              WORKSPACE_QUERY,
              setError
            );
            setSelectedInviteIds((prev) =>
              result.data
                ? [...prev, result.data.inviteWorkspaceUser.workspaceInvite.id]
                : [...prev]
            );
          }
        },
      });
    },
    [companyId, groupId, inviteUser, workspaceId]
  );

  const handleResendInvite = useCallback(
    async (user: User) => {
      try {
        const { data } = await resendInvite({
          variables: {
            workspaceId,
            userId: user.id,
          },
        });

        if (data?.resendWorkspaceInvite.error) {
          setError(data.resendWorkspaceInvite.error);
        } else {
          setError(null);
          showSuccess({ text: 'Invite resent!' });
        }
      } catch (err) {
        captureException(err, true);
      }
    },
    [resendInvite, workspaceId]
  );

  const handleRemoveInvite = useCallback(
    (inviteId: number) => {
      removeInvite({
        variables: {
          inviteId,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          removeWorkspaceInvite: true,
        },
        update: (cache) =>
          removeCachedInvite(
            cache,
            { workspaceId, groupId, companyId },
            inviteId
          ),
      });
    },
    [companyId, groupId, removeInvite, workspaceId]
  );

  const buttons = [
    {
      text: 'Cancel',
      color: 'default',
      outline: true,
      onClick: handleCloseGroupUserModal,
      disabled: loadingBulkGroupAccess || loadingBulkInviteAccess,
    },
    {
      text: 'Save',
      color: 'primary',
      outline: false,
      onClick: handleClose,
      disabled: loadingBulkGroupAccess || loadingBulkInviteAccess,
    },
  ];

  const loading =
    workspaceLoading ||
    companyLoading ||
    groupLoading ||
    !users ||
    loadingBulkGroupAccess ||
    loadingBulkInviteAccess;

  return (
    <UserListModal
      title={companyId ? 'Additional Users' : 'Group Access'}
      show={show}
      loadingInvite={loadingInvite}
      loadingUsers={loading}
      groupAccess={true}
      folderAccess={false}
      onClose={handleClose}
      onSelect={handleSelect}
      selectedUserIds={selectedIdsState}
      selectedInviteIds={selectedInviteIds}
      readOnlyUserIds={
        canAccessWorkspaceSettings || !restrictRemoveGroupAccess
          ? defaultSelected
          : selectedUserIds
      }
      onInvite={canAccessWorkspaceSettings ? handleInvite : null}
      onResendInvite={
        canAccessWorkspaceSettings ? handleResendInvite : undefined
      }
      error={error}
      users={users || []}
      invites={
        canAccessWorkspaceSettings || !restrictRemoveGroupAccess ? invites : []
      }
      onRemoveInvite={
        canAccessWorkspaceSettings || !restrictRemoveGroupAccess
          ? handleRemoveInvite
          : undefined
      }
      showWorkspaceOwner
      showSecondaryAdmin
      buttons={buttons}
      inviteButtonId={
        companyId
          ? 'company-additional-user-invite-button'
          : 'group-user-invite-button'
      }
    />
  );
};

export default GroupUserModal;
