import { BUDGET_GROUP_FIELDS, BUDGET_LIST_FIELDS } from 'fragments';
import gql from 'graphql-tag';

export const QUERY = gql`
  query BudgetsModal($companyId: Int!) {
    currentUser {
      firstName
      lastName
      userId
    }
    company(id: $companyId) {
      id
      name
      accounting {
        integration
        hasSubdividedProBudgets
      }
      budgets {
        ...BudgetListFields
        datasheet {
          id
          startDate
          endDate
          needsDatasheetRefresh
        }
      }
      budgetGroups {
        ...BudgetGroupFields
      }
      budgetFilterLimit
      customKpiVariables
      consolidationCompanies {
        id
        parentConsolidationId
        name
        abbreviation
        customKpiVariables
      }
      defaultBudgetId
      defaultBudgetType
      defaultForecastId
      defaultForecastType
    }
  }
  ${BUDGET_LIST_FIELDS}
  ${BUDGET_GROUP_FIELDS}
`;

export const CREATE = gql`
  mutation CreateBudget(
    $companyId: Int!
    $budgetSettings: BudgetSettingsInput!
  ) {
    createBudgetJob(companyId: $companyId, budgetSettings: $budgetSettings) {
      success
    }
  }
`;

export const CREATE_BUDGET_GROUP = gql`
  mutation CreateBudgetGroup(
    $companyId: Int!
    $name: String!
    $budgetIds: [Int!]!
  ) {
    createBudgetGroup(
      companyId: $companyId
      name: $name
      budgetIds: $budgetIds
    ) {
      ...BudgetGroupFields
    }
  }
  ${BUDGET_GROUP_FIELDS}
`;

export const UPDATE_BUDGET_GROUP = gql`
  mutation UpdateBudgetGroup(
    $budgetGroupId: Int!
    $name: String!
    $budgetIds: [Int!]!
  ) {
    updateBudgetGroup(
      budgetGroupId: $budgetGroupId
      name: $name
      budgetIds: $budgetIds
    ) {
      ...BudgetGroupFields
    }
  }
  ${BUDGET_GROUP_FIELDS}
`;

export const DELETE_BUDGET_GROUP = gql`
  mutation DeleteBudgetGroup($budgetGroupId: Int!) {
    deleteBudgetGroup(budgetGroupId: $budgetGroupId) {
      success
      error
    }
  }
`;

export const CREATE_EXCEL_TEMPLATE = gql`
  mutation CreateExcelTemplate(
    $companyId: Int!
    $budgetSettings: BudgetSettingsInput!
  ) {
    createExcelTemplate(
      companyId: $companyId
      budgetSettings: $budgetSettings
    ) {
      success
    }
  }
`;

export const BUDGET_JOB_FINISHED = gql`
  subscription BudgetJobFinished($companyId: Int!) {
    budgetJobFinished(companyId: $companyId) {
      budget {
        ...BudgetListFields
        datasheet {
          id
          startDate
          endDate
          needsDatasheetRefresh
        }
      }
      templateUrl
      existingExcel
      existingExcelName
      updated
      validation {
        valid
        missingColumns
        empty
        errors {
          line
          columnErrors {
            column
            error
            actual
          }
          actualData
        }
      }
    }
  }
  ${BUDGET_LIST_FIELDS}
`;

export const CREATE_EXCEL = gql`
  mutation CreateExcelBudget(
    $companyId: Int!
    $name: String!
    $excelFile: Upload!
  ) {
    createExcelBudgetJob(
      companyId: $companyId
      name: $name
      excelFile: $excelFile
    ) {
      success
    }
  }
`;
export const UPDATE_EXCEL = gql`
  mutation UpdateExcelBudget($tableId: Int!, $excelFile: Upload!) {
    updateExcelBudgetJob(tableId: $tableId, excelFile: $excelFile) {
      success
    }
  }
`;
export const DOWNLOAD_EXCEL = gql`
  mutation DownloadExcel($budgetId: Int!) {
    downloadExcelBudgetJob(budgetId: $budgetId) {
      success
    }
  }
`;

export const DELETE = gql`
  mutation DeleteBudget($budgetId: Int!) {
    deleteBudget(budgetId: $budgetId)
  }
`;

export const RENAME = gql`
  mutation RenameBudget($budgetId: Int!, $name: String!) {
    renameBudget(budgetId: $budgetId, name: $name) {
      id
      name
    }
  }
`;

export const DUPLICATE = gql`
  mutation DuplicateBudget($budgetId: Int!) {
    duplicateTable(tableId: $budgetId) {
      ...BudgetListFields
    }
  }
  ${BUDGET_LIST_FIELDS}
`;

export const VALIDATE = gql`
  mutation ValidateExcelBudget($companyId: Int!, $excelFile: Upload!) {
    validateExcelBudgetJob(companyId: $companyId, excelFile: $excelFile) {
      success
    }
  }
`;
