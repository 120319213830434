import React, { useMemo } from 'react';
import gql from 'graphql-tag';
import moment, { Moment } from 'moment';
import { useQuery } from '@apollo/client';
import useCompanyId from 'hooks/useCompanyId';

const query = gql`
  query WithBookMonth($companyId: Int!) {
    company(id: $companyId) {
      id
      bookMonth
      fiscalYearMonth
    }
  }
`;

export interface WithBookMonthProps extends WithCompanyIdProps {
  bookMonth: Moment;
  fiscalYearMonth: number;
}

const withBookMonth = <P extends WithBookMonthProps>(
  Component: React.ComponentType<P>
) => {
  function WithBookMonth(props) {
    const { companyId: propCompanyId } = props;

    const hookCompanyId = useCompanyId();

    const companyId = propCompanyId || hookCompanyId;

    const { data } = useQuery(query, { variables: { companyId } });

    const { bookMonth, fiscalYearMonth } = useMemo(() => {
      if (!data || !data.company) {
        return { bookMonth: null, fiscalYearMonth: null };
      }
      return {
        bookMonth:
          data.company && data.company.bookMonth
            ? moment.utc(data.company.bookMonth)
            : moment.utc().startOf('month'),
        fiscalYearMonth: data.company ? data.company.fiscalYearMonth : 0,
      };
    }, [data]);

    if (!data || !data.company) {
      return null;
    }

    return (
      <Component
        {...props}
        bookMonth={bookMonth}
        fiscalYearMonth={fiscalYearMonth}
      />
    );
  }

  return WithBookMonth;
};

export default withBookMonth;
