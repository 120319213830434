/**
 *
 * SetFirmName
 *
 */

import React from 'react';
import styled from 'styled-components';
import { Query } from '@apollo/client/react/components';
import gql from 'graphql-tag';
import { Form } from 'informed';

import Modal from 'components/Modal';
import TextField from 'components/TextField';
import Mutation from 'components/Mutation';
import Button from 'components/Button';

const StyledButton = styled(Button)`
  margin-left: 10px;
`;

const Inner = styled.div`
  display: flex;
`;

const StyledTextField = styled(TextField)`
  min-width: 0;
`;

const ErrorDiv = styled.div`
  color: ${props => props.theme.colors.danger};
  font-size: 13px;
`;

const QUERY = gql`
  query GetFirmName($firmId: Int!) {
    firm(id: $firmId) {
      id
      name
    }
  }
`;

const SET_FIRM_NAME = gql`
  mutation SetFirmName($firmId: Int!, $name: String!) {
    setFirmName(firmId: $firmId, name: $name) {
      id
      name
    }
  }
`;

const validate = value => {
  if (!value || !value.trim()) {
    return 'Required';
  } else if (value.trim().length > 35) {
    return 'Name cannot be more than 35 characters';
  }
  return undefined;
};

class SetFirmName extends React.PureComponent {
  getApi = formApi => {
    this.formApi = formApi;
  };
  render() {
    const { firmId, show, onClose, title } = this.props;

    return (
      <Modal
        show={show}
        title={title || 'Rename Folder'}
        onClose={onClose}
        width={400}
      >
        <Query query={QUERY} variables={{ firmId }}>
          {({ data, loading, error }) => {
            if (loading || error) {
              return null;
            }

            return (
              <Mutation mutation={SET_FIRM_NAME}>
                {setFirmName => (
                  <Form
                    getApi={this.getApi}
                    initialValues={{ name: data.firm.name }}
                    onSubmit={({ name }) => {
                      setFirmName({
                        variables: {
                          firmId,
                          name: name.trim(),
                        },
                        optimisticResponse: {
                          __typename: 'Mutation',
                          setFirmName: {
                            __typename: 'Firm',
                            id: firmId,
                            name,
                          },
                        },
                      });
                      onClose();
                    }}
                  >
                    {({ formState }) => (
                      <>
                        <Inner>
                          <StyledTextField
                            field="name"
                            autoComplete="off" data-1p-ignore
                            validate={validate}
                          />
                          <StyledButton type="submit" color="primary">
                            Update
                          </StyledButton>
                        </Inner>
                        <ErrorDiv>{formState.errors.name || null}</ErrorDiv>
                      </>
                    )}
                  </Form>
                )}
              </Mutation>
            );
          }}
        </Query>
      </Modal>
    );
  }
}

export default SetFirmName;
