/* global Image */
import ColorThief from './colorThief';

const colorThief = new ColorThief();

const rgbToHex = (r, g, b) => {
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};

const getImagePalette = (
  url: string | ArrayBuffer,
  callback: (colors: string[]) => void
): void => {
  const img = new Image();
  img.onload = () => {
    const colors = colorThief
      .getPalette(img, 9, 5, false)
      .slice(0, 7)
      .map((color) => rgbToHex(color[0], color[1], color[2]));
    callback(colors);
  };
  img.crossOrigin = 'Anonymous';
  const src = url as string;
  if (src.startsWith('data:')) {
    img.src = url as string;
  } else {
    // need timestamp on end to prevent cors error.
    img.src = src + '?' + new Date().getTime();
  }
};

export default getImagePalette;
