import GustoLogo from 'components/IntegrationLogos/GustoLogo';
import QuickBooksLogo from 'components/IntegrationLogos/QuickbooksLogo';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { showWarning, showInfo } from 'utils/popups';
import Integration from './Integration';
import Button from '../../Button';
import XeroLogo from 'components/IntegrationLogos/XeroLogo';
import { QbLogo, TextButton } from 'components/AddCompanyPanel';
import XeroButton from 'components/AddCompanyPanel/XeroButton';

import {
  connectQbo,
  connectXero,
  connectGusto,
  connectMyob,
} from 'utils/connections';
import useWorkspaceId from 'hooks/useWorkspaceId';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import CreateCsvCompany from 'components/CreateCsvCompany';
import CsvButton from 'components/AddCompanyPanel/CsvButton';
import QbdAttach from './QbdAttach';
import GustoButton from 'components/AddCompanyPanel/GustoButton';
import { captureException } from 'utils/sentry';
import useIsAdministrator from 'hooks/useIsAdministrator';
import SvgButton from 'components/AddCompanyPanel/SvgButton';
// @ts-ignore
import myob from 'components/AddCompanyPanel/myob.svg';
import MyobLogo from 'components/IntegrationLogos/MyobLogo';

const DISCONNECT = gql`
  mutation DisconnectIntegration($companyId: Int!, $integration: Integration!) {
    disconnectCompanyIntegration(
      companyId: $companyId
      integration: $integration
    )
  }
`;

const CREATE_GUSTO_ACCOUNT = gql`
  mutation CreateGustoAccount($companyId: Int!) {
    createGustoAccount(companyId: $companyId) {
      claimUrl
    }
  }
`;

const Div = styled.div`
  max-width: 500px;
`;

const Container = styled.div`
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const BookkeepingContainer = styled.div<{ showBookkeeping: boolean }>``;

const IntegrationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ConnectButton = styled(Button)`
  width: fit-content;
  margin-top: 15px;
  padding-left: 25px;
  padding-right: 25px;
`;

const Back = styled.a`
  color: #617182;
  &:hover {
    color: #617182;
  }
`;

const StyledXeroButton = styled(XeroButton)`
  width: 300px;
  margin-bottom: 9px;
`;

const StyledGustoButton = styled(GustoButton)`
  margin-left: 0;
  margin-right: 0;
`;

const StyledCsvButton = styled(CsvButton)`
  width: 300px;
  margin-bottom: 9px;
`;

const StyledSvgButton = styled(SvgButton)`
  width: 300px;
  margin: unset;
  margin-bottom: 9px;

  img {
    width: 100%;
  }
`;

const StyledQbLogo = styled(QbLogo)`
  width: 300px;
`;

const StyledTextButton = styled(TextButton)`
  width: 300px;
  margin-bottom: 9px;
  margin-left: 0;
  margin-right: 0;
  height: 50px;
`;

const GustoAccountButton = styled.button`
  color: ${(props) => props.theme.colors.link}!important;
  padding-left: 0;
  padding-right: 0;
`;

function GustoConnect(props) {
  const { companyId, onClick, ...rest } = props;

  const [create] = useMutation(CREATE_GUSTO_ACCOUNT);

  const administrator = useIsAdministrator();

  const handleCreate = useCallback(async () => {
    try {
      const { data } = await create({
        variables: {
          companyId,
        },
      });

      if (!data.createGustoAccount.claimUrl) {
        return showInfo({
          title: '',
          text: 'Failed to start signup process with Gusto. You may already have a Gusto account.',
        });
      }

      location.href = data.createGustoAccount.claimUrl;
    } catch (err) {
      captureException(err, true);
    }
  }, [companyId, create]);

  return (
    <div>
      <StyledGustoButton
        width={'auto'}
        height={'100%'}
        {...rest}
        onClick={() => onClick()}
      />
      <p>Select only one Gusto company if you are asked to choose.</p>
      <GustoAccountButton type="button" onClick={handleCreate}>
        Click here to create a Gusto account
      </GustoAccountButton>
      <p>
        After creating a Gusto account, you will need to confirm your email.
        After that you may return here to continue connecting.
      </p>
      {administrator && (
        <GustoAccountButton type="button" onClick={() => onClick(true)}>
          Connect Gusto Demo
        </GustoAccountButton>
      )}
    </div>
  );
}

type companyIntegrations = {
  bookkeeping: string | null;
  gusto: boolean;
};

interface IntegrationSettingsProps {
  companyIntegrations: companyIntegrations; // TODO: change to required when done
  companyId: number;
  groupId: number;
  canDisconnectCompany: boolean;
  isQBD: boolean;
}

const IntegrationSettings: React.FC<IntegrationSettingsProps> = ({
  companyIntegrations: { bookkeeping, gusto },
  companyId,
  groupId,
  canDisconnectCompany,
  isQBD,
}) => {
  const contentRef = React.createRef<HTMLDivElement>();
  const [showBookkeeping, setShowBookkeeping] = useState<boolean>(false);
  const [showQbd, setShowQbd] = useState<boolean>(false);
  const [showCsv, setShowCsv] = useState<boolean>(false);
  const workspaceId = useWorkspaceId();

  const [disconnect] = useMutation(DISCONNECT);

  const onConnectBookkeeping = (): void => {
    setShowBookkeeping(true);
  };

  const onConnectGusto = useCallback(
    (demo) => {
      connectGusto(groupId, workspaceId, companyId, demo);
    },
    [companyId, groupId, workspaceId]
  );

  const onClickCsv = useCallback(() => {
    setShowCsv(true);
  }, []);
  const onCloseCsv = useCallback(() => {
    setShowCsv(false);
  }, []);

  const disconnectIntegration = (id: string, name: string): void => {
    showWarning({
      title: 'Hold on',
      text: `Are you sure you want to disconnect ${name}?`,
      confirmButtonText: 'Disconnect',
      showDenyButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        disconnect({
          variables: {
            companyId,
            integration: id,
          },
        }).then(() => {
          window.location.reload();
        });
      } else {
        // Do nothing
      }
    });
  };

  const onClickQbd = useCallback(() => {
    setShowBookkeeping(false);
    setShowQbd(true);
  }, []);

  const bookKeepingConnections = useMemo(() => {
    return [
      {
        id: 'QBO',
        name: 'Quickbooks Online',
        connect: (
          <StyledQbLogo
            type="button"
            onClick={() => connectQbo(groupId, workspaceId, companyId)}
          />
        ),
      },
      {
        id: 'QBD',
        name: 'Quickbooks Desktop',
        connect: (
          <StyledTextButton onClick={onClickQbd}>
            QuickBooks Desktop
          </StyledTextButton>
        ),
      },
      {
        id: 'XERO',
        name: 'Xero',
        connect: (
          <StyledXeroButton
            type="button"
            onClick={() => connectXero(groupId, workspaceId, companyId)}
          />
        ),
      },
      {
        id: 'MYOB',
        name: 'MYOB',
        connect: (
          <StyledSvgButton
            onClick={() => connectMyob(groupId, workspaceId, companyId)}
            src={myob}
          />
        ),
      },
      {
        id: 'CSV',
        name: 'CSV',
        connect: (
          <StyledCsvButton onClick={onClickCsv}>Connect to CSV</StyledCsvButton>
        ),
      },
    ];
  }, [companyId, groupId, onClickCsv, onClickQbd, workspaceId]);

  const integrations = useMemo(
    () => ({
      bookkeeping: {
        QB: {
          id: 'QB',
          name: 'QuickBooks',
          logo: <QuickBooksLogo />,
          connect: (
            <StyledQbLogo
              type="button"
              onClick={() => connectQbo(groupId, workspaceId, companyId)}
            />
          ),
        },
        XERO: {
          id: 'XERO',
          name: 'Xero',
          logo: <XeroLogo />,
          connect: (
            <StyledXeroButton
              type="button"
              onClick={() => connectXero(groupId, workspaceId, companyId)}
            />
          ),
        },
        CSV: {
          id: 'CSV',
          name: 'CSV',
          logo: 'CSV',
          connect: (
            <StyledCsvButton onClick={onClickCsv}>
              Connect to CSV
            </StyledCsvButton>
          ),
        },
        MYOB: {
          id: 'MYOB',
          name: 'MYOB',
          logo: <MyobLogo width={80} />,
          connect: (
            <StyledSvgButton
              onClick={() => connectMyob(groupId, workspaceId, companyId)}
              src={myob}
            />
          ),
        },
        // Add more bookkeeping types here when needed
      },
      GUSTO: {
        id: 'GUSTO',
        name: 'Gusto',
        logo: <GustoLogo />,
      },
      // Add more integration types here when needed
    }),
    [companyId, groupId, onClickCsv, workspaceId]
  );

  return (
    <Div>
      <Header>
        <span>Integrations</span>
        {showBookkeeping && (
          <Back
            onClick={() => {
              if (showBookkeeping) {
                setShowBookkeeping(false);
              } else if (showQbd) {
                setShowQbd(false);
                setShowBookkeeping(true);
              }
            }}
          >
            <i className={`mdi-set mdi-arrow-left`} />
            Back
          </Back>
        )}
      </Header>

      <Container>
        {showBookkeeping && (
          <BookkeepingContainer showBookkeeping={showBookkeeping}>
            {bookKeepingConnections.map((integration) => {
              return integration.connect;
            })}
          </BookkeepingContainer>
        )}

        {showQbd && <QbdAttach companyId={companyId} />}

        {!showBookkeeping && !showQbd && (
          <IntegrationsContainer>
            {bookkeeping ? (
              <Integration
                id={integrations.bookkeeping[bookkeeping].id}
                name={integrations.bookkeeping[bookkeeping].name}
                logo={
                  isQBD
                    ? 'QuickBooks Desktop'
                    : integrations.bookkeeping[bookkeeping].logo
                }
                onDisconnect={disconnectIntegration}
                canDisconnectCompany={canDisconnectCompany}
              />
            ) : (
              canDisconnectCompany && (
                <ConnectButton
                  outline
                  type="button"
                  onClick={onConnectBookkeeping}
                >
                  Connect Bookkeeping
                </ConnectButton>
              )
            )}
            {gusto ? (
              <Integration
                id={integrations.GUSTO.id}
                name={integrations.GUSTO.name}
                logo={integrations.GUSTO.logo}
                onDisconnect={disconnectIntegration}
                canDisconnectCompany={canDisconnectCompany}
              />
            ) : canDisconnectCompany ? (
              <GustoConnect companyId={companyId} onClick={onConnectGusto} />
            ) : null}
          </IntegrationsContainer>
        )}
      </Container>
      <CreateCsvCompany
        attachCompanyId={companyId}
        show={showCsv}
        onClose={onCloseCsv}
      />
    </Div>
  );
};

export default IntegrationSettings;
