/**
 *
 * Create
 *
 */
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import {
  Bottom,
  CreateButton,
  FileInput,
  GrowthRateInput,
  Half,
  Section,
} from './styledComponents';
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import { useValidateExcel } from './hooks';
import { ValidationErrors } from './components';
import HorizontalTabs from 'components/HorizontalTabs';
import Select from 'components/Select';
import BetaBadge from 'components/BetaBadge';
import Help from 'components/Help';
import { getDates } from './utils';
import BankAccountSelect from 'components/BankAccountSelect';
import BudgetReferenceSelect from 'components/BudgetReferenceSelect';
import { DEV, STAGING } from 'environment';
import SubdividedSelect from './SubdividedSelect';

const Content = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const Side = styled.div`
  width: 100%;
`;

const FieldSection = styled.div`
  margin-bottom: 0.5rem;
`;

const RowDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCheckbox = styled(Checkbox)`
  display: block;
`;

const ValidateText = styled.div`
  font-size: 0.75rem;
`;

const BankInfoText = styled.div`
  font-size: 0.75rem;
`;

const pills = ['Reach Reporting', 'Use Excel', 'Legacy Budgets'];

const dateRangeOptions = [
  {
    label: 'Current Fiscal Year',
    value: 'CURRENT_FISCAL_YEAR',
  },
  {
    label: 'Next Fiscal Year',
    value: 'NEXT_FISCAL_YEAR',
  },
  {
    label: 'Next Fiscal Year + 1',
    value: 'NEXT_YEAR_PLUS_ONE_TWELVE_FISCAL_MONTHS',
  },
  {
    label: 'Next Fiscal Year + 2',
    value: 'NEXT_YEAR_PLUS_TWO_TWELVE_FISCAL_MONTHS',
  },
  {
    label: 'Next Fiscal Year + 3',
    value: 'NEXT_YEAR_PLUS_THREE_TWELVE_FISCAL_MONTHS',
  },
  {
    label: 'Next Fiscal Year + 4',
    value: 'NEXT_YEAR_PLUS_FOUR_TWELVE_FISCAL_MONTHS',
  },
  {
    label: 'Last Fiscal Year',
    value: 'LAST_FISCAL_YEAR',
  },
];

if (STAGING || DEV) {
  dateRangeOptions.push(
    {
      label: 'Last Fiscal Year - 1',
      value: 'LAST_FISCAL_YEAR_BACK_ONE',
    },
    {
      label: 'Last Fiscal Year - 2',
      value: 'LAST_FISCAL_YEAR_BACK_TWO',
    }
  );
}

const prefillRangeOptions = [
  {
    label: 'Last Fiscal Year',
    value: 'LAST_FISCAL_YEAR',
  },
  {
    label: 'Last Fiscal Year - 1',
    value: 'LAST_FISCAL_YEAR_BACK_ONE',
  },
  {
    label: 'Current Fiscal Year',
    value: 'CURRENT_FISCAL_YEAR',
  },
];

export const ADVANCED_TAB = 0;
export const EXCEL_TAB = 1;
export const LEGACY_TAB = 2;

const Create = (props) => {
  const {
    budget,
    budgets,
    additionalAccounts,
    dispatch,
    setBudget,
    handleToggleAdditionalAccounts,
    handleToggleThreeWayEnabled,
    hasFilter,
    hasSubdividedProBudgets,
    handleChangeGrowthRate,
    createBudget,
    createExcelTemplate,
    companyId,
    tab,
    prevTab,
    setTab,
    setFile,
    file,
    supportedStatements,
    creating,
    creatingTemplate,
    fiscalYearMonth,
    hideLegacyBudgets,
    isSummit,
    isMyob,
    handleToggleSubdivided,
    handleSubdivide,
    budgetFilterLimit,
  } = props;
  const [selectedRange, setSelectedRange] = useState('CURRENT_FISCAL_YEAR');
  const [selectedPrefillRange, setSelectedPrefillRange] =
    useState('LAST_FISCAL_YEAR');
  const {
    validateExcel,
    loading: validateLoading,
    validationErrors,
  } = useValidateExcel({ companyId, onSetFile: setFile });

  useEffect(() => {
    setBudget((prev) => ({
      ...prev,
      prefillData: false,
      prefillBudgetId: null,
      forecastRangeDates: getDates(selectedRange, fiscalYearMonth),
      prefillDates: getDates(selectedPrefillRange, fiscalYearMonth),
    }));
    // only use once on render to reset
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const switchedToAdvanced = prevTab !== ADVANCED_TAB && tab === ADVANCED_TAB;
    if (switchedToAdvanced) {
      setBudget((prev) => ({
        ...prev,
        forecastRangeDates: getDates(selectedRange, fiscalYearMonth),
        prefillDates: getDates(selectedPrefillRange, fiscalYearMonth),
      }));
    }
  }, [
    fiscalYearMonth,
    prevTab,
    selectedPrefillRange,
    selectedRange,
    setBudget,
    tab,
  ]);

  const submitDisabled = useMemo(() => {
    if (tab === EXCEL_TAB) {
      return (
        !file ||
        creating ||
        validateLoading ||
        !budget.name ||
        !budget.name.trim()
      );
    } else if (tab === LEGACY_TAB) {
      return (
        creating ||
        !budget.name ||
        !budget.name.trim() ||
        (!budget.profitAndLoss && !budget.additionalAccounts.length)
      );
    } else {
      return (
        creating ||
        !budget.name ||
        !budget.name.trim() ||
        (budget.threeWayEnabled &&
          (!budget.threeWaySettings ||
            !budget.threeWaySettings.bankAccountId)) ||
        (budget.subdividedEnabled && !budget.subdivide)
      );
    }
  }, [
    tab,
    file,
    creating,
    validateLoading,
    budget.name,
    budget.profitAndLoss,
    budget.additionalAccounts.length,
    budget.threeWayEnabled,
    budget.threeWaySettings,
    budget.subdividedEnabled,
    budget.subdivide,
  ]);

  const downloadTemplateDisabled = useMemo(() => {
    return !budget.profitAndLoss && !budget.balanceSheet && !budget.cashFlow;
  }, [budget.balanceSheet, budget.cashFlow, budget.profitAndLoss]);

  const handleChangeTab = useCallback(
    (selectedTab) => {
      setTab(selectedTab);
      if (selectedTab === ADVANCED_TAB && !budget.profitAndLoss) {
        setBudget(() => ({
          ...budget,
          profitAndLoss: true,
        }));
      }
    },
    [budget, setBudget, setTab]
  );

  const handleChangeBankAccount = useCallback(
    (accountId) => {
      if (!budget.threeWaySettings) {
        setBudget(() => ({
          ...budget,
          threeWaySettings: {
            bankAccountId: accountId,
          },
        }));
      } else {
        setBudget(() => ({
          ...budget,
          threeWaySettings: {
            ...budget.threeWaySettings,
            bankAccountId: accountId,
          },
        }));
      }
    },
    [budget, setBudget]
  );

  const hiddenIndices = useMemo(() => {
    if (isSummit) {
      return [1, 2];
    }
    if (hideLegacyBudgets) {
      return [2];
    }
    return [];
  }, [hideLegacyBudgets, isSummit]);

  const handleSelectPrefill = useCallback(
    (refBudgetId) => {
      if (refBudgetId === -1) {
        setSelectedPrefillRange('LAST_FISCAL_YEAR');
        setBudget((prev) => ({
          ...prev,
          prefillDates: getDates('LAST_FISCAL_YEAR', fiscalYearMonth),
          prefillBudgetId: null,
        }));
        return;
      }

      const refBudget = budgets.find((b) => b.id === refBudgetId);
      if (refBudget) {
        setBudget((prev) => ({
          ...prev,
          prefillDates: {
            startDate: moment.utc(refBudget.datasheet.startDate),
            endDate: moment.utc(refBudget.datasheet.endDate),
          },
          prefillBudgetId: refBudgetId,
        }));
      }
    },
    [budgets, fiscalYearMonth, setBudget]
  );

  if (isMyob) {
    pills.splice(1, 2);
  }

  return (
    <div>
      <BetaBadge fullWidth text={'PRO'} bottom={24} left={135}>
        <HorizontalTabs
          tabs={pills}
          current={tab}
          onChange={handleChangeTab}
          hiddenIndices={hiddenIndices}
        />
      </BetaBadge>
      <div
        style={{
          width: '100%',
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 212px)',
        }}
      >
        <Content>
          <Side>
            <Section>
              {tab === EXCEL_TAB && (
                <FieldSection>STEP 1: Download Template</FieldSection>
              )}
              {tab === EXCEL_TAB && <p>Choose Statements</p>}
              Select Statements
              {tab !== ADVANCED_TAB &&
                supportedStatements.includes('PROFIT_AND_LOSS') && (
                  <StyledCheckbox
                    checked={budget.profitAndLoss}
                    onChange={(e) =>
                      setBudget({
                        ...budget,
                        profitAndLoss: e.target.checked,
                      })
                    }
                  >
                    Profit and Loss
                  </StyledCheckbox>
                )}
              {tab === EXCEL_TAB && (
                <>
                  {supportedStatements.includes('BALANCE_SHEET') && (
                    <StyledCheckbox
                      checked={budget.balanceSheet}
                      onChange={(e) =>
                        setBudget({
                          ...budget,
                          balanceSheet: e.target.checked,
                        })
                      }
                    >
                      Balance Sheet
                    </StyledCheckbox>
                  )}

                  {supportedStatements.includes('CASH_FLOW') && (
                    <StyledCheckbox
                      checked={budget.cashFlow}
                      onChange={(e) =>
                        setBudget({
                          ...budget,
                          cashFlow: e.target.checked,
                        })
                      }
                    >
                      Cash Flow
                    </StyledCheckbox>
                  )}
                </>
              )}
              {tab === LEGACY_TAB && (
                <FieldSection>
                  <Checkbox
                    checked={additionalAccounts}
                    onChange={handleToggleAdditionalAccounts}
                  >
                    Additional Accounts
                  </Checkbox>
                  {additionalAccounts && (
                    <Button
                      onClick={() => dispatch({ type: 'ADD_EDIT' })}
                      color="secondary"
                    >
                      Add/Edit
                    </Button>
                  )}
                </FieldSection>
              )}
              {tab === ADVANCED_TAB &&
                supportedStatements.includes('BALANCE_SHEET') && (
                  <>
                    <StyledCheckbox
                      checked={budget.profitAndLoss}
                      onChange={(e) =>
                        setBudget({
                          ...budget,
                          profitAndLoss: e.target.checked,
                        })
                      }
                      disabled
                    >
                      Profit and Loss
                    </StyledCheckbox>
                    <Checkbox
                      checked={budget.threeWayEnabled}
                      onChange={handleToggleThreeWayEnabled}
                      testId="create-budget-three-way-enabled"
                    >
                      Balance Sheet & Cash Flow Statement
                    </Checkbox>
                    {budget.threeWayEnabled && (
                      <Half>
                        <BankInfoText>Choose Main Bank Account</BankInfoText>
                        <BankAccountSelect
                          portal
                          onChange={handleChangeBankAccount}
                          testId="create-budget-bank-account-select"
                          value={
                            budget.threeWaySettings &&
                            budget.threeWaySettings.bankAccountId
                          }
                        />
                        <BankInfoText>
                          This account will be used to calculate the results of
                          Profit & Loss and Balance Sheet budget/forecast.
                        </BankInfoText>
                      </Half>
                    )}
                  </>
                )}
            </Section>
            {tab === ADVANCED_TAB && hasSubdividedProBudgets && hasFilter && (
              <Section>
                Subdivided
                <Checkbox
                  checked={budget.subdividedEnabled}
                  onChange={handleToggleSubdivided}
                  testId="create-budget-subdivided"
                >
                  Enable Subdivided by Class or Department
                </Checkbox>
                {budget.subdividedEnabled && (
                  <>
                    <Half>
                      <SubdividedSelect
                        companyId={companyId}
                        subdivide={budget.subdivide}
                        onChange={handleSubdivide}
                        disableSubs
                        limit={budgetFilterLimit}
                      />
                    </Half>
                    <BankInfoText>
                      Maximum of {budgetFilterLimit} classes or departments.
                      This list will show up for the Profit and Loss only. Only
                      top level classes/departments can be selected.
                    </BankInfoText>
                  </>
                )}
              </Section>
            )}
            <FieldSection>
              Budget Date Range
              <Half>
                <Select
                  options={dateRangeOptions}
                  onChange={(range) => {
                    setSelectedRange(range);
                    setBudget((prev) => ({
                      ...prev,
                      forecastRangeDates: getDates(range, fiscalYearMonth),
                    }));
                  }}
                  value={selectedRange}
                  portal
                  testId="create-budget-date-range"
                />
                <div data-testid="forecast-range-dates">
                  {budget.forecastRangeDates.startDate.format('MMM YYYY')} -{' '}
                  {budget.forecastRangeDates.endDate.format('MMM YYYY')}
                </div>
              </Half>
            </FieldSection>
            <FieldSection>
              <Checkbox
                checked={budget.prefillData}
                onChange={(e) =>
                  setBudget({ ...budget, prefillData: e.target.checked })
                }
                testId="create-budget-prefill-data"
              >
                Pre-fill Data
              </Checkbox>
              {budget.prefillData && (
                <Half>
                  <div style={{ display: 'flex' }}>
                    <div style={{ flexGrow: 1 }}>
                      <BudgetReferenceSelect
                        companyId={companyId}
                        fiscalYearMonth={fiscalYearMonth}
                        range={selectedRange}
                        value={budget.prefillBudgetId || -1}
                        onChange={handleSelectPrefill}
                        testId="create-budget-prefill-budget"
                        portal
                      />
                      {!budget.prefillBudgetId && (
                        <Select
                          options={prefillRangeOptions}
                          onChange={(range) => {
                            setSelectedPrefillRange(range);
                            setBudget((prev) => ({
                              ...prev,
                              prefillDates: getDates(range, fiscalYearMonth),
                            }));
                          }}
                          value={selectedPrefillRange}
                          testId="create-budget-prefill-range"
                          portal
                        />
                      )}
                    </div>
                  </div>
                  <div data-testid="prefill-dates">
                    {budget.prefillDates.startDate.format('MMM YYYY')} -{' '}
                    {budget.prefillDates.endDate.format('MMM YYYY')}
                  </div>
                </Half>
              )}
            </FieldSection>
            {budget.prefillData && (
              <FieldSection>
                <RowDiv>
                  <Checkbox
                    checked={budget.growthRate !== null}
                    onChange={(e) =>
                      setBudget({
                        ...budget,
                        growthRate: e.target.checked ? 0 : null,
                      })
                    }
                    testId="create-budget-apply-growth-rate"
                  >
                    Apply growth rate
                  </Checkbox>
                  {budget.growthRate !== null && (
                    <GrowthRateInput
                      value={budget.growthRate}
                      onChange={handleChangeGrowthRate}
                      data-testid="create-budget-growth-rate"
                    />
                  )}
                </RowDiv>
              </FieldSection>
            )}
            {(tab === ADVANCED_TAB || tab === EXCEL_TAB) && (
              <FieldSection>
                <RowDiv>
                  <Checkbox
                    checked={budget.hideInactiveAccounts}
                    onChange={(e) =>
                      setBudget({
                        ...budget,
                        hideInactiveAccounts: e.target.checked,
                      })
                    }
                    testId="create-budget-hide-inactive-accounts"
                  >
                    {"Don't include deleted/inactive accounts"}
                    <Help>
                      Not including deleted accounts may influence the
                      historical prefill data.
                    </Help>
                  </Checkbox>
                </RowDiv>
              </FieldSection>
            )}
            {tab === EXCEL_TAB && (
              <FieldSection>
                <Button
                  color="secondary"
                  outline
                  onClick={createExcelTemplate}
                  disabled={downloadTemplateDisabled || creatingTemplate}
                  loading={creatingTemplate}
                >
                  Download Excel Template
                </Button>
              </FieldSection>
            )}
          </Side>
          {tab === EXCEL_TAB && (
            <Side>
              <FieldSection>STEP 2: Upload Excel File</FieldSection>
              <FieldSection>
                <FileInput onChange={validateExcel} />
              </FieldSection>
              {validateLoading && <ValidateText>Validating...</ValidateText>}
              <ValidationErrors validationErrors={validationErrors} />
            </Side>
          )}
        </Content>
      </div>
      <Bottom>
        <input
          type="text"
          value={budget.name}
          onChange={(e) =>
            setBudget({
              ...budget,
              name: e.target.value,
            })
          }
          placeholder="Budget Name"
          maxLength={40}
          data-testid="create-budget-name"
          autoComplete="off"
          data-1p-ignore
        />
        <CreateButton
          color="primary"
          outline
          onClick={() => {
            if (!creating) createBudget();
          }}
          disabled={submitDisabled}
          loading={creating}
          data-testid="create-budget-submit"
        >
          Create
        </CreateButton>
      </Bottom>
    </div>
  );
};

export default Create;
