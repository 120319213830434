/**
 *
 * GroupSettingsDropdown
 *
 */
import React, { useCallback, useState } from 'react';
import gql from 'graphql-tag';

import Dropdown from 'components/Dropdown';
import DropdownButton from 'components/DropdownButton';
import SetFirmName from 'modals/SetFirmName';
import { useMutation } from '@apollo/client';
import produce from 'immer';
import { showError } from 'utils/popups';
import { Firm } from 'interfaces/firm';

const DELETE_GROUP = gql`
  mutation DeleteGroup($groupId: Int!) {
    deleteFirm(firmId: $groupId) {
      success
    }
  }
`;

const UPDATE = gql`
  fragment DeleteGroupUpdate on Workspace {
    id
    groups {
      id
      name
    }
  }
`;

const LEAVE_GROUP = gql`
  mutation LeaveGroup($groupId: Int!) {
    leaveFirm(firmId: $groupId) {
      success
    }
  }
`;

interface GroupSettingsDropdownProps {
  trigger: React.ReactNode;
  workspaceId: number;
  groupId: number;
  empty: boolean;
  canRename: boolean;
  canDelete: boolean;
  canLeave: boolean;
}

const GroupSettingsDropdown: React.FunctionComponent<GroupSettingsDropdownProps> =
  (props) => {
    const {
      trigger,
      workspaceId,
      groupId,
      empty,
      canDelete,
      canRename,
      canLeave,
    } = props;

    const [showRename, setShowRename] = useState(false);

    const [deleteGroup] = useMutation(DELETE_GROUP);
    const [leaveGroup] = useMutation(LEAVE_GROUP);

    const openRename = useCallback(() => setShowRename(true), []);
    const closeRename = useCallback(() => setShowRename(false), []);

    const handleLeave = useCallback(() => {
      if (!canLeave) {
        // eslint-disable-next-line new-cap
        return showError({
          text: 'You cannot leave this group',
        });
      }

      leaveGroup({
        variables: {
          groupId,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          leaveFirm: {
            __typename: 'LeaveFirmResult',
            success: true,
          },
        },
        update: (cache) => {
          const data = cache.readFragment({
            fragment: UPDATE,
            id: `Workspace:${workspaceId}`,
          });

          cache.writeFragment({
            fragment: UPDATE,
            id: `Workspace:${workspaceId}`,
            data: produce(data, (draft: { groups: Firm[] }) => {
              draft.groups = draft.groups.filter((g) => g.id !== groupId);
            }),
          });
        },
        onError: (e) => showError({ text: e.message }),
      });
    }, [canLeave, groupId, leaveGroup, workspaceId]);

    const handleDelete = useCallback(() => {
      if (!empty && canDelete) {
        // eslint-disable-next-line new-cap
        return showError({
          text: 'Folder must be empty to delete',
        });
      }

      deleteGroup({
        variables: {
          groupId,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          deleteFirm: {
            __typename: 'DeleteFirmResult',
            success: true,
          },
        },
        update: (cache) => {
          const data = cache.readFragment({
            fragment: UPDATE,
            id: `Workspace:${workspaceId}`,
          });

          cache.writeFragment({
            fragment: UPDATE,
            id: `Workspace:${workspaceId}`,
            data: produce(data, (draft: { groups: Firm[] }) => {
              draft.groups = draft.groups.filter((g) => g.id !== groupId);
            }),
          });
        },
      });
    }, [empty, canDelete, deleteGroup, groupId, workspaceId]);

    return (
      <>
        <Dropdown trigger={trigger}>
          {canRename && (
            <DropdownButton onClick={openRename}>Rename Group</DropdownButton>
          )}
          {canDelete && (
            <DropdownButton onClick={handleDelete}>Delete Group</DropdownButton>
          )}
          {canLeave && (
            <DropdownButton onClick={handleLeave}>Leave Group</DropdownButton>
          )}
        </Dropdown>
        <SetFirmName
          title="Rename Group"
          show={showRename}
          firmId={groupId}
          onClose={closeRename}
        />
      </>
    );
  };

export default GroupSettingsDropdown;
