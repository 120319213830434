import React from 'react';
import QuickBooksLogo from './QuickbooksLogo';
import XeroLogo from './XeroLogo';
import GustoLogo from './GustoLogo';
import GustoLogoSmall from './GustoLogoSmall';
import QuickBooksLogoSmall from './QuickBooksLogoSmall';
import XeroLogoSmall from './XeroLogoSmall';
import CsvLogoSmall from './CsvLogoSmall';
import MyobLogo from './MyobLogo';

export const integrationToLogo = {
  QB: { lg: <QuickBooksLogo />, sm: <QuickBooksLogoSmall /> },
  QBO: { lg: <QuickBooksLogo />, sm: <QuickBooksLogoSmall /> },
  QBD: { lg: <QuickBooksLogo />, sm: <QuickBooksLogoSmall /> },
  XERO: { lg: <XeroLogo height={30} />, sm: <XeroLogoSmall /> },
  GUSTO: { lg: <GustoLogo height={23} width={60} />, sm: <GustoLogoSmall /> },
  CSV: { lg: 'CSV', sm: <CsvLogoSmall /> },
  SUMMIT: { lg: 'Summit', sm: 'Summit' },
  MYOB: { lg: <MyobLogo width={70} />, sm: <MyobLogo /> },
};

export const integrationToName = {
  QB: 'QuickBooks',
  XERO: 'Xero',
  GUSTO: 'Gusto',
  CSV: 'CSV',
  SUMMIT: 'SUMMIT',
  MYOB: 'MYOB',
};

export const integrationIdToName = {
  1: 'QB',
  2: 'XERO',
  3: 'COMPANY_INFO',
  4: 'SUMMIT',
  5: 'CSV',
  6: 'GOOGLE_SHEETS',
  7: 'ACS',
  8: 'GUSTO',
  12: 'MYOB',
};

export const integrationToId = {
  QB: 1,
  XERO: 2,
  COMPANY_INFO: 3,
  SUMMIT: 4,
  CSV: 5,
  GOOGLE_SHEETS: 6,
  ACS: 7,
  GUSTO: 8,
  EXCHANGE_RATES: 10,
  MYOB: 12,
};
