/**
 *
 * useConsolidationType
 *
 */
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useMemo } from 'react';

const GET_INTEGRATION_TYPE = gql`
  query getConsolidationIntegrationType($consolidationId: Int!) {
    getConsolidationIntegrationType(consolidationId: $consolidationId) {
      id
      accountingIntegration
    }
  }
`;

interface useConsolidationTypeOptions {
  companyId: number;
}

const useConsolidationType: React.FC<useConsolidationTypeOptions> = (
  companyId
) => {
  const { data: typeData } = useQuery(GET_INTEGRATION_TYPE, {
    variables: {
      consolidationId: companyId,
    },
    skip: !companyId,
  });

  return useMemo(() => {
    if (!typeData) {
      return null;
    }

    return (
      typeData.getConsolidationIntegrationType?.accountingIntegration || null
    );
  }, [typeData]);
};

export default useConsolidationType;
