/**
 *
 * NumberInput
 *
 */
import React from 'react';
import styled from 'styled-components';
import 'rc-input-number/assets/index.css';
import InputNumber from 'rc-input-number';
import theme from 'styled-theming';

interface NumberInputProps {
  min?: number;
  max?: number;
  value: number;
  name?: string;
  onChange: (string) => void;
  className?: string;
  precision?: number;
  style?: React.CSSProperties;
  scale?: number;
  disabled?: boolean;
}

const arrowColor = theme('mode', {
  dark: 'hsla(0,0%,100%,.8)',
  light: '#666666',
});
const inputColor = theme('mode', {
  dark: 'hsla(0,0%,100%,.8)',
  light: '#666666',
});

const Span = styled.span<{ scale: number }>`
  display: inline-block;
  & .rc-input-number {
    height: ${({ scale }) => scale * 2}rem;
    min-height: 1.5rem;
  }
  & .rc-input-number-handler {
    height: ${({ scale }) => scale}rem;
    min-height: 0.75rem;
  }
  & .rc-input-number-handler-up-inner,
  & .rc-input-number-handler-down-inner {
    color: ${arrowColor};
  }
  && input {
    background: transparent;
    color: ${inputColor};
    font-size: ${({ scale }) => scale * 100}%;
    vertical-align: top;
  }
`;

const NumberInput: React.FunctionComponent<NumberInputProps> = (props) => {
  const { className, scale = 1, ...rest } = props;
  return (
    <Span className={className} scale={scale}>
      <InputNumber {...rest} />
    </Span>
  );
};

export default NumberInput;
