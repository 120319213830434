/**
 *
 * NameModal
 *
 */
import React from 'react';
import styled from 'styled-components';
import { Form } from 'informed';

import Modal from 'components/Modal';
import TextField from 'components/TextField';
import Button from 'components/Button';

import { isResourceName } from 'validation';

const Inner = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
`;

const ErrorDiv = styled.div`
  color: ${(props) => props.theme.colors.danger};
  font-size: 13px;
`;

interface NameModalProps {
  marginTop?: string;
  title: string;
  defaultName?: string;
  show: boolean;
  onClose: () => void;
  onSubmit: ({ name: string }) => void;
  loading?: boolean;
}

const NameModal: React.FunctionComponent<NameModalProps> = ({
  marginTop = '100px',
  onClose,
  show,
  title,
  onSubmit,
  defaultName,
  loading,
}) => {
  return (
    <Modal
      show={show}
      title={title}
      onClose={onClose}
      width={500}
      marginTop={marginTop}
      zIndex={1005}
    >
      <Form
        id="name-modal"
        onSubmit={onSubmit}
        initialValues={{ name: defaultName }}
      >
        {({ formState, formApi }) => (
          <div>
            <Inner>
              <TextField
                id="dashboard-form-name"
                type="text"
                field="name"
                autoFocus
                validate={isResourceName}
              />
              <StyledButton
                type="button"
                color="primary"
                loading={loading}
                disabled={loading}
                onClick={formApi.submitForm}
              >
                Save
              </StyledButton>
            </Inner>
            <ErrorDiv>{formState.errors.name || null}</ErrorDiv>
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default NameModal;
