import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

const QUERY = gql`
  query DemoCompany {
    currentUser {
      demoCompany {
        id
      }
    }
  }
`;

const LAUNCH = gql`
  mutation UseLaunchDemo($companyId: Int!) {
    setCompany(companyId: $companyId) @client
  }
`;

export default function useDemoCompany() {
  const { data, loading } = useQuery(QUERY);
  const history = useHistory();

  const [launchDemo] = useMutation(LAUNCH);

  return useMemo(() => {
    if (data && data.currentUser && data.currentUser.demoCompany) {
      return {
        launchDemo: () =>
          launchDemo({
            variables: {
              companyId: data.currentUser.demoCompany.id,
            },
          }).then(() => history.push('/')),
        loading,
      };
    }

    return { launchDemo: null, loading };
  }, [data, history, launchDemo, loading]);
}
