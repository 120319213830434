import SyncIcon from 'components/Icons/SyncIcon';
import Button from 'components/Button';
import Alert from 'components/Alert';
import React, { useCallback, useState } from 'react';
import { QueryResult, useMutation } from '@apollo/client';
import { RESEND_VERIFICATION_EMAIL } from './queriesAndMutations';
import useTimer from 'hooks/useTimer';
import { EmailVerification } from './styledComponents';
import {
  TwoFactorAuthQuery,
  TwoFactorAuthQueryVariables,
} from '__generated__/graphql';

interface Props {
  verifiedEmail: TwoFactorAuthQuery['currentUser']['verifiedEmail'];
  disable: boolean;
  refetch: QueryResult<
    TwoFactorAuthQuery,
    TwoFactorAuthQueryVariables
  >['refetch'];
}

const VerifyEmail = ({
  verifiedEmail,
  disable,
  refetch,
}: Props): React.ReactElement => {
  const { timeLeft, setTimer } = useTimer();
  const [refreshed, setRefreshed] = useState(false);
  const [emailResent, setEmailResent] = useState(false);

  const [resendVerificationEmail] = useMutation(RESEND_VERIFICATION_EMAIL);

  // when button with SyncIcon in it is pressed start a cooldown timer that disables the button for 10 seconds
  const handleRefresh = useCallback(() => {
    refetch();
    setRefreshed(true);
    setTimer(10);
  }, [refetch, setTimer]);

  return (
    <EmailVerification>
      <p>
        Please verify your email address before setting up two-factor
        authentication.
      </p>
      {refreshed && !verifiedEmail && (
        <Alert color="danger" outline>
          Email has not yet been verified. Please check your email and verify.
        </Alert>
      )}
      <Button
        color="info"
        disabled={disable || emailResent}
        onClick={() => {
          if (!disable) {
            resendVerificationEmail();
            setEmailResent(true);
          }
        }}
      >
        Resend Verification Email
      </Button>
      {emailResent && (
        <Button
          color="info"
          onClick={handleRefresh}
          disabled={timeLeft !== 0}
          divContainer
        >
          <SyncIcon width={15} />
          {timeLeft !== 0 && <span>{timeLeft}</span>}
        </Button>
      )}
    </EmailVerification>
  );
};

export default VerifyEmail;
