import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';

import Modal from 'components/Modal';
import { useForm } from 'react-hook-form';
import gql from 'graphql-tag';
import Spinner from 'components/Spinner';
import { captureException } from 'utils/sentry';
import useCompanyId from 'hooks/useCompanyId';
import VerticalTabs from 'components/VerticalTabs';
import { showError, showInfo, showSuccess } from 'utils/popups';
import Swal from 'swal';

import GeneralSettings from './GeneralSettings';
import ReportSettings from './ReportSettings';
import LabelSettings from './LabelsSettings';

import { ToggleVisible, Col, MenuDiv } from './styledComponents';
import useBookMonth from 'hooks/useBookMonth';
import moment, { Moment } from 'moment';
import { useLocation } from 'react-router';
import { FINANCIAL_LABELS } from 'fragments';
import useSupportedStatements from 'hooks/useSupportedStatements';
import usePrevious from 'hooks/usePrevious';
import useConsolidationType from 'hooks/useConsolidationType';
import IntegrationSettings from './IntegrationSettings';
import useIntegrationTypes from 'hooks/useIntegrationTypes';
import HideScrollbar from 'components/HideScrollbar';
import useWorkspaceId from 'hooks/useWorkspaceId';
import { S3Response } from 'react-s3-uploader';
import uploadImageToS3 from 'utils/uploadImageToS3';
import { MAX_FILE_SIZE } from 'components/LogoUploader';
import useSaveLogo from 'hooks/useSaveLogo';
import BankMappings from './BankMappings';
import AiSettings from './AiSettings';
import EarningsBeforeSettings, {
  EarningsBeforeState,
} from './EarningsBeforeSettings';

const Container = styled.div`
  height: 100%;
`;

const Row = styled.div`
  display: flex;
  height: 100%;
`;

const Div = styled.div`
  height: 100%;
`;

const StyledTabs = styled(VerticalTabs)`
  div {
    margin-bottom: unset;
  }
`;

const QUERY = gql`
  query CompanySettings($companyId: Int!) {
    company(id: $companyId) {
      id
      firmId
      name
      nameOverride
      originalName
      preparedBy
      disclaimer
      colors
      customColors
      betaTestMode
      developerCompany
      currency
      showAccountNumbers
      sortWithAccountNumbers
      hideInactiveAccounts
      lastUpdatedThreshold
      accountingIntegration
      defaultBudgetId
      defaultBudgetType
      defaultForecastId
      defaultForecastType
      bookMonth
      defaultBookMonth
      isBookMonthFixed
      forecastSuffix
      hideActualsAfterBm
      useLogoDefault
      useThemeDefault
      agingBookMonth
      aiEnabled
      aiUsage {
        tokensSent
        tokensReceived
        percentageUsed
      }
      canDisconnectCompany: hasPermission(
        permission: DISCONNECT_COMPANY
        firmPermission: MANAGE_COMPANY
      )
      accounting {
        canToggleAccountNumbers
        hasAccountingBasis
        accountingBasis
        supportedFinancialLabels
        integration
        bankAccounts(useOriginalTypes: true) {
          id
          name
          bankAccountType
        }
        bankAccountMappings {
          accountId
          bankAccountType
        }
      }
      logo {
        id
        url
      }
      financialLabels {
        ...FinancialLabelFields
      }
      isConsolidation
      demo
      demoDataRefreshDate
      threeWayBudgetInfo {
        allIncomeItems {
          identifier
          itemType
          name
          accountNumber
          group
        }
        allExpenseItems {
          identifier
          itemType
          name
          accountNumber
          group
        }
      }
      ebitdaSettings {
        taxes {
          identifier
          itemType
        }
        interestExpense {
          identifier
          itemType
        }
        interestIncome {
          identifier
          itemType
        }
        depreciationAmortization {
          identifier
          itemType
        }
      }
    }
  }
  ${FINANCIAL_LABELS}
`;

const SAVE = gql`
  mutation SetCompanySettings(
    $companyId: Int!
    $settings: CompanySettingsInput!
    $financialLabels: FinancialLabelsInput!
    $bookMonth: Date
    $accountingBasis: AccountingBasis
    $colors: [String!]!
    $customColors: [String!]!
    $betaTestMode: Boolean!
    $forecastSuffixOverride: Boolean
    $hideActualsAfterBm: Boolean
    $useLogoDefault: Boolean
    $useThemeDefault: Boolean
    $agingBookMonth: Boolean
    $bankAccountMappings: [BankAccountMappingInput!]
    $aiEnabled: Boolean
    $ebitdaSettings: EbitdaSettingsInput
  ) {
    setCompanySettings(
      companyId: $companyId
      settings: $settings
      financialLabels: $financialLabels
      bookMonth: $bookMonth
      accountingBasis: $accountingBasis
      colors: $colors
      customColors: $customColors
      betaTestMode: $betaTestMode
      forecastSuffixOverride: $forecastSuffixOverride
      hideActualsAfterBm: $hideActualsAfterBm
      useLogoDefault: $useLogoDefault
      useThemeDefault: $useThemeDefault
      agingBookMonth: $agingBookMonth
      bankAccountMappings: $bankAccountMappings
      aiEnabled: $aiEnabled
      ebitdaSettings: $ebitdaSettings
    ) {
      id
      name
      nameOverride
      forecastSuffix
      preparedBy
      disclaimer
      colors
      customColors
      currency
      showAccountNumbers
      sortWithAccountNumbers
      hideInactiveAccounts
      lastUpdatedThreshold
      bookMonth
      defaultBookMonth
      defaultBudgetId
      defaultBudgetType
      defaultForecastId
      defaultForecastType
      isBookMonthFixed
      hideActualsAfterBm
      useLogoDefault
      useThemeDefault
      agingBookMonth
      aiEnabled
      accounting {
        hasAccountingBasis
        accountingBasis
        bankAccountMappings {
          accountId
          bankAccountType
        }
      }
      financialLabels {
        ...FinancialLabelFields
      }
      ebitdaSettings {
        taxes {
          identifier
          itemType
        }
        interestExpense {
          identifier
          itemType
        }
        interestIncome {
          identifier
          itemType
        }
        depreciationAmortization {
          identifier
          itemType
        }
      }
      betaTestMode
    }
  }
  ${FINANCIAL_LABELS}
`;

const SET_SEEN = gql`
  mutation SetSettingsSeen($companyId: Int!) {
    setCompanySettingsSeen(companyId: $companyId) {
      id
      settingsSeen
    }
  }
`;

const WORKSPACE_QUERY = gql`
  query CompanyWorkspaceSettings($workspaceId: Int!) {
    workspace(id: $workspaceId) {
      id
      name
      canAccessWorkspaceSettings: hasPermission(action: CHANGE_WORKSPACE_NAME)
      defaultLogoUrl
      defaultTheme
      defaultThemeCustom
      defaultLogoAndThemeSaved
      aiEnabled
    }
  }
`;

const defaultDisclaimer =
  'The information contained in this report is provided for informational purposes only and is not intended to substitute for obtaining accounting, ' +
  'tax, or financial advice from a professional accountant. Any tax advice contained in this report is not intended to be used for the purpose of ' +
  'avoiding penalties under tax law. While we use reasonable efforts to furnish accurate and up-to-date information, we do not warrant that any ' +
  'information contained in or made available through this report is accurate, complete, reliable, current or error-free. We assume no liability or ' +
  'responsibility for any errors or omissions in the content of this report or delivered information.';

const defaultColorState = { colors: [], customColors: [] };

interface Props {
  companyId?: number;
  show: boolean;
  onClose?: () => void;
  onSave?: () => void;
  showHelp?: boolean;
  groups?: boolean;
  initialTab?: string;
}

// eslint-disable-next-line react/display-name
const CompanySettingsModal = memo<Props>(function CompanySettingsModalInner({
  companyId: _companyId,
  show,
  onClose,
  onSave,
  showHelp = false,
  groups = false,
  initialTab = 'general',
}) {
  const location = useLocation();

  const openCompanyId = useCompanyId();
  const consolidationType = useConsolidationType(openCompanyId);
  const companyId = openCompanyId || _companyId;
  const { bookMonth, isBookMonthFixed } = useBookMonth(companyId);
  const { supportedStatements } = useSupportedStatements(companyId);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [bookMonthState, setBookMonth] = useState<Moment | null>(null);
  const [newLogo, setNewLogo] = useState<File | null>(null);
  const [bookMonthFixedState, setBookMonthFixed] = useState<boolean | null>(
    null
  );
  const [defaultBudgetState, setDefaultBudgetState] = useState<string | null>(
    null
  );
  const [defaultForecastState, setDefaultForecastState] = useState<
    string | null
  >(null);
  const [earningsBeforeState, setEarningsBeforeState] =
    useState<EarningsBeforeState>({
      taxes: [],
      interestExpense: [],
      interestIncome: [],
      depreciationAmortization: [],
    });
  const [accountingBasis, setAccountingBasis] = useState(null);
  const [colorState, setColorState] = useState(defaultColorState);
  const [bankAccountMappings, setBankAccountMappings] = useState<
    Array<{ accountId: string; bankAccountType: string }>
  >([]);
  const [bankAccountMappingsTouched, setBankAccountMappingsTouched] =
    useState<boolean>(false);
  const [aiEnabledState, setAiEnabled] = useState(false);

  const workspaceId = useWorkspaceId();

  const { colors, customColors } = colorState;

  const { data, loading } = useQuery(QUERY, {
    variables: {
      companyId,
    },
    skip: !show || !companyId,
  });

  const isQBD = useMemo(() => {
    return data?.company?.accounting?.integration === 'QBD';
  }, [data]);

  const { data: workspaceData } = useQuery(WORKSPACE_QUERY, {
    variables: {
      workspaceId,
    },
    skip: !workspaceId,
  });

  const { accountingIntegration, payrollIntegration } =
    useIntegrationTypes(companyId);

  const defaultBudgetValue = useMemo(() => {
    if (data && data.company) {
      return `${data.company.defaultBudgetType}_${data.company.defaultBudgetId}`;
    }
    return null;
  }, [data]);

  const defaultForecastValue = useMemo(() => {
    if (data && data.company) {
      return `${data.company.defaultForecastType}_${data.company.defaultForecastId}`;
    }
    return null;
  }, [data]);

  const prevDefaultBudgetId = usePrevious(defaultBudgetValue);
  const prevDefaultForecastId = usePrevious(defaultForecastValue);
  useEffect(() => {
    if (prevDefaultBudgetId && defaultBudgetValue) {
      setDefaultBudgetState(defaultBudgetValue);
    }
  }, [defaultBudgetValue, prevDefaultBudgetId]);

  useEffect(() => {
    if (prevDefaultForecastId && defaultForecastValue) {
      setDefaultForecastState(defaultForecastValue);
    }
  }, [defaultForecastValue, prevDefaultForecastId]);

  const onChangeColors = useCallback(
    (value) => {
      setColorState({
        colors: value,
        customColors,
      });
      setUnsavedChanges(true);
    },
    [customColors]
  );
  const onChangeCustomColors = useCallback((value) => {
    setColorState({
      colors: value,
      customColors: value,
    });
    setUnsavedChanges(true);
  }, []);

  useEffect(() => {
    setBookMonth(bookMonth);
    setBookMonthFixed(isBookMonthFixed);
  }, [bookMonth, isBookMonthFixed]);

  const prevShow = usePrevious(show);
  useEffect(() => {
    if (!prevShow && show && bookMonth) {
      setBookMonth(bookMonth);
      setBookMonthFixed(isBookMonthFixed);
    }
    if (!prevShow && show) {
      setBankAccountMappingsTouched(false);
    }
  }, [bookMonth, isBookMonthFixed, prevShow, show]);

  const onChangeBookMonth = useCallback((newValue) => {
    setBookMonth(newValue.clone().add(1, 'month'));
    setBookMonthFixed(true);
    setUnsavedChanges(true);
  }, []);

  const onRevertBookMonth = useCallback(() => {
    if (!data && !data.company) return;

    setBookMonth(moment.utc(data.company.defaultBookMonth));
    setBookMonthFixed(false);
    setUnsavedChanges(true);
  }, [data]);

  const supportedFinancialLabels = useMemo(
    () =>
      (data &&
        data.company &&
        data.company.accounting &&
        data.company.accounting.supportedFinancialLabels) ||
      [],
    [data]
  );

  const showGeneral = 'general';
  const showReport = 'report';
  const showLabels = supportedFinancialLabels.length ? 'statementLabels' : null;
  const showIntegrations = 'integrations';
  const showBankMapping = 'bankMapping';

  const [tab, setTab] = useState(initialTab);
  const {
    register,
    watch,
    reset,
    getValues,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const disclaimerValue = watch('disclaimer');
  const countryValue = watch('currency');
  const showAccountNumbersValue = watch('showAccountNumbers');

  useEffect(() => {
    if (data?.company?.accounting?.accountingBasis) {
      setAccountingBasis(data.company.accounting.accountingBasis);
    }
    if (data?.company?.colors) {
      setColorState({
        colors: data.company.colors,
        customColors: data.company.customColors,
      });
    }
    if (
      !data?.company?.isConsolidation &&
      data?.company?.accounting?.bankAccountMappings &&
      accountingIntegration === 'XERO'
    ) {
      const mappings = data.company.accounting.bankAccountMappings
        .filter((bankAccountMapping) => {
          return data.company.accounting.bankAccounts.find(
            (bankAccount) => bankAccount.id === bankAccountMapping.accountId
          );
        })
        .map((mapping) => ({
          accountId: mapping.accountId,
          bankAccountType: mapping.bankAccountType,
        }));

      // add missing
      data.company.accounting.bankAccounts.forEach((bankAccount) => {
        if (!mappings.find((m) => m.accountId === bankAccount.id)) {
          mappings.push({
            accountId: bankAccount.id,
            bankAccountType: bankAccount.bankAccountType,
          });
        }
      });

      setBankAccountMappings(mappings);
    }
    if (data?.company?.aiEnabled !== undefined) {
      setAiEnabled(data.company.aiEnabled || false);
    }
    if (data?.company?.ebitdaSettings) {
      setEarningsBeforeState({
        taxes: data.company.ebitdaSettings.taxes,
        interestExpense: data.company.ebitdaSettings.interestExpense,
        interestIncome: data.company.ebitdaSettings.interestIncome,
        depreciationAmortization:
          data.company.ebitdaSettings.depreciationAmortization,
      });
    }
  }, [accountingIntegration, consolidationType, data]);

  const onChangeAccountingBasis = useCallback((value) => {
    setAccountingBasis(value);
    setUnsavedChanges(true);
  }, []);

  const [save, { loading: saving }] = useMutation(SAVE);
  const { save: saveLogo } = useSaveLogo();
  const [setSeen] = useMutation(SET_SEEN, {
    variables: {
      companyId,
    },
  });

  const handleChangeLogo = (files: File[]) => {
    setNewLogo(files[0]);
    setUnsavedChanges(true);
  };

  useEffect(() => {
    if (data && data.company && companyId) {
      reset({
        nameOverride: data.company.nameOverride || data.company.name,
        forecastSuffix: data.company.forecastSuffix,
        preparedBy: data.company.preparedBy,
        disclaimer: data.company.disclaimer,
        financialLabels: data.company.financialLabels,
        currency: data.company.currency,
        betaTestMode: data.company.betaTestMode,
        showAccountNumbers: data.company.showAccountNumbers,
        sortWithAccountNumbers: data.company.sortWithAccountNumbers,
        hideInactiveAccounts: data.company.hideInactiveAccounts,
        lastUpdatedThreshold: data.company.lastUpdatedThreshold,
        hideActualsAfterBm: data.company.hideActualsAfterBm,
        useLogoDefault: data.company.useLogoDefault,
        useThemeDefault: data.company.useThemeDefault,
        agingBookMonth: data.company.agingBookMonth,
      });
    }
  }, [companyId, data, reset]);

  const handleClose = useCallback(
    async (revertValues = false) => {
      try {
        if (showHelp) {
          await setSeen();
        }

        setTab('general');
        setNewLogo(null);
        setUnsavedChanges(false);

        if (revertValues) {
          setDefaultBudgetState(defaultBudgetValue);
          setDefaultForecastState(defaultForecastValue);
        }

        onClose && onClose();
      } catch (err) {
        captureException(err, true);
      }
    },
    [defaultBudgetValue, defaultForecastValue, onClose, setSeen, showHelp]
  );

  const onFinishLogoUpload = useCallback(
    async (result: S3Response, file: File, hmac: string) => {
      setNewLogo(null);
      try {
        const url = result.signedUrl.slice(0, result.signedUrl.indexOf('?'));
        await saveLogo(companyId, url, hmac);
      } catch (err) {
        captureException(err, true);
      }
    },
    [companyId, saveLogo]
  );

  const onLogoUploadError = () => {
    showError({
      title: 'Oops!',
      text: 'Something went wrong while uploading your logo. Please try again.',
    });
    setNewLogo(null);
  };

  const handleSave = useCallback(async () => {
    if (!data || !data.company) return;

    try {
      const {
        nameOverride,
        forecastSuffix,
        preparedBy,
        disclaimer,
        labels,
        currency,
        showAccountNumbers,
        sortWithAccountNumbers,
        hideInactiveAccounts,
        lastUpdatedThreshold,
        betaTestMode,
        forecastSuffixOverride,
        hideActualsAfterBm,
        useLogoDefault,
        useThemeDefault,
        agingBookMonth = false,
      } = getValues();

      const newBookMonth = bookMonthFixedState ? bookMonthState : null;

      let [budgetType, budgetId] =
        typeof defaultBudgetState === 'string'
          ? defaultBudgetState.split('_')
          : [null, null];

      if (budgetType === 'null') budgetType = null;
      if (budgetId === 'null') budgetId = null;

      let [forecastType, forecastId] =
        typeof defaultForecastState === 'string'
          ? defaultForecastState.split('_')
          : [null, null];

      if (forecastType === 'null') forecastType = null;
      if (forecastId === 'null') forecastId = null;

      const financialLabels = {};

      if (labels) {
        Object.keys(labels).forEach((key) => {
          const label = labels[key].trim();
          if (label !== '') financialLabels[key] = label;
          else financialLabels[key] = null;
        });
      }

      const currencyChangedText =
        currency !== data.company.currency
          ? ' Consolidated companies with metrics using exchange rates data may need to be remapped.'
          : '';

      const bankAccountMappingsToSave =
        accountingIntegration === 'XERO' &&
        bankAccountMappingsTouched &&
        !data?.company?.isConsolidation
          ? bankAccountMappings
          : null;

      await save({
        variables: {
          companyId,
          settings: {
            nameOverride: nameOverride.trim(),
            forecastSuffix: forecastSuffix ? forecastSuffix.trim() : '',
            preparedBy: preparedBy ? preparedBy.trim() : '',
            disclaimer: disclaimer.trim() || defaultDisclaimer,
            currency,
            showAccountNumbers,
            sortWithAccountNumbers,
            hideInactiveAccounts,
            lastUpdatedThreshold,
            defaultBudgetId: budgetId,
            defaultBudgetType: budgetType,
            defaultForecastId: forecastId,
            defaultForecastType: forecastType,
          },
          financialLabels,
          bookMonth: newBookMonth,
          accountingBasis,
          colors,
          customColors,
          betaTestMode,
          forecastSuffixOverride,
          hideActualsAfterBm: hideActualsAfterBm || false,
          useLogoDefault: useLogoDefault || false,
          useThemeDefault: useThemeDefault || false,
          agingBookMonth: agingBookMonth || false,
          bankAccountMappings: bankAccountMappingsToSave,
          aiEnabled: aiEnabledState || false,
          ebitdaSettings: {
            taxes: earningsBeforeState.taxes.map((item) => ({
              identifier: item.identifier,
              itemType: item.itemType,
            })),
            interestExpense: earningsBeforeState.interestExpense.map(
              (item) => ({
                identifier: item.identifier,
                itemType: item.itemType,
              })
            ),
            interestIncome: earningsBeforeState.interestIncome.map((item) => ({
              identifier: item.identifier,
              itemType: item.itemType,
            })),
            depreciationAmortization:
              earningsBeforeState.depreciationAmortization.map((item) => ({
                identifier: item.identifier,
                itemType: item.itemType,
              })),
          },
        },
      });

      if (newLogo) {
        uploadImageToS3({
          file: newLogo,
          onFinish: onFinishLogoUpload,
          onError: onLogoUploadError,
          maxFileSize: MAX_FILE_SIZE,
        });
      }

      onSave && onSave();
      if (bankAccountMappingsToSave) {
        showInfo({
          title: 'Changes Saved',
          text: `Please sync this company for the changes to take effect.`,
        });
      } else if (openCompanyId && location.pathname.includes('/report/')) {
        showInfo({
          title: 'Changes Saved',
          text: `If in a report, refresh your browser to have the changes applied.${currencyChangedText}`,
        });
      } else if (
        showAccountNumbers !== null &&
        showAccountNumbers !== data.company.showAccountNumbers &&
        !location.pathname.includes('/workspaces')
      ) {
        showInfo({
          title: 'Changes Saved',
          text: `Refresh your browser to have the changes applied.${currencyChangedText}`,
        });
      } else if (
        data.company.accounting &&
        data.company.accounting.accountingBasis !== accountingBasis
      ) {
        showInfo({
          title: 'Changes Saved',
          text: `Refresh your browser to have the changes applied.${currencyChangedText}`,
        });
      } else if (currency !== data.company.currency) {
        showInfo({
          title: 'Changes Saved',
          text: `Refresh your browser to have the changes applied.${currencyChangedText}`,
        });
      } else {
        showSuccess({
          title: 'Company Settings Updated!',
          showConfirmButton: false,
          timer: 1500,
        });
      }

      if (agingBookMonth !== data.company.agingBookMonth) {
        showInfo({
          title: 'Changes Saved',
          text: `You will need to sync this company for AR/AP aging data to reflect new setting.`,
        });
      }

      handleClose();
    } catch (err) {
      captureException(err, true);
    }
  }, [
    data,
    getValues,
    bookMonthFixedState,
    bookMonthState,
    defaultBudgetState,
    defaultForecastState,
    accountingIntegration,
    bankAccountMappingsTouched,
    bankAccountMappings,
    save,
    companyId,
    accountingBasis,
    colors,
    customColors,
    aiEnabledState,
    earningsBeforeState,
    newLogo,
    onSave,
    openCompanyId,
    location.pathname,
    handleClose,
    onFinishLogoUpload,
  ]);

  const onSubmit = useMemo(
    () => handleSubmit(handleSave),
    [handleSave, handleSubmit]
  );

  const handleUnsavedChanges = useCallback(() => {
    setUnsavedChanges(true);
  }, [setUnsavedChanges]);

  const checkUnsaved = useCallback(() => {
    if (unsavedChanges) {
      Swal.fire({
        title: 'Unsaved Changes',
        text: 'Are you sure you want to close without saving?',
        showConfirmButton: true,
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: 'Save',
        cancelButtonText: `Don't Save`,
        confirmButtonColor: '#22BAA0',
        cancelButtonColor: '#617182',
        customClass: {
          container: 'custom-position-container',
          popup: 'custom-position-popup',
        },
        showClass: {
          popup: 'fade-in',
        },
        hideClass: {
          popup: 'fade-out',
        },
      }).then((results) => {
        if (results.value === true) {
          onSubmit();
        } else if (results.dismiss === Swal.DismissReason.cancel)
          handleClose(true);
      });
    } else handleClose(true);
  }, [handleClose, onSubmit, unsavedChanges]);

  const buttons = useMemo(
    () => [
      {
        color: 'default',
        outline: true,
        text: 'Cancel',
        onClick: checkUnsaved,
      },
      {
        color: 'success',
        text: 'Save',
        onClick: onSubmit,
        loading: saving,
        disabled: saving,
        className: 'company-settings-success-button',
      },
    ],
    [checkUnsaved, onSubmit, saving]
  );

  const canToggleAccountNumbers = useMemo(
    () =>
      (data &&
        data.company &&
        data.company.accounting &&
        data.company.accounting.canToggleAccountNumbers) ||
      false,
    [data]
  );

  const tabs = useMemo(() => {
    const showPreparedWarning =
      !!data && !!data.company && data.company.preparedBy === 'Reach Reporting';

    const showGeneralWarning =
      !!data &&
      !!data.company &&
      !data.company.defaultBudgetId &&
      !!accountingIntegration;

    const result: {
      id: string;
      label: string;
      icon?: string;
      iconColor?: string;
      iconStyle?: React.CSSProperties;
    }[] = [
      {
        id: 'general',
        label: 'General Settings',
        icon: showGeneralWarning ? 'mdi-set mdi-alert' : undefined,
        iconColor: showGeneralWarning ? 'warning' : undefined,
        iconStyle: {
          marginLeft: 14,
        },
      },
      {
        id: 'report',
        label: 'Report Settings',
        icon: showPreparedWarning ? 'mdi-set mdi-alert' : undefined,
        iconColor: showPreparedWarning ? 'warning' : undefined,
        iconStyle: {
          marginLeft: 23,
        },
      },
    ];

    if (supportedFinancialLabels.length) {
      result.push({
        id: 'statementLabels',
        label: 'Financial Statement Labels',
      });
    }

    if (accountingIntegration === 'XERO' && !data?.company?.isConsolidation) {
      result.push({
        id: 'bankMapping',
        label: 'Bank/Credit Card Mapping',
      });
    }

    if (
      accountingIntegration !== 'SUMMIT' &&
      !data?.company?.isConsolidation &&
      data?.company?.accountingIntegration
    )
      result.push({
        id: 'earningsBefore',
        label: 'EBIT/EBITDA',
      });

    if (
      accountingIntegration !== 'SUMMIT' &&
      accountingIntegration !== 'ACS' &&
      !data?.company?.isConsolidation &&
      !data?.company?.demo
    )
      result.push({
        id: 'integrations',
        label: 'Integrations',
      });

    if (workspaceData?.workspace?.aiEnabled || data?.company?.demo) {
      result.push({
        id: 'ai',
        label: 'AI Enhanced Reporting',
      });
    }

    return result;
  }, [
    accountingIntegration,
    data,
    supportedFinancialLabels.length,
    workspaceData?.workspace?.aiEnabled,
  ]);

  const connectedIntegrations = useMemo(
    () => ({
      bookkeeping: accountingIntegration,
      gusto: payrollIntegration === 'GUSTO',
    }),
    [accountingIntegration, payrollIntegration]
  );

  const handleFormKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter' && e.target instanceof HTMLInputElement) {
      e.preventDefault();
    }
  };

  return (
    <Modal
      title="Company Settings"
      show={show}
      buttons={buttons}
      width={770}
      fullscreen
    >
      {show ? <HideScrollbar /> : <></>}
      <Div>
        {loading ||
        !data ||
        !data.company ||
        saving ||
        defaultBudgetState === null ||
        defaultForecastState === null ? (
          <Div>
            <Spinner />
          </Div>
        ) : (
          <Container>
            <Row>
              <MenuDiv>
                <StyledTabs tabs={tabs} current={tab} onChange={setTab} />
              </MenuDiv>
              <Col size={2}>
                <form
                  onSubmit={handleSubmit(handleSave)}
                  onKeyDown={handleFormKeyDown}
                >
                  <ToggleVisible visible={tab === showGeneral}>
                    <GeneralSettings
                      show={show}
                      groups={groups}
                      defaultBudgetState={defaultBudgetState}
                      onChangeDefaultBudgetState={setDefaultBudgetState}
                      defaultForecastState={defaultForecastState}
                      onChangeDefaultForecastState={setDefaultForecastState}
                      companyId={companyId}
                      companyName={data.company.originalName}
                      isConsolidation={data.company.isConsolidation}
                      currency={data.company.currency}
                      colors={colors}
                      customColors={customColors}
                      developerCompany={data.company.developerCompany}
                      logo={data.company.logo}
                      showHelp={showHelp}
                      register={register}
                      control={control}
                      countryValue={countryValue}
                      bookMonth={bookMonthState}
                      isBookMonthFixed={bookMonthFixedState}
                      handleUnsavedChanges={handleUnsavedChanges}
                      onChange={handleChangeLogo}
                      onChangeBookMonth={onChangeBookMonth}
                      onRevertBookMonth={onRevertBookMonth}
                      accountingBasis={accountingBasis}
                      accountingIntegration={accountingIntegration}
                      integration={
                        data.company.accounting &&
                        data.company.accounting.integration
                      }
                      onChangeAccountingBasis={onChangeAccountingBasis}
                      betaTestMode={data.company.betaTestMode}
                      hasAccountingBasis={
                        data &&
                        data.company &&
                        data.company.accounting &&
                        data.company.accounting.hasAccountingBasis
                      }
                      onChangeColors={onChangeColors}
                      onChangeCustomColors={onChangeCustomColors}
                      showAccountNumbers={data.company.showAccountNumbers}
                      showAccountNumbersValue={showAccountNumbersValue}
                      sortWithAccountNumbers={
                        data.company.sortWithAccountNumbers
                      }
                      hideInactiveAccounts={data.company.hideInactiveAccounts}
                      lastUpdatedThreshold={data.company.lastUpdatedThreshold}
                      canToggleAccountNumbers={canToggleAccountNumbers}
                      defaultLogoUrl={
                        workspaceData &&
                        workspaceData.workspace &&
                        workspaceData.workspace.defaultLogoUrl
                      }
                      defaultTheme={
                        workspaceData &&
                        workspaceData.workspace &&
                        workspaceData.workspace.defaultTheme
                      }
                      defaultThemeCustom={
                        workspaceData &&
                        workspaceData.workspace &&
                        workspaceData.workspace.defaultThemeCustom
                      }
                      defaultLogoAndThemeSaved={
                        workspaceData &&
                        workspaceData.workspace &&
                        workspaceData.workspace.defaultLogoAndThemeSaved
                      }
                      useLogoDefault={
                        data && data.company && data.company.useLogoDefault
                      }
                      useThemeDefault={
                        data && data.company && data.company.useThemeDefault
                      }
                      demo={data?.company?.demo || false}
                      demoDataRefreshDate={data?.company?.demoDataRefreshDate}
                    />
                  </ToggleVisible>
                  <ToggleVisible visible={tab === showReport}>
                    <ReportSettings
                      errors={errors}
                      disclaimer={disclaimerValue}
                      register={register}
                      handleUnsavedChanges={handleUnsavedChanges}
                      control={control}
                    />
                  </ToggleVisible>
                  <ToggleVisible visible={tab === showLabels}>
                    <LabelSettings
                      register={register}
                      labels={data.company.financialLabels}
                      handleUnsavedChanges={handleUnsavedChanges}
                      supportedStatements={supportedStatements}
                      supportedFinancialLabels={supportedFinancialLabels}
                    />
                  </ToggleVisible>
                  {accountingIntegration === 'XERO' &&
                    !!data.company?.accounting?.bankAccounts && (
                      <ToggleVisible visible={tab === showBankMapping}>
                        <BankMappings
                          bankAccounts={data.company?.accounting?.bankAccounts}
                          bankAccountMappings={bankAccountMappings}
                          onChangeBankAccountType={(
                            accountId: string,
                            bankAccountType: string
                          ) => {
                            setBankAccountMappings((prev) =>
                              prev.map((m) =>
                                m.accountId === accountId
                                  ? { ...m, bankAccountType }
                                  : m
                              )
                            );
                            setBankAccountMappingsTouched(true);
                            setUnsavedChanges(true);
                          }}
                        />
                      </ToggleVisible>
                    )}
                  <></>
                  {!data?.company?.isConsolidation && (
                    <ToggleVisible visible={tab === 'earningsBefore'}>
                      <EarningsBeforeSettings
                        allIncomeItems={
                          data?.company?.threeWayBudgetInfo?.allIncomeItems
                        }
                        allExpenseItems={
                          data?.company?.threeWayBudgetInfo?.allExpenseItems
                        }
                        state={earningsBeforeState}
                        onStateChange={setEarningsBeforeState}
                        showAccountNumbers={data.company.showAccountNumbers}
                      />
                    </ToggleVisible>
                  )}
                  {accountingIntegration !== 'SUMMIT' &&
                    accountingIntegration !== 'ACS' &&
                    tab === showIntegrations &&
                    !data?.company?.isConsolidation &&
                    !data?.company?.demo && (
                      <IntegrationSettings
                        companyIntegrations={connectedIntegrations}
                        companyId={companyId}
                        groupId={data.company.firmId}
                        canDisconnectCompany={data.company.canDisconnectCompany}
                        isQBD={isQBD}
                      />
                    )}
                  <ToggleVisible visible={tab === 'ai'}>
                    <AiSettings
                      aiEnabled={aiEnabledState}
                      aiUsage={data.company.aiUsage}
                      onChangeAiEnabled={setAiEnabled}
                      visible={tab === 'ai'}
                      demo={data?.company?.demo}
                    />
                  </ToggleVisible>
                </form>
              </Col>
            </Row>
          </Container>
        )}
      </Div>
    </Modal>
  );
});

export default CompanySettingsModal;
