/**
 *
 * Error
 *
 */

import React from 'react';
import styled from 'styled-components';

import GlobalStyles from 'components/GlobalStyles';

const Div = styled.div`
  text-align: center;
  margin: 5%;
`;

const H1 = styled.h1`
  color: ${props => props.theme.colors.primary};
  font-size: 80px;
  font-weight: 400;
`;

const H3 = styled.h3`
  font-size: 16px;
`;

const Button = styled.button`
  padding: 0;
  color: #399af2;
`;

class ErrorPage extends React.PureComponent {
  handleReload = () => {
    window.location.pathname = '/';
  };
  render() {
    return (
      <Div>
        <GlobalStyles />
        <H1>Error</H1>
        <H3>
          Whoops, an error occurred. Our team has been notified. Try{' '}
          <Button onClick={this.handleReload}>Reloading.</Button>
        </H3>
      </Div>
    );
  }
}

export default ErrorPage;
