import React from 'react';

const PdfFileIcon = ({
  color = 'white', //#617182
  width = '24px',
  height = '24px',
  cursor,
  onClick,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  cursor?: string;
  onClick?: (any) => void;
}) => (
  <svg width={width} height={height} viewBox="0 0 60.77 71.96">
    <path
      fill={color}
      d="M10.53,2A6.55,6.55,0,0,0,4,8.53V60.74a6.55,6.55,0,0,0,6.53,6.52H49.68a6.55,6.55,0,0,0,6.53-6.52V21.58L36.63,2Zm14.4,43.58a3.36,3.36,0,0,1-.78,1.08,3.44,3.44,0,0,1-1.17.7,4.16,4.16,0,0,1-1.42.25H20.17v3.68H17.78V40.76h3.78A4.16,4.16,0,0,1,23,41a3.61,3.61,0,0,1,1.17.7,3.43,3.43,0,0,1,.78,3.87Zm9.8,2.58a4.63,4.63,0,0,1-1.08,1.67,5,5,0,0,1-1.71,1.08,6.28,6.28,0,0,1-2.23.38H26.07V40.76h3.64a6.14,6.14,0,0,1,2.23.39,4.64,4.64,0,0,1,2.79,2.76,6.13,6.13,0,0,1,0,4.25ZM43,43.06H38.52V45h4v2.3h-4v4H36.13V40.76H43Zm.49-18.22H33.37V6.89l17.94,18Z"
    />
    <path
      fill={color}
      d="M22.54,43.4a1.19,1.19,0,0,0-.39-.25,1.62,1.62,0,0,0-.57-.09H20.17v2.26h1.41a1.19,1.19,0,0,0,.94-.34,1.17,1.17,0,0,0,.31-.8,1.25,1.25,0,0,0-.07-.43A1,1,0,0,0,22.54,43.4Z"
    />
    <path
      fill={color}
      d="M32,43.87a2.53,2.53,0,0,0-1-.6,4.07,4.07,0,0,0-1.37-.21H28.46V49h1.16A4.06,4.06,0,0,0,31,48.78a2.64,2.64,0,0,0,1-.59,2.47,2.47,0,0,0,.57-.92,3.75,3.75,0,0,0,.2-1.24,3.71,3.71,0,0,0-.2-1.24A2.47,2.47,0,0,0,32,43.87Z"
    />
  </svg>
);

export default PdfFileIcon;
