/**
 *
 * GustoButton
 *
 */
import React from 'react';

import SvgButton from './SvgButton';
import gustoImg from './connect-gusto.svg';

const GustoButton = (props) => {
  return (
    <div className="test">
      <SvgButton src={gustoImg} {...props} />
    </div>
  );
};

export default GustoButton;
