import { useEffect, useState } from 'react';

type Timer = {
  timeLeft: number;
  setTimer: React.Dispatch<React.SetStateAction<number>>;
};

const useTimer = (): Timer => {
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    if (timeLeft === 0) return;

    const timerId = setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearTimeout(timerId);
  }, [timeLeft]);

  return { timeLeft, setTimer: setTimeLeft };
};

export default useTimer;
