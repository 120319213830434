import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  padding: 12px 5px;
  border: 1px solid #617182;
  border-radius: 5px;
  height: 60px;
  margin-top: 15px;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
`;

const ConnectionHandler = styled.button<{ connect: boolean }>`
  font-size: 0.75rem;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  margin-right: 20px;
  transition:
    border 150ms ease-in-out,
    color 150ms ease-in-out;

  &:hover {
    border: 1px solid ${(props) => (props.connect ? '#a5dc86' : '#ff865e')};
    color: ${(props) => (props.connect ? '#a5dc86' : '#ff865e')};
  }
`;

interface IntegrationProps {
  id: string;
  name: string;
  logo: React.ReactElement;
  canDisconnectCompany: boolean;
  onConnect?: (id: string) => void;
  onDisconnect?: (id: string, name: string) => void;
}

const Integration: React.FC<IntegrationProps> = ({
  id,
  name,
  logo,
  canDisconnectCompany,
  onConnect,
  onDisconnect,
}) => {
  const hasConnect = !!onConnect;
  const hasDisconnect = !!onDisconnect && canDisconnectCompany;
  return (
    <Container>
      <Logo>{logo}</Logo>
      {(hasDisconnect || hasConnect) && (
        <ConnectionHandler
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            if (hasConnect) {
              onConnect(id);
            } else {
              onDisconnect(id, name);
            }
          }}
          connect={hasConnect}
        >
          {hasConnect ? 'Connect' : 'Disconnect'}
        </ConnectionHandler>
      )}
    </Container>
  );
};

export default Integration;
