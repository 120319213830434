/**
 *
 * TreeRow
 *
 */
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import Checkbox from 'components/Checkbox';
import Radio from 'components/Radio';
import Help from 'components/Help';

const Toggle = styled.button.attrs({
  type: 'button',
})`
  margin-left: -19px;
  margin-right: 7px;
  width: 10px;
`;

const Label = styled.label`
  padding: 2px;
  display: inline-block;
  cursor: pointer;
  font-weight: 400;
  font-size: 13px;
  &:hover {
    background: #eee;
  }
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledCheckbox = styled(Checkbox)`
  vertical-align: middle;
  margin-right: 0;
  margin-bottom: 0;
`;
const StyledRadio = styled(Radio)`
  vertical-align: middle;
  margin-right: 0;
  margin-bottom: 0;
`;

const Name = styled.span`
  vertical-align: middle;
`;

const Button = styled.button`
  color: ${(props) => props.theme.colors.link};
  font-size: 13px;
  vertical-align: middle;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const TreeRow = ({ data, index, style }) => {
  const {
    onToggle,
    onSelect,
    single,
    onDeselectAllForItem,
    onSelectAllForItem,
    rows,
  } = data;

  const { hasArrow, expanded, checked, disabled, level, item } = rows[index];

  const { value, name, help, selectAllEnabled } = item;

  const handleToggle = useCallback(() => {
    onToggle(value);
  }, [onToggle, value]);

  const handleSelect = useCallback(
    (e) => {
      const rowIds = rows
        .filter((row) => !row.disabled)
        .map((row) => row.item.value);
      onSelect(value, e.target.checked, rowIds, e.nativeEvent.shiftKey);
    },
    [onSelect, value, rows]
  );

  const handleLabelClick = useCallback(() => {
    document.getSelection().removeAllRanges();
    if (item.disabled && item.children.length) {
      return handleToggle();
    }
  }, [handleToggle, item.children.length, item.disabled]);

  const Component = single ? StyledRadio : StyledCheckbox;

  return (
    <div style={style}>
      <ListItem key={value} style={{ paddingLeft: (level + 1) * 15 }}>
        <Left>
          {hasArrow && (
            <Toggle onClick={handleToggle}>
              <i className={`fa fa-caret-${expanded ? 'down' : 'right'}`} />
            </Toggle>
          )}
          <Label onClick={handleLabelClick}>
            {!disabled && (
              <Component checked={checked} onChange={handleSelect} />
            )}

            <Name onClick={disabled ? () => handleToggle : null}>
              {name.replaceAll('.xlsx', '').replaceAll('.xls', '')}
            </Name>
          </Label>
        </Left>
        {!!help && <Help content={help} />}
        {selectAllEnabled && !single && expanded && (
          <Right>
            <Button onClick={() => onSelectAllForItem(item)}>Select All</Button>
            <Button onClick={() => onDeselectAllForItem(item)}>
              Deselect All
            </Button>
          </Right>
        )}
      </ListItem>
    </div>
  );
};

export default TreeRow;
