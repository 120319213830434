import React from 'react';
import { withRouter } from 'react-router-dom';

import withCompanyId from 'hoc/withCompanyId';
import CompanyButton from './CompanyButton';

export default withCompanyId(
  withRouter(function SwitchCompany({ history }) {
    return (
      <CompanyButton onClick={() => history.push('/workspaces')}>
        Workspace Home
      </CompanyButton>
    );
  })
);
