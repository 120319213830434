import React, { useCallback, useEffect, useState } from 'react';
import Select from 'components/Select';
import { components, ControlProps } from 'react-select';
import ClassDepartmentModal, {
  FilterTypes,
} from 'components/ClassDepartmentModal';
import { Accounting, Class, Department } from 'interfaces/accounting';
import useClassesAndDepartments from 'hooks/useClassesAndDepartments';
import { SubdivideInput } from 'interfaces/table';

const SELECTION_LIMIT = 10;

const Control = ({ children, ...props }: ControlProps<any, boolean, any>) => {
  const { onShow, selectedNames } = props.selectProps as any;
  props.innerProps.onClick = onShow;
  return (
    <components.Control {...props}>
      {selectedNames ? (
        <span style={{ padding: '8px' }}>{selectedNames}</span>
      ) : (
        children
      )}
    </components.Control>
  );
};

const Menu = () => <></>;

interface Props {
  companyId: number;
  subdivide: SubdivideInput;
  disableChangeType?: boolean;
  disableRemoveAll?: boolean;
  disableSubs?: boolean;
  limit?: number;
  onChange: (
    subdivide: { classes?: number[]; departments?: number[] } | null
  ) => void;
}

const SubdividedSelect: React.FunctionComponent<Props> = ({
  companyId,
  subdivide,
  disableChangeType,
  disableRemoveAll,
  disableSubs,
  limit,
  onChange,
}: Props) => {
  const { classes, departments, loading } = useClassesAndDepartments(companyId);
  const [show, setShow] = useState(false);
  const [selectedNames, setSelectedNames] = useState<string>('');

  useEffect(() => {
    if (subdivide) {
      const selected = subdivide.classes || subdivide.departments || [];
      const newSelectedNames = selected.reduce<string>(
        (allNames, id, index) => {
          let item: Class | Department | undefined;

          if (subdivide.classes && classes) {
            item = classes.find((cls) => cls.id === id);
          } else if (subdivide.departments && departments) {
            item = departments.find((dep) => dep.id === id);
          }

          if (item) {
            allNames += `${item.name}${index === selected.length - 1 ? '' : ', '}`;
          }

          return allNames;
        },
        ''
      );

      setSelectedNames(newSelectedNames);
    } else {
      setSelectedNames('');
    }
  }, [subdivide, classes, departments]);

  const handleClassDepartmentSave = useCallback(
    (
      type: FilterTypes,
      selected: Accounting['classes'] | Accounting['departments']
    ) => {
      const newSubdivide: { classes?: number[]; departments?: number[] } = {};

      if (selected.length) {
        if (type === 'CLASSES') {
          newSubdivide.classes = selected.map((item) => item.id);
        } else {
          newSubdivide.departments = selected.map((item) => item.id);
        }
      } else {
        return null;
      }

      return newSubdivide;
    },
    []
  );

  const onSave = (
    _companyId: number,
    type: FilterTypes,
    selected: Accounting['classes'] | Accounting['departments']
  ) => {
    const newSubdivide = handleClassDepartmentSave(type, selected);
    setShow(false);
    onChange(newSubdivide);
  };

  const type = !subdivide
    ? undefined
    : subdivide.classes
      ? 'CLASSES'
      : 'DEPARTMENTS';
  const selected = subdivide?.classes || subdivide?.departments || undefined;

  if (loading) {
    return null;
  }

  return (
    <>
      <Select
        components={{ Control, Menu }}
        onShow={() => setShow(true)}
        selectedNames={selectedNames}
      />

      <ClassDepartmentModal
        show={show}
        companyId={companyId}
        type={type}
        selected={selected}
        onSave={onSave}
        onClose={() => setShow(false)}
        limit={limit || SELECTION_LIMIT}
        disableChangeType={disableChangeType}
        disableRemoveAll={disableRemoveAll}
        disableSubs={disableSubs}
      />
    </>
  );
};

export default SubdividedSelect;
