import { useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';

/**
 * A subsitute for the finicky useLazyQuery from Apollo
 * @param {graphql string} query
 * @returns Function that takes a variables object and fetches data using
 * the given query when called
 */

const useImperativeQuery = (query) => {
  const { refetch, networkStatus } = useQuery(query, {
    skip: true,
    fetchPolicy: 'network-only',
  });

  const imperativelyCallQuery = useCallback(
    (variables) => {
      return refetch(variables);
    },
    [refetch]
  );

  return useMemo(
    () => ({
      callQuery: imperativelyCallQuery,
      networkStatus,
    }),
    [imperativelyCallQuery, networkStatus]
  );
};

export default useImperativeQuery;
