import styled from 'styled-components';

export const Tr = styled.tr``;

const Table = styled.table`
  & tbody ${Tr}:nth-of-type(odd) {
    ${props => props.striped && 'background-color: #f0f6ff;'};
  }
`;

export const Th = styled.th``;

export default Table;
