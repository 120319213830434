import logo from 'images/logo-white.png';

export default {
  id: 'lightThemeP',
  name: 'theme-o',
  color: 'linear-gradient(135deg,#7041ab 21%,#3190f0 100%,#3190f0 100%)',
  colors: {
    primary: '#7041ab',
    secondary: '#788db4',
    accent: '#F64A91',
    success: '#2fbfa0',
    danger: '#ff5c75',
    warning: '#FFCE67',
    info: '#399AF2',
    link: '#337ab7',
    light: '#f0f6ff',
    dark: '#15182b',
    default: '#4E5E6A',
  },
  chartColors: [
    '#7041ab',
    '#F64A91',
    '#2fbfa0',
    '#ff5c75',
    '#FFCE67',
    '#399AF2',
  ],
  sidebar: {
    background: '#fff',
    logo,
    logoBackground: '#7041ab',
    color: '#718EB1',
    hoverColor: '#7041ab',
  },
  header: {
    background: 'linear-gradient(135deg,#7041ab 21%,#3190f0 100%,#3190f0 100%)',
    logo,
    color: '#fff',
  },
};
