import { ApolloError, gql, useQuery } from '@apollo/client';
import useCompanyId from './useCompanyId';
import { Company } from 'interfaces/company';
import { Accounting } from 'interfaces/accounting';

const CLASSES_AND_DEPARTMENTS = gql`
  query ClassDepartmentFilterHook($companyId: Int!) {
    company(id: $companyId) {
      id
      accounting {
        classes {
          id
          name
          fullyQualifiedName
          parentId
        }
        departments {
          id
          name
          fullyQualifiedName
          parentId
        }
      }
    }
  }
`;

const useClassesAndDepartments = (
  _companyId?: number,
  skip?: boolean
): {
  classes?: Accounting['classes'];
  departments?: Accounting['departments'];
  loading?: boolean;
  error?: ApolloError;
} => {
  const companyId = useCompanyId(_companyId);

  const { data, loading, error } = useQuery<{
    company: Company;
  }>(CLASSES_AND_DEPARTMENTS, {
    variables: { companyId },
    skip: !companyId || skip,
  });

  if (!data || loading || error) {
    return { loading, error };
  }

  const { classes, departments } = data.company.accounting;

  return { classes, departments };
};

export default useClassesAndDepartments;
