import Modal, { ModalProps } from 'components/Modal';
import React, { useCallback } from 'react';
import { GET_BACKUP_CODES, GET_TWO_FACTOR } from '../queriesAndMutations';
import { useApolloClient, useQuery } from '@apollo/client';
import { Container } from '../ApplicationSetupModal/styledComponents';
import LoadingIndicator from 'components/LoadingIndicator';
import Button from 'components/Button';
import { CurrentUser } from 'interfaces/user';

interface Props extends Omit<ModalProps, 'children'> {
  onClose: () => void;
}

const BackupCodeModal = ({ show, onClose }: Props): React.ReactElement => {
  const client = useApolloClient();
  const { data, loading } = useQuery(GET_BACKUP_CODES, {
    skip: !show,
    fetchPolicy: 'network-only',
  });

  const handleClose = useCallback(() => {
    const currentUser = client.readQuery<CurrentUser>({
      query: GET_TWO_FACTOR,
    });

    client.writeQuery({
      query: GET_TWO_FACTOR,
      data: {
        currentUser: {
          ...currentUser,
          downloadedBackupCodes: true,
        },
      },
    });

    onClose();
  }, [client, onClose]);

  const downloadBackupCodes = useCallback(() => {
    if (!data?.currentUser?.backupCodes) return;

    const element = document.createElement('a');
    const file = new Blob([data?.currentUser.backupCodes.join('\n')], {
      type: 'text/plain',
    });
    element.href = URL.createObjectURL(file);
    element.download = 'backup-codes.txt';
    document.body.appendChild(element);
    element.click();
  }, [data?.currentUser.backupCodes]);

  return (
    <Modal title={'Backup Codes'} show={show} onClose={handleClose}>
      {loading ? (
        <Container>
          <LoadingIndicator independent />
        </Container>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <p>
            Save these codes securely. They allow you to log in if you lose
            access to your 2FA app. Each code can only be used once. This is
            your only chance to download them, so ensure you can access them if
            needed.
          </p>
          <div>
            <pre>{data?.currentUser?.backupCodes?.join('\n')}</pre>
          </div>
          <div>
            <Button color="primary" onClick={downloadBackupCodes}>
              Download Backup Codes
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default BackupCodeModal;
