/**
 *
 * UploadPage
 *
 */
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import Button from 'components/Button';
import CsvValidationTable from './CsvValidationTable';
import TrialBalanceValidation from './TrialBalanceValidation';

import sampleFile from './CSV Trial Balance Import Sample.xlsx';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const FileInput = styled.input`
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 0.775rem;
`;

const Name = styled.div`
  display: flex;
`;

const NameInput = styled.input`
  flex-grow: 1;
  margin-right: 0.5rem;
`;

const VALIDATE = gql`
  mutation ValidateCsvFile($csvFile: Upload!) {
    validateCsvCompany(csvFile: $csvFile) {
      valid
      missingColumns
      errors {
        line
        columnErrors {
          column
          error
          actual
        }
        actualData
      }
      trialBalanceErrors {
        date
        balance
      }
      errorMessage
    }
  }
`;

interface UploadPageProps {
  onSubmit: (any) => void;
  loading: boolean;
  update: boolean;
  hideName: boolean;
  onChangeCsvFile: (file: any) => void;
}

const UploadPage: React.FunctionComponent<UploadPageProps> = (props) => {
  const { onSubmit, loading, update, hideName, onChangeCsvFile } = props;
  const { register, handleSubmit } = useForm();
  const [onValidate, { data, loading: loadingValidation }] =
    useMutation(VALIDATE);

  const onChange = useCallback(
    (e) => {
      const files = e.target.files;
      if (!files.length) return;

      onChangeCsvFile(files[0]);

      onValidate({
        variables: {
          csvFile: files[0],
        },
      });
    },
    [onChangeCsvFile, onValidate]
  );

  const onFormSubmit = useCallback(
    (e) => {
      e.stopPropagation();
      handleSubmit(onSubmit)(e);
    },
    [handleSubmit, onSubmit]
  );

  const valid =
    data && data.validateCsvCompany && data.validateCsvCompany.valid;

  return (
    <form onSubmit={onFormSubmit}>
      {!update && (
        <div>
          <a href={`${sampleFile}?v=3`}>Download Template File</a>
        </div>
      )}
      <FileInput
        {...register('csvFile', { required: true })}
        type="file"
        name="csvFile"
        placeholder="Choose csv file"
        accept=".csv"
        onChange={onChange}
      />
      <CsvValidationTable
        validationResult={data && data.validateCsvCompany}
        loading={loadingValidation}
      />
      <TrialBalanceValidation
        validationResult={data && data.validateCsvCompany}
      />
      {valid && (
        <>
          <p>CSV was successfuly validated</p>
          <Name>
            {!update && !hideName && (
              <NameInput
                {...register('companyName', { required: true, maxLength: 50 })}
                type="text"
                name="companyName"
                placeholder="Company Name"
                autoComplete="off" data-1p-ignore
              />
            )}

            <Button color="success" disabled={loading} loading={loading}>
              {update ? 'Upload' : 'Create'}
            </Button>
          </Name>
        </>
      )}
    </form>
  );
};

export default UploadPage;
