/**
 *
 * SelectField
 *
 */

import React, { useCallback } from 'react';
import { asField } from 'informed';

import Select from 'components/Select';

const SelectField = asField(function SelectField({
  fieldState,
  fieldApi,
  options,
  id,
  onChange,
  defaultValue,
  testId,
  ...rest
}) {
  const { value } = fieldState;
  const { setValue } = fieldApi;

  const currentValue = value || defaultValue;

  const handleChange = useCallback(
    (newValue) => {
      if (onChange) {
        const result = onChange(newValue, currentValue);
        if (result !== undefined) {
          setValue(newValue);
        }
      } else {
        setValue(newValue);
      }
    },
    [currentValue, onChange, setValue]
  );

  return (
    <Select
      {...rest}
      id={id}
      options={options}
      value={currentValue}
      onChange={handleChange}
      testId={testId}
    />
  );
});

export default SelectField;
