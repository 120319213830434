import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { showError } from 'utils/popups';
import { Delete } from '.././components';
import './styles.css';
import Checkbox from 'components/Checkbox';
import { DEV, STAGING } from 'environment';
import { Container } from './styledComponents';
import {
  DeleteUserResult,
  DeleteUserVariables,
  TwoFactorAuthenticationMethod,
} from 'interfaces/user';

const STANDARD_TOTP_LENGTH = 6;
const BACKUP_CODE_LENGTH = 8;

const DELETE_USER = gql`
  mutation DeleteUser(
    $forceDelete: Boolean!
    $code: String
    $method: TwoFactorAuthenticationMethod
  ) {
    deleteUser(forceDelete: $forceDelete, code: $code, method: $method) {
      id
      success
      error
    }
  }
`;

const CHECK_2FA = gql`
  mutation UserSettingsCheckTwoFactorAuth(
    $method: TwoFactorAuthenticationMethod
  ) {
    checkTwoFactorAuth(method: $method) {
      success
      error
      phoneLast4
      verifiedTotp
      method
    }
  }
`;

interface Props {
  showTwoFactorAuth?: boolean;
}

const DeleteUser = ({ showTwoFactorAuth }: Props): React.ReactElement => {
  const [forceDelete, setForceDelete] = useState(false);
  const [deleteUser] = useMutation<DeleteUserResult, DeleteUserVariables>(
    DELETE_USER
  );
  const [check2FA] = useMutation(CHECK_2FA);

  const initializeTwoFactorAuth = useCallback(
    async (method?: TwoFactorAuthenticationMethod | null) => {
      const { data } = await check2FA({
        variables: {
          method,
        },
      });

      if (data?.checkTwoFactorAuth?.success) {
        return {
          success: true,
          method: data.checkTwoFactorAuth.method,
          verifiedTotp: data.checkTwoFactorAuth.verifiedTotp,
          phoneLast4: data.checkTwoFactorAuth.phoneLast4,
        };
      } else {
        await showError({
          title: 'Oops!',
          text: data?.checkTwoFactorAuth?.error,
        });
        return { success: false, method: null, verifiedTotp: false };
      }
    },
    [check2FA]
  );

  const openVerificationPopup = useCallback(
    async (method, verifiedTotp, phoneLast4) => {
      if (method === TwoFactorAuthenticationMethod.PHONE) {
        await initializeTwoFactorAuth(TwoFactorAuthenticationMethod.PHONE);
      }

      return Swal.fire({
        title: 'Two Factor Authentication',
        text:
          method === TwoFactorAuthenticationMethod.TOTP
            ? 'Enter the 6 digit code from your authenticator app'
            : `Enter the 6 digit code sent to your phone ending in ${phoneLast4}`,
        input: 'text',
        inputPlaceholder: 'Enter code',
        showCancelButton: true,
        showDenyButton:
          method === TwoFactorAuthenticationMethod.PHONE ||
          (method === TwoFactorAuthenticationMethod.TOTP &&
            verifiedTotp &&
            phoneLast4),
        confirmButtonText: 'Verify Code',
        denyButtonText:
          method === TwoFactorAuthenticationMethod.TOTP && verifiedTotp
            ? 'Receive Code via SMS'
            : 'Resend Code',
        cancelButtonText: 'Cancel',
        customClass: {
          input: 'custom-input',
        },
        preConfirm: (input) => {
          if (!input) {
            Swal.showValidationMessage('Code is required');
            return false;
          }

          if (
            input.length !== STANDARD_TOTP_LENGTH &&
            input.length !== BACKUP_CODE_LENGTH
          ) {
            Swal.showValidationMessage('Invalid code');
            return false;
          }

          return input;
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          // Add code to send the entered code to the server and handle verification
          return { code: result.value, method };
        } else if (result.isDenied) {
          if (method === TwoFactorAuthenticationMethod.PHONE) {
            // The resend functionality is subsituting the isDenied call
            await initializeTwoFactorAuth(TwoFactorAuthenticationMethod.PHONE);
          } else if (method === TwoFactorAuthenticationMethod.TOTP) {
            return await openVerificationPopup(
              TwoFactorAuthenticationMethod.PHONE,
              verifiedTotp,
              phoneLast4
            );
          }
        }
      });
    },
    [initializeTwoFactorAuth]
  );

  const handleTwoFactorAuth = useCallback(async (): Promise<
    | { code: string | undefined; method: TwoFactorAuthenticationMethod | null }
    | undefined
  > => {
    let code: string | undefined;
    let method: TwoFactorAuthenticationMethod | null = null;
    if (showTwoFactorAuth) {
      const {
        success,
        method: initialMethod,
        verifiedTotp,
        phoneLast4,
      } = await initializeTwoFactorAuth();
      if (success) {
        const result = await openVerificationPopup(
          initialMethod,
          verifiedTotp,
          phoneLast4
        );
        if (result) {
          code = result.code;
          method = result.method;
        }
      }
    }

    return { code, method };
  }, [initializeTwoFactorAuth, openVerificationPopup, showTwoFactorAuth]);

  const handleDelete = useCallback(
    async (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
      // eslint-disable-next-line require-await
    ): Promise<void | SweetAlertResult<any>> => {
      e.preventDefault();
      return Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this! Type 'DELETE' to confirm.",
        icon: 'warning',
        input: 'text',
        inputPlaceholder: 'Type DELETE',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        customClass: {
          input: 'custom-input',
        },
        preConfirm: (input) => {
          if (input !== 'DELETE') {
            Swal.showValidationMessage('You need to type "DELETE" to confirm');
            return false;
          }
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          const twoFactorResult = await handleTwoFactorAuth();
          let variables: DeleteUserVariables = {
            forceDelete,
          };

          if (showTwoFactorAuth) {
            if (!twoFactorResult?.code || !twoFactorResult?.method) {
              return;
            }

            variables = {
              ...variables,
              code: twoFactorResult.code,
              method: twoFactorResult.method,
            };
          }

          deleteUser({
            variables,
            update: (_, { data }) => {
              const deleteUserResult = data?.deleteUser;
              if (deleteUserResult?.success) {
                window.location.href = '/logout';
              } else if (deleteUserResult?.error) {
                showError({
                  title: 'Oops!',
                  text: deleteUserResult?.error,
                });
              }
            },
          });
        }
      });
    },
    [deleteUser, forceDelete, handleTwoFactorAuth, showTwoFactorAuth]
  );

  return (
    <Container>
      <Delete onClick={handleDelete}>Delete Account</Delete>
      {(DEV || STAGING) && (
        <Checkbox
          checked={forceDelete}
          onChange={() => setForceDelete(!forceDelete)}
          noMargin
        >
          Force Delete
        </Checkbox>
      )}
    </Container>
  );
};

export default DeleteUser;
