/**
 *
 * Button
 *
 */

import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const StyledLink = styled(({ block, outline, ...rest }) => <Link {...rest} />)`
  border: 1px solid transparent;
  color: ${props => props.theme.button[props.color].color};
  background: ${props => props.theme.button[props.color].base};
  border-color: ${props => props.theme.button[props.color].border};
  
  ${props =>
    !props.outline &&
    css`
      &:hover {
        color: ${props =>
          props.theme.button[props.color].hoverColor ||
          props.theme.button[props.color].color};
      }
    `}
  
  ${props =>
    props.outline &&
    css`
      background: transparent;
      color: ${props =>
        props.theme.button[props.color].outlineColor ||
        props.theme.button[props.color].base};
      border-color: ${props =>
        props.theme.button[props.color].outlineBorder ||
        props.theme.button[props.color].base};
      &:hover {
        color: ${props =>
          props.theme.button[props.color].outlineHover || 'white'};
        background: ${props => props.color === 'default' ? '#617182' : props.theme.button[props.color].hover}!important;
      }
    `}
  box-shadow: ${props =>
    props.floating ? '0 5px 10px rgba(115,108,199,.4)' : 'none'};
  font-size: 13px;
  padding: 7px 12px;
  line-height: 1.42857143;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 300;
  border-radius: ${props => (props.round ? '50px' : '0.25rem')};
  vertical-align: middle;
  background-image: none;
  text-align: center;
  text-decoration: none;
  outline: 0;
  ${props =>
    props.large &&
    `
    padding: 25px 50px;
    font-size: 1rem;
    line-height: .2;
  `} &:hover {
    background: ${props => props.theme.button[props.color].hover};
    box-shadow: 0 5px 10px rgba(115, 108, 199, 0.4);
    text-decoration: none;
  }
  ${props => (props.block ? 'display: block; width: 100%' : '')};
`;

class StyledButton extends React.PureComponent {
  render() {
    const {
      className,
      color,
      block,
      to,
      children,
      external,
      outline,
      ...rest
    } = this.props;

    return (
      <StyledLink
        className={className}
        color={color || 'default'}
        block={block}
        to={to}
        outline={outline ? 'true' : null}
        as={external ? 'a' : Link}
        {...rest}
      >
        {children}
      </StyledLink>
    );
  }
}

export default StyledButton;
