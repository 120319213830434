import React, { ReactElement } from 'react';

const CheckedIcon = ({
  color = '#f9fafc',
  width = '100%',
  height = '100%',
  cursor,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  cursor?: string;
  onClick?: (any) => void;
}): ReactElement => {
  return (
    <>
      <svg
        {...props}
        width={width}
        height={height}
        cursor={cursor}
        viewBox="0 0 24 24"
      >
        <path
          fill={color}
          d="M7.2,10.8h0a1,1,0,0,0,0,1.4l3.2,3.2a.78.78,0,0,0,1.1,0l8.7-8.7a1,1,0,0,0,0-1.4h0a1,1,0,0,0-1.4,0L11,13.2,8.6,10.8A1,1,0,0,0,7.2,10.8Z"
        />
        <path
          fill={color}
          d="M19,19H5V5h9a.94.94,0,0,0,1-1,.94.94,0,0,0-1-1H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V12a.94.94,0,0,0-1-1h0a.94.94,0,0,0-1,1Z"
        />
      </svg>
    </>
  );
};

export default CheckedIcon;
