import React from 'react';
import LoadingIndicator from 'components/LoadingIndicator';
import useMaintenanceMode from 'hooks/useMaintenanceMode';
import { Redirect, useLocation } from 'react-router-dom';
import getDisplayName from 'utils/getDisplayName';

function RedirectMaintenanceMode(Component) {
  const NewComponent = function (props) {
    const location = useLocation();

    const isOnLoginPage = location.pathname === '/login';

    const { down, loading } = useMaintenanceMode();

    if (!isOnLoginPage) {
      if (loading) {
        return <LoadingIndicator />;
      }

      if (down) {
        return <Redirect to="/login" />;
      }
    }

    return <Component {...props} />;
  };

  NewComponent.displayName = `RedirectMaintenanceMode(${getDisplayName(
    Component
  )})`;

  return NewComponent;
}

export default RedirectMaintenanceMode;
