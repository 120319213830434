/**
 *
 * DemoBanner
 *
 */

import React from 'react';
import styled from 'styled-components';

import { demoBannerZIndex } from 'utils/zIndex';

const Div = styled.div`
  z-index: ${demoBannerZIndex};
  line-height: 40px;
  text-align: center;
  background: ${props => props.theme.colors.primary};
  color: white;
  line-height: 1;
  padding: 10px;
`;

class DemoBanner extends React.PureComponent {
  render() {
    return <Div>This is a demo company.</Div>;
  }
}

export default DemoBanner;
