import styled, { css } from 'styled-components';

import { Bar } from 'components/Sidebar/styledComponents';

interface StyleProps {
  mouseEntered?: boolean;
  theme: {
    sidebar: {
      color: string;
      hoverColor: string;
      syncColor: string;
    };
  };
  colors?: {
    warning: string;
  };
  beta?: string;
}

interface ColorProps extends StyleProps {
  showText?: boolean;
  mouseEntered?: boolean;
  syncing?: boolean;
  progress?: number | null;
  submenuOpened?: boolean;
}

export const Container = styled.div<ColorProps>`
  position: relative;
  width: 100%;
  height: 44px;
  overflow: ${(props) => (props.submenuOpened ? 'visible' : 'hidden')};
  ${(props) =>
    !props.syncing &&
    css`
      &:hover {
        background-color: ${(p) => p.theme.sidebar.buttonHighlight};
        color: ${(p: ColorProps) => p.theme.sidebar.hoverColor};
      }
    `}
  ${(props) =>
    props.syncing &&
    css`
      border-radius: 5px;
    `}
  transition: background-color 0.15s ease-out, border-radius 0.15s ease-out;
`;

export const Progress = styled.div<ColorProps>`
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: ${(props) => `${props.progress}%`};
  background-color: rgba(240, 246, 255, 0.7);
  transition: width 0.15s ease-out;
`;

export const Button = styled.button<StyleProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
  font-weight: 300;
  width: 100%;
  color: ${(props) =>
    props.mouseEntered
      ? props.theme.sidebar.hoverColor
      : props.theme.sidebar.color};
`;

export const Text = styled.div<ColorProps>`
  position: relative;
  width: 0;
  text-align: left;
  font-size: 0.85rem;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.4s ease-out, color 0.15s ease-out;
  ${Bar}:hover & {
    opacity: 1;
  }
`;

export const SyncLimit = styled.div<ColorProps & { noHoverChange?: boolean }>`
  margin-top: -1px;
  position: absolute;
  right: 10px;
  font-size: 0.85rem;
  opacity: ${({ noHoverChange }) => (noHoverChange ? 1 : 0)};
  transition: opacity 0.4s ease-out, color 0.15s ease-out;
  ${Bar}:hover & {
    opacity: 1;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
`;

export const Icon = styled.div<ColorProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 0;
  width: 25px;
  height: 20px;
  border-radius: 5px;
  background-color: ${(props) =>
    props.mouseEntered
      ? props.theme.sidebar.syncContainerHoverColor
      : props.theme.sidebar.syncContainerColor};
  transition: background-color 0.15s ease-out;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  ${(props) =>
    props.syncing &&
    css`
      svg {
        animation: rotation 1s infinite linear;
      }
    `}

  svg > path {
    fill: ${(props) =>
      props.mouseEntered
        ? props.theme.sidebar.syncHoverColor
        : props.theme.sidebar.syncColor};
    transition: fill 0.15s ease-out;
  }
`;

export const Info = styled.div<ColorProps>`
  opacity: ${(props) => (props.syncing ? 0 : 1)}
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 0.5rem;
  margin-bottom: -12px;
  transition: opacity 0.15s ease-out;
`;

export const SubMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #2b2d37;
  bottom: 0;
  left: calc(100% + 5px);
  font-size: 0.75rem;
  padding: 5px;
  border-radius: 5px;
`;

export const SubMenuButton = styled.button<{ width?: number }>`
  ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
    `}
  transition: background-color 200ms ease-out, color 200ms ease-out;
  padding: 10px 25px 10px 10px;
  border-radius: 5px;
  text-align: left;
  white-space: nowrap;
  color: #c9cfd5;
  &:hover {
    color: #2b2d37;
    background-color: #b2b8c2;
  }
  display: flex;
  flex-direction: column;
`;
