/* global fetch Headers sessionStorage */

import { API_URL } from 'environment';

export function checkAuthenticated() {
  return fetch(`${API_URL}/auth/verify`, {
    method: 'GET',
    credentials: 'include',
  })
    .then((response) => response.json())
    .then((result) => {
      return result.isLoggedIn;
    })
    .catch(() => false);
}

export function login(email, password, twoFactorCode, popup, method) {
  return fetch(`${API_URL}/auth/login`, {
    method: 'POST',
    body: JSON.stringify({
      email,
      password,
      twoFactorCode,
      popup,
      method,
    }),
    headers: new Headers({
      'content-type': 'application/json; charset=UTF-8',
    }),
    credentials: 'include',
  }).then((response) => {
    try {
      return response.json();
    } catch (e) {
      return { success: false };
    }
  });
}

export function register(values) {
  return fetch(`${API_URL}/auth/register`, {
    method: 'POST',
    body: JSON.stringify(values),
    headers: new Headers({
      'content-type': 'application/json; charset=UTF-8',
    }),
    credentials: 'include',
  }).then((response) => {
    try {
      return response.json();
    } catch (e) {
      return { success: false };
    }
  });
}

export function signup(values) {
  return fetch(`${API_URL}/auth/signup`, {
    method: 'POST',
    body: JSON.stringify(values),
    headers: new Headers({
      'content-type': 'application/json; charset=UTF-8',
    }),
    credentials: 'include',
  }).then((response) => {
    try {
      return response.json();
    } catch (e) {
      return { success: false };
    }
  });
}

export function logout() {
  sessionStorage.removeItem('currentCompanyId');
  sessionStorage.removeItem('companiesSelectedGroup');
  sessionStorage.removeItem('currentWorkspaceId');
  sessionStorage.clear();
  return fetch(`${API_URL}/auth/logout`, {
    method: 'POST',
    credentials: 'include',
  });
}

export function validateEmail(email) {
  return fetch(encodeURI(`${API_URL}/auth/validate-email`), {
    method: 'POST',
    headers: new Headers({
      'content-type': 'application/json; charset=UTF-8',
    }),
    body: JSON.stringify({
      email,
    }),
  }).then((response) => {
    try {
      return response.json();
    } catch (e) {
      return { success: false, error: 'An error occurred.' };
    }
  });
}

export function setupPassword(code, firstName, lastName, password, phone, accept) {
  return fetch(`${API_URL}/auth/register/${code || ''}`, {
    method: 'POST',
    body: JSON.stringify({
      firstName,
      lastName,
      password,
      phone,
      accept,
    }),
    headers: new Headers({
      'content-type': 'application/json; charset=UTF-8',
    }),
    credentials: 'include',
  }).then((response) => {
    try {
      return response.json();
    } catch (e) {
      return { success: false, error: 'An error occurred.' };
    }
  });
}

export function forgot(email) {
  return fetch(`${API_URL}/auth/forgot`, {
    method: 'POST',
    body: JSON.stringify({
      email,
    }),
    headers: new Headers({
      'content-type': 'application/json; charset=UTF-8',
    }),
  }).then((response) => {
    try {
      return response.json();
    } catch (e) {
      return { success: false, error: 'An error occurred.' };
    }
  });
}

export function resetPassword(code, password) {
  return fetch(`${API_URL}/auth/forgot/${code}`, {
    method: 'POST',
    body: JSON.stringify({
      password,
    }),
    headers: new Headers({
      'content-type': 'application/json; charset=UTF-8',
    }),
  }).then((response) => {
    try {
      return response.json();
    } catch (e) {
      return { success: false, error: 'An error occurred.' };
    }
  });
}
