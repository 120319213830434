import styled from 'styled-components';
import { trialBannerZIndex } from 'utils/zIndex';

const Banner = styled.div`
  z-index: ${trialBannerZIndex};
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  line-height: 40px;
  text-align: center;
  background: ${props => props.theme.colors.primary};
  color: white;
  line-height: 1;
  padding: 10px;
`;

export default Banner;
