/**
 *
 * CreateButton
 *
 */
import React from 'react';
import { PrimaryButtonStyle } from './styledComponents';
import DropdownButton from 'components/DropdownButton';

const CreateButton = (props) => {
  const { dispatch, setCreating } = props;

  return (
    <PrimaryButtonStyle
      color="primary"
      onClick={() => {
        setCreating(false);
        dispatch({ type: 'CREATE' });
      }}
      dropdownContent={
        <DropdownButton
          onClick={() => {
            setCreating(false);
            dispatch({ type: 'GROUP' });
          }}
        >
          Multi-Year Group
        </DropdownButton>
      }
    >
      Create New
    </PrimaryButtonStyle>
  );
};

export default CreateButton;
