import React from 'react';
import { Actions as Container, Limit, Button } from './styledComponents';

interface Props {
  limit?: number;
  count: number;
  disableRemoveAll?: boolean;
  onSave: () => void;
}

const Actions = ({
  limit,
  count,
  disableRemoveAll,
  onSave,
}: Props): React.ReactElement => {
  return (
    <Container>
      <Limit>
        {limit && (
          <>
            <i className="mdi-set mdi-alert" />
            {count}/{limit} Classes and Departments Used
          </>
        )}
      </Limit>
      <Button
        onClick={onSave}
        disabled={
          (!!limit && count > limit) || (disableRemoveAll && count === 0)
        }
        color="primary"
      >
        Save
      </Button>
    </Container>
  );
};

export default Actions;
