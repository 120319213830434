import React from 'react';
import styled from 'styled-components';

import styledTheming from 'styled-theming';
import media from 'media';
import Button from 'components/Button';
import DropdownButton from 'components/DropdownButton';

const tableHeaderBackground = styledTheming('mode', {
  light: '#EAEBEF',
  dark: '#494d5b',
});

const rowHoverColor = styledTheming('mode', {
  light: '#f4f4f7',
  dark: '#35394a',
});

export const TableStyle = styled.div`
  padding: 1rem 0 0 0;
  max-height: calc(100vh - ${({ hasFooter }) => (hasFooter ? 155 : 68)}px);
  min-height: 50vh;
  overflow-y: auto;

  table {
    font-size: 12px;
    border-spacing: 0;
    table-layout: fixed;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    /* ${media.tablet`
      td:first-child,
      th:first-child {
        width: 2rem;
        padding-left: 1rem;
      }
    `} */

    thead {
      background-color: ${tableHeaderBackground};
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;

      :last-child {
        border-right: 0;
      }
    }

    tbody tr:hover {
      background-color: ${rowHoverColor};
    }
  }
`;

export const ExpandedTableRow = styled.tr`
  /* background-color: ${rowHoverColor}; */
`;

export const ManageMultiYearButton = styled.button`
  color: ${({ theme }) => theme.colors.warning} !important;
`;

export const OpenButton = styled(Button)`
  padding-bottom: 1px;
`;

export const Tags = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Tag = styled.span`
  background-color: ${({ color, theme }) => theme.colors[color ?? 'primary']};
  color: ${({ theme }) => theme.colors.white};
  padding: 3px 5px;
  border-radius: 3px;
  margin-right: 5px;
`;

export const ColoredDropdownButton = styled(DropdownButton)`
  color: ${({ color, theme }) => theme.colors[color ?? 'primary']} !important;
`;

const BaseTooltipContainer = styled.span``;

export const TooltipContainer = React.forwardRef((props, ref) => (
  <BaseTooltipContainer {...props} ref={ref} />
));

TooltipContainer.displayName = 'TooltipContainer';

export const ExcelButton = styled.button`
  height: 18px;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  svg > path {
    fill: ${({ theme: _theme }) => _theme.body.color};
  }

  > span {
    display: flex;
    align-items: center;
  }

  // Give direct span children a right margin of 5px if they are not the last child
  > span:not(:last-child) {
    margin-right: 5px;
  }
`;

export const LockContainer = styled.span`
  svg > path {
    fill: ${({ theme: _theme }) => _theme.colors.danger} !important;
  }
  padding-right: 2px;
  padding-top: 3px;
  > span {
    display: flex;
    align-items: center;
  }
`;
