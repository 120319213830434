/**
 *
 * ToggleSection
 *
 */

import React from 'react';
// import styled from 'styled-components';

class ToggleSection extends React.Component {
  render() {
    if (this.props.show) {
      return this.props.children;
    }

    return null;
  }
}

export default ToggleSection;
