import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import useCompanyId from './useCompanyId';

export const INTEGRATION_TYPES = gql`
  query UseIntegrationTypes($companyId: Int!) {
    company(id: $companyId) {
      id
      accountingIntegration
      payrollIntegration
      accounting {
        integration
      }
    }
  }
`;

export default function useIntegrationTypes(_companyId) {
  const companyId = useCompanyId() || _companyId;

  const { loading, error, data } = useQuery(INTEGRATION_TYPES, {
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  return useMemo(() => {
    if (!companyId) return {};

    if (loading || !data) return {};
    if (error) return {};
    if (!data || !data.company) {
      return {};
    }

    return {
      accountingIntegration: data.company.accountingIntegration,
      detailedAccountingIntegration: data.company.accounting?.integration,
      payrollIntegration: data.company.payrollIntegration,
    };
  }, [companyId, data, error, loading]);
}
