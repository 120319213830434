import React, { useState, useCallback, useMemo } from 'react';
import gql from 'graphql-tag';
import styled from 'styled-components';
import useTheme from 'hooks/useTheme';
import {
  Controller,
  useController,
  Control,
  FieldValues,
} from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { countries, formatCurrency } from 'library/currency';

import BookMonth from './BookMonth';
import {
  SettingTitle,
  FieldGroup,
  Label,
  Input,
  CheckBox,
  LabelCol,
  InputCol,
  LaunchCurrencyModal,
  BulkUpdateDiv,
  BulkUpdateCheckboxDiv,
  BulkUpdateText,
} from './styledComponents';
import Select from 'components/Select';
import BudgetSelect from 'components/BudgetSelect';
import { captureException } from 'utils/sentry';
import Button from 'components/Button';
import moment, { Moment } from 'moment';
import CurrencyModal from 'components/CurrencyModal';
import { Country } from 'interfaces/company';
import Help from 'components/Help';
import LogoUploader from 'components/LogoUploader';
import { showError, showConfirm } from 'utils/popups';
import ThemesModal from 'components/ColorTheme/ThemesModal';
import ColorTheme from 'components/ColorTheme';
import { equals } from 'ramda';
import getImagePalette from 'utils/getImagePalette';
import Swal from 'sweetalert2';

const UpdateDemoDataButton = styled.button`
  font-size: 14px;
  padding: 0px;
  color: ${(props) =>
    props.disabled ? 'gray' : props.theme.colors.link}!important;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')}!important;
`;

const FULL_SYNC = gql`
  mutation FullSync($companyId: Int!) {
    syncCompanyIntegration(
      companyId: $companyId
      integration: QB
      fullSync: true
    ) {
      id
    }
  }
`;

const LOGO_QUERY = gql`
  query CompanyLogo($companyId: Int!) {
    company(id: $companyId) {
      id
      logo {
        id
        url
      }
    }
  }
`;

const UPDATE_DEMO_DATA = gql`
  mutation UpdateDemoData($companyId: Int!) {
    updateDemoData(companyId: $companyId) {
      id
      demoDataRefreshDate
    }
  }
`;

const AppearanceSettings = styled.div`
  display: flex;
  margin-bottom: 14px;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const HelperText = styled.div`
  font-size: 0.75rem;
  text-align: right;
`;

const CurrencyPreview = styled.div`
  text-align: right;
  margin-left: 1rem;
`;

const BudgetSplit = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BudgetSplitSection = styled.div`
  width: calc(50% - 4px);
`;

const StyledBudgetSelect = styled(BudgetSelect)<{
  maxMenuHeight?: number;
}>`
  margin: 0;
`;

const BetaTestText = styled.div`
  font-size: 0.75rem;
`;

const BetaLabel = styled.span`
  color: #ff865e;
`;

const showAccountNumbersOptions = [
  {
    label: 'Enabled',
    value: true,
  },
  {
    label: 'Disabled',
    value: false,
  },
];

const hideInactiveAccountsOptions = [
  {
    label: 'Enabled',
    value: true,
  },
  {
    label: 'Disabled',
    value: false,
  },
];

const lastUpdatedThresholdOptions = [
  {
    label: 'Remove All',
    value: 0,
  },
  {
    label: '1 Year',
    value: 1,
  },
  {
    label: '2 Years',
    value: 2,
  },
  {
    label: '3 Years',
    value: 3,
  },
  {
    label: '4 Years',
    value: 4,
  },
  {
    label: '5 Years',
    value: 5,
  },
];

const basisOptions = [
  {
    label: 'Cash',
    value: 'CASH',
  },
  {
    label: 'Accrual',
    value: 'ACCRUAL',
  },
];

const LastUpdatedThresholdSelect = ({
  formControl,
  name,
  defaultValue,
  handleChange,
}) => {
  const {
    field: { onChange: onLastUpdatedThresholdChange, value },
  } = useController({
    name,
    control: formControl,
    defaultValue,
  });

  return (
    <Select
      options={lastUpdatedThresholdOptions}
      portal
      onChange={(v) => {
        handleChange();
        onLastUpdatedThresholdChange(v);
      }}
      value={value}
    ></Select>
  );
};

const HideInactiveAccountsSelect = ({
  formControl,
  name,
  defaultValue,
  handleChange,
  setHideInactiveAccountsActive,
}) => {
  const {
    field: { onChange: onHideInactiveAccountsChange, value },
  } = useController({
    name,
    control: formControl,
    defaultValue,
  });

  return (
    <Select
      options={hideInactiveAccountsOptions}
      portal
      onChange={(v) => {
        handleChange();
        onHideInactiveAccountsChange(v);
        setHideInactiveAccountsActive(v);
      }}
      value={value}
    ></Select>
  );
};

// Used useController hook to create Account Numbers select element since the Controller
// element wasn't working how it should (Currency select works fine)
const AccountNumbersSelect = ({
  formControl,
  name,
  defaultValue,
  handleChange,
}) => {
  const {
    field: { onChange: onShowAccountNumbersChange, value },
  } = useController({
    name,
    control: formControl,
    defaultValue,
  });

  return (
    <Select
      options={showAccountNumbersOptions}
      portal
      testId="show-account-numbers-select"
      onChange={(v) => {
        handleChange();
        onShowAccountNumbersChange(v);
      }}
      value={value}
    ></Select>
  );
};

const SortWithAccountNumbersSelect = ({
  formControl,
  name,
  defaultValue,
  handleChange,
}) => {
  const {
    field: { onChange: onSortWithAccountNumbersChange, value },
  } = useController({
    name,
    control: formControl,
    defaultValue,
  });

  return (
    <Select
      options={showAccountNumbersOptions}
      portal
      testId="sort-with-account-numbers-select"
      onChange={(v) => {
        handleChange();
        onSortWithAccountNumbersChange(v);
      }}
      value={value}
    ></Select>
  );
};

interface GeneralSettingsProps {
  companyId: number;
  groups: boolean;
  defaultBudgetState: string;
  onChangeDefaultBudgetState: (string: string) => void;
  defaultForecastState: string;
  onChangeDefaultForecastState: (string: string) => void;
  companyName: string;
  colors: any;
  customColors: any;
  logo: any;
  showHelp: boolean;
  bookMonth: any;
  isBookMonthFixed: boolean | null;
  register: (any) => any;
  control: Control<FieldValues>;
  currency: string;
  countryValue: string;
  handleUnsavedChanges: () => void;
  onChangeBookMonth: (moment: Moment) => void;
  onRevertBookMonth: () => void;
  onChange: (files: File[]) => void;
  onChangeAccountingBasis: (string) => void;
  onChangeColors: (any) => void;
  onChangeCustomColors: (any) => void;
  accountingBasis: string | null;
  accountingIntegration: string;
  integration: string;
  hasAccountingBasis: boolean;
  showAccountNumbers: boolean;
  showAccountNumbersValue: boolean;
  sortWithAccountNumbers?: boolean;
  hideInactiveAccounts: boolean;
  lastUpdatedThreshold: number;
  developerCompany: boolean;
  isConsolidation: boolean;
  canToggleAccountNumbers: boolean;
  canToggleHideInactiveAccounts?: boolean;
  betaTestMode: boolean;
  show: boolean;
  defaultLogoUrl: string;
  defaultTheme: [];
  defaultThemeCustom: [];
  useLogoDefault: boolean;
  useThemeDefault: boolean;
  defaultLogoAndThemeSaved: boolean;
  demo: boolean;
  demoDataRefreshDate: any;
}

const GeneralSettings: React.FC<GeneralSettingsProps> = ({
  companyId,
  companyName,
  colors,
  customColors,
  show,
  showHelp,
  bookMonth,
  isBookMonthFixed,
  register,
  control,
  countryValue,
  currency,
  handleUnsavedChanges,
  onChange,
  onChangeBookMonth,
  onRevertBookMonth,
  defaultBudgetState,
  onChangeDefaultBudgetState,
  defaultForecastState,
  onChangeDefaultForecastState,
  accountingBasis,
  accountingIntegration,
  integration,
  onChangeAccountingBasis,
  hasAccountingBasis,
  onChangeColors,
  onChangeCustomColors,
  showAccountNumbers,
  showAccountNumbersValue,
  sortWithAccountNumbers,
  hideInactiveAccounts,
  lastUpdatedThreshold,
  developerCompany,
  isConsolidation,
  canToggleAccountNumbers,
  betaTestMode,
  defaultLogoUrl,
  defaultTheme,
  defaultThemeCustom,
  useLogoDefault,
  useThemeDefault,
  defaultLogoAndThemeSaved,
  demo,
  demoDataRefreshDate,
}) => {
  const theme = useTheme();
  const [showTheme, setShowTheme] = useState(false);
  const [change, setChange] = useState(false);
  const [showCurrencyModal, setShowCurrencyModal] = useState<boolean>(false);
  const [hideInactiveAccountsActive, setHideInactiveAccountsActive] =
    useState<boolean>(hideInactiveAccounts);
  const [disableLogoUpload, setDisableLogoUpload] = useState(useLogoDefault);
  const [disableThemeChange, setDisableThemeChange] = useState(useThemeDefault);

  const [logoPreview, setLogoPreview] = useState<
    string | ArrayBuffer | null | undefined
  >(null);

  const { data, loading } = useQuery(LOGO_QUERY, {
    variables: {
      companyId,
    },
  });

  const onChangeLogo = useCallback(
    (files: File[]) => {
      if (files.length > 0) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setLogoPreview(e?.target?.result);
          let logoUrl: string;
          if (e?.target?.result && !disableLogoUpload) {
            logoUrl = e?.target?.result || data?.company?.logo;
          } else {
            logoUrl = defaultLogoUrl;
          }
          getImagePalette(logoUrl, (logoColors) => {
            getImagePalette(data.company.logo.url, (currentLogoColors) => {
              getImagePalette(defaultLogoUrl, (defaultLogoColors) => {
                if (
                  equals(defaultLogoColors, colors) ||
                  equals(currentLogoColors, colors)
                ) {
                  onChangeColors(logoColors);
                }
              });
            });
          });
        };
        reader.readAsDataURL(files[0]);
        onChange(files);
      }
    },
    [
      colors,
      data.company.logo,
      defaultLogoUrl,
      disableLogoUpload,
      onChange,
      onChangeColors,
    ]
  );

  const [onUpdateDemoData] = useMutation(UPDATE_DEMO_DATA);

  const handleUpdateDemoData = useCallback(async () => {
    try {
      const confirmed = await showConfirm({
        title: 'Refresh Demo Data',
        text: 'Are you sure you want to refresh the demo data? This cannot be undone.',
      });

      if (confirmed) {
        Swal.fire({
          icon: 'info',
          text: 'Demo data updating...',
          toast: true,
          showConfirmButton: false,
        });

        const {
          data: { updateDemoData: success },
        } = await onUpdateDemoData({
          variables: {
            companyId,
          },
        });

        if (success) {
          setTimeout(() => {
            Swal.update({
              icon: 'success',
              text: 'Demo data updated!',
            });
            setTimeout(() => {
              Swal.close();
            }, 2000);
          }, 2000);
        }
      }
    } catch (err) {
      captureException(err, true);
    }
  }, [companyId, onUpdateDemoData]);

  const demoDataCanUpdate = useMemo(() => {
    if (demo && demoDataRefreshDate) {
      const end = moment.utc();
      const endDate = moment.utc(demoDataRefreshDate);
      const duration = moment.duration(end.diff(endDate));
      const days = Math.ceil(duration.asDays());
      if (days >= 30) {
        return true;
      }
    } else if (demo) {
      return true;
    }
    return false;
  }, [demoDataRefreshDate, demo]);

  const onChangeLogoError = ({
    title,
    message,
  }: {
    title: string;
    message: string;
  }) => {
    setLogoPreview(null);
    showError({ title, text: message });
  };

  const [onFullSync] = useMutation(FULL_SYNC);

  const budgetValue = useMemo(() => {
    if (defaultBudgetState) {
      const [type, id] = defaultBudgetState.split('_');
      return {
        budgetType: type,
        budgetId: parseInt(id, 10),
      };
    }
    return null;
  }, [defaultBudgetState]);

  const forecastValue = useMemo(() => {
    if (defaultForecastState) {
      const [type, id] = defaultForecastState.split('_');
      return {
        budgetType: type,
        budgetId: parseInt(id, 10),
      };
    }
    return null;
  }, [defaultForecastState]);

  const handleChange = useCallback(() => {
    if (!change) {
      setChange(true);
      handleUnsavedChanges();
    }
  }, [change, setChange, handleUnsavedChanges]);

  const handleUseLogoDefault = useCallback(() => {
    handleChange();
    setDisableLogoUpload(!disableLogoUpload);
    let currentLogoUrl: string;
    let nextLogoUrl: string;
    if ((logoPreview || data?.company?.logo) && !disableLogoUpload) {
      currentLogoUrl = logoPreview || data.company.logo.url;
      nextLogoUrl = defaultLogoUrl;
    } else {
      currentLogoUrl = defaultLogoUrl;
      nextLogoUrl = logoPreview || data?.company?.logo?.url;
    }

    getImagePalette(currentLogoUrl, (currentLogoColors) => {
      if (equals(currentLogoColors, colors)) {
        getImagePalette(nextLogoUrl, (nextLogoColors) => {
          onChangeColors(nextLogoColors);
        });
      }
    });
  }, [
    colors,
    data.company.logo,
    defaultLogoUrl,
    disableLogoUpload,
    handleChange,
    logoPreview,
    onChangeColors,
  ]);

  const handleThemeClose = useCallback(() => {
    setShowTheme(false);
  }, []);

  const handleFullSync = useCallback(async () => {
    try {
      await onFullSync({
        variables: {
          companyId,
        },
      });

      window.location.reload();
    } catch (err) {
      captureException(err, true);
    }
  }, [companyId, onFullSync]);

  const currencyPreview = useMemo(() => {
    if (!countryValue || !countries[countryValue]) return '';
    return formatCurrency(12345678.12, countryValue);
  }, [countryValue]);

  if (!data) {
    return null;
  }

  return (
    <>
      <SettingTitle>General Settings</SettingTitle>
      {showHelp && <p>This can be managed in the workspace home</p>}
      <FieldGroup>
        <LabelCol>
          <Label htmlFor="companyName">Company Name</Label>
        </LabelCol>
        <InputCol>
          <Input
            {...register('nameOverride')}
            id="companyName"
            name="nameOverride"
            type="text"
            placeholder={companyName}
            onChange={handleChange}
            autoComplete="off" data-1p-ignore
          />
        </InputCol>
      </FieldGroup>
      <FieldGroup>
        <LabelCol />
        <InputCol>
          <AppearanceSettings>
            <div>
              <LogoUploader
                loading={loading}
                onStage={onChangeLogo}
                defaultImageUrl={
                  disableLogoUpload
                    ? defaultLogoUrl
                    : logoPreview || data.company.logo.url
                }
                onError={onChangeLogoError}
                disabled={disableLogoUpload}
              />
              {defaultLogoAndThemeSaved && (
                <BulkUpdateDiv>
                  <BulkUpdateCheckboxDiv>
                    <CheckBox
                      style={{ cursor: 'pointer' }}
                      {...register('useLogoDefault')}
                      id="useLogoDefault"
                      disabled={!defaultLogoUrl}
                      name="useLogoDefault"
                      type={'checkbox'}
                      onChange={defaultLogoUrl ? handleUseLogoDefault : null}
                    />
                  </BulkUpdateCheckboxDiv>
                  <BulkUpdateText
                    style={{ marginTop: '2px' }}
                    htmlFor="useLogoDefault"
                  >
                    {defaultLogoUrl
                      ? 'Use Workspace Logo Default'
                      : 'No Workspace Logo Default Set'}
                    <Help>
                      The workspace owner can set the default logo in workspace
                      settings.
                    </Help>
                  </BulkUpdateText>
                </BulkUpdateDiv>
              )}
            </div>
            <div>
              <ColorTheme
                id={companyId}
                type={'company'}
                theme={theme}
                logoPreview={logoPreview}
                logo={data.company.logo.url}
                colors={disableThemeChange ? defaultTheme : colors}
                customColors={customColors}
                onClick={() => {
                  if (!disableThemeChange) {
                    setShowTheme(true);
                  }
                }}
                onChangeColors={onChangeColors}
                defaultLogoUrl={defaultLogoUrl}
                defaultTheme={defaultTheme}
                defaultThemeCustom={defaultThemeCustom}
                disabled={disableThemeChange}
                disableLogoUpload={disableLogoUpload}
              />

              {defaultLogoAndThemeSaved && (
                <BulkUpdateDiv>
                  <BulkUpdateCheckboxDiv>
                    <CheckBox
                      style={{ cursor: 'pointer' }}
                      {...register('useThemeDefault')}
                      disabled={!defaultTheme}
                      id="useThemeDefault"
                      name="useThemeDefault"
                      type={'checkbox'}
                      onChange={
                        !defaultTheme
                          ? () => handleChange()
                          : () => {
                              setDisableThemeChange(!disableThemeChange);
                              handleChange();
                            }
                      }
                    />
                  </BulkUpdateCheckboxDiv>
                  <BulkUpdateText
                    style={{ marginTop: '2px' }}
                    htmlFor="useThemeDefault"
                  >
                    {!defaultTheme
                      ? 'No Workspace Theme Default Set'
                      : 'Use Workspace Theme Default'}
                    <Help>
                      The workspace owner can set the default theme in workspace
                      settings.
                    </Help>
                  </BulkUpdateText>
                </BulkUpdateDiv>
              )}
            </div>
          </AppearanceSettings>
        </InputCol>
      </FieldGroup>

      {!!accountingIntegration && (
        <>
          <FieldGroup>
            <LabelCol style={{ marginTop: -24 }}>Default Budget</LabelCol>
            <InputCol>
              <BudgetSplit>
                <BudgetSplitSection>
                  <StyledBudgetSelect
                    key={`budgetSelect_${show.toString()}`}
                    warning={!budgetValue || !budgetValue.budgetId}
                    onChange={({
                      budgetId: newBudgetId,
                      budgetType: newBudgetType,
                    }) =>
                      onChangeDefaultBudgetState(
                        `${newBudgetType}_${newBudgetId}`
                      )
                    }
                    value={budgetValue}
                    portal
                    maxMenuHeight={150}
                    includeConsolidation={isConsolidation}
                    companyId={companyId}
                    isDefaultPicker
                  />
                </BudgetSplitSection>
                <BudgetSplitSection>
                  <div>
                    <Input
                      {...register('forecastSuffix')}
                      id="forecastSuffix"
                      name="forecastSuffix"
                      type="text"
                      placeholder="Budget/Forecast Suffix"
                      onChange={handleChange}
                      maxLength={20}
                      autoComplete="off" data-1p-ignore
                    />
                  </div>
                  <BulkUpdateDiv>
                    <BulkUpdateCheckboxDiv>
                      <CheckBox
                        {...register('forecastSuffixOverride')}
                        id="forecastSuffixOverride"
                        name="forecastSuffixOverride"
                        type={'checkbox'}
                        onChange={() => handleChange()}
                      />
                    </BulkUpdateCheckboxDiv>
                    <BulkUpdateText htmlFor="forecastSuffixOverride">
                      Update suffix for all metrics
                    </BulkUpdateText>
                  </BulkUpdateDiv>
                </BudgetSplitSection>
              </BudgetSplit>
            </InputCol>
          </FieldGroup>
          <FieldGroup>
            <LabelCol>Default Forecast</LabelCol>
            <InputCol>
              <BudgetSplit>
                <BudgetSplitSection>
                  <StyledBudgetSelect
                    key={`forecastSelect_${show.toString()}`}
                    warning={!forecastValue || !forecastValue.budgetId}
                    onChange={({
                      budgetId: newForecastId,
                      budgetType: newForecastType,
                    }) =>
                      onChangeDefaultForecastState(
                        `${newForecastType}_${newForecastId}`
                      )
                    }
                    value={forecastValue}
                    portal
                    maxMenuHeight={150}
                    includeConsolidation={isConsolidation}
                    companyId={companyId}
                    isDefaultPicker
                  />
                </BudgetSplitSection>
              </BudgetSplit>
            </InputCol>
          </FieldGroup>
        </>
      )}
      <FieldGroup>
        <LabelCol>Currency Symbol</LabelCol>
        <InputCol
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Controller
            control={control}
            name="currency"
            defaultValue={currency}
            render={({ field: { onChange: onCurrencyChange, value } }) => (
              <>
                <LaunchCurrencyModal
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    setShowCurrencyModal(true);
                  }}
                >{`${countries[value].name} ${countries[value].currency} ${countries[value].symbol}`}</LaunchCurrencyModal>
                <CurrencyModal
                  show={showCurrencyModal}
                  onClose={() => setShowCurrencyModal(false)}
                  currency={value}
                  onSubmit={(country: { country: string } & Country) =>
                    onCurrencyChange(country.country)
                  }
                />
              </>
            )}
          />
          <CurrencyPreview>{currencyPreview}</CurrencyPreview>
        </InputCol>
      </FieldGroup>
      {canToggleAccountNumbers && (
        <>
          <FieldGroup>
            <LabelCol>Account Numbers</LabelCol>
            <InputCol>
              <AccountNumbersSelect
                formControl={control}
                name={'showAccountNumbers'}
                defaultValue={showAccountNumbers}
                handleChange={handleChange}
              />
            </InputCol>
          </FieldGroup>
          {!showAccountNumbersValue && (
            <FieldGroup>
              <LabelCol>Sort Using Account Numbers</LabelCol>
              <InputCol>
                <SortWithAccountNumbersSelect
                  formControl={control}
                  name={'sortWithAccountNumbers'}
                  defaultValue={sortWithAccountNumbers}
                  handleChange={handleChange}
                />
              </InputCol>
            </FieldGroup>
          )}
        </>
      )}
      {integration === 'QBO' && !isConsolidation && (
        <>
          <FieldGroup>
            <LabelCol
              style={{
                marginTop: -18,
              }}
            >
              Hide Inactive Accounts
            </LabelCol>
            <InputCol>
              <HideInactiveAccountsSelect
                formControl={control}
                name={'hideInactiveAccounts'}
                defaultValue={hideInactiveAccounts}
                handleChange={handleChange}
                setHideInactiveAccountsActive={setHideInactiveAccountsActive}
              />
              <HelperText>
                This will NOT recalculate totals, but will just hide these
                accounts.
              </HelperText>
            </InputCol>
          </FieldGroup>
          {hideInactiveAccountsActive && (
            <FieldGroup>
              <LabelCol>Last Updated Threshold</LabelCol>
              <InputCol>
                <LastUpdatedThresholdSelect
                  formControl={control}
                  name={'lastUpdatedThreshold'}
                  defaultValue={lastUpdatedThreshold}
                  handleChange={handleChange}
                />
                <HelperText>
                  Inactive accounts that had been set as inactive before
                  threshold will not show. This will also apply to any
                  consolidated companies using this QBO company.
                </HelperText>
              </InputCol>
            </FieldGroup>
          )}
          <FieldGroup>
            <LabelCol>
              Aging Reports follow Book Month{' '}
              <Help>
                The company will need to be synced anytime the book month or
                this setting changes.
              </Help>
            </LabelCol>
            <InputCol>
              <CheckBox
                {...register('agingBookMonth')}
                id="agingBookMonth"
                name="agingBookMonth"
                type={'checkbox'}
                onChange={() => handleChange()}
              />
            </InputCol>
          </FieldGroup>
        </>
      )}
      {hasAccountingBasis && (
        <FieldGroup>
          <LabelCol>Reporting Basis</LabelCol>
          <InputCol>
            <Select
              testId="accounting-basis-select"
              options={basisOptions}
              value={accountingBasis}
              onChange={onChangeAccountingBasis}
            />
          </InputCol>
        </FieldGroup>
      )}

      <BookMonth
        bookMonth={bookMonth}
        isBookMonthFixed={isBookMonthFixed}
        onChangeBookMonth={onChangeBookMonth}
        onRevertBookMonth={onRevertBookMonth}
        register={register}
        handleChange={handleChange}
      />
      {demo && (
        <>
          <FieldGroup>
            <LabelCol>
              Refresh Demo Data
              <Help>
                Demo company data can be refreshed once every 30 days. This only
                affects the date ranges of the data. You will not lose your
                dashboards, reports, or any other customizations.
              </Help>
            </LabelCol>
            <InputCol>
              <UpdateDemoDataButton
                onClick={handleUpdateDemoData}
                type={'button'}
                disabled={!demoDataCanUpdate}
              >
                Refresh
              </UpdateDemoDataButton>
            </InputCol>
          </FieldGroup>
        </>
      )}
      <ThemesModal
        id={companyId}
        type={'company'}
        show={showTheme}
        noMessage
        colors={colors}
        customColors={customColors}
        onClose={handleThemeClose}
        onChangeColors={onChangeColors}
        onChangeCustomColors={onChangeCustomColors}
        onAddLogo={onChangeLogo}
        disabled={disableLogoUpload}
        onLogoUploadError={onChangeLogoError}
        defaultImageUrl={
          disableLogoUpload
            ? defaultLogoUrl
            : logoPreview || data.company.logo.url
        }
      />
      {developerCompany ? (
        <>
          <FieldGroup>
            <LabelCol>
              <BetaLabel>Beta Test Mode</BetaLabel>
            </LabelCol>
            <InputCol>
              <CheckBox
                {...register('betaTestMode')}
                id="betaTestMode"
                name="betaTestMode"
                type={'checkbox'}
                onChange={() => handleChange()}
              />

              {betaTestMode && (
                <Button
                  color="info"
                  outline
                  type="button"
                  onClick={handleFullSync}
                >
                  Perform full sync
                </Button>
              )}
            </InputCol>
          </FieldGroup>
          <BetaTestText>
            Expect sync times to take several minutes or more depending on the
            size of your company
          </BetaTestText>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default GeneralSettings;
