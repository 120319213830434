import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

const cellStyle = css`
  margin: 0;
  padding: 0.5rem;
`;

export const Thead = styled.thead``;

export const Th = styled.th<{ isSorted: boolean }>`
  ${cellStyle}
  background: ${({ isSorted, theme }) =>
    theme.table.header[isSorted ? 'sortedBackground' : 'background']};
  i {
    margin-left: 0.5rem;
  }
`;

export const Td = styled.td`
  ${cellStyle}
`;

export const Tbody = styled.tbody`
  tr {
    transition: background-color 0.05s ease-in-out;
  }
  tr:not(.expanded-row):hover {
    background-color: ${({ theme }) => theme.table.row.hover};
  }
  tr: ;
`;

export const Table = styled.table<{
  paddingBottom: CSSProperties['paddingBottom'];
}>`
  font-size: 12px;
  border-spacing: 0;
  table-layout: fixed;

  tr {
    ${({ paddingBottom }) =>
      paddingBottom
        ? css`
            :nth-last-child(2) {
              td {
                border-bottom: 0;
              }
            }

            :last-child:hover {
              background-color: inherit;
            }
          `
        : css`
            :last-child {
              td {
                border-bottom: 0;
              }
            }
          `}
  }

    &.expanded-row {
      border-bottom: ${({ theme }) => theme.table.header.background} 1px solid;
      border-top: ${({ theme }) => theme.table.header.background} 1px solid;
    }
  }

  th,
  td {
    :last-child {
      border-right: 0;
    }
  }
`;
