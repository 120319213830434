/* global sessionStorage */

import { makeVar } from '@apollo/client';
import { countries } from 'library/currency';

export const currencyCountry = makeVar('US');

export const currencySymbol = makeVar('$');

export function setCurrencyCode(countryCode) {
  currencySymbol(countries[countryCode].symbol);
}

export const companiesSelectedGroup = makeVar(
  parseInt(
    (sessionStorage && sessionStorage.getItem('companiesSelectedGroup')) ||
      '-1',
    10
  )
);

export const showLoginModal = makeVar(false);

export function setSelectedGroup(groupId) {
  sessionStorage.setItem('companiesSelectedGroup', groupId);
  companiesSelectedGroup(groupId);
}

export const parentWindowScrollInfo = makeVar(null);

export default {
  companiesSelectedGroup,
  showLoginModal,
  parentWindowScrollInfo,
};
