import scrollbar from 'css/scrollbar';
import styled, { css, Keyframes, keyframes } from 'styled-components';
import { dropdownContentZIndex } from 'utils/zIndex';

const fadeIn = keyframes`${{
  from: {
    transform: 'translate(0, 20px)',
  },
  to: {
    transform: 'translate(0, 1px)',
  },
}}`;

const fadeInCenter = keyframes`${{
  from: {
    transform: 'translate(-50%, 20px)',
  },
  to: {
    transform: 'translate(-50%, 1px)',
  },
}}`;

export const Div = styled.div`
  position: relative;
`;

export const ButtonWrapper = styled.div<{ align: string }>`
  text-align: ${({ align }) => align};
`;

export const Content = styled.div<{
  width: number;
  align: 'right' | 'center';
  open: boolean;
  animation: Keyframes;
  showScroll: boolean;
  zIndex: number;
  overflowXHidden: boolean;
  noAnimation: boolean;
}>`
  position: absolute;
  ${({ align }) =>
    align === 'right'
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `}
  ${({ align }) =>
    align === 'center' &&
    css`
      left: 50%;
      transform: translateX(-50%);
    `}
  width: ${(props) => props.width}px;
  box-shadow: ${(props) => props.theme.dropdown.boxShadow};
  border: none;
  border-radius: 4px;
  z-index: ${({ zIndex }) => zIndex || dropdownContentZIndex};
  background: ${(props) => props.theme.dropdown.background};
  color: ${(props) => props.theme.dropdown.color};
  font-weight: 300;
  padding: 0.5rem 0;
  display: none;
  ${({ overflowXHidden }) =>
    overflowXHidden &&
    css`
      overflow-x: hidden;
    `}
  ${({ open }) =>
    open &&
    css`
      display: block;
    `}

    ${({ open, animation, align, noAnimation }) =>
    open &&
    !noAnimation &&
    css`
      animation: ${animation || (align === 'center' ? fadeInCenter : fadeIn)}
        0.25s linear;
    `}

  & i {
    color: ${({ theme }) => theme.dropdown.icon};
  }

  & input[type='text'] {
    color: ${({ theme }) => theme.dropdown.color};
  }
  & button {
    color: ${({ theme }) => theme.dropdown.color};
  }

  ${({ showScroll }) =>
    showScroll &&
    css`
      overflow-y: auto;
      max-height: 600px;
      ${scrollbar()}
    `}
`;

export const ErrorMsg = styled.div`
  padding: 0.5rem;
  font-size: 0.775rem;
`;
