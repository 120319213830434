export function getAllValues(children) {
  let result = [];
  children.forEach((child) => {
    result.push(child.value);
    if (child.children && child.children.length) {
      result = result.concat(getAllValues(child.children));
    }
  });
  return result;
}

export function getAllNamesAndValues(children) {
  let result = [];
  children.forEach((child) => {
    result.push({
      name: child.name,
      value: child.value,
    });
    if (child.children && child.children.length) {
      result = result.concat(getAllNamesAndValues(child.children));
    }
  });
  return result;
}

export function getAllChildren(children) {
  let result = [];
  children.forEach((child) => {
    result.push(child);
    if (child.children && child.children.length) {
      result = result.concat(getAllChildren(child.children));
    }
  });
  return result;
}

export function filteredOnSearch(data, term) {
  if (term !== '') {
    const searchTerm = term.toLowerCase();
    const checkboxArray = getAllNamesAndValues(data);
    const filteredCheckboxArray = checkboxArray
      .filter(
        (item) =>
          item.name.split('_').join(' ').toLowerCase().includes(searchTerm) ||
          item.name.toLowerCase().includes(searchTerm)
      )
      .map((item) => {
        return item.value;
      });
    const allChildren = getAllChildren(data);
    const updatedChildren = allChildren.map((item) => {
      const updatedObject = { children: [] };
      const keys = Object.keys(item).filter((i) => i !== 'children');
      keys.forEach((key) => {
        updatedObject[key] = item[key];
      });
      return updatedObject;
    });
    const updatedFilteredItems = updatedChildren.filter((item) =>
      filteredCheckboxArray.includes(item.value)
    );
    return updatedFilteredItems;
  } else {
    return data;
  }
}

const totalTypes = ['total', 'accountTotal', 'cfTotal', 'cfAccountTotal'];

export function buildItemList(
  children,
  single,
  disableTotals,
  expanded,
  selected,
  onToggle,
  onSelect,
  onSelectAllForItem,
  onDeselectAllForItem,
  level = 0,
  visible = true,
  itemData = {}
) {
  if (!itemData.onToggle) {
    itemData.onToggle = onToggle;
    itemData.onSelect = onSelect;
    itemData.onSelectAllForItem = onSelectAllForItem;
    itemData.onDeselectAllForItem = onDeselectAllForItem;
    itemData.single = single;
    itemData.rows = [];
  }

  if (!visible) {
    return itemData;
  }

  if (!children) return itemData;

  children.forEach((child) => {
    const itemType = child.value.split('_')[0];

    const disabled = disableTotals && totalTypes.includes(itemType);
    // Hides labels that can't be selected and don't have children
    if (child.children && !child.children.length && disabled) {
      return;
    }

    const rowData = {
      hasArrow: child.children && !!child.children.length,
      expanded: expanded.includes(child.value),
      checked: selected.includes(child.value),
      disabled: child.disabled || disabled,
      level,
      item: child,
    };

    itemData.rows.push(rowData);

    if (child.children) {
      buildItemList(
        child.children,
        single,
        disableTotals,
        expanded,
        selected,
        onToggle,
        onSelect,
        onSelectAllForItem,
        onDeselectAllForItem,
        level + 1,
        expanded.includes(child.value),
        itemData
      );
    }
  });

  return itemData;
}

export function getDefaultExpanded(single, defaultPath) {
  if (!single || !defaultPath) return [];

  return defaultPath.slice(1, defaultPath.length - 1);
}
