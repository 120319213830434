import React from 'react';
import { Query } from '@apollo/client/react/components';
import gql from 'graphql-tag';
import * as SentryApi from '@sentry/browser';
import LogRocketApi from 'logrocket';

import SentryUser from './User';
import logRocketId from 'utils/logRocketId';

const QUERY = gql`
  query Sentry {
    isLoggedIn @client
  }
`;

class Sentry extends React.PureComponent {
  render() {
    return (
      <Query query={QUERY}>
        {({ data, error, loading }) => {
          if (loading) {
            return null;
          }

          if (data.isLoggedIn) {
            return <SentryUser />;
          }

          if (logRocketId) {
            LogRocketApi.getSessionURL((sessionURL) => {
              SentryApi.configureScope((scope) => {
                scope.setExtra('sessionURL', sessionURL);
              });
            });
          }

          SentryApi.configureScope((scope) => {
            scope.setUser(null);
          });
          return null;
        }}
      </Query>
    );
  }
}

export default Sentry;
