import scrollbar from 'css/scrollbar';
import styled, { css, keyframes } from 'styled-components';

export const EXPAND_TIME_MS = 250;

export const DrawerContainer = styled.div`
  position: relative;
`;

export const Drawer = styled.div<{
  height?: number;
  showScrollbar: boolean;
  noAnimation: boolean;
}>`
  overflow-y: ${({ showScrollbar }) => (showScrollbar ? 'auto' : 'hidden')};
  overflow-x: hidden;

  height: ${(props: { height?: number }) =>
    props.height ? props.height : 0}px;

  margin-top: ${(props: { height?: number }) => (props.height ? 5 : 0)}px;

  padding-top: ${({ height }) => (height ? 5 : 0)}px;

  ${({ noAnimation }) =>
    !noAnimation &&
    css`
      transition: height ${EXPAND_TIME_MS}ms ease-out,
        margin-top ${EXPAND_TIME_MS}ms ease-out,
        padding-top ${EXPAND_TIME_MS}ms ease-out;
    `}

  border-top: ${({ theme }) => theme.dropdownDrawer.borders} solid
    ${(props: { height?: number }) => (props.height ? 2 : 0)}px;

  border-bottom: ${({ theme }) => theme.dropdownDrawer.borders} solid
    ${(props: { height?: number }) => (props.height ? 2 : 0)}px;

  ${scrollbar()}
`;

export const arrowFadeOut = keyframes`
  100% {
    opacity: 1;
  }
`;

export const ArrowIcon = styled.div<{ showScrollArrow: boolean }>`
  position: absolute;
  bottom: 0;
  right: 5px;
  opacity: 0;
  ${(props: { showScrollArrow: boolean }) =>
    props.showScrollArrow &&
    css`
      animation: ${arrowFadeOut} 1s alternate infinite ease-out;
    `};
`;

export const DropdownIcon = styled.div<{ open: boolean }>`
  position: absolute;
  right: 5px;
  top: 0;
  & > i {
    transition: color 100ms ease-out;
    ${(props: { open: boolean }): any =>
      props.open
        ? css`
            color: #617182;
          `
        : css`
            color: #dee2e6;
          `}
  }
`;

export const Container = styled.div<{
  marginRight: number;
  marginBottom: number;
}>`
  display: flex;
  flex-direction: column;
  margin-right: ${({ marginRight }) => marginRight || 0}px;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  justify-content: center;
  position: relative;
  cursor: pointer;
`;

export const IconContainer = styled.button<{
  expanded: boolean;
  width?: string;
  focused: boolean;
}>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 10px 5px;
  border-radius: 5px;
  background-color: ${({ expanded, theme, focused }) =>
    expanded || focused
      ? theme.dropdownDrawer.expandedBackgroundColor
      : theme.dropdownDrawer.backgroundColor};
  transition: background-color 100ms ease-out;
  width: ${(props) => props.width || 'auto'};
  &:hover {
    background-color: ${({ theme }) =>
      theme.dropdownDrawer.expandedBackgroundColor};
  }
  color: ${({ theme }) => theme.dropdownDrawer.color};
`;

export const Text = styled.div`
  font-weight: bolder;
  font-size: 1.1rem;
  line-height: 1;
`;
