import gql from 'graphql-tag';

export const QUERY = gql`
  query LastSynced($companyId: Int!, $workspaceId: Int!) {
    workspace(id: $workspaceId) {
      id
      syncLimit
    }
    company(id: $companyId) {
      id
      demo
      currentSyncJob {
        id
        integration
        syncing
        progress
        stage
      }
      lastSyncedByIntegration {
        lastSynced
        integration
      }
      accounting {
        integration
      }
      isConsolidation
      canSyncConsolidation
      syncTimestamps
      consolidationCompanies {
        id
        name
        syncTimestamps
      }
    }
  }
`;

export const SYNC = gql`
  mutation Sync($companyId: Int!, $integration: Integration!, $utcOffset: Int) {
    syncCompanyIntegration(
      companyId: $companyId
      integration: $integration
      utcOffset: $utcOffset
    ) {
      id
      syncing
      progress
      stage
      connectionLost
      error
    }
  }
`;

export const SYNCED = gql`
  subscription SyncJobFinished($companyId: Int!) {
    syncJobFinished(companyId: $companyId) {
      id
      syncing
      progress
      stage
      connectionLost
      unsyncedCompanies
      error
    }
  }
`;

export const SYNC_JOB = gql`
  subscription SyncJob($companyId: Int!) {
    syncJob(companyId: $companyId) {
      id
      syncing
      progress
      stage
      integration
    }
  }
`;

export const SYNC_QBD = gql`
  subscription SyncQbd($companyId: Int!) {
    syncQbd(companyId: $companyId)
  }
`;
