/**
 *
 * Alert
 *
 */

import React from 'react';
import styled from 'styled-components';

import Animation from 'components/Animation';

const Div = styled.div`
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: opacity 0.15s linear;
  font-size: 0.875rem;
  background: ${props => props.theme.colors[props.color]};
  border-color: ${props => props.theme.colors[props.color]};
  color: white;
  ${props => props.outline && `color: ${props.theme.colors[props.color]};`};
  ${props => props.outline && 'background: white;'};
`;

class Alert extends React.PureComponent {
  render() {
    const { children, ...rest } = this.props;
    return (
      <Animation type="fadeIn">
        <Div {...rest}>{children}</Div>
      </Animation>
    );
  }
}

export default Alert;
