/**
 *
 * TemplateJobWatcher
 *
 */
import { useCallback } from 'react';
import { gql, useSubscription } from '@apollo/client';
import {
  DASHBOARD_LIST,
  LIBRARY_TABLE_FIELDS,
  REPORT_LIST_FIELDS,
} from 'fragments';
import useCompanyId from 'hooks/useCompanyId';
import { REPORT_LIST } from 'routes/ReportList/queries';
import produce from 'immer';
import { showInfo } from 'utils/popups';
import DASHBOARD_LIST_QUERY from 'queries/dashboardList';
import useThemeMode from 'hooks/useThemeMode';

const SUBSCRIPTION = gql`
  subscription TemplateJobFinished(
    $companyId: Int!
    $itemType: ItemType!
    $theme: Theme
  ) {
    templateJobFinished(companyId: $companyId, itemType: $itemType) {
      item {
        ... on Dashboard {
          ...DashboardListFields
        }
        ... on Report {
          ...ReportListFields
        }
      }
      messages
    }
  }
  ${REPORT_LIST_FIELDS}
  ${DASHBOARD_LIST}
`;

const LIBRARY_SUBSCRIPTION = gql`
  subscription TemplateLibraryJobFinished(
    $companyId: Int!
    $itemType: ItemType!
    $theme: Theme
  ) {
    templateLibraryJobFinished(companyId: $companyId, itemType: $itemType) {
      itemType
      item {
        ... on Dashboard {
          ...DashboardListFields
        }
        ... on Report {
          ...ReportListFields
        }
        ... on Table {
          ...LibraryTableFields
          isDatasheet
        }
      }
    }
  }
  ${REPORT_LIST_FIELDS}
  ${DASHBOARD_LIST}
  ${LIBRARY_TABLE_FIELDS}
`;

function TemplateJobWatcher() {
  const companyId = useCompanyId();
  const mode = useThemeMode();

  const onSubscriptionData = useCallback(
    (
      {
        client,
        data: {
          data: {
            templateJobFinished: { item: newItem, messages },
          },
        },
      },
      query,
      key
    ) => {
      client.cache.updateQuery(
        { query, variables: { companyId, theme: mode.toUpperCase() } },
        produce((draft) => {
          if (!draft || !draft.company) return;

          if (draft.company[key].find((item) => item.id === newItem.id)) return;

          const pendingIndex = draft.company[key].findIndex(
            (item) => item.id < 0 && item.name === newItem.name
          );
          if (pendingIndex > -1) {
            draft.company[key].splice(pendingIndex, 1, newItem);
          } else {
            draft.company[key].unshift(newItem);
          }

          if (messages) {
            (async () => {
              for (let i = 0; i < messages.length; i++) {
                await showInfo({
                  width: 800,
                  html: messages[i],
                });
              }
            })();
          }
        })
      );
    },
    [companyId, mode]
  );

  const handleLibrarySubscription = useCallback(
    ({
      client: { cache },
      data: {
        data: {
          templateLibraryJobFinished: { itemType, item },
        },
      },
    }) => {
      if (itemType === 'DASHBOARD') {
        cache.evict({
          id: `Company:${companyId}`,
          fieldName: 'libraryDashboards',
        });
        cache.evict({
          id: `Company:${companyId}`,
          fieldName: 'datasheets',
        });
        cache.gc();
      } else if (itemType === 'REPORT') {
        cache.evict({
          id: `Company:${companyId}`,
          fieldName: 'libraryReports',
        });
        cache.evict({
          id: `Company:${companyId}`,
          fieldName: 'datasheets',
        });
        cache.gc();
      } else if (itemType === 'TABLE') {
        if (item.isDatasheet) {
          cache.evict({
            id: `Company:${companyId}`,
            fieldName: 'libraryDatasheets',
          });
        } else {
          cache.evict({
            id: `Company:${companyId}`,
            fieldName: 'libraryTablesPaged',
          });
          cache.evict({
            id: `Company:${companyId}`,
            fieldName: 'libraryStatements',
          });
          cache.evict({
            id: `Company:${companyId}`,
            fieldName: 'libraryTablesNoStatementsPaged',
          });
          cache.evict({
            id: `Company:${companyId}`,
            fieldName: 'libraryTables',
          });
        }
        cache.gc();
      }
    },
    [companyId]
  );

  const handleReport = useCallback(
    (data) => {
      onSubscriptionData(data, REPORT_LIST, 'reports');
    },
    [onSubscriptionData]
  );
  const handleDashboard = useCallback(
    (data) => {
      onSubscriptionData(data, DASHBOARD_LIST_QUERY, 'dashboards');
    },
    [onSubscriptionData]
  );

  useSubscription(SUBSCRIPTION, {
    variables: {
      companyId,
      itemType: 'REPORT',
    },
    onData: handleReport,
    skip: !companyId,
  });
  useSubscription(SUBSCRIPTION, {
    variables: {
      companyId,
      itemType: 'DASHBOARD',
      theme: mode.toUpperCase(),
    },
    onData: handleDashboard,
    skip: !companyId,
  });
  useSubscription(LIBRARY_SUBSCRIPTION, {
    variables: {
      companyId,
      itemType: 'REPORT',
      theme: mode.toUpperCase(),
    },
    onData: handleLibrarySubscription,
    skip: !companyId,
  });
  useSubscription(LIBRARY_SUBSCRIPTION, {
    variables: {
      companyId,
      itemType: 'DASHBOARD',
      theme: mode.toUpperCase(),
    },
    onData: handleLibrarySubscription,
    skip: !companyId,
  });
  useSubscription(LIBRARY_SUBSCRIPTION, {
    variables: {
      companyId,
      itemType: 'TABLE',
      theme: mode.toUpperCase(),
    },
    onData: handleLibrarySubscription,
    skip: !companyId,
  });

  return null;
}

export default TemplateJobWatcher;
