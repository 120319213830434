/**
 *
 * CompanyBudgetSelect
 *
 */
import React from 'react';
import { useController } from 'react-hook-form';
import styled from 'styled-components';

import BudgetSelect from 'components/BudgetSelect';
import { SelectWrapper } from './styledComponents';

const StyledBudgetSelect = styled(BudgetSelect)`
  margin-right: 0;
`;

interface BudgetSelectProps {
  control: any;
  name: string;
  defaultValue: any;
  companyId: number;
  budgetType: string;
}

const CompanyBudgetSelect: React.FC<BudgetSelectProps> = ({
  control,
  name,
  defaultValue,
  companyId,
  budgetType,
}) => {
  const {
    field: { onChange: onBudgetChange, value },
  } = useController({
    name,
    control,
    defaultValue,
    rules: { required: true },
  });

  return (
    <SelectWrapper>
      <StyledBudgetSelect
        key={`budgetSelect-${value === null ? 'null' : 'selected'}`}
        onChange={onBudgetChange}
        value={value}
        companyId={companyId}
        budgetType={budgetType}
        portal
        menuPosition="fixed"
      />
    </SelectWrapper>
  );
};

export default CompanyBudgetSelect;
