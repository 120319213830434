/**
 *
 * Consolidations
 *
 */

import React from 'react';
import styled from 'styled-components';

import VerticalTabs from 'components/VerticalTabs';
import { LeftContainer as ContainerBase } from 'components/ItemSelectFlowStructure';

const LeftContainer = styled(ContainerBase)`
  flex: 1;
`;

class Consolidations extends React.PureComponent {
  render() {
    const { companies, current, onChange, company } = this.props;

    if (!companies || !companies.length) {
      return null;
    }

    const tabs = [company.name];

    tabs.push(...companies.map((_company) => _company.name));

    return (
      <LeftContainer>
        <VerticalTabs tabs={tabs} current={current} onChange={onChange} />
      </LeftContainer>
    );
  }
}

export default Consolidations;
