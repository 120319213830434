import React, { useCallback, useMemo } from 'react';
import useBudgets from 'hooks/useBudgets';
import Select, { combineStyles } from 'components/Select';
import styled, { useTheme } from 'styled-components';
import useIsConsolidation from 'hooks/useIsConsolidation';
import R from 'ramda';
import useAccountingIntegrationId from 'hooks/useAccountingIntegrationId';

const StyledSelect = styled(Select)`
  flex-grow: 1;
  margin-right: ${({ marginRight }) => marginRight}px;
`;

const WarningText = styled.div`
  color: ${(props) => props.theme.colors.danger};
  font-size: ${({ scale }) => scale * 0.75}rem;
`;

const sortByNameCaseInsensitive = R.sortBy(
  R.compose(R.toLower, R.prop('label'))
);

const BudgetSelect = ({
  className = null,
  onChange,
  styles = null,
  value,
  budgetType = null,
  companyId = -1,
  includeCustom = false,
  showNoBudgetOption = false,
  includeConsolidation = false,
  portal = false,
  maxMenuHeight = 0,
  marginRight = 5,
  showDefaultBudgetOption = false,
  showDefaultForecastOption = false,
  showForecastBaseOptions = false,
  warning = false,
  compact = false,
  scale = 1,
  menuShouldScrollIntoView = true,
  menuPosition = undefined,
  showDefault = 'budget', // either 'budget' or 'forecast'
  isDefaultPicker = false,
  testId = null
}) => {
  const theme = useTheme();

  const accountingIntegration = useAccountingIntegrationId();

  const {
    budgetGroups,
    companyBudgets,
    accountingBudgets,
    defaultBudgetId,
    defaultBudgetType,
    defaultForecastId,
    defaultForecastType,
  } = useBudgets(companyId !== -1 ? companyId : null);
  const isConsolidation = useIsConsolidation(
    companyId !== -1 ? companyId : null
  );
  // const [budgetValue, setBudgetValue] = useState(defaultValue);

  const noBudgetsPresent =
    !includeCustom &&
    showNoBudgetOption &&
    companyBudgets &&
    accountingBudgets &&
    !companyBudgets.length &&
    !accountingBudgets.length &&
    !isConsolidation;

  const combinedStyles = useMemo(() => {
    const selectStyles = {
      option: (provided, state) => {
        if (
          state.value === 'INTEGRATION_-3' ||
          state.value === 'INTEGRATION_-3_BASE'
        ) {
          return {
            ...provided,
            color: state.isSelected ? '#ffffff' : theme.colors.success,
          };
        } else if (
          state.value === 'INTEGRATION_-4' ||
          state.value === 'INTEGRATION_-4_BASE'
        ) {
          return {
            ...provided,
            color: state.isSelected ? '#ffffff' : theme.colors.primary,
          };
        }

        return provided;
      },
      singleValue: (provided, state) => {
        if (
          state.data.value === 'INTEGRATION_-3' ||
          state.data.value === 'INTEGRATION_-3_BASE'
        ) {
          return {
            ...provided,
            color: theme.colors.success,
          };
        } else if (
          state.data.value === 'INTEGRATION_-4' ||
          state.data.value === 'INTEGRATION_-4_BASE'
        ) {
          return {
            ...provided,
            color: theme.colors.primary,
          };
        }

        return provided;
      },
      control: (provided) => {
        if (!warning) {
          return provided;
        }

        return {
          ...provided,
          borderColor: theme.colors.warning,
        };
      },
    };

    if (styles) {
      return combineStyles(selectStyles, styles);
    }
    return selectStyles;
  }, [
    styles,
    theme.colors.primary,
    theme.colors.success,
    theme.colors.warning,
    warning,
  ]);

  const budgetOptions = useMemo(() => {
    if (!companyBudgets) return [];

    const custom =
      includeCustom || noBudgetsPresent
        ? [
            {
              value: `COMPANY_-1`,
              label: noBudgetsPresent
                ? 'None (Please create a budget)'
                : 'Custom',
              budgetId: -1,
              budgetType: 'COMPANY',
            },
          ]
        : [];

    let filteredCompanyBudgets = companyBudgets;
    let filteredAccountingBudgets = accountingBudgets;
    let filteredBudgetGroups = budgetGroups;

    let defaultBudget;
    if (defaultBudgetType === 'INTEGRATION' && budgetType !== 'COMPANY') {
      defaultBudget = accountingBudgets.find(
        (budget) => budget.id === defaultBudgetId
      );
      if (defaultBudget && !showDefaultBudgetOption && !isDefaultPicker) {
        filteredAccountingBudgets = filteredAccountingBudgets.filter(
          (budget) => budget.id !== defaultBudgetId
        );
      }
    } else if (
      defaultBudgetType === 'COMPANY' &&
      budgetType !== 'INTEGRATION'
    ) {
      defaultBudget = companyBudgets.find(
        (budget) => budget.id === defaultBudgetId
      );
      if (
        defaultBudget &&
        !showDefaultBudgetOption &&
        showDefault === 'budget' &&
        !isDefaultPicker
      ) {
        filteredCompanyBudgets = filteredCompanyBudgets.filter(
          (budget) => budget.id !== defaultBudgetId
        );
      }
    } else if (defaultBudgetType === 'GROUP' && budgetType !== 'INTEGRATION') {
      defaultBudget = budgetGroups.find(
        (budget) => budget.id === defaultBudgetId
      );
      if (
        defaultBudget &&
        !showDefaultBudgetOption &&
        showDefault === 'budget' &&
        !isDefaultPicker
      ) {
        filteredBudgetGroups = filteredBudgetGroups.filter(
          (budget) => budget.id !== defaultBudgetId
        );
      }
    }

    let defaultForecast;
    if (defaultForecastType === 'INTEGRATION' && budgetType !== 'COMPANY') {
      defaultForecast = accountingBudgets.find(
        (budget) => budget.id === defaultForecastId
      );
      if (defaultForecast && !showDefaultForecastOption && !isDefaultPicker) {
        filteredAccountingBudgets = filteredAccountingBudgets.filter(
          (budget) => budget.id !== defaultForecastId
        );
      }
    } else if (
      defaultForecastType === 'COMPANY' &&
      budgetType !== 'INTEGRATION'
    ) {
      defaultForecast = companyBudgets.find(
        (budget) => budget.id === defaultForecastId
      );
      if (
        defaultForecast &&
        !showDefaultForecastOption &&
        showDefault === 'forecast' &&
        !isDefaultPicker
      ) {
        filteredCompanyBudgets = filteredCompanyBudgets.filter(
          (budget) => budget.id !== defaultForecastId
        );
      }
    } else if (
      defaultForecastType === 'GROUP' &&
      budgetType !== 'INTEGRATION'
    ) {
      defaultForecast = budgetGroups.find(
        (budget) => budget.id === defaultForecastId
      );
      if (
        defaultForecast &&
        !showDefaultForecastOption &&
        showDefault === 'forecast' &&
        !isDefaultPicker
      ) {
        filteredBudgetGroups = filteredBudgetGroups.filter(
          (budget) => budget.id !== defaultForecastId
        );
      }
    }

    if (budgetType === 'INTEGRATION') {
      filteredCompanyBudgets = [];
      filteredBudgetGroups = [];
    }
    if (budgetType === 'COMPANY') {
      filteredAccountingBudgets = [];
    }

    const defaultBudgetOption = [];
    const defaultForecastOption = [];

    if (
      !isConsolidation &&
      (showDefaultBudgetOption || showDefaultForecastOption) &&
      !isDefaultPicker
    ) {
      if (showDefaultBudgetOption) {
        const defaultBudgetName = defaultBudget
          ? ` (${defaultBudget.name})`
          : '';
        defaultBudgetOption.push({
          value: `INTEGRATION_-3`,
          budgetId: -3,
          budgetType: 'INTEGRATION',
          label: `Always Use Default Budget${defaultBudgetName}`,
        });

        if (showForecastBaseOptions && accountingIntegration === 'SUMMIT') {
          defaultBudgetOption.push({
            value: `INTEGRATION_-3_BASE`,
            budgetId: -3,
            budgetType: 'INTEGRATION',
            useBaseForecastValues: true,
            label: `Always Use Default Budget${defaultBudgetName} (Forecast Only)`,
          });
        }
      }

      if (showDefaultForecastOption) {
        const defaultForecastName = defaultForecast
          ? ` (${defaultForecast.name})`
          : '';
        defaultForecastOption.push({
          value: `INTEGRATION_-4`,
          budgetId: -4,
          budgetType: 'INTEGRATION',
          label: `Always Use Default Forecast${defaultForecastName}`,
        });

        if (showForecastBaseOptions && accountingIntegration === 'SUMMIT') {
          defaultForecastOption.push({
            value: `INTEGRATION_-4_BASE`,
            budgetId: -4,
            budgetType: 'INTEGRATION',
            useBaseForecastValues: true,
            label: `Always Use Default Forecast${defaultForecastName} (Forecast Only)`,
          });
        }
      }
    } else if (defaultBudget || defaultForecast) {
      if (defaultBudget && showDefault === 'budget' && !isDefaultPicker) {
        defaultBudgetOption.push({
          value: `${defaultBudgetType}_${defaultBudget.id}`,
          budgetId: defaultBudget.id,
          budgetType: defaultBudgetType,
          label: `${defaultBudget.name} (default)`,
        });
      }

      if (defaultForecast && showDefault === 'forecast' && !isDefaultPicker) {
        defaultForecastOption.push({
          value: `${defaultForecastType}_${defaultForecast.id}`,
          budgetId: defaultForecast.id,
          budgetType: defaultForecastType,
          label: `${defaultForecast.name} (default)`,
        });
      }
    }

    const mainOptions = [
      ...filteredAccountingBudgets.map((budget) => ({
        value: `INTEGRATION_${budget.id}`,
        budgetId: budget.id,
        budgetType: 'INTEGRATION',
        label: budget.name,
      })),
      ...filteredCompanyBudgets.reduce((prev, budget) => {
        prev.push({
          value: `COMPANY_${budget.id}`,
          budgetId: budget.id,
          budgetType: 'COMPANY',
          label: budget.name,
        });

        if (showForecastBaseOptions && accountingIntegration === 'SUMMIT') {
          prev.push({
            value: `COMPANY_${budget.id}_BASE`,
            budgetId: budget.id,
            budgetType: 'COMPANY',
            useBaseForecastValues: true,
            label: `${budget.name} (Forecast Only)`,
          });
        }

        return prev;
      }, []),
      ...filteredBudgetGroups.map((group) => ({
        value: `GROUP_${group.id}`,
        budgetId: group.id,
        budgetType: 'GROUP',
        label: group.name,
      })),
    ];

    const result = [
      ...custom,
      ...defaultBudgetOption,
      ...defaultForecastOption,
      ...sortByNameCaseInsensitive(mainOptions),
    ];

    if (isConsolidation && includeConsolidation) {
      result.push({
        value: `INTEGRATION_-2`,
        budgetId: -2,
        budgetType: 'INTEGRATION',
        label: 'Consolidated Budget',
      });
    }

    return result;
  }, [
    accountingBudgets,
    accountingIntegration,
    budgetGroups,
    budgetType,
    companyBudgets,
    defaultBudgetId,
    defaultBudgetType,
    defaultForecastId,
    defaultForecastType,
    includeConsolidation,
    includeCustom,
    isConsolidation,
    isDefaultPicker,
    noBudgetsPresent,
    showDefault,
    showDefaultBudgetOption,
    showDefaultForecastOption,
    showForecastBaseOptions,
  ]);

  const budgetValue = useMemo(() => {
    if (value) {
      return `${value.budgetType}_${value.budgetId}${
        value.useBaseForecastValues ? '_BASE' : ''
      }`;
    }
    return '';
  }, [value]);

  const handleChange = useCallback(
    (_, opt) => {
      onChange &&
        onChange({
          budgetType: opt.budgetType,
          budgetId: opt.budgetId,
          useBaseForecastValues: opt.useBaseForecastValues || false,
          noDefaultBudget:
            `${opt.budgetType}_${opt.budgetId}` === 'INTEGRATION_-3' &&
            !defaultBudgetId &&
            !!companyBudgets,
          noDefaultForecast:
            `${opt.budgetType}_${opt.budgetId}` === 'INTEGRATION_-4' &&
            !defaultForecastId &&
            !!companyBudgets,
        });
    },
    [companyBudgets, defaultBudgetId, defaultForecastId, onChange]
  );

  const type = useMemo(() => {
    if (budgetType === 'INTEGRATION_-3' && !defaultBudgetId) {
      return 'budget';
    } else if (budgetType === 'INTEGRATION_-4' && !defaultForecastId) {
      return 'forecast';
    }

    return null;
  }, [budgetType, defaultBudgetId, defaultForecastId]);

  return (
    <>
      <StyledSelect
        value={budgetValue}
        options={budgetOptions}
        onChange={handleChange}
        styles={combinedStyles}
        portal={portal}
        className={className}
        maxMenuHeight={maxMenuHeight !== 0 ? maxMenuHeight : undefined}
        marginRight={marginRight}
        theme={theme}
        compact={compact}
        scale={scale}
        menuShouldScrollIntoView={menuShouldScrollIntoView}
        menuPosition={menuPosition}
        testId={testId}
      />
      {type && !!companyBudgets && (
        <WarningText scale={scale}>
          {`You have not set up a default ${type} in the company
          settings`}
        </WarningText>
      )}
    </>
  );
};

export default BudgetSelect;
