/**
 *
 * CreateDatasheetForm
 *
 */

import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Form } from 'informed';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import TextField from 'components/TextField';
import Button from 'components/Button';

import { captureException } from 'utils/sentry';
import useCompanyId from 'hooks/useCompanyId';
import produce from 'immer';
import { DATASHEETS_QUERY } from 'components/DataSheetModal/mutations';
import { clearDataPickerList } from 'utils/cache';

const Inner = styled.div`
  display: flex;
  width: 80%;
`;

const ErrorDiv = styled.div`
  color: ${(props) => props.theme.colors.danger};
  font-size: 13px;
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
  width: 120px;
  white-space: nowrap;
`;

const CREATE = gql`
  mutation CreateDatasheet($companyId: Int!, $name: String!) {
    createDatasheet(companyId: $companyId, name: $name) {
      id
      name
      lastUpdated
    }
  }
`;

const CREATE_LIBRARY = gql`
  mutation CreateDatasheetFromLibrary(
    $companyId: Int!
    $tableId: Int!
    $name: String!
  ) {
    createDatasheetFromLibrary(
      companyId: $companyId
      tableId: $tableId
      name: $name
    ) {
      id
      name
      lastUpdated
    }
  }
`;

function validate(value) {
  if (!value) return 'Name is required';
  if (value.length > 35) return 'Name cannot be more than 35 characters';
  return undefined;
}

function CreateDatasheetForm(props) {
  const { className, libraryDatasheetId, onCompleted } = props;
  const companyId = useCompanyId();
  const [formApi, setApi] = useState(null);
  const history = useHistory();
  const getApi = useCallback((api) => setApi(api), []);

  const [create, { loading }] = useMutation(
    libraryDatasheetId ? CREATE_LIBRARY : CREATE
  );

  const handleSubmit = useCallback(
    async ({ name }) => {
      try {
        await create({
          variables: {
            companyId,
            tableId: libraryDatasheetId,
            name,
          },
          update: (cache, { data }) => {
            const variables = {
              companyId,
            };
            const d = cache.readQuery({
              query: DATASHEETS_QUERY,
              variables,
            });
            const datasheet =
              data[
                libraryDatasheetId
                  ? 'createDatasheetFromLibrary'
                  : 'createDatasheet'
              ];

            cache.writeQuery({
              query: DATASHEETS_QUERY,
              variables,
              data: produce(d, (draft) => {
                draft.company.datasheets.push(datasheet);
              }),
            });

            clearDataPickerList(cache, companyId);

            formApi.reset();
            onCompleted && onCompleted();

            history.push(`/datasheet/${datasheet.id}`);
          },
        });
      } catch (err) {
        captureException(err, true);
      }
    },
    [create, companyId, libraryDatasheetId, formApi, onCompleted, history]
  );

  return (
    <Form
      id="create-datasheet-form"
      className={className}
      getApi={getApi}
      onSubmit={handleSubmit}
    >
      {({ formState }) => (
        <>
          <div style={{ display: 'flex', marginLeft: 'auto' }}>
            <Inner style={{ marginLeft: 'auto', width: '490px' }}>
              <TextField
                id="datasheet-form-name"
                type="text"
                field="name"
                autoFocus
                validate={validate}
              />
              <StyledButton
                type="submit"
                color="primary"
                loading={loading}
                disabled={loading}
              >
                Create New
              </StyledButton>
            </Inner>
          </div>
          <ErrorDiv>{formState.errors.name || null}</ErrorDiv>
        </>
      )}
    </Form>
  );
}

export default CreateDatasheetForm;
