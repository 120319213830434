import gql from 'graphql-tag';

export const BUDGET_LIST_FIELDS = gql`
  fragment BudgetListFields on Table {
    id
    name
    createdBasis
    lastUpdated
    isExcel
    pending
    isAdvancedBudget
    budgetBookMonth
    needsDriverRefresh
    inClientPortal
    status
    locked
    viewOnly
    subdivideEnabled
    budgetFilterType
    statusHistory {
      id
      user {
        id
        firstName
        lastName
      }
      status
      createdAt
    }
  }
`;

export const BUDGET_GROUP_FIELDS = gql`
  fragment BudgetGroupFields on BudgetGroup {
    id
    name
    budgetIds
    hasFilter
  }
`;

export const CUSTOM_LIVE_TEXT = gql`
  fragment CustomLiveTextFields on CustomLiveText {
    id
    name
    item {
      itemType
      itemSubtype
      identifier
      integration
      companyId
      invert
      format {
        numberFormat
        decimals
        isPlural
      }
    }
  }
`;

export const TAG_VALUES = gql`
  fragment TagValueFields on TagValues {
    companyName
    disclaimer
    book_month
    book_month_end
    book_month_end_date_only
    book_month_end_minus_one_date_only
    book_month_end_prior_year_year_only
    book_month_year_only
    last_synced
    aging_date
    fiscal_months_ended
    fiscal_months_ended_spelled_out
    fiscal_months_ended_spelled_out_no_year
    final_fiscal_month
    final_fiscal_month_back_one
    last_fiscal_quarter_end
  }
`;

export const PRICING_PLAN = gql`
  fragment PricingPlanFields on PricingPlan {
    id
    name
    description
    minimum
    unit
    flag
    price
    promoPrice
    annualPrice
    annualPromoPrice
    highlighted
    group
    annualDescription
    annualUnit
    showAnnualTotal
    promoDescription
    additionalPrice
    additionalAnnualPrice
    monthlyTrialDiscount
  }
`;

export const QUERY_TABLE = gql`
  fragment QueryTableFields on QueryTable {
    id
    name
    itemType
    source
    filter
    limit
    groupBy
    layout
    sortBy {
      field
      direction
    }
    columnFields {
      field
      func
      active
    }
    columns {
      id
      type
      active
      title
      rowStyles
      headerStyles
      otherRowStyles
      totalRowStyles
      order

      ... on FieldQueryColumn {
        field
        func
      }
      ... on CustomQueryColumn {
        formula {
          value
          row
          finalRowReplacements
        }
      }
    }
    pivotFields
    valueFields
    columnOrder
    columnHeaderStyles
    columnRowStyles
    pivotHeaderTitles
    pivotHeaderStyles
    pivotValueStyles
    pivotOtherRowStyles
    pivotTotalRowStyles
    rowTotalStyles
    rowTotalHeaderStyles
    rowTotalOtherRowStyles
    rowTotalTotalRowStyles
    includeTotal
    includeRowTotal
    includeOther
    view
    viewSettings
    dateOverride {
      enabled
      field
      entries {
        range
        title
      }
    }
    filterOverride {
      enabled
      entries {
        title
        field
        selected
      }
    }
  }
`;

export const LIBRARY_ACCESS = gql`
  fragment LibraryAccessFields on LibraryAccess {
    id
    personalLibrary
    personalFolder
    companies {
      id
      firmId
    }
    firms {
      id
    }
    workspace
  }
`;

export const LIBRARY_ITEM = gql`
  fragment LibraryItemFields on LibraryItem {
    ... on Dashboard {
      id
      name
    }
    ... on Report {
      id
      name
    }
    ... on Table {
      id
      name
    }
    ... on Spreadsheet {
      id
      name
    }
    ... on QueryTable {
      id
      name
    }
  }
`;

export const LIBRARY_INFO = gql`
  fragment LibraryInfoFields on LibraryInfo {
    id
    source
    workspace {
      id
      name
    }
    company {
      id
      name
    }
    firm {
      id
      name
    }
    user {
      id
      email
    }
    createdBy {
      id
      email
    }
    created
    companyName
    isOwner

    access {
      ...LibraryAccessFields
    }
  }
  ${LIBRARY_ACCESS}
`;

const LAYOUT = gql`
  fragment LayoutFields on KpiLayout {
    lg {
      x
      y
      w
      h
    }
    md {
      x
      y
      w
      h
    }
    sm {
      x
      y
      w
      h
    }
    xs {
      x
      y
      w
      h
    }
    xxs {
      x
      y
      w
      h
    }
    x
    y
    w
    h
  }
`;

export const TEXT_KPI = gql`
  fragment TextKpiFields on TextKpi {
    id
    layout {
      ...LayoutFields
    }
    classId
    departmentId
    opacity
    showBorder
    text
  }
  ${LAYOUT}
`;

export const IMAGE_KPI = gql`
  fragment ImageKpiFields on ImageKpi {
    id
    layout {
      ...LayoutFields
    }
    classId
    departmentId
    opacity
    showBorder
    image
  }
  ${LAYOUT}
`;

export const PLACEHOLDER_KPI = gql`
  fragment PlaceholderKpiFields on PlaceholderKpi {
    id
    layout {
      ...LayoutFields
    }
    classId
    departmentId
    opacity
    showBorder
  }
  ${LAYOUT}
`;

export const KPI = gql`
  fragment KpiFields on Kpi {
    id
    layout {
      ...LayoutFields
    }
    classId
    departmentId
    opacity
    showBorder
    ... on SpecialKpi {
      name
      specialKpiType
      startDate
      endDate
      range
      comparison
      comparisonStartDate
      comparisonEndDate
      customStartDate
      customEndDate
      customComparisonStartDate
      showTable
      quantity
    }
    ... on CustomKpi {
      type
      name
      description
      range
      view
      startDate
      endDate
      customStartDate
      customEndDate
      comparison
      comparisonStartDate
      comparisonEndDate
      customComparisonStartDate
      outcomeName
      outcomeOnly
      showTable
      budgetLine {
        id
        name
        values
      }
      groups {
        name
        values
        comparisonValues
        items {
          name
          values
          comparisonValues
        }
      }
    }
    ... on TextKpi {
      text
    }
    ... on ImageKpi {
      image
    }
  }

  ${LAYOUT}
`;

export const REPORT_KPI = gql`
  fragment ReportKpiFields on Kpi {
    id
    classId
    departmentId
    ... on SpecialKpi {
      name
      specialKpiType
      startDate
      endDate
      range
      comparison
      comparisonStartDate
      comparisonEndDate
      customStartDate
      customEndDate
      customComparisonStartDate
      showTable
      quantity
    }
    ... on CustomKpi {
      type
      name
      description
      range
      view
      startDate
      endDate
      customStartDate
      customEndDate
      comparison
      comparisonStartDate
      comparisonEndDate
      customComparisonStartDate
      outcomeName
      outcomeOnly
      showTable
      budgetLine {
        id
        name
        values
      }
      groups {
        name
        values
        comparisonValues
        items {
          name
          values
          comparisonValues
        }
      }
    }
  }
`;

export const TABLE_COMMENT = gql`
  fragment TableCommentFields on TableComment {
    id
    rowEditorId
    columnEditorId
    user {
      id
      fullName
      email
    }
    assignedTable {
      id
      name
      isDatasheet
      isBudgetStatement
      statementType
    }
    content
    createdAt
    updatedAt
  }
`;

export const TABLE_LOCK = gql`
  fragment TableLockFields on TableLock {
    id
    locked
    userId
    userName
    userEmail
    timestamp
  }
`;

export const REPORT_LOCK = gql`
  fragment ReportLockFields on ReportLock {
    id
    locked
    userId
    userName
    userEmail
    timestamp
  }
`;

export const BUDGET_ROW_SETTINGS = gql`
  fragment BudgetRowSettingsFields on BudgetRowSettings {
    budgetRowType
    adjustmentPercent
    avgAdjustmentPercent
    percentAccount
    accountPercent
    datasheetRowId
    updateWithActuals
    updateAmount
    forecastAlgorithm
    forecastAlgorithmReference
    avgMonths
    excludeAfterBookMonth
    allocationType
    previousYearAllocationType
    trailingAverageBmOffset
    trailingAverageEndDate
  }
`;

export const METRIC_UPDATE = gql`
  fragment MetricUpdate on Table {
    bookMonth
    cellStyles
    compressedCellStyles
    pending
    columns {
      id
      originalId
      title
      type
      subtype
      headerStyle
      rowStyle
      selected
      subtitle
      ... on DateTableColumn {
        startDate
        endDate
        usingDays
        classId
        departmentId
        range
        rangeOffset
        filterCompanyIds
        useTopItemRange
        includeEliminations
        eliminationsOnly
        actualsTitleSuffixEnabled
        actualDataSuffix
      }
      ... on ForecastTableColumn {
        startDate
        endDate
        usingDays
        classId
        departmentId
        budgetId
        budgetType
        useBaseForecastValues
        range
        rangeOffset
        filterCompanyIds
        useTopItemRange
        includeEliminations
        eliminationsOnly
        budgetTitleSuffixEnabled
        forecastActualsTitleSuffixEnabled
        subtitleOverrideEnabled
        actualsTitleSuffixEnabled
        budgetSuffix
        actualSuffix
        actualDataSuffix
        forecastSuffix
        subtitleActual
        subtitleForecast
        budgetColumnType
      }
      ... on TotalTableColumn {
        columns
      }
      ... on DifferenceTableColumn {
        columns
      }
      ... on DivideTableColumn {
        columns
      }
      ... on PercentDifferenceTableColumn {
        columns
      }
      ... on AverageTableColumn {
        columns
      }
      ... on SheetTableColumn {
        gsSpreadsheetId
        gsHeadersId
      }
      ... on AgingTableColumn {
        columns
      }
    }
    companyFilterOverride {
      enabled
      title
      companyIds
    }
    createdBasis
    customValues
    dateOverride {
      enabled
      entries {
        columns
        title
        default
      }
    }
    filterOverride {
      enabled
      title
      allowed
      classIds
      departmentIds
    }
    fiscalYearMonth
    formattingRules
    id
    isBudget
    isDatasheet
    isAdvancedBudget
    startDate
    endDate
    name
    rowChange {
      enabled
      row
      title
      entries {
        title
        value
      }
    }
    rows {
      id
      originalId
      title
      type
      selected
      parentId
      expanded
      level
      blankWhenExpanded
      freezeSort
      alwaysVisible
      hidden
      touched
      ... on AccountTableRow {
        originalTitle
        budgetRowSettings {
          ...BudgetRowSettingsFields
        }
        item {
          itemType
          identifier
          integration
          companyId
          classId
          departmentId
          summitBookMonth
          summitUseBm
        }
        altCollapsedData
        altCollapsedItem {
          itemType
          identifier
          integration
          companyId
        }
        budgetId
        budgetType
        useBaseForecastValues
        dataType
        titleSuffixEnabled
        titleSuffix
      }
      ... on CustomTableRow {
        originalTitle
      }
    }
    sortColumnAsc
    sortColumnId
    statementLocked
    statementType
    subtitleRow {
      selected
      title
    }
    topItemChildren
    topItemCount
    topItemEndDate
    topItemParents
    topItemRange
    topItemStartDate
    topItemTotal
    topItemType
    view
    viewSettings
    initialMinimumSet
    defaultForecastStyles
    defaultLabels
    defaultAbbreviate
    defaultHideCategoryZeroRows
    presetDataTypes
    comments {
      ...TableCommentFields
    }
  }
  ${TABLE_COMMENT}
  ${BUDGET_ROW_SETTINGS}
`;

export const SPREADSHEET = gql`
  fragment SpreadsheetFields on Spreadsheet {
    id
    name
    range
    startDate
    endDate
    customStartDate
    customEndDate
    lastModified
    settings
    rows {
      id
      integration
      itemType
      identifier
      companyId
      name

      classId
      departmentId

      cells {
        id
        timestamp
        value
        formula
        text
      }
    }
  }
`;

export const GOOGLE_SHEET_FIELDS = gql`
  fragment GoogleSpreadsheetFields on GoogleSpreadsheet {
    id
    name
    lastModified
    isExcel
    nightlySyncError
  }
`;

export const LIBRARY_TABLE_FIELDS = gql`
  fragment LibraryTableFields on Table {
    id
    name
    darkThumbnail
    lightThumbnail
    pending
    statementType
    statementLocked
    companyId
    hidden
  }
`;

export const CUSTOM_PAGE = gql`
  fragment CustomPageFields on CustomReportPage {
    id
    type
    title
    order
    kpis {
      ...KpiFields
    }
    snapToTop
    backgroundImage
    footer
    footerEnabled
    companyId
    content
    landscape
    paperSize
    marginTop
    marginBottom
    marginLeft
    marginRight
    inTableOfContents
  }
  ${KPI}
`;

export const MARKETPLACE_REPORT_FIELDS = gql`
  fragment MarketplaceReportFields on Report {
    id
    name
    lastModified
    preset
    newCompanyDefault
    companyId
    pages {
      id
      ... on CustomReportPage {
        ...CustomPageFields
        content
      }
      companyId
    }
  }
  ${CUSTOM_PAGE}
  ${LIBRARY_INFO}
`;

export const REPORT_LIST_FIELDS = gql`
  fragment ReportListFields on Report {
    id
    name
    reportBuilderType
    favorite
    thumbnail
    created
    lastModified
    isNew
    inClientPortal
    pending
  }
`;

export const TABLE_PRESETS = gql`
  fragment TablePresetFields on Table {
    id
    name
    darkThumbnail
    lightThumbnail
    preset
    presetIntegrations
    presetDataTypes
    presetNew
    statementType
    statementLocked
    companyId
  }
`;

export const BUDGET_DATASHEET = gql`
  fragment BudgetDatasheetFields on Table {
    id
    name
    customValues
    isDatasheet
    startDate
    endDate
    lastUpdated
    cellStyles
    compressedCellStyles
    datasheetBudgetValues
    rows {
      id
      originalId
      title
      type
      selected
      parentId
      expanded
      level
      blankWhenExpanded
      freezeSort
      alwaysVisible
      ... on AccountTableRow {
        originalTitle
        budgetRowSettings {
          ...BudgetRowSettingsFields
        }
        item {
          itemType
          identifier
          integration
          companyId
          classId
          departmentId
          summitBookMonth
          summitUseBm
        }
        altCollapsedData
        altCollapsedItem {
          itemType
          identifier
          integration
          companyId
        }
        budgetId
        budgetType
        useBaseForecastValues
        dataType
        titleSuffixEnabled
        titleSuffix
      }
      ... on CustomTableRow {
        originalTitle
      }
    }
    columns {
      id
      originalId
      title
      type
      subtype
      headerStyle
      rowStyle
      selected
      subtitle
      ... on DateTableColumn {
        startDate
        endDate
        usingDays
        classId
        departmentId
        range
        rangeOffset
        filterCompanyIds
        useTopItemRange
        includeEliminations
        eliminationsOnly
        actualsTitleSuffixEnabled
        actualDataSuffix
      }
    }
  }
  ${BUDGET_ROW_SETTINGS}
`;

export const TABLE_COMMENTS = gql`
  fragment TableComments on Table {
    comments {
      ...TableCommentFields
    }
  }
  ${TABLE_COMMENT}
`;

export const TABLE = gql`
  fragment TableFields on Table {
    id
    name
    accountingBasis
    customValues
    companyId
    view
    viewSettings
    itemType
    cellStyles
    compressedCellStyles
    preset
    presetIntegrations
    presetDataTypes
    presetNew
    isFavoritePreset
    statementType
    statementLocked
    bookMonth
    fiscalYearMonth
    formattingRules
    isBudget
    isDatasheet
    isAdvancedBudget
    isBudgetStatement
    startDate
    endDate
    createdBasis
    lastUpdated
    topItemType
    topItemCount
    topItemTotal
    topItemRange
    topItemRangeOffset
    topItemStartDate
    topItemEndDate
    topItemParents
    topItemChildren
    sortColumnId
    sortColumnAsc
    initialMinimumSet
    defaultForecastStyles
    defaultLabels
    defaultAbbreviate
    defaultHideCategoryZeroRows
    pending
    status
    locked
    viewOnly
    threeWaySettings {
      bankAccountId
      forecastBmEnabled
      forecastOverrideActuals
      arEnabled
      apEnabled
      inventoryEnabled
      arDays
      arPercentage
      apDays
      apPercentage
      inventoryDays
      inventoryPercentage
      arAccount {
        itemType
        identifier
      }
      apAccount {
        itemType
        identifier
      }
      inventoryAccount {
        itemType
        identifier
      }
      arSourceAccounts {
        itemType
        identifier
      }
      apSourceAccounts {
        itemType
        identifier
      }
      inventorySourceAccounts {
        itemType
        identifier
      }
      newBudgetYearSelection
      priorYearSelection
      twoYearsPriorSelection
      refreshRowDrivers
      refreshOnOpen
      newBudgetStartDate
      newBudgetEndDate
    }
    subtitleRow {
      selected
      title
    }
    dateOverride {
      enabled
      entries {
        columns
        title
        default
      }
    }
    filterOverride {
      enabled
      allowed
      title
      classIds
      departmentIds
    }
    companyFilterOverride {
      enabled
      title
      companyIds
    }
    rowChange {
      enabled
      row
      title
      entries {
        title
        value
      }
    }
    rows {
      id
      originalId
      title
      type
      selected
      parentId
      expanded
      level
      blankWhenExpanded
      freezeSort
      alwaysVisible
      hidden
      touched
      ... on AccountTableRow {
        originalTitle
        budgetRowSettings {
          ...BudgetRowSettingsFields
        }
        item {
          itemType
          identifier
          integration
          companyId
          classId
          departmentId
          summitBookMonth
          summitUseBm
        }
        altCollapsedData
        altCollapsedItem {
          itemType
          identifier
          integration
          companyId
        }
        budgetId
        budgetType
        useBaseForecastValues
        dataType
        titleSuffixEnabled
        titleSuffix
      }
      ... on CustomTableRow {
        originalTitle
      }
    }
    columns {
      id
      originalId
      title
      type
      subtype
      headerStyle
      rowStyle
      selected
      subtitle
      ... on DateTableColumn {
        startDate
        endDate
        usingDays
        classId
        departmentId
        range
        rangeOffset
        filterCompanyIds
        useTopItemRange
        includeEliminations
        eliminationsOnly
        actualsTitleSuffixEnabled
        actualDataSuffix
      }
      ... on ForecastTableColumn {
        startDate
        endDate
        usingDays
        classId
        departmentId
        budgetId
        budgetType
        useBaseForecastValues
        range
        rangeOffset
        filterCompanyIds
        useTopItemRange
        includeEliminations
        eliminationsOnly
        budgetTitleSuffixEnabled
        forecastActualsTitleSuffixEnabled
        subtitleOverrideEnabled
        actualsTitleSuffixEnabled
        budgetSuffix
        actualSuffix
        actualDataSuffix
        forecastSuffix
        subtitleActual
        subtitleForecast
        budgetColumnType
      }
      ... on TotalTableColumn {
        columns
      }
      ... on DifferenceTableColumn {
        columns
      }
      ... on DivideTableColumn {
        columns
      }
      ... on PercentDifferenceTableColumn {
        columns
      }
      ... on AverageTableColumn {
        columns
      }
      ... on SheetTableColumn {
        gsSpreadsheetId
        gsHeadersId
      }
      ... on AgingTableColumn {
        columns
      }
    }
    comments {
      ...TableCommentFields
    }
  }
  ${TABLE_COMMENT}
  ${BUDGET_ROW_SETTINGS}
`;

export const EXPOSED_TABLE = gql`
  fragment ExposedTableFields on Table {
    id
    name
    customValues
    view
    viewSettings
    cellStyles
    compressedCellStyles
    statementType
    statementLocked
    dateOverride {
      enabled
      entries {
        columns
        title
        default
      }
    }
    filterOverride {
      enabled
      allowed
      title
      classIds
      departmentIds
    }
    rowChange {
      enabled
      row
      title
      entries {
        title
        value
      }
    }
    rows {
      id
      title
      type
      selected
      parentId
      expanded
      level
      blankWhenExpanded
      freezeSort
      hidden
      touched
      ... on AccountTableRow {
        originalTitle
        item {
          itemType
          identifier
          integration
          companyId
        }
        altCollapsedData
        altCollapsedItem {
          itemType
          identifier
          integration
        }
        budgetId
        budgetType
        useBaseForecastValues
        dataType
        titleSuffixEnabled
        titleSuffix
      }
      ... on CustomTableRow {
        originalTitle
      }
    }
    columns {
      id
      title
      type
      subtype
      headerStyle
      rowStyle
      selected
      ... on DateTableColumn {
        startDate
        endDate
        usingDays
        classId
        departmentId
        range
        rangeOffset
      }
      ... on ForecastTableColumn {
        startDate
        endDate
        usingDays
      }
    }
  }
`;

export const STATEMENT_PAGE = gql`
  fragment StatementPageFields on StatementReportPage {
    id
    title
    order
    type
    paperSize
    landscape
    marginTop
    marginBottom
    marginLeft
    marginRight
    activeDateOverride
    activeRowChange
    activeFilterClassIds
    activeFilterDepartmentIds
    activeFilterCompanyIds
    integrationFilterOverrides
    inTableOfContents
    table {
      ...TableFields
    }
  }
  ${TABLE}
`;

export const REPORT_PAGE = gql`
  fragment ReportPageFields on ReportPage {
    id
    type
    title
    order
    landscape
    paperSize
    marginTop
    marginBottom
    marginLeft
    marginRight
    inTableOfContents
    ... on CustomReportPage {
      ...CustomPageFields
      content
    }
    ... on StatementReportPage {
      ...StatementPageFields
    }
  }
  ${CUSTOM_PAGE}
  ${STATEMENT_PAGE}
`;

export const CANVAS_REPORT = gql`
  fragment CanvasReportFields on Report {
    id
    name
    lastModified
    preset
    presetIntegrations
    premium
    period
    newCompanyDefault
    themeColor
    pageNumberSettings {
      enabled
      align
      fontFamily
      fontWeight
      fontSize
      fill
      startIndex
    }

    pages {
      ...ReportPageFields
    }
  }
  ${REPORT_PAGE}
`;

export const CANVAS_REPORT_NO_PAGES = gql`
  fragment CanvasReportNoPagesFields on Report {
    id
    name
    lastModified
    preset
    presetIntegrations
    presetDataTypes
    presetDescription
    presetNew
    premium
    period
    newCompanyDefault
    themeColor
    useCachedValues

    pageNumberSettings {
      enabled
      align
      fontFamily
      fontWeight
      fontSize
      fill
      startIndex
      includePageCount
    }
  }
`;

export const DIVIDER_FIELDS = gql`
  fragment DividerFields on Divider {
    id
    title
    layout
  }
`;

export const DASHBOARD_LIVE_TEXT_FIELDS = gql`
  fragment DashboardLiveTextFields on DashboardLiveText {
    id
    type
    range
    startDate
    endDate
    itemType
    identifier
    integration
    companyId
    budgetId
    budgetType
    comparisonRange
    comparisonStartDate
    liveSentence
    name
    formatFontColor
    upColor
    downColor
    useSymbol
    symbolUp
    symbolDown
    negativeParenthesis
    abbreviate
    showChangeAs
    accountDrivers
    drivers
    layout
    dateOverride
    filterOverride
    companyFilterOverride
    were
    inverted
    format
    decimal
  }
`;

export const DASHBOARD_LIVE_TEXT_RENAME = gql`
  fragment DashboardLiveTextRename on DashboardLiveText {
    id
    name
  }
`;

export const DASHBOARD_LIVE_TEXT_DATA_FIELDS = gql`
  fragment DashboardLiveTextDataFields on DashboardLiveTextData {
    id
    dashboardLiveText {
      ...DashboardLiveTextFields
    }
    data {
      type
      account {
        name
        value
        properties {
          startDate
          endDate
          budgetType
          budgetId
        }
      }
      drivers {
        name
        value
      }
      comparison {
        name
        value
        properties {
          startDate
          endDate
          budgetType
          budgetId
        }
      }
    }
  }

  ${DASHBOARD_LIVE_TEXT_FIELDS}
`;

export const DASHBOARD = gql`
  fragment DashboardFields on Dashboard {
    id
    name
    lastModified
    inClientPortal
    order
    tables {
      ...TableFields
      layout
      isNew
    }
    queryTables {
      ...QueryTableFields
    }
    dividers {
      ...DividerFields
    }
    dashboardLiveTexts {
      ...DashboardLiveTextDataFields
    }
  }

  ${TABLE}
  ${QUERY_TABLE}
  ${DIVIDER_FIELDS}
  ${DASHBOARD_LIVE_TEXT_DATA_FIELDS}
`;

export const DASHBOARD_LIST = gql`
  fragment DashboardListFields on Dashboard {
    id
    name
    created
    lastModified
    favorite
    inClientPortal
    thumbnail(theme: $theme)
    isNew
    pending
    link {
      code
    }
  }
`;

export const SCRATCH_PAD_ITEM = gql`
  fragment ScratchPadItemFields on ScratchPadItem {
    id
    name
    objects
    svg
    width
    height
    isPage
  }
`;

export const WORKSPACE_LIST = gql`
  fragment WorkspaceListFields on Workspace {
    id
    name
    owner {
      id
    }
    secondaryAdmin {
      id
    }
  }
`;

export const COMPANY_LIST = gql`
  fragment CompanyListFields on Company {
    id
    name
  }
`;

export const WORKSPACE_OWNER = gql`
  fragment WorkspaceOwnerFields on Workspace {
    id
    owner {
      id
      email
      fullName
    }
    hasPermission(action: SET_SECONDARY_ADMIN)
  }
`;

export const CURRENT_USER_STRIPE_SUBSCRIPTION = gql`
  fragment CurrentUserStripeSubscriptionFields on CurrentUser {
    stripeSubscription {
      id
      hasPaymentMethod
    }
  }
`;

export const GROUP_COMPANY_LIST = gql`
  fragment GroupCompanyListFields on Company {
    id
    name
    firmId
    currency
    canShare: hasPermission(
      permission: CREATE_CLIENT
      firmPermission: CREATE_CLIENTS
    )
    canDisconnectCompany: hasPermission(
      permission: DISCONNECT_COMPANY
      firmPermission: MANAGE_COMPANY
    )
    isConsolidation
    isConsolidationOwner
    canManageConsolidation
    isConsolidationEmpty
    accountingIntegration
    bookMonth
    fiscalYearMonth
    isBookMonthFixed
    updatedAt
    aiEnabled
    logo {
      id
      url
    }
    accounting {
      accountingBasis
      hasAccountingBasis
      canModifyFiscalYearMonth
      lastSynced
      integration
    }
    lastSyncedByIntegration {
      integration
      lastSynced
    }
    users {
      id
      email
      fullName
    }
    clientPortalUsers {
      id
      email
      fullName
    }
  }
`;

export const FINANCIAL_LABELS = gql`
  fragment FinancialLabelFields on FinancialLabels {
    income
    cogs
    grossProfit
    expense
    netOperatingIncome
    otherIncome
    otherExpense
    otherIncomeExpense
    netProfit
    assets
    currentAssets
    bankAccounts
    accountsReceivable
    otherCurrentAssets
    fixedAssets
    otherAssets
    liabilitiesAndEquity
    liabilities
    currentLiabilities
    accountsPayable
    creditCard
    otherCurrentLiabilities
    longTermLiabilities
    equity
    operatingActivities
    investingActivities
    financingActivities
    netCashIncreaseForPeriod
  }
`;
