/**
 *
 * ThemeSidebar
 *
 */

import React from 'react';
import styled from 'styled-components';
import { Query, Mutation } from '@apollo/client/react/components';
import gql from 'graphql-tag';

import colorThemes from 'themes/colors';
import produce from 'immer';

const darkThemes = colorThemes.dark;
const lightThemes = colorThemes.light;

const Sidebar = styled.div`
  box-shadow: 0 0 15px 1px rgba(69, 65, 78, 0.2);
  right: ${props => (props.open ? 0 : -450)}px;
  z-index: 1005;
  position: fixed;
  overflow: hidden;
  width: 450px;
  padding: 20px;
  background-color: #fff;
  top: 0;
  height: 100%;
  backface-visibility: hidden;
  transition: all 0.3s ease;
`;

const Background = styled.div`
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.3);
  bottom: 0;
  position: fixed;
`;

const H5 = styled.h5`
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 1rem;
`;

const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Theme = styled.button`
  margin: 5px;
  padding: 10px;
  background: #eef5ff;
  text-align: center;
  position: relative;
`;

const ThemeTitle = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
`;

const Split = styled.div`
  height: 60px;
  width: 72px;
`;

const SidebarPreview = styled.div`
  float: left;
  width: 20%;
  height: 100%;
`;

const ColorPreview = styled.div`
  width: 80%;
  float: left;
  height: 100%;
`;

const Check = styled.div`
  position: absolute;
  display: block;
  left: 45px;
  top: 50px;
  width: 30px;
  height: 30px;
  margin-left: -10px;
  text-align: center;
  color: #fff;
  font-size: 28px;
`;

const SET_THEME_QUERY = gql`
  query UpdateTheme {
    currentUser {
      theme {
        id
        name
      }
    }
  }
`;

const SET_THEME = gql`
  mutation SetTheme($theme: String!) {
    setTheme(theme: $theme) {
      theme {
        id
        name
      }
      success
    }
  }
`;

class ThemeSidebar extends React.PureComponent {
  render() {
    const { open, onClose, selected } = this.props;

    return (
      <Mutation
        mutation={SET_THEME}
        update={(cache, { data: { setTheme } }) => {
          if (setTheme.success) {
            const data = cache.readQuery({ query: SET_THEME_QUERY });

            cache.writeQuery({
              query: SET_THEME_QUERY,
              data: produce(data, draft => {
                draft.currentUser.theme = setTheme.theme;
              }),
            });
          }
        }}
        onError={this.ShowError}
      >
        {setTheme => (
          <div>
            <Sidebar open={open}>
              <H5>Colors with dark sidebar</H5>
              <Section>
                {darkThemes.map(theme => (
                  <Theme
                    key={theme.id}
                    onClick={() =>
                      setTheme({
                        variables: {
                          theme: theme.id,
                        },
                      })
                    }
                  >
                    <ThemeTitle>{theme.name}</ThemeTitle>
                    <Split>
                      <SidebarPreview style={{ background: '#161931' }} />
                      <ColorPreview style={{ background: theme.color }} />
                    </Split>
                    {selected === theme.id && <Check className="icon-check" />}
                  </Theme>
                ))}
              </Section>
              <H5>Colors with light sidebar</H5>
              <Section>
                {lightThemes.map(theme => (
                  <Theme
                    key={theme.id}
                    onClick={() =>
                      setTheme({
                        variables: {
                          theme: theme.id,
                        },
                      })
                    }
                  >
                    <ThemeTitle>{theme.name}</ThemeTitle>
                    <Split>
                      <SidebarPreview style={{ background: '#fff' }} />
                      <ColorPreview style={{ background: theme.color }} />
                    </Split>
                    {selected === theme.id && <Check className="icon-check" />}
                  </Theme>
                ))}
              </Section>
            </Sidebar>
            {open && <Background onClick={onClose} />}
          </div>
        )}
      </Mutation>
    );
  }
}

export default ThemeSidebar;
