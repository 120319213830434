import scrollbar from 'css/scrollbar';
import styled from 'styled-components';

export const Container = styled.div`
  max-height: calc(100vh - 400px);
  overflow: auto;

  ${scrollbar()}

  thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
  }

  tbody {
    tr {
      vertical-align: middle;
      td:nth-child(n + 3) {
        text-align: center;
      }
    }
  }
`;

export const Search = styled.input.attrs({ type: 'text', autoComplete: 'off' })`
  width: 100%;
  margin-bottom: 10px;
`;

export const Footer = styled.div`
  margin-top: 10px;
  text-align: right;
`;
