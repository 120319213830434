import React from 'react';

const GustoLogo = ({
  width = '80px',
  height = null,
  cursor = null,
}: React.SVGProps<SVGSVGElement> & {
  cursor?: string;
}): React.ReactElement => {
  return (
    <svg
      style={{ cursor: cursor, transform: 'translateY(3px)' }}
      width={width}
      height={height}
      viewBox="-153 160 652 230"
    >
      <path
        fill="#F45D48"
        d="M401.7,194.1c-37.1,0-67.3,30.2-67.3,67.5c0,37.2,30.2,67.5,67.3,67.5c37,0,67.2-30.3,67.2-67.5
	C468.9,224.3,438.7,194.1,401.7,194.1L401.7,194.1z M401.7,297.1c-19.5,0-35.4-15.9-35.4-35.5c0-19.6,15.9-35.5,35.4-35.5
	c19.5,0,35.4,15.9,35.4,35.5C437,281.2,421.1,297.1,401.7,297.1L401.7,297.1z M203.7,247.9l-11.7-5.9c-5.1-2.5-8.3-4.5-9.6-6
	c-1.2-1.3-1.9-3-1.9-4.8c0-2.3,1-4.2,3-5.7c2-1.5,4.8-2.3,8.5-2.3c6.6,0,13.9,4,22,12l19.8-19.9c-4.9-6.7-11.3-12-18.6-15.8
	c-7.2-3.6-15.3-5.5-23.4-5.5c-12.1,0-22.1,3.6-29.9,10.8c-7.8,7.2-11.7,15.9-11.7,26c0,15.7,10.3,28.8,31,39.3l10.8,5.4
	c9.3,4.7,13.9,9.5,13.9,14.5c0,2.7-1.3,5-3.8,7.1c-2.6,2-6,3-10.2,3c-3.9,0-8.4-1.4-13.4-4.1c-5-2.7-9.5-6.3-13.2-10.7l-19.7,21.6
	c11.1,14.6,26,22,44.6,22c14,0,25.2-3.8,33.5-11.5c8.3-7.6,12.5-17.1,12.5-28.5c0-8.6-2.3-16-6.9-22.4
	C224.5,260.3,216,254.1,203.7,247.9L203.7,247.9z M4.1,314.4V196.7h-31.9v7.9c-10.7-6.9-23.3-10.6-36-10.6
	c-37,0-67.2,30.2-67.2,67.5c0,37.2,30.2,67.5,67.2,67.5c12.6,0,25-3.5,35.7-10.3c0,1,0,1.9,0,2.6c0,19.6-15.9,35.5-35.4,35.5
	c-6.6,0-13.1-1.9-18.7-5.4l-15.8,27.8c10.4,6.3,22.4,9.7,34.6,9.7c37.1,0,67.7-30.3,67.7-67.5C4.1,320,4.2,315.8,4.1,314.4
	L4.1,314.4z M-63.9,297.1c-19.5,0-35.4-15.9-35.4-35.5c0-19.6,15.9-35.5,35.4-35.5c19.5,0,35.4,15.9,35.4,35.5
	C-28.5,281.2-44.4,297.1-63.9,297.1L-63.9,297.1z M131.2,196.7H99.1v64.2c0,9.1,0,24.9-6.7,31.6c-3.2,3.2-6.7,6.1-14.1,6.1
	c-7.4,0-10.9-2.9-14.1-6.1c-6.7-6.7-6.7-22.5-6.6-31.6v-64.2H25.6v64c-0.1,13.2-0.2,37.7,15.8,54c9.4,9.5,20.6,14.4,36.9,14.4
	c16.3,0,27.5-4.8,36.9-14.4c16.1-16.3,15.9-40.8,15.8-54L131.2,196.7z M312.9,291.4c-2.6,2-8.8,6.3-15.8,5.3
	c-5.8-0.8-10.5-5-11.2-19.2v-53.3h37.7v-27.6h-37.7v-35.9h-32v7.1h0v105.5c0,16.8,4.2,55.8,43.2,55.8c19.7-0.3,31.6-11,36.6-15.2
	l0.7-0.5l-19.2-23.7C315.1,289.7,313.8,290.8,312.9,291.4L312.9,291.4z M312.9,291.4"
      />
    </svg>
  );
};

export default GustoLogo;
