const { pathOr, repeat, reverse } = require('ramda');
const moment = require('moment');
const {
  extractLabel,
  rowIndexToLabel,
  toLabel,
  columnIndexToLabel,
} = require('../formula-parser');
const { formatRange } = require('../ranges');
const {
  tableColumnTypes,
  forecastAlgorithms,
  forecastAlgorithmReferences,
  budgetRowTypes,
  budgetAllocationTypes,
  statementTypes,
  budgetColumnTypes,
  budgetFilterTypes,
} = require('../../constants/tables.json');
const { SimpleLinearRegression } = require('ml-regression');
const { PeriodOverPeriodGrowth } = require('./forecastAlgorithms');
const calculateMatteColor = require('../color/calculateMatteColor');
const zlib = require('browserify-zlib');
const { integrations } = require('../../persistence/constants.json');
const { smartWait } = require('../smartWait');
const applyAlphaToThemeColor = require('../color/applyAlphaToThemeColor');
const isNodeJs = typeof window === 'undefined';

const totalsWithBudgetSettingsByIntegration = {
  XERO: ['accountsReceivable', 'accountsPayable'],
  QB: ['accrualsAndDeferredIncome'],
  [integrations.XERO]: ['accountsReceivable', 'accountsPayable'],
  [integrations.QB]: ['accrualsAndDeferredIncome'],
};
module.exports.totalsWithBudgetSettingsByIntegration =
  totalsWithBudgetSettingsByIntegration;

function getFormulaRefs(formula) {
  const regex =
    /([$])?([A-Za-z]+)([$])?([0-9]+)(:([$])?([A-Za-z]+)([$])?([0-9]+)){0,1}/g;

  return formula ? formula.match(regex) || [] : [];
}
module.exports.getFormulaRefs = getFormulaRefs;

module.exports.budgetFilterTypes = budgetFilterTypes;

const budgetStatementsWithTotalColumn = new Set([
  'PROFIT_AND_LOSS',
  'CASH_FLOW',
  statementTypes.PROFIT_AND_LOSS,
  statementTypes.CASH_FLOW,
]);

module.exports.proBudgetRowSettingsDefaults = Object.freeze({
  budgetRowType: isNodeJs ? budgetRowTypes.DEFAULT : 'DEFAULT',
  adjustmentPercent: 0,
  avgAdjustmentPercent: 0,
  percentAccount: null,
  accountPercent: 50,
  datasheetRowId: null,
  updateWithActuals: true,
  updateAmount: 18000,
  avgMonths: 6,
  forecastAlgorithm: isNodeJs ? forecastAlgorithms.LINEAR : 'LINEAR',
  forecastAlgorithmReference: isNodeJs
    ? forecastAlgorithmReferences.ROLLING_24_MONTHS
    : 'ROLLING_24_MONTHS',
  excludeAfterBookMonth: true,
  allocationType: 'EQUAL_AMOUNTS',
  previousYearAllocationType: 'SEASONALITY',
  trailingAverageBmOffset: 0,
  trailingAverageEndDate: null,
});

function isOldCompanyFilter(value) {
  if (typeof value === 'string' && value.match(/^\d+$/)) {
    return !!value.match(/^\d+$/);
  } else if (typeof value === 'number') {
    return true;
  }
  return false;
}
module.exports.isOldCompanyFilter = isOldCompanyFilter;

function isNewCompanyFilter(value) {
  return typeof value === 'string' && !value.match(/^\d+$/);
}
module.exports.isNewCompanyFilter = isNewCompanyFilter;

function parseStringFilterId(value) {
  // match if all characters are numeric
  if (typeof value === 'string' && value.match(/^\d+$/)) {
    return parseInt(value);
  }
  return value;
}
module.exports.parseStringFilterId = parseStringFilterId;

function getFilters(classId, departmentId, filterCompanyIds) {
  let columnClassId = classId;
  let columnDepartmentId = departmentId;
  let columnFilterCompanyIds = filterCompanyIds;

  if (filterCompanyIds && filterCompanyIds.length) {
    let updatedColumnClassId;
    let updatedColumnDepartmentId;
    let updatedColumnFilterCompanyIds;

    if (isNewCompanyFilter(filterCompanyIds[0])) {
      filterCompanyIds.forEach((filter, i) => {
        const [filterType, companyId, filterId] = filter.split('_');

        if (filterType === 'company') {
          if (!updatedColumnFilterCompanyIds) {
            updatedColumnFilterCompanyIds = [];
          }
          updatedColumnFilterCompanyIds.push(parseInt(companyId));
        }
        if (filterType === 'class') {
          if (!updatedColumnClassId) {
            updatedColumnClassId = [];
          }
          updatedColumnClassId.push(parseStringFilterId(filterId));
        }
        if (filterType === 'department') {
          if (!updatedColumnDepartmentId) {
            updatedColumnDepartmentId = [];
          }
          updatedColumnDepartmentId.push(parseStringFilterId(filterId));
        }
      });

      columnClassId = updatedColumnClassId;
      columnDepartmentId = updatedColumnDepartmentId;
      columnFilterCompanyIds = updatedColumnFilterCompanyIds;
    }
  }

  return {
    classId: columnClassId,
    departmentId: columnDepartmentId,
    filterCompanyIds: columnFilterCompanyIds,
  };
}
module.exports.getFilters = getFilters;

function getCustomValue(data, rowId, colId) {
  const customValue = pathOr(null, [rowId, colId], data.customValues);

  if (customValue !== null) {
    if (typeof customValue === 'string') {
      const value = pathOr(0, [rowId, colId], data.values);

      if (typeof value === 'string') {
        return 0;
      }

      return value;
    } else {
      return customValue || 0;
    }
  }

  return null;
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function escapeRegExpSubstr(string) {
  return string.replace(/[$]/g, '$$$$');
}

function formatDates(startDate, endDate, format = 'MMM YY') {
  if (startDate.isSame(endDate, 'month')) {
    return startDate.format(format);
  }
  return `${startDate.format(format)} - ${endDate.format(format)}`;
}

const borders = ['Top', 'Right', 'Bottom', 'Left'];

function getObjectPath(obj, path) {
  return path.reduce((acc, key) => {
    if (typeof acc === 'object' && acc !== null) {
      return acc[key];
    }
    return undefined;
  }, obj);
}
module.exports.getObjectPath = getObjectPath;

function setObjectPath(obj, path, value) {
  let cursor = obj;
  path.forEach((key, i) => {
    if (i === path.length - 1) {
      cursor[key] = value;
    } else {
      if (!cursor[key]) {
        cursor[key] = {};
      }
      cursor = cursor[key];
    }
  });
}

module.exports.setObjectPath = setObjectPath;

function roundDecimal(number, decimals = 2) {
  return parseFloat(number.toFixed(decimals));
}

function getTrailingAverageMonths(
  budgetBookMonth,
  avgMonths,
  trailingAverageBmOffset
) {
  const endDate = budgetBookMonth
    .clone()
    .add(trailingAverageBmOffset || 0, 'months');

  const startDate = endDate.clone().subtract(avgMonths - 1, 'months');

  return { startDate, endDate };
}
module.exports.getTrailingAverageMonths = getTrailingAverageMonths;

const hasExcludeAfterBookMonth = new Set(['LINEAR', forecastAlgorithms.LINEAR]);
module.exports.hasExcludeAfterBookMonth = hasExcludeAfterBookMonth;

module.exports.defaultAccountingFilterKeys = ['classId', 'departmentId'];

module.exports.defaultAccountingFilters = [
  {
    key: 'classId',
    name: 'Class',
    items: [],
  },
  {
    key: 'departmentId',
    name: 'Department',
    items: [],
  },
];

function isBudgetBalanceSheet(data) {
  return (
    (data.isBudgetStatement && data.statementType === 'BALANCE_SHEET') ||
    data.statementType === statementTypes.BALANCE_SHEET
  );
}

function mapAdvancedBudgetColumns(data, callback) {
  const result = [];

  data.columns.forEach((col, j) => {
    if (j === 0) {
      return;
    }

    if (budgetStatementsWithTotalColumn.has(data.statementType)) {
      if (j < data.columns.length - 1) {
        result.push(callback(col, j - 1));
      }
    } else {
      result.push(callback(col, j - 1));
    }
  });

  return result;
}

function loopAdvancedBudgetColumns(data, callback) {
  data.columns.forEach((col, j) => {
    if (j === 0) {
      return;
    }

    if (budgetStatementsWithTotalColumn.has(data.statementType)) {
      if (j < data.columns.length - 1) {
        callback(col, j - 1);
      }
    } else {
      callback(col, j - 1);
    }
  });
}
module.exports.loopAdvancedBudgetColumns = loopAdvancedBudgetColumns;

function calculateDataSheetDriver(
  rowId,
  budgetRowSettings,
  data,
  prevPeriodActuals,
  datasheet
) {
  const actuals = [];

  const values = mapAdvancedBudgetColumns(data, (col, j) => {
    const actual = pathOr(0, [rowId, j], prevPeriodActuals);
    actuals.push(actual);

    const value = pathOr(
      0,
      [budgetRowSettings.datasheetRowId, datasheet.data.columns[j].id],
      datasheet.data.values
    );

    if (budgetRowSettings.datasheetRowId) {
      const rowIndex = datasheet.data.rows.findIndex((r) => {
        return r.id === budgetRowSettings.datasheetRowId;
      });

      if (rowIndex < 0) {
        return value;
      }

      const isSelected = datasheet.data.rows[rowIndex].selected;

      return isSelected ? value : 0;
    } else {
      return value;
    }
  });

  return { actuals, values };
}

function calculateAnnualFixedDriver(
  rowId,
  budgetRowSettings,
  data,
  actuals,
  prevPeriodActuals,
  budgetBookMonth,
  isPreview
) {
  let remaining = budgetRowSettings.updateAmount || 0;
  const updateWithActuals = budgetRowSettings.updateWithActuals;
  const months = data.columns.length - 1;
  const forecastOverrideActuals =
    data?.threeWaySettings?.forecastOverrideActuals;

  let perMonth = remaining / months;

  const driverActuals = [];

  const allocation =
    budgetRowSettings.allocationType || budgetAllocationTypes.EQUAL_AMOUNTS;

  let multiplier;

  const isSeasonal =
    allocation === budgetAllocationTypes.SEASONALITY ||
    allocation === 'SEASONALITY';

  const startIndex =
    updateWithActuals && budgetBookMonth
      ? Math.max(-1, budgetBookMonth.diff(data.columns[1].startDate, 'month'))
      : -1;

  const calculatedColIndices = [];

  if (isSeasonal) {
    let total = 0;

    loopAdvancedBudgetColumns(data, (col, j) => {
      if (updateWithActuals) {
        const customValue = isPreview
          ? null
          : getCustomValue(data, rowId, col.id);
        if (
          customValue !== null &&
          (j > startIndex || !forecastOverrideActuals)
        ) {
          remaining -= customValue;
          return;
        }
        if (j <= startIndex) {
          remaining -= pathOr(0, [rowId, j], actuals);
          return;
        }
      }

      calculatedColIndices.push(j);

      total += pathOr(0, [rowId, j], prevPeriodActuals);
    });

    multiplier = [];

    if (total) {
      loopAdvancedBudgetColumns(data, (col, j) => {
        if (calculatedColIndices.includes(j)) {
          multiplier.push(pathOr(0, [rowId, j], prevPeriodActuals) / total);
        } else {
          multiplier.push(1);
        }
      });
    } else {
      loopAdvancedBudgetColumns(data, (col, j) => {
        if (calculatedColIndices.includes(j)) {
          multiplier.push(1 / calculatedColIndices.length);
        } else {
          multiplier.push(1);
        }
      });
    }
  } else {
    let remainingCols = 12;

    loopAdvancedBudgetColumns(data, (col, j) => {
      if (updateWithActuals) {
        const customValue = isPreview
          ? null
          : getCustomValue(data, rowId, col.id);
        if (
          customValue !== null &&
          (j > startIndex || !forecastOverrideActuals)
        ) {
          remaining -= customValue;
          remainingCols--;
          return;
        }
        if (updateWithActuals && j <= startIndex) {
          remaining -= pathOr(0, [rowId, j], actuals);
          remainingCols--;
          return;
        }
      }
      calculatedColIndices.push(j);
    });

    perMonth = remaining / remainingCols;
  }

  const values = mapAdvancedBudgetColumns(data, (col, j) => {
    const customValue = isPreview ? null : getCustomValue(data, rowId, col.id);
    const actual = pathOr(0, [rowId, j], actuals);
    driverActuals.push(pathOr(0, [rowId, j], prevPeriodActuals));

    let value = 0;

    if (!isSeasonal) {
      if (calculatedColIndices.includes(j)) {
        value = perMonth;
      } else if (customValue !== null) {
        value = customValue;
      } else {
        value = actual;
      }
    } else {
      if (calculatedColIndices.includes(j)) {
        value = remaining * multiplier[j];
      } else if (customValue !== null) {
        value = customValue;
      } else {
        value = actual;
      }
    }

    return roundDecimal(value);
  });

  return { actuals: driverActuals, values };
}

function calculatePreviousPeriodDriver(
  rowId,
  budgetRowSettings,
  data,
  prevPeriodActuals
) {
  const actuals = [];

  let values = mapAdvancedBudgetColumns(data, (col, j) => {
    const percent = budgetRowSettings.adjustmentPercent || 0;

    let prevPeriodValue = pathOr(0, [rowId, j], prevPeriodActuals);

    actuals.push(prevPeriodValue);

    if (percent) {
      prevPeriodValue = roundDecimal(prevPeriodValue * ((100 + percent) / 100));
    }

    return prevPeriodValue;
  });

  const total = values.reduce((acc, val) => acc + val, 0);

  if (
    budgetRowSettings.previousYearAllocationType === 'EQUAL_AMOUNTS' ||
    budgetRowSettings.previousYearAllocationType ===
      budgetAllocationTypes.EQUAL_AMOUNTS
  ) {
    const perMonth = roundDecimal(total / 12);
    values = repeat(perMonth, 12);
  }

  return { actuals, values, total };
}

function calculateTrailingAverageDriver(
  rowId,
  budgetRowSettings,
  data,
  actuals,
  prevPeriodActuals,
  prevPeriodBackOneActuals,
  budgetBookMonth
) {
  const { startDate, endDate } = getTrailingAverageMonths(
    budgetBookMonth,
    budgetRowSettings.avgMonths,
    budgetRowSettings.trailingAverageBmOffset
  );

  const date = data.columns[1].startDate.clone().subtract(2, 'years');

  let total = 0;
  const previewActuals = [];
  let j = 0;

  const previewActualsEndDate = moment.max(
    endDate,
    data.columns[1].startDate.clone().subtract(1, 'month')
  );

  let source;
  while (date.isSameOrBefore(previewActualsEndDate, 'month')) {
    if (j < 12) {
      source = prevPeriodBackOneActuals;
    } else if (j < 24) {
      source = prevPeriodActuals;
    } else {
      source = actuals;
    }

    const prevPeriodValue = pathOr(0, [rowId, j % 12], source);
    if (j >= 12 && j < 24) {
      previewActuals.push(prevPeriodValue);
    }

    if (date.isBetween(startDate, endDate, 'month', '[]')) {
      total += prevPeriodValue;
    }

    date.add(1, 'month');
    j++;
  }

  const allocation =
    budgetRowSettings.allocationType || budgetAllocationTypes.EQUAL_AMOUNTS;

  let avg = total / budgetRowSettings.avgMonths;
  const percent = budgetRowSettings.avgAdjustmentPercent || 0;
  if (percent) {
    avg = roundDecimal(avg * ((100 + percent) / 100));
  }

  let values = repeat(avg, 12);

  if (
    allocation === budgetAllocationTypes.SEASONALITY ||
    allocation === 'SEASONALITY'
  ) {
    let pastTotal = 0;
    loopAdvancedBudgetColumns(data, (col, colIndex) => {
      pastTotal += pathOr(0, [rowId, colIndex], prevPeriodActuals);
    });
    if (pastTotal) {
      const multiplier = [];
      loopAdvancedBudgetColumns(data, (col, colIndex) => {
        multiplier[colIndex] =
          pathOr(0, [rowId, colIndex], prevPeriodActuals) / pastTotal;
      });
      const avgTotal = avg * 12;

      values = multiplier.map((m) => roundDecimal(m * avgTotal));
    }
  }

  return { actuals: previewActuals, values, average: avg };
}

function calculateForecastAlgorithm(
  budgetRowSettings,
  algorithm,
  forecastAlgorithmReference,
  actuals,
  prevPeriodActuals,
  prevPeriodBackOneActuals,
  rowId,
  data,
  companyBookMonth,
  forecastBm
) {
  const x = [];
  const y = [];
  let excludedActualsCount = 0;
  const forecastBmEnabled = data.threeWaySettings?.forecastBmEnabled;

  let i = 1;

  const useRolling24Months =
    (forecastAlgorithmReference === 'ROLLING_24_MONTHS' ||
      forecastAlgorithmReference ===
        forecastAlgorithmReferences.ROLLING_24_MONTHS) &&
    forecastBmEnabled &&
    algorithm === 'LINEAR';

  const rollingExcludedActuals = [];

  const rollingStartIndex =
    (useRolling24Months &&
      forecastBm?.diff(data.columns[1].startDate, 'months') + 1) ||
    0;

  const hasExclude =
    hasExcludeAfterBookMonth.has(algorithm) && !useRolling24Months;

  mapAdvancedBudgetColumns(data, (col, j) => {
    if (hasExclude && budgetRowSettings.excludeAfterBookMonth) {
      const date = col.startDate.clone().subtract(2, 'years');

      if (date.isSameOrAfter(companyBookMonth, 'month')) {
        i++;
        excludedActualsCount += 1;
        return;
      }
    }
    if (useRolling24Months && j < rollingStartIndex) {
      rollingExcludedActuals.push(
        pathOr(0, [rowId, j], prevPeriodBackOneActuals)
      );
      return;
    }

    x.push(i++);
    y.push(pathOr(0, [rowId, j], prevPeriodBackOneActuals));
  });
  mapAdvancedBudgetColumns(data, (col, j) => {
    if (hasExclude && budgetRowSettings.excludeAfterBookMonth) {
      const date = col.startDate.clone().subtract(1, 'years');

      if (date.isSameOrAfter(companyBookMonth, 'month')) {
        excludedActualsCount += 1;
        i++;
        return;
      }
    }

    x.push(i++);
    y.push(pathOr(0, [rowId, j], prevPeriodActuals));
  });

  if (rollingStartIndex > 0) {
    mapAdvancedBudgetColumns(data, (col, j) => {
      if (j < rollingStartIndex) {
        const value = pathOr(0, [rowId, j], actuals);
        x.push(i++);
        y.push(value);
      }
    });
  }

  let calc;
  let hasPastPrediction = false;
  if (algorithm === 'LINEAR' || algorithm === forecastAlgorithms.LINEAR) {
    calc = new SimpleLinearRegression(x, y);
    hasPastPrediction = true;
  } else if (
    algorithm === 'PERIOD_OVER_PERIOD_GROWTH_RATE' ||
    algorithm === forecastAlgorithms.PERIOD_OVER_PERIOD_GROWTH_RATE
  ) {
    calc = new PeriodOverPeriodGrowth(x, y);
  }

  const values = [];
  i -= rollingStartIndex;
  mapAdvancedBudgetColumns(data, () => {
    const value = calc.predict(i);
    values.push(roundDecimal(value));
    i++;
  });

  if (hasPastPrediction) {
    const pastPredictedValues = [];

    for (let pastX = 1; pastX <= 24 - rollingStartIndex; pastX++) {
      pastPredictedValues.push(roundDecimal(calc.predict(pastX)));
    }

    return {
      actuals: rollingExcludedActuals.concat(y),
      values,
      pastPredictedValues,
      excludedActualsCount,
      isForecastAlgorithm: true,
    };
  }

  return {
    actuals: rollingExcludedActuals.concat(y),
    values,
    excludedActualsCount,
    isForecastAlgorithm: true,
  };
}
module.exports.calculateForecastAlgorithm = calculateForecastAlgorithm;

function calculateCarryForwardDriver(
  data,
  prevPeriodActuals,
  actuals,
  rowId,
  forecastBm
) {
  const firstColumn = data.columns[isBudgetBalanceSheet(data) ? 1 : 0];

  const isSummit = data.rows[0]?.item?.integration === 'SUMMIT';

  let overrideActualsIndex = -1;
  if (
    data.threeWaySettings?.forecastOverrideActuals ||
    (isSummit && data.threeWaySettings?.forecastBmEnabled)
  ) {
    overrideActualsIndex = forecastBm.diff(firstColumn.startDate, 'months');
  }

  let valueToCarry =
    overrideActualsIndex > -1
      ? pathOr(0, [rowId, overrideActualsIndex], actuals)
      : pathOr(0, [rowId, 11], prevPeriodActuals);
  const actualsOutput = [];

  const values = mapAdvancedBudgetColumns(data, (col, j) => {
    const customValue = getCustomValue(data, rowId, col.id);

    const prevPeriodValue = pathOr(0, [rowId, j], prevPeriodActuals);

    actualsOutput.push(prevPeriodValue);

    if (j <= overrideActualsIndex) {
      return pathOr(0, [rowId, j], actuals) || 0;
    } else if (customValue !== null && customValue !== undefined) {
      valueToCarry = customValue;
      return customValue;
    } else {
      return valueToCarry;
    }
  });

  return { values, actuals: actualsOutput };
}

function calculateCustomDriver(prevPeriodActuals, rowId, data, budgetValues) {
  const actuals = [];
  const rowIndex = data.rows.findIndex((r) => r.id === rowId);

  const values = mapAdvancedBudgetColumns(data, (col, j) => {
    const value = pathOr(0, [rowIndex, j], budgetValues);

    const actual = pathOr(0, [rowId, j], prevPeriodActuals);

    actuals.push(actual);

    return value;
  });

  return { actuals, values };
}

module.exports.calculateBudgetDriver = function (
  rowId,
  budgetRowSettings,
  data,
  datasheet,
  prevPeriodActuals,
  prevPeriodBackOneActuals,
  actuals,
  budgetBookMonth,
  companyBookMonth,
  budgetValuesFromActuals,
  isPreview,
  isTotalPreview,
  budgetValues
) {
  const budgetRowType =
    budgetRowSettings.budgetRowType || budgetRowTypes.DEFAULT;

  if (isTotalPreview) {
    let rowIndex = data.rows.findIndex((r) => r.id === rowId);

    const row = data.rows[rowIndex];
    if (row.expanded === false && row.children && row.children.length) {
      const lastChild = row.children[row.children.length - 1];
      rowIndex = data.rows.findIndex((r) => r.id === lastChild.id);
    }

    return {
      actuals: prevPeriodActuals[rowId].slice(0, 12),
      values: mapAdvancedBudgetColumns(data, (col, j) => {
        return budgetValues[rowIndex][j] || 0;
      }),
    };
  }

  if (budgetValuesFromActuals && budgetValuesFromActuals[rowId]) {
    return {
      values: mapAdvancedBudgetColumns(data, (col) => {
        return budgetValuesFromActuals[rowId][col.id] || 0;
      }),
    };
  }

  if (
    budgetRowType === 'DATA_SHEET' ||
    budgetRowType === budgetRowTypes.DATA_SHEET
  ) {
    return calculateDataSheetDriver(
      rowId,
      budgetRowSettings,
      data,
      prevPeriodActuals,
      datasheet
    );
  } else if (
    budgetRowType === 'ANNUAL_FIXED' ||
    budgetRowType === budgetRowTypes.ANNUAL_FIXED
  ) {
    return calculateAnnualFixedDriver(
      rowId,
      budgetRowSettings,
      data,
      actuals,
      prevPeriodActuals,
      budgetBookMonth,
      isPreview
    );
  } else if (
    budgetRowType === 'PREVIOUS_PERIOD' ||
    budgetRowType === budgetRowTypes.PREVIOUS_PERIOD
  ) {
    return calculatePreviousPeriodDriver(
      rowId,
      budgetRowSettings,
      data,
      prevPeriodActuals
    );
  } else if (
    budgetRowType === 'TRAILING_AVERAGE' ||
    budgetRowType === budgetRowTypes.TRAILING_AVERAGE
  ) {
    return calculateTrailingAverageDriver(
      rowId,
      budgetRowSettings,
      data,
      actuals,
      prevPeriodActuals,
      prevPeriodBackOneActuals,
      budgetBookMonth
    );
  } else if (
    budgetRowType === 'FORECAST_ALGORITHMS' ||
    budgetRowType === budgetRowTypes.FORECAST_ALGORITHMS
  ) {
    return calculateForecastAlgorithm(
      budgetRowSettings,
      budgetRowSettings.forecastAlgorithm || 'LINEAR',
      budgetRowSettings.forecastAlgorithmReference || 'ROLLING_24_MONTHS',
      actuals,
      prevPeriodActuals,
      prevPeriodBackOneActuals,
      rowId,
      data,
      companyBookMonth,
      budgetBookMonth
    );
  } else if (
    budgetRowType === 'CARRY_FORWARD' ||
    budgetRowType === budgetRowTypes.CARRY_FORWARD
  ) {
    return calculateCarryForwardDriver(
      data,
      prevPeriodActuals,
      actuals,
      rowId,
      budgetBookMonth
    );
  } else if (
    budgetRowType === 'DEFAULT' ||
    budgetRowType === budgetRowTypes.DEFAULT
  ) {
    return calculateCustomDriver(prevPeriodActuals, rowId, data, budgetValues);
  }

  return {};
};

module.exports.calculateBankAccountRow = function (
  data,
  bankAccountRowId,
  totalToRowId,
  accountToRowId,
  threeWayBudgetInfo
) {
  const result = repeat(0, data.columns.length - 1);

  const positive = [
    'accountsReceivableItems',
    'otherCurrentAssetItems',
    'fixedAssetItems',
    'otherAssetItems',
    'bankAccountItems',
  ];

  const negative = ['liabilitiesAndEquity'];

  let prevMonthValue = pathOr(
    0,
    [bankAccountRowId, data.columns[0].id],
    data.values
  );

  data.columns.forEach((col, i) => {
    if (i === 0) return;
    const prevCol = data.columns[i - 1];

    let combined = prevMonthValue;
    positive.forEach((total) => {
      if (!threeWayBudgetInfo[total]) return;
      const items = threeWayBudgetInfo[total];
      items.forEach(({ itemType, identifier }) => {
        const source = itemType === 'total' ? totalToRowId : accountToRowId;

        const rowId = source[identifier];
        if (rowId === bankAccountRowId) return;

        const lastMonth = pathOr(0, [rowId, prevCol.id], data.values);
        const currentMonth = pathOr(0, [rowId, col.id], data.values);
        const diff = lastMonth - currentMonth;

        combined += diff;
      });
    });
    negative.forEach((total) => {
      const lastMonth = pathOr(
        0,
        [totalToRowId[total], prevCol.id],
        data.values
      );
      const currentMonth = pathOr(
        0,
        [totalToRowId[total], col.id],
        data.values
      );

      const diff = lastMonth - currentMonth;

      combined -= diff;
    });

    result[i - 1] = combined;
    prevMonthValue = combined;
  });

  return result;
};

module.exports.calculateRetainedEarningsRow = function (
  data,
  retainedEarningsRowId,
  balNetProfitRowId,
  actuals,
  actualsEndIndex,
  rowIdToIndex
) {
  const prevMonthNetProfitValue = pathOr(
    0,
    [balNetProfitRowId, data.columns[0].id],
    data.values
  );

  const prevMonthRetainedEarningsValue = pathOr(
    0,
    [retainedEarningsRowId, data.columns[0].id],
    data.values
  );

  let retainedEarningsValue =
    prevMonthNetProfitValue + prevMonthRetainedEarningsValue;

  if (actualsEndIndex !== null) {
    if (actualsEndIndex > -1) {
      retainedEarningsValue = pathOr(
        0,
        [retainedEarningsRowId, actualsEndIndex],
        actuals
      );
    }
  }

  const result = repeat(retainedEarningsValue, data.columns.length - 1);

  const rowIndex = rowIdToIndex[retainedEarningsRowId];
  if (rowIndex !== undefined) {
    if (data.customValues[retainedEarningsRowId]) {
      loopAdvancedBudgetColumns(data, (col, j) => {
        const customValue = pathOr(
          null,
          [retainedEarningsRowId, col.id],
          data.customValues
        );
        if (customValue !== null && customValue !== undefined) {
          result[j] = customValue;
        }
      });
    }
  }

  return {
    values: result,
    initialRetainedEarningsValue: retainedEarningsValue,
  };
};

module.exports.calculateBalNetProfitRow = function (
  profitAndLossData,
  profitAndLossBudgetValues
) {
  const netProfitRowIndex = profitAndLossData.rows.findIndex(
    (row) =>
      row.item &&
      row.item.itemType === 'total' &&
      row.item.identifier === 'netProfit'
  );

  let prevValue = 0;

  const result = mapAdvancedBudgetColumns(profitAndLossData, (col, j) => {
    const value = pathOr(0, [netProfitRowIndex, j], profitAndLossBudgetValues);
    const combined = value + prevValue;
    prevValue = combined;
    return combined;
  });

  return result;
};

module.exports.calculateAgingRow = function (
  balanceSheetData,
  actuals,
  forecastBookMonth,
  profitAndLossData,
  profitAndLossBudgetValues,
  profitAndLossRowIdToIndex,
  agingRowId,
  sourceRowIds,
  percentCash,
  days,
  existingTitle,
  newTitle
) {
  const refColumnIndex = forecastBookMonth.diff(
    balanceSheetData.columns[0].startDate,
    'months'
  );

  const referenceValue =
    refColumnIndex > 0
      ? pathOr(0, [agingRowId, refColumnIndex - 1], actuals)
      : pathOr(
          0,
          [agingRowId, balanceSheetData.columns[refColumnIndex].id],
          balanceSheetData.values
        );

  let existingValue = referenceValue;
  const sourceValues = [];
  const existingValues = [];
  const newValues = [];

  const rawMonths = days / 30;
  const months = Math.floor(rawMonths);
  const remainder = rawMonths % 1;

  const result = mapAdvancedBudgetColumns(profitAndLossData, (col, j) => {
    if (j <= refColumnIndex - 1) {
      const actual = pathOr(0, [agingRowId, j], actuals);
      existingValues.push(null);
      newValues.push(null);

      sourceValues.push(actual);

      return actual;
    }

    let currentMonthAmount = 0;
    sourceRowIds.forEach((rowId) => {
      const rowIndex = profitAndLossRowIdToIndex[rowId];
      if (rowIndex === undefined) return;
      const value = pathOr(0, [rowIndex, j], profitAndLossBudgetValues);
      currentMonthAmount += value;
    });
    currentMonthAmount -= (currentMonthAmount * percentCash) / 100;
    sourceValues.push(currentMonthAmount);

    if (days < 30) {
      existingValues.push(0);
      newValues.push(currentMonthAmount);
      return currentMonthAmount;
    }

    existingValue = Math.max(0, existingValue - (referenceValue * 30) / days);
    if (existingValue < 0.000001) {
      existingValue = 0;
    }
    existingValues.push(existingValue);
    let newAmount = 0;

    for (let m = j - months + 1; m <= j; m++) {
      if (m >= refColumnIndex) {
        newAmount += sourceValues[m];
      }
    }

    if (remainder) {
      const trailingIndex = j - months;
      if (trailingIndex >= refColumnIndex) {
        newAmount += sourceValues[trailingIndex] * remainder;
      }
    }

    newValues.push(newAmount);

    return newAmount + existingValue;
  });

  return {
    supportingData: [
      {
        title: existingTitle,
        values: existingValues,
      },
      {
        title: newTitle,
        values: newValues,
      },
    ],
    values: result,
  };
};

module.exports.deleteObjectPath = function (obj, path) {
  let cursor = obj;
  for (let i = 0; i < path.length; i++) {
    const key = path[i];

    if (i === path.length - 1) {
      delete cursor[key];
    } else {
      if (!cursor[key]) {
        return;
      }
      cursor = cursor[key];
    }
  }
};

module.exports.getTableCellStyles = function (
  styles,
  themeColor,
  scale = 1,
  theme,
  pageBackgroundColor,
  isStatement
) {
  if (scale !== 1) {
    if (styles.fontSize) {
      styles.fontSize = parseInt(styles.fontSize, 10) * scale + 'px';
    }
  }

  if (!themeColor) return styles;

  if (styles.useThemeColor) {
    styles.color = applyAlphaToThemeColor(styles.color, themeColor);
  }
  if (styles.backgroundUseThemeColor) {
    styles.backgroundColor = applyAlphaToThemeColor(
      styles.backgroundColor,
      themeColor
    );
  } else if (isStatement) {
    const themedBackgroundColor = theme === 'light' ? '#FFFFFF' : '#222532';
    const matteColor = calculateMatteColor(
      pageBackgroundColor ||
        (styles.backgroundColor ? themedBackgroundColor : 'transparent'),
      styles.backgroundColor || 'transparent'
    );

    if (matteColor) styles.backgroundColor = matteColor;
  }
  borders.forEach((borderName) => {
    const style = styles[`border${borderName}`];

    if (!style) return;

    const useThemeColor = styles[`border${borderName}UseThemeColor`];

    if (useThemeColor) {
      const [pixels, borderStyle, originalColor] = style.split(' ');
      styles[`border${borderName}`] =
        `${pixels} ${borderStyle} ${applyAlphaToThemeColor(originalColor, themeColor)}`;
    }
  });
  return styles;
};

module.exports.getColumnTitle = function (
  column,
  bookMonth,
  fiscalYearMonth,
  isAdvancedBudget,
  j,
  statementType,
  fullYear
) {
  if (column.type === 'CUSTOM' || column.type === tableColumnTypes.CUSTOM) {
    return column.title || null;
  } else if (
    column.type === 'SHEET' ||
    column.type === 'AGING' ||
    column.type === tableColumnTypes.SHEET
  ) {
    return column.title || null;
  } else if (
    column.type === 'DATE' ||
    column.type === tableColumnTypes.DATE ||
    column.type === 'FORECAST' ||
    column.type === tableColumnTypes.FORECAST ||
    column.type === 'FORECAST_ACTUALS' ||
    column.type === tableColumnTypes.FORECAST_ACTUALS
  ) {
    let columnStartDate = column.startDate;
    if (column.startDate && !column.startDate.isSame) {
      columnStartDate = moment.utc(column.startDate);
    }

    let columnEndDate = column.endDate;
    if (column.endDate && !column.endDate.isSame) {
      columnEndDate = moment.utc(column.endDate);
    }

    let suffix = '';

    if (
      column.forecastActualsTitleSuffixEnabled &&
      (column.type === 'FORECAST_ACTUALS' ||
        column.type === tableColumnTypes.FORECAST_ACTUALS)
    ) {
      if (columnEndDate.isBefore(bookMonth, 'month')) {
        suffix = column.actualSuffix;
      } else {
        suffix = column.forecastSuffix;
      }
    } else if (
      column.actualsTitleSuffixEnabled &&
      (column.type === 'DATE' || column.type === tableColumnTypes.DATE)
    ) {
      suffix = column.actualDataSuffix;
    } else if (
      column.budgetTitleSuffixEnabled &&
      (column.type === 'FORECAST' || column.type === tableColumnTypes.FORECAST)
    ) {
      suffix = column.budgetSuffix;
    }

    if (
      isAdvancedBudget &&
      (column.type === 'FORECAST' ||
        column.type === tableColumnTypes.FORECAST) &&
      (j > 0 ||
        (statementType !== 'BALANCE_SHEET' &&
          statementType !== statementTypes.BALANCE_SHEET))
    ) {
      switch (column.budgetColumnType) {
        case budgetColumnTypes.ACTUALS:
          suffix = 'Actuals';
          break;
        case budgetColumnTypes.VARIANCE:
          suffix = 'Var';
          break;
        default:
          suffix = 'Budget';
      }
    }

    const now = bookMonth || moment.utc();

    if (column.usingDays) {
      if (columnStartDate.isSame(columnEndDate, 'day')) {
        return columnStartDate.format('MMM DD, YY') + suffix;
      }
      return `${columnStartDate.format('MMM DD, YY')} - ${columnEndDate.format(
        'MMM DD, YY'
      )}${suffix}`;
    }

    return (
      (column.title ||
        (column.range &&
          formatRange(
            column.range,
            columnStartDate,
            columnEndDate,
            now,
            fullYear ? 'MMM YYYY' : 'MMM YY',
            column.rangeOffset,
            fiscalYearMonth
          )) ||
        (columnStartDate &&
          formatDates(
            columnStartDate,
            columnEndDate,
            fullYear ? 'MMM YYYY' : 'MMM YY'
          )) ||
        '') + (suffix ? ' ' + suffix : '')
    );
  }
};

const sortReplacements = (replacements) => {
  const sorted = [...replacements];
  let swapped = true;
  const maxIterations = 1000; // Prevent infinite loop
  let iterations = 0;

  // Loop until no swaps are made, ensuring exhaustive comparison
  while (swapped && iterations++ < maxIterations) {
    swapped = false;
    for (let i = 0; i < sorted.length - 1; i++) {
      for (let j = i + 1; j < sorted.length; j++) {
        const a = sorted[i];
        const b = sorted[j];

        if (b.original === a.updated || a.updated.includes(b.original)) {
          // Swap if necessary
          [sorted[i], sorted[j]] = [sorted[j], sorted[i]];
          swapped = true;
        }
      }
    }
  }

  return sorted;
};
module.exports.sortReplacements = sortReplacements;

module.exports.getChangedLabelPermutations = (
  oldRowIndex,
  oldColIndex,
  newRowIndex,
  newColIndex,
  result
) => {
  const oldRowLabel = rowIndexToLabel(oldRowIndex);
  const newRowLabel = rowIndexToLabel(newRowIndex);
  const oldColLabel = columnIndexToLabel(oldColIndex);
  const newColLabel = columnIndexToLabel(newColIndex);

  const permutations = [
    [0, 0],
    [1, 0],
    [0, 1],
    [1, 1],
  ];

  for (let i = 0; i < permutations.length; i++) {
    const [leftAbs, rightAbs] = permutations[i];

    const oldLabel = `${leftAbs ? '$' : ''}${oldColLabel}${
      rightAbs ? '$' : ''
    }${oldRowLabel}`;
    const newLabel = `${leftAbs ? '$' : ''}${newColLabel}${
      rightAbs ? '$' : ''
    }${newRowLabel}`;

    result[oldLabel] = newLabel;
    result[oldLabel.toLowerCase()] = newLabel;
  }

  return result;
};

module.exports.shiftFormula = function (
  formula,
  shiftX,
  shiftY,
  allowShiftAbsolute
) {
  const regex = /([$])?([A-Za-z]+)([$])?([0-9]+)/g;
  const tokensToIgnore = [
    'DAYS360',
    'BIN2',
    'DEC2',
    'HEX2',
    'LOG10',
    'OCT2',
    'SUMX2',
    'MY2',
    'PY2',
    'SUMXMY2',
  ];

  const tokens = formula.match(regex);

  if (!tokens) return formula;

  const replacements = {};

  tokens.forEach((token) => {
    if (replacements[token]) return;
    if (tokensToIgnore.includes(token)) {
      return;
    }
    const [row, col] = extractLabel(token);

    if (!row.isAbsolute || allowShiftAbsolute) {
      row.index += shiftY;
    }
    if (!col.isAbsolute || allowShiftAbsolute) {
      col.index += shiftX;
    }

    replacements[token] = toLabel(row, col);
  });

  const replacementsList = [];

  Object.keys(replacements).forEach((original) => {
    replacementsList.push({
      original,
      updated: replacements[original],
    });
  });

  let result = formula;

  const sortedReplacements = sortReplacements(replacementsList);

  sortedReplacements.forEach((replacement) => {
    if (replacement.original === replacement.updated) return;

    let rex = new RegExp(
      `(?<!\\$)(?:\\b|^|(?<=\\W))(${escapeRegExp(replacement.original)})\\b(\\D|$)`
    );
    let match = result.match(rex);

    while (match) {
      const updated =
        escapeRegExpSubstr(replacement.updated) + escapeRegExpSubstr(match[2]);

      result = result.replace(rex, updated);

      rex = new RegExp(
        `(?<!\\$)(?:\\b|^|(?<=\\W))(${escapeRegExp(replacement.original)})\\b(\\D|$)`
      );
      match = result.match(rex);
    }
  });

  return result;
};

module.exports.parseIdentifier = function (
  identifier,
  alwaysArray = false,
  type = null
) {
  if (
    typeof identifier === 'string' &&
    identifier[0] === '[' &&
    identifier[identifier.length - 1] === ']'
  ) {
    const values = identifier.slice(1, identifier.length - 1).split(',');
    if (type) {
      return values.map((value) => {
        if (type === 'number') {
          return isNaN(value) ? 0 : parseInt(value, 10);
        }
        return value;
      });
    }
    return values;
  }
  let result = identifier;
  if (type === 'number') {
    result = isNaN(identifier) ? 0 : parseInt(identifier, 10);
  }
  return alwaysArray ? [result] : result;
};

module.exports.formatTree = function formatTree(treeData) {
  return treeData.map((item) => {
    const [itemType, identifier, companyId] = item.value.split('_');

    // if (item.children.length) {
    if (itemType === 'total') {
      return {
        value: item.value,
        name: item.name.replace('Total ', ''),
        defaultName: item.defaultName,
        blankWhenExpanded: true,
        freezeSort: true,
        integration: item.integration,
        rowStyle: item.rowStyle,
        rowHidden: item.rowHidden,
        invert: item.invert,
        children:
          item.children.length || item.isGroup
            ? formatTree(item.children).concat({
                value: item.value,
                integration: item.integration,
                invert: item.invert,
                name:
                  item.name.indexOf('Total') === 0
                    ? item.name
                    : `Total ${item.name}`,
                defaultName: item.defaultName,
                children: [],
                rowStyle: {
                  fontWeight: 700,
                  borderTop: '1px double #cccccc',
                },
              })
            : [],
      };
    } else if (itemType === 'accountTotal' || itemType === 'cfAccountTotal') {
      const accountType =
        itemType === 'cfAccountTotal' ? 'cfAccount' : 'account';
      return {
        value: `${accountType}_${identifier}${
          companyId ? `_${companyId}` : ''
        }`,
        name: item.name.replace(' - Total', ''),
        nameWithoutAccountNumber: item.nameWithoutAccountNumber
          ? item.nameWithoutAccountNumber.replace(' - Total', '')
          : undefined,
        integration: item.integration,
        active: typeof item.active === 'boolean' ? item.active : true,
        altCollapsedData: true,
        altCollapsedItem: {
          itemType: `${accountType}Total`,
          identifier: identifier,
          companyId,
          integration: item.integration,
        },
        children: formatTree(
          item.children.slice(0, item.children.length - 1)
        ).concat({
          integration: item.integration,
          value: `${accountType}Total_${identifier}${
            companyId ? `_${companyId}` : ''
          }`,
          name: `Total ${item.name.replace(' - Total', '')}`,
          nameWithoutAccountNumber: item.nameWithoutAccountNumber
            ? `Total ${item.nameWithoutAccountNumber.replace(' - Total', '')}`
            : undefined,
          freezeSort: true,
          active: typeof item.active === 'boolean' ? item.active : true,
          rowStyle: {
            fontWeight: 700,
            borderTop: '1px double #cccccc',
          },
          invert: item.invert,
          children: [],
        }),
      };
    }
    // }

    return item;
  });
};

/**
 * Performs a depth-first traversal over all of the node descendants,
 * incrementing currentIndex by 1 for each
 */
function getNodeDataAtTreeIndexOrNextIndex({
  targetIndex,
  node,
  currentIndex,
  getNodeKey,
  path = [],
  lowerSiblingCounts = [],
  ignoreCollapsed = true,
  isPseudoRoot = false,
}) {
  // The pseudo-root is not considered in the path
  const selfPath = !isPseudoRoot
    ? [...path, getNodeKey({ node, treeIndex: currentIndex })]
    : [];

  // Return target node when found
  if (currentIndex === targetIndex) {
    return {
      node,
      lowerSiblingCounts,
      path: selfPath,
    };
  }

  // Add one and continue for nodes with no children or hidden children
  if (!node.children || (ignoreCollapsed && node.expanded !== true)) {
    return { nextIndex: currentIndex + 1 };
  }

  // Iterate over each child and their descendants and return the
  // target node if childIndex reaches the targetIndex
  let childIndex = currentIndex + 1;
  const childCount = node.children.length;
  for (let i = 0; i < childCount; i += 1) {
    const result = getNodeDataAtTreeIndexOrNextIndex({
      ignoreCollapsed,
      getNodeKey,
      targetIndex,
      node: node.children[i],
      currentIndex: childIndex,
      lowerSiblingCounts: [...lowerSiblingCounts, childCount - i - 1],
      path: selfPath,
    });

    if (result.node) {
      return result;
    }

    childIndex = result.nextIndex;
  }

  // If the target node is not found, return the farthest traversed index
  return { nextIndex: childIndex };
}

const getDescendantCount = (module.exports.getDescendantCount =
  function getDescendantCount({ node, ignoreCollapsed = true }) {
    return (
      getNodeDataAtTreeIndexOrNextIndex({
        getNodeKey: () => {},
        ignoreCollapsed,
        node,
        currentIndex: 0,
        targetIndex: -1,
      }).nextIndex - 1
    );
  });

/**
 * Walk all descendants of the given node, depth-first
 *
 * @param {Object} args - Function parameters
 * @param {function} args.callback - Function to call on each node
 * @param {function} args.getNodeKey - Function to get the key from the nodeData and tree index
 * @param {boolean} args.ignoreCollapsed - Ignore children of nodes without `expanded` set to `true`
 * @param {boolean=} args.isPseudoRoot - If true, this node has no real data, and only serves
 *                                        as the parent of all the nodes in the tree
 * @param {Object} args.node - A tree node
 * @param {Object=} args.parentNode - The parent node of `node`
 * @param {number} args.currentIndex - The treeIndex of `node`
 * @param {number[]|string[]} args.path - Array of keys leading up to node to be changed
 * @param {number[]} args.lowerSiblingCounts - An array containing the count of siblings beneath the
 *                                             previous nodes in this path
 *
 * @return {number|false} nextIndex - Index of the next sibling of `node`,
 *                                    or false if the walk should be terminated
 */
function walkDescendants({
  callback,
  getNodeKey,
  ignoreCollapsed,
  isPseudoRoot = false,
  node,
  parentNode = null,
  currentIndex,
  path = [],
  lowerSiblingCounts = [],
  noChildren = false,
}) {
  // The pseudo-root is not considered in the path
  const selfPath = isPseudoRoot
    ? []
    : [...path, getNodeKey({ node, treeIndex: currentIndex })];
  const selfInfo = isPseudoRoot
    ? null
    : {
        node,
        parentNode,
        path: selfPath,
        treeIndex: currentIndex,
      };

  if (!isPseudoRoot) {
    const callbackResult = callback(selfInfo);

    // Cut walk short if the callback returned false
    if (callbackResult === false) {
      return false;
    }
  }

  // Return self on nodes with no children or hidden children
  if (
    !node.children ||
    (node.expanded !== true && ignoreCollapsed && !isPseudoRoot)
  ) {
    return currentIndex;
  }

  // Get all descendants
  let childIndex = currentIndex;
  const childCount = node.children.length;
  if (typeof node.children !== 'function') {
    for (let i = 0; i < childCount; i += 1) {
      childIndex = walkDescendants({
        callback,
        getNodeKey,
        ignoreCollapsed,
        node: node.children[i],
        parentNode: isPseudoRoot ? null : node,
        currentIndex: childIndex + 1,
        lowerSiblingCounts: [...lowerSiblingCounts, childCount - i - 1],
        path: selfPath,
        noChildren,
      });

      // Cut walk short if the callback returned false
      if (childIndex === false) {
        return false;
      }
    }
  }

  if (noChildren && node && node.children && Array.isArray(node.children)) {
    delete node.children;
  }

  return childIndex;
}

/**
 * Perform a change on the given node and all its descendants, traversing the tree depth-first
 *
 * @param {Object} args - Function parameters
 * @param {function} args.callback - Function to call on each node
 * @param {function} args.getNodeKey - Function to get the key from the nodeData and tree index
 * @param {boolean} args.ignoreCollapsed - Ignore children of nodes without `expanded` set to `true`
 * @param {boolean=} args.isPseudoRoot - If true, this node has no real data, and only serves
 *                                        as the parent of all the nodes in the tree
 * @param {Object} args.node - A tree node
 * @param {Object=} args.parentNode - The parent node of `node`
 * @param {number} args.currentIndex - The treeIndex of `node`
 * @param {number[]|string[]} args.path - Array of keys leading up to node to be changed
 * @param {number[]} args.lowerSiblingCounts - An array containing the count of siblings beneath the
 *                                             previous nodes in this path
 *
 * @return {number|false} nextIndex - Index of the next sibling of `node`,
 *                                    or false if the walk should be terminated
 */
function mapDescendants({
  callback,
  getNodeKey,
  ignoreCollapsed,
  isPseudoRoot = false,
  node,
  parentNode = null,
  currentIndex,
  path = [],
  lowerSiblingCounts = [],
}) {
  const nextNode = { ...node };

  // The pseudo-root is not considered in the path
  const selfPath = isPseudoRoot
    ? []
    : [...path, getNodeKey({ node: nextNode, treeIndex: currentIndex })];
  const selfInfo = {
    node: nextNode,
    parentNode,
    path: selfPath,
    lowerSiblingCounts,
    treeIndex: currentIndex,
  };

  // Return self on nodes with no children or hidden children
  if (
    !nextNode.children ||
    (nextNode.expanded !== true && ignoreCollapsed && !isPseudoRoot)
  ) {
    return {
      treeIndex: currentIndex,
      node: callback(selfInfo),
    };
  }

  // Get all descendants
  let childIndex = currentIndex;
  const childCount = nextNode.children.length;
  if (typeof nextNode.children !== 'function') {
    nextNode.children = nextNode.children.map((child, i) => {
      const mapResult = mapDescendants({
        callback,
        getNodeKey,
        ignoreCollapsed,
        node: child,
        parentNode: isPseudoRoot ? null : nextNode,
        currentIndex: childIndex + 1,
        lowerSiblingCounts: [...lowerSiblingCounts, childCount - i - 1],
        path: selfPath,
      });
      childIndex = mapResult.treeIndex;

      return mapResult.node;
    });
  }

  return {
    node: callback(selfInfo),
    treeIndex: childIndex,
  };
}

/**
 * Count all the visible (expanded) descendants in the tree data.
 *
 * @param {!Object[]} treeData - Tree data
 *
 * @return {number} count
 */
module.exports.getVisibleNodeCount = function getVisibleNodeCount({
  treeData,
}) {
  const traverse = (node) => {
    if (
      !node.children ||
      node.expanded !== true ||
      typeof node.children === 'function'
    ) {
      return 1;
    }

    return (
      1 +
      node.children.reduce(
        (total, currentNode) => total + traverse(currentNode),
        0
      )
    );
  };

  return treeData.reduce(
    (total, currentNode) => total + traverse(currentNode),
    0
  );
};

/**
 * Get the <targetIndex>th visible node in the tree data.
 *
 * @param {!Object[]} treeData - Tree data
 * @param {!number} targetIndex - The index of the node to search for
 * @param {!function} getNodeKey - Function to get the key from the nodeData and tree index
 *
 * @return {{
 *      node: Object,
 *      path: []string|[]number,
 *      lowerSiblingCounts: []number
 *  }|null} node - The node at targetIndex, or null if not found
 */
module.exports.getVisibleNodeInfoAtIndex = function getVisibleNodeInfoAtIndex({
  treeData,
  index: targetIndex,
  getNodeKey,
}) {
  if (!treeData || treeData.length < 1) {
    return null;
  }

  // Call the tree traversal with a pseudo-root node
  const result = getNodeDataAtTreeIndexOrNextIndex({
    targetIndex,
    getNodeKey,
    node: {
      children: treeData,
      expanded: true,
    },
    currentIndex: -1,
    path: [],
    lowerSiblingCounts: [],
    isPseudoRoot: true,
  });

  if (result.node) {
    return result;
  }

  return null;
};

/**
 * Walk descendants depth-first and call a callback on each
 *
 * @param {!Object[]} treeData - Tree data
 * @param {!function} getNodeKey - Function to get the key from the nodeData and tree index
 * @param {function} callback - Function to call on each node
 * @param {boolean=} ignoreCollapsed - Ignore children of nodes without `expanded` set to `true`
 *
 * @return void
 */
function walk({
  treeData,
  getNodeKey,
  callback,
  ignoreCollapsed = true,
  noChildren = false,
}) {
  if (!treeData || treeData.length < 1) {
    return;
  }

  walkDescendants({
    callback,
    getNodeKey,
    ignoreCollapsed,
    isPseudoRoot: true,
    node: { children: treeData },
    currentIndex: -1,
    path: [],
    lowerSiblingCounts: [],
    noChildren,
  });
}

/**
 * Perform a depth-first transversal of the descendants and
 *  make a change to every node in the tree
 *
 * @param {!Object[]} treeData - Tree data
 * @param {!function} getNodeKey - Function to get the key from the nodeData and tree index
 * @param {function} callback - Function to call on each node
 * @param {boolean=} ignoreCollapsed - Ignore children of nodes without `expanded` set to `true`
 *
 * @return {Object[]} changedTreeData - The changed tree data
 */
const map = (module.exports.map = function map({
  treeData,
  getNodeKey,
  callback,
  ignoreCollapsed = true,
}) {
  if (!treeData || treeData.length < 1) {
    return [];
  }

  return mapDescendants({
    callback,
    getNodeKey,
    ignoreCollapsed,
    isPseudoRoot: true,
    node: { children: treeData },
    currentIndex: -1,
    path: [],
    lowerSiblingCounts: [],
  }).node.children;
});

/**
 * Expand or close every node in the tree
 *
 * @param {!Object[]} treeData - Tree data
 * @param {?boolean} expanded - Whether the node is expanded or not
 *
 * @return {Object[]} changedTreeData - The changed tree data
 */
module.exports.toggleExpandedForAll = function toggleExpandedForAll({
  treeData,
  expanded = true,
}) {
  return map({
    treeData,
    callback: ({ node }) => ({ ...node, expanded }),
    getNodeKey: ({ treeIndex }) => treeIndex,
    ignoreCollapsed: false,
  });
};

/**
 * Replaces node at path with object, or callback-defined object
 *
 * @param {!Object[]} treeData
 * @param {number[]|string[]} path - Array of keys leading up to node to be changed
 * @param {function|any} newNode - Node to replace the node at the path with, or a function producing the new node
 * @param {!function} getNodeKey - Function to get the key from the nodeData and tree index
 * @param {boolean=} ignoreCollapsed - Ignore children of nodes without `expanded` set to `true`
 *
 * @return {Object[]} changedTreeData - The changed tree data
 */
const changeNodeAtPath = (module.exports.changeNodeAtPath =
  function changeNodeAtPath({
    treeData,
    path,
    newNode,
    getNodeKey,
    ignoreCollapsed = true,
  }) {
    const RESULT_MISS = 'RESULT_MISS';
    const traverse = ({
      isPseudoRoot = false,
      node,
      currentTreeIndex,
      pathIndex,
    }) => {
      if (
        !isPseudoRoot &&
        getNodeKey({ node, treeIndex: currentTreeIndex }) !== path[pathIndex]
      ) {
        return RESULT_MISS;
      }

      if (pathIndex >= path.length - 1) {
        // If this is the final location in the path, return its changed form
        return typeof newNode === 'function'
          ? newNode({ node, treeIndex: currentTreeIndex })
          : newNode;
      }
      if (!node.children) {
        // If this node is part of the path, but has no children, return the unchanged node
        throw new Error('Path referenced children of node with no children.');
      }

      let nextTreeIndex = currentTreeIndex + 1;
      for (let i = 0; i < node.children.length; i += 1) {
        const result = traverse({
          node: node.children[i],
          currentTreeIndex: nextTreeIndex,
          pathIndex: pathIndex + 1,
        });

        // If the result went down the correct path
        if (result !== RESULT_MISS) {
          if (result) {
            // If the result was truthy (in this case, an object),
            //  pass it to the next level of recursion up
            return {
              ...node,
              children: [
                ...node.children.slice(0, i),
                result,
                ...node.children.slice(i + 1),
              ],
            };
          }
          // If the result was falsy (returned from the newNode function), then
          //  delete the node from the array.
          return {
            ...node,
            children: [
              ...node.children.slice(0, i),
              ...node.children.slice(i + 1),
            ],
          };
        }

        nextTreeIndex +=
          1 + getDescendantCount({ node: node.children[i], ignoreCollapsed });
      }

      return RESULT_MISS;
    };

    // Use a pseudo-root node in the beginning traversal
    const result = traverse({
      node: { children: treeData },
      currentTreeIndex: -1,
      pathIndex: -1,
      isPseudoRoot: true,
    });

    if (result === RESULT_MISS) {
      throw new Error('No node found at the given path.');
    }

    return result.children;
  });

/**
 * Removes the node at the specified path and returns the resulting treeData.
 *
 * @param {!Object[]} treeData
 * @param {number[]|string[]} path - Array of keys leading up to node to be deleted
 * @param {!function} getNodeKey - Function to get the key from the nodeData and tree index
 * @param {boolean=} ignoreCollapsed - Ignore children of nodes without `expanded` set to `true`
 *
 * @return {Object[]} changedTreeData - The tree data with the node removed
 */
module.exports.removeNodeAtPath = function removeNodeAtPath({
  treeData,
  path,
  getNodeKey,
  ignoreCollapsed = true,
}) {
  return changeNodeAtPath({
    treeData,
    path,
    getNodeKey,
    ignoreCollapsed,
    newNode: null, // Delete the node
  });
};

/**
 * Removes the node at the specified path and returns the resulting treeData.
 *
 * @param {!Object[]} treeData
 * @param {number[]|string[]} path - Array of keys leading up to node to be deleted
 * @param {!function} getNodeKey - Function to get the key from the nodeData and tree index
 * @param {boolean=} ignoreCollapsed - Ignore children of nodes without `expanded` set to `true`
 *
 * @return {Object} result
 * @return {Object[]} result.treeData - The tree data with the node removed
 * @return {Object} result.node - The node that was removed
 * @return {number} result.treeIndex - The previous treeIndex of the removed node
 */
module.exports.removeNode = function removeNode({
  treeData,
  path,
  getNodeKey,
  ignoreCollapsed = true,
}) {
  let removedNode = null;
  let removedTreeIndex = null;
  const nextTreeData = changeNodeAtPath({
    treeData,
    path,
    getNodeKey,
    ignoreCollapsed,
    newNode: ({ node, treeIndex }) => {
      // Store the target node and delete it from the tree
      removedNode = node;
      removedTreeIndex = treeIndex;

      return null;
    },
  });

  return {
    treeData: nextTreeData,
    node: removedNode,
    treeIndex: removedTreeIndex,
  };
};

/**
 * Gets the node at the specified path
 *
 * @param {!Object[]} treeData
 * @param {number[]|string[]} path - Array of keys leading up to node to be deleted
 * @param {!function} getNodeKey - Function to get the key from the nodeData and tree index
 * @param {boolean=} ignoreCollapsed - Ignore children of nodes without `expanded` set to `true`
 *
 * @return {Object|null} nodeInfo - The node info at the given path, or null if not found
 */
module.exports.getNodeAtPath = function ({
  treeData,
  path,
  getNodeKey,
  ignoreCollapsed = true,
}) {
  let foundNodeInfo = null;

  try {
    changeNodeAtPath({
      treeData,
      path,
      getNodeKey,
      ignoreCollapsed,
      newNode: ({ node, treeIndex }) => {
        foundNodeInfo = { node, treeIndex };
        return node;
      },
    });
  } catch (err) {
    // Ignore the error -- the null return will be explanation enough
  }

  return foundNodeInfo;
};

/**
 * Adds the node to the specified parent and returns the resulting treeData.
 *
 * @param {!Object[]} treeData
 * @param {!Object} newNode - The node to insert
 * @param {number|string} parentKey - The key of the to-be parentNode of the node
 * @param {!function} getNodeKey - Function to get the key from the nodeData and tree index
 * @param {boolean=} ignoreCollapsed - Ignore children of nodes without `expanded` set to `true`
 * @param {boolean=} expandParent - If true, expands the parentNode specified by parentPath
 * @param {boolean=} addAsFirstChild - If true, adds new node as first child of tree
 *
 * @return {Object} result
 * @return {Object[]} result.treeData - The updated tree data
 * @return {number} result.treeIndex - The tree index at which the node was inserted
 */
module.exports.addNodeUnderParent = function ({
  treeData,
  newNode,
  parentKey = null,
  getNodeKey,
  ignoreCollapsed = true,
  expandParent = false,
  addAsFirstChild = false,
}) {
  if (parentKey === null) {
    return {
      treeData: [...(treeData || []), newNode],
      treeIndex: (treeData || []).length,
    };
  }

  let insertedTreeIndex = null;
  let hasBeenAdded = false;
  const changedTreeData = map({
    treeData,
    getNodeKey,
    ignoreCollapsed,
    callback: ({ node, treeIndex, path }) => {
      const key = path ? path[path.length - 1] : null;
      // Return nodes that are not the parent as-is
      if (hasBeenAdded || key !== parentKey) {
        return node;
      }
      hasBeenAdded = true;

      const parentNode = {
        ...node,
      };

      if (expandParent) {
        parentNode.expanded = true;
      }

      // If no children exist yet, just add the single newNode
      if (!parentNode.children) {
        insertedTreeIndex = treeIndex + 1;
        return {
          ...parentNode,
          children: [newNode],
        };
      }

      if (typeof parentNode.children === 'function') {
        throw new Error('Cannot add to children defined by a function');
      }

      let nextTreeIndex = treeIndex + 1;
      for (let i = 0; i < parentNode.children.length; i += 1) {
        nextTreeIndex +=
          1 +
          getDescendantCount({ node: parentNode.children[i], ignoreCollapsed });
      }

      insertedTreeIndex = nextTreeIndex;

      const children = addAsFirstChild
        ? [newNode, ...parentNode.children]
        : [...parentNode.children, newNode];

      return {
        ...parentNode,
        children,
      };
    },
  });

  if (!hasBeenAdded) {
    throw new Error('No node found with the given key.');
  }

  return {
    treeData: changedTreeData,
    treeIndex: insertedTreeIndex,
  };
};

function addNodeAtDepthAndIndex({
  targetDepth,
  minimumTreeIndex,
  newNode,
  ignoreCollapsed,
  expandParent,
  isPseudoRoot = false,
  isLastChild,
  node,
  currentIndex,
  currentDepth,
  getNodeKey,
  path = [],
}) {
  const selfPath = (n) =>
    isPseudoRoot
      ? []
      : [...path, getNodeKey({ node: n, treeIndex: currentIndex })];

  // If the current position is the only possible place to add, add it
  if (
    currentIndex >= minimumTreeIndex - 1 ||
    (isLastChild && !(node.children && node.children.length))
  ) {
    if (typeof node.children === 'function') {
      throw new Error('Cannot add to children defined by a function');
    } else {
      const extraNodeProps = expandParent ? { expanded: true } : {};
      const nextNode = {
        ...node,

        ...extraNodeProps,
        children: node.children ? [newNode, ...node.children] : [newNode],
      };

      return {
        node: nextNode,
        nextIndex: currentIndex + 2,
        insertedTreeIndex: currentIndex + 1,
        parentPath: selfPath(nextNode),
        parentNode: isPseudoRoot ? null : nextNode,
      };
    }
  }

  // If this is the target depth for the insertion,
  // i.e., where the newNode can be added to the current node's children
  if (currentDepth >= targetDepth - 1) {
    // Skip over nodes with no children or hidden children
    if (
      !node.children ||
      typeof node.children === 'function' ||
      (node.expanded !== true && ignoreCollapsed && !isPseudoRoot)
    ) {
      return { node, nextIndex: currentIndex + 1 };
    }

    // Scan over the children to see if there's a place among them that fulfills
    // the minimumTreeIndex requirement
    let childIndex = currentIndex + 1;
    let insertedTreeIndex = null;
    let insertIndex = null;
    for (let i = 0; i < node.children.length; i += 1) {
      // If a valid location is found, mark it as the insertion location and
      // break out of the loop
      if (childIndex >= minimumTreeIndex) {
        insertedTreeIndex = childIndex;
        insertIndex = i;
        break;
      }

      // Increment the index by the child itself plus the number of descendants it has
      childIndex +=
        1 + getDescendantCount({ node: node.children[i], ignoreCollapsed });
    }

    // If no valid indices to add the node were found
    if (insertIndex === null) {
      // If the last position in this node's children is less than the minimum index
      // and there are more children on the level of this node, return without insertion
      if (childIndex < minimumTreeIndex && !isLastChild) {
        return { node, nextIndex: childIndex };
      }

      // Use the last position in the children array to insert the newNode
      insertedTreeIndex = childIndex;
      insertIndex = node.children.length;
    }

    // Insert the newNode at the insertIndex
    const nextNode = {
      ...node,
      children: [
        ...node.children.slice(0, insertIndex),
        newNode,
        ...node.children.slice(insertIndex),
      ],
    };

    // Return node with successful insert result
    return {
      node: nextNode,
      nextIndex: childIndex,
      insertedTreeIndex,
      parentPath: selfPath(nextNode),
      parentNode: isPseudoRoot ? null : nextNode,
    };
  }

  // Skip over nodes with no children or hidden children
  if (
    !node.children ||
    typeof node.children === 'function' ||
    (node.expanded !== true && ignoreCollapsed && !isPseudoRoot)
  ) {
    return { node, nextIndex: currentIndex + 1 };
  }

  // Get all descendants
  let insertedTreeIndex = null;
  let pathFragment = null;
  let parentNode = null;
  let childIndex = currentIndex + 1;
  let newChildren = node.children;
  if (typeof newChildren !== 'function') {
    newChildren = newChildren.map((child, i) => {
      if (insertedTreeIndex !== null) {
        return child;
      }

      const mapResult = addNodeAtDepthAndIndex({
        targetDepth,
        minimumTreeIndex,
        newNode,
        ignoreCollapsed,
        expandParent,
        isLastChild: isLastChild && i === newChildren.length - 1,
        node: child,
        currentIndex: childIndex,
        currentDepth: currentDepth + 1,
        getNodeKey,
        path: [], // Cannot determine the parent path until the children have been processed
      });

      if ('insertedTreeIndex' in mapResult) {
        ({
          insertedTreeIndex,
          parentNode,
          parentPath: pathFragment,
        } = mapResult);
      }

      childIndex = mapResult.nextIndex;

      return mapResult.node;
    });
  }

  const nextNode = { ...node, children: newChildren };
  const result = {
    node: nextNode,
    nextIndex: childIndex,
  };

  if (insertedTreeIndex !== null) {
    result.insertedTreeIndex = insertedTreeIndex;
    result.parentPath = [...selfPath(nextNode), ...pathFragment];
    result.parentNode = parentNode;
  }

  return result;
}

/**
 * Insert a node into the tree at the given depth, after the minimum index
 *
 * @param {!Object[]} treeData - Tree data
 * @param {!number} depth - The depth to insert the node at (the first level of the array being depth 0)
 * @param {!number} minimumTreeIndex - The lowest possible treeIndex to insert the node at
 * @param {!Object} newNode - The node to insert into the tree
 * @param {boolean=} ignoreCollapsed - Ignore children of nodes without `expanded` set to `true`
 * @param {boolean=} expandParent - If true, expands the parent of the inserted node
 * @param {!function} getNodeKey - Function to get the key from the nodeData and tree index
 *
 * @return {Object} result
 * @return {Object[]} result.treeData - The tree data with the node added
 * @return {number} result.treeIndex - The tree index at which the node was inserted
 * @return {number[]|string[]} result.path - Array of keys leading to the node location after insertion
 * @return {Object} result.parentNode - The parent node of the inserted node
 */
module.exports.insertNode = function ({
  treeData,
  depth: targetDepth,
  minimumTreeIndex,
  newNode,
  getNodeKey = () => {},
  ignoreCollapsed = true,
  expandParent = false,
}) {
  if (!treeData && targetDepth === 0) {
    return {
      treeData: [newNode],
      treeIndex: 0,
      path: [getNodeKey({ node: newNode, treeIndex: 0 })],
      parentNode: null,
    };
  }

  const insertResult = addNodeAtDepthAndIndex({
    targetDepth,
    minimumTreeIndex,
    newNode,
    ignoreCollapsed,
    expandParent,
    getNodeKey,
    isPseudoRoot: true,
    isLastChild: true,
    node: { children: treeData },
    currentIndex: -1,
    currentDepth: -1,
  });

  if (!('insertedTreeIndex' in insertResult)) {
    throw new Error('No suitable position found to insert.');
  }

  const treeIndex = insertResult.insertedTreeIndex;
  return {
    treeData: insertResult.node.children,
    treeIndex,
    path: [
      ...insertResult.parentPath,
      getNodeKey({ node: newNode, treeIndex }),
    ],
    parentNode: insertResult.parentNode,
  };
};

/**
 * Get tree data flattened.
 *
 * @param {!Object[]} treeData - Tree data
 * @param {!function} getNodeKey - Function to get the key from the nodeData and tree index
 * @param {boolean=} ignoreCollapsed - Ignore children of nodes without `expanded` set to `true`
 *
 * @return {{
 *      node: Object,
 *      path: []string|[]number,
 *      lowerSiblingCounts: []number
 *  }}[] nodes - The node array
 */
module.exports.getFlatDataFromTree = function getFlatDataFromTree({
  treeData,
  getNodeKey,
  ignoreCollapsed = true,
  noChildren = false,
}) {
  if (!treeData || treeData.length < 1) {
    return [];
  }

  const flattened = [];
  walk({
    treeData,
    getNodeKey,
    ignoreCollapsed,
    noChildren,
    callback: (nodeInfo) => {
      flattened.push(nodeInfo);
    },
  });

  return flattened;
};

/**
 * Generate a tree structure from flat data.
 *
 * @param {!Object[]} flatData
 * @param {!function=} getKey - Function to get the key from the nodeData
 * @param {!function=} getParentKey - Function to get the parent key from the nodeData
 * @param {string|number=} rootKey - The value returned by `getParentKey` that corresponds to the root node.
 *                                  For example, if your nodes have id 1-99, you might use rootKey = 0
 *
 * @return {Object[]} treeData - The flat data represented as a tree
 */
function getTreeFromFlatData({
  flatData,
  getKey = (node) => node.id,
  getParentKey = (node) => node.parentId,
  rootKey = '0',
}) {
  if (!flatData) {
    return [];
  }

  const childrenToParents = {};
  flatData.forEach((child) => {
    const parentKey = getParentKey(child);

    if (parentKey in childrenToParents) {
      childrenToParents[parentKey].push(child);
    } else {
      childrenToParents[parentKey] = [child];
    }
  });

  if (!(rootKey in childrenToParents)) {
    return [];
  }

  const trav = (parent) => {
    const parentKey = getKey(parent);
    if (parentKey in childrenToParents) {
      return {
        ...parent,
        children: childrenToParents[parentKey].map((child) => trav(child)),
      };
    }

    return { ...parent };
  };

  return childrenToParents[rootKey].map((child) => trav(child));
}
module.exports.getTreeFromFlatData = getTreeFromFlatData;

/**
 * Check if a node is a descendant of another node.
 *
 * @param {!Object} older - Potential ancestor of younger node
 * @param {!Object} younger - Potential descendant of older node
 *
 * @return {boolean}
 */
function isDescendant(older, younger) {
  return (
    !!older.children &&
    typeof older.children !== 'function' &&
    older.children.some(
      (child) => child === younger || isDescendant(child, younger)
    )
  );
}
module.exports.isDescendant = isDescendant;

/**
 * Get the maximum depth of the children (the depth of the root node is 0).
 *
 * @param {!Object} node - Node in the tree
 * @param {?number} depth - The current depth
 *
 * @return {number} maxDepth - The deepest depth in the tree
 */
function getDepth(node, depth = 0) {
  if (!node.children) {
    return depth;
  }

  if (typeof node.children === 'function') {
    return depth + 1;
  }

  return node.children.reduce(
    (deepest, child) => Math.max(deepest, getDepth(child, depth + 1)),
    depth
  );
}
module.exports.getDepth = getDepth;

/**
 * Find nodes matching a search query in the tree,
 *
 * @param {!function} getNodeKey - Function to get the key from the nodeData and tree index
 * @param {!Object[]} treeData - Tree data
 * @param {?string|number} searchQuery - Function returning a boolean to indicate whether the node is a match or not
 * @param {!function} searchMethod - Function returning a boolean to indicate whether the node is a match or not
 * @param {?number} searchFocusOffset - The offset of the match to focus on
 *                                      (e.g., 0 focuses on the first match, 1 on the second)
 * @param {boolean=} expandAllMatchPaths - If true, expands the paths to any matched node
 * @param {boolean=} expandFocusMatchPaths - If true, expands the path to the focused node
 *
 * @return {Object[]} matches - An array of objects containing the matching `node`s, their `path`s and `treeIndex`s
 * @return {Object[]} treeData - The original tree data with all relevant nodes expanded.
 *                               If expandAllMatchPaths and expandFocusMatchPaths are both false,
 *                               it will be the same as the original tree data.
 */
function find({
  getNodeKey,
  treeData,
  searchQuery,
  searchMethod,
  searchFocusOffset,
  expandAllMatchPaths = false,
  expandFocusMatchPaths = true,
}) {
  let matchCount = 0;
  const trav = ({ isPseudoRoot = false, node, currentIndex, path = [] }) => {
    let matches = [];
    let isSelfMatch = false;
    let hasFocusMatch = false;
    // The pseudo-root is not considered in the path
    const selfPath = isPseudoRoot
      ? []
      : [...path, getNodeKey({ node, treeIndex: currentIndex })];
    const extraInfo = isPseudoRoot
      ? null
      : {
          path: selfPath,
          treeIndex: currentIndex,
        };

    // Nodes with with children that aren't lazy
    const hasChildren =
      node.children &&
      typeof node.children !== 'function' &&
      node.children.length > 0;

    // Examine the current node to see if it is a match
    if (!isPseudoRoot && searchMethod({ ...extraInfo, node, searchQuery })) {
      if (matchCount === searchFocusOffset) {
        hasFocusMatch = true;
      }

      // Keep track of the number of matching nodes, so we know when the searchFocusOffset
      //  is reached
      matchCount += 1;

      // We cannot add this node to the matches right away, as it may be changed
      //  during the search of the descendants. The entire node is used in
      //  comparisons between nodes inside the `matches` and `treeData` results
      //  of this method (`find`)
      isSelfMatch = true;
    }

    let childIndex = currentIndex;
    const newNode = { ...node };
    if (hasChildren) {
      // Get all descendants
      newNode.children = newNode.children.map((child) => {
        const mapResult = trav({
          node: child,
          currentIndex: childIndex + 1,
          path: selfPath,
        });

        // Ignore hidden nodes by only advancing the index counter to the returned treeIndex
        // if the child is expanded.
        //
        // The child could have been expanded from the start,
        // or expanded due to a matching node being found in its descendants
        if (mapResult.node.expanded) {
          childIndex = mapResult.treeIndex;
        } else {
          childIndex += 1;
        }

        if (mapResult.matches.length > 0 || mapResult.hasFocusMatch) {
          matches = [...matches, ...mapResult.matches];
          if (mapResult.hasFocusMatch) {
            hasFocusMatch = true;
          }

          // Expand the current node if it has descendants matching the search
          // and the settings are set to do so.
          if (
            (expandAllMatchPaths && mapResult.matches.length > 0) ||
            ((expandAllMatchPaths || expandFocusMatchPaths) &&
              mapResult.hasFocusMatch)
          ) {
            newNode.expanded = true;
          }
        }

        return mapResult.node;
      });
    }

    // Cannot assign a treeIndex to hidden nodes
    if (!isPseudoRoot && !newNode.expanded) {
      matches = matches.map((match) => ({
        ...match,
        treeIndex: null,
      }));
    }

    // Add this node to the matches if it fits the search criteria.
    // This is performed at the last minute so newNode can be sent in its final form.
    if (isSelfMatch) {
      matches = [{ ...extraInfo, node: newNode }, ...matches];
    }

    return {
      node: matches.length > 0 ? newNode : node,
      matches,
      hasFocusMatch,
      treeIndex: childIndex,
    };
  };

  const result = trav({
    node: { children: treeData },
    isPseudoRoot: true,
    currentIndex: -1,
  });

  return {
    matches: result.matches,
    treeData: result.node.children,
  };
}
module.exports.find = find;

/**
 * Compress cell styles
 * @param {Object} cellStyles - Cell styles
 * @return {string>}
 */
function compressCellStyles(cellStyles) {
  const cellStylesString = JSON.stringify(cellStyles);
  const cellStylesBuffer = Buffer.from(cellStylesString);
  const compressedCellStyles = zlib.gzipSync(cellStylesBuffer);
  const base64Data = compressedCellStyles.toString('base64');

  return base64Data;
}
module.exports.compressCellStyles = compressCellStyles;

/**
 * Decompress cell styles
 * @param {Object} table - Table
 * @return {Object} cellStyles - Cell styles
 */
function getDecompressedCellStyles(table) {
  let cellStyles = {};
  if (table?.compressedCellStyles) {
    try {
      const compressedBuffer = Buffer.from(
        table.compressedCellStyles,
        'base64'
      );
      const decompressedBuffer = zlib.gunzipSync(compressedBuffer);
      const decompressedString = decompressedBuffer.toString('utf-8');
      cellStyles = JSON.parse(decompressedString);
    } catch (e) {
      cellStyles = table?.cellStyles || {};
    }
  } else {
    cellStyles = table?.cellStyles || {};
  }

  return cellStyles;
}
module.exports.getDecompressedCellStyles = getDecompressedCellStyles;

async function topologicalSort(cells, debug) {
  const hasCircularRef = [];

  cells.forEach((cell) => {
    cell.tsReferences = cell.references.slice(0);
    cell.tsReferencedBy = cell.referencedBy.slice(0);

    if (cell.tsReferences.includes(cell)) {
      hasCircularRef.push(cell);
    }
  });

  if (hasCircularRef.length) {
    hasCircularRef.forEach((cell) => {
      cell.tsReferences.forEach((ref) => {
        if (ref !== cell) {
          ref.tsReferencedBy = ref.tsReferencedBy.filter((n) => n !== cell);
        }
      });
    });
  }

  const result = [];

  const startNodes = cells.filter((cell) => !cell.tsReferencedBy.length);

  let index = 0;
  const iterations = isNodeJs ? 10 : 1000;

  await smartWait(async (awaiter) => {
    while (startNodes.length) {
      if (index++ % iterations === 0) {
        const promise = awaiter();
        if (promise) {
          await promise;
        }
      }

      const node = startNodes.pop();
      result.push(node);
      node.tsReferences.forEach((child) => {
        node.tsReferences = node.tsReferences.filter((n) => n !== child);
        if (child.tsReferencedBy) {
          child.tsReferencedBy = child.tsReferencedBy.filter((n) => n !== node);
        }
        if (child.tsReferencedBy && !child.tsReferencedBy.length) {
          startNodes.push(child);
        }
      });
    }
  });

  let loops = [];
  cells.forEach((cell) => {
    if (cell.tsReferencedBy.length || cell.tsReferences.length) {
      loops.push(cell);
    }
  });
  loops = new Set(loops);

  if (loops.size) {
    return {
      errors: Array.from(loops).filter((cell) => cell.formula),
      result: reverse(result.filter((cell) => !loops.has(cell))),
    };
  }
  return {
    errors: [],
    result: reverse(result),
  };
}
module.exports.topologicalSort = topologicalSort;

function parseBudgetValuesFromActuals(budgetValuesFromActuals, budgetFilterId) {
  const result = {};
  const filterKey = budgetFilterId ?? '-1';

  Object.keys(budgetValuesFromActuals).forEach((rowId) => {
    Object.keys(budgetValuesFromActuals[rowId]).forEach((columnId) => {
      if (
        typeof budgetValuesFromActuals[rowId][columnId][filterKey] !==
        'undefined'
      ) {
        if (!result[rowId]) {
          result[rowId] = {};
        }
        result[rowId][columnId] =
          budgetValuesFromActuals[rowId][columnId][filterKey];
      }
    });
  });

  return result;
}
module.exports.parseBudgetValuesFromActuals = parseBudgetValuesFromActuals;

function serializeTableData(data) {
  const { columns, bookMonth, budgetBookMonth, dateOverride, ...rest } = data;

  const result = {
    ...rest,
    bookMonth: bookMonth?.format ? bookMonth.format() : bookMonth,
    budgetBookMonth: budgetBookMonth?.format
      ? budgetBookMonth.format()
      : budgetBookMonth,
    topItemStartDate: rest.topItemStartDate?.format
      ? rest.topItemStartDate.format()
      : rest.topItemStartDate,
    topItemEndDate: rest.topItemEndDate?.format
      ? rest.topItemEndDate?.format()
      : rest.topItemEndDate,
    startDate: rest.startDate?.format
      ? rest.startDate?.format()
      : rest.startDate,
    endDate: rest.endDate?.format ? rest.endDate?.format() : rest.endDate,
    columns: columns.map(({ startDate, endDate, ...restCol }) => {
      return {
        ...restCol,
        startDate: startDate?.format ? startDate?.format() : startDate,
        endDate: endDate?.format ? endDate?.format() : endDate,
      };
    }),
    dateOverride: {
      ...dateOverride,
      entries: dateOverride?.entries?.map((entry) => {
        const colKeys = Object.keys(entry.columns);

        if (!colKeys.length) {
          return entry;
        }

        const newCols = {};
        colKeys.forEach((key) => {
          newCols[key] = {
            ...entry.columns[key],
            startDate: entry.columns[key].startDate?.format
              ? entry.columns[key].startDate?.format()
              : entry.columns[key].startDate,
            endDate: entry.columns[key].endDate?.format
              ? entry.columns[key].endDate?.format()
              : entry.columns[key].endDate,
          };
        });

        return {
          ...entry,
          columns: newCols,
        };
      }),
    },
  };

  return result;
}
module.exports.serializeTableData = serializeTableData;

function deserializeTableData(data) {
  if (data.bookMonth) {
    data.bookMonth = moment.utc(data.bookMonth);
  }

  if (data.budgetBookMonth) {
    data.budgetBookMonth = moment.utc(data.budgetBookMonth);
  }

  if (data.topItemStartDate) {
    data.topItemStartDate = moment.utc(data.topItemStartDate);
  }

  if (data.topItemEndDate) {
    data.topItemEndDate = moment.utc(data.topItemEndDate);
  }

  data.columns.forEach((col) => {
    if (col.startDate) {
      col.startDate = moment.utc(col.startDate);
    }
    if (col.endDate) {
      col.endDate = moment.utc(col.endDate);
    }
  });

  if (data.startDate) {
    data.startDate = moment.utc(data.startDate);
  }

  if (data.endDate) {
    data.endDate = moment.utc(data.endDate);
  }

  if (data.dateOverride) {
    data.dateOverride.entries.forEach((entry) => {
      const colKeys = Object.keys(entry.columns);

      if (!colKeys.length) {
        return;
      }

      const newCols = {};
      colKeys.forEach((key) => {
        if (entry.columns[key].startDate) {
          entry.columns[key].startDate = moment.utc(
            entry.columns[key].startDate
          );
        }
        if (entry.columns[key].endDate) {
          entry.columns[key].endDate = moment.utc(entry.columns[key].endDate);
        }

        newCols[key] = entry.columns[key];
      });

      entry.columns = newCols;
    });
  }

  return data;
}
module.exports.deserializeTableData = deserializeTableData;

function serializeTopItemSettings(topItemSettings) {
  if (!topItemSettings) {
    return null;
  }

  return {
    ...topItemSettings,
    topItemStartDate: topItemSettings.topItemStartDate?.format
      ? topItemSettings.topItemStartDate?.format()
      : topItemSettings.topItemStartDate,
    topItemEndDate: topItemSettings.topItemEndDate?.format
      ? topItemSettings.topItemEndDate?.format()
      : topItemSettings.topItemEndDate,
  };
}
module.exports.serializeTopItemSettings = serializeTopItemSettings;

function deserializeTopItemSettings(topItemSettings) {
  if (topItemSettings.topItemStartDate) {
    topItemSettings.topItemStartDate = moment.utc(
      topItemSettings.topItemStartDate
    );
  }
  if (topItemSettings.topItemEndDate) {
    topItemSettings.topItemEndDate = moment.utc(topItemSettings.topItemEndDate);
  }

  return topItemSettings;
}
module.exports.deserializeTopItemSettings = deserializeTopItemSettings;
