import fileSize from './fileSize';
import { checkPng } from './png';
import { showError, showWarning } from './popups';

export const MAX_IMAGE_FILE_SIZE = 5242880; // 5MB
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/jpg', 'image/png'];

interface Props {
  file: File;
  maxFileSize?: number;
  next: (file: File) => void;
  onError?: (message: string) => any;
}

export const imagePreprocessor = ({
  file,
  maxFileSize = MAX_IMAGE_FILE_SIZE,
  next,
  onError,
}: Props): boolean => {
  let message = '';
  let error = false;
  if (file.size > maxFileSize) {
    // If file size larger than size limit, show warning and stop upload
    message = `Maximum file upload size is ${fileSize(maxFileSize)}.`;
    error = true;
  } else if (!ALLOWED_FILE_TYPES.includes(file.type)) {
    // If file is something other than jpg/jpeg or png show warning and stop upload
    message = 'Only JPG/JPEG and PNG files are allowed.';
    error = true;
  } else if (file.name.length > 60) {
    // If file name is longer than 60 characters, show warning and stop upload
    message = 'File name is too long. Maximum length is 60 characters.';
    error = true;
  }

  if (error) {
    showWarning({
      title: 'Oops...',
      text: message,
    });
    return false;
  } else {
    if (file.type === 'image/png') {
      checkPng(
        file,
        (nextFile: File) => {
          next(nextFile);
        },
        (errorResult: { title: any; message: any }) => {
          showError({ title: errorResult.title, text: errorResult.message });
          onError && onError(errorResult.message);
        }
      );
    } else {
      next(file);
    }
    return true;
  }
};
