import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { Query } from '@apollo/client/react/components';

import GlobalStyles from 'components/GlobalStyles';

class NotAuthenticatedRoute extends React.PureComponent {
  renderRoute = props => {
    const Component = this.props.component;

    return (
      <React.Fragment>
        <GlobalStyles />
        <Component {...props} />
      </React.Fragment>
    );
  };
  render() {
    return <Route render={this.renderRoute} path={this.props.path} />;
  }
}

export default NotAuthenticatedRoute;
