/**
 *
 * SvgButton
 *
 */
import React from 'react';
import styled from 'styled-components';

const A = styled.a`
  color: ${(props) => props.theme.colors.link};
`;

const SvgButtonDiv = styled.div`
  text-align: center;
  margin: 15px;
  display: inline-block;
  & img {
    transition: filter 0.2s;
  }
  & img:hover {
    filter: brightness(0.9);
  }
`;

const SvgButtonEl = styled.button`
  padding: 0;
  margin: 0;
  display: block;
`;

const SvgButton = (props) => {
  const { src, helpLink, helpText, onClick, className, id } = props;

  return (
    <SvgButtonDiv className={className}>
      <SvgButtonEl
        onClick={onClick}
        type="button"
        className="connection-button"
        id={id}
      >
        <img src={src} width="350" />
      </SvgButtonEl>
      {!!helpLink && (
        <A href={helpLink} target="_blank">
          {helpText}
        </A>
      )}
    </SvgButtonDiv>
  );
};

export default SvgButton;
