import { useState, useCallback, useMemo } from 'react';

export default function useSavedState(name, initialValue, type = null) {
  const loadedInitialValue = useMemo(() => {
    const value = window.localStorage.getItem(name) || initialValue;

    const typeValue = type === null ? initialValue : type;

    switch (typeof typeValue) {
      case 'number': {
        const result = parseInt(value, 10);
        if (isNaN(result)) {
          return initialValue;
        }
        return result;
      }
      case 'boolean':
        return value === 'true' || value === true;
      case 'object':
        try {
          return typeof value === 'string' ? JSON.parse(value) : value;
        } catch {
          return value;
        }
      case 'string':
      default:
        return value;
    }
  }, [initialValue, name, type]);

  const [value, setValue] = useState(loadedInitialValue);

  const onChange = useCallback(
    newValue => {
      setValue(newValue);

      if (newValue === null) {
        window.localStorage.removeItem(name);
      } else if (typeof newValue === 'object') {
        window.localStorage.setItem(name, JSON.stringify(newValue));
      } else {
        window.localStorage.setItem(name, newValue);
      }
    },
    [name]
  );

  return [value, onChange];
}
