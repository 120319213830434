import styled from 'styled-components';
import Button from 'components/Button';
import CreateDatasheetForm from 'components/CreateDatasheetForm';

export const Scroll = styled.div`
  overflow-y: auto;
`;

export const Table = styled.table`
  width: 100%;
  margin-bottom: 20px;
  border-collapse: separate;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BottomExcel = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const BottomGoogleSheet = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const EditName = styled.div`
  display: flex;
`;

export const Back = styled.button`
  color: ${(props) => props.theme.colors.link};
  margin-top: 15px;
  font-size: 13px;
`;

export const LibraryBack = styled(Back)`
  margin-top: 0;
`;

export const LibraryBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LibraryCreateForm = styled(CreateDatasheetForm)`
  width: 100%;
  margin-left: auto;
  display: flex;
`;

export const Tr = styled.tr`
  & td {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }
  & td:first-child {
    border-left: 1px solid transparent;
  }
  & td:last-child {
    border-right: 1px solid transparent;
  }
`;

export const LibraryTr = styled(Tr)`
  && td {
    border-color: ${(props) =>
      props.selected ? props.theme.colors.info : 'transparent'};
  }
  &:hover td {
    border-color: ${(props) => props.theme.colors.info};
  }
`;

export const LibraryButton = styled(Button)`
  margin-left: 10px;
`;

export const Clearfix = styled.div`
  clear: both;
`;

export const LibraryText = styled(Back)`
  float: right;
`;

export const OutlinedButton = styled(Button).attrs({
  color: 'default',
  outline: true,
})`
  border: none;
  margin-right: 10px;
`;

export const A = styled.a`
  color: ${(props) => props.theme.colors.link};
  font-size: 0.775rem;
  margin-right: 1rem;
`;
