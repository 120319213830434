import { gql } from '@apollo/client';
import { REPORT_LIST_FIELDS } from 'fragments';

export const REPORT_LIST = gql`
  query ReportList($companyId: Int!) {
    company(id: $companyId) {
      id
      aiEnabled
      logo {
        id
        url
      }
      reports {
        ...ReportListFields
      }
    }
  }
  ${REPORT_LIST_FIELDS}
`;
