import styled from 'styled-components';
import theme from 'styled-theming';

export const FieldGroup = styled.div`
  position: relative;
  margin-bottom: 1rem;
`;

export const Label = styled.label`
  font-size: 0.75rem;
  display: block;
`;

export const Input = styled.input`
  display: block;
`;

const messageColor = theme('mode', {
  dark: '#3a404f',
  light: '#617182',
});

export const Message = styled.div`
  color: ${messageColor};
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 4px;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`;

export const Delete = styled.button`
  color: ${({ theme: _theme }) => _theme.colors.danger} !important;
  font-size: 0.75rem;
  margin-left: 1rem;
  margin-right: 1rem;
`;
