import { useApolloClient } from '@apollo/client';
import Promise from 'bluebird';
import {
  integrationToLogo,
  integrationToName,
} from 'components/IntegrationLogos/util';
import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { captureException } from 'utils/sentry';
import useAccountingIntegrationId from './useAccountingIntegrationId';
import useAsync from './useAsync';
import useCompanyId from './useCompanyId';
import useIntegrations from './useIntegrations';
import useIsConsolidation from './useIsConsolidation';
import useMountedState from './useMountedState';

const VARIABLES_QUERY = gql`
  query UseDataVariables(
    $companyId: Int!
    $integration: Integration!
    $consolidatedCompanyId: Int
  ) {
    company(id: $companyId) {
      id
      customKpiVariables(integration: $integration)
      consolidationCompanies {
        id
        parentConsolidationId
        name
        abbreviation
        sharedDataVariables(inConsolidation: true)
        customKpiVariables(
          integration: $integration
          consolidatedCompanyId: $consolidatedCompanyId
        )
      }
    }
  }
`;

function loadVariables(client, companyId, integrations) {
  return Promise.mapSeries(integrations, async (integration) => {
    const { data } = await client.query({
      query: VARIABLES_QUERY,
      variables: {
        companyId,
        integration,
        consolidatedCompanyId: companyId,
      },
    });

    return {
      icon: integrationToLogo[integration].lg,
      text: integrationToName[integration],
      data: data.company.customKpiVariables,
      consolidationCompanies: data.company.consolidationCompanies,
    };
  });
}

export default function useDataVariables(_companyId) {
  const isMounted = useMountedState();
  const client = useApolloClient();
  const loadedCompanyId = useCompanyId();
  const companyId = _companyId || loadedCompanyId;

  const integrations = useIntegrations(companyId);
  const accountingIntegration = useAccountingIntegrationId({ companyId });
  const isCurrentCompanyConsolidation = useIsConsolidation(
    loadedCompanyId || companyId
  );
  const [dataVariablesByIntegration, setDataVariables] = useState(null);

  const { execute } = useAsync(loadVariables, false);

  useEffect(() => {
    if (
      companyId &&
      integrations &&
      accountingIntegration !== null &&
      isCurrentCompanyConsolidation !== null
    ) {
      if (isCurrentCompanyConsolidation && !accountingIntegration) {
        return;
      }

      setDataVariables(null);
      execute(
        client,
        companyId,
        isCurrentCompanyConsolidation ? [accountingIntegration] : integrations
      )
        .then((result) => {
          if (isMounted()) {
            setDataVariables(result);
          }
        })
        .catch((err) => {
          captureException(err);
        });
    }
  }, [
    accountingIntegration,
    client,
    companyId,
    execute,
    integrations,
    isCurrentCompanyConsolidation,
    isMounted,
  ]);

  return dataVariablesByIntegration;
}
