/**
 *
 * FileUploader
 *
 */

import React, { useCallback, useMemo, useState } from 'react';
import { FileError, useDropzone } from 'react-dropzone';

import DelimitedFileIcon from '../Icons/DelimitedFileIcon';
import ExcelFileIcon from '../Icons/ExcelFileIcon';
import PdfFileIcon from '../Icons/PdfFileIcon';
import WordFileIcon from '../Icons/WordFileIcon';
import { showWarning } from 'utils/popups';
import { get } from 'lodash';
import {
  Dropzone,
  ICON_COLOR,
  Info,
  LogoContainer,
  Text,
} from './styledComponents';
import fileSize from 'utils/fileSize';
import LoadingIndicator from 'components/LoadingIndicator';
import { checkPng } from 'utils/png';

const MAX_SIZE_IN_BYTES = 31457280; // 30 megabytes
const MAX_NAME_LENGTH = 60;
const IMAGE_EXTENSIONS = ['png', 'jpeg', 'jpg'];

export type AcceptedFileTypes =
  | 'pdf'
  | 'xlsx'
  | 'xls'
  | 'xlsm'
  | 'docx'
  | 'doc'
  | 'png'
  | 'jpeg'
  | 'jpg'
  | string;

export interface FileUploaderProps {
  acceptedFileTypes: AcceptedFileTypes[];
  onStage: <T extends File>(files: T[]) => void;
  onError?: ({ title, message }: { title: string; message: string }) => void;
  usedStorage?: number;
  storageCapacity?: number;
  maxFileSize?: number;
  maxNameLength?: number;
  CustomDropzone?: React.ReactElement;
  showConstraints?: boolean;
  defaultImageUrl?: string;
  loading?: boolean;
  disabled?: boolean;
}

const FileUploader: React.FunctionComponent<FileUploaderProps> = ({
  acceptedFileTypes,
  onStage,
  onError,
  usedStorage,
  storageCapacity,
  maxFileSize = MAX_SIZE_IN_BYTES,
  maxNameLength = MAX_NAME_LENGTH,
  CustomDropzone,
  showConstraints,
  defaultImageUrl,
  loading,
  disabled,
}) => {
  const [toUpload, setToUpload] = useState<File[] | null>(null);
  const [icon, setIcon] = useState<React.ReactNode | null>(null);

  const getFileIcon = useCallback(
    (extension: string, imageDataUrl?: string | ArrayBuffer | null) => {
      switch (extension) {
        case 'pdf': {
          setIcon(
            <PdfFileIcon width={'100%'} height={'100%'} color={ICON_COLOR} />
          );
          break;
        }
        case 'xlsx':
        case 'xls':
        case 'xlsm': {
          setIcon(
            <ExcelFileIcon width={'100%'} height={'100%'} color={ICON_COLOR} />
          );
          break;
        }
        case 'csv': {
          setIcon(
            <DelimitedFileIcon
              width={'100%'}
              height={'100%'}
              color={ICON_COLOR}
            />
          );
          break;
        }
        case 'png':
        case 'jpeg':
        case 'jpg': {
          setIcon(
            <LogoContainer>
              {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <img src={imageDataUrl} height={'100%'} />
              }
            </LogoContainer>
          );
          break;
        }
        case 'doc':
        case 'docx':
        default: {
          setIcon(
            <WordFileIcon width={'100%'} height={'100%'} color={ICON_COLOR} />
          );
          break;
        }
      }
    },
    []
  );

  const validator = useCallback(
    <T extends File>(file: T): FileError | FileError[] | null => {
      const pathSplit = file.name.split('.');
      const extension = pathSplit[pathSplit.length - 1];

      if (acceptedFileTypes.indexOf(extension) === -1) {
        return {
          code: 'file-type-not-allowed',
          message: `The file type ${extension} is not allowed.`,
        };
      }

      if (usedStorage && storageCapacity) {
        if (file.size + usedStorage > storageCapacity) {
          return {
            code: 'max-capacity-reached',
            message: 'The file size exceeds the available storage space.',
          };
        }
      }
      if (file.name.length > maxNameLength) {
        return {
          code: 'file-name-too-long',
          message: `The file name exceeds the maximum length of ${maxNameLength} characters.`,
        };
      }
      return null;
    },
    [acceptedFileTypes, usedStorage, storageCapacity, maxNameLength]
  );

  const onDrop = useCallback(
    <T extends File>(files: T[]) => {
      if (files.length) {
        const firstItem = files.slice(0, 1);
        setToUpload(firstItem);
        onStage(firstItem);
        const reader = new FileReader();
        reader.onload = () => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const pathSplit = firstItem[0].path.split('.');
          const extension = pathSplit[pathSplit.length - 1];
          getFileIcon(extension, reader.result);
        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const pathSplit = firstItem[0].path.split('.');
        const extension = pathSplit[pathSplit.length - 1];
        if (IMAGE_EXTENSIONS.includes(extension)) {
          if (extension === 'png') {
            checkPng(
              firstItem[0],
              (file: File) => reader.readAsDataURL(file),
              ({ title, message }: { title: string; message: string }) => {
                onError && onError({ title, message });
                setToUpload(null);
              }
            );
          } else {
            reader.readAsDataURL(firstItem[0]);
          }
        } else {
          getFileIcon(extension);
        }
      }
    },
    [onStage, getFileIcon, onError]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected: (files) => {
      if (files.length) {
        let message = '';
        const errorCode = get(files[0], 'errors[0].code', '');
        const errorMessage = get(files[0], 'errors[0].message', '');
        if (errorCode === 'file-too-large') {
          message = `Maximum file upload size is ${fileSize(maxFileSize)}.`;
        } else if (files.length > 1) {
          message = 'Only 1 file is allowed at a time';
        } else if (
          errorCode === 'max-capacity-reached' ||
          errorCode === 'file-name-too-long' ||
          errorCode === 'file-type-not-allowed'
        ) {
          message = errorMessage;
        }
        showWarning({
          title: 'Oops...',
          text: message,
        });
      }
    },
    validator,
    multiple: false,
    maxSize: maxFileSize,
  });

  const acceptedFiles = acceptedFileTypes
    .map((fileType) => `.${fileType}`)
    .join(', ');

  const DropzoneInput = useMemo(
    () =>
      loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <input
            disabled={disabled}
            {...getInputProps()}
            accept={acceptedFiles}
          />
          {toUpload ? (
            icon
          ) : defaultImageUrl ? (
            <LogoContainer>
              <img src={defaultImageUrl} height={'100%'} />
            </LogoContainer>
          ) : (
            <Text>Click here or drag and drop a file to start</Text>
          )}
        </>
      ),
    [
      acceptedFiles,
      defaultImageUrl,
      disabled,
      getInputProps,
      icon,
      loading,
      toUpload,
    ]
  );

  return (
    <div>
      <div {...getRootProps()}>
        {CustomDropzone ? (
          React.cloneElement(CustomDropzone, {
            isDragActive,
            children: DropzoneInput,
          })
        ) : (
          <Dropzone dragActive={isDragActive}>{DropzoneInput}</Dropzone>
        )}
      </div>
      {showConstraints && (
        <>
          <Info>Max File Size: {<span>{fileSize(maxFileSize)}</span>}</Info>
          <Info>Allowed File Types: {`.${acceptedFileTypes.join(', .')}`}</Info>
        </>
      )}
    </div>
  );
};

export default FileUploader;
