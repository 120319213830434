import React from 'react';

import { Clearfix } from '../common-styled-components';
import ExcelUploader from '../ExcelUploader';

const CreateExcelDatasheet = (props) => {
  const { onClose, onExcelSuccess, onExcelError, googleSpreadsheetId } = props;

  return (
    <div>
      <ExcelUploader
        acceptedFileTypes={['xlsx', 'xls']}
        onCompleted={onClose}
        onExcelSuccess={onExcelSuccess}
        onExcelError={onExcelError}
        googleSpreadsheetId={googleSpreadsheetId}
      />
      <Clearfix />
    </div>
  );
};

export default CreateExcelDatasheet;
