import React from 'react';
import styled from 'styled-components';

import csv from './csv.svg';

const XeroLogo = styled.button`
  margin-left: auto;
  margin-right: auto;
  width: 350px;
  padding: 0;
  margin-bottom: 0.25rem;
  & img {
    width: 100%;
    transition: filter 0.2s;
  }
  & img:hover {
    filter: brightness(0.9);
  }
`;

function CsvButton(props) {
  const { onClick, className, ...rest } = props;

  return (
    <XeroLogo
      onClick={onClick}
      type="button"
      className={`connection-button ${className || ''}`}
      {...rest}
    >
      <img src={csv} />
    </XeroLogo>
  );
}

export default CsvButton;
