import { useQuery, gql } from '@apollo/client';
import { useMemo } from 'react';
import { LINK } from 'environment';

const ADMINISTRATOR_QUERY = gql`
  query IsAdministrator {
    currentUser {
      administrator
    }
  }
`;

export default function useIsAdministrator() {
  const { data } = useQuery(ADMINISTRATOR_QUERY, {
    skip: LINK,
  });

  return useMemo(() => {
    if (data && data.currentUser) {
      return data.currentUser.administrator;
    }
    return null;
  }, [data]);
}
