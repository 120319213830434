/**
 *
 * LoginForm
 *
 */

import React, { useCallback, useEffect, useState } from 'react';
import { isRequired, isEmail } from 'validation';
import { login } from 'auth';
import { captureException } from 'utils/sentry';

import Alert from 'components/Alert';
import FormGroup from 'components/FormGroup';

import FieldError from 'components/FieldError';
import ToggleVisible from 'components/ToggleVisible';
import {
  Button,
  Fields,
  ForgotLink,
  Form,
  P,
  PasswordToggleContainer,
  ResendButton,
  TextField,
} from './styledComponents';
import { useHistory } from 'react-router-dom';
import useMaintenanceMode from 'hooks/useMaintenanceMode';
import PasswordToggle from 'components/PasswordToggle';

import { sizes } from 'media';
import useMedia from 'hooks/useMedia';
import { TwoFactorAuthenticationMethod } from 'interfaces/user';
import { AuthenticationSource } from 'interfaces/authenticationSource';
import { capitalizeWord } from 'utils/capitalizeWord';

type TwoFactorState = {
  needsTwoFactorAuth: boolean;
  phoneLast4: string | null;
  method: TwoFactorAuthenticationMethod | null;
  verifiedTotp: boolean;
};

const DEFAULT_TWO_FACTOR_STATE = {
  needsTwoFactorAuth: false,
  phoneLast4: null,
  method: null,
  verifiedTotp: false,
};

type LoginForm = { email: string; password: string; twoFactorCode?: string };

interface Props {
  email?: string;
  hideForgotLink?: boolean;
  redirect?: string;
  popup?: string;
  className?: string;
  requiredAuthenticationSource?: AuthenticationSource | null;
  onSuccess?: () => void;
}

const LoginForm: React.FunctionComponent<Props> = ({
  email,
  hideForgotLink,
  redirect,
  popup,
  className,
  requiredAuthenticationSource,
  onSuccess,
}: Props): React.ReactElement => {
  const history = useHistory();
  const { down, loading: loadingMaintenance } = useMaintenanceMode();
  const [loading, setLoading] = useState(false);
  const [twoFactorState, setTwoFactorState] = useState<TwoFactorState>(
    DEFAULT_TWO_FACTOR_STATE
  );
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [lastCode, setLastCode] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (requiredAuthenticationSource) {
      setError(
        `This account requires you to sign in with ${capitalizeWord(requiredAuthenticationSource)}`
      );
    }
  }, [requiredAuthenticationSource]);

  const handleSubmit = useCallback(
    async (values: LoginForm, method: TwoFactorState['method']) => {
      const { email: formEmail, password, twoFactorCode } = values;
      setLoading(true);
      try {
        setLastCode(twoFactorCode);
        const result = await login(
          formEmail,
          password,
          twoFactorCode,
          popup,
          method
        );
        if (result.success) {
          if (onSuccess) {
            onSuccess();
          } else {
            window.location.pathname = '/';
          }
        }

        if (result.needsTwoFactorAuth) {
          setTwoFactorState((prevState) => {
            if (!result.method) {
              return { ...prevState, ...result, method };
            } else {
              return { ...prevState, ...result };
            }
          });
        } else {
          if (!result.success) {
            setTwoFactorState(DEFAULT_TWO_FACTOR_STATE);
          }
        }
        if (result.error) {
          setError(result.error);
        } else if (result.success) {
          setError(null);
        }
        if (redirect) {
          history.push(redirect);
        }
        if (down && !result?.login?.needsTwoFactorAuth) {
          window.location.pathname = '/';
        }
      } catch (err) {
        captureException(err, true);
      }
      setLoading(false);
    },
    [popup, redirect, down, onSuccess, history]
  );

  const onResendCode = useCallback(
    (values: LoginForm, method?: TwoFactorAuthenticationMethod | null) =>
      handleSubmit(
        { email: values.email, password: values.password },
        method || twoFactorState.method
      ),
    [handleSubmit, twoFactorState.method]
  );

  const toggleTwoFactorType = useCallback(
    (values: LoginForm) => {
      if (twoFactorState.method === TwoFactorAuthenticationMethod.PHONE) {
        setTwoFactorState({
          ...twoFactorState,
          method: TwoFactorAuthenticationMethod.TOTP,
        });
      } else {
        setTwoFactorState({
          ...twoFactorState,
          method: TwoFactorAuthenticationMethod.PHONE,
        });
        onResendCode(values, TwoFactorAuthenticationMethod.PHONE);
      }
    },
    [twoFactorState, onResendCode]
  );

  return (
    <Form
      onSubmit={(values) => handleSubmit(values, twoFactorState.method)}
      initialValues={{ email: email || '', password: '' }}
      className={className}
    >
      {({ formState, formApi }) => {
        const switchMethod = () => {
          toggleTwoFactorType({
            email: formState.values.email,
            password: formState.values.password,
          });
          formApi.setValue('twoFactorCode', '');
        };
        return (
          <div>
            {error && (
              <Alert color="danger" outline>
                {error}
              </Alert>
            )}
            <Fields>
              <ToggleVisible show={!twoFactorState.needsTwoFactorAuth}>
                <FormGroup>
                  <TextField
                    field="email"
                    type="text"
                    id="username"
                    placeholder="EMAIL"
                    validate={isEmail}
                    validateOnBlur
                    autoComplete={email ? 'off' : 'email username'}
                    disabled={!!email}
                    data-1p-ignore="false"
                  />
                  <FieldError error={formState.errors.email} />
                </FormGroup>
                <FormGroup>
                  <PasswordToggle
                    show={showPassword}
                    onClick={() => setShowPassword(!showPassword)}
                    size={1.25}
                    maxWidth={360}
                    style={{ top: '10%' }}
                  >
                    <TextField
                      field="password"
                      type={showPassword ? 'text' : 'password'}
                      id="password"
                      name="password"
                      placeholder="PASSWORD"
                      validate={isRequired}
                      validateOnBlur
                      autoComplete="current-password"
                      data-1p-ignore="false"
                    />
                  </PasswordToggle>
                  <FieldError error={formState.errors.password} />
                  {!hideForgotLink && (
                    <ForgotLink to="/forgot">Forgot password?</ForgotLink>
                  )}
                </FormGroup>
              </ToggleVisible>
              <ToggleVisible
                flex
                show={twoFactorState.needsTwoFactorAuth}
                style={{
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <P>
                  {twoFactorState.method ===
                  TwoFactorAuthenticationMethod.PHONE ? (
                    <>
                      <span>
                        Please enter the code we have sent to your phone ending
                        in {twoFactorState.phoneLast4}
                      </span>
                      {twoFactorState.verifiedTotp && (
                        <>
                          <br />
                          <span>
                            Having trouble?{' '}
                            <a
                              onClick={switchMethod}
                              style={{ cursor: 'pointer' }}
                            >
                              Use your authenticator app
                            </a>
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <span>
                        Please enter the code in your authenticator app
                      </span>
                      <br />
                      {twoFactorState.phoneLast4 && (
                        <span>
                          Having trouble?{' '}
                          <a
                            onClick={switchMethod}
                            style={{ cursor: 'pointer' }}
                          >
                            Receive code via SMS
                          </a>
                        </span>
                      )}
                    </>
                  )}

                  {twoFactorState.method ===
                    TwoFactorAuthenticationMethod.PHONE && (
                    <ResendButton
                      type="button"
                      onClick={() => onResendCode(formState.values)}
                    >
                      Resend code
                    </ResendButton>
                  )}
                </P>
                <TextField
                  field="twoFactorCode"
                  type="text"
                  id="twoFactorCode"
                  autoComplete="one-time-code"
                  data-1p-ignore="false"
                  onChange={() => setError(null)}
                  error={twoFactorState.needsTwoFactorAuth && !!error}
                />
              </ToggleVisible>
            </Fields>
            <FormGroup>
              <Button
                color="default"
                outline
                loading={loading}
                disabled={
                  loading ||
                  loadingMaintenance ||
                  (!!error &&
                    lastCode === formState.values.twoFactorCode &&
                    twoFactorState.needsTwoFactorAuth)
                }
              >
                {twoFactorState.needsTwoFactorAuth ? 'VERIFY' : 'LOGIN'}
              </Button>
            </FormGroup>
          </div>
        );
      }}
    </Form>
  );
};

export default LoginForm;
