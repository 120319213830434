/**
 *
 * UnpaidBanner
 *
 */
import React from 'react';
import styled from 'styled-components';

import Banner from 'components/Banner';
import StripePortalButton from 'components/StripePortalButton';
import useStripeUnpaid from 'hooks/useStripeUnpaid';
import { Redirect } from 'react-router';

const StyledBanner = styled(Banner)`
  background: ${props => props.theme.colors.danger};
`;

const Button = styled(StripePortalButton)`
  text-decoration: underline;
`;

const UnpaidBanner = props => {
  const { redirect } = props;

  const { unpaid, isBillingUser } = useStripeUnpaid();

  if (!unpaid) return null;

  if (redirect) return <Redirect to="/unpaid" />;

  if (!isBillingUser) return null;

  return (
    <StyledBanner>
      Your last subscription payment failed.
      <Button text="Click here to manage billing." />
    </StyledBanner>
  );
};

export default UnpaidBanner;
