/**
 *
 * ClientPortalUsers
 *
 */
import React, { useState, useCallback, useEffect } from 'react';
import gql from 'graphql-tag';
import client from 'client';

import UserListModal from 'components/UserListModal';
import { useMutation, useQuery } from '@apollo/client';
import produce from 'immer';
import usePrevious from 'hooks/usePrevious';
import { Company, CompanyInvite } from '../../interfaces/company';

import {
  GET_COMPANY_FOLDERS,
  INVITE,
  REMOVE_PORTAL_USER,
  UPDATE_USER_FOLDERS,
  UPDATE_FOLDER_USERS,
  UPDATE_FOLDERS_USERS,
  RESEND_INVITE,
  REMOVE_INVITE,
  UPDATE_INVITE_FOLDERS,
} from 'queries/ClientPortal';
import Swal from 'sweetalert2';
import { get } from 'lodash';
import { FolderContent } from 'interfaces/folder';
import { notificationToast, showError, showSuccess } from 'utils/popups';
import { User } from 'interfaces/user';
import { CLIENT_TO_WORKSPACE } from 'queries/workspaceUsers';
import useWorkspaceId from 'hooks/useWorkspaceId';
import { updateCachedClientUsers } from 'cache/workspaceUsers';
import { captureException } from 'utils/sentry';
import FolderSelectModal from 'components/FolderSelectModal';

const QUERY = gql`
  query ClientPortalUsers($companyId: Int!) {
    company(id: $companyId) {
      id
      clientPortalUsers {
        id
        fullName
        email
        clientPortalOnly
      }
      companyInvites {
        id
        companyId
        folderIds
        link
        user {
          id
          fullName
          email
          ... on OtherUser {
            inviteLink
          }
        }
      }
    }
  }
`;

interface ClientPortalUsersProps {
  title: string;
  companyId: number;
  show: boolean;
  showChecks?: boolean;
  showSave?: boolean;
  currentFolderId?: number;
  currentFolderUsers?: Array<number>;
  folderAccess?: boolean;
  showClientUserOptions?: boolean;
  onClose: () => void;
  onPreview?: (int) => void;
  isClientPortalUser?: boolean;
}

const ClientPortalUsers: React.FunctionComponent<ClientPortalUsersProps> = ({
  title,
  companyId,
  show,
  showChecks = true,
  showSave = false,
  currentFolderId,
  currentFolderUsers,
  folderAccess = true,
  showClientUserOptions,
  onClose,
  onPreview,
}) => {
  const [error, setError] = useState(null);
  const workspaceId = useWorkspaceId();
  const prevShow = usePrevious(show);
  const [showUpdateInviteFoldersModal, setShowUpdateInviteFoldersModal] =
    useState(false);
  const [focusedInvite, setFocusedInvite] = useState<CompanyInvite | null>(
    null
  );

  useEffect(() => {
    if (!prevShow && show) setError(null);
  }, [prevShow, show]);

  const { data: userData, loading: loadingUsers } = useQuery<{
    company: Company;
  }>(QUERY, {
    variables: {
      companyId,
    },
    skip: !show,
  });

  const { data: folderData, loading: loadingFolders } = useQuery(
    GET_COMPANY_FOLDERS,
    {
      variables: {
        companyId,
      },
      skip: !show,
    }
  );

  const [handleInvite, { loading: loadingInvite }] = useMutation(INVITE);
  const [handleResendInvite, { loading: loadingResendInvite }] =
    useMutation(RESEND_INVITE);
  const [handleRemoveInvite] = useMutation(REMOVE_INVITE);
  const [handleUpdateInviteFolders, { loading: loadingUpdateInviteFolders }] =
    useMutation(UPDATE_INVITE_FOLDERS);
  const [clientToWorkspace, { loading: loadingClientToWorkspace }] =
    useMutation<
      { toggleClientUser: { user?: User; error?: string } },
      {
        userId: number;
        workspaceId: number;
        clientPortalOnly: boolean;
      }
    >(CLIENT_TO_WORKSPACE);
  const [selectedFolderUsers, setFolderUsers] = useState(
    currentFolderUsers || []
  );
  const [savedCurrentFolderUsers, setSavedCurrentFolderUsers] = useState(
    currentFolderUsers || []
  );

  const resetSelection = useCallback(() => {
    setFolderUsers([]);
    onClose();
  }, [onClose, setFolderUsers]);

  // On show, set saved current folder users for referencing
  useEffect(() => {
    if (show) setSavedCurrentFolderUsers(currentFolderUsers || []);
  }, [currentFolderUsers, show]);

  // On currentFolderUsers update, update folderUsers
  useEffect(() => {
    if (!folderAccess) setFolderUsers(currentFolderUsers || []);
  }, [currentFolderUsers, folderAccess, setFolderUsers]);

  const folderAccessUpdateFinish = useCallback(() => {
    notificationToast({
      title: 'Folder Access Updated!',
      // target: '.userListModal .modalContent',
      timer: 1000,
      stopOnHover: false,
    });
  }, []);

  const [handleUserFoldersUpdate, { loading: loadingUserFoldersUpdate }] =
    useMutation(UPDATE_USER_FOLDERS, {
      onCompleted: () => {
        folderAccessUpdateFinish();
      },
    });

  const handleFolderAccessUpdate = useCallback(
    (folderAccessUpdate) => {
      handleUserFoldersUpdate({
        variables: {
          userId: folderAccessUpdate.userId,
          addFolderIds: folderAccessUpdate.toAdd,
          removeFolderIds: folderAccessUpdate.toRemove,
          companyId,
        },
        update: (cache, { data: { updateUserFolders } }) => {
          if (!updateUserFolders.success) return;

          // Add user to all given added folders
          for (const folder of updateUserFolders.addedFolders) {
            const data: FolderContent | null = cache.readFragment({
              id: `FolderContent:${folder.id}`,
              fragment: UPDATE_FOLDERS_USERS,
            });

            if (data) {
              const folderUsers = [...data.users, updateUserFolders.user];

              cache.writeFragment({
                id: `FolderContent:${folder.id}`,
                fragment: UPDATE_FOLDERS_USERS,
                data: produce(data, (draft) => {
                  draft.users = [...folderUsers];
                }),
              });
            }
          }

          // Remove user from all given removed folders
          for (const folder of updateUserFolders.removedFolders) {
            const data: FolderContent | null = cache.readFragment({
              id: `FolderContent:${folder.id}`,
              fragment: UPDATE_FOLDERS_USERS,
            });
            if (data) {
              const folderUsers = data.users.filter((user) => {
                return user.id !== updateUserFolders.user.id;
              });

              cache.writeFragment({
                id: `FolderContent:${folder.id}`,
                fragment: UPDATE_FOLDERS_USERS,
                data: produce(data, (draft) => {
                  draft.users = [...folderUsers];
                }),
              });
            }
          }
        },
      });
    },
    [handleUserFoldersUpdate, companyId]
  );

  const [handleRemove] = useMutation(REMOVE_PORTAL_USER);

  const [handleFolderUsersUpdate] = useMutation(UPDATE_FOLDER_USERS, {
    onCompleted: () => folderAccessUpdateFinish(),
  });

  const handleUserCheck = useCallback(
    (userId: number, checked: boolean) => {
      const selectionChange = [...selectedFolderUsers];
      if (checked) {
        selectionChange.push(userId);
      } else {
        selectionChange.splice(
          selectionChange.findIndex((u) => u === userId),
          1
        );
      }
      setFolderUsers(selectionChange);
    },
    [selectedFolderUsers]
  );

  const onRemove = useCallback(
    (user: User) => {
      const userId = user.id;
      Swal.fire({
        text: `Are you sure you want to remove ${user.fullName}'s access to the Client Portal?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Remove',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.value === true) {
          Swal.fire('User removed!', '', 'success');
          handleRemove({
            variables: {
              companyId,
              userId,
            },
            update: (cache) => {
              const queryData = cache.readQuery({
                query: QUERY,
                variables: {
                  companyId,
                },
              });

              client.writeQuery({
                query: QUERY,
                variables: {
                  companyId,
                },
                data: produce(queryData, (draft: { company: Company }) => {
                  draft.company.clientPortalUsers =
                    draft.company.clientPortalUsers.filter(
                      (u) => u.id !== userId
                    );
                }),
              });
            },
          });
        }
      });
    },
    [companyId, handleRemove]
  );

  const onInvite = useCallback(
    (clientInvite) => {
      return handleInvite({
        variables: {
          ...clientInvite.newClient,
          companyId,
          folderIds: clientInvite.selectedFolders,
        },
        update: (cache, { data: { inviteCompanyUser } }) => {
          if (!inviteCompanyUser.success) {
            return;
          }
          const data = cache.readQuery({
            query: QUERY,
            variables: {
              companyId,
            },
          });

          cache.writeQuery({
            query: QUERY,
            variables: {
              companyId,
            },
            data: produce(data, (draft: { company: Company }) => {
              draft.company.companyInvites = [
                ...draft.company.companyInvites,
                inviteCompanyUser.invite,
              ];
            }),
          });
        },
      });
    },
    [handleInvite, companyId]
  );

  const onResendInvite = useCallback(
    async (user: User) => {
      try {
        const { data: resendData } = await handleResendInvite({
          variables: {
            companyId,
            userId: user.id,
          },
        });

        if (resendData?.resendCompanyInvite.error) {
          setError(resendData.resendCompanyInvite.error);
        } else {
          setError(null);
          showSuccess({ text: 'Invite resent!' });
        }
      } catch (err) {
        captureException(err, true);
      }
    },
    [companyId, handleResendInvite]
  );

  const onRemoveInvite = useCallback(
    (inviteId: number, user: User) => {
      try {
        Swal.fire({
          text: `Are you sure you want to remove ${user.fullName}'s invite?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Remove',
          cancelButtonText: 'Cancel',
        }).then(async (result) => {
          if (result.value === true) {
            await handleRemoveInvite({
              variables: {
                inviteId,
              },
              update: (cache) => {
                const queryData = cache.readQuery({
                  query: QUERY,
                  variables: {
                    companyId,
                  },
                });

                client.writeQuery({
                  query: QUERY,
                  variables: {
                    companyId,
                  },
                  data: produce(queryData, (draft: { company: Company }) => {
                    draft.company.companyInvites =
                      draft.company.companyInvites.filter(
                        (invite) => invite.id !== inviteId
                      );
                  }),
                });
              },
            });
            Swal.fire('Invite removed!', '', 'success');
          }
        });
      } catch (err) {
        captureException(err, true);
      }
    },
    [companyId, handleRemoveInvite]
  );

  const toggleUpdateInviteFoldersModal = useCallback(
    (inviteId: number | null, state: boolean) => {
      const invite =
        inviteId &&
        userData?.company.companyInvites.find(
          (_invite) => _invite.id === inviteId
        );

      if (invite) {
        setFocusedInvite(invite || null);
      }

      setShowUpdateInviteFoldersModal(state);
    },
    [userData?.company.companyInvites]
  );

  const onUpdateInviteFolders = useCallback(
    (folderIds: number[]) => {
      if (focusedInvite) {
        handleUpdateInviteFolders({
          variables: {
            inviteId: focusedInvite.id,
            folderIds,
          },
          update: (cache, { data: { updateInviteFolderAccess } }) => {
            if (!updateInviteFolderAccess.success) return;

            cache.modify({
              id: cache.identify({
                __typename: 'CompanyInvite',
                id: focusedInvite.id,
              }),
              fields: {
                folderIds() {
                  return updateInviteFolderAccess.invite.folderIds;
                },
              },
            });
          },
          onCompleted: () => {
            setShowUpdateInviteFoldersModal(false);
            setFocusedInvite(null);
          },
        });
      }
    },
    [focusedInvite, handleUpdateInviteFolders]
  );

  const handleFolderUsersSave = useCallback(() => {
    let folderId;
    if (typeof currentFolderId === 'string')
      folderId = parseInt(currentFolderId);
    else folderId = currentFolderId;

    handleFolderUsersUpdate({
      variables: {
        folderId,
        addUserIds: selectedFolderUsers.filter(
          (userId) => !savedCurrentFolderUsers.includes(userId)
        ),
        removeUserIds: savedCurrentFolderUsers.filter(
          (userId) => !selectedFolderUsers.includes(userId)
        ),
      },
      update: (cache, { data: { updateFolderUsers } }) => {
        if (!updateFolderUsers.success) return;

        const updateFolderId = updateFolderUsers.folderId;
        const data: FolderContent | null = cache.readFragment({
          id: `FolderContent:${updateFolderId}`,
          fragment: UPDATE_FOLDERS_USERS,
        });

        if (data) {
          let folderUsers = [...data.users, ...updateFolderUsers.addedUsers];
          folderUsers = folderUsers.filter((folderUser) => {
            return !updateFolderUsers.removedUsers
              .map((removedUser) => removedUser.id)
              .includes(folderUser.id);
          });

          cache.writeFragment({
            id: `FolderContent:${updateFolderId}`,
            fragment: UPDATE_FOLDERS_USERS,
            data: produce(data, (draft) => {
              draft.users = [...folderUsers];
            }),
          });

          setSavedCurrentFolderUsers(
            folderUsers.map((folderUser) => folderUser.id)
          );
        }
      },
    });
  }, [
    currentFolderId,
    handleFolderUsersUpdate,
    selectedFolderUsers,
    savedCurrentFolderUsers,
  ]);

  const handlePreview = useCallback(
    (userId) => {
      onPreview && onPreview(userId);
      onClose();
    },
    [onClose, onPreview]
  );

  const handleClientToUser = useCallback(
    (user: User) => {
      clientToWorkspace({
        variables: {
          userId: user.id,
          workspaceId,
          clientPortalOnly: user.clientPortalOnly,
        },
        update: (cache, result) => {
          if (result.data?.toggleClientUser) {
            if (result.data.toggleClientUser.error) {
              showError({
                title: 'Oops...',
                text:
                  result.data.toggleClientUser.error ||
                  'There was an error changing the client to a workspace user.',
              });
            } else if (result.data.toggleClientUser.user) {
              updateCachedClientUsers(
                cache,
                QUERY,
                result,
                companyId,
                workspaceId,
                user
              );
            }
          }
        },
      });
    },
    [clientToWorkspace, companyId, workspaceId]
  );

  if (loadingUsers || !userData || !userData.company) {
    return null;
  }

  return (
    <>
      <FolderSelectModal
        title={`Update Folder Access for ${focusedInvite?.user.fullName}`}
        companyId={companyId}
        onSubmit={(folderIds) => onUpdateInviteFolders(folderIds)}
        onClose={() => toggleUpdateInviteFoldersModal(null, false)}
        selectedFolderIds={focusedInvite?.folderIds || []}
        show={showUpdateInviteFoldersModal}
        zIndex={11}
        loading={loadingUpdateInviteFolders}
      />
      <UserListModal
        className={'userListModal'}
        title={title}
        width={700}
        show={show}
        companyId={companyId}
        users={userData.company.clientPortalUsers}
        invites={userData.company.companyInvites}
        folders={get(folderData, 'company.folders', [])}
        showChecks={showChecks}
        showSave={showSave}
        selectedUserIds={selectedFolderUsers}
        folderAccess={folderAccess}
        onInvite={onInvite}
        onResendInvite={onResendInvite}
        onRemoveInvite={onRemoveInvite}
        onShowUpdateInviteFolders={(inviteId: number) =>
          toggleUpdateInviteFoldersModal(inviteId, true)
        }
        onPreview={onPreview ? handlePreview : undefined}
        onUpdate={handleFolderAccessUpdate}
        onSave={handleFolderUsersSave}
        onSelect={showChecks ? handleUserCheck : undefined}
        onRemove={folderAccess ? onRemove : undefined}
        onClose={resetSelection}
        loadingUsers={
          loadingUsers || loadingUserFoldersUpdate || loadingClientToWorkspace
        }
        loadingFolders={loadingFolders}
        loadingInvite={loadingInvite}
        loadingResendInvite={loadingResendInvite}
        loadingUpdateInviteFolders={loadingUpdateInviteFolders}
        error={error || undefined}
        onClientUserChange={handleClientToUser}
        showClientUserOptions={showClientUserOptions}
        zIndex={10}
        isClientPortalUser={true}
        inviteButtonId="client-portal-user-invite-button"
      />
    </>
  );
};

export default ClientPortalUsers;
