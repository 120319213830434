import styled, { css } from 'styled-components';

const DropdownButton = styled.button`
  width: 100%;
  padding: ${({ scale = 1 }) => scale * 10}px ${({ scale = 1 }) => scale * 20}px;
  font-size: ${({ scale = 1 }) => scale * 0.875}rem;
  color: ${(props) => props.theme.dropdown.color};
  display: block;
  text-decoration: none;
  text-align: left;
  &:hover {
    ${({ theme, disabled }) =>
      !disabled &&
      css`
        background: ${theme.dropdown.hover.background};
        color: ${theme.dropdown.hover.color};
      `}
  }

  & :disabled,
  button[aria-disabled='true'] {
    cursor: not-allowed;
  }
`;

export default DropdownButton;
