import React from 'react';

const GustoLogoSmall = ({
  width = '80px',
  height = null,
  cursor = null,
}: React.SVGProps<SVGSVGElement> & {
  cursor?: string;
}): React.ReactElement => {
  return (
    <svg
      style={{ cursor: cursor }}
      width={width}
      height={height}
      viewBox="0 0 500 500"
    >
      <circle cx="250" cy="250" r="250" fill="#fff" />
      <path
        d="M111.58,179.75l0-1,0-.59a114.47,114.47,0,0,1,6.55-32.82q24.92-69.87,96.36-90.14a101,101,0,0,1,26.58-3.91c3.53,0,7.05-.62,10.57-.48,13.47.53,26.75,2.24,39.36,7.39A93.5,93.5,0,0,1,321.58,78c.38.39.78.74,1.83,1.73-.25-3.88-.62-7.06-.62-10.22,0-4.4.26-8.81.4-13.22l.59,0h1.1c12,0,24,.12,36.05.12,8.93,0,17.87-.08,26.81-.12a3.56,3.56,0,0,1,.53.16c0,1.11,0,2.23,0,3.36V301.4c0,1,.06,2,.1,3.08-.39,12-1,23.91-3.29,35.71-4.92,25.09-16.94,46.4-34.22,65a135.84,135.84,0,0,1-68.37,39.9c-7.66,1.88-15.63,2.56-23.48,3.5a76,76,0,0,1-12.79.6c-23.65-1.15-45.89-7.31-66.34-19.49-.3-.19-.57-.44-1-.81,10.81-18.8,21.57-37.56,32.42-56.46.72.37,1.37.66,2,1A76,76,0,0,0,242.34,383c29.39,3.31,60.28-13.33,72.84-41.48a71.54,71.54,0,0,0,6.35-30.89l.38-12.4v-2.21l-.06-.43-.46.34a84.48,84.48,0,0,1-39.67,25.55c-7.66,2.32-15.75,3.26-23.66,4.82l-.6,0-1,0L239,326.33c-8.38-1.35-17-2-25.09-4.21-41.33-11.45-71.34-36.71-90-75.34a127.09,127.09,0,0,1-11.14-36.67c-.72-4.82-1.1-9.69-1.64-14.54q.21-7.9.41-15.8Zm65.16,8.46c-1.15,37,30.66,76,77.73,72.84,36.91-2.51,67-32.87,67.09-72.3.05-19.52-6.78-36.61-20.6-50.58-16.67-16.84-37-23.62-60.47-21.29-32.32,3.19-63.69,32.1-63.74,71.33Z"
        fill="#f15f4a"
      />
      <path
        d="M388.41,304.45c0-1-.09-2-.09-3.08V59.73c0-1.12,0-2.23,0-3.37a12,12,0,0,1,.57,2.57q0,121.42,0,242.82A16,16,0,0,1,388.41,304.45Z"
        fill="#f8aea3"
      />
      <path
        d="M387.76,56.22c-8.94.05-17.88.13-26.82.13-12,0-24-.08-36-.13a17.77,17.77,0,0,1,2.7-.4h57.46a19.56,19.56,0,0,1,2.7.4Z"
        fill="#fcd7d2"
      />
      <path d="M239,326.32l17.5.06Z" fill="#f69486" />
      <path
        d="M111.58,179.75c-.14,5.27-.27,10.54-.42,15.8C111.31,190.29,111.44,185,111.58,179.75Z"
        fill="#f58f7f"
      />
      <path
        d="M322,298.22l-.38,12.41Q321.77,304.42,322,298.22Z"
        fill="#fcd6d1"
      />
      <path d="M323.8,56.2l-.59,0Z" fill="#fcd7d2" />
      <path
        d="M111.64,178.14l0,.59a2.08,2.08,0,0,1-.11-.41s.1-.12.14-.18Z"
        fill="#f58f7f"
      />
      <path
        d="M321.44,295.93l.46-.34.06.43a1.58,1.58,0,0,1-.52-.09Z"
        fill="#fcd6d1"
      />
      <path d="M257.51,326.33l.6,0Z" fill="#f69486" />
    </svg>
  );
};

export default GustoLogoSmall;
