import '@babel/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import 'intersection-observer';
import 'whatwg-fetch';
import { ApolloProvider } from '@apollo/client';
import { Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import CustomScroll from 'react-custom-scroll';
import $ from 'jquery';

import * as Sentry from '@sentry/browser';

import 'react-custom-scroll/dist/customScroll.css';
import 'sweetalert2/dist/sweetalert2.css';
import 'react-table-6/react-table.css';
import 'react-month-picker/css/month-picker.css';
import 'react-notifications/lib/notifications.css';
import 'css/fonts.css';
import 'ladda/dist/ladda-themeless.min.css';
import 'fonts/simple-line-icons.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import '@mdi/font/css/materialdesignicons.min.css';
import 'react-toggle/style.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'utils/jexcel.formulas'; // loads formulas to global scope
import 'loaders.css/loaders.min.css';
import { enablePatches } from 'immer';
import errorCodes from 'utils/errorCodes';

import 'css/style.css';

import 'utils/chart-defaults';
import 'utils/scrollIntoView';

import client from './client';
import App from './routes';

import history from './routerHistory';
import { WhitelabelProvider } from 'context/WhitelabelProvider';

enablePatches();

window.$ = $;

if (window.location.search) {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('g') && urlParams.get('w')) {
    window.sessionStorage.setItem('companiesSelectedGroup', urlParams.get('g'));
    window.sessionStorage.setItem('currentWorkspaceId', urlParams.get('w'));
    if (urlParams.get('c')) {
      window.sessionStorage.setItem('currentCompanyId', urlParams.get('c'));
    }
  }

  if (urlParams.get('tenantId')) {
    window.sessionStorage.setItem('xeroLauncherId', urlParams.get('tenantId'));
  }

  if (urlParams.get('authEvent') && urlParams.get('authSource')) {
    window.localStorage.setItem('authEvent', urlParams.get('authEvent'));
    window.localStorage.setItem('authSource', urlParams.get('authSource'));
  }

  if (
    urlParams.get('via') &&
    (window.location.hostname === 'localhost' ||
      window.location.hostname === 'app-stg.reachreporting.com')
  ) {
    window.Rewardful = { referral: urlParams.get('via') };
  }

  if (urlParams.get('gtm_debug')) {
    window.gtm_debug = urlParams.get('gtm_debug');
  }
}

const sentryDsn = {
  'http://localhost:8080': null,
  /* 'http://localhost:8080':
    'https://6470455915b34fe2a5487f752f683ba5@sentry.io/1813586', */
  'https://app-stg.reachreporting.com':
    'https://f4918ecc165ba58e5016a28bca09c1c2@o4505603161849856.ingest.sentry.io/1308457',
  'https://app.reachreporting.com':
    'https://5d742ba04ffc5c8ae78d1bec49d14b54@o4505603161849856.ingest.sentry.io/1312562',
};

if (window.location.search) {
  const urlParams = new URLSearchParams(window.location.search);
  const error = urlParams.get('error');
  if (error && errorCodes[error]) {
    window.sessionStorage.setItem('error-code', error);
  }
}

CustomScroll.defaultProps.allowOuterScroll = true;

if (
  sentryDsn[window.location.origin] ||
  sentryDsn[window.location.origin] === undefined
) {
  Sentry.init({
    dsn:
      sentryDsn[window.location.origin] ||
      'https://5d742ba04ffc5c8ae78d1bec49d14b54@o4505603161849856.ingest.sentry.io/1312562',
    release: process.env.SOURCE_VERSION,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    // replaysSessionSampleRate: 0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    // replaysOnErrorSampleRate: 1.0,

    // integrations: [new Sentry.Replay()],
    beforeSend(event) {
      if (event && event.message && event.message.includes('Failed to fetch')) {
        return null;
      }
      if (event && event.additionalData && event.additionalData.graphQLErrors) {
        return null;
      }
      return event;
    },
  });
}

const MOUNT_NODE = document.getElementById('root');

const render = (Component) => {
  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(
    // <Sentry.Profiler>
    <ApolloProvider client={client}>
      <HelmetProvider>
        <WhitelabelProvider>
          <Router history={history}>
            <Component />
          </Router>
        </WhitelabelProvider>
      </HelmetProvider>
    </ApolloProvider>,
    // </Sentry.Profiler>,
    MOUNT_NODE
  );
};

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept(['./routes'], () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const UpdatedApp = require('./routes').default;
    render(UpdatedApp);
  });
}

render(App);
