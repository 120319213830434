const server = process.env.SERVER;

let apiUrl;
let wsApiUrl;
let link = false;

if (
  !server &&
  (window.location.pathname.indexOf('/shared/') === 0 ||
    window.location.pathname.indexOf('/embed/') === 0)
) {
  link = true;
}

if (server) {
  apiUrl = process.env.API_URL;
} else {
  const subdomain = window.location.hostname.substr(
    0,
    window.location.hostname.indexOf('.')
  );

  const origin = window.location.origin;
  if (window.location.hostname === 'frontendreach.ngrok.io') {
    apiUrl = 'https://backendreach.ngrok.io';
    wsApiUrl = 'wss://backendreach.ngrok.io';
  }else if (window.location.hostname === 'localhost') {
    apiUrl = 'http://localhost:8081';
    wsApiUrl = 'ws://localhost:8081';
  } else if (window.location.hostname === 'clientdashboards.nowcfo.com') {
    apiUrl = 'https://reachapi.nowcfo.com';
    wsApiUrl = 'wss://reachapi.nowcfo.com';
  } else if (window.location.hostname === 'dbtest.summitcpa.net') {
    apiUrl = 'https://api.summitcpa.net';
    wsApiUrl = 'wss://api.summitcpa.net';
  } else if (window.location.hostname === 'thedash.advancedcfo.com') {
    apiUrl = 'https://api.advancedcfo.com';
    wsApiUrl = 'wss://api.advancedcfo.com';
  } else if (window.location.hostname.indexOf('dashboard.') === 0) {
    apiUrl = origin.replace('dashboard', 'api');
    wsApiUrl = apiUrl.replace('https', 'wss');
  } else if (window.location.hostname.indexOf('reports.') === 0) {
    apiUrl = origin.replace('reports', 'api');
    wsApiUrl = apiUrl.replace('https', 'wss');
  } else if (subdomain.indexOf('app') > -1) {
    apiUrl = origin.replace('app', 'api');
    wsApiUrl = apiUrl.replace('https', 'wss');
  } else {
    apiUrl = origin.replace(subdomain, 'api');
    wsApiUrl = apiUrl.replace('https', 'wss');
  }
}

export const API_URL = apiUrl;
export const WS_API_URL = wsApiUrl;
export const LINK = link;
export const STAGING =
  window.location.hostname === 'app-stg.reachreporting.com';
export const DEV = 
  window.location.hostname === 'localhost';
