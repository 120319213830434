import React from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

const StyledLink = styled(RouterLink)`
  color: ${props => props.theme.colors.link};
  text-decoration: none;
  &:hover {
    color: #23527c;
    text-decoration: underline;
  }
`;

class LinkWithPreload extends React.PureComponent {
  handlePreload = () => {
    if (this.props.preload && this.props.preload.preload) {
      this.props.preload.preload();
    }
  };
  render() {
    const { preload, ...rest } = this.props;

    return <StyledLink {...rest} onMouseOver={this.handlePreload} />;
  }
}

export default LinkWithPreload;
