import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { WorkspaceInvite } from 'interfaces/workspace';

const queryPermissions = `restrictRemoveGroupAccess
canAccessWorkspaceSettings: hasPermission(action: CHANGE_WORKSPACE_NAME)`;

const getWorkspaceUserGenericQuery = (
  permissions?: string,
  group?: boolean,
  company?: boolean,
  getWorkspaceGroups?: boolean
) => `
    workspace(id: $workspaceId) {
      id
      users {
        id
        fullName
        email
        clientPortalOnly
      }
      workspaceInvites${
        group
          ? `(groupId: $groupId${company ? ' companyId: $companyId' : ''})`
          : company
          ? '(companyId: $companyId)'
          : ''
      } {
        id
        groupIds
        companyIds
        link
        user {
          id
          fullName
          email
          clientPortalOnly
        }
      }
      ${
        getWorkspaceGroups
          ? `groups {
          id
        }`
          : ''
      }
      ${permissions || ''}
      billingUser {
        id
      }
    }
`;

export const GROUP_WORKSPACE_USERS = gql`
  query GroupUserModalWorkspace($workspaceId: Int!, $groupId: Int!) {
    currentUser {
      userId
    }
    ${getWorkspaceUserGenericQuery(
      `restrictRemoveGroupAccess
      canAccessWorkspaceSettings: hasPermission(action: CHANGE_WORKSPACE_NAME)`,
      true
    )}
  }
`;

export const COMPANY_WORKSPACE_USERS = gql`
  query CompanyUserModalWorkspace($workspaceId: Int!, $groupId: Int!) {
      currentUser {
        userId
      }
      ${getWorkspaceUserGenericQuery(
        `restrictRemoveGroupAccess
        canAccessWorkspaceSettings: hasPermission(action: CHANGE_WORKSPACE_NAME)`,
        true,
        true
      )}
    }
`;

export const getWorkspaceUserQuery = (
  group?: boolean,
  company?: boolean,
  permissions?: boolean,
  getWorkspaceGroups?: boolean
): DocumentNode => {
  if (group && !company) {
    return gql`
      query WorkspaceUsers($workspaceId: Int!, $groupId: Int) {
        ${getWorkspaceUserGenericQuery(
          permissions ? queryPermissions : undefined,
          group,
          company,
          getWorkspaceGroups
        )}
      }
    `;
  } else if (group && company) {
    return gql`
      query WorkspaceUsers($workspaceId: Int!, $groupId: Int, $companyId: Int) {
        ${getWorkspaceUserGenericQuery(
          permissions ? queryPermissions : undefined,
          group,
          company,
          getWorkspaceGroups
        )}
      }
    `;
  } else if (!group && company) {
    return gql`
    query WorkspaceUsers($workspaceId: Int!, $companyId: Int) {
      ${getWorkspaceUserGenericQuery(
        permissions ? queryPermissions : undefined,
        group,
        company,
        getWorkspaceGroups
      )}
    }
  `;
  } else {
    return gql`
      query WorkspaceUsers($workspaceId: Int!) {
        ${getWorkspaceUserGenericQuery(
          permissions ? queryPermissions : undefined,
          group,
          company,
          getWorkspaceGroups
        )}
      }
    `;
  }
};

export type InviteWorkspaceUserResult = {
  inviteWorkspaceUser: {
    workspaceInvite: WorkspaceInvite;
    error: string;
  };
};

export const INVITE_USER = gql`
  mutation InviteWorkspaceUser(
    $workspaceId: Int!
    $email: Email!
    $firstName: String!
    $lastName: String!
    $workspaceRoleId: Int
    $groupId: Int
    $companyId: Int
  ) {
    inviteWorkspaceUser(
      workspaceId: $workspaceId
      email: $email
      firstName: $firstName
      lastName: $lastName
      workspaceRoleId: $workspaceRoleId
      groupId: $groupId
      companyId: $companyId
    ) {
      workspaceInvite {
        id
        groupIds
        companyIds
        link
        user {
          id
          fullName
          email
          clientPortalOnly
        }
      }
      error
    }
  }
`;

export const RESEND_WORKSPACE_INVITE = gql`
  mutation ResendWorkspaceInvite($workspaceId: Int!, $userId: Int!) {
    resendWorkspaceInvite(workspaceId: $workspaceId, userId: $userId) {
      success
      error
    }
  }
`;

export const REMOVE_USER = gql`
  mutation RemoveWorkspaceUser($workspaceId: Int!, $userId: Int!) {
    removeWorkspaceUser(workspaceId: $workspaceId, userId: $userId)
  }
`;

export const REMOVE_INVITE = gql`
  mutation RemoveInvite($inviteId: Int!) {
    removeWorkspaceInvite(inviteId: $inviteId)
  }
`;

export const LEAVE_WORKSPACE = gql`
  mutation LeaveWorkspace($workspaceId: Int!, $userId: Int!) {
    leaveWorkspace(workspaceId: $workspaceId, userId: $userId)
  }
`;

export const BULK_UPDATE_INVITES = gql`
  mutation BulkUpdateInvites(
    $workspaceId: Int!
    $inviteUpdates: [InviteUpdate!]!
  ) {
    bulkSetWorkspaceInviteAccess(
      workspaceId: $workspaceId
      inviteUpdates: $inviteUpdates
    ) {
      success
      workspaceInvites {
        id
        user {
          id
          fullName
          email
        }
        groupIds
        companyIds
      }
      error
    }
  }
`;

export const WORKSPACE_TO_CLIENT = gql`
  mutation toggleClientUser(
    $userId: Int!
    $workspaceId: Int!
    $companyId: Int!
    $folderIds: [Int!]!
    $clientPortalOnly: Boolean!
  ) {
    toggleClientUser(
      userId: $userId
      workspaceId: $workspaceId
      companyId: $companyId
      folderIds: $folderIds
      clientPortalOnly: $clientPortalOnly
    ) {
      user {
        id
        email
        fullName
        clientPortalOnly
      }
      error
    }
  }
`;

export const CLIENT_TO_WORKSPACE = gql`
  mutation toggleClientUser(
    $userId: Int!
    $workspaceId: Int!
    $clientPortalOnly: Boolean!
  ) {
    toggleClientUser(
      userId: $userId
      workspaceId: $workspaceId
      clientPortalOnly: $clientPortalOnly
    ) {
      user {
        id
        email
        fullName
        clientPortalOnly
      }
      error
    }
  }
`;

export const SET_WORKSPACE_OWNER = gql`
  mutation setWorkspaceOwner($workspaceId: Int!, $userId: Int!) {
    setWorkspaceOwner(workspaceId: $workspaceId, userId: $userId) {
      workspace {
        id
        owner {
          id
          email
          fullName
        }
      }
      error
    }
  }
`;

export const SET_WORKSPACE_SECONDARY_ADMIN = gql`
  mutation setWorkspaceSecondaryAdmin(
    $workspaceId: Int!
    $userId: Int!
    $revoke: Boolean!
  ) {
    setWorkspaceSecondaryAdmin(
      workspaceId: $workspaceId
      userId: $userId
      revoke: $revoke
    ) {
      workspace {
        id
        owner {
          id
          email
          fullName
        }
        secondaryAdmin {
          id
          email
          fullName
        }
      }
      error
    }
  }
`;
