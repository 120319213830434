/**
 *
 * Radio
 *
 */

import React from 'react';
import styled, { css } from 'styled-components';

import newId from 'utils/newId';

const Div = styled.label`
  margin-bottom: 6px;
  position: relative;
  display: inline-flex;
  align-items: center;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  margin-right: 1rem;
  ${({ truncate }) =>
    truncate &&
    css`
      overflow: hidden;
    `}}
  overflow: hidden;
`;

const Input = styled.input`
  display: none;
`;

const Label = styled.span`
  padding-top: 1px;
  ${({ truncate }) =>
    truncate &&
    css`
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    `}

  &:before {
    position: absolute;
    top: 0.25rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: '';
    left: 0;
    color: white;
    border-radius: 50%;
    pointer-events: none;
    user-select: none;
    background-color: ${(props) => (props.disabled ? '#e0ecff' : '#dee2e6')};
  }
  ${Input}:checked~&:before {
    background-color: ${(props) => props.theme.colors.info};
  }
  ${Input}:checked:disabled~&:before {
    background-color: #e0ecff;
  }
  &:after {
    position: absolute;
    top: 0.25rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: '';
    left: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }
  ${Input}:checked~&:after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
  }
`;

class Radio extends React.PureComponent {
  constructor(props) {
    super(props);
    this.id = newId();
    this.for = props.for;
  }
  render() {
    const { children, className, disabled, partial, truncate, ...rest } =
      this.props;

    return (
      <Div
        className={className}
        htmlFor={this.for || this.id}
        truncate={truncate}
      >
        <Input
          type="radio"
          id={this.for || this.id}
          disabled={disabled}
          {...rest}
        />
        <Label disabled={disabled} partial={partial} truncate={truncate}>
          {children}
        </Label>
      </Div>
    );
  }
}

export default Radio;
