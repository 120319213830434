import React, { useCallback } from 'react';
import Modal from 'components/Modal';
import useMedia from 'hooks/useMedia';
import { sizes } from 'media';
import { useHistory, useLocation } from 'react-router-dom';
import Button from 'components/Button';
import styled from 'styled-components';
import useWorkspaceId from 'hooks/useWorkspaceId';

const MOBILE_DISABLED_LOCATIONS = [
  'dashboards',
  'reports',
  'report',
  'table',
  'datasheet',
];

const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

interface Props {
  clientPortal?: boolean;
}

const MobileWarningModal = ({ clientPortal }: Props): React.ReactNode => {
  const phone = useMedia([`(min-width: ${sizes.phone}px)`], [false], true);
  const { pathname } = useLocation();
  const history = useHistory();
  const workspaceId = useWorkspaceId();

  const handleClick = useCallback(() => {
    if (clientPortal) {
      history.push(`/portal`);
    } else if (workspaceId) {
      history.push(`/workspaces/${workspaceId}`);
    }
  }, [clientPortal, history, workspaceId]);

  const showWarning = MOBILE_DISABLED_LOCATIONS.some(
    (location) =>
      pathname.slice(1, pathname.length).split('/').includes(location) && phone
  );

  const redirectLocation = clientPortal ? 'Portal' : 'Workspace Home';

  return (
    <Modal title="Head's Up!" show={showWarning}>
      <div>
        For the best experience creating and editing dashboards and reports, we
        recommend logging in on your computer.
      </div>
      <Footer>
        <Button color="primary" onClick={handleClick}>
          {`Go to ${redirectLocation}`}
        </Button>
      </Footer>
    </Modal>
  );
};

export default MobileWarningModal;
