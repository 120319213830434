/**
 *
 * ChangePassword
 *
 */
import { useMutation } from '@apollo/client';
import Button from 'components/Button';
import HookErrorMessage from 'components/HookErrorMessage';
import gql from 'graphql-tag';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import { captureException } from 'utils/sentry';
import { isPassword } from 'validation';
import { FieldGroup, Label, Input, Message } from './components';
import PasswordToggle from 'components/PasswordToggle';

const UPDATE_PASSWORD = gql`
  mutation UpdatePassword(
    $currentPassword: String!
    $newPassword: String!
    $confirmNewPassword: String!
  ) {
    updatePassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
      confirmNewPassword: $confirmNewPassword
    ) {
      success
      error
    }
  }
`;

const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm();

  const newPassword = watch('newPassword');
  const confirmNewPassword = watch('confirmNewPassword');

  const [message, setMessage] = useState(null);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const [updatePassword, { loading }] = useMutation(UPDATE_PASSWORD);

  const onSubmit = useCallback(
    async (values) => {
      try {
        setMessage(null);

        const { data } = await updatePassword({
          variables: values,
        });

        if (data && data.updatePassword && data.updatePassword.success) {
          reset();
          return setMessage('Password updated successfuly');
        } else if (data && data.updatePassword && data.updatePassword.error) {
          return setMessage(data.updatePassword.error);
        } else {
          setMessage('An error occurred');
        }
      } catch (err) {
        captureException(err, true);
      }
    },
    [reset, updatePassword]
  );

  return (
    <div>
      <p>Change Password</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldGroup>
          <Label>Current Password</Label>
          <PasswordToggle
            show={showCurrentPassword}
            onClick={() => setShowCurrentPassword(!showCurrentPassword)}
            size={1.25}
          >
            <Input
              {...register('currentPassword', {
                required: 'Required',
              })}
              type={showCurrentPassword ? 'text' : 'password'}
              name="currentPassword"
              autoComplete="off"
            />
          </PasswordToggle>
          <HookErrorMessage errors={errors} field="currentPassword" />
        </FieldGroup>
        <FieldGroup>
          <Label>New Password</Label>
          <PasswordToggle
            show={showNewPassword}
            onClick={() => setShowNewPassword(!showNewPassword)}
            size={1.25}
          >
            <Input
              type={showNewPassword ? 'text' : 'password'}
              name="newPassword"
              {...register('newPassword', {
                required: 'Required',
                validate: (value) => isPassword(value) === undefined,
              })}
              autoComplete="off"
            />
          </PasswordToggle>
          <HookErrorMessage
            errors={errors}
            field="newPassword"
            message="Password must have 8 - 128 characters, 1 letter, and 1 number"
          />
        </FieldGroup>
        <FieldGroup>
          <Label>Confirm New Password</Label>
          <PasswordToggle
            show={showConfirmNewPassword}
            onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
            size={1.25}
          >
            <Input
              type={showConfirmNewPassword ? 'text' : 'password'}
              name="confirmNewPassword"
              {...register('confirmNewPassword', {
                required: 'Required',
                validate: (value) => value === newPassword,
              })}
              autoComplete="off"
            />
          </PasswordToggle>
          <HookErrorMessage
            errors={errors}
            field="confirmNewPassword"
            message="Password must match"
          />
        </FieldGroup>
        {!!message && <Message>{message}</Message>}
        <Button color="success" disabled={loading} loading={loading}>
          Save
        </Button>
      </form>
    </div>
  );
};

export default ChangePassword;
