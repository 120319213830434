import { Text } from 'informed';
import styled from 'styled-components';

const TextField = styled(Text).attrs((props) => ({
  id: props.id,
  autoComplete: props.autoComplete || 'off',
  'data-1p-ignore': props['data-1p-ignore'] || 'true',
}))`
  display: block;
  width: 100%;
  font: inherit;
  font-family: inherit;
  margin: 0;

  &,
  &[type='text'],
  &[type='email'],
  &[type='password'],
  &[type='tel'] {
    background: ${(props) => props.theme.formControl.background};
    border-radius: ${(props) => props.theme.formControl.borderRadius};
    border: ${({ error, theme }) =>
      error ? `1px solid ${theme.colors.danger}` : theme.formControl.border};
    box-shadow: ${(props) => props.theme.formControl.boxShadow};
    font-size: ${(props) =>
      props.theme.formControl.fontSizeScaled(props.scale || 1)};
    padding: ${(props) =>
      props.theme.formControl.paddingScaled(props.scale || 1)};
    transition: ${(props) => props.theme.formControl.transition};
    line-height: ${(props) => props.theme.formControl.lineHeight};
    color: ${(props) => props.theme.formControl.color};
    &::-webkit-input-placeholder {
      color: ${(props) => props.theme.formControl.placeholder};
    }
    &:focus {
      border: ${({ error, theme }) =>
        error
          ? `1px solid ${theme.colors.danger}`
          : theme.formControl.focus.border};
      background: ${(props) => props.theme.formControl.focus.background};
    }
  }
  &:disabled {
    background-color: #dddddd;
  }
`;

export default TextField;
