/**
 *
 * RightSidebar
 *
 */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import theme from 'styled-theming';

import Main from './Main';
import { rightSidebarZIndex } from 'utils/zIndex';
import HideHubspot from 'components/HideHubspot';
import { gql, useMutation, useQuery } from '@apollo/client';
import CompanySettingsModal from 'components/CompanySettingsModal';
import UserSettingsModal from 'components/UserSettingsModal';
import UpdatesModal from 'components/UpdatesModal';
import useWorkspaceId from 'hooks/useWorkspaceId';

const QUERY = gql`
  query Main {
    currentUser {
      email
      fullName
      hasPaid
      billingProvider
      isSummitUser
      showUpdates
      demoCompany {
        id
      }
      companies {
        id
      }
      stripeSubscription {
        id
        hasPaymentMethod
      }
    }
  }
`;

const UPDATES_SEEN = gql`
  mutation UpdatesSeen {
    setUpdatesSeen {
      showUpdates
    }
  }
`;

const bgColor = theme('mode', {
  light: 'white',
  dark: '#222532',
});

const width = 556;

const Div = styled.div<{ open: boolean }>`
  width: ${width}px;
  max-width: calc(100% - ${(props) => props.theme.sidebar.width});
  position: fixed;
  right: ${(props) => (props.open ? 0 : -width)}px;
  top: ${(props) => props.theme.header.height};
  background: ${bgColor};
  bottom: 0;
  transition: right 0.15s, width 0.15s linear;
  padding: 30px 25px;
  box-shadow: 0px 0 3px 0px rgba(0, 0, 0, 0.2);
  z-index: ${rightSidebarZIndex};
`;

interface Props {
  trigger: React.RefObject<HTMLButtonElement>;
  open: boolean;
  onClose: () => void;
}

const RightSidebar = ({
  open,
  trigger,
  onClose,
}: Props): React.ReactElement => {
  const divRef = useRef<HTMLDivElement>(null);
  const workspaceId = useWorkspaceId();
  const [showCompanySettings, setShowCompanySettings] = useState(false);
  const [showUserSettings, setShowUserSettings] = useState(false);
  const [showUpdates, setShowUpdates] = useState(false);

  const { data } = useQuery(QUERY);
  const [setSeen] = useMutation(UPDATES_SEEN);

  const toggleModal = useCallback(
    (type: 'user' | 'company' | 'updates', state: boolean) => {
      if (type === 'user') {
        setShowUserSettings(state);
      } else if (type === 'company') {
        setShowCompanySettings(state);
      } else if (type === 'updates') {
        setShowUpdates(state);
      }
    },
    []
  );

  const onCloseUpdates = useCallback(() => {
    toggleModal('updates', false);
    setSeen({
      update: (cache) => {
        cache.writeQuery({
          query: QUERY,
          data: {
            currentUser: {
              ...data?.currentUser,
              showUpdates: false,
            },
          },
        });
      },
    });
  }, [data?.currentUser, setSeen, toggleModal]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        divRef.current &&
        !divRef.current.contains(event.target) &&
        trigger?.current &&
        !trigger.current.contains(event.target)
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [divRef, onClose, trigger]);

  useEffect(() => {
    if (workspaceId && data?.currentUser?.showUpdates) {
      setShowUpdates(true);
    }
  }, [data, data?.currentUser?.showUpdates, setSeen, workspaceId]);

  return (
    <Div open={open} ref={divRef}>
      {open && <HideHubspot />}
      {open && (
        <Main
          onClose={onClose}
          toggleModal={toggleModal}
          hasPaid={data?.currentUser?.hasPaid}
          billingProvider={data?.currentUser?.billingProvider}
          email={data?.currentUser?.email}
          stripeSubscription={data?.currentUser?.stripeSubscription}
          isSummitUser={data?.currentUser?.isSummitUser}
        />
      )}
      <CompanySettingsModal
        groups={true}
        companyId={
          data &&
          data.currentUser &&
          data.currentUser.companies &&
          data.currentUser.companies.id
        }
        show={showCompanySettings}
        onClose={() => toggleModal('company', false)}
      />
      <UserSettingsModal
        show={showUserSettings}
        onClose={() => toggleModal('user', false)}
      />
      <UpdatesModal show={showUpdates} onClose={onCloseUpdates} />
    </Div>
  );
};

export default RightSidebar;
