/* global fetch */

import {
  ApolloClient,
  InMemoryCache,
  defaultDataIdFromObject,
  split,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloLink } from 'apollo-link';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { setContext } from '@apollo/client/link/context';
import DebounceLink from 'apollo-link-debounce';
import { sha256 } from 'crypto-hash';

import possibleTypes from './possibleTypes.json';
import LogRocket from 'logrocket';
// import setupLogRocketReact from 'logrocket-react';

import { merge } from 'lodash';

import logRocketId from 'utils/logRocketId';

import { API_URL, WS_API_URL } from 'environment';

// import CompaniesLocalState from 'routes/Companies/localState';
// import MainLayoutLocalState from 'layouts/Main/localState';
import MainLocalVariables from 'layouts/Main/variables';
import GlobalVariables, {
  setSelectedGroup,
  showLoginModal,
} from 'graphql/variables';
import MinimalLayoutLocalState from 'layouts/Minimal/localState';
import NotAuthenticatedLocalState from 'components/NotAuthenticatedRoute/localState';

import {
  checkAuthenticated,
  login,
  register,
  logout,
  setupPassword,
  forgot,
  resetPassword,
  signup,
} from 'auth';
import gql from 'graphql-tag';
import { showError, showWarning } from 'utils/popups';
import { getUserIdentifier, pushEvent } from 'utils/ga';

if (logRocketId) {
  LogRocket.init(logRocketId, {
    network: {
      isEnabled: false,
    },
  });
  // setupLogRocketReact(LogRocket);
}

const combinedVariables = merge(MainLocalVariables, GlobalVariables);

const variablePolicies = {};

Object.keys(combinedVariables).forEach((key) => {
  variablePolicies[key] = {
    read() {
      return combinedVariables[key]();
    },
  };
});

export const cache = new InMemoryCache({
  possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        table: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'Table',
              id: args.id,
            });
          },
        },
        currentUser: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
        getUsersFolders: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        CompanyFolders: {
          merge(existing, incoming) {
            return existing.filter((f) => incoming.includes(f));
          },
        },
        ...variablePolicies,
      },
    },
    Accounting: {
      fields: {
        bankAccounts: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        bankAccountMappings: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    Report: {
      fields: {
        pages: {
          keyArgs: false,
          merge(existing = [], incoming, { args }) {
            if (!args) return incoming;
            const { offset } = args;
            if (typeof offset !== 'number') return incoming;

            const merged = existing ? existing.slice(0) : [];
            for (let i = 0; i < incoming.length; ++i) {
              merged[offset + i] = incoming[i];
            }
            return merged;
          },
        },
        lastModified: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    CurrentUser: {
      fields: {
        stripeInvoicesToPush: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    Company: {
      fields: {
        reports: {
          merge: false,
        },
        accounting: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
        datasheets: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        libraryDashboards: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        dashboards: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        libraryReports: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        consolidationCompanies: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        libraryTablesPaged: {
          keyArgs: false,
          merge(existing = [], incoming, { args: { offset = 0 } }) {
            const merged = existing ? existing.slice(0) : [];
            for (let i = 0; i < incoming.length; ++i) {
              merged[offset + i] = incoming[i];
            }
            return merged;
          },
        },
        libraryTablesNoStatementsPaged: {
          keyArgs: false,
          merge(existing = [], incoming, { args: { offset = 0 } }) {
            const merged = existing ? existing.slice(0) : [];
            for (let i = 0; i < incoming.length; ++i) {
              merged[offset + i] = incoming[i];
            }
            return merged;
          },
        },
        customLiveText: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        favoriteReports: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        favoriteDashboards: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        users: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        table: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'Table',
              id: args.id,
            });
          },
        },
        budgets: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        budgetGroups: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    Table: {
      fields: {
        layout: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        rows: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        columns: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        statusHistory: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    User: {
      merge: true,
    },
    Firm: {
      fields: {
        managers: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        companies: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    Dashboard: {
      fields: {
        tables: {
          merge: false,
        },
        queryTables: {
          merge: false,
        },
      },
    },
    Workspace: {
      fields: {
        groups: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    FolderContent: {
      fields: {
        users: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        dashboards: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        reports: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        files: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
  },
  cacheRedirects: {
    Query: {
      company: (_, { id }, { getCacheKey }) =>
        getCacheKey({ __typename: 'Company', id }),
      firm: (_, { id }, { getCacheKey }) =>
        getCacheKey({ __typename: 'Firm', id }),
      workspace: (_, { id }, { getCacheKey }) =>
        getCacheKey({ __typename: 'Workspace', id }),
      clientPortal: (_, { companyId }, { getCacheKey }) =>
        getCacheKey({ __typename: 'ClientPortal', id: companyId }),
      /* dashboard: (_, { id, accountingBasis }, { getCacheKey }) =>
        getCacheKey({ __typename: 'Dashboard', id, accountingBasis }), */
      /* dashboard: (_, args, { getCacheKey }) => {
        return getCacheKey({
          __typename: 'Dashboard',
          id: args.id,
          accountingBasis: args.accountingBasis,
        });
      }, */
    },
    Company: {
      /* dashboard: (_, { id, accountingBasis }, { getCacheKey }) =>
        getCacheKey({ __typename: 'Dashboard', id, accountingBasis }), */
      /* report: (_, { id }, { getCacheKey }) =>
        getCacheKey({ __typename: 'Report', id }), */
      /* table: (_, { id }, { getCacheKey }) =>
        getCacheKey({ __typename: 'Table', id }), */
    },
  },
  dataIdFromObject: (object) => {
    if (
      object.__typename &&
      (object.__typename.indexOf('TableColumn') > -1 ||
        object.__typename.indexOf('TableRow') > -1 ||
        object.__typename.indexOf('SpreadsheetCell') > -1 ||
        object.__typename.indexOf('IntegrationFilterItem') > -1 ||
        object.__typename.indexOf('QueryColumn') > -1)
    ) {
      return null;
    }

    switch (object.__typename) {
      case 'Company':
        if (object.parentConsolidationId) {
          return `Company:${object.id}:${object.parentConsolidationId}`;
        }
        return defaultDataIdFromObject(object);
      /* case 'Dashboard':
        if (object.accountingBasis) {
          return `Dashboard:${object.id}:${object.accountingBasis}`;
        }
        return defaultDataIdFromObject(object); */
      case 'Table':
        /* if (object.accountingBasis) {
          return `Table:${object.id}:${object.accountingBasis}`;
        } */
        return defaultDataIdFromObject(object);
      case 'KpiSpreadsheetRow':
      case 'IntegrationAccount':
        return null;
      case 'Report':
        if (object.companyId) {
          return `Report:${object.id}:${object.companyId}`;
        }
        return defaultDataIdFromObject(object);
      case 'CustomReportPage':
        if (object.companyId) {
          return `CustomReportPage:${object.id}:${object.companyId}`;
        }
        return defaultDataIdFromObject(object);
      case 'ItemFoldersResult':
        if (object.itemId && object.itemType) {
          return `ItemFoldersResult:${object.itemId}:${object.itemType}`;
        }
        break;
      default:
        return defaultDataIdFromObject(object);
    }
  },
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: WS_API_URL + '/graphql',
    keepAlive: 10000,
    lazy: true,
    retryAttempts: 20,
  })
);

const staging = window.location.hostname === 'app-stg.reachreporting.com';
const dev = window.location.hostname === 'localhost';

const uploadLink = createUploadLink({
  uri: API_URL + '/graphql',
  credentials: 'include',
  fetch,
  headers: { 'Apollo-Require-Preflight': 'true' },
});

const baseHttpLink =
  // eslint-disable-next-line no-constant-condition
  staging || dev || true
    ? uploadLink
    : createPersistedQueryLink({ sha256 }).concat(uploadLink);

/* const baseHttpLink = uploadLink; */

const webLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  baseHttpLink
);

const LOGIN = gql`
  query Login {
    isLoggedIn
  }
`;

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (
    networkError &&
    networkError.message &&
    networkError.message.includes('Must be logged in')
  ) {
    try {
      const data = cache.readQuery({
        query: LOGIN,
      });

      if (data.isLoggedIn) {
        showLoginModal(true);
      }
    } catch {}
    return;
  }

  if (graphQLErrors) {
    const unauthenticatedError = graphQLErrors.find(
      (e) => e.extensions && e.extensions.code === 'UNAUTHENTICATED'
    );
    if (unauthenticatedError) {
      try {
        const data = cache.readQuery({
          query: LOGIN,
        });

        if (data.isLoggedIn) {
          showLoginModal(true);
        }
      } catch {}
      return;
    }
    const rateLimitError = graphQLErrors.find(
      (e) => e.extensions && e.extensions.code === 'RATE_LIMIT'
    );
    console.log(rateLimitError);
    if (rateLimitError) {
      return showWarning({
        title: 'Oops...',
        icon: 'warning',
        text: 'You are doing that too often.',
      });
    }

    const workspaceError = graphQLErrors.find(
      (e) =>
        e.extensions &&
        e.extensions.code === 'FORBIDDEN' &&
        e.path &&
        e.path.length === 1 &&
        e.path[0] === 'workspace'
    );
    const companyError = graphQLErrors.find(
      (e) =>
        e.extensions &&
        e.extensions.code === 'FORBIDDEN' &&
        e.path &&
        e.path.length === 1 &&
        e.path[0] === 'company'
    );
    if (workspaceError || companyError) {
      window.sessionStorage.removeItem('currentWorkspaceId');
      window.sessionStorage.removeItem('companiesSelectedGroup');
      window.sessionStorage.removeItem('currentCompanyId');
      window.location.pathname = '/';
      return;
    }
  }
  console.error(graphQLErrors, networkError);

  const silentErrors = [
    'NetworkError when attempting to fetch resource.',
    'Failed to fetch',
    'undefined',
    'Socket closed with event 4500 Internal server error',
  ];

  if (networkError && silentErrors.includes(networkError.message)) return;
  if (
    graphQLErrors &&
    graphQLErrors.some(
      (err) =>
        err.message &&
        networkError &&
        err.message.includes(networkError.message)
    )
  )
    return;

  // eslint-disable-next-line no-new
  showError();
});

const authLink = setContext((_, { headers }) => {
  const pathname = window.location.pathname;
  if (pathname.indexOf('/shared/') === 0) {
    return {
      headers: {
        ...headers,
        link: pathname.replace('/shared/', ''),
      },
    };
  }
  if (pathname.indexOf('/embed/') === 0) {
    return {
      headers: {
        ...headers,
        link: pathname.replace('/embed/', ''),
      },
    };
  }
  return {
    headers,
  };
});

const debounceLink = new DebounceLink(100);

//const link = errorLink.concat(webLink);
const link = errorLink.concat(
  ApolloLink.from([authLink, debounceLink, webLink])
);

const mergedLocalState = merge(
  {
    resolvers: {
      Query: {
        isLoggedIn: (_, args, { cache }) => {
          return checkAuthenticated();
        },
        currentCompanyId: () =>
          parseInt(
            sessionStorage && sessionStorage.getItem('currentCompanyId'),
            10
          ) || 0,
        currentWorkspaceId: () =>
          parseInt(
            sessionStorage && sessionStorage.getItem('currentWorkspaceId'),
            10
          ) || 0,
        companiesSelectedGroup: () =>
          parseInt(
            (!!window.sessionStorage &&
              window.sessionStorage.getItem('companiesSelectedGroup')) ||
              '-1',
            10
          ),
      },
      Mutation: {
        setTheme: (_, { theme }, { cache }) => {
          const data = {
            data: {
              theme,
            },
          };
          cache.writeQuery({
            query: gql`
              query SetTheme {
                theme
              }
            `,
            data,
          });
          return null;
        },
        register: (_, values, { cache }) =>
          register(values).then((result) => ({
            ...result,
            __typename: 'RegistrationResult',
          })),
        signup: (_, values, { cache }) =>
          signup(values).then((result) => ({
            ...result,
            __typename: 'SignupResult',
          })),
        login: (_, { email, password, twoFactorCode, popup }, { cache }) =>
          login(email, password, twoFactorCode).then((result) => {
            const data = {
              isLoggedIn: result.success,
              needsTwoFactorAuth: result.needsTwoFactorAuth || false,
              phoneLast4: result.phoneLast4 || null,
              error: result.error || null,
            };

            if (result.success) {
              pushEvent('login', {
                loginMethod: 'email',
                userId: getUserIdentifier(result),
              });
            }

            if (result.email && !result.clientPortalOnly) {
              const _hsq = (window._hsq = window._hsq || []);
              _hsq.push([
                'identify',
                {
                  email: result.email,
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  last_logged_in: new Date(),
                },
              ]);
              _hsq.push(['trackPageView']);
            }

            if (popup) {
              window.opener.postMessage({ success: true }, '*');
              window.close();
            }

            cache.writeQuery({
              query: LOGIN,
              data,
            });
            return {
              ...result,
              __typename: 'LoginResult',
            };
          }),
        forgot: (_, { email }, { cache }) =>
          forgot(email).then((result) => {
            const data = {
              isLoggedIn: false,
              needsTwoFactorAuth: false,
              phoneLast4: null,
              error: null,
            };

            cache.writeQuery({
              query: LOGIN,
              data,
            });
            return {
              ...result,
              __typename: 'ForgotResult',
            };
          }),
        resetPassword: (_, { code, password }) =>
          resetPassword(code, password).then((result) => {
            return { ...result, __typename: 'ResetPasswordResult' };
          }),
        setupPassword: (
          _,
          { code, firstName, lastName, password, phone, accept },
          { cache }
        ) =>
          setupPassword(
            code,
            firstName,
            lastName,
            password,
            phone,
            accept
          ).then((result) => {
            const data = {
              isLoggedIn: result.success,
              needsTwoFactorAuth: false,
              phoneLast4: null,
              error: null,
            };

            if (result.success) {
              pushEvent('register', {
                method: 'invite',
                userId: getUserIdentifier(result),
              });
            }

            cache.writeQuery({
              query: LOGIN,
              data,
            });
            return {
              ...result,
              __typename: 'SetupPasswordResult',
            };
          }),
        logout: (_, args, { cache }) =>
          logout().then(() =>
            cache.writeQuery({
              query: LOGIN,
              data: {
                isLoggedIn: false,
                needsTwoFactorAuth: false,
                phoneLast4: null,
                error: null,
              },
            })
          ),
        setCompany: (_, { companyId }, { cache }) => {
          if (!companyId) {
            !!window.sessionStorage &&
              window.sessionStorage.removeItem('currentCompanyId');
          } else {
            !!window.sessionStorage &&
              window.sessionStorage.setItem('currentCompanyId', companyId);
          }
          const data = {
            query: gql`
              query CurrentCompanyId {
                currentCompanyId
              }
            `,
            data: {
              currentCompanyId: companyId,
            },
          };
          cache.writeQuery(data);
          return null;
        },
        setWorkspaceGroupCompany: (
          _,
          { workspaceId, companyId, groupId },
          { cache }
        ) => {
          window.sessionStorage.setItem('currentWorkspaceId', workspaceId);
          window.sessionStorage.setItem('currentCompanyId', companyId);

          const data = {
            query: gql`
              query CurrentWorkspaceCompanyId {
                currentWorkspaceId
                currentCompanyId
              }
            `,
            data: {
              currentWorkspaceId: workspaceId,
              currentCompanyId: companyId,
            },
          };
          cache.writeQuery(data);
          setSelectedGroup(groupId);
          return null;
        },
        setWorkspace: (_, { workspaceId }, { cache }) => {
          if (!workspaceId) {
            !!window.sessionStorage &&
              window.sessionStorage.removeItem('currentWorkspaceId');
          } else {
            !!window.sessionStorage &&
              window.sessionStorage.setItem('currentWorkspaceId', workspaceId);
          }
          const data = {
            query: gql`
              query CurrentWorkspaceId {
                currentWorkspaceId
              }
            `,
            data: {
              currentWorkspaceId: workspaceId,
            },
          };
          cache.writeQuery(data);
          return null;
        },
        setCompaniesSelectedGroup: (_, { group }, { cache }) => {
          !!window.sessionStorage &&
            window.sessionStorage.setItem('companiesSelectedGroup', group);
          const data = {
            query: gql`
              query CurrentGroupId {
                companiesSelectedGroup
              }
            `,
            data: {
              companiesSelectedGroup: group,
            },
          };
          cache.writeQuery(data);
          return null;
        },
      },
    },
  },
  // CompaniesLocalState,
  // MainLayoutLocalState,
  MinimalLayoutLocalState,
  NotAuthenticatedLocalState
);

const client = new ApolloClient({
  link,
  cache,
  ...mergedLocalState,
  defaultOptions: {
    partialRefetch: true,
  },
});

cache.writeQuery({
  query: gql`
    query DefaultLocalState {
      notAuthenticatedTheme
      showCreateFirmModal
      showCollaboratorsModal
      collaboratorsModalCompanyId
      showSetFirmNameModal
      setFirmNameModalFirmId
      showFirmManagersModal
      firmManagersModalFirmId
      showTransferCompanyModal
      transferCompanyId
    }
  `,
  data: {
    notAuthenticatedTheme: mergedLocalState.defaults.notAuthenticatedTheme,
    ...mergedLocalState.defaults,
  },
});

window.apolloClient = client;

export default client;
