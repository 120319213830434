/**
 *
 * AccountingBasis
 *
 */
import React, { useCallback } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';

import { CustomSelectClass } from '../GroupCompanyList/styledComponents';
import { useMutation } from '@apollo/client';

const SET_ACCOUNTING_BASIS = gql`
  mutation ChangeAccountingBasis(
    $companyId: Int!
    $accountingBasis: AccountingBasis!
  ) {
    changeAccountingBasis(
      companyId: $companyId
      accountingBasis: $accountingBasis
    ) {
      id
      accounting {
        accountingBasis
      }
    }
  }
`;

const options = [
  {
    value: 'CASH',
    label: 'Cash',
  },
  {
    value: 'ACCRUAL',
    label: 'Accrual',
  },
];

interface AccountingBasisProps {
  companyId: number;
  accountingBasis: string;
}

const AccountingBasis: React.FunctionComponent<AccountingBasisProps> = props => {
  const { companyId, accountingBasis } = props;

  const [changeBasis] = useMutation(SET_ACCOUNTING_BASIS);

  const handleChange = useCallback(
    e => {
      changeBasis({
        variables: {
          companyId,
          accountingBasis: e.target.value,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          changeAccountingBasis: {
            __typename: 'Company',
            id: companyId,
            accounting: {
              __typename: 'Accounting',
              accountingBasis: e.target.value,
            },
          },
        },
      });
    },
    [changeBasis, companyId]
  );

  return (
    <div>
      <CustomSelectClass
        onChange={handleChange}
        value={accountingBasis}
        onClick={e => e.stopPropagation()}
        className="accounting-basis"
      >
        {options.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </CustomSelectClass>
    </div>
  );
};

export default AccountingBasis;
