/**
 *
 * FiscalYearMonth
 *
 */
import React, { useCallback } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import moment from 'moment';

import Select from 'components/Select';

const Div = styled.div`
  width: 7rem;
`;

/* const Select = styled.select`
  padding: 0px 12px;
  font-size: 0.75rem;

`; */

const options = [
  { value: 0, label: 'January' },
  { value: 1, label: 'February' },
  { value: 2, label: 'March' },
  { value: 3, label: 'April' },
  { value: 4, label: 'May' },
  { value: 5, label: 'June' },
  { value: 6, label: 'July' },
  { value: 7, label: 'August' },
  { value: 8, label: 'September' },
  { value: 9, label: 'October' },
  { value: 10, label: 'November' },
  { value: 11, label: 'December' },
];

const styles = {
  container: (base) => ({
    ...base,
    fontSize: '0.75rem',
  }),
  control: (base) => ({
    ...base,
    minHeight: '22px',
  }),
};

const UPDATE = gql`
  mutation SetFiscalYearMonth($companyId: Int!, $fiscalYearMonth: Int!) {
    setAccountingFiscalYearMonth(
      companyId: $companyId
      fiscalYearMonth: $fiscalYearMonth
    ) {
      id
      fiscalYearMonth
    }
  }
`;

interface FiscalYearMonthProps {
  value: number;
  companyId: number;
  canModify: boolean;
}

const FiscalYearMonth: React.FunctionComponent<FiscalYearMonthProps> = (
  props
) => {
  const { value, companyId, canModify } = props;
  const [onChange] = useMutation(UPDATE);

  const handleChange = useCallback(
    (newValue) => {
      onChange({
        variables: {
          companyId,
          fiscalYearMonth: newValue,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          setAccountingFiscalYearMonth: {
            __typename: 'Company',
            id: companyId,
            fiscalYearMonth: newValue,
          },
        },
      });
    },
    [companyId, onChange]
  );

  if (!canModify) {
    return moment.utc([2020, value]).format('MMMM');
  }

  return (
    <Div onClick={(e) => e.stopPropagation()}>
      <Select
        value={value}
        onChange={handleChange}
        options={options}
        compact
        styles={styles}
      ></Select>
    </Div>
  );
};

export default FiscalYearMonth;
