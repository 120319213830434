import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { Query } from '@apollo/client/react/components';

import NotFound from 'routes/NotFound/Loadable';

import LoadingIndicator from 'components/LoadingIndicator';
import Mutation from 'components/Mutation';

const QUERY = gql`
  query CompanyRoute {
    isLoggedIn @client
    currentCompanyId @client
  }
`;

const DEMO_QUERY = gql`
  query CompanyRouteDemo {
    currentUser {
      hasCompany
      needsOnboarding
      demoCompany {
        id
      }
    }
  }
`;

const PORTAL_QUERY = gql`
  query PortalQuery {
    currentUser {
      clientPortalOnly
    }
  }
`;

const OPEN_DEMO_COMPANY = gql`
  mutation OpenDemoCompany($companyId: Int!) {
    setCompany(companyId: $companyId) @client
  }
`;

class CompanyRoute extends React.PureComponent {
  renderRoute = (props) => {
    const Component = this.props.component;
    const Layout = this.props.layout;
    return (
      <Mutation mutation={OPEN_DEMO_COMPANY}>
        {(openDemo) => (
          <Query query={QUERY}>
            {({ data, error, loading }) => {
              if (loading) {
                return null;
              }
              if (error) {
                return <Redirect to="/error" />;
              }
              if (!data.isLoggedIn) {
                return <Redirect to="/login" />;
              }

              if (!data.currentCompanyId) {
                return (
                  <Query query={DEMO_QUERY}>
                    {({ data, loading, error }) => {
                      if (loading || error) {
                        return <LoadingIndicator />;
                      }

                      if (data.currentUser.needsOnboarding) {
                        return <Redirect to="/onboarding" />;
                      }

                      if (
                        data.currentUser.hasCompany ||
                        !data.currentUser.demoCompany
                      ) {
                        return <Redirect to="/workspaces" />;
                      } else {
                        openDemo();
                        return <LoadingIndicator />;
                      }
                    }}
                  </Query>
                );
              }
              if (Layout) {
                return (
                  <Query query={PORTAL_QUERY}>
                    {({ data, loading, error }) => {
                      if (loading || error) {
                        return <LoadingIndicator />;
                      }

                      if (data.currentUser.clientPortalOnly) {
                        return <Redirect to="/portal" />;
                      }

                      return (
                        <Layout {...this.props.layoutProps}>
                          <Component
                            {...props}
                            {...this.props.componentProps}
                          />
                        </Layout>
                      );
                    }}
                  </Query>
                );
              }
              return <Component {...props} />;
            }}
          </Query>
        )}
      </Mutation>
    );
  };
  render() {
    const { component, loc, layout, exact, location, ...rest } = this.props;

    if (loc) {
      return loc.toRoute(
        {
          render: this.renderRoute,
          invalid: NotFound,
        },
        !!exact
      );
    }

    return <Route render={this.renderRoute} exact={exact} {...rest} />;
  }
}

export default CompanyRoute;
