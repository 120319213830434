/* global fetch Headers */
import { API_URL } from 'environment';

export function sendDebugData(title, attachment) {
  return fetch(`${API_URL}/debug/slack`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({
      title,
      attachment,
    }),
    headers: new Headers({
      'content-type': 'application/json; charset=UTF-8',
    }),
  });
}
