import React, { useCallback, useMemo } from 'react';
import { TableInner } from './table';
import { Budget } from 'interfaces/accounting';
import { Column } from 'react-table';
import { TableStyle as BaseTableStyle } from './table/styledComponents';
import moment from 'moment';
import { Bottom, CreateButton } from './styledComponents';
import styled from 'styled-components';
import { Company } from 'interfaces/company';

const TableStyle = styled(BaseTableStyle)<{ hasFooter: boolean }>``;

interface Props {
  loading: boolean;
  rows: Budget[];
  columns: Column[];
  group: Budget & { budgetsInGroup: Budget[] };
  onCreateGroup: (name: string, budgetIds: number[]) => void;
  onUpdateGroup: (
    budgetGroupId: number,
    name: string,
    budgetIds: number[]
  ) => void;
  defaults: {
    defaultBudgetId?: Company['defaultBudgetId'];
    defaultBudgetType?: Company['defaultBudgetType'];
    defaultForecastId?: Company['defaultForecastId'];
    defaultForecastType?: Company['defaultForecastType'];
  };
}

const Group = ({
  loading,
  rows: _rows,
  columns,
  group,
  onCreateGroup,
  onUpdateGroup,
  defaults,
}: Props): React.ReactElement => {
  const [selected, setSelected] = React.useState<Budget[]>(
    group ? group.budgetsInGroup : []
  );
  const [name, setName] = React.useState<string>(group ? group.name : '');
  const onSelectRow = useCallback(
    (row: Budget) => {
      if (selected.map((s) => s.id).includes(row.id)) {
        setSelected(selected.filter((r) => r.id !== row.id));
      } else {
        setSelected([...selected, row]);
      }
    },
    [selected]
  );

  const rows = useMemo(() => {
    return _rows.map((row) => {
      const modified: Budget & {
        canSelect?: boolean;
        disabledMessage?: string;
        toggleSelectedRow: () => void;
        isSelected?: boolean;
      } = {
        ...row,
        toggleSelectedRow: () => onSelectRow(row),
      };

      if (!selected.length) {
        modified.canSelect = true;
        return modified;
      }
      const selectedIds = selected.map((s) => s.id);
      modified.isSelected = selectedIds.includes(row.id);
      const { earliestDate, latestDate } = selected.reduce(
        (acc, curr) => {
          const startDate = moment.utc(curr.datasheet.startDate);
          const endDate = moment.utc(curr.datasheet.endDate);
          const _earliestDate = moment.utc(acc.earliestDate);
          const _latestDate = moment.utc(acc.latestDate);
          if (startDate.isBefore(_earliestDate)) {
            acc.earliestDate = startDate;
          }
          if (endDate.isAfter(_latestDate)) {
            acc.latestDate = endDate;
          }
          return acc;
        },
        {
          earliestDate: moment.utc(selected[0].datasheet.startDate),
          latestDate: moment.utc(selected[0].datasheet.endDate),
        }
      );

      const { sequential, noOverlap, isBetween } = selected.reduce(
        (acc, curr) => {
          if (selected.map((s) => s.id).includes(row.id)) {
            const startDate = moment.utc(row.datasheet.startDate);
            const endDate = moment.utc(row.datasheet.endDate);
            return {
              sequential: true,
              noOverlap: true,
              isBetween:
                !startDate.isSameOrBefore(earliestDate) &&
                !endDate.isSameOrAfter(latestDate) &&
                startDate.isAfter(earliestDate) &&
                endDate.isBefore(latestDate),
            };
          }
          const startDate = moment.utc(curr.datasheet.startDate);
          const endDate = moment.utc(curr.datasheet.endDate);
          const rowStartDate = moment.utc(row.datasheet.startDate);
          const rowEndDate = moment.utc(row.datasheet.endDate);
          const startOverlap =
            rowStartDate.isBetween(startDate, endDate) ||
            rowStartDate.isSame(startDate);
          const endOverlap =
            rowEndDate.isBetween(startDate, endDate) ||
            rowEndDate.isSame(endDate);

          const startDateWithoutTime = startDate
            .clone()
            .subtract(1, 'month')
            .endOf('month')
            .startOf('day');
          const rowEndDateWithoutTime = rowEndDate
            .clone()
            .endOf('month')
            .startOf('day');
          const endDateMatch = startDateWithoutTime.isSame(
            rowEndDateWithoutTime
          );
          const rowStartDateWithoutTime = rowStartDate
            .clone()
            .subtract(1, 'month')
            .endOf('month')
            .startOf('day');
          const endDateWithoutTime = endDate
            .clone()
            .endOf('month')
            .startOf('day');
          const startDateMatch = endDateWithoutTime.isSame(
            rowStartDateWithoutTime
          );
          const isSequential = endDateMatch || startDateMatch;

          if (startOverlap || endOverlap || !isSequential) {
            return {
              sequential: !!(!isSequential && acc.sequential),
              noOverlap: startOverlap || endOverlap ? false : acc.noOverlap,
              isBetween: false,
            };
          } else {
            return {
              sequential: true,
              noOverlap: acc.noOverlap,
              isBetween: false,
            };
          }
        },
        { sequential: false, noOverlap: true, isBetween: false }
      );

      if (sequential && !noOverlap) {
        modified.disabledMessage = 'Overlaps with selected budgets';
      } else if (!sequential && noOverlap) {
        modified.disabledMessage = 'Not sequential with selected budgets';
      } else if (!sequential && !noOverlap) {
        modified.disabledMessage =
          'Not sequential and overlaps with selected budgets';
      } else if (isBetween) {
        modified.disabledMessage = 'Within selected budgets';
      } else {
        modified.canSelect = true;
      }

      return modified;
    });
  }, [_rows, onSelectRow, selected]);

  return (
    <>
      <TableStyle hasFooter>
        <TableInner
          data={rows}
          dataColumns={columns}
          canSelect
          showGroupEmptyText
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          defaults={defaults}
        />
      </TableStyle>
      <Bottom>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Group Name"
          maxLength={40}
          data-testid="create-budget-name"
          autoComplete="off" data-1p-ignore
        />
        {
          (
            <CreateButton
              color="primary"
              outline
              onClick={() =>
                group
                  ? onUpdateGroup(
                      group.id,
                      name,
                      selected.map((s) => s.id)
                    )
                  : onCreateGroup(
                      name,
                      selected.map((s) => s.id)
                    )
              }
              disabled={loading || !selected.length || !name.length}
              loading={loading}
              data-testid="create-budget-submit"
            >
              {group ? 'Update' : 'Create'}
            </CreateButton>
          ) as any
        }
      </Bottom>
    </>
  );
};

export default Group;
