import React, { useMemo, useState } from 'react';
import {
  Description,
  DescriptionContainer,
  HorizontalTabs,
  SettingTitle,
  ToggleVisible,
  Where,
} from './styledComponents';
import MultiAccountSelect from 'components/AdvancedBudgetSettingsModal/MultiAccountSelect';

const TABS = [
  'Taxes',
  'Interest Expense',
  'Interest Income',
  'Depreciation & Amortization',
];

enum Tab {
  Taxes,
  InterestExpense,
  InterestIncome,
  DepreciationAmortization,
}

const tabEnumToName = (tab: Tab): string => {
  switch (tab) {
    case Tab.Taxes:
      return 'taxes';
    case Tab.InterestExpense:
      return 'interestExpense';
    case Tab.InterestIncome:
      return 'interestIncome';
    case Tab.DepreciationAmortization:
      return 'depreciationAmortization';
  }
};

interface AccountItem {
  identifier: string;
  itemType: string;
  name: string;
  accountNumber: string;
  group: number;
}

interface AccountOption {
  value: AccountItem;
  label: string;
}

export interface EarningsBeforeState {
  taxes: AccountItem[];
  interestExpense: AccountItem[];
  interestIncome: AccountItem[];
  depreciationAmortization: AccountItem[];
}

interface Props {
  allIncomeItems: AccountItem[];
  allExpenseItems: AccountItem[];
  state: EarningsBeforeState;
  showAccountNumbers: boolean;
  onStateChange: (state: EarningsBeforeState) => void;
}

const incomeGroups = [
  {
    label: 'Income',
  },
  {
    label: 'Other Income',
  },
];

const expenseGroups = [
  {
    label: 'Cost of Goods Sold',
  },
  {
    label: 'Operating Expenses',
  },
  {
    label: 'Other Expenses',
  },
];

const getSourceAccountOptions = (
  groups,
  sourceAccounts: AccountItem[],
  showAccountNumbers: boolean
) => {
  return groups.map(({ label }, index) => ({
    label,
    index,
    options: (sourceAccounts || [])
      .filter((account) => account.group === index)
      .map((account) => ({
        value: account,
        label:
          showAccountNumbers && account.accountNumber
            ? `${account.accountNumber} ${account.name}`
            : account.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
  }));
};

const getSelectedSourceValues = (
  selectedAccounts: AccountItem[],
  sourceAccountOptions: {
    label: string;
    index: number;
    options: { value: AccountItem; label: string }[];
  }[]
): AccountOption[] => {
  const result: AccountOption[] = [];
  sourceAccountOptions.forEach((group) => {
    const selectedOptions = group.options.filter((option) => {
      return selectedAccounts.some((account) => {
        return (
          option.value.itemType === account.itemType &&
          option.value.identifier === account.identifier
        );
      });
    });
    if (selectedOptions.length) {
      result.push(...selectedOptions);
    }
  });

  return result;
};

const EarningsBeforeSettings = ({
  allIncomeItems,
  allExpenseItems,
  state,
  showAccountNumbers,
  onStateChange,
}: Props): React.ReactElement => {
  const [tab, setTab] = useState<Tab>(Tab.Taxes);
  const onChangeSourceAccounts = (currentTab: Tab, values: AccountOption[]) => {
    onStateChange({
      ...state,
      [tabEnumToName(currentTab)]: values.map((option) => ({
        itemType: option.value.itemType,
        identifier: option.value.identifier,
      })),
    });
  };

  const onTabChange = (index: Tab) => {
    setTab(index);
  };

  const incomeSourceAccountOptions = useMemo(() => {
    return getSourceAccountOptions(
      incomeGroups,
      allIncomeItems,
      showAccountNumbers
    );
  }, [allIncomeItems, showAccountNumbers]);

  const expenseSourceAccountOptions = useMemo(() => {
    return getSourceAccountOptions(
      expenseGroups,
      allExpenseItems,
      showAccountNumbers
    );
  }, [allExpenseItems, showAccountNumbers]);

  const taxes = useMemo(() => {
    return getSelectedSourceValues(state.taxes, expenseSourceAccountOptions);
  }, [state, expenseSourceAccountOptions]);

  const interestExpense = useMemo(() => {
    return getSelectedSourceValues(
      state.interestExpense,
      expenseSourceAccountOptions
    );
  }, [state, expenseSourceAccountOptions]);

  const interestIncome = useMemo(() => {
    return getSelectedSourceValues(
      state.interestIncome,
      incomeSourceAccountOptions
    );
  }, [state, incomeSourceAccountOptions]);

  const depreciationAmortization = useMemo(() => {
    return getSelectedSourceValues(
      state.depreciationAmortization,
      expenseSourceAccountOptions
    );
  }, [state, expenseSourceAccountOptions]);

  return (
    <>
      <SettingTitle>EBIT/EBITDA</SettingTitle>
      <DescriptionContainer style={{ marginBottom: '1.5rem' }}>
        <Description>
          EBIT: Net Income + Taxes + Net Interest Expense
        </Description>
        <Description>
          EBITDA: Net Income + Taxes + Net Interest Expense + D&A
        </Description>
        <Where>Where:</Where>
        <Description>
          Net Income = Net Profit or Loss from Profit & Loss Statement
        </Description>
        <Description>
          Net Interest Expense = Interest Expense - Interest Income
        </Description>
      </DescriptionContainer>
      <Description>
        Map the following accounts to use EBITDA and EBIT.
        <br />
        Only individual/child accounts are included in the selection, not
        total/parent accounts.
        <br />
        After mapping, EBIT/EBITDA selections will appear in the Ratio/Metrics
        section of the data picker.
      </Description>
      <HorizontalTabs tabs={TABS} current={tab} onChange={onTabChange} />
      <ToggleVisible visible={tab === Tab.Taxes}>
        <MultiAccountSelect
          isMulti
          value={taxes}
          options={expenseSourceAccountOptions}
          onChange={(value: AccountOption[]) =>
            onChangeSourceAccounts(Tab.Taxes, value)
          }
          components={null}
          isDisabled={false}
          collapseGroups
          defaultOpenGroupIndex={-1}
          menuPlacement="bottom"
        />
      </ToggleVisible>
      <ToggleVisible visible={tab === Tab.InterestExpense}>
        <MultiAccountSelect
          isMulti
          value={interestExpense}
          options={expenseSourceAccountOptions}
          onChange={(value: AccountOption[]) =>
            onChangeSourceAccounts(Tab.InterestExpense, value)
          }
          components={null}
          isDisabled={false}
          collapseGroups
          defaultOpenGroupIndex={-1}
        />
      </ToggleVisible>
      <ToggleVisible visible={tab === Tab.InterestIncome}>
        <MultiAccountSelect
          isMulti
          value={interestIncome}
          options={incomeSourceAccountOptions}
          onChange={(value: AccountOption[]) =>
            onChangeSourceAccounts(Tab.InterestIncome, value)
          }
          components={null}
          isDisabled={false}
          collapseGroups
          defaultOpenGroupIndex={-1}
        />
      </ToggleVisible>
      <ToggleVisible visible={tab === Tab.DepreciationAmortization}>
        <MultiAccountSelect
          isMulti
          value={depreciationAmortization}
          options={expenseSourceAccountOptions}
          onChange={(value: AccountOption[]) =>
            onChangeSourceAccounts(Tab.DepreciationAmortization, value)
          }
          components={null}
          isDisabled={false}
          collapseGroups
          defaultOpenGroupIndex={-1}
        />
      </ToggleVisible>
    </>
  );
};

export default EarningsBeforeSettings;
