import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import useCompanyId from './useCompanyId';

export const ACCOUNTING_INTEGRATION = gql`
  query UseIntegrations($companyId: Int!) {
    company(id: $companyId) {
      id
      integrations
    }
  }
`;

export default function useIntegrations(_companyId) {
  const companyId = useCompanyId() || _companyId;

  const { loading, error, data } = useQuery(ACCOUNTING_INTEGRATION, {
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  if (!companyId) return null;

  if (loading) return null;
  if (error) return null;
  if (!data || !data.company) {
    return null;
  }
  return data.company.integrations;
}
