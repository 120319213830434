import React, { ReactElement } from 'react';

const BudgetForecastIcon = ({
  color = '#f9fafc',
  width = '100%',
  height = '100%',
  cursor,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  cursor?: string;
  onClick?: (any) => void;
}): ReactElement => {
  return (
    <>
      <svg
        {...props}
        width={width}
        height={height}
        cursor={cursor}
        viewBox="0 0 24 24"
      >
        <path
          fill={color}
          d="M18,22A2,2 0 0,0 20,20V4C20,2.89 19.1,2 18,2H12V9L9.5,7.5L7,9V2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18Z"
        />
      </svg>
    </>
  );
};

export default BudgetForecastIcon;
