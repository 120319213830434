import React, { useEffect, useRef } from 'react';
import { Container, Icon } from './styledComponents';

interface Props<T> {
  /** Either a string or an object used to define the tab */
  tab: T;
  /** The index of the tab */
  index: number;
  /** The currently selected tab index */
  current: number;
  /** Change function invoked on tab click */
  onChange: (id: number) => void;
}

interface Tab {
  /** The name to be displayed for the tab */
  label: string;
  /** The ID for the tab used in place of the index */
  id: number;
  /** Color of the optional icon */
  iconColor?: string;
  /** Style of the optional icon */
  iconStyle?: React.CSSProperties;
  /** The MDI classnames for the optional icon */
  icon?: string;
}

const isTab = (tab: unknown): tab is Tab => (tab as Tab).label !== undefined;

const Tab = <T extends Tab | string>({
  tab,
  index,
  current,
  onChange,
}: Props<T>): React.ReactElement => {
  const tabRef = useRef<HTMLButtonElement>(null);

  let name: string;
  let id = index;
  let icon: string | null = null;
  let iconColor: string | null = null;
  let selected: boolean = index === current;
  let iconStyle: React.CSSProperties = {};

  if (isTab(tab)) {
    name = tab.label;
    id = tab.id;
    selected = id === current;
    icon = tab.icon || null;
    iconColor = tab.iconColor || null;
    iconStyle = tab.iconStyle || {};
  } else {
    name = tab;
  }

  /** Adds a title to truncated tabs for mouse over */
  useEffect(() => {
    const _tab = tabRef.current;
    if (_tab && _tab.scrollWidth > _tab.clientWidth) {
      _tab.title = name;
    }
  }, [name]);

  return (
    <Container
      ref={tabRef}
      key={index}
      selected={selected}
      onClick={() => onChange(id)}
      className={`tab-${name.toLowerCase().split(' ').join('-')}`}
    >
      {name}
      {!!icon && (
        <Icon className={icon} iconColor={iconColor} style={iconStyle} />
      )}
    </Container>
  );
};

export default Tab;
