import React from 'react';
import { Container, Top } from './styledComponents';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import lightThemeUploadImg from 'images/upload.png';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import darkThemeUploadImage from 'images/upload-light.png';
import useTheme from 'hooks/useTheme';

const CustomDropzone = ({
  children,
}: {
  children?: React.ReactElement[];
}): React.ReactElement => {
  const theme = useTheme();
  return (
    <Container>
      <Top>
        <span>File</span>
        <span>
          <img
            src={
              theme.mode === 'light'
                ? lightThemeUploadImg
                : darkThemeUploadImage
            }
          />
        </span>
      </Top>
      {children}
    </Container>
  );
};

export default CustomDropzone;
