import React from 'react';
import styled from 'styled-components';

export const getColor = (status) => {
  switch (status) {
    case 'In Progress':
      return 'progress';
    case 'Needs Review':
      return 'danger';
    case 'Completed':
      return 'success';
    default:
  }
};

export const Container = styled.span`
  background-color: ${({ color, theme }) => theme.colors[color ?? 'primary']};
  color: ${({ theme }) => theme.colors.white};
  padding: 3px 5px;
  border-radius: 3px;
  margin-right: 5px;
  white-space: nowrap;
`;

const StatusColumn = ({ status }) => {
  if (!status) return null;

  return <Container color={getColor(status)}>{status}</Container>;
};

export default StatusColumn;
