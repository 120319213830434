import React, { useMemo } from 'react';
import Table from 'components/StandardTable/';
import DateColumn from  './statusHistoryColumns/DateColumn/index.js'
import NameColumn from './statusHistoryColumns/NameColumn/index.js'
import StatusColumn from './statusHistoryColumns/StatusColumn/index.js'
import Modal from 'components/Modal'

import styled from 'styled-components';

const Scroll = styled.div`
  max-height: calc(100vh - 200px);
  overflow-y: auto;
`;

const StatusHistoryModal = (props) => {
  const { row, showHistory, setShowHistory } = props
  const buttons = useMemo(
    () => [
      {
        text: 'Close',
        color: 'default',
        outline: true,
        onClick: () => setShowHistory(false),
      },
    ],
    [setShowHistory]
  );

  const columns = useMemo(() => {
    const _columns = [
      {
        Header: 'Set By',
        accessor: 'setBy',
        Cell: ({row: statusHistory}) => <NameColumn user={statusHistory.original.user} />,
        width: 100
      },
      {
        Header: 'Date/Time',
        accessor: 'dateTime',
        Cell: ({ row: statusHistory }) => <DateColumn createdAt={statusHistory.original.createdAt} />,
        width: 100,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row: statusHistory}) => <StatusColumn status={statusHistory.original.status} />,
        width: 60,
        align: 'center',
      }
    ];

    return _columns;
  }, []);

  return (
    <Modal show={showHistory} buttons={buttons} width={800} title={'Status History'}>
      <Scroll>
        <Table
          columns={columns}
          data={row.length === 0 ? row : row.statusHistory}
        />
      </Scroll>
    </Modal>
  )
}

export default StatusHistoryModal;