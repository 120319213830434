import React from 'react';
import styled from 'styled-components';

import xeroImage from './xero-connect-blue.svg';

const XeroLogo = styled.button`
  margin-left: auto;
  margin-right: auto;
  width: 350px;
  padding: 0;
  margin-bottom: 0.25rem;
  & img {
    transition: filter 0.2s;
    width: 100%;
  }
  & img:hover {
    filter: brightness(0.9);
  }
`;

function XeroButton(props) {
  const { onClick, className, ...rest } = props;

  return (
    <XeroLogo
      onClick={onClick}
      className={`connection-button ${className || ''}`}
      {...rest}
    >
      <img src={xeroImage} />
    </XeroLogo>
  );
}

export default XeroButton;
