import React from 'react';
import { Query } from '@apollo/client/react/components';
import gql from 'graphql-tag';
import hoistNonReactStatics from 'hoist-non-react-statics';

import getDisplayName from 'utils/getDisplayName';
import { OperationVariables, QueryResult } from '@apollo/client';

export const COMPANY_ID_QUERY = gql`
  query WithCompanyId {
    currentCompanyId @client
  }
`;

export interface WithCompanyIdProps {
  companyId: number;
}

const withCompanyId = <P extends Record<string, unknown>>(
  Component: React.ComponentType<P>,
  required = true
): React.ComponentClass => {
  class WithCompanyId extends React.Component<P & WithCompanyIdProps> {
    static displayName = `WithCompanyId(${getDisplayName(Component)})`;
    render() {
      if (this.props.companyId) {
        return <Component {...(this.props as P)} />;
      }

      return (
        <Query query={COMPANY_ID_QUERY}>
          {({ data, loading, error }: QueryResult<any, OperationVariables>) => {
            if (loading || error) {
              return null;
            }
            if (!data.currentCompanyId && required) {
              return null;
            }
            return (
              <Component
                {...(this.props as P)}
                companyId={parseInt(data.currentCompanyId, 10)}
              />
            );
          }}
        </Query>
      );
    }
  }

  hoistNonReactStatics(WithCompanyId, Component);

  return WithCompanyId;
};

export default withCompanyId;
