/**
 *
 * Sidebar
 *
 */

import React, { useCallback, useState } from 'react';

import useAccountingIntegrationId from 'hooks/useAccountingIntegrationId';
import useCompanyId from 'hooks/useCompanyId';
import useIsConsolidation from 'hooks/useIsConsolidation';
import useWorkspaceId from 'hooks/useWorkspaceId';
import { ClientPortalPreviewLocation } from 'locations';

import BudgetsButton from './BudgetsButton';
import ClientPortalIcon from 'components/Icons/ClientPortalIcon';
import CompanySettingsModal from 'components/CompanySettingsModal';
import DataSheetsButton from './DataSheetsButton';
import EliminationsIcon from 'components/Icons/EliminiationsIcon';
import GearIcon from 'components/Icons/GearIcon';
import LastSynced from 'components/LastSynced';
import SidebarButton from './SidebarButton';
import TopMenu from './TopMenu';
import { Bar, Header, Top, Bottom } from './styledComponents';
import { sizes } from 'media';
import useMedia from 'hooks/useMedia';
import Logo from './Logo';
import useWhitelabel from 'hooks/useWhitelabel';
import DataSheetModal from 'components/DataSheetModal';
import BudgetsConsolidationModal from 'components/BudgetsConsolidationModal';
import BudgetsModal from 'components/BudgetsModal';
import { OPEN_BUDGETS_MODAL, usePubSub } from 'utils/pubsub';

const hasEliminations = new Set(['QB', 'XERO', 'CSV', 'MYOB']);

interface SidebarProps {
  noCompany: boolean;
  anonymous: boolean;
  toggleSidebar: (sidebarState: boolean) => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  noCompany,
  anonymous,
  toggleSidebar,
}) => {
  const companyId = useCompanyId();
  const workspaceId = useWorkspaceId();
  const isConsolidation = useIsConsolidation();
  const { whiteLabel } = useWhitelabel();
  const accountingIntegration = useAccountingIntegrationId();
  const phone = useMedia([`(min-width: ${sizes.phone}px)`], [false], true);
  const [showCompanySettings, setShowCompanySettings] =
    useState<boolean>(false);
  const [showDataSheetModal, setShowDataSheetModal] = useState<boolean>(false);
  const [showBudgetsModal, setShowBudgetsModal] = useState<boolean>(false);

  const toggleModal = useCallback(
    (type: 'budgets' | 'company' | 'dataSheet', state: boolean) => {
      if (type === 'dataSheet') {
        setShowDataSheetModal(state);
      } else if (type === 'company') {
        setShowCompanySettings(state);
      } else if (type === 'budgets') {
        setShowBudgetsModal(state);
      }
    },
    []
  );

  const openBudgets = useCallback(() => {
    setShowBudgetsModal(true);
  }, []);

  usePubSub(OPEN_BUDGETS_MODAL, openBudgets);

  const showEliminations =
    isConsolidation && hasEliminations.has(accountingIntegration);

  if (!workspaceId) {
    return null;
  }
  const clientPortalUrl = ClientPortalPreviewLocation.toUrl({
    workspaceId,
    companyId,
  });
  return phone ? null : (
    <>
      <Bar
        anonymous={anonymous}
        onMouseEnter={() => toggleSidebar && toggleSidebar(true)}
        onMouseLeave={() => toggleSidebar && toggleSidebar(false)}
      >
        <Logo
          forcedTheme="dark"
          fullWidth={143}
          style={
            whiteLabel
              ? { padding: '10px 0 10px 10px' }
              : { margin: '18px 0 18px 18px' }
          }
        />
        {!noCompany && companyId && (
          <>
            <Top>
              <Header>Library Assets</Header>
              <TopMenu />
            </Top>
            <Bottom>
              <Header>Additional Inputs</Header>
              {!anonymous && (
                <>
                  {showEliminations && (
                    <SidebarButton
                      icon={<EliminationsIcon height={'24px'} width={'24px'} />}
                      text={'Eliminations'}
                      to={'/eliminations'}
                      id="sidebar-eliminations-button"
                    />
                  )}
                  <BudgetsButton
                    toggleSidebar={toggleSidebar}
                    onClick={() => toggleModal('budgets', true)}
                  />

                  <DataSheetsButton
                    toggleSidebar={toggleSidebar}
                    onClick={() => toggleModal('dataSheet', true)}
                  />
                </>
              )}
              <Header>Other Assets</Header>
              <SidebarButton
                icon={<ClientPortalIcon height={'24px'} width={'24px'} />}
                text={'Client Portal'}
                to={clientPortalUrl}
                id="sidebar-client-portal-button"
              />
              <SidebarButton
                icon={<GearIcon height={'24px'} width={'24px'} />}
                text={'Company Settings'}
                onClick={() => setShowCompanySettings(true)}
                id="sidebar-company-settings-button"
              />
              <LastSynced />
            </Bottom>
          </>
        )}
      </Bar>
      <DataSheetModal
        show={showDataSheetModal}
        onClose={() => toggleModal('dataSheet', false)}
      />
      <CompanySettingsModal
        companyId={companyId}
        show={showCompanySettings}
        onClose={() => toggleModal('company', false)}
      />
      {isConsolidation ? (
        <BudgetsConsolidationModal
          show={showBudgetsModal}
          onClose={() => toggleModal('budgets', false)}
        />
      ) : (
        <BudgetsModal
          show={showBudgetsModal}
          workspaceId={1}
          title={'Budgets / Forecasts'}
          forecastRange={'Jan 2020 - Dec 2020'}
          prefillDataRange={'Jan 2019 - Dec 2019'}
          width={1000}
          onClose={() => toggleModal('budgets', false)}
        />
      )}
    </>
  );
};

export default Sidebar;
