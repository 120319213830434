/**
 *
 * Empty
 *
 */
import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';

import Button from 'components/Button';
import media from 'media';
import useDemoCompany from 'hooks/useDemoCompany';

const Div = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
  margin-right: 1rem;
  margin-left: 1rem;
  ${media.large`
    display: flex;
    align-items: flex-start;
  `}
`;

const VideoSide = styled.div`
  margin-bottom: 20px;
  ${media.large`
  margin-right: 5%;
  margin-bottom: 0px;
  `}
`;

const PlayerWrapper = styled.div`
  position: relative;
  /* padding-top: 22.25%; Player ratio: 100 / (1280 / 720) */
  /* padding-top: calc(100vh - 312px);
  max-height: 437px; */
  width: 39%;
  max-width: 100%;

  width: 560px;
  height: 324px;
  padding-top: 0;

  ${media.large`
    margin-right: 5%;
    width: 560px;
    height: 324px;
    padding-top: 0;
  `}
`;

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 2.0625rem;
  margin-bottom: 1.625rem;

  max-width: 35rem;
  ${media.large`
  margin-left: -1rem;
  `}
`;

const VideoTitle = styled(Title)`
  margin-left: 0;
  margin-bottom: 0.5rem;
  ${media.large`
  margin-left: 0;
  `}
`;

const VideoDescription = styled.div`
  font-weight: 400;
  font-size: 1.3375rem;
  margin-bottom: 0.5rem;
  color: ${(props) => props.theme.colors.brand};
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 1.4375rem;
  margin-bottom: 1.625rem;
  max-width: 31rem;
`;

const StyledButton = styled(Button)`
  font-size: 1.0625rem;
`;
const DemoButton = styled(StyledButton)`
  margin-left: 0.25rem;
`;

const SharedContent = styled.div`
  width: 229px;
  font-family: 'Open Sans';
  font-size: 15px;
  font-weight: 400;
  padding-bottom: 30px;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > button:first-child {
    margin-bottom: 10px;
    ${media.desktop`
      margin-bottom: 0px;
    `};
  }

  ${media.desktop`
    flex-direction: row;
  `};
`;

const Empty = (props) => {
  const { onAddCompany, sharedWithMe, canAdd } = props;
  const { launchDemo } = useDemoCompany();

  if (sharedWithMe) {
    return (
      <SharedContent>
        Shared with Me is only a group used for individual companies that are
        shared with you. Select/Create a different Group to add a company.
      </SharedContent>
    );
  }

  return (
    <Div>
      <VideoSide>
        <VideoTitle>Step 1 - Watch Video</VideoTitle>
        <VideoDescription>
          The smart way to visualize your data in just a few clicks.
        </VideoDescription>
        <PlayerWrapper>
          <StyledReactPlayer
            url="https://youtu.be/3aQjgNh7a1g"
            controls
            width="100%"
            height="100%"
            config={{
              youtube: {
                playerVars: {
                  showinfo: 0,
                  modestbranding: 1,
                  autoplay: 0,
                },
              },
            }}
          />
        </PlayerWrapper>
      </VideoSide>
      <div>
        <Title>Step 2 - Connect your company or sample a demo company</Title>
        <Description>
          You are just a few clicks away from creating quick and beautiful
          reports.
        </Description>
        <ButtonContainer>
          {canAdd && (
            <StyledButton color="info" onClick={onAddCompany}>
              Connect New Company
            </StyledButton>
          )}
          <DemoButton color="default" outline onClick={launchDemo}>
            Launch Demo Company
          </DemoButton>
        </ButtonContainer>
      </div>
    </Div>
  );
};

export default Empty;
