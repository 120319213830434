/* global fetch */
import React, { useCallback, useMemo, useState } from 'react';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import TimeAgo from 'react-timeago';

import {
  Container,
  Div,
  LogoDiv,
  PrimaryButtonStyle,
  ManageButton,
  ActionButtonsContainer,
  ActionsContainer,
  ActionButton,
  ActionButtonExternalLink,
  DisconnectButton,
  DivRow,
  StyledAvatarList,
  SyncDateColumn,
} from './styledComponents';
import { useMutation } from '@apollo/client';

import TransferWorkspaceCompany from 'modals/TransferWorkspaceCompany';
import ManageConsolidation from 'modals/CreateConsolidation';
import GroupUserModal from 'components/GroupUserModal';
import ClientPortalUsers from 'components/ClientPortalUsers';
import CreateCsvCompany from 'components/CreateCsvCompany';

import { reconnect } from 'utils/connections';

import QboButton from './QboButton';
import { showConfirm, showError } from 'utils/popups';
import { captureException } from 'utils/sentry';
import produce from 'immer';
import CompanySettingsModal from 'components/CompanySettingsModal';
import { integrationToName } from 'components/IntegrationLogos/util';
import useAllIntegrationStatus from 'hooks/useAllIntegrationStatus';

import { API_URL } from 'environment';
import useAsync from 'hooks/useAsync';
import download from 'downloadjs';

import { sizes } from 'media';
import useMedia from 'hooks/useMedia';
import ClientPortalAccess from 'components/GroupCompanyList/ClientPortalAccess';
import LogoUploader from 'components/LogoUploader';
import uploadImageToS3 from 'utils/uploadImageToS3';
import useSaveLogo from 'hooks/useSaveLogo';
import { navigateWithGtmDebug } from 'utils/ga';

const OPEN_COMPANY = gql`
  mutation WorkspaceOpenCompany($companyId: Int!) {
    setCompany(companyId: $companyId) @client
  }
`;

const DISCONNECT = gql`
  mutation DisconnectCompany($companyId: Int!) {
    disconnectCompany(companyId: $companyId) {
      disconnectedId
    }
  }
`;

const LEAVE = gql`
  mutation LeaveCompany($companyId: Int!) {
    leaveCompany(companyId: $companyId) {
      companyId
    }
  }
`;

const UPDATE = gql`
  fragment AfterDisconnectCompany on Firm {
    id
    companies {
      id
    }
  }
`;
const UPDATE_CONSOLIDATION = gql`
  fragment AfterDisconnectCompanyConsolidated on Firm {
    id
    companies(integrationType: ACCOUNTING) {
      id
    }
  }
`;

const UPDATE_COUNT = gql`
  fragment WorkspaceCount on Workspace {
    id
    companyCount
  }
`;

const UPDATE_SHARED = gql`
  fragment AfterLeaveCompany on Workspace {
    id
    sharedCompanies {
      id
    }
  }
`;

const GroupCompanyListItem = (props) => {
  const { row, workspaceId, groupId, toggleRowExpanded, minimal } = props;
  const {
    id: companyId,
    groupId: companyGroupid,
    canShare,
    canDisconnectCompany,
    isConsolidation,
    canManageConsolidation,
    name,
    accountingIntegration,
    needsToPay,
    ownerNeedsToPay,
    defaultLogoUrl,
    logo,
    useLogoDefault,
    integration,
  } = row.original;

  const [loading, setLoading] = useState(false);
  const [showClients, setShowClients] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);
  const [showManage, setShowManage] = useState(false);
  const [showUpdateCsv, setShowUpdateCsv] = useState(false);
  const [showCompanySettings, setShowCompanySettings] = useState(false);
  const [downloadPending, setDownloadPending] = useState(false);
  const [saving, setSaving] = useState(false);
  const onOpenUsers = useCallback(() => setShowUsers(true), []);
  const onOpenTransfer = useCallback(() => setShowTransfer(true), []);
  const onOpenUpdateCsv = useCallback(() => setShowUpdateCsv(true), []);
  const onCloseUsers = useCallback(() => setShowUsers(false), []);
  const onCloseTransfer = useCallback(() => setShowTransfer(false), []);
  const onCloseUpdateCsv = useCallback(() => setShowUpdateCsv(false), []);
  const phone = useMedia([`(min-width: ${sizes.phone}px)`], [false], true);
  const tablet = useMedia([`(min-width: ${sizes.tablet}px)`], [false], true);
  const history = useHistory();
  const { save: saveLogo } = useSaveLogo();

  const { firstInactive, allIntegrationStatus, refetch } =
    useAllIntegrationStatus(companyId);

  const onCompleted = useCallback(() => {
    navigateWithGtmDebug('/');
  }, []);

  const handleManage = useCallback(() => setShowManage(true), []);
  const onCloseManage = useCallback(() => setShowManage(false), []);

  const handleCloseGroupUserModal = useCallback(() => setShowUsers(false), []);

  const [setCompany] = useMutation(OPEN_COMPANY, {
    variables: {
      companyId: row.original.id,
    },
  });

  const handleOpen = useCallback(() => {
    setCompany().then(() => onCompleted());
  }, [onCompleted, setCompany]);

  const onOpen = useCallback(async () => {
    setLoading(true);
    try {
      const {
        allIntegrationStatus: currentIntegrationStatus,
        firstInactive: inactiveIntegration,
        isConsolidationEmpty,
        consolidationError,
      } = await refetch();

      let onlyGustoInactive = false;

      const gustoStatus = currentIntegrationStatus.find(
        (item) => item.integration === 'GUSTO'
      );

      if (gustoStatus && !gustoStatus.active) {
        onlyGustoInactive = currentIntegrationStatus.every(
          (item) => item.integration === 'GUSTO' || item.active
        );
      }

      if (consolidationError) {
        setLoading(false);
        return showError({
          title: 'Consolidation Error',
          text: consolidationError,
          icon: 'info',
        });
      }

      if (inactiveIntegration && !onlyGustoInactive) {
        setLoading(false);
        return showError({
          title: 'Connection Lost',
          text: isConsolidation
            ? `The connection${
                inactiveIntegration.inactiveCompanyNames.length > 1 ? 's' : ''
              } to ${inactiveIntegration.inactiveCompanyNames.join(
                ', '
              )} needs to be restored.`
            : 'The connection needs to be restored.',
          icon: 'info',
        });
      }

      if (isConsolidation && isConsolidationEmpty) {
        setLoading(false);
        handleManage();
      } else if (needsToPay) {
        history.push('/subscribe');
      } else if (ownerNeedsToPay) {
        history.push('/inactive');
      } else {
        handleOpen();
      }
    } catch (err) {
      captureException(err, true);
      setLoading(false);
    }
  }, [
    handleManage,
    handleOpen,
    history,
    isConsolidation,
    needsToPay,
    ownerNeedsToPay,
    refetch,
  ]);

  const [disconnect] = useMutation(DISCONNECT);
  const [leave] = useMutation(LEAVE);

  const disconnectCompany = useCallback(async () => {
    try {
      if (
        await showConfirm({
          title: 'Are you sure?',
          text: canDisconnectCompany
            ? 'You will lose all dashboards/reports even if you reconnect later.'
            : 'You will lose access to this company.',
        })
      ) {
        const op = canDisconnectCompany ? disconnect : leave;

        await op({
          variables: {
            companyId,
          },
          update: (cache) => {
            const fragment = canDisconnectCompany ? UPDATE : UPDATE_SHARED;
            const id = canDisconnectCompany
              ? `Firm:${groupId}`
              : `Workspace:${workspaceId}`;

            const data = cache.readFragment({
              fragment,
              id,
            });

            let consolidationData;
            if (canDisconnectCompany) {
              consolidationData = cache.readFragment({
                fragment: UPDATE_CONSOLIDATION,
                id,
              });
            }

            if (canDisconnectCompany) {
              try {
                const workspaceData = cache.readFragment({
                  fragment: UPDATE_COUNT,
                  id: `Workspace:${workspaceId}`,
                });

                if (workspaceData.companyCount) {
                  cache.writeFragment({
                    fragment: UPDATE_COUNT,
                    id: `Workspace:${workspaceId}`,
                    data: produce(workspaceData, (draft) => {
                      draft.companyCount = workspaceData.companyCount - 1;
                    }),
                  });
                }
              } catch {}
            }

            cache.writeFragment({
              fragment,
              id,
              data: produce(data, (draft) => {
                if (canDisconnectCompany) {
                  draft.companies = draft.companies.filter(
                    (c) => c.id !== companyId
                  );
                } else {
                  draft.sharedCompanies = draft.sharedCompanies.filter(
                    (c) => c.id !== companyId
                  );
                }
              }),
            });

            if (consolidationData) {
              cache.writeFragment({
                fragment: UPDATE_CONSOLIDATION,
                id,
                data: produce(consolidationData, (draft) => {
                  draft.companies = draft.companies.filter(
                    (c) => c.id !== companyId
                  );
                }),
              });
            }
          },
          onCompleted: () => toggleRowExpanded(row.id, false),
        });
      }
    } catch (err) {
      captureException(err, true);
    }
  }, [
    canDisconnectCompany,
    companyId,
    disconnect,
    groupId,
    leave,
    row.id,
    toggleRowExpanded,
    workspaceId,
  ]);

  let disconnectMessage = 'Checking connection status...';
  let disconnectDisabled = true;
  let disconnectColor = 'default';
  let disconnectAction;
  const disconnectComponent = DisconnectButton;
  let reconnectComponent = null;

  const onReconnect = useCallback(() => {
    if (firstInactive) {
      reconnect(
        companyGroupid,
        workspaceId,
        firstInactive.integration,
        companyId
      );
    }
  }, [companyGroupid, companyId, firstInactive, workspaceId]);

  if (allIntegrationStatus !== null) {
    disconnectDisabled = false;
    if (canDisconnectCompany) {
      disconnectAction = disconnectCompany;
      disconnectColor = 'danger';
      disconnectMessage = 'Delete company';
    } else {
      disconnectAction = disconnectCompany;
      disconnectColor = 'danger';
      disconnectMessage = 'Leave Company';
    }
  }

  if (allIntegrationStatus !== null && firstInactive) {
    reconnectComponent =
      firstInactive.integration === 'QB' ? QboButton : DisconnectButton;

    if (canDisconnectCompany) {
      disconnectMessage = 'Remove';
    }
  }

  let seeConsolidationDisconnect = false;

  if (isConsolidation) {
    if (canManageConsolidation) {
      disconnectAction = disconnectCompany;
      disconnectColor = 'danger';
      disconnectMessage = 'Delete company';
      seeConsolidationDisconnect = true;
    } else if (!canDisconnectCompany) {
      disconnectAction = disconnectCompany;
      disconnectColor = 'danger';
      disconnectMessage = 'Leave Company';
      seeConsolidationDisconnect = true;
    }
  }

  const getCsvFile = useCallback(async (id, csvName) => {
    try {
      const response = await fetch(`${API_URL}/csv/download?companyId=${id}`, {
        method: 'GET',
        credentials: 'include',
      });
      const blob = await response.blob();

      await download(blob, `${csvName}.csv`);
    } catch (err) {
      captureException(err, true);
    }
    setDownloadPending(false);
  }, []);

  const onLogoUpload = useCallback(
    (files) => {
      if (files.length > 0) {
        setSaving(true);
        const file = files[0];
        uploadImageToS3({
          file,
          onFinish: async (result, f, hmac) => {
            try {
              const url = result.signedUrl.slice(
                0,
                result.signedUrl.indexOf('?')
              );
              await saveLogo(companyId, url, hmac);
            } catch (err) {
              captureException(err, true);
            } finally {
              setSaving(false);
            }
          },
          onError: () => setSaving(false),
        });
      }
    },
    [companyId, saveLogo]
  );

  const { execute } = useAsync(getCsvFile, false);

  const currentLogo = useMemo(
    () => (useLogoDefault ? defaultLogoUrl : logo),
    [useLogoDefault, defaultLogoUrl, logo]
  );

  const isQBD = useMemo(() => {
    return integration === 'QBD';
  }, [integration]);

  return (
    <>
      <td></td>
      <td colSpan={tablet ? 3 : minimal ? 2 : 4}>
        <Container>
          {!minimal && (
            <LogoDiv>
              <Div>
                <LogoUploader
                  onStage={onLogoUpload}
                  defaultImageUrl={currentLogo}
                  loading={saving}
                  disabled={row.original.useLogoDefault}
                />
              </Div>
            </LogoDiv>
          )}
          <ActionButtonsContainer>
            <PrimaryButtonStyle
              onClick={() => onOpen('open')}
              color="primary"
              disabled={allIntegrationStatus === null || loading || saving}
              loading={loading}
              className="open-button"
            >
              {saving ? 'Uploading...' : 'Open'}
            </PrimaryButtonStyle>
            <ClientPortalAccess
              workspaceId={workspaceId}
              companyId={companyId}
              isConsolidation={isConsolidation}
            />
            <PrimaryButtonStyle
              color="primary"
              outline
              onClick={() => setShowClients(true)}
              className="manage-clients-button"
            >
              Manage Clients
            </PrimaryButtonStyle>
            {!minimal && (
              <StyledAvatarList
                users={row.original.clientPortalUsers}
                size={20}
              />
            )}
          </ActionButtonsContainer>
          <ActionsContainer>
            {accountingIntegration === 'CSV' &&
              canDisconnectCompany &&
              !isConsolidation && (
                <React.Fragment>
                  {
                    <ActionButton onClick={onOpenUpdateCsv}>
                      Update CSV
                    </ActionButton>
                  }
                  <ActionButtonExternalLink
                    disabled={downloadPending}
                    onClick={() => {
                      setDownloadPending(true);
                      return execute(row.original.id, row.original.name);
                    }}
                  >
                    Download CSV
                  </ActionButtonExternalLink>
                </React.Fragment>
              )}

            <DivRow>
              <ActionButton
                onClick={() => setShowCompanySettings(true)}
                className="company-settings-button"
              >
                Company Settings
              </ActionButton>
            </DivRow>

            {canShare && (
              <DivRow>
                <ActionButton
                  onClick={onOpenUsers}
                  className="additional-users-button"
                >
                  Additional Users
                </ActionButton>
                {!minimal && (
                  <StyledAvatarList users={row.original.users} size={20} />
                )}
              </DivRow>
            )}
            {canDisconnectCompany && groupId !== -1 && (
              <ActionButton onClick={onOpenTransfer} className="move-to-button">
                Move to...
              </ActionButton>
            )}
            {isConsolidation && (
              <ManageButton onClick={handleManage} className="manage-button">
                {canManageConsolidation ? 'Manage' : 'View'}
              </ManageButton>
            )}
            {!isConsolidation &&
              !!reconnectComponent &&
              allIntegrationStatus !== null && (
                <DisconnectButton
                  as={reconnectComponent}
                  color="success"
                  onClick={onReconnect}
                  className="disconnect-button"
                >
                  Reconnect
                </DisconnectButton>
              )}

            {allIntegrationStatus !== null &&
              (!isConsolidation ||
                (isConsolidation && seeConsolidationDisconnect)) && (
                <DisconnectButton
                  as={disconnectComponent}
                  color={disconnectColor}
                  onClick={disconnectAction}
                  disabled={disconnectDisabled}
                >
                  {disconnectMessage}
                </DisconnectButton>
              )}
          </ActionsContainer>
        </Container>
      </td>
      {!phone && !tablet && (
        <SyncDateColumn>
          {row.original.lastSyncedByIntegration &&
            row.original.lastSyncedByIntegration.reduce((res, item) => {
              if (isConsolidation && item.integration === 'GUSTO') {
                return res;
              }

              res.push(
                <div key={item.integration}>
                  {isQBD && item.integration === 'QB' && !isConsolidation
                    ? 'QuickBooks Desktop'
                    : integrationToName[item.integration]}
                  : <TimeAgo date={item.lastSynced} minPeriod={30} />
                </div>
              );

              return res;
            }, [])}
        </SyncDateColumn>
      )}

      <GroupUserModal
        show={showUsers}
        handleCloseGroupUserModal={handleCloseGroupUserModal}
        companyId={companyId}
        workspaceId={workspaceId}
        onClose={onCloseUsers}
      />
      <TransferWorkspaceCompany
        show={showTransfer}
        onClose={onCloseTransfer}
        companyId={companyId}
        workspaceId={workspaceId}
      />
      <ManageConsolidation
        show={showManage}
        onClose={onCloseManage}
        firmId={groupId}
        companyId={companyId}
        companyName={name}
        workspaceId={workspaceId}
        canManageConsolidation={canManageConsolidation}
      />
      <ClientPortalUsers
        title="Client Portal Users"
        companyId={companyId}
        show={showClients}
        showChecks={false}
        showClientUserOptions
        onClose={() => setShowClients(false)}
      />
      <CreateCsvCompany
        show={showUpdateCsv}
        companyId={companyId}
        onClose={onCloseUpdateCsv}
      />
      <CompanySettingsModal
        companyId={companyId}
        show={showCompanySettings}
        onClose={() => setShowCompanySettings(false)}
      />
    </>
  );
};

export default GroupCompanyListItem;
