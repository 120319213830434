import React from 'react';

const DelimitedFileIcon = ({
  color = 'white', // #617182
  width = '24px',
  height = '24px',
}: React.SVGProps<SVGSVGElement> & {
  cursor?: string;
  onClick?: (any) => void;
}): React.ReactElement => {
  return (
    <svg width={width} height={height} viewBox="0 0 60.77 71.96">
      <path
        fill={color}
        d="M10.53,2A6.55,6.55,0,0,0,4,8.53V60.74a6.55,6.55,0,0,0,6.53,6.52H49.68a6.55,6.55,0,0,0,6.53-6.52V21.58L36.63,2ZM26.22,49.59A5.4,5.4,0,0,1,24.38,51a5.51,5.51,0,0,1-2.36.49,5.61,5.61,0,0,1-2.18-.42,5.47,5.47,0,0,1-1.74-1.16,5.82,5.82,0,0,1-1.16-1.74,5.79,5.79,0,0,1,0-4.33,5.65,5.65,0,0,1,1.16-1.73A5.52,5.52,0,0,1,22,40.54a5.36,5.36,0,0,1,2.22.44A5.45,5.45,0,0,1,26,42.26l.16.18-1.69,1.64-.18-.2a3.32,3.32,0,0,0-.95-.77,3.19,3.19,0,0,0-2.49,0,3,3,0,0,0-1,.64,2.91,2.91,0,0,0-.66,1A3.35,3.35,0,0,0,18.91,46a3.3,3.3,0,0,0,.25,1.32,3.05,3.05,0,0,0,.66,1,3,3,0,0,0,1,.63,3.25,3.25,0,0,0,2.6-.08A3.4,3.4,0,0,0,24.48,48l.17-.21,1.72,1.62Zm7.72.14a3.43,3.43,0,0,1-.88,1,4.06,4.06,0,0,1-1.18.58,4.46,4.46,0,0,1-1.28.19,4.31,4.31,0,0,1-1.3-.19,4.07,4.07,0,0,1-1.16-.58,4.11,4.11,0,0,1-.91-1,4.59,4.59,0,0,1-.61-1.3l-.06-.22,2.25-.89.07.28a2.53,2.53,0,0,0,.62,1.14,1.52,1.52,0,0,0,1.12.42,1.73,1.73,0,0,0,.5-.07,1.18,1.18,0,0,0,.41-.19.8.8,0,0,0,.27-.28,1,1,0,0,0,0-.8.79.79,0,0,0-.26-.33A2.62,2.62,0,0,0,31,47.2a7.72,7.72,0,0,0-.89-.36l-.62-.23a4.07,4.07,0,0,1-.85-.4,3.2,3.2,0,0,1-.8-.61,3,3,0,0,1-.61-.86A2.6,2.6,0,0,1,27,43.61a2.89,2.89,0,0,1,.26-1.21,2.93,2.93,0,0,1,.71-1,3.57,3.57,0,0,1,1.11-.65,4.47,4.47,0,0,1,2.8,0,3.39,3.39,0,0,1,1,.56,3.19,3.19,0,0,1,.68.75,4.11,4.11,0,0,1,.37.77l.08.22L31.88,44l-.09-.25a1.54,1.54,0,0,0-.44-.65,1.22,1.22,0,0,0-.85-.27,1.47,1.47,0,0,0-.87.24.61.61,0,0,0-.3.54.69.69,0,0,0,.29.57,4.22,4.22,0,0,0,1.17.57l.63.21a6.64,6.64,0,0,1,1.14.5,3.44,3.44,0,0,1,.92.7,2.9,2.9,0,0,1,.6.94,3.43,3.43,0,0,1,.2,1.2A3,3,0,0,1,33.94,49.73Zm6.37,1.56H38L34.25,40.76h2.58L39,47l.19.67.2-.66,2.22-6.22h2.58Zm3.17-26.45H33.37V6.89l17.94,18Z"
      />
    </svg>
  );
};

export default DelimitedFileIcon;
