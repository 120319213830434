import React from 'react';
import styled from 'styled-components';
import theme from 'styled-theming';

const color = theme('mode', {
  dark: '#3a404f',
  light: '#617182',
});

const Span = styled.span`
  color: ${color};
  font-size: 12px;
  font-weight: bold;
`;

function HookErrorMessage(props) {
  const { errors, field, message = null } = props;

  if (!errors) return null;

  if (!errors[field]) {
    return null;
  }

  return <Span>{errors[field].message || message}</Span>;
}

export default HookErrorMessage;
