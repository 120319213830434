/**
 *
 * Result
 *
 */

import React from 'react';
import styled from 'styled-components';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import measureSync from 'library/measureSync';

import Button from 'components/Button';
import { dataPickerResultItemZIndex } from 'utils/zIndex';
import { RightContainer } from 'components/ItemSelectFlowStructure';

const Scroll = styled.div`
  height: 260px;
  overflow-y: auto;

  @media screen and (min-height: 643px) {
    height: ${(props) => (props.dates ? '300px' : '330px')};
  }
  @media screen and (min-height: 743px) {
    height: ${(props) => (props.dates ? '380px' : '430px')};
  }
`;

const I = styled.i`
  font-size: 18px;
  color: #788db4;
`;

const Item = styled.div`
  display: flex;
  font-size: 13px;
  z-index: ${dataPickerResultItemZIndex};
  align-items: center;
`;

const Value = styled.div`
  flex-grow: 1;
`;

const Trash = styled.button`
  width: 20px;
  padding: 0;
`;

const Drag = styled(I)`
  cursor: move;
  margin-right: 5px;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const H4 = styled.h4`
  margin-bottom: 0;
`;

const SortableItem = SortableElement(({ value, onRemove, id, single }) => (
  <Item>
    {!single && <Drag className="la la-reorder" />}
    <Value>{value}</Value>
    {!single && (
      <Trash onClick={() => onRemove(id)} type="button">
        <I className="la la-trash" />
      </Trash>
    )}
  </Item>
));

const SortableList = SortableContainer(
  ({ items, getItemName, onRemove, single }) => {
    return (
      <div>
        {items.map((id, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={getItemName(id)}
            id={id}
            onRemove={onRemove}
            single={single}
          />
        ))}
      </div>
    );
  }
);

export function mapIdsToItems(children, companyId, initial = {}) {
  const result = initial;
  children.forEach((child) => {
    const value = companyId ? `${child.value}` : child.value;

    if (child.value) {
      result[value] = child;
    }
    if (child.children && child.children.length) {
      mapIdsToItems(child.children, companyId, result);
    }
  });
  return result;
}

export function mapConsolidationData(
  companyData,
  consolidationCompanies,
  sharedDataVariables
) {
  let result = measureSync('mapConsolidationData.companyData', () =>
    mapIdsToItems(companyData)
  );

  if (sharedDataVariables) {
    result = mapIdsToItems(sharedDataVariables, null, result);
  }

  consolidationCompanies.forEach((company) => {
    result = mapIdsToItems(company.customKpiVariables, company.id, result);
    result = mapIdsToItems(company.sharedDataVariables, company.id, result);
  });

  return result;
}

class Result extends React.PureComponent {
  constructor(props) {
    super(props);

    this.setupDataMap(props);
    this.state = {
      saving: false,
    };
  }
  setupDataMap = (props) => {
    if (props.consolidationCompanies && props.consolidationCompanies.length) {
      this.idToItem = mapConsolidationData(
        props.data,
        props.consolidationCompanies,
        props.sharedDataVariables
      );
    } else {
      this.idToItem = mapIdsToItems(props.data);
      if (props.sharedDataVariables) {
        this.idToItem = mapIdsToItems(
          props.sharedDataVariables,
          null,
          this.idToItem
        );
      }
    }
  };
  getItemName = (id) => {
    const { consolidationCompanies } = this.props;

    const item = this.idToItem[id];

    if (item) {
      if (consolidationCompanies) {
        const [, , companyIdText] = item.value.split('_');

        if (companyIdText) {
          const companyId = parseInt(companyIdText, 10);

          const company = consolidationCompanies.find(
            (c) => c.id === companyId
          );
          if (company) {
            return `${item.name} (${company.abbreviation || company.name})`;
          }
        }
      }

      return item.name;
    }
    return 'Missing';
  };
  onSave = () => {
    const { onSave } = this.props;
    this.setState({ saving: true });
    onSave();
  };
  render() {
    const { selected, onRemove, onSortEnd, single, dates } = this.props;
    const { saving } = this.state;

    return (
      <RightContainer dates={dates}>
        <Top>
          <H4>Added</H4>
          <Button
            color="success"
            type="button"
            onClick={this.onSave}
            loading={saving}
            disabled={saving}
          >
            Save
          </Button>
        </Top>
        <Scroll dates={dates}>
          <SortableList
            items={selected}
            getItemName={this.getItemName}
            onRemove={onRemove}
            lockAxis="y"
            onSortEnd={onSortEnd}
            single={single}
            shouldCancelStart={() => single}
          />
        </Scroll>
      </RightContainer>
    );
  }
}

export default Result;
