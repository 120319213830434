/**
 *
 * DataSheetsButton
 *
 */
import React, { useCallback } from 'react';

import SidebarButton from './SidebarButton';
import SheetIcon from 'components/Icons/SheetIcon';

const DataSheetsButton = ({ toggleSidebar, onClick }) => {
  const handleOnClick = useCallback(() => {
    toggleSidebar && toggleSidebar(false);
    onClick && onClick();
  }, [onClick, toggleSidebar]);

  return (
    <SidebarButton
      icon={<SheetIcon height={24} width={24} />}
      onClick={handleOnClick}
      text={'Sheets'}
      testId="datasheets-button"
      id="sidebar-sheets-button"
    />
  );
};

export default DataSheetsButton;
