import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { Query } from '@apollo/client/react/components';

import NotFound from 'routes/NotFound/Loadable';

const QUERY = gql`
  query PortalRoute {
    isLoggedIn @client
  }
`;

const PORTAL_QUERY = gql`
  query PortalRoutePortalQuery {
    currentUser {
      clientPortalOnly
    }
  }
`;

class PortalRoute extends React.PureComponent {
  renderRoute = props => {
    const Component = this.props.component;
    const Layout = this.props.layout;
    return (
      <Query query={QUERY}>
        {({ data, error, loading }) => {
          if (loading) {
            return null;
          }
          if (error) {
            return <Redirect to="/error" />;
          }
          if (!data.isLoggedIn) {
            return <Redirect to="/login" />;
          }
          return (
            <Query query={PORTAL_QUERY}>
              {({ data, loading, error }) => {
                if (loading || error) return null;

                if (!data.currentUser.clientPortalOnly) {
                  return <Redirect to="/" />;
                }

                if (Layout) {
                  return (
                    <Layout>
                      <Component {...props} />
                    </Layout>
                  );
                }
                return <Component {...props} />;
              }}
            </Query>
          );
        }}
      </Query>
    );
  };
  render() {
    const { component, layout, loc, exact, ...rest } = this.props;

    if (loc) {
      return loc.toRoute(
        {
          render: this.renderRoute,
          invalid: NotFound,
        },
        !!exact
      );
    }

    return <Route render={this.renderRoute} exact={exact} {...rest} />;
  }
}

export default PortalRoute;
