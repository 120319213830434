/**
 *
 * DatasheetModal
 *
 */
import React, { useState, useReducer, useCallback, useMemo } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import useMedia from 'hooks/useMedia';
import { sizes } from 'media';

import {
  Header,
  GroupNameTitle,
  SecondaryButtonStyle,
  PrimaryButtonStyle,
  ActionButtonsContainer,
  InfoText,
} from './styledComponents';

import DropdownButton from 'components/DropdownButton';
import Dropdown from 'components/Dropdown';

import Modal from 'components/Modal';

import List from './List';
import ChooseDatasheetType from './ChooseDatasheetType';
import CreateReachDatasheet from './CreateReachDatasheet';
import CreateExcelDatasheet from './CreateExcelDatasheet';
// import MyTemplates from './Library';
import MyTemplates from './LibraryNew';
import useCompanyId from 'hooks/useCompanyId';
import GoogleAccountList from './GoogleComponents/GoogleAccountList';
import GoogleSheetSuccess from './GoogleComponents/GoogleSheetSuccess';
import GoogleSheetErrors from './GoogleComponents/GoogleSheetErrors';
import ExcelSheetSuccess from './GoogleComponents/ExcelSheetSuccess';
import ExcelSheetErrors from './GoogleComponents/ExcelSheetErrors';
import HideScrollbar from 'components/HideScrollbar';
import { DEV, STAGING } from 'environment';
import { showConfirm } from '../../utils/popups';

import { Clearfix } from './common-styled-components';
import CreateDatasheetForm from 'components/CreateDatasheetForm';
import { clearDataPickerList } from 'utils/cache';

function reducer(state, action) {
  switch (action.type) {
    case 'CHANGE_TAB':
      return {
        ...state,
        tab: action.tab,
      };
    case 'TEMPLATES':
      return {
        ...state,
        page: 'TEMPLATES',
        width: 500,
        title: 'My Templates',
      };
    case 'DATASHEET_LIST':
      return {
        ...state,
        page: 'DATASHEET_LIST',
        width: 500,
        title: '',
      };
    case 'CREATE_REACH_DATASHEET':
      return {
        ...state,
        page: 'CREATE_REACH_DATASHEET',
        title: 'Create Data Sheet',
        width: 400,
      };
    case 'CREATE_EXCEL_DATASHEET':
      return {
        ...state,
        page: 'CREATE_EXCEL_DATASHEET',
        title: 'Create EXCEL Data Sheet',
        width: 400,
      };
    case 'CREATE_LEGACY_DATASHEET':
      return {
        ...state,
        page: 'CREATE_LEGACY_DATASHEET',
        title: 'Create Data Sheet (Legacy)',
        width: 400,
      };
    case 'CHOOSE_DATASHEET_TYPE':
      return {
        ...state,
        page: 'CHOOSE_DATASHEET_TYPE',
        width: 800,
        title: 'New Sheet',
      };
    case 'GOOGLE_SHEET_SUCCESS':
      return {
        ...state,
        page: 'GOOGLE_SHEET_SUCCESS',
        width: 800,
        googleSpreadsheetSuccess: action.googleSpreadsheet,
        emailSuccess: action.email,
        title: 'Success',
      };
    case 'GOOGLE_SHEET_ERRORS':
      return {
        ...state,
        page: 'GOOGLE_SHEET_ERRORS',
        width: 800,
        googleSpreadsheetErrors: {
          errors: action.errors,
          fileId: action.fileId,
          token: action.token,
          googleAccountId: action.googleAccountId,
          googleSpreadsheetId: action.googleSpreadsheetId,
          sync: action.sync || false,
          email: action.email,
        },
        title: 'Invalid Data',
      };
    case 'EXCEL_SHEET_SUCCESS':
      return {
        ...state,
        page: 'EXCEL_SHEET_SUCCESS',
        width: 800,
        googleSpreadsheetSuccess: action.googleSpreadsheet,
        title: 'Success',
      };
    case 'EXCEL_SHEET_ERRORS':
      return {
        ...state,
        page: 'EXCEL_SHEET_ERRORS',
        width: 800,
        googleSpreadsheetErrors: {
          errors: action.errors,
          googleSpreadsheetId: action.googleSpreadsheetId,
        },
        title: 'Invalid Data',
      };
    case 'GOOGLE_ACCOUNT_LIST':
      return {
        ...state,
        page: 'GOOGLE_ACCOUNT_LIST',
        width: 600,
        title: 'Choose Google Account',
      };
  }
}
const initialState = {
  page: 'DATASHEET_LIST',
  width: 500,
  title: '',
  tab: 0,
};

const QUERY = gql`
  query DataSheetModal($companyId: Int!) {
    company(id: $companyId) {
      id
      name
      spreadsheets {
        id
        name
        lastModified
      }
    }
  }
`;

const DatasheetModal = (props) => {
  const { show, onClose } = props;

  const companyId = useCompanyId();
  const client = useApolloClient();
  const { data, loading, error } = useQuery(QUERY, {
    variables: {
      companyId,
    },
    skip: !companyId || !show,
  });

  const tabs = useMemo(() => {
    const result = ['Data Sheets', 'Google Sheets'];
    if (
      DEV ||
      STAGING ||
      (data && data.company && data.company.spreadsheets.length)
    ) {
      result.push('Data Sheets (Legacy)');
    }

    return result;
  }, [data]);
  const minimal = useMedia([`(min-width: ${sizes.tablet}px)`], [false], true);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [state, dispatch] = useReducer(reducer, initialState);
  const [creating, setCreating] = useState(false);
  const [excelFile, setExcelFile] = useState(null);
  const [googleSpreadsheetId, setGoogleSpreadsheetId] = useState(null);
  const [excelUpdate, setExcelUpdate] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [hideModalBackButton, setHideModalBackButton] = useState(false);

  const onConnect = useCallback(() => {
    dispatch({ type: 'GOOGLE_ACCOUNT_LIST' });
  }, [dispatch]);

  const handleClose = useCallback(() => {
    dispatch({ type: 'DATASHEET_LIST' });
    setShowCreateModal(false);
    onClose();
  }, [onClose, dispatch, setShowCreateModal]);

  const handleExcelSuccess = useCallback(
    async (googleSpreadsheet) => {
      dispatch({ type: 'EXCEL_SHEET_SUCCESS', googleSpreadsheet });
      if (
        excelUpdate &&
        (await showConfirm({
          title: 'Update Successful',
          text: 'Would you like to refresh?',
        }))
      ) {
        window.location.reload();
      } else {
        clearDataPickerList(client.cache, companyId);
      }
    },
    [client, companyId, dispatch, excelUpdate]
  );

  const handleExcelErrors = useCallback(
    (errors, selectedExcelFile) => {
      setExcelFile(selectedExcelFile);
      dispatch({ type: 'EXCEL_SHEET_ERRORS', errors });
    },
    [dispatch, setExcelFile]
  );

  const handleUpdateExcelDatasheet = useCallback(
    (gsSpreadsheetId) => {
      setExcelUpdate(true);
      setGoogleSpreadsheetId(gsSpreadsheetId);
      dispatch({
        type: 'CREATE_EXCEL_DATASHEET',
      });
    },
    [dispatch, setExcelUpdate, setGoogleSpreadsheetId]
  );

  const handleResetExcelStates = useCallback(() => {
    dispatch({ type: 'DATASHEET_LIST' });
    setExcelUpdate(false);
    setExcelFile(null);
    setGoogleSpreadsheetId(null);
    setHideModalBackButton(false);
  }, [setExcelUpdate, setExcelFile, setGoogleSpreadsheetId]);

  const {
    page,
    googleSpreadsheetErrors,
    googleSpreadsheetSuccess,
    emailSuccess,
  } = state;

  if (!data || loading || error) {
    return null;
  }

  return (
    <Modal
      show={show}
      minimal={minimal}
      title={'Data Sheets'}
      width={1000}
      fullscreen
      onClose={onClose}
      marginTop={50}
      hideHeader
      onExited={() => setCreating(false)}
    >
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Header>
          <GroupNameTitle>{'Data Sheets'}</GroupNameTitle>
          {!minimal && !hideModalBackButton && (
            <ActionButtonsContainer>
              <SecondaryButtonStyle
                color="default"
                onClick={() => {
                  handleResetExcelStates();
                  if (page === 'DATASHEET_LIST') {
                    onClose();
                  } else {
                    dispatch({ type: 'DATASHEET_LIST' });
                  }
                }}
                outline
              >
                {page === 'DATASHEET_LIST' ? 'Cancel' : 'Back'}
              </SecondaryButtonStyle>
              {page === 'DATASHEET_LIST' && (
                <Dropdown
                  trigger={
                    <PrimaryButtonStyle color="primary" outline>
                      Create New
                    </PrimaryButtonStyle>
                  }
                  offsetY={35}
                  offsetX={-80}
                >
                  {({ closeDropdown }) => {
                    return (
                      <>
                        <DropdownButton
                          onClick={() => {
                            setCreating(false);
                            setShowCreateModal(true);
                            closeDropdown();
                          }}
                          style={{ color: '#839bb3' }}
                        >
                          Create Data Sheet
                        </DropdownButton>
                        <DropdownButton
                          onClick={() => {
                            setCreating(false);
                            onConnect();
                            closeDropdown();
                          }}
                          style={{ color: '#839bb3' }}
                        >
                          Connect Google Sheet
                        </DropdownButton>
                        <DropdownButton
                          onClick={() => {
                            setCreating(false);
                            dispatch({
                              type: 'CREATE_EXCEL_DATASHEET',
                            });
                            closeDropdown();
                          }}
                          style={{ color: '#839bb3' }}
                        >
                          Upload Excel Sheet
                        </DropdownButton>
                        <DropdownButton
                          onClick={() => {
                            dispatch({ type: 'TEMPLATES' });
                            closeDropdown();
                          }}
                          style={{ color: '#839bb3' }}
                        >
                          My Templates
                        </DropdownButton>
                      </>
                    );
                  }}
                </Dropdown>
              )}
            </ActionButtonsContainer>
          )}
        </Header>
        <InfoText>
          Data Sheets are designed to store non-financial data. They are not
          optimized to be used for remapping entire chart of accounts.
        </InfoText>
        {/* <Modal show={show} width={960} onClose={onClose} title={titleFormatted}> */}
        {page === 'TEMPLATES' && (
          <MyTemplates dispatch={dispatch} {...props} onClose={handleClose} />
        )}
        {page === 'DATASHEET_LIST' && (
          <List
            cache={client.cache}
            dispatch={dispatch}
            state={state}
            onClose={handleClose}
            tabs={tabs}
            show={show}
            handleUpdateExcelDatasheet={handleUpdateExcelDatasheet}
          />
        )}
        {page === 'CREATE_REACH_DATASHEET' && (
          <CreateReachDatasheet
            dispatch={dispatch}
            {...props}
            onClose={handleClose}
            showCreateModal={showCreateModal}
            onCloseCreateModal={() => setShowCreateModal(false)}
          />
        )}
        {page === 'CREATE_EXCEL_DATASHEET' && (
          <CreateExcelDatasheet
            dispatch={dispatch}
            {...props}
            onClose={handleClose}
            onExcelSuccess={handleExcelSuccess}
            onExcelError={handleExcelErrors}
            googleSpreadsheetId={googleSpreadsheetId}
          />
        )}
        {page === 'CREATE_LEGACY_DATASHEET' && (
          <CreateReachDatasheet
            dispatch={dispatch}
            {...props}
            onClose={handleClose}
            legacy
          />
        )}
        {page === 'CHOOSE_DATASHEET_TYPE' && (
          <ChooseDatasheetType
            dispatch={dispatch}
            {...props}
            onClose={handleClose}
          />
        )}
        {page === 'GOOGLE_SHEET_SUCCESS' && (
          <GoogleSheetSuccess
            dispatch={dispatch}
            {...props}
            googleSpreadsheetSuccess={googleSpreadsheetSuccess}
            emailSuccess={emailSuccess}
            onClose={handleClose}
            onResetExcelStates={handleResetExcelStates}
            setHideModalBackButton={setHideModalBackButton}
          />
        )}
        {page === 'GOOGLE_SHEET_ERRORS' && (
          <GoogleSheetErrors
            dispatch={dispatch}
            {...props}
            googleSpreadsheetErrors={googleSpreadsheetErrors}
            onClose={handleClose}
            excelFile={excelFile}
          />
        )}
        {page === 'EXCEL_SHEET_SUCCESS' && (
          <ExcelSheetSuccess
            dispatch={dispatch}
            {...props}
            googleSpreadsheetSuccess={googleSpreadsheetSuccess}
            onClose={handleClose}
            excelUpdate={excelUpdate}
            onResetExcelStates={handleResetExcelStates}
            setHideModalBackButton={setHideModalBackButton}
          />
        )}
        {page === 'EXCEL_SHEET_ERRORS' && (
          <ExcelSheetErrors
            dispatch={dispatch}
            {...props}
            googleSpreadsheetErrors={googleSpreadsheetErrors}
            onClose={handleClose}
            excelFile={excelFile}
            googleSpreadsheetId={googleSpreadsheetId}
            onExcelSuccess={handleExcelSuccess}
          />
        )}
        {page === 'GOOGLE_ACCOUNT_LIST' && (
          <GoogleAccountList
            dispatch={dispatch}
            {...props}
            onClose={handleClose}
          />
        )}
        <>
          <Modal
            show={showCreateModal}
            title={'Create New Datasheet'}
            onClose={() => setShowCreateModal(false)}
            width={500}
            marginTop={'100px'}
            zIndex={1005}
          >
            <CreateDatasheetForm
              onCompleted={() => {
                setShowCreateModal(false);
                onClose();
              }}
              autocomplete="off"
            />
          </Modal>
          <Clearfix />
        </>
        {show && <HideScrollbar />}
      </div>
    </Modal>
  );
};
export default DatasheetModal;
