/**
 *
 * RegisterPlanForm
 *
 */
import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Form } from 'informed';

import TextField from 'components/TextField';
import CheckboxField from 'components/CheckboxField';
import { isEmail, isRequired, isPassword, isPhone } from 'validation';
import media from 'media';
import ErrorText from './ErrorText';
import Button from './Button';
import Link from 'components/Link';
import { captureException } from 'utils/sentry';
import { showError } from 'utils/popups';
import { validateEmail, register } from 'auth';
import OauthButtons from 'components/OauthButtons';
import useRecaptcha from 'hooks/useRecaptcha';
import { DEV, STAGING } from 'environment';
import PasswordToggle from 'components/PasswordToggle';

const isChecked = (value) => (!value ? 'Must accept terms' : undefined);

const Div = styled.div`
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 10rem;
`;

const P = styled.p`
  margin-bottom: 14px;
  font-weight: bold;
  font-size: 18px;
`;

const Row = styled.div`
  width: auto;
  display: block;
  margin: 0 20px;
  ${media.tablet`
    display: flex;
    margin: 0 0 11px 0;
  `}
`;

const FieldWrapper = styled.div`
  position: relative;
  text-align: left;

  ${media.tablet`
    &:first-child {
      margin-right: 6px;
    }
    &:last-child {
      margin-left: 6px;
    }
    width: calc(50% - 6px); 
  `}
`;

const BackSection = styled.div`
  margin-top: 1rem;
  text-align: center;
`;

const BottomText = styled.p`
  font-weight: bold;
`;

const StyledLink = styled(Link)``;

const StyledTextField = styled(TextField)`
  height: 41px;
  letter-spacing: 1.43px;
  margin-bottom: 6px;
  width: 100%;
  ${media.tablet`
    width: calc(50% - 6px);
    margin-bottom: 0;
    &:first-child {
      margin-right: 6px;
    }
    &:last-child {
      margin-left: 6px;
    }
  
    ${FieldWrapper} & {
      width: 100%;
      margin: 0;
    }
  
  `}
`;

const Bottom = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

const SubmitButton = styled(Button)`
  margin-top: 1rem;
`;

const matchValidation = (value, values) => {
  return values.password !== value ? 'Passwords must match' : undefined;
};

const RegisterPlanForm = ({
  onSuccess,
  planName,
  isAnnual,
  pricingGroup,
  salesRegistration = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [formApi, setFormApi] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const getApi = useCallback((api) => setFormApi(api), []);
  const [siteKey, setSiteKey] = useState(null);
  const [recaptchaLoading, recaptchaToken, KeyInput] = useRecaptcha(
    'signup',
    (value) => setSiteKey(value)
  );

  const handleSubmit = useCallback(
    async (values) => {
      if (!recaptchaToken) {
        console.error('Recaptcha token not found');
        return;
      }

      try {
        setLoading(true);

        const { error } = await validateEmail(values.email);
        if (error) {
          setLoading(false);

          return formApi.setError('email', error);
        }

        let referral = '';
        if (window.Rewardful) {
          referral = window.Rewardful.referral;
        }

        const result = await register({
          ...values,
          pricingGroup,
          referral,
          salesRegistration,
          recaptchaToken,
          recaptchaSiteKey: siteKey,
        });

        const { errors } = result;

        if (errors) {
          setLoading(false);

          return showError({ text: errors[0].msg });
        }

        onSuccess && onSuccess();
      } catch (err) {
        setLoading(false);
        captureException(err, true);
      }
    },
    [
      formApi,
      onSuccess,
      pricingGroup,
      recaptchaToken,
      salesRegistration,
      siteKey,
    ]
  );

  const theme =
    localStorage.getItem('theme') ||
    window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'DARK'
      : 'LIGHT' || 'LIGHT';

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        getApi={getApi}
        initialValues={{
          theme,
          planName,
          isAnnual,
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          company: '',
          password: '',
          confirmPassword: '',
        }}
      >
        {({ formState }) => {
          const { invalid, pristine } = formState;
          const incomplete = Object.values(formState.values).some(
            (value) => value === ''
          );
          return (
            <>
              <Div>
                <P>Create Your Account</P>
                <Row>
                  <FieldWrapper>
                    <StyledTextField
                      type="text"
                      field="firstName"
                      placeholder="FIRST NAME*"
                      validate={isRequired}
                      validateOnChange
                      autoComplete="given-name"
                      maxLength={30}
                      data-1p-ignore="false"
                    />
                    <ErrorText field="firstName" />
                  </FieldWrapper>
                  <FieldWrapper>
                    <StyledTextField
                      type="text"
                      field="lastName"
                      placeholder="LAST NAME*"
                      validate={isRequired}
                      validateOnChange
                      autoComplete="family-name"
                      maxLength={30}
                      data-1p-ignore="false"
                    />
                    <ErrorText field="lastName" />
                  </FieldWrapper>
                </Row>
                <Row>
                  <FieldWrapper>
                    <StyledTextField
                      type="tel"
                      field="phone"
                      placeholder="PHONE*"
                      autoComplete="tel"
                      validateOnChange
                      validate={isPhone}
                      maxLength={30}
                      validateOnBlur
                      data-1p-ignore="false"
                    />
                    <ErrorText field="phone" />
                  </FieldWrapper>
                  <FieldWrapper>
                    <StyledTextField
                      type="text"
                      field="email"
                      placeholder="EMAIL*"
                      validate={isEmail}
                      validateOnChange
                      autoComplete="email username"
                      keepState
                      validateOnBlur
                      data-1p-ignore="false"
                    />
                    <ErrorText field="email" maxLength={40} />
                  </FieldWrapper>
                </Row>
                <Row>
                  <FieldWrapper>
                    <StyledTextField
                      field="company"
                      type="text"
                      id="login-company"
                      placeholder="COMPANY*"
                      validate={isRequired}
                      validateOnBlur
                      autoComplete="organization"
                      maxLength={150}
                      data-1p-ignore="false"
                    />
                    <ErrorText field="company" />
                  </FieldWrapper>
                  <FieldWrapper>
                    <PasswordToggle
                      show={showPassword}
                      onClick={() => setShowPassword(!showPassword)}
                      size={1.25}
                    >
                      <StyledTextField
                        type={showPassword ? 'text' : 'password'}
                        field="password"
                        placeholder="PASSWORD*"
                        validate={isPassword}
                        validateOnChange
                        autoComplete="new-password"
                        notify={['confirmPassword']}
                        data-1p-ignore="false"
                      />
                    </PasswordToggle>
                    <ErrorText field="password" />
                  </FieldWrapper>
                </Row>
                <Row>
                  <FieldWrapper></FieldWrapper>
                  <FieldWrapper>
                    <PasswordToggle
                      show={showConfirmPassword}
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      size={1.25}
                    >
                      <StyledTextField
                        type={showConfirmPassword ? 'text' : 'password'}
                        field="confirmPassword"
                        placeholder="CONFIRM PASSWORD*"
                        validate={matchValidation}
                        validateOnChange
                        autoComplete="new-password"
                        notify={['password']}
                        data-1p-ignore="false"
                      />
                    </PasswordToggle>
                    <ErrorText field="confirmPassword" />
                  </FieldWrapper>
                </Row>
                <Bottom>
                  <CheckboxField
                    field="agree"
                    validate={isChecked}
                    validateOnChange
                    validateOnMount
                  >
                    I agree to the{' '}
                    <a
                      href="//reachreporting.com/terms"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      terms and conditions
                    </a>
                  </CheckboxField>
                  <SubmitButton
                    disabled={
                      incomplete ||
                      invalid ||
                      pristine ||
                      loading ||
                      recaptchaLoading
                    }
                    type="submit"
                  >
                    Register
                  </SubmitButton>
                </Bottom>
              </Div>
              <TextField type="hidden" field="theme" data-1p-ignore="false" />
              <TextField
                type="hidden"
                field="planName"
                data-1p-ignore="false"
              />
              <TextField
                type="hidden"
                field="isAnnual"
                data-1p-ignore="false"
              />
              <OauthButtons />
              <BackSection>
                <BottomText>
                  Already have an account?{' '}
                  <StyledLink to="/login">Sign in here.</StyledLink>
                </BottomText>
              </BackSection>
            </>
          );
        }}
      </Form>
      {KeyInput && (STAGING || DEV) && <KeyInput />}
    </>
  );
};

export default RegisterPlanForm;
