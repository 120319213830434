import styled from 'styled-components';

export const Button = styled.button`
  background-color: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  position: absolute;
  right: 10px;
`;

export const Container = styled.div<{ maxWidth?: number }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}
  width: 100%;
`;
