/**
 *
 * OauthButtons
 *
 */
import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';

import googleBtn from './google.svg';
import googleHoverBtn from './google-hover.svg';
import xeroBtn from './xero.svg';
import xeroHoverBtn from './xero-hover.svg';
import intuitBtn from './intuit.svg';
import intuitHoverBtn from './intuit-hover.svg';
import { API_URL } from 'environment';
import { launchPopup } from 'utils/popups';
// import microsoftBtn from './microsoft.png';

const Div = styled.div`
  text-align: center;
`;

const Button = styled.button`
  margin-bottom: 0.75rem;
  background-image: url(${(props) => props.image});
  &:after {
    display: block;
    content: '';
    background-image: url(${(props) => props.hoverImage});
  }
  &:hover {
    background-image: url(${(props) => props.hoverImage});
  }
  width: 201px;
  height: 48px;
  margin-right: 1rem;
  margin-left: 1rem;
  ${(props) =>
    props.hasBorder &&
    css`
      border: 1px solid #ccc;
      border-radius: 6px;
    `}
`;

const OrSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  justify-content: center;
`;

const OrLine = styled.hr`
  border: 1px solid ${(props) => props.theme.body.color};
  margin: 0 10px;
  max-width: 300px;
  width: 100%;
`;

const OrText = styled.div`
  text-align: center;
  font-weight: 600;
`;

const OauthButtons = (props) => {
  const {
    className,
    hideGoogle,
    hideIntuit,
    hideXero,
    registerValues,
    popup,
    requiredAuthenticationSource,
  } = props;

  const params = useMemo(() => {
    const values = registerValues ? { ...registerValues } : {};
    if (popup) {
      values.popup = true;
    }
    if (window.Rewardful && window.Rewardful.referral) {
      values.referral = window.Rewardful.referral;
    }
    if (Object.keys(values).length) {
      return `?${new URLSearchParams(values)}`;
    }
    return '';
  }, [popup, registerValues]);

  const handleGoogle = useCallback(() => {
    if (popup) {
      return launchPopup(`${API_URL}/auth/google${params}`);
    }
    window.location.href = `${API_URL}/auth/google${params}`;
  }, [params, popup]);
  const handleXero = useCallback(() => {
    if (popup) {
      return launchPopup(`${API_URL}/auth/xero${params}`);
    }
    window.location.href = `${API_URL}/auth/xero${params}`;
  }, [params, popup]);
  const handleIntuit = useCallback(() => {
    if (popup) {
      return launchPopup(`${API_URL}/auth/intuit${params}`);
    }
    window.location.href = `${API_URL}/auth/intuit${params}`;
  }, [params, popup]);

  return (
    <Div className={className}>
      {!requiredAuthenticationSource && (
        <OrSection>
          <OrLine />
          <OrText>OR</OrText>
          <OrLine />
        </OrSection>
      )}
      {!hideGoogle && (
        <Button
          onClick={handleGoogle}
          image={googleBtn}
          hoverImage={googleHoverBtn}
          hasBorder={popup}
          id="google-oauth-button"
        />
      )}
      {!hideIntuit && (
        <Button
          onClick={handleIntuit}
          image={intuitBtn}
          hoverImage={intuitHoverBtn}
          id="intuit-oauth-button"
        />
      )}
      {!hideXero && (
        <Button
          onClick={handleXero}
          image={xeroBtn}
          hoverImage={xeroHoverBtn}
          id="xero-oauth-button"
        />
      )}
      {/* <button onClick={handleMicrosoft}>
        <img src={microsoftBtn} />
  </button> */}
    </Div>
  );
};

export default OauthButtons;
