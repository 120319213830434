import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { GOOGLE_SHEET_QUERY, CREATE_GOOGLE_SHEET } from '../mutations';
import useCompanyId from 'hooks/useCompanyId';
import { captureException } from 'logrocket';
import produce from 'immer';
import { clearDataPickerList } from 'utils/cache';

export default function useCreateGoogleSheet() {
  const [create] = useMutation(CREATE_GOOGLE_SHEET);
  const [loading, setLoading] = useState(false);
  const companyId = useCompanyId();

  const onCreate = useCallback(
    ({ fileId, googleAccountId, dispatch, email, ignoreErrors }) => {
      setLoading(true);
      create({
        variables: {
          companyId,
          googleSpreadsheetExternalId: fileId,
          googleAccountId,
          ignoreErrors,
        },
        update: (cache, { data: { connectGoogleSpreadsheet } }) => {
          if (connectGoogleSpreadsheet.success) {
            const data = cache.readQuery({
              query: GOOGLE_SHEET_QUERY,
              variables: {
                companyId,
              },
            });

            cache.writeQuery({
              query: GOOGLE_SHEET_QUERY,
              variables: {
                companyId,
              },
              data: produce(data, (draft) => {
                const existing = draft.company.googleSpreadsheets.find(
                  (item) =>
                    item.id === connectGoogleSpreadsheet.googleSpreadsheet.id
                );

                if (!existing) {
                  draft.company.googleSpreadsheets = [
                    ...draft.company.googleSpreadsheets,
                    connectGoogleSpreadsheet.googleSpreadsheet,
                  ];
                }
              }),
            });

            clearDataPickerList(cache, companyId);
          }
        },
      })
        .then((result) => {
          if (result.data) {
            const { success, errors, googleSpreadsheet } =
              result.data.connectGoogleSpreadsheet;

            if (errors) {
              dispatch({
                type: 'GOOGLE_SHEET_ERRORS',
                errors,
                fileId,
                googleAccountId,
                email,
              });
            } else if (success) {
              dispatch({
                type: 'GOOGLE_SHEET_SUCCESS',
                googleSpreadsheet,
                email,
              });
            }
            setLoading(false);
          }
        })
        .catch((err) => {
          captureException(err);
          setLoading(false);
        });
    },
    [companyId, create]
  );

  return { loading, onCreate };
}
