/**
 *
 * FilterBox
 *
 */
import React, { useMemo } from 'react';

import { useFilterBoxState } from './hooks';
import {
  Input,
  ListBox,
  ListWrapper,
  StyledCheckbox,
  Top,
} from './styledComponents';
import Checkbox from './Checkbox';

export type ItemType = 'class' | 'department' | 'company';

export interface FilterItem {
  value: string | number;
  id: string | number;
  label: string;
  name?: string;
  disabled: boolean;
  parentId?: string | number;
  type?: ItemType;
  abbreviation?: string;
}

export interface ParentItem extends FilterItem {
  classesOrDepartments?: Array<FilterItem>;
}

interface FilterBoxProps {
  title?: string;
  onChangeSearch?: React.ChangeEventHandler<HTMLInputElement>;
  search?: string;
  items: Array<ParentItem>;
  selected: Array<any>;
  onSelectAll?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDeselectAll?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  partialSelected?: Array<any>;
  className?: string;
  emptyCheckbox?: string;
  halfWidth?: boolean;
  width?: number;
  style?: React.CSSProperties;
  isConsolidation?: boolean;
  limitReached?: boolean;
  disableSubs?: boolean;
}

const FilterBox = ({
  title,
  items = [],
  search,
  onChangeSearch,
  selected = [],
  onSelectAll,
  onDeselectAll,
  onChange,
  partialSelected = [],
  className,
  emptyCheckbox,
  halfWidth = false,
  width,
  style,
  isConsolidation = false,
  limitReached,
  disableSubs,
}: FilterBoxProps): React.ReactElement => {
  const selectAllChecked = useMemo(() => {
    if (!selected || !items) {
      return false;
    }

    return !items.some(
      (item) =>
        !item.disabled &&
        !selected.includes(item.value || item.id) &&
        !partialSelected.includes(item.value || item.id) &&
        !(disableSubs && !!item.parentId)
    );
  }, [selected, items, partialSelected, disableSubs]);

  return (
    <ListWrapper
      className={className}
      halfWidth={halfWidth}
      width={width || 0}
      style={style}
    >
      <Top>
        {title && <div>{title}</div>}
        {search !== undefined && (
          <Input
            onChange={onChangeSearch}
            value={search}
            placeholder="Search"
            autoComplete="off"
            data-1p-ignore
          />
        )}
      </Top>
      <ListBox>
        {emptyCheckbox && (
          <div>
            <StyledCheckbox
              checked={!selected.length}
              styleAsDisabled
              onChange={onDeselectAll}
              disabled={limitReached && !selected.length}
            >
              {emptyCheckbox}
            </StyledCheckbox>
          </div>
        )}
        {onSelectAll && (
          <>
            <div>
              <StyledCheckbox
                checked={selectAllChecked}
                onChange={onSelectAll}
                disabled={limitReached && !selectAllChecked}
              >
                Select All
              </StyledCheckbox>
            </div>
            {!isConsolidation && (
              <div>
                {items.map((item) => (
                  <Checkbox
                    key={item.value || item.id}
                    item={item}
                    selected={selected}
                    partialSelected={partialSelected}
                    onChange={onChange}
                    disabled={
                      (disableSubs && !!item.parentId) ||
                      (limitReached &&
                        !(
                          selected?.some(
                            // eslint-disable-next-line eqeqeq
                            (value) => value === item.value || value === item.id
                          ) ||
                          (item.disabled && !isConsolidation) ||
                          partialSelected.includes(item.value || item.id)
                        ))
                    }
                  />
                ))}
              </div>
            )}
          </>
        )}
        {isConsolidation && (
          <>
            {items.map((item) => (
              <React.Fragment key={item.value || item.id}>
                <Checkbox
                  item={item}
                  selected={selected}
                  partialSelected={partialSelected}
                  onChange={onChange}
                  isConsolidation={isConsolidation}
                  disabled={
                    limitReached &&
                    !(
                      selected?.some(
                        // eslint-disable-next-line eqeqeq
                        (value) => value === item.value || value === item.id
                      ) ||
                      (item.disabled && !isConsolidation) ||
                      partialSelected.includes(item.value || item.id)
                    )
                  }
                />
                {item.classesOrDepartments?.map((classOrDepartment) => (
                  <Checkbox
                    key={classOrDepartment.value || classOrDepartment.id}
                    item={classOrDepartment}
                    selected={selected}
                    partialSelected={partialSelected}
                    onChange={onChange}
                    indent
                    isConsolidation={isConsolidation}
                    disabled={
                      limitReached &&
                      !(
                        selected?.some(
                          // eslint-disable-next-line eqeqeq
                          (value) =>
                            value === classOrDepartment.value ||
                            value === classOrDepartment.id
                        ) ||
                        (classOrDepartment.disabled && !isConsolidation) ||
                        partialSelected.includes(
                          classOrDepartment.value || classOrDepartment.id
                        )
                      )
                    }
                  />
                ))}
              </React.Fragment>
            ))}
          </>
        )}
        {!items.length && <div>No results</div>}
      </ListBox>
    </ListWrapper>
  );
};

export { useFilterBoxState };
export default FilterBox;
