/**
 *
 * Minimal
 *
 */

import React from 'react';
import { Query, Mutation } from '@apollo/client/react/components';
import gql from 'graphql-tag';
import styled, { ThemeProvider, withTheme } from 'styled-components';

import Header from 'components/Header';
import CreateFirm from 'modals/CreateFirm';
import ManageUsers from 'modals/ManageUsers';
import SetFirmName from 'modals/SetFirmName';
import TransferCompany from 'modals/TransferCompany';
import TrainingVideos from 'modals/TraningVideos';
import ThemeSidebar from 'components/ThemeSidebar';
import GlobalStyles from 'components/GlobalStyles';
import ErrorCodePopup from 'components/ErrorCodePopup';

import colors from 'themes/colors';
import lightTheme from 'themes/quantum';
import darkTheme from 'themes/dark';

import { merge } from 'utils/arrayUtils';
import Masquerade from 'components/Masquerade';
import { setTheme } from 'utils/theme';

const mergedDarkTheme = merge(lightTheme, darkTheme);

const Content = styled.div`
  padding-top: ${({ headerHeight }) => headerHeight}px;
  transition: padding-top 250ms ease-in-out;
`;

const QUERY = gql`
  query MinimalLayout {
    showCreateFirmModal @client
    showCollaboratorsModal @client
    collaboratorsModalCompanyId @client
    showSetFirmNameModal @client
    setFirmNameModalFirmId @client
    firmManagersModalFirmId @client
    showTransferCompanyModal @client
    transferCompanyId @client
    showTrainingVideosModal @client
    currentUser {
      theme
      clientPortalOnly
    }
  }
`;
const CLOSE = gql`
  mutation CloseCreateFirm {
    setShowCreateFirmModal(show: false) @client
  }
`;
const CLOSE_COLLABORATORS = gql`
  mutation CloseCreateFirm {
    setShowCollaboratorsModal(show: false) @client
  }
`;
const CLOSE_SET_FIRM_NAME = gql`
  mutation CloseSetFirmName {
    setShowSetFirmNameModal(show: false) @client
  }
`;
const CLOSE_TRANSFER_COMPANY = gql`
  mutation CloseTransferCompany {
    setShowTransferCompanyModal(show: false) @client
  }
`;

const CLOSE_TRAINING = gql`
  mutation CloseTrainingVideosMinimal {
    setShowTrainingVideosModal(show: false) @client
  }
`;

const themes = {};

colors.dark.forEach((t) => {
  themes[t.id] = t;
});
colors.light.forEach((t) => {
  themes[t.id] = t;
});

@withTheme
class Minimal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      headerOpen: false,
      themeSidebarOpen: false,
      headerHeight: 0,
    };
    this.headerRef = null;
    this.setHeaderRef = this.setHeaderRef.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }
  componentDidMount() {
    this.updateHeight();
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  setHeaderRef(node) {
    if (node !== null) {
      this.setState({ headerHeight: node.clientHeight });
      this.headerRef = node;
    }
  }
  onMaintenanceBannerLoad = (displayBanner) => {
    if (this.headerRef && displayBanner) {
      this.setState({
        headerHeight: this.headerRef.clientHeight,
      });
    }
  };
  onMaintenanceBannerClose = () => {
    if (this.headerRef) {
      this.setState({
        headerHeight: this.headerRef.clientHeight,
      });
    }
  };
  handleResize() {
    if (this.headerRef) {
      this.setState({ headerHeight: this.headerRef.clientHeight });
    }
  }
  handleToggleHeader = () => {
    this.setState({
      headerOpen: !this.state.headerOpen,
    });
  };
  handleToggleThemeSidebar = () => {
    this.setState({
      themeSidebarOpen: !this.state.themeSidebarOpen,
    });
  };
  updateHeight() {
    if (!this.headerRef) return;

    const height = this.headerRef.clientHeight;
    this.setState({
      headerHeight: height,
    });
  }
  render() {
    const { theme, videoTours = true } = this.props;

    return (
      <Query query={QUERY}>
        {({ data, loading, error }) => {
          if (loading || error) {
            return null;
          }

          setTheme(data.currentUser.theme);

          const dark = data.currentUser.theme === 'DARK';
          const newTheme = dark ? mergedDarkTheme : theme;

          return (
            <ThemeProvider theme={newTheme}>
              <div>
                <GlobalStyles />
                <Header
                  sidebar
                  sidebarCollapsed
                  minimal
                  logo
                  open={this.state.headerOpen}
                  onToggleThemeSidebar={this.handleToggleThemeSidebar}
                  videoTours={videoTours}
                  ref={this.setHeaderRef}
                  onMaintenanceBannerLoad={this.onMaintenanceBannerLoad}
                  onMaintenanceBannerClose={this.onMaintenanceBannerClose}
                  hideHomeButton
                  hideKnowledgeBaseButton={data.currentUser.clientPortalOnly}
                  hideVideoTutorialButton={data.currentUser.clientPortalOnly}
                />
                <Content headerHeight={this.state.headerHeight}>
                  {this.props.children}
                </Content>

                <ThemeSidebar
                  open={this.state.themeSidebarOpen}
                  onClose={this.handleToggleThemeSidebar}
                  selected={data.theme}
                />
                <Mutation mutation={CLOSE}>
                  {(close) => (
                    <CreateFirm
                      show={data.showCreateFirmModal}
                      onClose={close}
                    />
                  )}
                </Mutation>
                <Mutation mutation={CLOSE_COLLABORATORS}>
                  {(close) => (
                    <ManageUsers
                      show={data.showCollaboratorsModal}
                      companyId={data.collaboratorsModalCompanyId}
                      onClose={close}
                    />
                  )}
                </Mutation>
                <Mutation mutation={CLOSE_SET_FIRM_NAME}>
                  {(close) => (
                    <SetFirmName
                      show={data.showSetFirmNameModal}
                      firmId={data.setFirmNameModalFirmId}
                      onClose={close}
                    />
                  )}
                </Mutation>
                <Mutation mutation={CLOSE_TRANSFER_COMPANY}>
                  {(close) => (
                    <TransferCompany
                      show={data.showTransferCompanyModal}
                      companyId={data.transferCompanyId}
                      onClose={close}
                    />
                  )}
                </Mutation>
                <Mutation mutation={CLOSE_TRAINING}>
                  {(close) => (
                    <TrainingVideos
                      show={data.showTrainingVideosModal}
                      onClose={close}
                    />
                  )}
                </Mutation>

                <ErrorCodePopup />

                <Masquerade />
              </div>
            </ThemeProvider>
          );
        }}
      </Query>
    );
  }
}

export default Minimal;
