/**
 *
 * Main
 *
 */

import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import CustomScroll from 'react-custom-scroll';
import { toast } from 'react-toastify';

import SwitchCompany from './SwitchCompany';
import WorkspaceControls from './WorkspaceControls';
import ClientPortalButton from './ClientPortalButton';
import Mutation from 'components/Mutation';

import useClientPortalOnly from 'hooks/useClientPortalOnly';

import useDemoCompany from 'hooks/useDemoCompany';
import StripePortalButton from 'components/StripePortalButton';
import useCompanyId from 'hooks/useCompanyId';
import media, { sizes } from 'media';
import useMedia from 'hooks/useMedia';
import MobileWarningModal from 'components/MobileWarningModal';
import MaintenanceBanner from 'components/MaintenanceBanner';

import useWorkspaceId from 'hooks/useWorkspaceId';
import { showInfo } from 'utils/popups';
import IconButton from './IconButton';

window.toast = toast;

const Div = styled.div`
  height: 100%;
`;

const CompanyControls = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

const linkColor = theme('mode', {
  light: '#617181',
  dark: '#d7d7d8',
});

export const linkCss = css`
  display: block;
  font-size: 14px;
  font-weight: bold;
  padding: 0;
  margin-bottom: 0.5rem;
  color: ${linkColor};
  i {
    margin-right: 0.5rem;
    color: ${linkColor};
  }
`;

const ExternalLink = styled.a`
  ${linkCss}
`;

const Button = styled.button`
  ${linkCss};
  &:hover {
    color: #40a9ff;
  }
`;

const StripeButton = styled(StripePortalButton)`
  ${linkCss}
`;

const StyledLink = styled(Link)`
  ${linkCss};
`;

const LogoutLink = styled(StyledLink)`
  color: ${(props) => props.theme.colors.danger};
  i {
    color: ${(props) => props.theme.colors.danger};
  }
`;

const Logout = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Email = styled.span`
  font-size: 14px;
  display: none;
  ${media.tablet`
    display: inline;
  `}
`;

const SUMMIT = gql`
  mutation SyncSummit {
    syncSummitCompanies {
      success
      message
    }
  }
`;

function Main(props) {
  const { onClose, toggleModal, email, stripeSubscription, isSummitUser } =
    props;
  const companyId = useCompanyId();
  const clientPortal = useClientPortalOnly();
  const workspaceId = useWorkspaceId();

  const tablet = useMedia([`(min-width: ${sizes.tablet}px)`], [false], true);
  const phone = useMedia([`(min-width: ${sizes.phone}px)`], [false], true);

  const { launchDemo } = useDemoCompany();

  const onShowUpdates = useCallback(() => {
    toggleModal('updates', true);
    onClose();
  }, [onClose, toggleModal]);

  return (
    <Div>
      <CustomScroll heightRelativeToParent="100%">
        <CompanyControls>
          <SwitchCompany onCompleted={onClose} />
          <WorkspaceControls />
        </CompanyControls>
        {!clientPortal && clientPortal !== null && !!companyId && (
          <IconButton
            onClick={() => {
              toggleModal('company', true);
              onClose();
            }}
            text="Company Settings"
            iconClass="mdi-set mdi-office-building-cog"
          />
        )}
        <IconButton
          onClick={() => {
            toggleModal('user', true);
            onClose();
          }}
          text="User Settings"
          iconClass="mdi-set mdi-account-cog"
        />
        <ClientPortalButton onClose={onClose} />
        {isSummitUser && !clientPortal && (
          <Mutation
            mutation={SUMMIT}
            onCompleted={(data) => {
              onClose();
              if (data?.syncSummitCompanies?.message) {
                showInfo({ text: data.syncSummitCompanies.message });
              } else if (data?.syncSummitCompanies?.success) {
                toast.info('Updating company info');
              }
            }}
          >
            {(onSyncSummit) => (
              <IconButton
                onClick={onSyncSummit}
                text="Sync Summit Companies"
                iconClass="mdi-set mdi-sync"
              />
            )}
          </Mutation>
        )}

        {!clientPortal && clientPortal !== null && (
          <IconButton
            onClick={onShowUpdates}
            text="What's New"
            iconClass="mdi-set mdi-gift"
          />
        )}
        {!!launchDemo &&
          !clientPortal &&
          clientPortal !== null &&
          !phone &&
          !!workspaceId && (
            <IconButton
              id="launch-demo-company-button"
              onClick={launchDemo}
              text="Launch Demo Company"
              iconClass="mdi-set mdi-domain"
            />
          )}

        {!clientPortal && tablet && (
          <>
            {/* <TrainingButton sidebar callback={() => onClose()} icon /> */}
            <ExternalLink
              href="https://knowledge.reachreporting.com/workspace-team-setup/manage"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="mdi-set mdi-account-group" />
              Team Setup Guide
            </ExternalLink>
          </>
        )}

        {!clientPortal && (
          <IconButton
            href="https://knowledge.reachreporting.com"
            target="_blank"
            text="Knowledge Center"
            iconClass="mdi-set mdi-school"
          />
        )}

        {stripeSubscription && !stripeSubscription.hasPaymentMethod && (
          <StyledLink to="/payment">
            <i className="mdi-set mdi-credit-card" />
            Add Payment Method
          </StyledLink>
        )}
        {stripeSubscription && stripeSubscription.hasPaymentMethod && (
          <StripeButton Button={Button} />
        )}
        <Logout>
          <LogoutLink to="/logout">
            <i className="mdi-set mdi-logout" />
            Logout
          </LogoutLink>
          {!!email && <Email>{email}</Email>}
        </Logout>
        <MaintenanceBanner
          align="none"
          banner
          showOverride
          style={{
            borderRadius: '5px',
            height: 'auto',
          }}
          showNotification
        />
      </CustomScroll>

      <MobileWarningModal clientPortal={clientPortal} />
    </Div>
  );
}

export default Main;
