import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import moment from 'moment';

const QUERY = gql`
  query TrialEnds {
    currentUser {
      trialEnds
    }
  }
`;

export default function useTrialEnds() {
  const { data, loading, error } = useQuery(QUERY);

  return useMemo(() => {
    if (loading || error || !data || !data.currentUser) {
      return { loading, error, trialEnds: undefined };
    }
    return {
      loading,
      error,
      trialEnds: data.currentUser.trialEnds
        ? moment(data.currentUser.trialEnds)
        : null,
    };
  }, [data, error, loading]);
}
