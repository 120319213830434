/**
 *
 * InviteForm
 *
 */
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/Button';
import media from 'media';

const Div = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;

  ${media.phone`
    flex-direction: row;
  `}
`;

const Input = styled.input<{ error: boolean }>`
  && {
    border-color: ${(props) => props.error && props.theme.colors.danger};
    outline-color: ${(props) => props.error && props.theme.colors.danger};
  }
`;

const Top = styled.div`
  display: flex;
  margin-right: 0;
  ${media.phone`
    margin-right: 0.5rem;
  `}
`;

const NameInput = styled(Input)<{ error: boolean }>`
  flex-grow: 1;
  ${media.phone`
    width: 22%;
  `}
  &:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const Bottom = styled.div`
  display: flex;
  margin-top: 0.5rem;
  ${media.phone`
    margin-top: 0;
  `}
`;

const EmailInput = styled(Input)`
  flex-grow: 1;
  margin-right: 0.5rem;
`;

const ErrorDiv = styled.div`
  color: ${(props) => props.theme.colors.danger};
  font-size: 0.875rem;
`;

type NewClient = {
  firstName: string;
  lastName: string;
  email: string;
};

interface InviteFormProps {
  loading?: boolean;
  error?: string | null;
  onSubmit: (newClient: NewClient) => Promise<any>;
  inviteButtonId?: string;
}

const validationSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
});

const InviteForm: React.FC<InviteFormProps> = ({
  onSubmit,
  loading,
  error,
  inviteButtonId,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{ firstName: string; lastName: string; email: string }>({
    resolver: yupResolver(validationSchema),
  });

  const handleSubmitReset = useCallback(
    async (values) => {
      await onSubmit(values);
      reset();
    },
    [onSubmit, reset]
  );

  return (
    <form onSubmit={handleSubmit(handleSubmitReset)}>
      <Div>
        <Top>
          <NameInput
            type="text"
            {...register('firstName')}
            name="firstName"
            placeholder="First Name"
            disabled={loading}
            error={!!errors.firstName}
            autoComplete="off"
            data-1p-ignore
          />
          <NameInput
            type="text"
            {...register('lastName')}
            name="lastName"
            placeholder="Last Name"
            disabled={loading}
            error={!!errors.lastName}
            autoComplete="off"
            data-1p-ignore
          />
        </Top>
        <Bottom>
          <EmailInput
            type="email"
            {...register('email')}
            name="email"
            placeholder="Email"
            disabled={loading}
            error={!!errors.email}
            autoComplete="off"
            data-1p-ignore
          />
          <Button
            color="primary"
            loading={loading}
            disabled={loading}
            id={inviteButtonId}
          >
            Invite
          </Button>
        </Bottom>
      </Div>
      <ErrorDiv>{error}</ErrorDiv>
    </form>
  );
};

export default InviteForm;
