/**
 *
 * CreateCsvCompany
 *
 */
import React, { useCallback, useEffect, useRef } from 'react';

import { GROUP_COMPANY_LIST } from 'fragments';

import Modal from 'components/Modal';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import useGroupId from 'hooks/useGroupId';
import useWorkspaceId from 'hooks/useWorkspaceId';
import { WorkspacesLocation } from 'locations';
import UploadPage from './UploadPage';
import { showError, showInfo } from 'utils/popups';
import usePrevious from 'hooks/usePrevious';

const CREATE = gql`
  mutation CreateCsvCompany(
    $workspaceId: Int!
    $groupId: Int!
    $companyName: String!
    $csvFile: Upload!
    $companyId: Int
  ) {
    createCsvCompany(
      workspaceId: $workspaceId
      groupId: $groupId
      companyName: $companyName
      csvFile: $csvFile
      companyId: $companyId
    ) {
      company {
        ...GroupCompanyListFields
      }
      error
    }
  }
  ${GROUP_COMPANY_LIST}
`;

const UPDATE_CSV = gql`
  mutation UpdateCsvCompany($companyId: Int!, $csvFile: Upload!) {
    updateCsvCompany(companyId: $companyId, csvFile: $csvFile) {
      company {
        ...GroupCompanyListFields
      }
      error
    }
  }
  ${GROUP_COMPANY_LIST}
`;

interface CreateCsvCompanyProps {
  show: boolean;
  onClose: () => void;
  companyId?: number;
  attachCompanyId?: number;
  onUpdate?: () => any;
}

const CreateCsvCompany: React.FunctionComponent<CreateCsvCompanyProps> = (
  props
) => {
  const {
    show,
    onClose,
    companyId,
    attachCompanyId,
    onUpdate: onUpdateComplete,
  } = props;

  const [onCreate, { loading }] = useMutation(CREATE);
  const [onUpdate, { loading: loadingUpdate }] = useMutation(UPDATE_CSV);
  const csvFileRef = useRef(null);

  const groupId = useGroupId();
  const workspaceId = useWorkspaceId();

  const onChangeCsvFile = useCallback((file) => {
    csvFileRef.current = file;
  }, []);

  const prevShow = usePrevious(show);

  useEffect(() => {
    if (!prevShow && show) {
      csvFileRef.current = null;
    }
  }, [prevShow, show]);

  const onSubmit = useCallback(
    async ({ companyName }) => {
      const op = companyId ? onUpdate : onCreate;

      const { data, errors } = await op({
        variables: {
          workspaceId,
          companyId: companyId || attachCompanyId,
          groupId,
          companyName: companyName || '',
          csvFile: csvFileRef.current,
        },
      });

      if (data?.updateCsvCompany?.error) {
        return showInfo({
          title: 'Error',
          text: data.updateCsvCompany.error,
        })
      }

      if (errors) {
        return showError();
      }

      if (companyId) {
        onClose();

        if (onUpdateComplete) {
          onUpdateComplete();
        }
      } else if (attachCompanyId) {
        window.location.reload();
      } else {
        window.location.pathname = WorkspacesLocation.toUrl({ workspaceId });
      }
    },
    [
      attachCompanyId,
      companyId,
      groupId,
      onClose,
      onCreate,
      onUpdate,
      onUpdateComplete,
      workspaceId,
    ]
  );

  const title = companyId ? 'Update csv data' : 'Create company from CSV';

  return (
    <Modal title={title} show={show} onClose={onClose} width={800}>
      <UploadPage
        onSubmit={onSubmit}
        loading={loading || loadingUpdate}
        update={!!companyId}
        onChangeCsvFile={onChangeCsvFile}
        hideName={!!attachCompanyId}
      />
    </Modal>
  );
};

export default CreateCsvCompany;
