import React from 'react';
import styled from 'styled-components';

import DashboardsIcon from 'components/Icons/DashboardsIcon';
import ReportsIcon from 'components/Icons/ReportsIcon';
import SidebarButton from './SidebarButton';

const Container = styled.div``;

const TopMenu: React.FC = () => {
  return (
    <Container>
      <SidebarButton
        icon={<DashboardsIcon height={'24px'} width={'24px'} />}
        text={'Dashboards'}
        to={'/dashboards'}
        id="sidebar-dashboards-button"
      />
      <SidebarButton
        icon={<ReportsIcon height={'24px'} width={'24px'} />}
        text={'Reports'}
        to={'/reports'}
        id="sidebar-reports-button"
      />
      {/** TODO: Add Metric and Statement pages before uncommenting */}
      {/* <SidebarButton
            icon={<MetricIcon height={'24px'} width={'24px'} />}
            text={'Metrics'}
          />
          <SidebarButton
            icon={<StatementIcon height={'24px'} width={'24px'} />}
            text={'Statements'}
            showText={}
          /> */}
    </Container>
  );
};

export default TopMenu;
