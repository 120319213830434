/**
 *
 * FileUploadModal
 *
 */
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';

import styled from 'styled-components';

import Modal from 'components/Modal';
import Button from 'components/Button';
import FolderTable from 'components/FolderList';
import NameModal from 'components/NameModal';
import Spinner from 'components/Spinner';
import produce from 'immer';
import useTheme from 'hooks/useTheme';

import {
  CONTENT_QUERY,
  NEW_FOLDER,
  GET_COMPANY_FOLDERS,
  GET_ITEM_FOLDERS,
  UPDATE_ITEM_FOLDERS,
  UPDATE_ITEM_FOLDERS_FRAGMENT,
} from 'queries/ClientPortal';

import { FolderContent, ItemFoldersResult } from 'interfaces/folder';
import { notificationToast, showWarning } from 'utils/popups';
import { FOLDER_ITEM_TYPES } from 'utils/constants';
import Radio from 'components/Radio';

const StyledButton = styled(Button)`
  margin-left: 10px;
`;
const DivRight = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px;
`;

const Divider = styled.div`
  width: 100%;
  margin: 10px 0 20px 0;
  border-top: 1px solid #e9ecef;
`;

const Header = styled.div`
  font-size: 0.75rem;
`;

const Access = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;

  label > span {
    font-size: 0.75rem;
  }
`;

interface ManageFileLocationModalProps {
  show: boolean;
  title: string;
  companyId: number;
  folders?: { id: number; name: string }[];
  itemId: number;
  itemType: number;
  loading?: boolean;
  onClose: () => void;
  viewOnly?: boolean;
  locked?: boolean;
}

const ManageFileLocationModal: React.FunctionComponent<ManageFileLocationModalProps> =
  (props) => {
    const {
      show,
      title,
      itemId,
      itemType,
      companyId,
      onClose,
      viewOnly: _viewOnly,
      locked,
    } = props;
    const [saving, setSaving] = useState(false);
    const [selectedFolders, setSelectedFolders] = useState<number[]>([]);
    const [savedCurrentFolders, setSavedCurrentFolders] = useState<number[]>(
      []
    );
    const [showNewFolder, setShowNewFolder] = useState(false);
    const [viewOnly, setViewOnly] = useState(_viewOnly);

    const theme = useTheme();

    const { data } = useQuery(GET_COMPANY_FOLDERS, {
      variables: {
        companyId,
      },

      skip: !show,
    });

    const companyFolders = useMemo(() => {
      if (!data || !data.company) {
        return [];
      }
      return data.company.folders;
    }, [data]);

    const resetSelection = () => {
      setSelectedFolders(savedCurrentFolders);
      setSaving(false);
      setViewOnly(undefined);
      onClose();
    };

    const [getItemFolders, { loading: loadingItemFolders }] = useLazyQuery(
      GET_ITEM_FOLDERS,
      {
        fetchPolicy: 'network-only',
        onCompleted: (folderData) => {
          const itemFolders = folderData.itemFolders.folders.map(
            (folder) => folder.id
          );
          setSelectedFolders(itemFolders);
          setSavedCurrentFolders(itemFolders);
        },
      }
    );

    useEffect(() => {
      if (show && itemId) {
        getItemFolders({
          variables: {
            itemId,
            itemType,
            companyId,
          },
        });
      }
    }, [companyId, getItemFolders, itemId, itemType, show]);

    useEffect(() => {
      if (_viewOnly !== undefined) {
        setViewOnly(_viewOnly);
      }
    }, [_viewOnly]);

    const [handleItemFoldersUpdate, { loading: loadingUpdateItemFolders }] =
      useMutation(UPDATE_ITEM_FOLDERS, {
        onCompleted: () => {
          notificationToast({
            title: 'Item Location Updated!',
            timer: 1000,
          }).then(() => {
            resetSelection();
          });
        },
      });

    const handleItemFoldersSave = useCallback(() => {
      setSaving(true);
      handleItemFoldersUpdate({
        variables: {
          addFolderIds: selectedFolders.filter(
            (folderId) => !savedCurrentFolders.includes(folderId)
          ),
          removeFolderIds: savedCurrentFolders.filter(
            (folderId) => !selectedFolders.includes(folderId)
          ),
          itemId,
          itemType,
          theme: theme.mode.toUpperCase(),
          viewOnly,
        },
        update: (cache, { data: { updateItemFolders } }) => {
          if (!updateItemFolders.success) return;

          if (itemType === FOLDER_ITEM_TYPES.DASHBOARD) {
            cache.modify({
              id: `Dashboard:${itemId}`,
              fields: {
                inClientPortal() {
                  return !!selectedFolders.length;
                },
              },
            });
          }

          if (itemType === FOLDER_ITEM_TYPES.TABLE) {
            cache.modify({
              id: `FolderBudget:${itemId}`,
              fields: {
                inClientPortal() {
                  return !!selectedFolders.length;
                },
                viewOnly() {
                  return viewOnly;
                },
                locked() {
                  return updateItemFolders.locked;
                },
              },
            });

            cache.modify({
              id: `Table:${itemId}`,
              fields: {
                inClientPortal() {
                  return !!selectedFolders.length;
                },
                viewOnly() {
                  return viewOnly;
                },
                locked() {
                  return updateItemFolders.locked;
                },
              },
            });
          }

          for (const folderId of updateItemFolders.addedFolders.map(
            (addedFolder) => addedFolder.id
          )) {
            const folderContentData: {
              getFolderContents: FolderContent;
            } | null = cache.readQuery({
              query: CONTENT_QUERY,
              variables: {
                folderId,
                companyId: companyId,
                theme: theme.mode.toUpperCase(),
              },
            });

            const item = JSON.parse(updateItemFolders.item);
            if (folderContentData) {
              try {
                cache.writeQuery({
                  query: CONTENT_QUERY,
                  variables: {
                    folderId,
                    companyId: companyId,
                    theme: theme.mode.toUpperCase(),
                  },
                  data: produce(
                    folderContentData,
                    (draft: { getFolderContents: FolderContent }) => {
                      if (
                        updateItemFolders.itemType ===
                        FOLDER_ITEM_TYPES.DASHBOARD
                      ) {
                        draft.getFolderContents.dashboards = [
                          ...folderContentData.getFolderContents.dashboards,
                          item,
                        ];
                      } else if (
                        updateItemFolders.itemType === FOLDER_ITEM_TYPES.REPORT
                      ) {
                        draft.getFolderContents.reports = [
                          ...folderContentData.getFolderContents.reports,
                          item,
                        ];
                      } else if (
                        updateItemFolders.itemType ===
                        FOLDER_ITEM_TYPES.CLIENT_PORTAL_FILE
                      ) {
                        draft.getFolderContents.files = [
                          ...folderContentData.getFolderContents.files,
                          item,
                        ];
                      } else if (
                        updateItemFolders.itemType === FOLDER_ITEM_TYPES.TABLE
                      ) {
                        draft.getFolderContents.budgets = [
                          ...folderContentData.getFolderContents.budgets,
                          item,
                        ];
                      }
                    }
                  ),
                });
              } catch (err) {
                // Folder hasn't been cached yet.
                continue;
              }
            }
          }

          let itemFolderIds: number[] = [
            ...savedCurrentFolders,
            ...updateItemFolders.addedFolders.map(
              (addedFolder) => addedFolder.id
            ),
          ];
          itemFolderIds = itemFolderIds.filter((itemFolder) => {
            return !updateItemFolders.removedFolders
              .map((removedFolder) => removedFolder.id)
              .includes(itemFolder);
          });

          for (const folderId of updateItemFolders.removedFolders.map(
            (removedFolder) => removedFolder.id
          )) {
            const folderContentData = cache.readQuery({
              query: CONTENT_QUERY,
              variables: {
                folderId,
                companyId: companyId,
                theme: theme.mode.toUpperCase(),
              },
            });

            try {
              cache.writeQuery({
                query: CONTENT_QUERY,
                variables: {
                  folderId,
                  companyId: companyId,
                  theme: theme.mode.toUpperCase(),
                },
                data: produce(
                  folderContentData,
                  (draft: { getFolderContents: FolderContent }) => {
                    if (
                      updateItemFolders.itemType === FOLDER_ITEM_TYPES.DASHBOARD
                    ) {
                      draft.getFolderContents.dashboards =
                        draft.getFolderContents.dashboards.filter(
                          (dashboard) =>
                            dashboard.id !== updateItemFolders.itemId
                        );
                    } else if (
                      updateItemFolders.itemType === FOLDER_ITEM_TYPES.REPORT
                    ) {
                      draft.getFolderContents.reports =
                        draft.getFolderContents.reports.filter(
                          (report) => report.id !== updateItemFolders.itemId
                        );
                    } else if (
                      updateItemFolders.itemType ===
                      FOLDER_ITEM_TYPES.CLIENT_PORTAL_FILE
                    ) {
                      draft.getFolderContents.files =
                        draft.getFolderContents.files.filter(
                          (file) => file.id !== updateItemFolders.itemId
                        );
                    } else if (
                      updateItemFolders.itemType === FOLDER_ITEM_TYPES.TABLE
                    ) {
                      draft.getFolderContents.budgets =
                        draft.getFolderContents.budgets.filter(
                          (budget) => budget.id !== updateItemFolders.itemId
                        );
                    }
                  }
                ),
              });
            } catch (err) {
              continue; // Folder hasn't been cached yet.
            }
          }

          const cacheData = cache.readFragment({
            id: `ItemFoldersResult:${itemId}:${itemType}`,
            fragment: UPDATE_ITEM_FOLDERS_FRAGMENT,
          });

          cache.writeFragment({
            id: `ItemFoldersResult:${itemId}:${itemType}`,
            fragment: UPDATE_ITEM_FOLDERS_FRAGMENT,
            data: produce(cacheData, (draft: ItemFoldersResult) => {
              let updatedFolders = [
                ...draft.folders,
                ...updateItemFolders.addedFolders,
              ];
              updatedFolders = updatedFolders.filter((updatedFolder) => {
                return !updateItemFolders.removedFolders
                  .map((removedFolder) => removedFolder.id)
                  .includes(updatedFolder.id);
              });
              draft.folders = [...updatedFolders];
            }),
          });

          setSavedCurrentFolders(itemFolderIds);
        },
        onError: () => {
          setSaving(false);
        },
      });
    }, [
      handleItemFoldersUpdate,
      selectedFolders,
      savedCurrentFolders,
      itemId,
      itemType,
      theme.mode,
      viewOnly,
      companyId,
    ]);

    const handleSave = useCallback(() => {
      if (itemType === FOLDER_ITEM_TYPES.TABLE) {
        if (viewOnly === false && locked) {
          showWarning({
            title: 'Budget is locked',
            text: 'Making this budget editable will unlock it for all workspace users and clients.',
            confirmButtonText: 'Continue',
            showDenyButton: true,
            denyButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              handleItemFoldersSave();
            }
          });
        } else {
          handleItemFoldersSave();
        }
      } else {
        handleItemFoldersSave();
      }
    }, [handleItemFoldersSave, itemType, locked, viewOnly]);

    const [createNewFolder, { loading: loadingNewFolder }] =
      useMutation(NEW_FOLDER);

    const handleNewFolder = useCallback(
      ({ name }) => {
        createNewFolder({
          variables: {
            companyId,
            folderName: name,
          },
          update: (cache, { data: { addNewFolder } }) => {
            const cacheData = cache.readQuery({
              query: GET_COMPANY_FOLDERS,
              variables: {
                companyId,
              },
            });

            cache.writeQuery({
              query: GET_COMPANY_FOLDERS,
              variables: {
                companyId,
              },
              data: produce(cacheData, (draft: any) => {
                draft.company.folders = [
                  ...draft.company.folders,
                  addNewFolder.folder,
                ];
              }),
            });
          },
        });
        setShowNewFolder(false);
      },
      [companyId, createNewFolder]
    );

    const handleCloseNewFolder = () => {
      setShowNewFolder(false);
    };

    const handleFolderCheck = useCallback(
      (folderId, checked) => {
        const selectionChange = [...selectedFolders];
        if (checked) selectionChange.push(folderId);
        else
          selectionChange.splice(
            selectionChange.findIndex((f) => f === folderId),
            1
          );
        setSelectedFolders(selectionChange);
      },
      [selectedFolders]
    );

    if (!data || !data.company) return null;

    return (
      <Modal
        className={'file-location-modal'}
        title={title}
        onClose={resetSelection}
        show={show}
      >
        {loadingItemFolders ? (
          <Spinner />
        ) : (
          <>
            {itemType === FOLDER_ITEM_TYPES.TABLE && (
              <>
                <Header>Edit Access</Header>
                <Access>
                  <Radio
                    onChange={() => setViewOnly(true)}
                    checked={viewOnly}
                    disabled={loadingUpdateItemFolders || saving}
                  >
                    View Only
                  </Radio>
                  <Radio
                    onChange={() => setViewOnly(false)}
                    checked={!viewOnly}
                    disabled={loadingUpdateItemFolders || saving}
                  >
                    Edit
                  </Radio>
                </Access>
              </>
            )}
            <FolderTable
              disableChecks={loadingUpdateItemFolders || saving}
              folders={companyFolders}
              selectedFolderIds={selectedFolders}
              onSelect={handleFolderCheck}
            />
          </>
        )}
        <Divider />
        <DivRight>
          <StyledButton
            disabled={loadingUpdateItemFolders || saving}
            loading={loadingUpdateItemFolders || saving}
            type="submit"
            color={
              selectedFolders.length || loadingItemFolders
                ? 'primary'
                : 'danger'
            }
            onClick={() => {
              handleSave();
            }}
          >
            {selectedFolders.length || loadingItemFolders
              ? 'Save'
              : 'Remove from Portal'}
          </StyledButton>
          <StyledButton
            disabled={loadingNewFolder || saving}
            loading={loadingNewFolder || saving}
            onClick={() => {
              setShowNewFolder(true);
            }}
            color="primary"
          >
            New Folder
          </StyledButton>
        </DivRight>
        <NameModal
          marginTop={'20%'}
          show={showNewFolder}
          title={'New Folder'}
          onSubmit={handleNewFolder}
          onClose={handleCloseNewFolder}
          defaultName={''}
        />
      </Modal>
    );
  };

export default ManageFileLocationModal;
