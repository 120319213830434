import React from 'react';
import Dropdown from 'components/Dropdown';
import DownArrowIcon from 'components/Icons/DownArrowicon';
import {
  DropdownButton,
  DropdownContainer,
  DropdownHeader,
  EmptyDropdownContainer,
  ItemDropdownContainer,
  Name,
} from './styledComponents';

export type DropdownItem = {
  id: number | string;
  name: string;
  type: 'header' | 'item';
  style?: React.CSSProperties;
};

interface Props<T extends DropdownItem> {
  dropdownItems: T[];
  displayedName: string;
  selected: T;
  selectedPosition: number;
  sidebarExpanded: boolean;
  onSelect?: (value: T) => void;
  ButtonComponent?: React.FunctionComponent<any>;
  buttonHeight?: number;
  headerHeight?: number;
  buttonType?: string;
  buttonTypePlural?: string;
  compact?: boolean;
  disabled?: boolean;
}

const GroupedDropdown = <T extends DropdownItem>({
  dropdownItems,
  displayedName,
  selected,
  selectedPosition,
  sidebarExpanded,
  onSelect,
  ButtonComponent,
  buttonHeight,
  headerHeight,
  buttonType,
  buttonTypePlural,
  compact,
  disabled,
}: Props<T>): JSX.Element => {
  return (
    <DropdownContainer sidebarExpanded={sidebarExpanded}>
      {!dropdownItems || dropdownItems.length === 0 ? (
        <EmptyDropdownContainer
          id={`no-${buttonTypePlural || buttonType}-dropdown-button`}
        >
          <Name compact={compact}>{displayedName || ''}</Name>
        </EmptyDropdownContainer>
      ) : (
        <Dropdown
          offsetX={0}
          offsetY={0}
          width={250}
          portal
          disabled={disabled}
          trigger={
            <ItemDropdownContainer
              id={`${buttonType}-dropdown-button`}
              compact={compact}
              disabled={disabled}
            >
              <Name compact={compact}>{displayedName || ''}</Name>
              {!disabled && (
                <DownArrowIcon
                  height={compact ? 18 : 24}
                  width={compact ? 18 : 24}
                />
              )}
            </ItemDropdownContainer>
          }
          showScroll={true}
          position={selectedPosition}
          overflowXHidden
        >
          {({ closeDropdown }: { closeDropdown: () => void }) =>
            dropdownItems.map((item: T, index: number) =>
              item.type === 'header' ? (
                <DropdownHeader key={index} index={index} height={headerHeight}>
                  {item.name}
                </DropdownHeader>
              ) : ButtonComponent ? (
                <ButtonComponent
                  key={index}
                  item={item}
                  selected={item.id === selected.id}
                  closeDropdown={() => {
                    closeDropdown();
                  }}
                />
              ) : (
                <DropdownButton
                  key={index}
                  height={buttonHeight}
                  selected={item.id === selected.id}
                  onClick={() => {
                    onSelect && onSelect(item);
                    closeDropdown();
                  }}
                  style={item.style}
                >
                  {item.name}
                </DropdownButton>
              )
            )
          }
        </Dropdown>
      )}
    </DropdownContainer>
  );
};

export default GroupedDropdown;
