/**
 *
 * AccountInfo
 *
 */
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from 'components/Button';
import HookErrorMessage from 'components/HookErrorMessage';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { captureException } from 'utils/sentry';
import produce from 'immer';
import { FieldGroup, Label, Input, Message, Bottom } from './components';
import DeleteUser from './DeleteUser';
import { ACCOUNT_INFO } from './queries';

const UPDATE = gql`
  mutation UpdateAccountInfo($firstName: String!, $lastName: String!) {
    updateAccountInfo(firstName: $firstName, lastName: $lastName) {
      firstName
      lastName
    }
  }
`;

const AccountInfo = ({ user }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();

  const [saved, setSaved] = useState(false);

  const [updateAccountInfo, { loading }] = useMutation(UPDATE);

  const isValid = !errors || !Object.keys(errors).length;

  const onSubmit = useCallback(
    async (values) => {
      try {
        setSaved(false);
        await updateAccountInfo({
          variables: {
            firstName: values.firstName.trim(),
            lastName: values.lastName.trim(),
          },
          update: (
            cache,
            {
              data: {
                updateAccountInfo: { firstName, lastName },
              },
            }
          ) => {
            const data = cache.readQuery({ query: ACCOUNT_INFO });

            cache.writeQuery({
              query: ACCOUNT_INFO,
              data: produce(data, (draft) => {
                draft.currentUser.firstName = firstName;
                draft.currentUser.lastName = lastName;
              }),
            });

            setSaved(true);
          },
        });
      } catch (err) {
        captureException(err, true);
      }
    },
    [updateAccountInfo]
  );

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p>Account Info</p>
        <FieldGroup>
          <Label>First Name</Label>
          <Input
            type="text"
            name="firstName"
            defaultValue={user.firstName}
            {...register('firstName', {
              validate: (value) =>
                (!!value && !!value.trim()) || 'This is required',
            })}
            maxLength={30}
          />
          <HookErrorMessage errors={errors} field="firstName" />
        </FieldGroup>
        <FieldGroup>
          <Label>Last Name</Label>
          <Input
            type="text"
            name="lastName"
            defaultValue={user.lastName}
            {...register('lastName', {
              validate: (value) =>
                (!!value && !!value.trim()) || 'This is required',
            })}
            maxLength={30}
          />
          <HookErrorMessage errors={errors} field="lastName" />
        </FieldGroup>
        <FieldGroup>
          <Label>Email</Label>
          <div>{user.email}</div>
        </FieldGroup>
        {saved && <Message>Account Info Saved</Message>}
        <Bottom>
          <Button
            color="success"
            type="submit"
            disabled={!isDirty || !isValid || loading}
            loading={loading}
          >
            Save
          </Button>
          <DeleteUser showTwoFactorAuth={user.hasTwoFactorAuth} />
        </Bottom>
      </form>
    </div>
  );
};

export default AccountInfo;
