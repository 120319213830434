import scrollbar from 'css/scrollbar';
import styled, { css, keyframes } from 'styled-components';

const TRANSITION_TIME = '250ms';

const fadeIn = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

export const Expanding = styled.div<{ height: number | string }>`
  height: ${({ height }) =>
    typeof height === 'string' ? height : `${height}px`};
  transition: height ${TRANSITION_TIME} ease-out;
  overflow-y: ${({ height }) =>
    typeof height === 'string' ? 'auto' : `hidden`};

  ${scrollbar()}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 2px;

  & form {
    width: 100%;
  }
`;

export const Visible = styled.div<{ visible: boolean }>`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  animation: ${fadeIn} 250ms ease-out;
  width: 100%;
`;

export const Instruction = styled.div`
  @keyframes flash {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.5;
    }
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  animation: flash 500ms infinite alternate ease-in-out;

  & svg {
    height: auto;
    width: auto;
    path {
      fill: ${({ theme }) => theme.body.color};
    }
  }
`;

export const Divider = styled.div`
  border-top: 1px solid #e9ecef;
  width: 100%;
  margin: 10px 0;
`;

export const PreviewHeader = styled.div`
  font-size: 0.85rem;
  width: 100%;
`;

export const PreviewContainer = styled.div`
  border: 1px solid #e9ecef;
  padding: 5px 10px 15px 10px;
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const SubmitContainer = styled.div`
  text-align: right;
  width: 100%;
`;

export const Launcher = styled.button`
  border: 1px solid #e9ecef;
  border-radius: 5px;

  &:hover {
    border-color: hsl(0, 0%, 80%);
  }
  margin-bottom: 10px;
`;

export const LiveTextLoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
`;

export const LoadingContainer = styled.div`
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.button.danger.base};
  font-size: 0.75rem;
`;

export const NameInput = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  label {
    margin-right: 5px;
  }

  input {
    flex-grow: 1;
  }
`;
export const Counter = styled.div<{ error: boolean }>`
  margin-left: auto;
  font-size: 0.65rem;
  ${({ error }) =>
    error &&
    css`
      color: ${({ theme }) => theme.button.danger.base};
    `}
`;
