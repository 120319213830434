/**
 *
 * HideScrollbar
 *
 */
import { createGlobalStyle } from 'styled-components';

const HideScrollbar = createGlobalStyle`
   body {
    overflow-y: hidden!important;
   }
 `;

export default HideScrollbar;
