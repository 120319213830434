import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import moment from 'moment';
import useCompanyId from './useCompanyId';

const QUERY = gql`
  query UseBookMonth($companyId: Int!) {
    company(id: $companyId) {
      id
      bookMonth
      fiscalYearMonth
      isBookMonthFixed
    }
  }
`;

export default function useBookMonth(_companyId) {
  const companyId = useCompanyId() || _companyId;

  const { data } = useQuery(QUERY, {
    variables: {
      companyId: _companyId || companyId,
    },
    skip: !companyId && !_companyId,
  });

  return useMemo(() => {
    if (data && data.company) {
      return {
        bookMonth: data.company.bookMonth
          ? moment.utc(data.company.bookMonth)
          : moment.utc().startOf('month'),
        fiscalYearMonth: data.company.fiscalYearMonth,
        isBookMonthFixed: data.company.isBookMonthFixed,
      };
    }
    return { bookMonth: null, fiscalYearMonth: null, isBookMonthFixed: null };
  }, [data]);
}
