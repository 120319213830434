/**
 *
 * ClassesDropdown
 *
 */
import Dropdown from 'components/Dropdown';
import Tooltip from 'components/Tooltip';
import { ClassDepartmentFilter } from 'interfaces/company';
import React from 'react';
import { DropdownHeader, IconButton } from './styledComponents';
import GearIcon from 'components/Icons/GearIcon';
import DropdownButton from 'components/DropdownButton';

interface ClassesDropdownProps {
  classesAndDepartments: ClassDepartmentFilter[];
  companyId: number;
  getCompanyName: (companyId: number) => string;
  handleShowClassDepartmentModal: (companyId: number) => void;
}

const ClassesDropdown: React.FunctionComponent<ClassesDropdownProps> = (
  props
) => {
  const {
    classesAndDepartments,
    companyId,
    getCompanyName,
    handleShowClassDepartmentModal,
  } = props;

  return (
    <Dropdown
      width={220}
      trigger={
        <Tooltip
          content={`Classes and Departments: ${
            // get the count of classes and departments for the company
            classesAndDepartments.find((item) => item.companyId === companyId)
              ?.filters.classes?.length ||
            classesAndDepartments.find((item) => item.companyId === companyId)
              ?.filters.departments?.length ||
            0
          }`}
        >
          <IconButton color="primary" style={{ marginLeft: '5px' }}>
            <GearIcon />
          </IconButton>
        </Tooltip>
      }
    >
      {({ closeDropdown }) => (
        <>
          <DropdownHeader>{getCompanyName(companyId)}</DropdownHeader>
          <DropdownButton
            onClick={() => {
              handleShowClassDepartmentModal(companyId);
              closeDropdown();
            }}
          >
            Classes and Departments
          </DropdownButton>
        </>
      )}
    </Dropdown>
  );
};

export default ClassesDropdown;
