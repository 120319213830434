/**
 *
 * BankMappings
 *
 */
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { SettingTitle } from './styledComponents';
import Select from 'components/Select';

interface BankMappingsProps {
  bankAccounts: { id: string; name: string; bankAccountType: string }[];
  bankAccountMappings: { accountId: string; bankAccountType: string }[];
  onChangeBankAccountType: (accountId: string, bankAccountType: string) => void;
}

const Div = styled.div``;

const Row = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const ColTitle = styled.div`
  font-weight: bold;
  font-size: 0.8rem;
  margin-bottom: 5px;
  margin-right: 20px;
`;

const Left = styled.div``;

const AccountTypeSelect = styled(Select)`
  width: 200px;
`;

const ItemDiv = styled.div`
  height: 30px;
  margin-bottom: 4px;
`;

const AccountName = styled(ItemDiv)`
  font-size: 0.8rem;
  line-height: 24px;
  margin-right: 20px;
`;

const options = [
  { value: 'BANK', label: 'Bank' },
  { value: 'CREDITCARD', label: 'Credit Card' },
  { value: 'PAYPAL', label: 'Paypal' },
];

const BankMappings: React.FunctionComponent<BankMappingsProps> = (props) => {
  const { bankAccounts, bankAccountMappings, onChangeBankAccountType } = props;

  const valuesByAccountId = useMemo(() => {
    const result = new Map<string, string>();
    bankAccountMappings.forEach((mapping) => {
      result.set(mapping.accountId, mapping.bankAccountType);
    });
    return result;
  }, [bankAccountMappings]);

  return (
    <Div>
      <SettingTitle>Bank/Credit Card Mapping</SettingTitle>
      <Row>
        <Left>
          <ColTitle>Account</ColTitle>
          {bankAccounts.map((bankAccount) => (
            <AccountName key={bankAccount.id}>{bankAccount.name}</AccountName>
          ))}
        </Left>
        <div>
          <ColTitle>Account Type</ColTitle>
          {bankAccounts.map((bankAccount) => (
            <ItemDiv key={bankAccount.id}>
              <AccountTypeSelect
                options={options}
                value={
                  valuesByAccountId.get(bankAccount.id) ||
                  bankAccount.bankAccountType
                }
                onChange={(selectedOption) => {
                  onChangeBankAccountType(bankAccount.id, selectedOption);
                }}
                compact
              />
            </ItemDiv>
          ))}
        </div>
      </Row>
    </Div>
  );
};

export default BankMappings;
