function findValueHelper(value, items, path = []) {
  let result;
  items.find((item) => {
    if (item.value === value) {
      result = [...path, item.value];
      return true;
    }
    if (item.children && item.children.length) {
      result = findValueHelper(value, item.children, [...path, item.value]);
      if (result) return true;
    }
    return false;
  });
  return result;
}

export function findValuePath(value, data, consolidationData) {
  let result;
  data.find((tab, index) => {
    const path = findValueHelper(value, tab.children);
    if (path) {
      result = { path: [tab.value, ...path], consolidationTab: 0 };
      return true;
    }
    return false;
  });
  if (result) return result;

  consolidationData.forEach((companyData, cIndex) => {
    companyData.forEach((tab) => {
      const path = findValueHelper(value, tab.children);
      if (path) {
        result = { path: [tab.value, ...path], consolidationTab: cIndex + 1 };
        return true;
      }
      return false;
    });
  });

  return result;
}

export function findValueIntegrationPath(
  value,
  dataVariablesByIntegration,
  sharedDataVariables
) {
  let resultPath;
  let resultConsolidationTab;
  const result = dataVariablesByIntegration.findIndex((entry) => {
    const consolidationData = (entry.consolidationCompanies || []).map(
      (c) => c.customKpiVariables
    );

    const found = findValuePath(value, entry.data, consolidationData);

    if (found) {
      const { path, consolidationTab } = found;
      resultPath = path;
      resultConsolidationTab = consolidationTab;
      return true;
    }
    return false;
  });

  if (result === -1 && sharedDataVariables) {
    const consolidationData = (
      (!!dataVariablesByIntegration[0] &&
        dataVariablesByIntegration[0].consolidationCompanies) ||
      []
    ).map((c) => c.sharedDataVariables);

    const found = findValuePath(value, sharedDataVariables, consolidationData);

    if (found) {
      const { path, consolidationTab } = found;
      resultPath = path;
      resultConsolidationTab = consolidationTab;
    }
  }

  if (resultPath) {
    return {
      currentIntegration: result,
      path: resultPath,
      consolidationTab: resultConsolidationTab,
    };
  }
  return {
    currentIntegration: 0,
    path: dataVariablesByIntegration.length
      ? [dataVariablesByIntegration[0].data[0].value]
      : null,
    consolidationTab: 0,
  };
}
