import useCompanyId from './useCompanyId';
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import gql from 'graphql-tag';

const QUERY = gql`
  query UseSupportedStatements($companyId: Int!) {
    company(id: $companyId) {
      id
      accounting {
        supportedStatements
      }
    }
  }
`;

export default function useSupportedStatements(_companyId) {
  const companyId = useCompanyId() || _companyId;

  const { data, loading, error } = useQuery(QUERY, {
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  return useMemo(() => {
    if (!data || !data.company) {
      return { loading, error };
    }
    return {
      loading,
      error,
      supportedStatements: data.company.accounting
        ? data.company.accounting.supportedStatements
        : null,
    };
  }, [data, error, loading]);
}
