import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import useAllIntegrationStatus from 'hooks/useAllIntegrationStatus';
import { ClientPortalPreviewLocation } from 'locations';
import { useHistory } from 'react-router-dom';
import { showError } from 'utils/popups';
import { captureException } from 'utils/sentry';
import { ClientPortal } from './styledComponents';

const OPEN_COMPANY = gql`
  mutation WorkspaceOpenCompany($companyId: Int!) {
    setCompany(companyId: $companyId) @client
  }
`;

interface Props {
  workspaceId: number;
  companyId: number;
  isConsolidation: boolean;
}

const ClientPortalAccess = ({
  workspaceId,
  companyId,
  isConsolidation,
}: Props): React.ReactElement => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { allIntegrationStatus, refetch } = useAllIntegrationStatus(companyId);
  const [setCompany] = useMutation(OPEN_COMPANY, {
    variables: {
      companyId,
    },
  });

  const open = useCallback(async () => {
    setLoading(true);
    try {
      const { firstInactive: inactiveIntegration, consolidationError } =
        await refetch();

      if (consolidationError) {
        setLoading(false);
        return showError({
          title: 'Consolidation Error',
          text: consolidationError,
          icon: 'info',
        });
      }

      if (inactiveIntegration) {
        setLoading(false);
        return showError({
          title: 'Connection Lost',
          text: isConsolidation
            ? `The connection${
                inactiveIntegration.inactiveCompanyNames.length > 1 ? 's' : ''
              } to ${inactiveIntegration.inactiveCompanyNames.join(
                ', '
              )} needs to be restored.`
            : 'The connection needs to be restored.',
          icon: 'info',
        });
      }

      history.push({
        pathname: ClientPortalPreviewLocation.toUrl({
          workspaceId,
          companyId,
        }),
        state: {
          expandedCompanyId: companyId,
          pathname: history.location.pathname,
        },
      });

      setCompany();
    } catch (err) {
      captureException(err, true);
      setLoading(false);
    }
  }, [companyId, history, isConsolidation, refetch, setCompany, workspaceId]);

  return (
    <ClientPortal
      color="primary"
      outline
      onClick={open}
      disabled={allIntegrationStatus === null || loading}
      className="client-portal-button"
    >
      Client Portal
    </ClientPortal>
  );
};

export default ClientPortalAccess;
