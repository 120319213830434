/**
 *
 * Hubspot
 *
 */
import { useEffect, useMemo } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import useWhitelabel from 'hooks/useWhitelabel';
import useWorkspaceId from 'hooks/useWorkspaceId';
import usePrevious from 'hooks/usePrevious';
import { pushData } from 'utils/ga';
import { STAGING } from 'environment';

const hubspotId =
  window.location.hostname === 'localhost' ? '40097541' : '6220889';

const QUERY = gql`
  query Hubspot {
    currentCompanyId @client
    currentWorkspaceId @client
    currentUser {
      userId
      email
      firstName
      lastName
      phone
      presignup
      plan
      subscriptionDescription
      businessType
      clients
      clientPortalOnly
      role
      referred
      otherReferred
      company
      isCollaborator
      registerPricingPlan
      hasCreatedDashboard
      hasCreatedReport
      hasQbCompany
      hasXeroCompany
      trialEnds
      lastLoggedIn
      workspaceOwner
      firstAuthMethod
      masquerade
      administrator
      adminMasqueradeOnly
      promo {
        id
        code
      }
      companies {
        id
      }
      firms {
        id
        companies {
          id
        }
      }
    }
  }
`;

const clientsToNumber = {
  '1-5': 5,
  '6-10': 10,
  '11-25': 25,
  '26-50': 50,
  '50+': 51,
  'n/a': 1,
};

const Hubspot = () => {
  const isLoggedIn = useIsLoggedIn();
  const { whiteLabel, loading: loadingWhitelabel } = useWhitelabel();
  const workspaceId = useWorkspaceId();
  const { data } = useQuery(QUERY, {
    skip: !isLoggedIn,
  });

  const user = data?.currentUser;
  const prevTrialEnds = usePrevious(user?.trialEnds);

  const enabled = useMemo(() => {
    return (
      user &&
      !user.clientPortalOnly &&
      !whiteLabel &&
      !loadingWhitelabel &&
      !STAGING &&
      !user.masquerade &&
      !user.administrator &&
      !user.adminMasqueradeOnly &&
      !window.localStorage.getItem('hideHubspot')
    );
  }, [loadingWhitelabel, user, whiteLabel]);

  useEffect(() => {
    if (enabled && workspaceId) {
      pushData({ workspace_id: workspaceId });
    }
  }, [enabled, workspaceId]);

  useEffect(() => {
    if (!user || !user.email || !enabled) return;
    if (user.clientPortalOnly || user.masquerade) return;

    const pushToHubspot = () => {
      const _hsq = window._hsq || [];

      let companyCount = user.companies?.length || 0;
      user.firms?.forEach((firm) => {
        companyCount += firm.companies?.length || 0;
      });

      const optional = {};

      if (user.businessType) optional.business_type = user.businessType;
      if (user.clients) {
        optional.of_said_companies =
          clientsToNumber[user.clients] || parseInt(user.clients) || 1;
      }
      if (user.subscriptionDescription) {
        optional.subscription_plan = user.subscriptionDescription;
      }
      if (user.promo) optional.promo = user.promo;
      if (user.phone) optional.phone = user.phone;
      if (user.company) optional.company = user.company;
      if (user.firstAuthMethod) {
        optional.signup_method = user.firstAuthMethod.toLowerCase();
      }
      if (user.trialEnds !== prevTrialEnds) {
        optional.date_trial_ends = user.trialEnds
          ? moment.utc(user.trialEnds).startOf('day').valueOf()
          : null;
      }
      if (user.otherReferred) optional.other_referral = user.otherReferred;

      const lastLoggedIn = user.lastLoggedIn
        ? moment.utc(user.lastLoggedIn).valueOf()
        : null;

      pushData({
        connected_companies: companyCount,
        companies_stated:
          clientsToNumber[user.clients] || parseInt(user.clients) || 1,
        industry: user.businessType || '',
        subscription_plan: user.plan?.toLowerCase(),
      });

      _hsq.push([
        'identify',
        {
          email: user.email,
          firstname: user.firstName,
          lastname: user.lastName,
          last_logged_in: lastLoggedIn,
          plan: user.plan?.toLowerCase(),
          report_dashboard_created:
            user.hasCreatedDashboard || user.hasCreatedReport,
          of_connected_companies: companyCount,
          uses_qb: user.hasQbCompany,
          uses_xero: user.hasXeroCompany,
          google_client_id: user.userId,
          workspace_owner: user.workspaceOwner,
          jobtitle: user.role,
          how_did_you_hear_about_reach_reporting_: user.referred,
          ...optional,
        },
      ]);

      _hsq.push(['trackPageView']);
    };

    const loadScript = () => {
      if (window._hsq) {
        pushToHubspot();
        return;
      }

      if (document.getElementById('hs-script-loader')) {
        const wait = setInterval(() => {
          if (window._hsq) {
            clearInterval(wait);
            pushToHubspot();
          }
        }, 100);
        return;
      }

      const script = document.createElement('script');
      script.src = `https://js.hs-scripts.com/${hubspotId}.js`;
      script.type = 'text/javascript';
      script.async = true;
      script.defer = true;
      script.id = 'hs-script-loader';
      document.head.appendChild(script);

      const wait = setInterval(() => {
        if (window._hsq) {
          clearInterval(wait);
          pushToHubspot();
        }
      }, 100);
    };

    loadScript();
  }, [enabled, prevTrialEnds, user]);

  return null;
};

export default Hubspot;
