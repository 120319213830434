import { ApolloCache, DocumentNode, FetchResult } from '@apollo/client';
import { GROUP_COMPANY_LIST } from 'fragments';
import gql from 'graphql-tag';
import produce from 'immer';
import { Company } from 'interfaces/company';
import { Firm } from 'interfaces/firm';
import { User } from 'interfaces/user';
import { Workspace, WorkspaceInvite } from 'interfaces/workspace';
import { GET_CLIENT_PORTAL_USERS } from 'queries/ClientPortal';
import { getWorkspaceUserQuery } from 'queries/workspaceUsers';
import { showSuccess } from 'utils/popups';

const GROUPS = gql`
  query GroupManagers($groupId: Int!) {
    group(id: $groupId) {
      id
      companies {
        ...GroupCompanyListFields
      }
      managers {
        id
      }
    }
  }
  ${GROUP_COMPANY_LIST}
`;

export const updateCachedInvitedUsers = (
  cache: ApolloCache<any>,
  variableIds: { workspaceId: number; groupId?: number; companyId?: number },
  result: Omit<
    FetchResult<
      {
        inviteWorkspaceUser: {
          error: string;
          workspaceInvite: WorkspaceInvite;
        };
      },
      Record<string, any>,
      Record<string, any>
    >,
    'context'
  >,
  query: DocumentNode,
  errorCallback: React.Dispatch<React.SetStateAction<string | null>>
): void => {
  if (result.data?.inviteWorkspaceUser?.error) {
    errorCallback(result.data.inviteWorkspaceUser.error);
    return;
  }

  cache.modify({
    id: cache.identify({
      __typename: 'Workspace',
      id: variableIds.workspaceId,
    }),
    fields: {
      workspaceInvites(existingWorkspaceInvites) {
        return [
          ...existingWorkspaceInvites,
          result.data?.inviteWorkspaceUser?.workspaceInvite,
        ];
      },
    },
  });
};

export const removeCachedInvite = (
  cache: ApolloCache<any>,
  variableIds: { workspaceId: number; groupId?: number; companyId?: number },
  inviteId: number,
): void => {
  const { workspaceId } = variableIds;

  cache.modify({
    id: cache.identify({
      __typename: 'Workspace',
      id: workspaceId,
    }),
    fields: {
      workspaceInvites(existingWorkspaceInvites, { readField }) {
        return existingWorkspaceInvites.filter(
          (inviteRef: any) => inviteId !== readField('id', inviteRef)
        );
      },
    },
  });
};

export const updateCachedWorkspaceUsers = (
  cache: ApolloCache<any>,
  query: DocumentNode,
  result: Omit<
    FetchResult<
      {
        toggleClientUser: {
          user?: User;
          error?: string;
        };
      },
      Record<string, any>,
      Record<string, any>
    >,
    'context'
  >,
  workspaceId: number,
  selectedUser: User,
  selectedCompany: Company,
  workspace: Workspace | undefined,
  callback: () => void,
  errorCallback: React.Dispatch<React.SetStateAction<string | null>>
): void => {
  if (result.data?.toggleClientUser?.user) {
    const {
      data: {
        toggleClientUser: { user },
      },
    } = result;
    const workplaceCachedData = cache.readQuery({
      query,
      variables: {
        workspaceId,
      },
    });

    cache.writeQuery({
      query,
      variables: {
        workspaceId,
      },
      data: produce(workplaceCachedData, (draft: { workspace: Workspace }) => {
        draft.workspace.users = draft.workspace.users.filter(
          (item) => item.id !== selectedUser.id
        );
      }),
    });

    const companyCachedData = cache.readQuery({
      query: GET_CLIENT_PORTAL_USERS,
      variables: {
        companyId: selectedCompany.id,
      },
    });

    if (companyCachedData) {
      cache.writeQuery({
        query: GET_CLIENT_PORTAL_USERS,
        variables: {
          companyId: selectedCompany.id,
        },
        data: produce(companyCachedData, (draft: { company: Company }) => {
          draft.company.clientPortalUsers = [
            ...draft.company.clientPortalUsers,
            user,
          ];
        }),
      });
    }

    if (workspace) {
      for (const group of workspace.groups) {
        const groupCachedData = cache.readQuery({
          query: GROUPS,
          variables: {
            groupId: group.id,
          },
        });

        if (groupCachedData) {
          cache.writeQuery({
            query: GROUPS,
            variables: {
              groupId: group.id,
            },
            data: produce(groupCachedData, (draft: { group: Firm }) => {
              draft.group.managers = draft.group.managers.filter(
                (manager) => manager.id !== user.id
              );

              draft.group.companies = draft.group.companies.reduce<Company[]>(
                (companies, company) => {
                  companies.push({
                    ...company,
                    users: company.users.filter(
                      (companyUser) => companyUser.id !== user.id
                    ),
                  });
                  return companies;
                },
                []
              );
            }),
          });
        }
      }
    }

    showSuccess({
      title: 'Workspace User Changed to Client Successfully!',
      showConfirmButton: false,
      timer: 1500,
    });
    callback();
  } else {
    errorCallback(
      'There was an error changing user from workspace user to client.'
    );
  }
};

export const updateCachedClientUsers = (
  cache: ApolloCache<any>,
  query: DocumentNode,
  result: Omit<
    FetchResult<
      {
        toggleClientUser: {
          user?: User;
          error?: string;
        };
      },
      Record<string, any>,
      Record<string, any>
    >,
    'context'
  >,
  companyId: number,
  workspaceId: number,
  user: User
): void => {
  if (result.data?.toggleClientUser?.user) {
    const {
      data: {
        toggleClientUser: { user: userResult },
      },
    } = result;

    const cachedCompanyData = cache.readQuery({
      query,
      variables: {
        companyId,
      },
    });

    cache.writeQuery({
      query,
      variables: {
        companyId,
      },
      data: produce(cachedCompanyData, (draft: { company: Company }) => {
        draft.company.clientPortalUsers =
          draft.company.clientPortalUsers.filter((u) => u.id !== user.id);
      }),
    });

    const workplaceCachedData = cache.readQuery({
      query: getWorkspaceUserQuery(),
      variables: {
        workspaceId,
      },
    });

    if (workplaceCachedData) {
      cache.writeQuery({
        query: getWorkspaceUserQuery(),
        variables: {
          workspaceId,
        },
        data: produce(
          workplaceCachedData,
          (draft: { workspace: Workspace }) => {
            draft.workspace.users = [...draft.workspace.users, userResult];
          }
        ),
      });
    }

    showSuccess({
      title: 'Client Has Successfully Been Made a Workspace User!',
      text: 'Next, navigate to Group Access settings to give them access to a specific group.',
    });
  }
};
