/**
 *
 * Categories
 *
 */

import React, { useMemo } from 'react';
import styled from 'styled-components';

import VerticalTabs from 'components/VerticalTabs';

const StyledTabs = styled(VerticalTabs)`
  margin-right: 10px;
  overflow-y: auto;
  width: 100%;
  & button {
    width: 100%;
  }
`;

const eliminationTabs = ['Profit and Loss', 'Balance Sheet'];
const agingReports = new Set(['arAging', 'apAging']);

function Categories(props) {
  const { data, current, onChange, elimination } = props;

  const tabs = useMemo(() => {
    const dataWithoutAgingReports = (data || []).filter(
      (d) => !!d && !agingReports.has(d.value)
    );

    if (elimination) {
      return dataWithoutAgingReports
        .filter((item) => eliminationTabs.includes(item.name))
        .map((item) => ({ id: item.value, label: item.name }));
    } else {
      return dataWithoutAgingReports.map((item) => ({
        id: item.value,
        label: item.name,
      }));
    }
  }, [data, elimination]);

  return <StyledTabs tabs={tabs} current={current} onChange={onChange} />;
}

export default Categories;
