/**
 *
 * Currency Modal
 *
 */

import Modal, { StandardModalProps } from 'components/Modal';
import React, { useCallback, useEffect, useState } from 'react';

import countriesJson from 'library/currency/countries.json';
import { Country } from 'interfaces/company';
import Radio from 'components/Radio';
import { Container, Footer, Search } from './styledComponents';
import Button from 'components/Button';

interface Props extends StandardModalProps {
  /**
   * The current set currency
   */
  currency: string;
  /**
   * Callback function invoked on submission
   */
  onSubmit: (country: { country: string } & Country) => void;
}

const CurrencyModal: React.FC<Props> = ({
  show,
  onClose,
  currency,
  onSubmit,
}) => {
  const [countries, setCountries] =
    useState<{ [x: string]: Country }>(countriesJson);
  const [selectedCurrency, setSelectedCurrency] = useState<
    { country: string } & Country
  >({ country: currency, ...countries[currency] });
  const [search, setSearch] = useState<string>('');

  /**
   * Updates the visible list of companies on search
   */
  useEffect(() => {
    if (search) {
      const lowerCaseSearch = search.toLowerCase();
      setCountries(
        Object.keys(countriesJson)
          .filter((key) => {
            const country = countriesJson[key];
            return (
              key.toLocaleLowerCase().includes(lowerCaseSearch) ||
              country.name.toLocaleLowerCase().includes(lowerCaseSearch) ||
              country.currency.toLocaleLowerCase().includes(lowerCaseSearch) ||
              country.locale.toLocaleLowerCase().includes(lowerCaseSearch) ||
              country.symbol.toLocaleLowerCase().includes(lowerCaseSearch)
            );
          })
          .reduce((_countries, key) => {
            _countries[key] = countriesJson[key];
            return _countries;
          }, {})
      );
    } else {
      setCountries(countriesJson);
    }
  }, [search]);

  /**
   * Handles submission and resets search
   */
  const handleSubmit = useCallback(() => {
    onSubmit(selectedCurrency);
    setSearch('');
    onClose();
  }, [onClose, onSubmit, selectedCurrency]);

  /**
   * Handles close event and resets search
   */
  const handleClose = useCallback(() => {
    setSearch('');
    onClose();
  }, [onClose]);

  return (
    <Modal
      show={show}
      onClose={handleClose}
      title={`Select Currency: ${selectedCurrency.name} ${selectedCurrency.currency} ${selectedCurrency.symbol}`}
    >
      <Search
        autoFocus
        placeholder="Search"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearch(e.target.value)
        }
      />
      <Container>
        <table>
          <thead>
            <tr>
              <th />
              <th>Country</th>
              <th>Currency</th>
              <th>Symbol</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(countries).map((country: string) => (
              <tr key={country}>
                <td>
                  <Radio
                    for={country}
                    name="country"
                    checked={selectedCurrency.country === country}
                    onChange={() =>
                      setSelectedCurrency({ country, ...countries[country] })
                    }
                  />
                </td>
                <td>
                  <label htmlFor={country}>{countries[country].name}</label>
                </td>
                <td>{countries[country].currency}</td>
                <td>{countries[country].symbol}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Container>
      <Footer>
        <Button color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Footer>
    </Modal>
  );
};

export default CurrencyModal;
