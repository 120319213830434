import React, { useCallback, useEffect, useState } from 'react';
import FolderTable from 'components/FolderList';
import Modal, { ModalProps } from 'components/Modal';
import { GET_COMPANY_FOLDERS } from 'queries/ClientPortal';
import { Company } from 'interfaces/company';
import { ButtonContainer, LoadingContainer } from './styledComponents';
import LoadingIndicator from 'components/LoadingIndicator';
import { useQuery } from '@apollo/client';
import Button from 'components/Button';
import usePrevious from 'hooks/usePrevious';

interface Props
  extends Pick<ModalProps, 'show' | 'onClose' | 'title' | 'zIndex'> {
  /**
   * The company ID to fetch folders for
   */
  companyId: number;
  /**
   * The text within the submit button
   * @default 'Submit'
   */
  submitText?: string;
  /**
   * The callback invoked when pressing the submit button
   */
  onSubmit: (folderIds: number[]) => void;
  /**
   * The IDs of the folders that should be selected by default
   */
  selectedFolderIds?: number[];
  /**
   * Whether the modal is loading
   */
  loading?: boolean;
}

const FolderSelectModal: React.FC<Props> = ({
  show,
  onClose,
  onSubmit,
  title,
  submitText = 'Submit',
  companyId,
  selectedFolderIds = [],
  zIndex,
  loading,
}) => {
  const [selectedIds, setSelectedIds] = useState<number[]>(
    selectedFolderIds || []
  );

  const prevShow = usePrevious(show);

  const {
    data,
    loading: foldersLoading,
    error,
  } = useQuery<{ company: Company }>(GET_COMPANY_FOLDERS, {
    variables: { companyId, skip: !companyId },
  });

  useEffect(() => {
    if (show && !prevShow) {
      setSelectedIds(selectedFolderIds);
    }
  }, [prevShow, selectedFolderIds, show]);

  const handleSelect = (folderId: number, selected: boolean) => {
    if (selected) {
      setSelectedIds((prev) => [...prev, folderId]);
    } else {
      setSelectedIds((prev) => prev.filter((id) => id !== folderId));
    }
  };

  const handleSubmit = useCallback(() => {
    onSubmit(selectedIds);
  }, [onSubmit, selectedIds]);

  return (
    <Modal show={show} onClose={onClose} title={title} zIndex={zIndex}>
      {!data && foldersLoading ? (
        <LoadingContainer>
          <LoadingIndicator independent />
        </LoadingContainer>
      ) : (
        <>
          <FolderTable
            folders={data?.company.folders || []}
            selectedFolderIds={selectedIds}
            onSelect={handleSelect}
            disableChecks={loading || foldersLoading || !!error}
          />
          <ButtonContainer>
            <Button
              color="success"
              onClick={handleSubmit}
              disabled={
                !selectedIds.length || loading || foldersLoading || !!error
              }
            >
              {submitText}
            </Button>
          </ButtonContainer>
        </>
      )}
    </Modal>
  );
};

export default FolderSelectModal;
