/**
 *
 * RateLimitNotification
 *
 */
import { useSubscription } from '@apollo/client';
import gql from 'graphql-tag';
import useCompanyId from 'hooks/useCompanyId';
import React, { useCallback, useState } from 'react';

import FloatingNotification from 'components/FloatingNotification';
import useTimeout from 'hooks/useTimeout';
import { showInfo } from 'utils/popups';

const SUBSCRIPTION = gql`
  subscription RateLimitNotification($companyId: Int!) {
    integrationRateLimitExceeded(companyId: $companyId) {
      integration
      empty
    }
  }
`;
const DAILY_LIMIT_SUBSCRIPTION = gql`
  subscription DailyRateLimitNotification($companyId: Int!) {
    integrationDailyLimitExceeded(companyId: $companyId) {
      integration
    }
  }
`;

const RateLimitNotification = () => {
  const companyId = useCompanyId();
  const [show, setShow] = useState(false);

  const hide = useCallback(() => setShow(false), []);

  const { resetTimeout } = useTimeout(hide, 5000, true);

  const onRateLimitSubscriptionData = useCallback(() => {
    setShow(true);
    resetTimeout();
  }, [resetTimeout]);

  const onDailyLimit = useCallback(() => {
    showInfo({
      title: 'Daily Limit Exceeded',
      text: 'Your accounting software limits how much data can be retrieved daily.',
    });
  }, []);

  useSubscription(SUBSCRIPTION, {
    variables: {
      companyId,
    },
    skip: !companyId,
    onData: onRateLimitSubscriptionData,
  });
  useSubscription(DAILY_LIMIT_SUBSCRIPTION, {
    variables: {
      companyId,
    },
    skip: !companyId,
    onData: onDailyLimit,
  });

  return show ? (
    <FloatingNotification>Loading lots of data...</FloatingNotification>
  ) : null;
};

export default RateLimitNotification;
