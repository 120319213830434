import React, { useMemo, useState, useCallback } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import Fuse from 'fuse.js';
import TimeAgo from 'react-timeago';
import R from 'ramda';
import { useHistory } from 'react-router-dom';

import { GROUP_COMPANY_LIST } from 'fragments';

import CreateConsolidation from 'modals/CreateConsolidation';
import { AddWorkspaceCompanyLocation } from 'locations';
import Spinner from 'components/Spinner';
import GroupUserModal from 'components/GroupUserModal';
import GroupSettingsDropdown from 'components/GroupSettingsDropdown';
import Help from 'components/Help';
import BookMonth from './BookMonth';
import FiscalYearMonth from './FiscalYearMonth';
import { sizes } from 'media';
import useMedia from 'hooks/useMedia';
import useTheme from 'hooks/useTheme';
import lightTheme from 'themes/quantum';

import {
  ParentDiv,
  AvatarContainer,
  Header,
  GroupNameTitle,
  GroupItem3,
  Icon,
  PrimaryButtonStyle,
  SecondaryButtonStyle,
  ActionButtonsContainer,
  GroupDetails,
  Background,
} from './styledComponents';

import { SearchBar, ReactTable, TeamAccessButton } from './components';
import GearLight from './GearLight.svg';
import GearDark from './GearDark.svg';
import AvatarList from 'components/AvatarList';
import TrainingLinks from 'components/TrainingLinks';
import SelectLarge from 'components/SelectLarge';
import ClientPortalAccess from './ClientPortalAccess';
import Alerts from './Alerts';
import Tooltip from 'components/Tooltip';

export const QUERY = gql`
  query GroupCompanyList($groupId: Int!, $workspaceId: Int!) {
    group(id: $groupId) {
      id
      name
      canRename: hasPermission(permission: SET_NAME)
      canDelete: hasPermission(permission: DELETE_FIRM)
      canLeave: hasPermission(permission: LEAVE_FIRM)
      companies {
        ...GroupCompanyListFields
        useLogoDefault
        useThemeDefault
      }
      owner {
        id
        fullName
        email
      }
      managers {
        id
        fullName
        email
      }
    }
    workspace(id: $workspaceId) {
      id
      canAddCompany
      users {
        id
        email
        fullName
      }
      defaultLogoUrl
      defaultTheme
    }
  }
  ${GROUP_COMPANY_LIST}
`;

export const SHARED_QUERY = gql`
  query SharedCompanyList($workspaceId: Int!) {
    workspace(id: $workspaceId) {
      id
      sharedCompanies {
        ...GroupCompanyListFields
        useLogoDefault
        useThemeDefault
      }
      defaultLogoUrl
      defaultTheme
    }
  }
  ${GROUP_COMPANY_LIST}
`;

const desktopColumns = (minimal) => [
  {
    Header: 'Company Name',
    accessor: 'name',
    // company name with icon if aiEnabled is true
    Cell: ({ row }) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ marginRight: 7 }}>{row.original.name}</div>
        {row.original.aiEnabled && (
          <Tooltip content="AI Enhanced Reporting Enabled" position="top">
            <i
              className="mdi-set mdi-shimmer"
              style={{
                fontSize: '17px',
                height: 20,
                marginTop: -4,
                color: lightTheme.colors.brand,
              }}
            />
          </Tooltip>
        )}
      </div>
    ),
  },
  {
    Header: 'Accounting Basis',
    accessor: 'accountingBasis',
  },
  {
    // eslint-disable-next-line react/display-name
    Header: () => (
      <React.Fragment>
        Book Month{' '}
        <Help>
          This is the latest month which the bookkeeping is completed. By
          changing this month, live dates and text will be updated to reflect
          the book month. This is helpful if a client&apos;s books are behind
          and you want live reports and dashboards to only give information that
          is completed through that book month. By default, the book month is
          always the last month.
        </Help>
      </React.Fragment>
    ),
    accessor: 'bookMonth',
    // eslint-disable-next-line react/display-name
    Cell: ({ row }) => (
      <BookMonth
        companyId={row.original.id}
        bookMonth={row.original.bookMonth}
        isBookMonthFixed={row.original.isBookMonthFixed}
        minimal={minimal}
      />
    ),
  },
  {
    Header: 'Start of Fiscal Year',
    accessor: 'fiscalYearMonth',
    // eslint-disable-next-line react/display-name
    Cell: ({ row }) => (
      <FiscalYearMonth
        companyId={row.original.id}
        value={row.original.fiscalYearMonth || 0}
        canModify={row.original.canModifyFiscalYearMonth}
      />
    ),
  },
  {
    Header: 'Last Synced',
    accessor: 'lastSynced',
    // eslint-disable-next-line react/display-name
    Cell: ({ cell: { value } }) => <TimeAgo date={value} minPeriod={30} />,
  },
];

const tabletColumns = [
  {
    Header: 'Company Name',
    accessor: 'name',
  },
  {
    Header: 'Start of Fiscal Year',
    accessor: 'fiscalYearMonth',
    // eslint-disable-next-line react/display-name
    Cell: ({ row }) => (
      <FiscalYearMonth
        companyId={row.original.id}
        value={row.original.fiscalYearMonth || 0}
        canModify={row.original.canModifyFiscalYearMonth}
      />
    ),
  },
  {
    Header: 'Last Synced',
    accessor: 'lastSynced',
    // eslint-disable-next-line react/display-name
    Cell: ({ cell: { value } }) => <TimeAgo date={value} minPeriod={30} />,
  },
];

const phoneColumns = (workspaceId) => [
  {
    Header: 'Company Name',
    accessor: 'name',
  },
  {
    Header: '',
    accessor: 'clientPortal',
    Cell: function ClientPortal({ row }) {
      return (
        <ClientPortalAccess
          companyId={row.original.id}
          workspaceId={workspaceId}
          isConsolidation={row.original.isConsolidation}
        />
      );
    },
  },
];

const items = ['Cash', 'Accrual'];

const sortByNameCaseInsensitive = R.sortBy(
  R.compose(R.toLower, R.prop('name'))
);

const GroupCompanyList = ({
  group,
  groups,
  onChangeGroup,
  workspaceId,
  needsToPay,
  ownerNeedsToPay,
  tablet,
  headerHeight,
}) => {
  const [showConsolidation, setShowConsolidation] = useState(false);
  const [showTeamAccess, setShowTeamAccess] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const desktop = useMedia([`(min-width: ${sizes.desktop}px)`], [false], true);
  const phone = useMedia([`(min-width: ${sizes.phone}px)`], [false], true);
  const theme = useTheme();
  const history = useHistory();

  const handleConsolidate = useCallback(() => setShowConsolidation(true), []);
  const handleCloseConsolidate = useCallback(
    () => setShowConsolidation(false),
    []
  );
  const handleTeamAccess = useCallback(() => setShowTeamAccess(true), []);
  const handleCloseTeamAccess = useCallback(() => setShowTeamAccess(false), []);

  const handleCloseGroupUserModal = () => setShowTeamAccess(false);

  const shared = group && group.id === -1;

  const defaultExpandedId = useMemo(
    () => history.location.state && history.location.state.expandedCompanyId,
    [history.location.state]
  );

  const handleAddCompany = () => {
    history.push(AddWorkspaceCompanyLocation.toUrl({ workspaceId }));
  };

  const handleOnSearchText = (e) => {
    setSearchValue(e.target.value);
  };

  const handleGroupChange = (selectedGroup) =>
    onChangeGroup(selectedGroup.value);

  const { data, loading } = useQuery(shared ? SHARED_QUERY : QUERY, {
    variables: {
      groupId: group && group.id,
      workspaceId,
    },
    skip: !group,
  });

  const list = useMemo(() => {
    const result =
      (shared
        ? data && data.workspace && data.workspace.sharedCompanies
        : data && data.group && data.group.companies) || [];

    return sortByNameCaseInsensitive(result);
  }, [data, shared]);

  const users = useMemo(() => {
    const managers =
      (shared ? [] : !!data && !!data.group && data.group.managers) || [];
    const owner = !!data && !!data.group && data.group.owner;

    if (owner) {
      return [owner, ...managers.filter((u) => u.id !== owner.id)];
    }

    return managers;
  }, [data, shared]);

  const fuse = useMemo(
    () =>
      new Fuse(list, {
        keys: [
          {
            name: 'name',
            weight: 1,
          },
        ],
        threshold: 0.4,
      }),
    [list]
  );

  const columns = useMemo(
    () =>
      tablet
        ? phone
          ? phoneColumns(workspaceId)
          : tabletColumns
        : desktopColumns(desktop),
    [desktop, phone, tablet, workspaceId]
  );

  const getLastSynced = useCallback((company) => {
    if (company.isConsolidation) {
      const nonGustoCompanies = company.lastSyncedByIntegration.filter(
        (item) => item.integration !== 'GUSTO'
      );
      return nonGustoCompanies.length
        ? moment.min(nonGustoCompanies.map((item) => moment(item.lastSynced)))
        : moment(company.updatedAt);
    }

    return company.lastSyncedByIntegration.length
      ? moment.min(
          company.lastSyncedByIntegration.map((item) => moment(item.lastSynced))
        )
      : moment(company.updatedAt);
  }, []);

  const lastSyncedByIntegrationMap = useCallback(
    (lastSyncedByIntegrationArray) => {
      return lastSyncedByIntegrationArray.map((item) => {
        return {
          integration: item.integration,
          __typename: 'LastSyncedIntegration',
          lastSynced: moment(item.lastSynced),
        };
      });
    }
  );

  const rows = useMemo(() => {
    const filteredRows = searchValue ? fuse.search(searchValue) : list;

    return filteredRows.map((company) => ({
      id: company.id,
      name: company.name,
      groupId: company.firmId,
      users: company.users,
      clientPortalUsers: company.clientPortalUsers,
      isConsolidation: company.isConsolidation,
      isConsolidationEmpty: company.isConsolidationEmpty,
      isConsolidationOwner: company.isConsolidationOwner,
      canManageConsolidation: company.canManageConsolidation,
      hasAccountingIntegration: !!company.accounting,
      accountingBasis: company.accounting && company.accounting.accountingBasis,
      hasAccountingBasis:
        company.accounting && company.accounting.hasAccountingBasis,
      bookMonth: company.bookMonth,
      fiscalYearMonth: company.fiscalYearMonth,
      accountingIntegration:
        company.accounting && company.accountingIntegration,
      isBookMonthFixed: company.isBookMonthFixed,
      lastSynced: getLastSynced(company),
      lastSyncedByIntegration: company.lastSyncedByIntegration.length
        ? lastSyncedByIntegrationMap(company.lastSyncedByIntegration)
        : company.lastSyncedByIntegration,
      aiEnabled: company.aiEnabled,
      canModifyFiscalYearMonth:
        company.accounting && company.accounting.canModifyFiscalYearMonth,
      canDisconnectCompany: company.canDisconnectCompany,
      canShare: company.canShare,
      logo: company.logo && company.logo.url,
      useLogoDefault: company.useLogoDefault,
      useThemeDefault: company.useThemeDefault,
      defaultLogoUrl: data.workspace.defaultLogoUrl,
      defaultTheme: data.workspace.defaultTheme,
      needsToPay,
      ownerNeedsToPay,
      clientPortal: '',
      integration: company?.accounting?.integration,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, fuse, list, needsToPay, ownerNeedsToPay]);

  const expanded = useMemo(() => {
    if (defaultExpandedId) {
      const row = rows.findIndex((r) => r.id === defaultExpandedId);
      if (row > -1) {
        return {
          [row]: true,
        };
      }
    }
    return {};
  }, [defaultExpandedId, rows]);

  if (
    !data ||
    (shared ? !data.workspace : !data.group) ||
    !group ||
    !group.id ||
    loading
  ) {
    return (
      <div style={{ height: '100vh' }}>
        <Spinner />
      </div>
    );
  }

  return (
    <ParentDiv headerHeight={headerHeight}>
      <Header minimal={desktop}>
        <GroupDetails minimal={desktop && !tablet}>
          {tablet ? (
            <SelectLarge
              items={groups.map((_group) => ({
                item: _group,
                label: _group.name,
                value: _group.id,
              }))}
              selected={{
                item: group,
                label: group.name,
                value: group.id,
              }}
              onSelect={(value) => handleGroupChange(value)}
            />
          ) : (
            <GroupNameTitle>{group.name}</GroupNameTitle>
          )}

          {!shared && !phone && (
            <>
              <GroupItem3>
                <GroupSettingsDropdown
                  trigger={
                    theme.mode === 'light' ? (
                      <Icon src={GearLight} />
                    ) : (
                      <Icon src={GearDark} />
                    )
                  }
                  workspaceId={workspaceId}
                  groupId={group.id}
                  empty={!group.companies.length}
                  canRename={data.group.canRename}
                  canDelete={data.group.canDelete}
                  canLeave={data.group.canLeave}
                />
              </GroupItem3>
              <TeamAccessButton
                onClick={handleTeamAccess}
                content="Group Access"
                className="group-access-button"
              />
              <AvatarContainer>
                <AvatarList users={users} />
              </AvatarContainer>
            </>
          )}
        </GroupDetails>
        <ActionButtonsContainer>
          <SearchBar
            minimal={desktop}
            onChange={handleOnSearchText}
            value={searchValue}
          />
          {!shared && !phone && (
            <React.Fragment>
              <SecondaryButtonStyle
                color="default"
                outline
                onClick={handleConsolidate}
                className="consolidate-button"
                id="consolidate-button"
              >
                Consolidate
              </SecondaryButtonStyle>
              {data.workspace.canAddCompany && (
                <PrimaryButtonStyle
                  color="primary"
                  outline
                  to={AddWorkspaceCompanyLocation.toUrl({ workspaceId })}
                  className="add-company-button"
                  id="add-company-button"
                >
                  Add Company
                </PrimaryButtonStyle>
              )}
            </React.Fragment>
          )}
        </ActionButtonsContainer>
        {phone && (
          <Alerts
            alerts={[
              {
                id: 1,
                title: 'Heads up!',
                message:
                  'Reach Reporting is a powerful software for creating customized reports, optimized for desktop use. For the best user experience, we recommend logging in on your computer. However, our client portal also offers a mobile view option for easy viewing on-the-go.',
              },
            ]}
          />
        )}
      </Header>
      <ReactTable
        data={rows}
        dataColumns={columns}
        items={items}
        workspaceId={workspaceId}
        groupId={group.id}
        expanded={expanded}
        tablet={tablet}
        phone={phone}
      />

      {phone && !rows.length ? (
        <p style={{ margin: '2rem', textAlign: 'center' }}>No Companies</p>
      ) : (
        <TrainingLinks
          onAddCompany={handleAddCompany}
          sharedWithMe={shared}
          empty={!rows.length}
          canAdd={data.workspace.canAddCompany}
          phone={phone}
        />
      )}

      <CreateConsolidation
        show={showConsolidation}
        workspaceId={workspaceId}
        firmId={group && group.id}
        onClose={handleCloseConsolidate}
      />

      <GroupUserModal
        key={`group_modal_${group.id}`}
        show={showTeamAccess}
        handleCloseGroupUserModal={handleCloseGroupUserModal}
        groupId={group.id}
        workspaceId={workspaceId}
        onClose={handleCloseTeamAccess}
      />
      <Background />
    </ParentDiv>
  );
};

export default GroupCompanyList;
