import React from 'react';

const XeroLogoSmall = ({
  width = '80px',
  height = null,
  cursor = null,
}: React.SVGProps<SVGSVGElement> & {
  cursor?: string;
}): React.ReactElement => {
  return (
    <svg
      style={{ cursor: cursor }}
      width={width}
      height={height}
      viewBox="0 0 500 500"
    >
      <circle cx="250" cy="250" r="250" fill="#25b5e9" />
      <path
        d="M180.89,256.44c3.7,15.49,11.6,25.74,25.5,30.49,14.67,5,28.2,2.53,39.75-8.31,1.3-1.22,2.12-3.32,3.6-3.89,3.76-1.45,7.76-2.25,11.66-3.32-.12,4.42,1.47,10-.62,13.07-12.82,18.7-40.4,25.64-62.53,16.6S161,266.3,164.7,244.16c4.47-27,25.49-46.32,51.69-47.58,24.55-1.18,46.83,15,53.88,39.19,4,13.68-1.29,20.7-15.4,20.68-24.24,0-48.48,0-74,0Zm75.24-15.51a36.73,36.73,0,0,0-36.4-29.6c-18.23,0-33.48,12-36.9,29.6Z"
        fill="#f7fcfe"
      />
      <path
        d="M381.28,304.18c-30.42,0-54-24-53.89-54.69.13-29.43,24.51-53.27,54.47-53.26s54.32,24.83,54.16,54.92c-.15,29.26-24.72,53.07-54.74,53Zm38.87-53.79a38.76,38.76,0,0,0-38.5-39c-20.85-.09-38.94,17.89-39.07,38.85-.14,21.27,17.39,38.71,38.88,38.68,22,0,38.72-16.7,38.7-38.49Z"
        fill="#f8fcfe"
      />
      <path
        d="M122.53,250.59C136.1,264,148.85,276,160.79,288.84c2.67,2.87,4.85,10,3.22,12.33-4.06,5.76-9.35,2.4-13.44-1.78-12.23-12.51-24.44-25-37.63-38.56-9.58,9.42-18.15,17.84-26.71,26.27-4.59,4.52-8.64,9.85-13.93,13.29-3,1.95-8,.82-12.08,1.08.66-3.79,0-8.84,2.19-11.15,12.57-13.19,25.83-25.72,40-39.59-12-11.84-23.18-22.84-34.33-33.85-2.29-2.26-5.51-4.24-6.5-7-1.24-3.46-.61-7.58-.79-11.42,3.76.43,8.88-.55,11.06,1.53C85.25,212.8,98,226.3,112.29,240.91c13.18-13.46,25.31-26,37.58-38.34,3.9-3.92,8-10,14.1-3.61,5.64,6,.26,10.4-3.77,14.42-12.15,12.11-24.4,24.11-37.66,37.2Z"
        fill="#f6fbfe"
      />
      <path
        d="M287.52,249.86c0-13.21-.15-26.42,0-39.63.17-11.87,2.64-13.32,13.71-9.48,1.92.66,4.5-.37,6.73-.85,4.5-1,9.07-3.17,13.4-2.73,3,.3,5.55,4.2,8.3,6.48-2.28,2.61-4.1,6.74-6.9,7.57-18.28,5.44-19.49,6.54-19.65,25.82-.15,17.85,0,35.7-.06,53.55,0,5.86,0,12.71-7.83,12.75-8.05,0-7.62-7.14-7.69-12.79-.17-13.56-.06-27.13-.05-40.7Z"
        fill="#f3fafe"
      />
      <path
        d="M380.27,263.36c-7.67-1.27-12-5.77-11.88-13.72.08-7.46,6.83-13.11,14.36-12.52A13,13,0,0,1,394.54,252C393.52,260.27,388.18,263.34,380.27,263.36Z"
        fill="#f9fcfe"
      />
    </svg>
  );
};

export default XeroLogoSmall;
