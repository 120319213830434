import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { captureException } from 'utils/sentry';

const SAVE_LOGO = gql`
  mutation SaveCompanyLogo($companyId: Int!, $url: String!, $hmac: String!) {
    setCompanyLogo(companyId: $companyId, url: $url, hmac: $hmac) {
      id
      logo {
        id
        url
      }
    }
  }
`;

const useSaveLogo = (): {
  save: (companyId: number, logoUrl: string, hmac: string) => Promise<void>;
  loading: boolean;
  error: any;
} => {
  const [saveLogo, { loading, error }] = useMutation(SAVE_LOGO);

  const save = useCallback(
    async (companyId: number, logoUrl: string, hmac: string) => {
      try {
        await saveLogo({
          variables: {
            companyId,
            url: logoUrl,
            hmac,
          },
        });
      } catch (e) {
        console.error(e);
        captureException(e);
      }
    },
    [saveLogo]
  );

  return { save, loading, error };
};

export default useSaveLogo;
