import TrainingVideos from 'modals/TraningVideos';
/**
 *
 * TrainingButton
 *
 */
import React, { useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import BetaBadge from 'components/BetaBadge';
import { linkCss } from 'components/RightSidebar/Main';
import IconButton from 'components/RightSidebar/IconButton';

const Button = styled.button`
  ${({ sidebar }) =>
    sidebar
      ? css`
          ${linkCss}
        `
      : css`
          font-size: 0.75rem;
          color: ${(props) =>
            props.workspace || props.minimal
              ? props.theme.workspaceHeader.color
              : props.theme.header.color};
        `}
  display: flex;
  align-items: center;
  white-space: nowrap;
  border-radius: 5px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 48.8px;
  margin-right: 5px;
  &:hover {
    background-color: ${(props) =>
      props.theme.mode === 'light'
        ? props.theme.header.buttonHighlight
        : '#393E46'};
  }
  color: ${(props) =>
    props.workspace || props.minimal
      ? props.theme.colors.gear
      : props.theme.header.color};
`;

const I = styled.i`
  font-size: ${(props) => (props.sidebar ? 'inherit' : '1.5rem')};
  margin-right: 3px;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0;
  color: ${(props) =>
    props.workspace || props.minimal
      ? props.theme.colors.gear
      : props.theme.header.color};
`;

const QUERY = gql`
  query completedTrainingVideos {
    basicTrainingVideos: getTrainingVideosBySectionName(sectionName: "BASIC") {
      id
    }
    completedTrainingVideos {
      id
      trainingId
    }
  }
`;

const TrainingButton = (props) => {
  const { workspace = false, minimal, sidebar, callback, icon } = props;
  const { data } = useQuery(QUERY);

  const [show, setShow] = useState(false);
  const uncompleted = useMemo(() => {
    if (data) {
      return (
        data.basicTrainingVideos.length !== data.completedTrainingVideos.length
      );
    } else {
      return false;
    }
  }, [data]);

  const IconComponent = sidebar ? 'i' : I;

  const television = useMemo(
    () =>
      uncompleted ? (
        <BetaBadge
          text={'!'}
          fontSize={'0.65rem'}
          top={-16}
          right={2}
          style={{ width: '15px', borderRadius: '50%' }}
        >
          <IconComponent
            workspace={workspace}
            className="mdi-set mdi-television-play"
            iconClass="mdi-set mdi-television-play"
            minimal={minimal}
            sidebar={sidebar}
          />
        </BetaBadge>
      ) : (
        <IconComponent
          workspace={workspace}
          className="mdi-set mdi-television-play"
          iconClass="mdi-set mdi-television-play"
          minimal={minimal}
          sidebar={sidebar}
        />
      ),
    [IconComponent, minimal, sidebar, uncompleted, workspace]
  );

  const handleShow = useCallback(() => {
    callback && callback();
    setShow(true);
  }, [callback]);
  const handleClose = useCallback(() => setShow(false), []);

  const Component = sidebar ? IconButton : Button;

  return (
    <>
      <Component
        onClick={handleShow}
        workspace={workspace}
        minimal={minimal}
        sidebar={sidebar}
        id="training-videos-button"
        iconClass="mdi-set mdi-television-play"
        text="Video Tutorials"
      >
        {icon && television}
        Video Tutorials
      </Component>
      <TrainingVideos show={show} onClose={handleClose} />
    </>
  );
};

export default TrainingButton;
