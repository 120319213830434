/**
 *
 * CreateFirm
 *
 */

import React from 'react';
import styled from 'styled-components';
import { Form } from 'informed';
import { Mutation } from '@apollo/client/react/components';
import gql from 'graphql-tag';

import Modal from 'components/Modal';
import TextField from 'components/TextField';
import Button from 'components/Button';

const Inner = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
`;

const ErrorDiv = styled.div`
  color: ${(props) => props.theme.colors.danger};
  font-size: 13px;
`;

const CREATE = gql`
  mutation CreateFirm($name: String!) {
    createFirm(name: $name) {
      id
    }
  }
`;

const QUERY = gql`
  query CreateFirmUpdate {
    currentUser {
      firms {
        id
      }
    }
  }
`;

function validate(value) {
  if (!value) return 'Name is required';
  if (value.length > 35) return 'Name cannot be more than 35 characters';
  return undefined;
}

import COMPANIES_QUERY from 'routes/Companies/query.gql';

class CreateFirm extends React.PureComponent {
  getApi = (formApi) => {
    this.formApi = formApi;
  };
  render() {
    const { firmId } = this.props;
    return (
      <Modal
        show={this.props.show}
        title="Create Folder"
        onClose={this.props.onClose}
        width={400}
      >
        <Mutation
          mutation={CREATE}
          onCompleted={() => {
            this.formApi.reset();
            this.props.onClose();
          }}
        >
          {(create) => (
            <Form
              getApi={this.getApi}
              onSubmit={({ name }) => {
                create({
                  variables: {
                    firmId,
                    name,
                  },
                  refetchQueries: [
                    {
                      query: COMPANIES_QUERY,
                    },
                  ],
                });
              }}
            >
              {({ formState }) => (
                <div>
                  <Inner>
                    <TextField
                      type="text"
                      field="name"
                      autoFocus
                      validate={validate}
                    />
                    <StyledButton type="submit" color="primary">
                      Create
                    </StyledButton>
                  </Inner>
                  <ErrorDiv>{formState.errors.name || null}</ErrorDiv>
                </div>
              )}
            </Form>
          )}
        </Mutation>
      </Modal>
    );
  }
}

export default CreateFirm;
