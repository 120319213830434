import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

import useIsLoggedIn from './useIsLoggedIn';

const QUERY = gql`
  query UseClientPortalOnly {
    currentUser {
      clientPortalOnly
    }
  }
`;

export default function useClientPortalOnly() {
  const loggedIn = useIsLoggedIn();
  const { data, loading, error } = useQuery(QUERY, {
    skip: !loggedIn,
  });

  if (loading || error || !data || !data.currentUser) return null;

  return data && data.currentUser && data.currentUser.clientPortalOnly;
}
