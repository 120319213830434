const moment = require('moment');

const {
  getStartDate,
  getEndDate,
  getComparisonStartDate,
  getComparisonEndDate,
  getFiscalQuarterStart,
} = require('../../library/ranges');

function generateLastQuartersOptions(live, count, now, grouping, range) {
  const currentDate = now.clone().startOf('quarter').subtract(1, 'quarter');
  const startDate = currentDate.clone().subtract(count - 1, 'quarters');
  const endDate = currentDate.clone().endOf('quarter');
  const result = [];

  if (grouping === 'total') {
    return [
      {
        startDate,
        endDate,
        range: live ? range : null,
        rangeOffset: live ? 0 : null,
      },
    ];
  }

  const last4Ranges = [
    'LAST_QUARTER',
    'LAST_QUARTER_BACK_ONE',
    'LAST_QUARTER_BACK_TWO',
    'LAST_QUARTER_BACK_THREE',
  ];

  let index = count - 1;

  for (
    let date = startDate.clone();
    date.isSameOrBefore(endDate);
    date.add(3, 'months')
  ) {
    let rangeToUse = live ? 'LAST_QUARTER' : null;
    let rangeOffset = live ? date.diff(currentDate, 'months') : null;
    if (index < 4 && rangeToUse) {
      rangeToUse = last4Ranges[index];
      rangeOffset = 0;
    }

    result.push({
      title: live ? null : `Q${date.quarter()} ${date.year()}`,
      type: 'DATE',
      startDate: date.clone(),
      endDate: date.clone().add(2, 'months'),
      range: rangeToUse,
      rangeOffset,
    });

    index--;
  }

  return result;
}
module.exports.generateLastQuartersOptions = generateLastQuartersOptions;

const updatesAnnuallyHelpIconClass = 'mdi-set mdi-calendar-blank';
const updateAnnuallyHelpText = 'updates annually';

function getMonthlyDates(
  startDate,
  endDate,
  live,
  _now,
  type = 'DATE',
  budgetId = -1,
  budgetType
) {
  const date = startDate.clone();
  const now = _now || moment.utc().startOf('month');
  const result = [];
  while (date.isSameOrBefore(endDate, 'month')) {
    result.push({
      type,
      startDate: date.clone(),
      endDate: date.clone(),
      range: live ? 'CURRENT_MONTH' : null,
      rangeOffset: live ? date.diff(now, 'months') : null,
      budgetId,
      budgetType,
    });

    date.add(1, 'month');
  }

  return result;
}

function getMonthlyComparisonDates(
  comparison,
  customComparisonStartDate,
  startDate,
  endDate,
  live,
  _now
) {
  const now = _now || moment.utc().startOf('month');
  const comparisonStartDate = getComparisonStartDate(
    startDate,
    endDate,
    comparison,
    customComparisonStartDate,
    now
  );

  const comparisonEndDate = getComparisonEndDate(
    startDate,
    endDate,
    comparison,
    customComparisonStartDate,
    now
  );

  return getMonthlyDates(comparisonStartDate, comparisonEndDate, live, _now);
}

function getOptionsFromRange(range, live, grouping, _now, fiscalYearMonth) {
  const now = _now || moment.utc();

  const startDate = getStartDate(range, now, now, null, fiscalYearMonth);
  const endDate = getEndDate(range, now, now, now, null, fiscalYearMonth);
  const currentMonth = now.clone().startOf('month');

  if (grouping === 'total') {
    return [
      {
        startDate,
        endDate,
        range: live ? range : null,
        rangeOffset: live ? 0 : null,
      },
    ];
  }

  const date = startDate.clone();

  const result = [];

  let updateQuarterly = false;
  let quarterOffset = 0;

  if (
    range.indexOf('LAST_QUARTER') === 0 ||
    range.indexOf('LAST_FISCAL_QUARTER') === 0
  ) {
    updateQuarterly = true;
    if (
      range === 'LAST_QUARTER_BACK_ONE' ||
      range === 'LAST_FISCAL_QUARTER_BACK_ONE'
    )
      quarterOffset = -3;
    if (
      range === 'LAST_QUARTER_BACK_TWO' ||
      range === 'LAST_FISCAL_QUARTER_BACK_TWO'
    )
      quarterOffset = -6;
    if (
      range === 'LAST_QUARTER_BACK_THREE' ||
      range === 'LAST_FISCAL_QUARTER_BACK_THREE'
    )
      quarterOffset = -9;
    if (
      range === 'LAST_QUARTER_BACK_FOUR' ||
      range === 'LAST_FISCAL_QUARTER_BACK_FOUR'
    )
      quarterOffset = -12;
  }

  const quarterMonths = [
    'LAST_QUARTER_FIRST_MONTH',
    'LAST_QUARTER_SECOND_MONTH',
    'LAST_QUARTER_THIRD_MONTH',
  ];

  const quarterFiscalRanges = [
    'LAST_FISCAL_QUARTER',
    'LAST_FISCAL_QUARTER_BACK_ONE',
    'LAST_FISCAL_QUARTER_BACK_TWO',
    'LAST_FISCAL_QUARTER_BACK_THREE',
    'LAST_FISCAL_QUARTER_BACK_FOUR',
  ];

  const quarterFiscalMonths = [
    'LAST_FISCAL_QUARTER_FIRST_MONTH',
    'LAST_FISCAL_QUARTER_SECOND_MONTH',
    'LAST_FISCAL_QUARTER_THIRD_MONTH',
  ];

  const currentQuarterRanges = ['CURRENT_QUARTER'];

  const currentQuarterMonths = [
    'CURRENT_QUARTER_FIRST_MONTH',
    'CURRENT_QUARTER_SECOND_MONTH',
    'CURRENT_QUARTER_THIRD_MONTH',
  ];

  const currentQuarterFiscalRanges = ['CURRENT_FISCAL_QUARTER'];

  const currentQuarterFiscalMonths = [
    'CURRENT_FISCAL_QUARTER_FIRST_MONTH',
    'CURRENT_FISCAL_QUARTER_SECOND_MONTH',
    'CURRENT_FISCAL_QUARTER_THIRD_MONTH',
  ];

  let index = 0;
  while (date.isSameOrBefore(endDate, 'month')) {
    let liveRange = live ? 'CURRENT_MONTH' : null;
    let rangeOffset = live ? date.diff(currentMonth, 'months') : null;
    if (liveRange && updateQuarterly && quarterFiscalRanges.includes(range)) {
      liveRange = quarterFiscalMonths[index % quarterFiscalMonths.length];
      rangeOffset = quarterOffset;
    } else if (liveRange && currentQuarterRanges.includes(range)) {
      liveRange = currentQuarterMonths[index % currentQuarterMonths.length];
      rangeOffset = 0;
    } else if (liveRange && currentQuarterFiscalRanges.includes(range)) {
      liveRange =
        currentQuarterFiscalMonths[index % currentQuarterFiscalMonths.length];
      rangeOffset = 0;
    } else if (liveRange && updateQuarterly) {
      liveRange = quarterMonths[index % quarterMonths.length];
      rangeOffset = quarterOffset;
    }

    result.push({
      startDate: date.clone(),
      endDate: date.clone(),
      range: liveRange,
      rangeOffset,
    });

    date.add(1, 'month');

    index++;
  }

  return result;
}

function getTwelveFiscalMonthsOptions(
  range,
  live,
  grouping,
  _now,
  fiscalYearMonth,
  additionalOffset = 0,
  fiscalEndRange
) {
  const now = _now || moment.utc();

  const startDate = getStartDate(range, now, now, null, fiscalYearMonth);
  const endDate = getEndDate(range, now, now, now, null, fiscalYearMonth);

  if (grouping === 'total') {
    return [
      {
        startDate,
        endDate,
        range: live ? range : null,
        rangeOffset: live ? 0 : null,
      },
    ];
  }

  const date = startDate.clone();

  const result = [];

  const fiscalEnd = fiscalEndRange || 'FINAL_FISCAL_MONTH';

  while (date.isSameOrBefore(endDate, 'month')) {
    result.push({
      startDate: date.clone(),
      endDate: date.clone(),
      range: live ? fiscalEnd : null,
      rangeOffset: live
        ? date.diff(endDate, 'months') - additionalOffset
        : null,
    });

    date.add(1, 'month');
  }

  return result;
}

function getTwelveCalendarMonthsOptions(
  range,
  live,
  grouping,
  _now,
  fiscalYearMonth,
  additionalOffset = 0,
  yearEndRange
) {
  const now = _now || moment.utc();

  const startDate = getStartDate(range, now, now, null, fiscalYearMonth);
  const endDate = getEndDate(range, now, now, now, null, fiscalYearMonth);

  if (grouping === 'total') {
    return [
      {
        startDate,
        endDate,
        range: live ? range : null,
        rangeOffset: live ? 0 : null,
      },
    ];
  }

  const date = startDate.clone();

  const result = [];

  const finalRange = yearEndRange || 'FINAL_CALENDAR_MONTH';

  while (date.isSameOrBefore(endDate, 'month')) {
    result.push({
      startDate: date.clone(),
      endDate: date.clone(),
      range: live ? finalRange : null,
      rangeOffset: live
        ? date.diff(endDate, 'months') - additionalOffset
        : null,
    });

    date.add(1, 'month');
  }

  return result;
}

function getFourQuartersUpdateAnnuallyOptions(
  _now,
  fiscalYearMonth,
  calendar,
  live,
  grouping,
  futureYearOffset = 0
) {
  const futureYears = {
    0: '',
    1: 'NEXT_YEAR_',
    2: 'NEXT_YEAR_PLUS_ONE_',
    3: 'NEXT_YEAR_PLUS_TWO_',
    4: 'NEXT_YEAR_PLUS_THREE_',
    5: 'NEXT_YEAR_PLUS_FOUR_',
  };

  const now = _now || moment.utc();

  const futureYearsForward = futureYearOffset || 0;

  const futureYear = futureYears[futureYearsForward];

  if (grouping === 'total') {
    const range = calendar
      ? futureYear + 'FOUR_CALENDAR_QUARTERS'
      : futureYear + 'FOUR_FISCAL_QUARTERS';
    const startDate = getStartDate(range, now, now, null, fiscalYearMonth);
    const endDate = getEndDate(range, now, now, now, null, fiscalYearMonth);
    return [
      {
        startDate,
        endDate,
        range: live ? range : null,
        rangeOffset: live ? 0 : null,
      },
    ];
  }

  const range = calendar ? 'FINAL_CALENDAR_QUARTER' : 'FINAL_FISCAL_QUARTER';

  const startDate = getStartDate(range, now, now, null, fiscalYearMonth);
  const endDate = getEndDate(range, now, now, now, null, fiscalYearMonth);

  const result = [];

  for (let i = 0; i < 4; i++) {
    if (futureYearsForward && futureYearsForward > 0) {
      result.push({
        startDate: startDate.clone(),
        endDate: endDate.clone(),
        range: live ? range : null,
        rangeOffset: live ? (i + 1) * 3 + 12 * (futureYearsForward - 1) : null,
      });

      startDate.add(3, 'months');
      endDate.add(3, 'months');
    } else {
      result.unshift({
        startDate: startDate.clone(),
        endDate: endDate.clone(),
        range: live ? range : null,
        rangeOffset: live ? -(i * 3) : null,
      });

      startDate.subtract(3, 'months');
      endDate.subtract(3, 'months');
    }
  }
  return result;
}

function getFourQuartersUpdateAnnuallyComparisonOptions(
  _now,
  fiscalYearMonth,
  calendar,
  live,
  comparison,
  customComparisonStartDate,
  grouping,
  futureYearOffset = 0
) {
  const now = _now || moment.utc();

  const range = calendar ? 'FINAL_CALENDAR_QUARTER' : 'FINAL_FISCAL_QUARTER';

  const options = getFourQuartersUpdateAnnuallyOptions(
    now,
    fiscalYearMonth,
    calendar,
    live,
    grouping,
    futureYearOffset
  );

  let startDate;
  let endDate;

  if (comparison === 'CUSTOM') {
    startDate = customComparisonStartDate.clone();
    endDate = startDate.clone().add(2, 'months');
    const actualStartDate = getStartDate(
      range,
      now,
      now,
      null,
      fiscalYearMonth
    );
    const result = [];

    for (let i = 0; i < 4; i++) {
      result.push({
        startDate: startDate.clone(),
        endDate: endDate.clone(),
        range: live ? range : null,
        rangeOffset: live ? startDate.diff(actualStartDate, 'months') : null,
      });

      startDate.add(3, 'months');
      endDate.add(3, 'months');
    }
    return result;
  } else {
    options.forEach((option, idx) => {
      option.startDate = option.startDate.clone().subtract(1, 'year');
      option.endDate = option.endDate.clone().subtract(1, 'year');
      option.rangeOffset = live ? option.rangeOffset - 12 : null;
    });
    return options;
  }
}

function getComparisonOptionsFromRange(
  range,
  live,
  grouping,
  comparison,
  customComparisonStartDate,
  _now,
  fiscalYearMonth,
  rangeBaseline = null
) {
  const now = _now || moment.utc();
  const startDate = getStartDate(range, now, now, null, fiscalYearMonth);
  const endDate = getEndDate(range, now, now, now, null, fiscalYearMonth);
  const currentMonth = now.clone().startOf('month');

  const startDateBaseline = rangeBaseline
    ? getStartDate(rangeBaseline, now, now, null, fiscalYearMonth)
    : null;

  const comparisonStartDate = getComparisonStartDate(
    startDate,
    endDate,
    comparison,
    customComparisonStartDate,
    now
  );

  const comparisonEndDate = getComparisonEndDate(
    startDate,
    endDate,
    comparison,
    customComparisonStartDate,
    now
  );

  const length = startDate.diff(comparisonStartDate, 'month');

  if (grouping === 'total') {
    return [
      {
        startDate: comparisonStartDate,
        endDate: comparisonEndDate,
        range: live ? range : null,
        rangeOffset: live ? -length : null,
      },
    ];
  }

  const date = comparisonStartDate.clone();
  const result = [];

  let updateQuarterly = false;
  let quarterOffset = 0;

  const quarterRanges = [
    'LAST_QUARTER',
    'LAST_QUARTER_BACK_ONE',
    'LAST_QUARTER_BACK_TWO',
    'LAST_QUARTER_BACK_THREE',
    'LAST_QUARTER_BACK_FOUR',
  ];

  const quarterFiscalRanges = [
    'LAST_FISCAL_QUARTER',
    'LAST_FISCAL_QUARTER_BACK_ONE',
    'LAST_FISCAL_QUARTER_BACK_TWO',
    'LAST_FISCAL_QUARTER_BACK_THREE',
    'LAST_FISCAL_QUARTER_BACK_FOUR',
  ];

  if (quarterRanges.includes(range) || quarterFiscalRanges.includes(range)) {
    if (
      range.indexOf('LAST_QUARTER') === 0 ||
      range.indexOf('LAST_FISCAL_QUARTER') === 0
    ) {
      updateQuarterly = true;
      if (
        range === 'LAST_QUARTER_BACK_ONE' ||
        range === 'LAST_FISCAL_QUARTER_BACK_ONE'
      )
        quarterOffset = -3;
      if (
        range === 'LAST_QUARTER_BACK_TWO' ||
        range === 'LAST_FISCAL_QUARTER_BACK_TWO'
      )
        quarterOffset = -6;
      if (
        range === 'LAST_QUARTER_BACK_THREE' ||
        range === 'LAST_FISCAL_QUARTER_BACK_THREE'
      )
        quarterOffset = -9;
      if (
        range === 'LAST_QUARTER_BACK_FOUR' ||
        range === 'LAST_FISCAL_QUARTER_BACK_FOUR'
      )
        quarterOffset = -12;
    }

    const quarterMonths = [
      'LAST_QUARTER_FIRST_MONTH',
      'LAST_QUARTER_SECOND_MONTH',
      'LAST_QUARTER_THIRD_MONTH',
    ];

    const quarterFiscalMonths = [
      'LAST_FISCAL_QUARTER_FIRST_MONTH',
      'LAST_FISCAL_QUARTER_SECOND_MONTH',
      'LAST_FISCAL_QUARTER_THIRD_MONTH',
    ];

    let index = 0;
    while (date.isSameOrBefore(endDate, 'month')) {
      let liveRange = live ? 'CURRENT_MONTH' : null;
      let rangeOffset = live ? date.diff(currentMonth, 'months') : null;
      if (liveRange && updateQuarterly && quarterFiscalRanges.includes(range)) {
        liveRange = quarterFiscalMonths[index % quarterFiscalMonths.length];
        rangeOffset = quarterOffset;
      } else if (liveRange && updateQuarterly) {
        liveRange = quarterMonths[index % quarterMonths.length];
        rangeOffset = quarterOffset;
      }

      result.push({
        startDate: date.clone(),
        endDate: date.clone(),
        range: liveRange,
        rangeOffset:
          comparison === 'PREVIOUS_PERIOD' ? rangeOffset - 3 : rangeOffset - 12,
      });

      date.add(1, 'month');

      index++;
    }
  } else {
    while (date.isSameOrBefore(comparisonEndDate, 'month')) {
      result.push({
        startDate: date.clone(),
        endDate: date.clone(),
        range: live
          ? startDateBaseline
            ? rangeBaseline
            : 'CURRENT_MONTH'
          : null,
        rangeOffset: live
          ? startDateBaseline
            ? date.diff(startDateBaseline, 'months')
            : date.diff(currentMonth, 'months')
          : null,
      });

      date.add(1, 'month');
    }
  }

  return result;
}

function generateLastFiscalQuartersOptions(
  live,
  count,
  now,
  fiscalYearMonth,
  grouping,
  range
) {
  const date = getFiscalQuarterStart(now, fiscalYearMonth, 0, count);
  const startDate = getFiscalQuarterStart(now, fiscalYearMonth, 0, 1);

  if (grouping === 'total') {
    return [
      {
        startDate: date,
        endDate: startDate.clone().endOf('quarter'),
        range: live ? range : null,
        rangeOffset: live ? 0 : null,
      },
    ];
  }

  const last4Ranges = [
    'LAST_FISCAL_QUARTER',
    'LAST_FISCAL_QUARTER_BACK_ONE',
    'LAST_FISCAL_QUARTER_BACK_TWO',
    'LAST_FISCAL_QUARTER_BACK_THREE',
  ];

  const result = [];
  let index = count - 1;

  for (let i = 0; i < count; i++) {
    let quarterRange = live ? 'LAST_FISCAL_QUARTER' : null;
    let rangeOffset = live ? date.diff(startDate, 'months') : null;

    if (index < 4 && quarterRange) {
      quarterRange = last4Ranges[index];
      rangeOffset = 0;
    }

    result.push({
      type: 'DATE',
      startDate: date.clone(),
      endDate: date.clone().add(2, 'months'),
      range: quarterRange,
      rangeOffset,
    });
    date.add(3, 'months');

    index--;
  }

  return result;
}
module.exports.generateLastFiscalQuartersOptions =
  generateLastFiscalQuartersOptions;

const getCustom = (module.exports.getCustom = function (
  startDate,
  endDate,
  live,
  type = 'DATE',
  bookMonth,
  budgetId,
  budgetType
) {
  const now = bookMonth || moment.utc();

  return [
    {
      type,
      startDate: startDate.clone(),
      endDate: endDate.clone(),
      range: live ? 'CUSTOM' : null,
      rangeOffset: live ? startDate.diff(now.startOf('month'), 'months') : null,
      budgetId,
      budgetType,
    },
  ];
});

function getCustomComparison(
  comparison,
  customComparisonStartDate,
  startDate,
  endDate,
  live,
  bookMonth
) {
  const now = bookMonth || moment.utc();

  const comparisonStartDate = getComparisonStartDate(
    startDate,
    endDate,
    comparison,
    customComparisonStartDate,
    now
  );

  const comparisonEndDate = getComparisonEndDate(
    startDate,
    endDate,
    comparison,
    customComparisonStartDate,
    now
  );

  return getCustom(comparisonStartDate, comparisonEndDate, live, 'DATE', now);
}

function getQuarterlyDates(startDate, endDate) {
  if (!startDate) {
    return null;
  }

  const date = startDate.clone();

  const result = [];
  while (date.isSameOrBefore(endDate, 'month')) {
    const quarterEnd = moment.min(endDate, date.clone().add(2, 'months'));

    result.push({
      startDate: date.clone(),
      endDate: quarterEnd,
    });

    date.add('3', 'months');
  }

  return result;
}

function getQuarters(
  startDate,
  endDate,
  live,
  type = 'DATE',
  budgetId = -1,
  budgetType,
  bookMonth
) {
  const dates = getQuarterlyDates(startDate, endDate);
  const result = [];
  const now = bookMonth || moment.utc().startOf('month');
  for (let i = 0; i < dates.length; i++) {
    result.push({
      type,
      startDate: dates[i].startDate.clone(),
      endDate: dates[i].endDate.clone(),
      range: live ? 'CUSTOM_QUARTER' : null,
      rangeOffset: dates[i].startDate.diff(now, 'month'),
      budgetId,
      budgetType,
    });
  }
  return result;
}

function getQuartersComparison(
  comparison,
  customComparisonStartDate,
  startDate,
  endDate,
  live,
  bookMonth
) {
  const now = bookMonth || moment.utc();

  const comparisonStartDate = getComparisonStartDate(
    startDate,
    endDate,
    comparison,
    customComparisonStartDate,
    now
  );

  const comparisonEndDate = getComparisonEndDate(
    startDate,
    endDate,
    comparison,
    customComparisonStartDate,
    now
  );

  return getQuarters(
    comparisonStartDate,
    comparisonEndDate,
    live,
    'DATE',
    -1,
    undefined,
    now
  );
}

function generateLastQuartersComparisonOptions(
  comparison,
  customComparisonStartDate,
  live,
  count,
  now,
  grouping,
  range
) {
  const startDate = now.clone().startOf('quarter').subtract(count, 'quarters');
  const endDate = now.clone().startOf('quarter').subtract(1, 'month');
  const lastQuarterStart = now
    .clone()
    .startOf('quarter')
    .subtract(1, 'quarter');

  const comparisonStartDate = getComparisonStartDate(
    startDate,
    endDate,
    comparison,
    customComparisonStartDate,
    now
  );

  const comparisonEndDate = getComparisonEndDate(
    startDate,
    endDate,
    comparison,
    customComparisonStartDate,
    now
  );

  if (grouping === 'total') {
    return [
      {
        startDate: comparisonStartDate,
        endDate: comparisonEndDate,
        range: live ? range : null,
        rangeOffset: live ? comparisonStartDate.diff(startDate, 'month') : null,
      },
    ];
  }

  const result = [];
  for (
    let date = comparisonStartDate.clone();
    date.isSameOrBefore(comparisonEndDate);
    date.add(3, 'months')
  ) {
    let title = null;
    const optionEndDate = date.clone().add(2, 'months');
    if (
      !live &&
      date.quarter() === endDate.quarter() &&
      date.year() === endDate.year()
    ) {
      title = `Q${date.quarter()} ${date.year()}`;
    }

    result.push({
      title: live ? null : title,
      type: 'DATE',
      startDate: date.clone(),
      endDate: optionEndDate,
      range: live ? 'LAST_QUARTER' : null,
      rangeOffset: live ? date.diff(lastQuarterStart, 'months') : null,
    });
  }

  return result;
}
module.exports.generateLastQuartersComparisonOptions =
  generateLastQuartersComparisonOptions;

function generateLastFiscalQuartersComparisonOptions(
  comparison,
  customComparisonStartDate,
  live,
  count,
  now,
  fiscalYearMonth,
  grouping,
  range
) {
  const startDate = getFiscalQuarterStart(now, fiscalYearMonth, 0, count);
  const endDate = getFiscalQuarterStart(now, fiscalYearMonth, 0, 1).add(
    2,
    'months'
  );

  const lastQuarterStart = getFiscalQuarterStart(now, fiscalYearMonth, 0, 1);

  const comparisonStartDate = getComparisonStartDate(
    startDate,
    endDate,
    comparison,
    customComparisonStartDate,
    now
  );

  const comparisonEndDate = getComparisonEndDate(
    startDate,
    endDate,
    comparison,
    customComparisonStartDate,
    now
  );

  if (grouping === 'total') {
    return [
      {
        startDate: comparisonStartDate,
        endDate: comparisonEndDate,
        range: live ? range : null,
        rangeOffset: live ? comparisonStartDate.diff(startDate, 'month') : null,
      },
    ];
  }

  const result = [];
  for (
    let date = comparisonStartDate.clone();
    date.isSameOrBefore(comparisonEndDate);
    date.add(3, 'months')
  ) {
    const optionEndDate = date.clone().add(2, 'months');

    result.push({
      title: null,
      type: 'DATE',
      startDate: date.clone(),
      endDate: optionEndDate,
      range: live ? 'LAST_FISCAL_QUARTER' : null,
      rangeOffset: live ? date.diff(lastQuarterStart, 'months') : null,
    });
  }

  return result;
}
module.exports.generateLastFiscalQuartersComparisonOptions =
  generateLastFiscalQuartersComparisonOptions;

function getForecastColumns(
  grouping,
  startDate,
  endDate,
  now,
  type,
  budgetId,
  budgetType,
  live
) {
  switch (grouping) {
    case 'monthsSeparate':
      return getMonthlyDates(
        startDate,
        endDate,
        live,
        now,
        type,
        budgetId,
        budgetType
      );
    case 'total':
      return getCustom(
        startDate,
        endDate,
        live,
        type,
        now,
        budgetId,
        budgetType
      );
    case 'quartersSeparate':
      return getQuarters(
        startDate,
        endDate,
        live,
        type,
        budgetId,
        budgetType,
        null
      );
  }
}

const ranges = (module.exports.ranges = [
  {
    value: 'CUSTOM',
    label: 'Custom',
    allowedGroupings: ['monthsSeparate', 'total', 'quartersSeparate'],
    liveDescription: 'Custom: updates monthly',
    getColumnOptions: (live, grouping, customStartDate, customEndDate, now) => {
      switch (grouping) {
        case 'monthsSeparate':
          return getMonthlyDates(customStartDate, customEndDate, live, now);
        case 'total':
          return getCustom(customStartDate, customEndDate, live, 'DATE', now);
        case 'quartersSeparate':
          return getQuarters(
            customStartDate,
            customEndDate,
            live,
            'DATE',
            -1,
            undefined,
            now
          );
      }
    },
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now
    ) => {
      switch (grouping) {
        case 'monthsSeparate':
          return getMonthlyComparisonDates(
            comparison,
            customComparisonStartDate,
            customStartDate,
            customEndDate,
            live,
            now
          );
        case 'total':
          return getCustomComparison(
            comparison,
            customComparisonStartDate,
            customStartDate,
            customEndDate,
            live,
            now
          );
        case 'quartersSeparate':
          return getQuartersComparison(
            comparison,
            customComparisonStartDate,
            customStartDate,
            customEndDate,
            live,
            now
          );
      }
    },
  },
  {
    value: 'FORECAST',
    label: 'Budget / Forecast',
    allowedGroupings: ['monthsSeparate', 'total', 'quartersSeparate'],
    liveDescription: 'Custom: updates monthly',
    getColumnOptions: (live, grouping, customStartDate, customEndDate, now) => {
      switch (grouping) {
        case 'monthsSeparate':
          return getMonthlyDates(
            customStartDate,
            customEndDate,
            live,
            now,
            'FORECAST'
          );
        case 'total':
          return getCustom(
            customStartDate,
            customEndDate,
            live,
            'FORECAST',
            now
          );
        case 'quartersSeparate':
          return getQuarters(
            customStartDate,
            customEndDate,
            live,
            'FORECAST',
            -1,
            undefined,
            now
          );
      }
    },
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now
    ) => {
      switch (grouping) {
        case 'monthsSeparate':
          return getMonthlyComparisonDates(
            comparison,
            customComparisonStartDate,
            customStartDate,
            customEndDate,
            live,
            now
          );
        case 'total':
          return getCustomComparison(
            comparison,
            customComparisonStartDate,
            customStartDate,
            customEndDate,
            live,
            now
          );
        case 'quartersSeparate':
          return getQuartersComparison(
            comparison,
            customComparisonStartDate,
            customStartDate,
            customEndDate,
            live,
            now
          );
      }
    },
  },
  {
    value: 'CURRENT_MONTH',
    label: 'Current Month',
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: ({ rangeOffset }) =>
      `Current Month${
        rangeOffset
          ? rangeOffset * -1 < 0
            ? ` + ${rangeOffset}`
            : ` - ${rangeOffset * -1}`
          : ''
      }: updates monthly`,
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'CURRENT_MONTH',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'CURRENT_MONTH',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'LAST_MONTH',
    label: 'Last Month',
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: 'Last Month: updates monthly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange('LAST_MONTH', live, grouping, now, fiscalYearMonth),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'LAST_MONTH',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'LAST_THREE_MONTHS',
    label: 'Current Month + Last 2',
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: 'Current Month + Last 2: updates monthly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'LAST_THREE_MONTHS',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'LAST_THREE_MONTHS',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'LAST_THREE_FULL_MONTHS',
    label: 'Last 3 Months',
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: 'Last 3 Months: updates monthly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'LAST_THREE_FULL_MONTHS',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'LAST_THREE_FULL_MONTHS',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'LAST_SIX_MONTHS',
    label: 'Current Month + Last 5',
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: 'Current Month + Last 5: updates monthly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'LAST_SIX_MONTHS',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'LAST_SIX_MONTHS',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'LAST_SIX_FULL_MONTHS',
    label: 'Last 6 Months',
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: 'Last 6 months: updates monthly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'LAST_SIX_FULL_MONTHS',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'LAST_SIX_FULL_MONTHS',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'LAST_TWELVE_MONTHS',
    label: 'Current Month + Last 11',
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: 'Current Month + Last 11: updates monthly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'LAST_TWELVE_MONTHS',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'LAST_TWELVE_MONTHS',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'LAST_TWELVE_FULL_MONTHS',
    label: 'Last 12 Months',
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: 'Last 12 Months: updates monthly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'LAST_TWELVE_FULL_MONTHS',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'LAST_TWELVE_FULL_MONTHS',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'TWELVE_FISCAL_MONTHS',
    label: 'Current Fiscal Year',
    selectHelpText: updateAnnuallyHelpText,
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: '12 Fiscal Months (current fiscal year) updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getTwelveFiscalMonthsOptions(
        'TWELVE_FISCAL_MONTHS',
        live,
        grouping,
        now,
        fiscalYearMonth,
        0,
        'FINAL_FISCAL_MONTH'
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'TWELVE_FISCAL_MONTHS',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth,
        'FINAL_FISCAL_MONTH'
      ),
  },
  {
    value: 'NEXT_FISCAL_YEAR',
    label: '12 Fiscal Months (next year)',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: '12 Fiscal Months (next year) updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getTwelveFiscalMonthsOptions(
        'NEXT_FISCAL_YEAR',
        live,
        grouping,
        now,
        fiscalYearMonth,
        0,
        'FINAL_FISCAL_MONTH_NEXT_YEAR'
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'NEXT_FISCAL_YEAR',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth,
        'FINAL_FISCAL_MONTH_NEXT_YEAR'
      ),
  },
  {
    value: 'TWELVE_FISCAL_MONTHS_LAST_YEAR',
    label: 'Last Fiscal Year',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: '12 Fiscal Months (last fiscal year) updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getTwelveFiscalMonthsOptions(
        'TWELVE_FISCAL_MONTHS_LAST_YEAR',
        live,
        grouping,
        now,
        fiscalYearMonth,
        12,
        'FINAL_FISCAL_MONTH'
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'TWELVE_FISCAL_MONTHS_LAST_YEAR',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth,
        'FINAL_FISCAL_MONTH'
      ),
  },

  {
    value: 'TWELVE_FISCAL_MONTHS_LAST_YEAR_BACK_TWO',
    label: '2 Fiscal Years Ago',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: '12 Fiscal Months (2 yrs ago) updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getTwelveFiscalMonthsOptions(
        'TWELVE_FISCAL_MONTHS_LAST_YEAR_BACK_TWO',
        live,
        grouping,
        now,
        fiscalYearMonth,
        24,
        'FINAL_FISCAL_MONTH'
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'TWELVE_FISCAL_MONTHS_LAST_YEAR_BACK_TWO',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth,
        'FINAL_FISCAL_MONTH'
      ),
  },
  {
    value: 'TWELVE_CALENDAR_MONTHS',
    label: 'Current Year',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: '12 Calendar Months (current year) updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getTwelveCalendarMonthsOptions(
        'TWELVE_CALENDAR_MONTHS',
        live,
        grouping,
        now,
        fiscalYearMonth,
        0,
        'FINAL_CALENDAR_MONTH'
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'TWELVE_CALENDAR_MONTHS',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth,
        'FINAL_CALENDAR_MONTH'
      ),
  },
  {
    value: 'FOUR_CALENDAR_QUARTERS',
    label: '4 Calendar Quarters (current year)',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['quartersSeparate', 'total'],
    liveDescription: '4 Calendar Quarters (current year) updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getFourQuartersUpdateAnnuallyOptions(
        now,
        fiscalYearMonth,
        true,
        live,
        grouping
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getFourQuartersUpdateAnnuallyComparisonOptions(
        now,
        fiscalYearMonth,
        true,
        live,
        comparison,
        customComparisonStartDate,
        grouping
      ),
  },
  {
    value: 'FOUR_FISCAL_QUARTERS',
    label: '4 Fiscal Quarters (current year)',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['quartersSeparate', 'total'],
    liveDescription: '4 Fiscal Quarters (current year) updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getFourQuartersUpdateAnnuallyOptions(
        now,
        fiscalYearMonth,
        false,
        live,
        grouping
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getFourQuartersUpdateAnnuallyComparisonOptions(
        now,
        fiscalYearMonth,
        false,
        live,
        comparison,
        customComparisonStartDate,
        grouping
      ),
  },
  {
    value: 'NEXT_CALENDAR_YEAR',
    label: '12 Calendar Months (next year)',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: '12 Calendar Months (next year) updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getTwelveCalendarMonthsOptions(
        'NEXT_CALENDAR_YEAR',
        live,
        grouping,
        now,
        fiscalYearMonth,
        0,
        'FINAL_CALENDAR_MONTH_NEXT_YEAR'
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'NEXT_CALENDAR_YEAR',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth,
        'FINAL_CALENDAR_MONTH_NEXT_YEAR'
      ),
  },
  {
    value: 'TWELVE_CALENDAR_MONTHS_LAST_YEAR',
    label: 'Last Year',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: '12 Calendar Months (last calendar year) updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getTwelveCalendarMonthsOptions(
        'TWELVE_CALENDAR_MONTHS_LAST_YEAR',
        live,
        grouping,
        now,
        fiscalYearMonth,
        12,
        'FINAL_CALENDAR_MONTH'
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'TWELVE_CALENDAR_MONTHS_LAST_YEAR',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth,
        'FINAL_CALENDAR_MONTH'
      ),
  },
  {
    value: 'TWELVE_CALENDAR_MONTHS_LAST_YEAR_BACK_TWO',
    label: '2 Years Ago',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: '12 Calendar Months (2 yrs ago) updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getTwelveCalendarMonthsOptions(
        'TWELVE_CALENDAR_MONTHS_LAST_YEAR_BACK_TWO',
        live,
        grouping,
        now,
        fiscalYearMonth,
        24,
        'FINAL_CALENDAR_MONTH'
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'TWELVE_CALENDAR_MONTHS_LAST_YEAR_BACK_TWO',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth,
        'FINAL_CALENDAR_MONTH'
      ),
  },
  /* {
    value: 'LAST_THREE_FORWARD_THREE',
    label: 'Last 3, Forward 3',
    getColumnOptions: live =>
      getOptionsFromRange('LAST_THREE_FORWARD_THREE', live, true),
  },
  {
    value: 'LAST_SIX_FORWARD_SIX',
    label: 'Last 6, Forward 6',
    getColumnOptions: live =>
      getOptionsFromRange('LAST_SIX_FORWARD_SIX', live, true),
  }, */
  {
    value: 'YEAR_TO_DATE',
    label: 'Year to Current Month',
    allowedGroupings: ['total'],
    liveDescription: 'Year to Date: updates monthly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange('YEAR_TO_DATE', live, grouping, now, fiscalYearMonth),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'YEAR_TO_DATE',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'YEAR_TO_DATE_BACK_ONE_YEAR',
    label: 'Year to Current Month (1 yr ago)',
    liveDescription: 'Year to Current Month (1 yr ago): updates monthly',
    allowedGroupings: ['total'],
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'YEAR_TO_DATE_BACK_ONE_YEAR',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'YEAR_TO_DATE_BACK_ONE_YEAR',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'YEAR_TO_DATE_BACK_TWO_YEARS',
    label: 'Year to Current Month (2 yr ago)',
    allowedGroupings: ['total'],
    liveDescription: 'Year to Current Month (2 yrs ago): updates monthly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'YEAR_TO_DATE_BACK_TWO_YEARS',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'YEAR_TO_DATE_BACK_TWO_YEARS',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'YEAR_TO_LAST_MONTH',
    label: 'Year to Last Month',
    liveDescription: 'Year to Last Month: updates monthly',
    allowedGroupings: ['total'],
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'YEAR_TO_LAST_MONTH',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'YEAR_TO_LAST_MONTH',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'YEAR_TO_LAST_MONTH_BACK_ONE_YEAR',
    label: 'Year to Last Month (1 yr ago)',
    allowedGroupings: ['total'],
    liveDescription: 'Year to Last Month (1 yr ago): updates monthly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'YEAR_TO_LAST_MONTH_BACK_ONE_YEAR',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'YEAR_TO_LAST_MONTH_BACK_ONE_YEAR',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'YEAR_TO_LAST_MONTH_BACK_TWO_YEARS',
    label: 'Year to Last Month (2 yrs ago)',
    allowedGroupings: ['total'],
    liveDescription: 'Year to Last Month (2 yrs ago): updates monthly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'YEAR_TO_LAST_MONTH_BACK_TWO_YEARS',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'YEAR_TO_LAST_MONTH_BACK_TWO_YEARS',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'FISCAL_YEAR_TO_DATE',
    label: 'Fiscal Year to Current Month',
    allowedGroupings: ['total'],
    liveDescription: 'Fiscal Year to Date: updates monthly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'FISCAL_YEAR_TO_DATE',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'FISCAL_YEAR_TO_DATE',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'FISCAL_YEAR_TO_DATE_BACK_ONE_YEAR',
    label: 'Fiscal Year to Current Month (1 yr ago)',
    allowedGroupings: ['total'],
    liveDescription: 'Fiscal Year to Date (1 yr ago): updates monthly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'FISCAL_YEAR_TO_DATE_BACK_ONE_YEAR',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'FISCAL_YEAR_TO_DATE_BACK_ONE_YEAR',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'FISCAL_YEAR_TO_DATE_BACK_TWO_YEARS',
    label: 'Fiscal Year to Current Month (2 yrs ago)',
    allowedGroupings: ['total'],
    liveDescription: 'Fiscal Year to Date (2 yrs ago): updates monthly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'FISCAL_YEAR_TO_DATE_BACK_TWO_YEARS',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'FISCAL_YEAR_TO_DATE_BACK_TWO_YEARS',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'FISCAL_YEAR_TO_LAST_MONTH',
    label: 'Fiscal Year to Last Month',
    allowedGroupings: ['total'],
    liveDescription: 'Fiscal Year to Last Month: updates monthly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'FISCAL_YEAR_TO_LAST_MONTH',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'FISCAL_YEAR_TO_LAST_MONTH',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'FISCAL_YEAR_TO_LAST_MONTH_BACK_ONE_YEAR',
    label: 'Fiscal Year to Last Month (1 yr ago)',
    allowedGroupings: ['total'],
    liveDescription: 'Fiscal Year to Last Month (1 yr ago): updates monthly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'FISCAL_YEAR_TO_LAST_MONTH_BACK_ONE_YEAR',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'FISCAL_YEAR_TO_LAST_MONTH_BACK_ONE_YEAR',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'FISCAL_YEAR_TO_LAST_MONTH_BACK_TWO_YEARS',
    label: 'Fiscal Year to Last Month (2 yrs ago)',
    allowedGroupings: ['total'],
    liveDescription: 'Fiscal Year to Last Month (2 yrs ago): updates monthly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'FISCAL_YEAR_TO_LAST_MONTH_BACK_TWO_YEARS',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'FISCAL_YEAR_TO_LAST_MONTH_BACK_TWO_YEARS',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'FISCAL_YEAR_TO_LAST_MONTH_BACK_ONE_MONTH',
    label: 'Fiscal Year to Last Month - 1 Mo',
    allowedGroupings: ['total'],
    liveDescription: 'Fiscal Year to Last Month - 1 Mo: updates monthly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'FISCAL_YEAR_TO_LAST_MONTH_BACK_ONE_MONTH',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'FISCAL_YEAR_TO_LAST_MONTH_BACK_ONE_MONTH',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'FISCAL_YEAR_TO_LAST_MONTH_BACK_TWO_MONTHS',
    label: 'Fiscal Year to Last Month - 2 Mo',
    allowedGroupings: ['total'],
    liveDescription: 'Fiscal Year to Last Month - 2 Mo: updates monthly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'FISCAL_YEAR_TO_LAST_MONTH_BACK_TWO_MONTHS',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'FISCAL_YEAR_TO_LAST_MONTH_BACK_TWO_MONTHS',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'FISCAL_YEAR_TO_LAST_MONTH_BACK_THREE_MONTHS',
    label: 'Fiscal Year to Last Month - 3 Mo',
    allowedGroupings: ['total'],
    liveDescription: 'Fiscal Year to Last Month - 3 Mo: updates monthly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'FISCAL_YEAR_TO_LAST_MONTH_BACK_THREE_MONTHS',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'FISCAL_YEAR_TO_LAST_MONTH_BACK_THREE_MONTHS',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'CURRENT_FISCAL_YEAR',
    label: 'Current Fiscal Year',
    selectHelpText: updateAnnuallyHelpText,
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: '12 Fiscal Months (current fiscal year) updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getTwelveFiscalMonthsOptions(
        'TWELVE_FISCAL_MONTHS',
        live,
        grouping,
        now,
        fiscalYearMonth,
        0,
        'FINAL_FISCAL_MONTH'
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'TWELVE_FISCAL_MONTHS',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth,
        'FINAL_FISCAL_MONTH'
      ),
  },
  {
    value: 'LAST_FISCAL_YEAR',
    label: 'Last Fiscal Year',
    selectHelpText: updateAnnuallyHelpText,
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: '12 Fiscal Months (last fiscal year) updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getTwelveFiscalMonthsOptions(
        'TWELVE_FISCAL_MONTHS_LAST_YEAR',
        live,
        grouping,
        now,
        fiscalYearMonth,
        12,
        'FINAL_FISCAL_MONTH'
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'TWELVE_FISCAL_MONTHS_LAST_YEAR',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth,
        'FINAL_FISCAL_MONTH'
      ),
  },
  {
    value: 'LAST_FISCAL_YEAR_BACK_ONE',
    label: '2 Fiscal Years Ago',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: '12 Fiscal Months (2 yrs ago) updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getTwelveFiscalMonthsOptions(
        'TWELVE_FISCAL_MONTHS_LAST_YEAR_BACK_TWO',
        live,
        grouping,
        now,
        fiscalYearMonth,
        24,
        'FINAL_FISCAL_MONTH'
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'TWELVE_FISCAL_MONTHS_LAST_YEAR_BACK_TWO',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth,
        'FINAL_FISCAL_MONTH'
      ),
  },
  {
    value: 'CURRENT_FISCAL_QUARTER',
    label: 'Current Fiscal Quarter',
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: 'Current Fiscal Quarter: updates quarterly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'CURRENT_FISCAL_QUARTER',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'CURRENT_FISCAL_QUARTER',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'CURRENT_FISCAL_QUARTER_FIRST_MONTH',
    label: 'Current Quarter (First Month)',
    allowedGroupings: ['total'],
    liveDescription: ({ rangeOffset }) => {
      if (rangeOffset) {
        return `Current Fiscal Quarter - ${-rangeOffset / 3}: First Month`;
      } else {
        return 'Current Fiscal Quarter: First Month';
      }
    },
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'CURRENT_FISCAL_QUARTER_FIRST_MONTH',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'CURRENT_FISCAL_QUARTER_FIRST_MONTH',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'CURRENT_FISCAL_QUARTER_SECOND_MONTH',
    label: 'Current Quarter (Second Month)',
    allowedGroupings: ['total'],
    liveDescription: ({ rangeOffset }) => {
      if (rangeOffset) {
        return `Current Fiscal Quarter - ${-rangeOffset / 3}: Second Month`;
      } else {
        return 'Current Fiscal Quarter: Second Month';
      }
    },
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'CURRENT_FISCAL_QUARTER_SECOND_MONTH',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'CURRENT_FISCAL_QUARTER_SECOND_MONTH',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'CURRENT_FISCAL_QUARTER_THIRD_MONTH',
    label: 'Current Fiscal Quarter (Third Month)',
    allowedGroupings: ['total'],
    liveDescription: ({ rangeOffset }) => {
      if (rangeOffset) {
        return `Current Fiscal Quarter - ${-rangeOffset / 3}: Third Month`;
      } else {
        return 'Current Fiscal Quarter: Third Month';
      }
    },
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'CURRENT_FISCAL_QUARTER_THIRD_MONTH',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'CURRENT_FISCAL_QUARTER_THIRD_MONTH',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'FISCAL_QUARTER_TO_LAST_MONTH',
    label: 'Fiscal Quarter to Last Month',
    allowedGroupings: ['total'],
    liveDescription: 'Fiscal Quarter to Last Month: updates monthly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'FISCAL_QUARTER_TO_LAST_MONTH',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'FISCAL_QUARTER_TO_LAST_MONTH',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'LAST_FISCAL_QUARTER',
    label: 'Last Fiscal Quarter',
    allowedGroupings: ['total', 'monthsSeparate'],
    liveDescription: ({ rangeOffset }) => {
      if (rangeOffset) {
        return `Last Fiscal Quarter - ${-rangeOffset / 3}: updates quarterly`;
      }
      return 'Last Fiscal Quarter: updates quarterly';
    },
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'LAST_FISCAL_QUARTER',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'LAST_FISCAL_QUARTER',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'LAST_FISCAL_QUARTER_BACK_ONE',
    label: 'Last Fiscal Quarter - 1',
    allowedGroupings: ['total', 'monthsSeparate'],
    liveDescription: 'Last Fiscal Quarter - 1: updates quarterly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'LAST_FISCAL_QUARTER_BACK_ONE',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'LAST_FISCAL_QUARTER_BACK_ONE',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'LAST_FISCAL_QUARTER_BACK_TWO',
    label: 'Last Fiscal Quarter - 2',
    allowedGroupings: ['total', 'monthsSeparate'],
    liveDescription: 'Last Fiscal Quarter - 2: updates quarterly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'LAST_FISCAL_QUARTER_BACK_TWO',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'LAST_FISCAL_QUARTER_BACK_TWO',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'LAST_FISCAL_QUARTER_BACK_THREE',
    label: 'Last Fiscal Quarter - 3',
    allowedGroupings: ['total', 'monthsSeparate'],
    liveDescription: 'Last Fiscal Quarter - 3: updates quarterly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'LAST_FISCAL_QUARTER_BACK_THREE',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'LAST_FISCAL_QUARTER_BACK_THREE',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'LAST_FISCAL_QUARTER_BACK_FOUR',
    label: 'Last Fiscal Quarter - 4',
    allowedGroupings: ['total', 'monthsSeparate'],
    liveDescription: 'Last Fiscal Quarter - 4: updates quarterly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'LAST_FISCAL_QUARTER_BACK_FOUR',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'LAST_FISCAL_QUARTER_BACK_FOUR',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'NEXT_FISCAL_QUARTER',
    label: 'Next Fiscal Quarter updates quarterly',
    allowedGroupings: ['total'],
    liveDescription: 'Next Fiscal Quarter: updates quarterly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'NEXT_FISCAL_QUARTER',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'NEXT_FISCAL_QUARTER',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'NEXT_FISCAL_QUARTER_FORWARD_ONE',
    label: 'Next Fiscal Quarter + 1 updates quarterly',
    allowedGroupings: ['total'],
    liveDescription: 'Next Fiscal Quarter + 1: updates quarterly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'NEXT_FISCAL_QUARTER_FORWARD_ONE',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'NEXT_FISCAL_QUARTER_FORWARD_ONE',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'NEXT_FISCAL_QUARTER_FORWARD_TWO',
    label: 'Next Fiscal Quarter + 2 updates quarterly',
    allowedGroupings: ['total'],
    liveDescription: 'Next Fiscal Quarter + 2: updates quarterly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'NEXT_FISCAL_QUARTER_FORWARD_TWO',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'NEXT_FISCAL_QUARTER_FORWARD_TWO',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'NEXT_FISCAL_QUARTER_FORWARD_THREE',
    label: 'Next Fiscal Quarter + 3 updates quarterly',
    allowedGroupings: ['total'],
    liveDescription: 'Next Fiscal Quarter + 3: updates quarterly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'NEXT_FISCAL_QUARTER_FORWARD_THREE',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'NEXT_FISCAL_QUARTER_FORWARD_THREE',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },

  {
    value: 'LAST_FOUR_FISCAL_QUARTERS',
    label: 'Last 4 Fiscal Quarters',
    allowedGroupings: ['quartersSeparate', 'total'],
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      generateLastFiscalQuartersOptions(
        live,
        4,
        now,
        fiscalYearMonth,
        grouping,
        'LAST_FOUR_FISCAL_QUARTERS'
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      generateLastFiscalQuartersComparisonOptions(
        comparison,
        customComparisonStartDate,
        live,
        4,
        now,
        fiscalYearMonth,
        grouping,
        'LAST_FOUR_FISCAL_QUARTERS'
      ),
  },
  {
    value: 'LAST_EIGHT_FISCAL_QUARTERS',
    label: 'Last 8 Fiscal Quarters',
    allowedGroupings: ['quartersSeparate', 'total'],
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      generateLastFiscalQuartersOptions(
        live,
        8,
        now,
        fiscalYearMonth,
        grouping,
        'LAST_EIGHT_FISCAL_QUARTERS'
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      generateLastFiscalQuartersComparisonOptions(
        comparison,
        customComparisonStartDate,
        live,
        8,
        now,
        fiscalYearMonth,
        grouping,
        'LAST_EIGHT_FISCAL_QUARTERS'
      ),
  },
  {
    value: 'CURRENT_YEAR',
    label: 'Current Year',
    selectHelpText: updateAnnuallyHelpText,
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: '12 Calendar Months (current year): updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getTwelveCalendarMonthsOptions(
        'TWELVE_CALENDAR_MONTHS',
        live,
        grouping,
        now,
        fiscalYearMonth,
        0,
        'FINAL_CALENDAR_MONTH'
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'TWELVE_CALENDAR_MONTHS',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth,
        'FINAL_CALENDAR_MONTH'
      ),
  },
  {
    value: 'LAST_YEAR',
    label: 'Last Year',
    selectHelpText: updateAnnuallyHelpText,
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: '12 Calendar Months (last calendar year) updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getTwelveCalendarMonthsOptions(
        'TWELVE_CALENDAR_MONTHS_LAST_YEAR',
        live,
        grouping,
        now,
        fiscalYearMonth,
        12,
        'FINAL_CALENDAR_MONTH'
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'TWELVE_CALENDAR_MONTHS_LAST_YEAR',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth,
        'FINAL_CALENDAR_MONTH'
      ),
  },
  {
    value: 'LAST_YEAR_BACK_ONE',
    label: '2 Years Ago',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: '12 Calendar Months (2 yrs ago) updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getTwelveCalendarMonthsOptions(
        'TWELVE_CALENDAR_MONTHS_LAST_YEAR_BACK_TWO',
        live,
        grouping,
        now,
        fiscalYearMonth,
        24,
        'FINAL_CALENDAR_MONTH'
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'TWELVE_CALENDAR_MONTHS_LAST_YEAR_BACK_TWO',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth,
        'FINAL_CALENDAR_MONTH'
      ),
  },
  {
    value: 'CURRENT_QUARTER',
    label: 'Current Quarter',
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: 'Current Quarter: updates quarterly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'CURRENT_QUARTER',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'CURRENT_QUARTER',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'CURRENT_QUARTER_FIRST_MONTH',
    label: 'Current Quarter (First Month)',
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: ({ rangeOffset }) => {
      if (rangeOffset) {
        return `Current Quarter - ${-rangeOffset / 3}: First Month`;
      } else {
        return 'Current Quarter: First Month';
      }
    },
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'CURRENT_QUARTER_FIRST_MONTH',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'CURRENT_QUARTER_FIRST_MONTH',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'CURRENT_QUARTER_SECOND_MONTH',
    label: 'Current Quarter (Second Month)',
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: ({ rangeOffset }) => {
      if (rangeOffset) {
        return `Current Quarter - ${-rangeOffset / 3}: Second Month`;
      } else {
        return 'Current Quarter: Second Month';
      }
    },
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'CURRENT_QUARTER_SECOND_MONTH',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'CURRENT_QUARTER_SECOND_MONTH',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'CURRENT_QUARTER_THIRD_MONTH',
    label: 'Current Quarter (Third Month)',
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: ({ rangeOffset }) => {
      if (rangeOffset) {
        return `Current Quarter - ${-rangeOffset / 3}: Third Month`;
      } else {
        return 'Current Quarter: Third Month';
      }
    },
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'CURRENT_QUARTER_THIRD_MONTH',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'CURRENT_QUARTER_THIRD_MONTH',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'QUARTER_TO_LAST_MONTH',
    label: 'Quarter to Last Month',
    allowedGroupings: ['total'],
    liveDescription: 'Quarter to Last Month: updates monthly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'QUARTER_TO_LAST_MONTH',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'QUARTER_TO_LAST_MONTH',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'FINAL_CALENDAR_QUARTER',
    label: 'Final Calendar Quarter',
    allowedGroupings: ['total'],
    liveDescription: ({ rangeOffset }) =>
      `Final Calendar Quarter${
        rangeOffset
          ? rangeOffset * -1 < 0
            ? ` + ${rangeOffset / 3}`
            : ` - ${(rangeOffset * -1) / 3}`
          : ''
      }: updates annually`,
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'FINAL_CALENDAR_QUARTER',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'FINAL_CALENDAR_QUARTER',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'FINAL_FISCAL_QUARTER',
    label: 'Final Fiscal Quarter',
    allowedGroupings: ['total'],
    liveDescription: ({ rangeOffset }) =>
      `Final Fiscal Quarter${
        rangeOffset
          ? rangeOffset * -1 < 0
            ? ` + ${rangeOffset / 3}`
            : ` - ${(rangeOffset * -1) / 3}`
          : ''
      }: updates annually`,
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'FINAL_FISCAL_QUARTER',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'FINAL_CALENDAR_QUARTER',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'LAST_QUARTER',
    label: 'Last Quarter',
    allowedGroupings: ['total', 'monthsSeparate'],
    liveDescription: ({ rangeOffset }) => {
      if (rangeOffset) {
        return `Last Quarter - ${-rangeOffset / 3}: updates quarterly`;
      } else {
        return 'Last Quarter: updates quarterly';
      }
    },
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange('LAST_QUARTER', live, grouping, now, fiscalYearMonth),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'LAST_QUARTER',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'LAST_QUARTER_FIRST_MONTH',
    label: 'Last Quarter (First Month)',
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: ({ rangeOffset }) => {
      if (rangeOffset) {
        return `Last Quarter - ${-rangeOffset / 3}: First Month`;
      } else {
        return 'Last Quarter: First Month';
      }
    },
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'LAST_QUARTER_FIRST_MONTH',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'LAST_QUARTER_FIRST_MONTH',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'LAST_QUARTER_SECOND_MONTH',
    label: 'Last Quarter (Second Month)',
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: ({ rangeOffset }) => {
      if (rangeOffset) {
        return `Last Quarter - ${-rangeOffset / 3}: Second Month`;
      } else {
        return 'Last Quarter: Second Month';
      }
    },
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'LAST_QUARTER_SECOND_MONTH',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'LAST_QUARTER_SECOND_MONTH',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'LAST_QUARTER_THIRD_MONTH',
    label: 'Last Quarter (Second Month)',
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: ({ rangeOffset }) => {
      if (rangeOffset) {
        return `Last Quarter - ${-rangeOffset / 3}: Third Month`;
      } else {
        return 'Last Quarter: Third Month';
      }
    },
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'LAST_QUARTER_THIRD_MONTH',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'LAST_QUARTER_THIRD_MONTH',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'LAST_FISCAL_QUARTER_FIRST_MONTH',
    label: 'Last Fiscal Quarter (First Month)',
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: ({ rangeOffset }) => {
      if (rangeOffset) {
        return `Last Fiscal Quarter - ${-rangeOffset / 3}: First Month`;
      } else {
        return 'Last Fiscal Quarter: First Month';
      }
    },
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'LAST_FISCAL_QUARTER_FIRST_MONTH',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'LAST_FISCAL_QUARTER_FIRST_MONTH',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'LAST_FISCAL_QUARTER_SECOND_MONTH',
    label: 'Last Fiscal Quarter (Second Month)',
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: ({ rangeOffset }) => {
      if (rangeOffset) {
        return `Last Fiscal Quarter - ${-rangeOffset / 3}: Second Month`;
      } else {
        return 'Last Fiscal Quarter: Second Month';
      }
    },
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'LAST_FISCAL_QUARTER_SECOND_MONTH',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'LAST_FISCAL_QUARTER_SECOND_MONTH',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'LAST_FISCAL_QUARTER_THIRD_MONTH',
    label: 'Last Fiscal Quarter (Second Month)',
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: ({ rangeOffset }) => {
      if (rangeOffset) {
        return `Last Fiscal Quarter - ${-rangeOffset / 3}: Third Month`;
      } else {
        return 'Last Fiscal Quarter: Third Month';
      }
    },
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'LAST_FISCAL_QUARTER_THIRD_MONTH',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'LAST_FISCAL_QUARTER_THIRD_MONTH',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'LAST_QUARTER_BACK_ONE',
    label: 'Last Quarter - 1',
    allowedGroupings: ['total', 'monthsSeparate'],
    liveDescription: 'Last Quarter - 1: updates quarterly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'LAST_QUARTER_BACK_ONE',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'LAST_QUARTER_BACK_ONE',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'LAST_QUARTER_BACK_TWO',
    label: 'Last Quarter - 2',
    allowedGroupings: ['total', 'monthsSeparate'],
    liveDescription: 'Last Quarter - 2: updates quarterly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'LAST_QUARTER_BACK_TWO',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'LAST_QUARTER_BACK_TWO',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'LAST_QUARTER_BACK_THREE',
    label: 'Last Quarter - 3',
    allowedGroupings: ['total', 'monthsSeparate'],
    liveDescription: 'Last Quarter - 3: updates quarterly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'LAST_QUARTER_BACK_THREE',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'LAST_QUARTER_BACK_THREE',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'LAST_QUARTER_BACK_FOUR',
    label: 'Last Quarter - 4',
    allowedGroupings: ['total', 'monthsSeparate'],
    liveDescription: 'Last Quarter - 4: updates quarterly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'LAST_QUARTER_BACK_FOUR',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'LAST_QUARTER_BACK_FOUR',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'LAST_FOUR_QUARTERS',
    label: 'Last 4 Quarters',
    allowedGroupings: ['quartersSeparate', 'total'],
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      generateLastQuartersOptions(live, 4, now, grouping, 'LAST_FOUR_QUARTERS'),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now
    ) =>
      generateLastQuartersComparisonOptions(
        comparison,
        customComparisonStartDate,
        live,
        4,
        now,
        grouping,
        'LAST_FOUR_QUARTERS'
      ),
  },

  {
    value: 'LAST_EIGHT_QUARTERS',
    label: 'Last 8 Quarters',
    allowedGroupings: ['quartersSeparate', 'total'],
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      generateLastQuartersOptions(
        live,
        8,
        now,
        grouping,
        'LAST_EIGHT_QUARTERS'
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now
    ) =>
      generateLastQuartersComparisonOptions(
        comparison,
        customComparisonStartDate,
        live,
        8,
        now,
        grouping,
        'LAST_EIGHT_QUARTERS'
      ),
  },
  {
    value: 'FINAL_CALENDAR_MONTH',
    label: 'Final Calendar Month',
    allowedGroupings: ['total'],
    liveDescription: ({ rangeOffset }) =>
      `Final Calendar Month${
        rangeOffset
          ? rangeOffset * -1 < 0
            ? ` + ${rangeOffset}`
            : ` - ${rangeOffset * -1}`
          : ''
      }: updates annually`,
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'FINAL_CALENDAR_MONTH',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'FINAL_CALENDAR_MONTH',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'FINAL_CALENDAR_MONTH_NEXT_YEAR',
    label: 'Final Calendar Month (next year)',
    allowedGroupings: ['total'],
    liveDescription: ({ rangeOffset }) =>
      `Final Calendar Month (next year)${
        rangeOffset
          ? rangeOffset * -1 < 0
            ? ` + ${rangeOffset}`
            : ` - ${rangeOffset * -1}`
          : ''
      }: updates annually`,
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'FINAL_CALENDAR_MONTH_NEXT_YEAR',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'FINAL_CALENDAR_MONTH_NEXT_YEAR',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'FINAL_FISCAL_MONTH',
    label: 'Final Fiscal Month',
    allowedGroupings: ['total'],
    liveDescription: ({ rangeOffset }) =>
      `Final Fiscal Month${
        rangeOffset
          ? rangeOffset * -1 < 0
            ? ` + ${rangeOffset}`
            : ` - ${rangeOffset * -1}`
          : ''
      }: updates annually`,
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'FINAL_FISCAL_MONTH',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'FINAL_FISCAL_MONTH',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'FINAL_FISCAL_MONTH_NEXT_YEAR',
    label: 'Final Fiscal Month (next year)',
    allowedGroupings: ['total'],
    liveDescription: ({ rangeOffset }) =>
      `Final Fiscal Month (next year) ${
        rangeOffset
          ? rangeOffset * -1 < 0
            ? ` + ${rangeOffset}`
            : ` - ${rangeOffset * -1}`
          : ''
      }: updates annually`,
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'FINAL_FISCAL_MONTH_NEXT_YEAR',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'FINAL_FISCAL_MONTH',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'NEXT_THREE_MONTHS',
    label: 'Next 3 Months updates monthly',
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: 'Next 3 Months: updates monthly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'NEXT_THREE_MONTHS',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'NEXT_THREE_MONTHS',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'NEXT_SIX_MONTHS',
    label: 'Next 6 Months updates monthly',
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: 'Next 6 Months: updates monthly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'NEXT_SIX_MONTHS',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'NEXT_SIX_MONTHS',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'NEXT_TWELVE_MONTHS',
    label: 'Next 12 Months updates monthly',
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: 'Next 12 Months: updates monthly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'NEXT_TWELVE_MONTHS',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'NEXT_TWELVE_MONTHS',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'NEXT_CALENDAR_QUARTER',
    label: 'Next Calendar Quarter updates quarterly',
    allowedGroupings: ['total'],
    liveDescription: 'Next Calendar Quarter: updates quarterly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'NEXT_CALENDAR_QUARTER',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'NEXT_CALENDAR_QUARTER',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'NEXT_CALENDAR_QUARTER_PLUS_ONE',
    label: 'Next Calendar Quarter + 1 updates quarterly',
    allowedGroupings: ['total'],
    liveDescription: 'Next Calendar Quarter + 1: updates quarterly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'NEXT_CALENDAR_QUARTER_PLUS_ONE',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'NEXT_CALENDAR_QUARTER_PLUS_ONE',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'NEXT_CALENDAR_QUARTER_PLUS_TWO',
    label: 'Next Calendar Quarter + 2 updates quarterly',
    allowedGroupings: ['total'],
    liveDescription: 'Next Calendar Quarter + 2: updates quarterly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'NEXT_CALENDAR_QUARTER_PLUS_TWO',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'NEXT_CALENDAR_QUARTER_PLUS_TWO',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'NEXT_CALENDAR_QUARTER_PLUS_THREE',
    label: 'Next Calendar Quarter + 3 updates quarterly',
    allowedGroupings: ['total'],
    liveDescription: 'Next Calendar Quarter + 3: updates quarterly',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getOptionsFromRange(
        'NEXT_CALENDAR_QUARTER_PLUS_THREE',
        live,
        grouping,
        now,
        fiscalYearMonth
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'NEXT_CALENDAR_QUARTER_PLUS_THREE',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth
      ),
  },
  {
    value: 'NEXT_YEAR_FOUR_FISCAL_QUARTERS',
    label: '4 Fiscal Quarters (next year)',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['quartersSeparate', 'total'],
    liveDescription: '4 Fiscal Quarters (next year): updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getFourQuartersUpdateAnnuallyOptions(
        now,
        fiscalYearMonth,
        false,
        live,
        grouping,
        1
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getFourQuartersUpdateAnnuallyComparisonOptions(
        now,
        fiscalYearMonth,
        false,
        live,
        comparison,
        customComparisonStartDate,
        grouping,
        1
      ),
  },
  {
    value: 'NEXT_YEAR_FOUR_CALENDAR_QUARTERS',
    label: '4 Calendar Quarters (next year)',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['quartersSeparate', 'total'],
    liveDescription: '4 Calendar Quarters (next year): updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getFourQuartersUpdateAnnuallyOptions(
        now,
        fiscalYearMonth,
        true,
        live,
        grouping,
        1
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getFourQuartersUpdateAnnuallyComparisonOptions(
        now,
        fiscalYearMonth,
        true,
        live,
        comparison,
        customComparisonStartDate,
        grouping,
        1
      ),
  },
  {
    value: 'NEXT_YEAR_PLUS_ONE_TWELVE_FISCAL_MONTHS',
    label: '12 Fiscal Months (next year + 1)',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: '12 Fiscal Months (next year + 1): updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getTwelveFiscalMonthsOptions(
        'NEXT_YEAR_PLUS_ONE_TWELVE_FISCAL_MONTHS',
        live,
        grouping,
        now,
        fiscalYearMonth,
        -12,
        'FINAL_FISCAL_MONTH_NEXT_YEAR'
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'NEXT_YEAR_PLUS_ONE_TWELVE_FISCAL_MONTHS',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth,
        'FINAL_FISCAL_MONTH_NEXT_YEAR'
      ),
  },
  {
    value: 'NEXT_YEAR_PLUS_ONE_TWELVE_CALENDAR_MONTHS',
    label: '12 Calendar Months (next year + 1)',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: '12 Calendar Months (next year + 1): updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getTwelveCalendarMonthsOptions(
        'NEXT_YEAR_PLUS_ONE_TWELVE_CALENDAR_MONTHS',
        live,
        grouping,
        now,
        fiscalYearMonth,
        -12,
        'FINAL_CALENDAR_MONTH_NEXT_YEAR'
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'NEXT_YEAR_PLUS_ONE_TWELVE_CALENDAR_MONTHS',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth,
        'FINAL_CALENDAR_MONTH_NEXT_YEAR'
      ),
  },
  {
    value: 'NEXT_YEAR_PLUS_ONE_FOUR_FISCAL_QUARTERS',
    label: '4 Fiscal Quarters (next year + 1)',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['quartersSeparate', 'total'],
    liveDescription: '4 Fiscal Quarters (next year + 1): updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getFourQuartersUpdateAnnuallyOptions(
        now,
        fiscalYearMonth,
        false,
        live,
        grouping,
        2
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getFourQuartersUpdateAnnuallyComparisonOptions(
        now,
        fiscalYearMonth,
        false,
        live,
        comparison,
        customComparisonStartDate,
        grouping,
        2
      ),
  },
  {
    value: 'NEXT_YEAR_PLUS_ONE_FOUR_CALENDAR_QUARTERS',
    label: '4 Calendar Quarters (next year + 1)',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['quartersSeparate', 'total'],
    liveDescription: '4 Calendar Quarters (next year + 1): updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getFourQuartersUpdateAnnuallyOptions(
        now,
        fiscalYearMonth,
        true,
        live,
        grouping,
        2
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getFourQuartersUpdateAnnuallyComparisonOptions(
        now,
        fiscalYearMonth,
        true,
        live,
        comparison,
        customComparisonStartDate,
        grouping,
        2
      ),
  },
  {
    value: 'NEXT_YEAR_PLUS_TWO_TWELVE_FISCAL_MONTHS',
    label: '12 Fiscal Months (next year + 2)',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: '12 Fiscal Months (next year + 2): updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getTwelveFiscalMonthsOptions(
        'NEXT_YEAR_PLUS_TWO_TWELVE_FISCAL_MONTHS',
        live,
        grouping,
        now,
        fiscalYearMonth,
        -24,
        'FINAL_FISCAL_MONTH_NEXT_YEAR'
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'NEXT_YEAR_PLUS_TWO_TWELVE_FISCAL_MONTHS',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth,
        'FINAL_FISCAL_MONTH_NEXT_YEAR'
      ),
  },
  {
    value: 'NEXT_YEAR_PLUS_TWO_TWELVE_CALENDAR_MONTHS',
    label: '12 Calendar Months (next year + 2)',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: '12 Calendar Months (next year + 2): updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getTwelveCalendarMonthsOptions(
        'NEXT_YEAR_PLUS_TWO_TWELVE_CALENDAR_MONTHS',
        live,
        grouping,
        now,
        fiscalYearMonth,
        -24,
        'FINAL_CALENDAR_MONTH_NEXT_YEAR'
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'NEXT_YEAR_PLUS_TWO_TWELVE_CALENDAR_MONTHS',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth,
        'FINAL_CALENDAR_MONTH_NEXT_YEAR'
      ),
  },
  {
    value: 'NEXT_YEAR_PLUS_TWO_FOUR_FISCAL_QUARTERS',
    label: '4 Fiscal Quarters (next year + 2)',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['quartersSeparate', 'total'],
    liveDescription: '4 Fiscal Quarters (next year + 2): updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getFourQuartersUpdateAnnuallyOptions(
        now,
        fiscalYearMonth,
        false,
        live,
        grouping,
        3
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getFourQuartersUpdateAnnuallyComparisonOptions(
        now,
        fiscalYearMonth,
        false,
        live,
        comparison,
        customComparisonStartDate,
        grouping,
        3
      ),
  },
  {
    value: 'NEXT_YEAR_PLUS_TWO_FOUR_CALENDAR_QUARTERS',
    label: '4 Calendar Quarters (next year + 2)',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['quartersSeparate', 'total'],
    liveDescription: '4 Calendar Quarters (next year + 2): updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getFourQuartersUpdateAnnuallyOptions(
        now,
        fiscalYearMonth,
        true,
        live,
        grouping,
        3
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getFourQuartersUpdateAnnuallyComparisonOptions(
        now,
        fiscalYearMonth,
        true,
        live,
        comparison,
        customComparisonStartDate,
        grouping,
        3
      ),
  },
  {
    value: 'NEXT_YEAR_PLUS_THREE_TWELVE_FISCAL_MONTHS',
    label: '12 Fiscal Months (next year + 3)',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: '12 Fiscal Months (next year + 3): updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getTwelveFiscalMonthsOptions(
        'NEXT_YEAR_PLUS_THREE_TWELVE_FISCAL_MONTHS',
        live,
        grouping,
        now,
        fiscalYearMonth,
        -36,
        'FINAL_FISCAL_MONTH_NEXT_YEAR'
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'NEXT_YEAR_PLUS_THREE_TWELVE_FISCAL_MONTHS',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth,
        'FINAL_FISCAL_MONTH_NEXT_YEAR'
      ),
  },
  {
    value: 'NEXT_YEAR_PLUS_THREE_TWELVE_CALENDAR_MONTHS',
    label: '12 Calendar Months (next year + 3)',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: '12 Calendar Months (next year + 3): updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getTwelveCalendarMonthsOptions(
        'NEXT_YEAR_PLUS_THREE_TWELVE_CALENDAR_MONTHS',
        live,
        grouping,
        now,
        fiscalYearMonth,
        -36,
        'FINAL_CALENDAR_MONTH_NEXT_YEAR'
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'NEXT_YEAR_PLUS_THREE_TWELVE_CALENDAR_MONTHS',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth,
        'FINAL_CALENDAR_MONTH_NEXT_YEAR'
      ),
  },
  {
    value: 'NEXT_YEAR_PLUS_THREE_FOUR_FISCAL_QUARTERS',
    label: '4 Fiscal Quarters (next year + 3)',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['quartersSeparate', 'total'],
    liveDescription: '4 Fiscal Quarters (next year + 3): updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getFourQuartersUpdateAnnuallyOptions(
        now,
        fiscalYearMonth,
        false,
        live,
        grouping,
        4
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getFourQuartersUpdateAnnuallyComparisonOptions(
        now,
        fiscalYearMonth,
        false,
        live,
        comparison,
        customComparisonStartDate,
        grouping,
        4
      ),
  },
  {
    value: 'NEXT_YEAR_PLUS_THREE_FOUR_CALENDAR_QUARTERS',
    label: '4 Calendar Quarters (next year + 3)',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['quartersSeparate', 'total'],
    liveDescription: '4 Calendar Quarters (next year + 3): updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getFourQuartersUpdateAnnuallyOptions(
        now,
        fiscalYearMonth,
        true,
        live,
        grouping,
        4
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getFourQuartersUpdateAnnuallyComparisonOptions(
        now,
        fiscalYearMonth,
        true,
        live,
        comparison,
        customComparisonStartDate,
        grouping,
        4
      ),
  },
  {
    value: 'NEXT_YEAR_PLUS_FOUR_TWELVE_FISCAL_MONTHS',
    label: '12 Fiscal Months (next year + 4)',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: '12 Fiscal Months (next year + 4): updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getTwelveFiscalMonthsOptions(
        'NEXT_YEAR_PLUS_FOUR_TWELVE_FISCAL_MONTHS',
        live,
        grouping,
        now,
        fiscalYearMonth,
        -48,
        'FINAL_FISCAL_MONTH_NEXT_YEAR'
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'NEXT_YEAR_PLUS_FOUR_TWELVE_FISCAL_MONTHS',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth,
        'FINAL_FISCAL_MONTH_NEXT_YEAR'
      ),
  },
  {
    value: 'NEXT_YEAR_PLUS_FOUR_TWELVE_CALENDAR_MONTHS',
    label: '12 Calendar Months (next year + 4)',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['monthsSeparate', 'total'],
    liveDescription: '12 Calendar Months (next year + 4): updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getTwelveCalendarMonthsOptions(
        'NEXT_YEAR_PLUS_FOUR_TWELVE_CALENDAR_MONTHS',
        live,
        grouping,
        now,
        fiscalYearMonth,
        -48,
        'FINAL_CALENDAR_MONTH_NEXT_YEAR'
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getComparisonOptionsFromRange(
        'NEXT_YEAR_PLUS_FOUR_TWELVE_CALENDAR_MONTHS',
        live,
        grouping,
        comparison,
        customComparisonStartDate,
        now,
        fiscalYearMonth,
        'FINAL_CALENDAR_MONTH_NEXT_YEAR'
      ),
  },
  {
    value: 'NEXT_YEAR_PLUS_FOUR_FOUR_FISCAL_QUARTERS',
    label: '4 Fiscal Quarters (next year + 4)',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['quartersSeparate', 'total'],
    liveDescription: '4 Fiscal Quarters (next year + 4): updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getFourQuartersUpdateAnnuallyOptions(
        now,
        fiscalYearMonth,
        false,
        live,
        grouping,
        5
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getFourQuartersUpdateAnnuallyComparisonOptions(
        now,
        fiscalYearMonth,
        false,
        live,
        comparison,
        customComparisonStartDate,
        grouping,
        5
      ),
  },
  {
    value: 'NEXT_YEAR_PLUS_FOUR_FOUR_CALENDAR_QUARTERS',
    label: '4 Calendar Quarters (next year + 4)',
    selectHelpIconClass: updatesAnnuallyHelpIconClass,
    selectHelpText: updateAnnuallyHelpText,
    allowedGroupings: ['quartersSeparate', 'total'],
    liveDescription: '4 Calendar Quarters (next year + 4): updates annually',
    getColumnOptions: (
      live,
      grouping,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getFourQuartersUpdateAnnuallyOptions(
        now,
        fiscalYearMonth,
        true,
        live,
        grouping,
        5
      ),
    getComparisonColumnOptions: (
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    ) =>
      getFourQuartersUpdateAnnuallyComparisonOptions(
        now,
        fiscalYearMonth,
        true,
        live,
        comparison,
        customComparisonStartDate,
        grouping,
        5
      ),
  },
  /* {
    value: 'NEXT_TWELVE_MONTHS',
    label: 'Next 12 Months',
    getColumnOptions: live => generateFutureMonths(12),
  },
  {
    value: 'NEXT_FOUR_QUARTERS',
    label: 'Next 4 Quarters',
    getColumnOptions: live => generateFutureQuarters(4),
  }, */
]);

module.exports.calculateRange = function (
  range,
  customStartDate,
  customEndDate,
  live,
  bookMonth,
  fiscalYearMonth
) {
  const rangeFuncs = ranges.find((r) => r.value === range);

  return rangeFuncs.getColumnOptions(
    live,
    'total',
    customStartDate,
    customEndDate,
    bookMonth,
    fiscalYearMonth
  )[0];
};

module.exports.calculateComparison = function (
  range,
  live,
  comparison,
  customComparisonStartDate,
  customStartDate,
  customEndDate,
  now,
  fiscalYearMonth
) {
  const rangeFuncs = ranges.find((r) => r.value === range);
  return rangeFuncs.getComparisonColumnOptions(
    live,
    'total',
    comparison,
    customComparisonStartDate,
    customStartDate,
    customEndDate,
    now,
    fiscalYearMonth
  )[0];
};

module.exports.getColumnOptions = function (
  range,
  grouping,
  customStartDate,
  customEndDate,
  live,
  hasComparison,
  comparison,
  customComparisonStartDate,
  change,
  percentChange,
  comparisonSide,
  now,
  fiscalYearMonth = 0,
  budgetId,
  budgetType,
  showPastActuals,
  hasActuals,
  actualsSide,
  hasReferenceDates,
  referenceAverage,
  referenceTotal,
  referenceSeparate,
  referenceSide,
  useBaseForecastValues,
  hasClassDept,
  subdivide,
  subtitles
) {
  const { getColumnOptions, getComparisonColumnOptions } = ranges.find(
    (r) => r.value === range
  );

  const leftColumns = [];
  let columns = getColumnOptions(
    live,
    grouping,
    customStartDate,
    customEndDate,
    now,
    fiscalYearMonth
  );
  let actualColumns;

  if (budgetId) {
    actualColumns = columns;
    columns = columns.map((col) => ({
      ...col,
      type: showPastActuals ? 'FORECAST_ACTUALS' : 'FORECAST',
      budgetId,
      budgetType,
      useBaseForecastValues,
      budgetTitleSuffixEnabled: true,
      budgetSuffix: 'Budget',
      subtitleOverrideEnabled: true,
      subtitleActual: 'Actual',
      subtitleForecast: 'Forecast',
    }));
  }

  if (
    hasClassDept &&
    subdivide &&
    subdivide.companies &&
    subdivide.companies.length > 0
  ) {
    const companyFilterColumns = [];
    subdivide.companies.forEach((comp) => {
      let subtitleName = '';
      if (comp.split('_').length === 2) {
        const id = comp.split('_')[1];
        subtitleName = (subtitles && subtitles[id]) || '';
      } else if (comp.split('_').length === 3) {
        const id = comp.split('_')[2];
        subtitleName = (subtitles && subtitles[id]) || '';
      }
      columns.forEach((col) =>
        companyFilterColumns.push({
          ...col,
          classId: null,
          departmentId: null,
          filterCompanyIds: [comp],
          subtitle: subtitleName,
        })
      );
    });
    columns = companyFilterColumns;
  } else if (
    hasClassDept &&
    subdivide &&
    subdivide.classes &&
    subdivide.classes.length > 0
  ) {
    const classFilterColumns = [];
    subdivide.classes.forEach((cls) => {
      const name = (subtitles && subtitles[cls]) || '';
      columns.forEach((col) =>
        classFilterColumns.push({
          ...col,
          classId: [cls],
          departmentId: null,
          filterCompanyIds: null,
          subtitle: name,
        })
      );
    });
    columns = classFilterColumns;
  } else if (
    hasClassDept &&
    subdivide &&
    subdivide.departments &&
    subdivide.departments.length > 0
  ) {
    const departmentFilterColumns = [];
    subdivide.departments.forEach((dep) => {
      const name = (subtitles && subtitles[dep]) || '';
      columns.forEach((col) =>
        departmentFilterColumns.push({
          ...col,
          classId: null,
          departmentId: [dep],
          filterCompanyIds: null,
          subtitle: name,
        })
      );
    });
    columns = departmentFilterColumns;
  }

  let result = columns || [];
  let comparisonColumns;

  if (hasComparison) {
    comparisonColumns = getComparisonColumnOptions(
      live,
      grouping,
      comparison,
      customComparisonStartDate,
      customStartDate,
      customEndDate,
      now,
      fiscalYearMonth
    );

    result = [];
    columns.forEach((col, index) => {
      const left = comparisonSide === 'LEFT';

      if (left) {
        result.push(comparisonColumns[index]);
        result.push(col);
      } else {
        result.push(col);
        result.push(comparisonColumns[index]);
      }

      if (change) {
        result.push({
          type: 'CUSTOM',
          title: 'CHANGE',
          formula: 'CHANGE',
          columns: left ? [-1, -2] : [-2, -1],
        });
      }

      if (percentChange) {
        let formulaColumns = change ? [-3, -2] : [-2, -1];

        if (!left) {
          formulaColumns = formulaColumns.reverse();
        }

        result.push({
          type: 'CUSTOM',
          title: '% CHANGE',
          formula: 'PERCENT_CHANGE',
          columns: formulaColumns,
        });
      }
    });
  }

  if ((hasActuals || hasReferenceDates) && budgetId) {
    result = [];

    let referenceColumns;

    if (hasReferenceDates) {
      const [totalReferenceColumn] = getComparisonColumnOptions(
        live,
        'total',
        comparison,
        customComparisonStartDate,
        customStartDate,
        customEndDate,
        now,
        fiscalYearMonth
      );

      if (referenceSeparate) {
        referenceColumns = getComparisonColumnOptions(
          live,
          grouping,
          comparison,
          customComparisonStartDate,
          customStartDate,
          customEndDate,
          now,
          fiscalYearMonth
        );
      }

      const referenceCount =
        totalReferenceColumn.endDate.diff(
          totalReferenceColumn.startDate,
          'month'
        ) + 1;

      if (referenceAverage || referenceTotal) {
        leftColumns.push({
          type: 'DATE',
          startDate: totalReferenceColumn.startDate,
          endDate: totalReferenceColumn.endDate,
          range: totalReferenceColumn.range,
          rangeOffset: totalReferenceColumn.rangeOffset,
          selected: false,
          titleStyles: {
            color: '#FF865E',
          },
        });
      }
      if (referenceAverage) {
        leftColumns.push({
          type: 'CUSTOM',
          formula: 'AVERAGE',
          formulaParams: {
            quantity: referenceCount,
          },
          title: 'Average',
          selected: false,
          columns: [-1],
          titleStyles: {
            color: '#FF865E',
          },
        });
      }
    }

    columns.forEach((col, index) => {
      const left = actualsSide === 'LEFT';
      const referenceLeft = referenceSide === 'LEFT';
      let colIndex = 0;
      let actualIndex;

      if (referenceColumns && referenceLeft) {
        result.push(referenceColumns[index]);
      }

      if (hasActuals && actualColumns && left) {
        actualIndex = colIndex++;
        result.push(actualColumns[index]);
      }

      const dataIndex = colIndex++;

      result.push(col);

      if (hasActuals && actualColumns && !left) {
        actualIndex = colIndex++;
        result.push(actualColumns[index]);
      }
      if (referenceColumns && !referenceLeft) {
        result.push(referenceColumns[index]);
      }

      if (hasActuals && change) {
        const changeIndex = colIndex++;

        result.push({
          type: 'CUSTOM',
          title: 'CHANGE',
          formula: 'CHANGE',
          columns: [actualIndex - changeIndex, dataIndex - changeIndex],
        });
      }

      if (hasActuals && percentChange) {
        const percentChangeIndex = colIndex++;

        result.push({
          type: 'CUSTOM',
          title: '% CHANGE',
          formula: 'PERCENT_CHANGE',
          columns: [
            dataIndex - percentChangeIndex,
            actualIndex - percentChangeIndex,
          ],
        });
      }
    });
  }

  return [...leftColumns, ...result];
};

module.exports.getForecastColumnOptions = function (
  startDate,
  endDate,
  grouping,
  hasActuals,
  change,
  percentChange,
  actualsSide,
  hasReferenceDates,
  referencePeriod,
  customReferenceStartDate,
  referenceAverage,
  referenceTotal,
  referenceSeparate,
  referenceSide,
  _now,
  fiscalYearMonth,
  budgetId,
  budgetType,
  live,
  showPastActuals
) {
  const now = _now || moment.utc().startOf('month');
  const referenceStartDate = getComparisonStartDate(
    startDate,
    endDate,
    referencePeriod,
    customReferenceStartDate,
    now
  );

  const referenceEndDate = getComparisonEndDate(
    startDate,
    endDate,
    referencePeriod,
    customReferenceStartDate,
    now
  );

  const referenceCount = referenceEndDate.diff(referenceStartDate, 'month') + 1;

  const leftColumns = [];

  if (hasReferenceDates) {
    if (referenceAverage || referenceTotal) {
      leftColumns.push({
        type: 'DATE',
        startDate: referenceStartDate,
        endDate: referenceEndDate,
        range: null,
        rangeOffset: null,
        selected: false,
        titleStyles: {
          color: '#FF865E',
        },
      });
    }
    if (referenceAverage) {
      leftColumns.push({
        type: 'CUSTOM',
        formula: 'AVERAGE',
        formulaParams: {
          quantity: referenceCount,
        },
        title: 'Average',
        selected: false,
        columns: [-1],
        titleStyles: {
          color: '#FF865E',
        },
      });
    }
  }

  const columns = getForecastColumns(
    grouping,
    startDate,
    endDate,
    now,
    'FORECAST',
    budgetId,
    budgetType,
    live,
    showPastActuals
  );
  let separateReference;
  if (hasReferenceDates && referenceSeparate) {
    separateReference = getForecastColumns(
      grouping,
      referenceStartDate,
      referenceEndDate,
      now,
      'DATE',
      -1,
      'COMPANY',
      live
    );
  }

  const result = [];
  let actualColumns;

  if (hasActuals) {
    actualColumns = getForecastColumns(
      grouping,
      startDate,
      endDate,
      now,
      'DATE',
      -1,
      'COMPANY',
      live
    );
  }

  columns.forEach((col, index) => {
    const left = actualsSide === 'LEFT';
    const referenceLeft = referenceSide === 'LEFT';
    let colIndex = 0;
    let actualIndex;

    if (separateReference && referenceLeft) {
      result.push(separateReference[index]);
    }

    if (actualColumns && left) {
      actualIndex = colIndex++;
      result.push(actualColumns[index]);
    }

    const dataIndex = colIndex++;

    result.push(col);

    if (actualColumns && !left) {
      actualIndex = colIndex++;
      result.push(actualColumns[index]);
    }
    if (separateReference && !referenceLeft) {
      result.push(separateReference[index]);
    }

    if (hasActuals) {
      if (change) {
        const changeIndex = colIndex++;

        result.push({
          type: 'CUSTOM',
          title: 'CHANGE',
          formula: 'CHANGE',
          columns: [dataIndex - changeIndex, actualIndex - changeIndex],
        });
      }

      if (percentChange) {
        const percentChangeIndex = colIndex++;

        result.push({
          type: 'CUSTOM',
          title: '% CHANGE',
          formula: 'PERCENT_CHANGE',
          columns: [
            actualIndex - percentChangeIndex,
            dataIndex - percentChangeIndex,
          ],
        });
      }
    }
  });

  return [...leftColumns, ...result];
};

const mainOptions = [
  'CUSTOM',
  'CURRENT_MONTH',
  'LAST_MONTH',
  'LAST_THREE_FULL_MONTHS',
  'LAST_SIX_FULL_MONTHS',
  'LAST_TWELVE_FULL_MONTHS',
  'YEAR_TO_LAST_MONTH',
  'YEAR_TO_LAST_MONTH_BACK_ONE_YEAR',
  'YEAR_TO_LAST_MONTH_BACK_TWO_YEARS',
  'CURRENT_YEAR',
  'LAST_YEAR',
  'TWELVE_FISCAL_MONTHS',
  'TWELVE_FISCAL_MONTHS_LAST_YEAR',
];

const calendarOptions = [
  'YEAR_TO_LAST_MONTH',
  'YEAR_TO_LAST_MONTH_BACK_ONE_YEAR',
  'YEAR_TO_LAST_MONTH_BACK_TWO_YEARS',
  'CURRENT_YEAR',
  'LAST_YEAR',
  'LAST_YEAR_BACK_ONE',
  'CURRENT_QUARTER',
  'QUARTER_TO_LAST_MONTH',
  'FINAL_CALENDAR_QUARTER',
  'LAST_QUARTER',
  'LAST_QUARTER_BACK_ONE',
  'LAST_QUARTER_BACK_TWO',
  'LAST_QUARTER_BACK_THREE',
  'LAST_QUARTER_BACK_FOUR',
  'LAST_FOUR_QUARTERS',
  'LAST_EIGHT_QUARTERS',
  'FINAL_CALENDAR_MONTH',
];

const fiscalOptions = [
  'FISCAL_YEAR_TO_LAST_MONTH',
  'FISCAL_YEAR_TO_LAST_MONTH_BACK_ONE_YEAR',
  'FISCAL_YEAR_TO_LAST_MONTH_BACK_TWO_YEARS',
  'CURRENT_FISCAL_YEAR',
  'LAST_FISCAL_YEAR',
  'LAST_FISCAL_YEAR_BACK_ONE',
  'CURRENT_FISCAL_QUARTER',
  'FISCAL_QUARTER_TO_LAST_MONTH',

  'FINAL_FISCAL_QUARTER',
  'LAST_FISCAL_QUARTER',
  'LAST_FISCAL_QUARTER_BACK_ONE',
  'LAST_FISCAL_QUARTER_BACK_TWO',
  'LAST_FISCAL_QUARTER_BACK_THREE',
  'LAST_FISCAL_QUARTER_BACK_FOUR',
  'LAST_FOUR_FISCAL_QUARTERS',
  'LAST_EIGHT_FISCAL_QUARTERS',
  'FINAL_FISCAL_MONTH',
];

const otherOptions = [
  'YEAR_TO_DATE',
  'YEAR_TO_DATE_BACK_ONE_YEAR',
  'YEAR_TO_DATE_BACK_TWO_YEARS',
  'FISCAL_YEAR_TO_DATE',
  'FISCAL_YEAR_TO_DATE_BACK_ONE_YEAR',
  'FISCAL_YEAR_TO_DATE_BACK_TWO_YEARS',
  'LAST_THREE_MONTHS',
  'LAST_SIX_MONTHS',
  'LAST_TWELVE_MONTHS',
  'FISCAL_YEAR_TO_LAST_MONTH_BACK_ONE_MONTH',
  'FISCAL_YEAR_TO_LAST_MONTH_BACK_TWO_MONTHS',
  'FISCAL_YEAR_TO_LAST_MONTH_BACK_THREE_MONTHS',
];

const updatedAnnuallyOptions = [
  'TWELVE_FISCAL_MONTHS',
  'TWELVE_FISCAL_MONTHS_LAST_YEAR',
  'TWELVE_FISCAL_MONTHS_LAST_YEAR_BACK_TWO',
  'TWELVE_CALENDAR_MONTHS',
  'TWELVE_CALENDAR_MONTHS_LAST_YEAR',
  'TWELVE_CALENDAR_MONTHS_LAST_YEAR_BACK_TWO',
  'FOUR_CALENDAR_QUARTERS',
  'FOUR_FISCAL_QUARTERS',
];

const futureFiscalOptions = [
  'NEXT_FISCAL_YEAR',
  'NEXT_YEAR_PLUS_ONE_TWELVE_FISCAL_MONTHS',
  'NEXT_YEAR_PLUS_TWO_TWELVE_FISCAL_MONTHS',
  'NEXT_YEAR_PLUS_THREE_TWELVE_FISCAL_MONTHS',
  'NEXT_YEAR_PLUS_FOUR_TWELVE_FISCAL_MONTHS',

  'NEXT_YEAR_FOUR_FISCAL_QUARTERS',
  'NEXT_YEAR_PLUS_ONE_FOUR_FISCAL_QUARTERS',
  'NEXT_YEAR_PLUS_TWO_FOUR_FISCAL_QUARTERS',
  'NEXT_YEAR_PLUS_THREE_FOUR_FISCAL_QUARTERS',
  'NEXT_YEAR_PLUS_FOUR_FOUR_FISCAL_QUARTERS',

  'NEXT_FISCAL_QUARTER',
  'NEXT_FISCAL_QUARTER_FORWARD_ONE',
  'NEXT_FISCAL_QUARTER_FORWARD_TWO',
  'NEXT_FISCAL_QUARTER_FORWARD_THREE',

  'FINAL_FISCAL_MONTH_NEXT_YEAR',
];

const futureCalendarOptions = [
  'NEXT_THREE_MONTHS',
  'NEXT_SIX_MONTHS',
  'NEXT_TWELVE_MONTHS',

  'NEXT_CALENDAR_YEAR',
  'NEXT_YEAR_PLUS_ONE_TWELVE_CALENDAR_MONTHS',
  'NEXT_YEAR_PLUS_TWO_TWELVE_CALENDAR_MONTHS',
  'NEXT_YEAR_PLUS_THREE_TWELVE_CALENDAR_MONTHS',
  'NEXT_YEAR_PLUS_FOUR_TWELVE_CALENDAR_MONTHS',

  'NEXT_YEAR_FOUR_CALENDAR_QUARTERS',
  'NEXT_YEAR_PLUS_ONE_FOUR_CALENDAR_QUARTERS',
  'NEXT_YEAR_PLUS_TWO_FOUR_CALENDAR_QUARTERS',
  'NEXT_YEAR_PLUS_THREE_FOUR_CALENDAR_QUARTERS',
  'NEXT_YEAR_PLUS_FOUR_FOUR_CALENDAR_QUARTERS',

  'NEXT_CALENDAR_QUARTER',
  'NEXT_CALENDAR_QUARTER_PLUS_ONE',
  'NEXT_CALENDAR_QUARTER_PLUS_TWO',
  'NEXT_CALENDAR_QUARTER_PLUS_THREE',

  'FINAL_CALENDAR_MONTH_NEXT_YEAR',
];

const rangeById = {};
ranges.forEach((item) => {
  rangeById[item.value] = item;
});

module.exports.rangeOptions = [
  {
    label: 'Most Common',
    type: 'group',
    options: mainOptions
      .filter((item) => item !== 'FORECAST')
      .map((id) => ({
        value: rangeById[id].value,
        label: rangeById[id].label,
        selectHelpText: rangeById[id].selectHelpText,
        selectHelpIconClass: rangeById[id].selectHelpIconClass,
      })),
    index: 0,
  },
  {
    label: 'Calendar',
    type: 'group',
    options: calendarOptions.map((id) => ({
      value: rangeById[id].value,
      label: rangeById[id].label,
      selectHelpText: rangeById[id].selectHelpText,
      selectHelpIconClass: rangeById[id].selectHelpIconClass,
    })),
    index: 1,
  },
  {
    label: 'Fiscal',
    type: 'group',
    options: fiscalOptions.map((id) => ({
      value: rangeById[id].value,
      label: rangeById[id].label,
      selectHelpText: rangeById[id].selectHelpText,
      selectHelpIconClass: rangeById[id].selectHelpIconClass,
    })),
    index: 2,
  },
  {
    label: 'Updates Annually',
    type: 'group',
    options: updatedAnnuallyOptions.map((id) => ({
      value: rangeById[id].value,
      label: rangeById[id].label,
      selectHelpText: rangeById[id].selectHelpText,
      selectHelpIconClass: rangeById[id].selectHelpIconClass,
    })),
    index: 3,
  },
  {
    label: 'Other',
    type: 'group',
    options: otherOptions.map((id) => ({
      value: rangeById[id].value,
      label: rangeById[id].label,
      selectHelpText: rangeById[id].selectHelpText,
      selectHelpIconClass: rangeById[id].selectHelpIconClass,
    })),
    index: 4,
  },
  {
    label: 'Future Calendar',
    type: 'group',
    options: futureCalendarOptions.map((id) => ({
      value: rangeById[id].value,
      label: rangeById[id].label,
      selectHelpText: rangeById[id].selectHelpText,
      selectHelpIconClass: rangeById[id].selectHelpIconClass,
    })),
    index: 5,
  },
  {
    label: 'Future Fiscal',
    type: 'group',
    options: futureFiscalOptions.map((id) => ({
      value: rangeById[id].value,
      label: rangeById[id].label,
      selectHelpText: rangeById[id].selectHelpText,
      selectHelpIconClass: rangeById[id].selectHelpIconClass,
    })),
    index: 6,
  },
];

module.exports.rangeOptionsWithForecast = [
  {
    label: 'Most Common',
    type: 'group',
    options: mainOptions.map((id) => ({
      value: rangeById[id].value,
      label: rangeById[id].label,
      selectHelpText: rangeById[id].selectHelpText,
      selectHelpIconClass: rangeById[id].selectHelpIconClass,
    })),
    index: 0,
  },
  {
    label: 'Calendar',
    type: 'group',
    options: calendarOptions.map((id) => ({
      value: rangeById[id].value,
      label: rangeById[id].label,
      selectHelpText: rangeById[id].selectHelpText,
      selectHelpIconClass: rangeById[id].selectHelpIconClass,
    })),
    index: 1,
  },
  {
    label: 'Fiscal',
    type: 'group',
    options: fiscalOptions.map((id) => ({
      value: rangeById[id].value,
      label: rangeById[id].label,
      selectHelpText: rangeById[id].selectHelpText,
      selectHelpIconClass: rangeById[id].selectHelpIconClass,
    })),
    index: 2,
  },
  {
    label: 'Updates Annually',
    type: 'group',
    options: updatedAnnuallyOptions.map((id) => ({
      value: rangeById[id].value,
      label: rangeById[id].label,
      selectHelpText: rangeById[id].selectHelpText,
      selectHelpIconClass: rangeById[id].selectHelpIconClass,
    })),
    index: 3,
  },
  {
    label: 'Other',
    type: 'group',
    options: otherOptions.map((id) => ({
      value: rangeById[id].value,
      label: rangeById[id].label,
      selectHelpText: rangeById[id].selectHelpText,
      selectHelpIconClass: rangeById[id].selectHelpIconClass,
    })),
    index: 4,
  },
  {
    label: 'Future Calendar',
    type: 'group',
    options: futureCalendarOptions.map((id) => ({
      value: rangeById[id].value,
      label: rangeById[id].label,
      selectHelpText: rangeById[id].selectHelpText,
      selectHelpIconClass: rangeById[id].selectHelpIconClass,
    })),
    index: 5,
  },
  {
    label: 'Future Fiscal',
    type: 'group',
    options: futureFiscalOptions.map((id) => ({
      value: rangeById[id].value,
      label: rangeById[id].label,
      selectHelpText: rangeById[id].selectHelpText,
      selectHelpIconClass: rangeById[id].selectHelpIconClass,
    })),
    index: 6,
  },
];

module.exports.comparisonOptions = [
  { label: 'Previous Period', value: 'PREVIOUS_PERIOD' },
  { label: 'Previous Year', value: 'PREVIOUS_YEAR' },
  { label: 'Custom', value: 'CUSTOM' },
];
