/**
 *
 * PortalLayout
 *
 */
import React, {
  useEffect,
  useMemo,
  useContext,
  useState,
  useCallback,
} from 'react';
import styled, { ThemeContext, ThemeProvider } from 'styled-components';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

import lightTheme from 'themes/quantum';
import darkTheme from 'themes/dark';
import { merge } from 'utils/arrayUtils';

import GlobalStyles from 'components/GlobalStyles';
import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import Masquerade from 'components/Masquerade';
import useMedia from 'hooks/useMedia';
import { sizes } from 'media';
import { getTheme, setTheme } from 'utils/theme';

const mergedDarkTheme = merge(lightTheme, darkTheme);

let headerRef: HTMLDivElement | null = null;

const Content = styled.div<{ hasSidebar: boolean; headerHeight: number }>`
  padding-top: ${(props) => props.headerHeight}px;
  padding-left: ${(props) =>
    props.hasSidebar ? props.theme.sidebar.width : 0};
  transition: padding-top 250ms ease-in-out;
`;

const QUERY = gql`
  query PortalLayout {
    currentUser {
      theme
      clientPortalOnly
    }
  }
`;

const PortalLayout: React.FunctionComponent = ({ children }) => {
  const { data } = useQuery(QUERY);
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const phone = useMedia([`(min-width: ${sizes.phone}px)`], [false], true);
  const theme = useContext(ThemeContext);
  const [headerHeight, setHeaderHeight] = useState(0);
  const setHeaderRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setHeaderHeight(node.clientHeight);
      headerRef = node;
    }
  }, []);

  const onMaintenanceBannerLoad = useCallback((displayBanner: boolean) => {
    if (headerRef && displayBanner) {
      setHeaderHeight(headerRef.clientHeight);
    }
  }, []);

  const onMaintenanceBannerClose = useCallback(() => {
    if (headerRef) {
      setHeaderHeight(headerRef.clientHeight);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (headerRef) {
        setHeaderHeight(headerRef.clientHeight);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (data && data.currentUser) {
      setTheme(data.currentUser.theme);
    }
  }, [data]);

  const handleSidebarExpanded = (state) => setSidebarExpanded(state);

  const newTheme = useMemo(() => {
    let dark = false;
    if (data && data.currentUser) {
      dark = data.currentUser.theme === 'DARK';
    } else {
      dark = getTheme() === 'DARK';
    }
    return dark ? mergedDarkTheme : theme;
  }, [data, theme]);

  const hasSidebar =
    data && data.currentUser && !data.currentUser.clientPortalOnly;

  return (
    <ThemeProvider theme={newTheme}>
      <div className={'modal-blur'}>
        <GlobalStyles />
        <Header
          ref={setHeaderRef}
          sidebarExpanded={sidebarExpanded}
          minimal={!hasSidebar}
          onMaintenanceBannerClose={onMaintenanceBannerClose}
          onMaintenanceBannerLoad={onMaintenanceBannerLoad}
        />
        {hasSidebar && (
          <Sidebar
            toggleSidebar={handleSidebarExpanded}
            noCompany={false}
            anonymous={false}
          />
        )}
        <Content headerHeight={headerHeight} hasSidebar={hasSidebar && !phone}>
          {children}
        </Content>

        <Masquerade />
      </div>
    </ThemeProvider>
  );
};

export default PortalLayout;
