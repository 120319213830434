import React from 'react';

const QuickBooksLogoSmall = ({
  width = '80px',
  height = null,
  cursor = null,
}: React.SVGProps<SVGSVGElement> & {
  cursor?: string;
}): React.ReactElement => {
  return (
    <svg
      style={{ cursor: cursor }}
      width={width}
      height={height}
      viewBox="0 0 500 500"
    >
      <circle cx="248.97" cy="268.56" r="205.73" fill="#fff" />
      <path
        d="M250,0C111.93,0,0,111.93,0,250S111.93,500,250,500,500,388.07,500,250,388.07,0,250,0Zm79.53,347.47c-18.69.46-37.4-.23-56.09.24-9.66.24-12.77-3.7-12.7-13,.31-39.59.13-79.2.13-118.79,0-39.23-.06-78.46,0-117.69,0-12.94,3-14.82,15.28-10.35,14.32,5.21,23.54,18.65,23.56,34.85.06,57.56.29,115.12-.2,172.69-.09,10.68,3,13.81,13.62,13.59,18.08-.38,36-.22,51.65-11.1C386.44,282.8,395.44,257,388,232.08c-7.2-24.31-30-41.26-56.9-42.4Q316,189,316,174.59q-.16-25.32,25.89-22.31c76.3,8.81,113.69,93.48,68.31,155.3-19.82,27-47.69,39.08-80.63,39.89Zm-91.9-65c0,39.23.06,78.46,0,117.68,0,12.93-3,14.81-15.28,10.34-14.33-5.21-23.54-18.65-23.55-34.85-.06-57.57-.29-115.12.2-172.67.09-10.66-3-13.82-13.61-13.6-18.08.38-36,.21-51.66,11.08-21.59,15-30.63,40.87-23.25,65.78,7.2,24.31,30,41.27,56.89,42.41q15.1.64,15.19,15.07.16,25.35-25.85,22.34C80.36,337.22,43,252.62,88.31,190.79c19.81-27,47.62-39.15,80.61-39.88,18.69-.41,37.41.24,56.09-.21,9.56-.22,12.85,3.55,12.76,13-.33,39.59-.14,79.18-.14,118.78Z"
        fill="#2CA01C"
      />
    </svg>
  );
};

export default QuickBooksLogoSmall;
