
import styled from 'styled-components';
import Panel from 'components/Panel';
import Link from 'components/Link';
import Button from 'components/Button';

export const Div = styled.div`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
`;

export const StyledPanel = styled(Panel)`
  padding: 20px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-bottom: 40px;
`;

export const Heading = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

export const StyledLink = styled(Link)``;

export const TopTitle = styled.h2`
  margin-bottom: 0;
`;

export const Options = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 1.5rem;
`;

export const OptionWrapper = styled.div`
  width: 350px;
  margin: 20px;
  margin-bottom: 0.5rem;
  cursor: pointer;
  transition: box-shadow 0.25s;
  overflow: hidden;
`;

export const Title = styled.div`
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 500;
`;

export const A = styled.a`
  color: ${props => props.theme.colors.link};
`;

export const BillingInfoP = styled.p`
  max-width: 450px;
  margin: 0 auto;
  margin-bottom: 20px;
`;

export const CsvButton = styled(Button).attrs({
  color: 'default',
  outline: true,
})`
  display: block;
  width: 350px;
  height: 45px;
  font-size: 1.2rem;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.25rem;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
`;

export const OutlinedButton = styled(Button).attrs({
  color: 'default',
  outline: true,
})`
border: none;
margin-right: 10px;
`