import {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';

const scrollbar = (
  trackColor?: string,
  thumbColor?: string
): FlattenInterpolation<ThemeProps<DefaultTheme>> => css`
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => trackColor || theme.scrollBar.track};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => thumbColor || theme.scrollBar.thumb};
    border-radius: 5px;
    padding: 1px;
  }

  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => thumbColor || theme.scrollBar.thumb};
`;

export default scrollbar;
