/**
 *
 * DatasheetsUpdatingStatus
 *
 */
import React, { useCallback, useRef, useEffect } from 'react';
import { gql, useSubscription } from '@apollo/client';
import { createGlobalStyle } from 'styled-components';
import { toast } from 'react-toastify';
import useCompanyId from 'hooks/useCompanyId';

const UPDATE_DATASHEETS_STATUS = gql`
  subscription UpdateDatasheetsStatus($companyId: Int!) {
    updateDatasheetsStatus(companyId: $companyId) {
      finished
      progress
      updateBudgets
      updateDatasheets
    }
  }
`;

const GlobalStyle = createGlobalStyle`
.Toastify__progress-bar {
  background: #52badb;
}
`;

const DatasheetsUpdatingStatus = () => {
  const ref = useRef();
  const companyId = useCompanyId();

  const onData = useCallback(
    ({
      data: {
        data: {
          updateDatasheetsStatus: {
            finished,
            progress,
            updateBudgets,
            updateDatasheets,
          },
        },
      },
    }) => {
      if (!finished && !ref.current) {
        const texts = [];
        if (updateBudgets) {
          texts.push('budgets');
        }
        if (updateDatasheets) {
          texts.push('datasheets');
        }

        ref.current = toast.loading(`Updating ${texts.join('/')}`, {
          progress: progress / 100,
          closeButton: true,
        });
      } else if (!finished && progress && ref.current) {
        toast.update(ref.current, { progress: progress / 100 });
      } else if (finished && ref.current) {
        const texts = [];
        if (updateBudgets) {
          texts.push('Budgets');
        }
        if (updateDatasheets) {
          texts.push('Datasheets');
        }
        toast.dismiss(ref.current);
        toast(`${texts.join('/')} updated`, {
          type: 'success',
        });
        ref.current = null;
      }
    },
    []
  );

  useEffect(() => {
    return () => {
      if (ref.current) {
        ref.current.dismiss();
        ref.current = null;
      }
    };
  }, []);

  useSubscription(UPDATE_DATASHEETS_STATUS, {
    variables: {
      companyId,
    },
    onData,
  });

  return <GlobalStyle />;
};

export default DatasheetsUpdatingStatus;
