/**
 *
 * Select
 *
 */

import React, { useCallback, useMemo, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import ReactSelect, { components } from 'react-select';
import equal from 'deep-equal';
import {
  CollapsibleGroup,
  CollapsibleMenuList,
  CollapsibleOption,
  helpIconStyles,
} from './collapsibleComponents';
import Help from 'components/Help';

const SelectWrapper = styled.div`
  & input[type='text'] {
    padding: 0 !important;
  }
  color: #333;
`;

const CustomInput = function (props) {
  return (
    <components.Input
      {...props}
      placeholder={
        props.selectProps.value && props.selectProps.isMulti
          ? 'Search'
          : undefined
      }
    />
  );
};

const CustomPlaceholder = function ({ innerProps, selectProps, ...props }) {
  const newInnerProps = useMemo(() => {
    if (!selectProps.testId) return innerProps;

    return {
      ...innerProps,
      'data-testid': selectProps.testId,
    };
  }, [innerProps, selectProps.testId]);

  return (
    <components.Placeholder
      {...props}
      innerProps={newInnerProps}
      selectProps={selectProps}
    />
  );
};

const CustomSingleValue = function ({
  innerProps,
  selectProps,
  children,
  ...props
}) {
  const newInnerProps = useMemo(() => {
    if (!selectProps.testId) return innerProps;

    return {
      ...innerProps,
      'data-testid': selectProps.testId + '-single-value',
    };
  }, [innerProps, selectProps.testId]);

  return (
    <components.SingleValue
      {...props}
      innerProps={newInnerProps}
      selectProps={selectProps}
    >
      {children}
      {!!props.data?.selectHelpText && (
        <>
          {' '}
          <Help
            iconClass={props.data?.selectHelpIconClass}
            style={helpIconStyles}
          >
            {props.data?.selectHelpText}
          </Help>
        </>
      )}
    </components.SingleValue>
  );
};

const CustomMenuList = function ({ innerProps, selectProps, ...props }) {
  const newInnerProps = useMemo(() => {
    if (!selectProps.testId) return innerProps;

    return {
      ...innerProps,
      'data-testid': selectProps.testId + '-menu-list',
    };
  }, [innerProps, selectProps.testId]);

  return (
    <components.MenuList
      {...props}
      innerProps={newInnerProps}
      selectProps={selectProps}
    />
  );
};

const selectStyles = {
  container: (base, state) => ({
    ...base,
    display: 'block',
    verticalAlign: 'middle',
    fontSize: `${state.selectProps.scale * 0.675}rem`,
    outline: 'none',
    // border: 'none',
  }),
  indicatorsContainer: (base) => ({
    ...base,
    position: 'absolute',
    right: 0,
    top: 0,
  }),
  input: (base) => ({
    ...base,
    margin: 0,
    padding: 0,
    height: 'auto',
    display: 'flex',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  option: (base, state) => ({
    ...base,
    minHeight: state.selectProps.compact
      ? state.selectProps.scale * 24
      : state.selectProps.scale * 36,
    backgroundColor: state.isSelected
      ? state.selectProps.appTheme.select.selectedBackground ||
        state.theme.colors.primary
      : state.isFocused
        ? state.selectProps.appTheme.select.hoverBackground ||
          state.theme.colors.primary25
        : 'transparent',

    color: state.isDisabled
      ? state.theme.colors.neutral20
      : state.isSelected
        ? state.selectProps.appTheme.select.hoverColor ||
          state.theme.colors.neutral0
        : 'inherit',
    fontSize: `${state.selectProps.scale * 0.675}rem`,
  }),
  menu: (base, state) => ({
    ...base,
    background: state.selectProps.appTheme.select.background,
    color: state.selectProps.appTheme.select.color,
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 2000,
  }),
  singleValue: (base, state) => ({
    ...base,
    color: state.selectProps.appTheme.select.color,
  }),
  control: (
    base,
    { isFocused, selectProps: { appTheme, compact, showError, scale } }
  ) => {
    let borderColor;
    if (showError) {
      borderColor = appTheme.colors.danger;
    } else if (appTheme.select.borderColor === null) {
      borderColor = 'transparent';
    } else {
      borderColor = base.borderColor;
    }

    return {
      ...base,
      minHeight: compact ? scale * 24 : scale * 36,
      // background: 'white',
      background: isFocused
        ? appTheme.select.openBackground
        : appTheme.select.background,
      color: appTheme.select.color,
      borderColor,
      cursor: 'pointer',
      outline: appTheme.select.outline === null ? 'transparent' : base.outline,
      boxShadow:
        appTheme.select.boxShadow === null ? 'transparent' : base.boxShadow,
      borderRadius: '8px',
      '&:hover': {
        borderColor:
          appTheme.select.borderColor === null
            ? 'transparent'
            : base['&:hover'].borderColor,
        backgroundColor: appTheme.select.hoverBackground,
      },
    };
  },
  valueContainer: (base, state) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: state.selectProps.isMulti ? 75 : 8,
  }),
  dropdownIndicator: (base, state) => {
    if (state.selectProps.compact) {
      return {
        ...base,
        paddingTop: 1,
        paddingBottom: 1,
        transform: `scale(${state.selectProps.scale})`,
        transformOrigin: 'top right',
      };
    }
    return {
      ...base,
      top: 0,
      bottom: 0,
      transform: `scale(${state.selectProps.scale})`,
      transformOrigin: 'top right',
    };
  },
  menuList: (base) => ({
    ...base,
  }),
};

function findValue(options, value) {
  if (!options) return null;

  for (let i = 0; i < options.length; i++) {
    const item = options[i];
    if (item.type === 'group') {
      const result = findValue(item.options, value);
      if (result) {
        return result;
      }
    }
    if (equal(item.value, value)) {
      return item;
    }
  }
  return null;
}

export function combineStyles(baseStyles, otherStyles) {
  const result = {};
  Object.keys(baseStyles).forEach((key) => {
    if (otherStyles[key]) {
      result[key] = (base, state) => {
        return otherStyles[key](baseStyles[key](base, state), state);
      };
    } else {
      result[key] = baseStyles[key];
    }
  });

  Object.keys(otherStyles).forEach((key) => {
    if (!result[key]) {
      result[key] = otherStyles[key];
    }
  });

  return result;
}

const portalElement = document.getElementById('select-portal');
if (portalElement) {
  portalElement.style.zIndex = 10000;
  portalElement.style.color = '#333';
}
const Select = withTheme(function SelectWithTheme({
  options,
  value,
  onChange,
  isMulti = false,
  className,
  compact,
  theme,
  styles,
  maxMenuHeight,
  portal,
  menuPlacement,
  showError,
  height,
  selectRef,
  collapseGroups,
  components: selectComponents,
  scale = 1,
  defaultOpenGroupIndex = 0,
  maxDropdownHeight = 300,
  ...rest
}) {
  const [openGroupIndex, setOpenGroupIndex] = useState(defaultOpenGroupIndex);

  const setOpenGroupIndexToCurrentValue = useCallback(() => {
    const matchingOptions = options.filter(
      (option) =>
        option.options &&
        option.options.some((nestedOption) => nestedOption.value === value)
    );
    const lastIndex =
      matchingOptions.length > 0
        ? options.lastIndexOf(matchingOptions.pop())
        : -1;

    if (lastIndex !== -1) {
      setOpenGroupIndex(options[lastIndex].index);
    }
  }, [options, value]);

  const onGroupToggle = (index) => {
    setOpenGroupIndex(index);
  };

  const selectValue = useMemo(
    () => (isMulti ? value : findValue(options, value)),
    [isMulti, options, value]
  );

  const combinedStyles = useMemo(() => {
    return styles ? combineStyles(selectStyles, styles) : selectStyles;
  }, [styles]);

  const handleChange = useCallback(
    (opt) => {
      if (isMulti) {
        onChange(opt);
      } else {
        onChange(opt.value, opt);
      }
    },
    [isMulti, onChange]
  );

  return (
    <SelectWrapper ref={selectRef} className={className} height={height}>
      <ReactSelect
        isMulti={isMulti}
        height={height}
        options={options}
        compact={compact}
        styles={combinedStyles}
        value={selectValue}
        onChange={handleChange}
        clearable={false}
        appTheme={theme}
        isSearchable={isMulti}
        maxMenuHeight={maxMenuHeight}
        menuPlacement={menuPlacement}
        menuPortalTarget={portal ? portalElement : null}
        showError={showError}
        closeMenuOnSelect={!isMulti}
        components={{
          Placeholder: CustomPlaceholder,
          MenuList: CustomMenuList,
          SingleValue: CustomSingleValue,
          Input: CustomInput,
          ...(selectComponents ?? {}),
          ...(collapseGroups && {
            MenuList: (props) =>
              CollapsibleMenuList({ ...props, maxHeight: maxDropdownHeight }),
            Group: CollapsibleGroup,
            Option: CollapsibleOption,
          }),
        }}
        onMenuClose={collapseGroups && setOpenGroupIndexToCurrentValue}
        onMenuOpen={collapseGroups && setOpenGroupIndexToCurrentValue}
        onGroupToggle={onGroupToggle}
        openGroupIndex={openGroupIndex}
        scale={scale}
        {...rest}
      />
    </SelectWrapper>
  );
});

export default Select;
