/**
 *
 * ColorTheme
 *
 */
import React from 'react';
import { withTheme } from 'styled-components';
import { equals } from 'ramda';
import switchLight from 'images/switch-light.png';
import switchDark from 'images/switch-dark.png';
import AppearanceBlock from './AppearanceBlock';

import {
  palettes,
  logoColorsCache,
  Palette,
  LogoPalette,
  CustomPalette,
} from './ThemesModal';
import getImagePalette from 'utils/getImagePalette';

@withTheme
class ColorTheme extends React.PureComponent {
  constructor(props) {
    super(props);
    const src = props.logo; // && props.logo.url;
    const defaultLogoUrl = props.defaultLogoUrl;
    if (!src) {
      this.state = {
        logoColors: [],
        defaultLogoColors: [],
      };
      return;
    }

    if (logoColorsCache[src]) {
      this.state = {
        logoColors: logoColorsCache[src],
        defaultLogoColors: logoColorsCache[defaultLogoUrl] || [],
      };
      if (props.getLogoColors) {
        props.getLogoColors(this.state.logoColors);
      }
    } else {
      this.state = {
        logoColors: [],
        defaultLogoColors: [],
      };
      getImagePalette(`${src}?${new Date().getTime()}`, (colors) => {
        this.setState({
          logoColors: colors,
        });
        logoColorsCache[src] = colors;
      });
      if (defaultLogoUrl) {
        getImagePalette(defaultLogoUrl, (colors) => {
          this.setState({
            defaultLogoColors: colors,
          });
          logoColorsCache[defaultLogoUrl] = colors;
        });
      }
    }
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.logoPreview &&
      this.props.logoPreview !== prevProps.logoPreview
    ) {
      this.setupLogoPreview();
    }

    if (
      (this.props.disabled && !prevProps.disabled) ||
      (this.props.disableLogoUpload && !prevProps.disableLogoUpload)
    ) {
      this.setupDefaultLogoPreview();
    }
  }
  // Function that detects if the current colors are logo colors
  isLogoColors = () => {
    const { logoColors } = this.state;
    const { colors } = this.props;
    return equals(colors, logoColors);
  };
  setupDefaultLogoPreview = () => {
    getImagePalette(this.props.defaultLogoUrl, (colors) => {
      this.setState({
        defaultLogoColors: colors,
      });
    });
  };

  setupLogoPreview = () => {
    getImagePalette(this.props.logoPreview, (colors) => {
      if (this.isLogoColors()) {
        this.props.onChangeColors(colors);
      }
      this.setState({
        logoColors: colors,
      });
    });
  };
  render() {
    const {
      theme: { mode },
      colors,
      id,
      type,
      customColors,
      onClick,
      logoPreview,
      logo,
      onChangeColors,
      className,
      disabled,
      defaultTheme,
      defaultThemeCustom,
      defaultLogoUrl,
    } = this.props;
    const { logoColors, defaultLogoColors } = this.state;

    const selectedPalette = palettes.find((palette) =>
      equals(colors, palette.colors)
    );

    const defaultLogoInUse = this.props.disableLogoUpload;
    const defaultLogoColorsThemeSelected = equals(
      defaultTheme,
      defaultLogoColors
    );

    const defaultThemeSelected = this.props.disabled;
    const logoSelected =
      selectedPalette === undefined &&
      (equals(colors, logoColors) ||
        (defaultThemeSelected && defaultLogoColorsThemeSelected) ||
        (defaultLogoInUse && equals(colors, defaultLogoColors)));
    const defaultCustomSelected = equals(colors, defaultThemeCustom);
    const customSelected =
      !selectedPalette && !logoSelected && equals(colors, customColors);
    const otherSelected = !selectedPalette && !logoSelected && !customSelected;

    return (
      <AppearanceBlock
        className={className}
        title="Theme"
        iconImg={mode === 'light' ? switchDark : switchLight}
        onClick={onClick}
        disabled={disabled}
      >
        {!!selectedPalette && (
          <Palette
            colors={selectedPalette.colors}
            image={selectedPalette.image}
            preview
            onClick={onClick}
          />
        )}
        {logoSelected && (
          <LogoPalette
            id={id}
            type={type}
            logoPreview={
              defaultThemeSelected && defaultLogoColorsThemeSelected
                ? defaultLogoUrl
                : logoPreview
            }
            logo={
              defaultThemeSelected && defaultLogoColorsThemeSelected
                ? defaultLogoUrl
                : logo
            }
            defaultLogoUrl={defaultLogoUrl}
            preview
            onClick={onClick}
            onSetColors={onChangeColors}
            Selected={logoSelected}
            disableLogoUpload={this.props.disableLogoUpload}
          />
        )}
        {(customSelected || otherSelected) && (
          <CustomPalette
            preview
            customColors={
              otherSelected
                ? colors
                : defaultThemeSelected
                ? defaultTheme
                : defaultCustomSelected
                ? defaultThemeCustom
                : customColors
            }
            onClick={onClick}
          />
        )}
      </AppearanceBlock>
    );
  }
}

export default ColorTheme;
