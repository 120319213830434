/**
 *
 * SpreadsheetList
 *
 */
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import moment from 'moment';

import Tooltip from 'components/Tooltip';
import Button from 'components/Button';
import { Table, Tr, Scroll } from '../common-styled-components';
import {
  CenterContainer,
  TableStyle,
  ExcelIcon,
  Toggle,
  AlertIcon,
} from '../styledComponents';
import { useTable, useSortBy } from 'react-table';
import DropdownOption from './DropdownOption';
import RenameModal from './RenameModal';
import CheckedIcon from 'components/Icons/CheckedIcon';

const STORAGE_KEY = 'dataSheetSortState';

const getColumns = (
  dateAccessor,
  displaySyncAllGoogleSheetsButton,
  nightlyGoogleSheetSync,
  onToggleNightlyGoogleSheetSync
) => [
  {
    Header: 'Name',
    accessor: 'name',
    width: '40%',
    // eslint-disable-next-line react/display-name
    Cell: ({ row }) => {
      const fileName = row.original.name || 'Excel Data Sheet';
      if (row.original.externalId) {
        return (
          <a
            href={`https://docs.google.com/spreadsheets/d/${row.original.externalId}`}
            target="_blank"
            rel="noreferrer"
          >
            {row.original.name}
          </a>
        );
      }
      const extensionRemoved = fileName
        .replaceAll('.xlsx', '')
        .replaceAll('.xls', '');
      return extensionRemoved;
    },
    sortType: (a, b) => {
      if (a.original.name.toLowerCase() < b.original.name.toLowerCase())
        return -1;
      if (a.original.name.toLowerCase() > b.original.name.toLowerCase())
        return 1;
      return 0;
    },
  },
  {
    Header: 'Type',
    accessor: 'sheetType',
    width: '20%',
    Cell: ({ row }) => {
      if (row.original.__typename === 'Spreadsheet') {
        return <span>Legacy Sheet</span>;
      } else if (row.original.__typename === 'GoogleSpreadsheet') {
        const isExcel = row.original.isExcel;
        return (
          <>
            <span>{isExcel ? 'Excel Sheet' : 'Google Sheet'}</span>
            {!isExcel && row.original.nightlySyncError && (
              <Tooltip content="This sheet did not sync because of errors.">
                <AlertIcon />
              </Tooltip>
            )}
          </>
        );
      } else if (row.original.__typename === 'Table') {
        return <span>Data Sheet</span>;
      }
    },
  },
  {
    Header: 'Last Updated',
    accessor: dateAccessor,
    width: '20%',
    // eslint-disable-next-line react/display-name
    Cell: ({ row }) => {
      if (row.original.__typename === 'Spreadsheet') {
        return (
          <span>
            {moment(
              row.original[row.original.dateAccessorLegacyDatasheet]
            ).format('MM/DD/YYYY h:mm a')}
          </span>
        );
      } else if (row.original.__typename === 'GoogleSpreadsheet') {
        return (
          <span>
            {moment(row.original[row.original.dateAccessorGoogleSheet]).format(
              'MM/DD/YYYY h:mm a'
            )}
          </span>
        );
      } else if (row.original.__typename === 'Table') {
        return (
          <span>
            {moment(row.original[row.original.dateAccessorNewDatasheet]).format(
              'MM/DD/YYYY h:mm a'
            )}
          </span>
        );
      }
    },
    sortType: (a, b) => {
      const aa = moment(a.original.lastUpdated || a.original.lastModified);
      const bb = moment(b.original.lastUpdated || b.original.lastModified);
      if (aa.isBefore(bb)) return -1;
      if (bb.isBefore(aa)) return 1;
      return 0;
    },
  },

  {
    Header: '',
    accessor: 'download',
    width: '10%',
    disableSortBy: true,
    // eslint-disable-next-line react/display-name
    Cell: ({ row }) => {
      if (row.original.__typename === 'GoogleSpreadsheet') {
        if (row.original.isExcel) {
          return (
            <button
              onClick={() => {
                row.original.onDownloadClickedExcelDatasheet(row.original.id);
              }}
            >
              <ExcelIcon /> {'Download'}
            </button>
          );
        }
      }
      return null;
    },
  },

  {
    Header: displaySyncAllGoogleSheetsButton ? (
      <Tooltip content="Sync All Google Sheets.">
        <button style={{ color: '#51b4cd' }}>
          <i
            className="mdi-set mdi-google-drive"
            style={{ color: '#51b4cd' }}
          />{' '}
          Sync
        </button>
      </Tooltip>
    ) : null,
    accessor: 'action',
    width: '6%',
    disableSortBy: true,
    // eslint-disable-next-line react/display-name
    Cell: ({ row }) => {
      if (row.original.__typename === 'Spreadsheet') {
        return (
          <Button
            onClick={() =>
              row.original.onButtonClickedLegacyDatasheet(row.original.id)
            }
            color="primary"
            compact
          >
            {row.original.buttonLabelLegacyDatasheet}
          </Button>
        );
      } else if (row.original.__typename === 'GoogleSpreadsheet') {
        if (row.original.isExcel) {
          return (
            <button
              onClick={() => {
                row.original.onUpdateClickedExcelDatasheet(row.original.id);
              }}
            >
              {row.original.updateLabelExcel}
            </button>
          );
        }
        return (
          <Button
            onClick={() =>
              row.original.onButtonClickedGoogleSheet(row.original.id)
            }
            color="primary"
            loading={
              row.original.buttonLoadingIdGoogleSheet === row.original.id ||
              !!row.original.syncAllLoadingGoogleSheet
            }
            disabled={
              !!row.original.buttonLoadingIdGoogleSheet ||
              row.original.syncAllLoadingGoogleSheet
            }
            compact
            style={{ width: '58px' }}
          >
            {row.original.buttonLabelGoogleSheet}
          </Button>
        );
      } else if (row.original.__typename === 'Table') {
        return (
          <Button
            onClick={() =>
              row.original.onButtonClickedNewDatasheet(row.original.id)
            }
            color="primary"
            compact
          >
            {row.original.buttonLabelNewDatasheet}
          </Button>
        );
      }
    },
  },
  {
    Header: displaySyncAllGoogleSheetsButton ? (
      <CenterContainer>
        <Tooltip content="Sync Google Sheets nightly">
          <Toggle
            checked={nightlyGoogleSheetSync}
            icons={{
              checked: <i className="mdi-set mdi-check" />,
            }}
            onChange={onToggleNightlyGoogleSheetSync}
          />
        </Tooltip>
      </CenterContainer>
    ) : (
      ''
    ),
    accessor: 'settings',
    width: '4%',
    disableSortBy: true,
    // eslint-disable-next-line react/display-name
    Cell: ({ row }) => {
      return (
        <CenterContainer>
          {row.original.__typename === 'Spreadsheet' ? (
            <DropdownOption
              sheet={row.original}
              onDelete={row.original.onDeleteLegacyDatasheet}
              deleteButtonLabel={row.original.deleteButtonLabelLegacyDatasheet}
              onEditName={row.original.handleEditName}
            />
          ) : row.original.__typename === 'GoogleSpreadsheet' ? (
            <DropdownOption
              sheet={row.original}
              onDelete={row.original.onDeleteGoogleSheet}
              deleteButtonLabel={
                row.original.isExcel
                  ? 'Delete Excel Sheet'
                  : row.original.deleteButtonLabelGoogleSheet
              }
              onEditName={row.original.handleEditName}
            />
          ) : row.original.__typename === 'Table' ? (
            <DropdownOption
              sheet={row.original}
              onDelete={row.original.onDeleteNewDatasheet}
              deleteButtonLabel={row.original.deleteButtonLabelNewDatasheet}
              onEditName={row.original.handleEditName}
            />
          ) : null}
        </CenterContainer>
      );
    },
  },
];

const SpreadsheetList = (props) => {
  const {
    spreadsheets,
    buttonLabelLegacyDatasheet,
    buttonLabelGoogleSheet,
    buttonLabelNewDatasheet,
    updateLabelExcel,
    deleteButtonLabelLegacyDatasheet,
    deleteButtonLabelGoogleSheet,
    deleteButtonLabelNewDatasheet,
    onDeleteLegacyDatasheet,
    onDeleteGoogleSheet,
    onDeleteNewDatasheet,
    onRenameLegacyDatasheet,
    onRenameGoogleSheet,
    onRenameNewDatasheet,
    onButtonClickedLegacyDatasheet,
    onButtonClickedGoogleSheet,
    onButtonClickedNewDatasheet,
    onDownloadClickedExcelDatasheet,
    onUpdateClickedExcelDatasheet,
    buttonLoadingIdGoogleSheet,
    onSyncAllButtonClickedGoogleSheet,
    syncAllLoadingGoogleSheet,
    dateAccessorGoogleSheet,
    dateAccessorLegacyDatasheet,
    dateAccessorNewDatasheet,
    type,
    renameLoading,
    displaySyncAllGoogleSheetsButton,
    nightlyGoogleSheetSync,
    onToggleNightlyGoogleSheetSync,
  } = props;
  const dateAccessor = 'lastUpdatedlastModified';
  const storageKey = `${STORAGE_KEY}_${type}_${dateAccessor}`;
  const [selected, setSelected] = useState(null);
  const [editingNameId, setEditingNameId] = useState(null);
  const [newName, setNewName] = useState('');
  const [sheetType, setSheetType] = useState(null);
  const [isExcel, setIsExcel] = useState(false);
  const [sortState, setSortState] = useState(() => {
    const storedSortState = localStorage.getItem(storageKey);
    return storedSortState
      ? JSON.parse(storedSortState)
      : [
          {
            id: dateAccessor,
            desc: true,
          },
        ];
  });

  const handleClick = useCallback((id) => {
    setSelected(id);
  }, []);

  const handleEditName = useCallback((sheet) => {
    setNewName(sheet.name);
    setEditingNameId(sheet.id);
    setSheetType(sheet.__typename);
    setIsExcel(sheet.isExcel);
  }, []);

  const handleNameChange = useCallback((e) => {
    setNewName(e.target.value);
  }, []);

  const handleNameChangeSubmit = useCallback(
    ({ name }) => {
      if (isExcel) {
        onRenameGoogleSheet(editingNameId, name + '.xlsx');
      } else if (sheetType === 'Spreadsheet') {
        onRenameLegacyDatasheet(editingNameId, name);
      } else if (sheetType === 'GoogleSpreadsheet') {
        onRenameGoogleSheet(editingNameId, name);
      } else if (sheetType === 'Table') {
        onRenameNewDatasheet(editingNameId, name);
      }
      setEditingNameId(null);
      setNewName('');
      setSheetType(null);
      setIsExcel(false);
    },
    [
      editingNameId,
      onRenameGoogleSheet,
      onRenameLegacyDatasheet,
      onRenameNewDatasheet,
      sheetType,
    ]
  );

  const handleCloseRenameModal = useCallback(() => {
    setEditingNameId(null);
    setNewName('');
    setSheetType(null);
    setIsExcel(false);
  }, []);

  const rowData = useMemo(() => {
    return spreadsheets.map((item) => {
      return {
        ...item,
        editingNameId,
        newName,
        handleNameChange,
        handleNameChangeSubmit,
        onButtonClickedLegacyDatasheet,
        onButtonClickedGoogleSheet,
        onButtonClickedNewDatasheet,
        onDownloadClickedExcelDatasheet,
        onUpdateClickedExcelDatasheet,
        buttonLoadingIdGoogleSheet,
        syncAllLoadingGoogleSheet,
        buttonLabelLegacyDatasheet,
        buttonLabelGoogleSheet,
        buttonLabelNewDatasheet,
        updateLabelExcel,
        onDeleteLegacyDatasheet,
        onDeleteGoogleSheet,
        onDeleteNewDatasheet,
        deleteButtonLabelLegacyDatasheet,
        deleteButtonLabelGoogleSheet,
        deleteButtonLabelNewDatasheet,
        handleEditName,
        dateAccessorGoogleSheet,
        dateAccessorLegacyDatasheet,
        dateAccessorNewDatasheet,
      };
    });
  }, [
    spreadsheets,
    editingNameId,
    newName,
    handleNameChange,
    handleNameChangeSubmit,
    onButtonClickedLegacyDatasheet,
    onButtonClickedGoogleSheet,
    onButtonClickedNewDatasheet,
    onDownloadClickedExcelDatasheet,
    onUpdateClickedExcelDatasheet,
    buttonLoadingIdGoogleSheet,
    syncAllLoadingGoogleSheet,
    buttonLabelLegacyDatasheet,
    buttonLabelGoogleSheet,
    buttonLabelNewDatasheet,
    updateLabelExcel,
    onDeleteLegacyDatasheet,
    onDeleteGoogleSheet,
    onDeleteNewDatasheet,
    deleteButtonLabelLegacyDatasheet,
    deleteButtonLabelGoogleSheet,
    deleteButtonLabelNewDatasheet,
    handleEditName,
    dateAccessorGoogleSheet,
    dateAccessorLegacyDatasheet,
    dateAccessorNewDatasheet,
  ]);

  const columns = useMemo(
    () =>
      getColumns(
        dateAccessor,
        displaySyncAllGoogleSheetsButton,
        nightlyGoogleSheetSync,
        onToggleNightlyGoogleSheetSync
      ),
    [
      displaySyncAllGoogleSheetsButton,
      nightlyGoogleSheetSync,
      onToggleNightlyGoogleSheetSync,
    ]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data: rowData,
      initialState: {
        sortBy: sortState,
      },
    },
    useSortBy
  );

  useEffect(() => {
    setSortState(sortBy);
    localStorage.setItem(storageKey, JSON.stringify(sortBy));
  }, [dateAccessor, sortBy, storageKey]);

  return (
    <div>
      <Scroll style={{ flexGrow: '1' }}>
        <TableStyle>
          <Table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, i) => (
                <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, j) => {
                    const isActionColumn = column.id === 'action';
                    if (isActionColumn) {
                      return (
                        <th
                          key={j}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          style={{
                            width: column.width,
                          }}
                          onClick={() => {
                            if (!displaySyncAllGoogleSheetsButton) {
                              return;
                            }

                            if (
                              column.id === 'action' &&
                              onSyncAllButtonClickedGoogleSheet
                            ) {
                              const googleSheets = spreadsheets.filter(
                                (sheet) =>
                                  sheet.__typename === 'GoogleSpreadsheet' &&
                                  !sheet.isExcel
                              );
                              onSyncAllButtonClickedGoogleSheet(googleSheets);
                            }
                          }}
                        >
                          {column.Header}
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <>
                                <strong>
                                  <i className="mdi-set mdi-chevron-down" />
                                </strong>
                              </>
                            ) : (
                              <>
                                <strong>
                                  <i className="mdi-set mdi-chevron-up" />
                                </strong>
                              </>
                            )
                          ) : (
                            ''
                          )}
                        </th>
                      );
                    }
                    return (
                      <th
                        key={j}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        style={{
                          width: column.width,
                        }}
                      >
                        {column.Header}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <>
                              <strong>
                                <i className="mdi-set mdi-chevron-down" />
                              </strong>
                            </>
                          ) : (
                            <>
                              <strong>
                                <i className="mdi-set mdi-chevron-up" />
                              </strong>
                            </>
                          )
                        ) : (
                          ''
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                const sheet = row.original;
                return (
                  <Tr
                    {...row.getRowProps()}
                    key={sheet.id}
                    selected={sheet.id === selected || (!selected && i === 0)}
                    onClick={() => handleClick(sheet.id)}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td key={cell.value} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </Tr>
                );
              })}
            </tbody>
          </Table>
        </TableStyle>
      </Scroll>
      <RenameModal
        onClose={handleCloseRenameModal}
        show={!!editingNameId}
        title="Rename Data Sheet"
        onSubmit={handleNameChangeSubmit}
        defaultName={newName}
        loading={renameLoading}
      />
    </div>
  );
};

export default SpreadsheetList;
