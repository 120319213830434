/**
 *
 * Panel
 *
 */

import styled, { css } from 'styled-components';

const Panel = styled.div`
  background-color: ${props => props.theme.panel.background};
  box-shadow: ${props => props.theme.panel.boxShadow};
  ${props =>
    props.hover &&
    css`
      transition: box-shadow 0.25s;
      &:hover {
        box-shadow: 0 10px 40px 0 rgba(18, 106, 211, 0.3),
          0 2px 9px 0 rgba(18, 106, 211, 0.3);
      }
    `}
`;

export default Panel;
