import React, { ReactNode } from 'react';
import { Container, ButtonLink, Button, Icon, Text } from './styledComponents';

interface SidebarButtonProps {
  to?: string;
  beta?: boolean;
  icon: ReactNode;
  text: string;
  id?: string;
  testId?: string;
  onClick?: () => void;
}

const SidebarButton: React.FC<SidebarButtonProps> = ({
  to,
  id,
  beta,
  icon,
  text,
  onClick,
  testId,
}) => {
  return (
    <Container>
      {to ? (
        <ButtonLink
          to={to}
          beta={beta ? 'true' : 'false'}
          data-testid={testId}
          id={id}
        >
          <Icon>{icon}</Icon>
          <Text>{text}</Text>
        </ButtonLink>
      ) : (
        <Button
          beta={beta ? 'true' : 'false'}
          onClick={onClick}
          data-testid={testId}
          id={id}
        >
          <Icon>{icon}</Icon>
          <Text>{text}</Text>
        </Button>
      )}
    </Container>
  );
};

export default SidebarButton;
