import React, { useCallback, useState } from 'react';
import {
  Container,
  Button,
  Buttons,
  RemoveButton,
  VerifyCode,
} from './styledComponents';
import ApplicationSetupModal from './ApplicationSetupModal';
import { useMutation } from '@apollo/client';
import { CodeInput, P } from './ApplicationSetupModal/styledComponents';
import { DISABLE, GET_TWO_FACTOR } from './queriesAndMutations';
import { CurrentUser } from 'interfaces/user';
import BackupCodeModal from './BackupCodeModal';
import { TwoFactorAuthQuery } from '__generated__/graphql';

interface Props {
  enabled: TwoFactorAuthQuery['currentUser']['verifiedTotp'];
  downloadBackupCodes: TwoFactorAuthQuery['currentUser']['downloadedBackupCodes'];
  error?: boolean;
  setError: React.Dispatch<React.SetStateAction<null | string>>;
  triggerToast: (message: string) => void;
}

const AuthenticatorApp = ({
  enabled,
  downloadBackupCodes,
  error,
  setError,
  triggerToast,
}: Props): React.ReactElement => {
  const [showApplicationSetupModal, setShowApplicationSetupModal] =
    useState(false);
  const [showBackupCodeModal, setShowBackupCodeModal] = useState(false);
  const [remove, setRemove] = useState(false);
  const [code, setCode] = useState('');
  const [previousCode, setPreviousCode] = useState<string | null>(null);
  const [disable] = useMutation(DISABLE);

  const handleShowApplicationSetupModal = () => {
    setShowApplicationSetupModal(true);
  };

  const handleCloseApplicationSetupModal = () => {
    setShowApplicationSetupModal(false);
  };

  const handleCloseBackupCodeModal = () => {
    setShowBackupCodeModal(false);
  };

  const handleShowBackupCodeModal = () => {
    setShowBackupCodeModal(true);
  };

  const handleRemove = () => {
    setRemove(true);
  };

  const handleDisable = useCallback(() => {
    if (error && previousCode === code) {
      setError('Please enter a new code');
      return;
    }

    setPreviousCode(code);

    disable({
      variables: { method: 'TOTP', code },
      update: (cache, { data: { disableTwoFactorAuth } }) => {
        if (disableTwoFactorAuth.success) {
          const getTwoFactorCache = cache.readQuery<{
            currentUser: CurrentUser;
          }>({ query: GET_TWO_FACTOR });

          if (!getTwoFactorCache) return;

          const { currentUser } = getTwoFactorCache;

          cache.writeQuery({
            query: GET_TWO_FACTOR,
            data: {
              currentUser: {
                ...currentUser,
                verifiedTotp: false,
                hasTwoFactorAuth: currentUser.verifiedTwoFactorPhone,
              },
            },
          });

          setRemove(false);
          setError(null);
          triggerToast('Two-factor authentication disabled');
        } else if (disableTwoFactorAuth.error) {
          setError(disableTwoFactorAuth.error);
        }
      },
    });
  }, [error, previousCode, code, disable, setError, triggerToast]);

  return (
    <>
      <Container marginBottom="1.5rem">
        <Buttons>
          {enabled && !remove ? (
            <>
              {!downloadBackupCodes && (
                <Button onClick={handleShowBackupCodeModal}>
                  Get Backup Codes
                </Button>
              )}
              <RemoveButton onClick={handleRemove}>Remove 2FA</RemoveButton>
            </>
          ) : !enabled && !remove ? (
            <Button onClick={handleShowApplicationSetupModal}>
              Enable App 2FA Method
            </Button>
          ) : (
            <div>
              <P>
                Enter the 6-digit code generated by your authentication app to
                remove two-factor authentication.
              </P>
              <VerifyCode>
                <CodeInput
                  value={code}
                  onChange={(e) => {
                    setCode(e.target.value);
                    setError(null);
                  }}
                  onKeyPress={(e) => e.key === 'Enter' && handleDisable()}
                  error={error}
                />
                <Button onClick={handleDisable}>Verify Code</Button>
              </VerifyCode>
            </div>
          )}
        </Buttons>
      </Container>
      <ApplicationSetupModal
        show={showApplicationSetupModal}
        onClose={handleCloseApplicationSetupModal}
        triggerToast={triggerToast}
      />
      <BackupCodeModal
        show={showBackupCodeModal}
        onClose={handleCloseBackupCodeModal}
      />
    </>
  );
};

export default AuthenticatorApp;
