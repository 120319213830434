/**
 *
 * KnowledgeButton
 *
 */
import React from 'react';
import styled from 'styled-components';

import media from 'media';

const Button = styled.a`
  font-size: 0.75rem;
  display: none;
  color: ${(props) =>
    props.workspace || props.minimal
      ? props.theme.colors.gear
      : props.theme.header.color};
  white-space: nowrap;
  ${media.tablet`
    display: inline-flex;
    align-items: center;
  `}
  border-radius: 5px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 48.8px;
  margin-right: 5px;
  &:hover {
    background-color: ${(props) =>
      props.theme.mode === 'light'
        ? props.theme.header.buttonHighlight
        : '#393E46'};
    color: ${(props) =>
      props.workspace || props.minimal
        ? props.theme.colors.gear
        : props.theme.header.color};
  }
`;

const I = styled.i`
  font-size: 1.5rem;
  margin-right: 4px;
  vertical-align: middle;
  color: ${(props) =>
    props.workspace || props.minimal
      ? props.theme.colors.gear
      : props.theme.header.color};
`;

const KnowledgeButton = (props) => {
  const { minimal, workspace } = props;

  return (
    <Button
      href="https://knowledge.reachreporting.com"
      target="__blank"
      minimal={minimal}
      workspace={workspace}
      id="knowledge-center-button"
    >
      <I
        className="mdi-set mdi-school-outline"
        minimal={minimal}
        workspace={workspace}
      />
      <span>Knowledge Center</span>
    </Button>
  );
};

export default KnowledgeButton;
