/**
 *
 * ManageLibrary
 *
 */

import React, { useCallback, useState } from 'react';
import gql from 'graphql-tag';
import { Query } from '@apollo/client/react/components';
import { withApollo } from '@apollo/client/react/hoc';

import { LIBRARY_INFO } from 'fragments';

import Modal from 'components/Modal';
import Spinner from 'components/Spinner';

import List from './List';
import withWorkspaceId from 'hoc/withWorkspaceId';
import { captureException } from 'utils/sentry';
import withCompanyId from 'hoc/withCompanyId';
import { showSuccess } from 'utils/popups';
import DropdownButton from 'components/DropdownButton';
import updateName from 'cache/libraryItems';
import { withTheme } from 'styled-components';

const QUERY = gql`
  query ManageLibraryModal($workspaceId: Int) {
    currentUser {
      companies(workspaceId: $workspaceId) {
        id
        name
      }
      firms(workspaceId: $workspaceId) {
        id
        name
        companies {
          id
          name
        }
      }
    }
  }
`;

const CREATE_LIBRARY_ITEM = gql`
  mutation CreateLibraryItem(
    $itemType: ItemType!
    $itemId: Int!
    $itemName: String!
    $settings: ItemLibrarySettingsInput!
  ) {
    createLibraryItemJob(
      itemType: $itemType
      itemId: $itemId
      itemName: $itemName
      settings: $settings
    ) {
      success
    }
  }
`;

const UPDATE_LIBRARY_ITEM = gql`
  mutation UpdateLibraryItem(
    $itemType: ItemType!
    $itemId: Int!
    $itemName: String!
    $settings: ItemLibrarySettingsInput!
  ) {
    updateLibraryItem(
      itemType: $itemType
      itemId: $itemId
      itemName: $itemName
      settings: $settings
    ) {
      ...LibraryInfoFields
    }
  }
  ${LIBRARY_INFO}
`;

@withApollo
@withWorkspaceId
@withCompanyId
@withTheme
class ManageLibraryModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
    };
  }
  setStatePromise = (newState) => {
    return new Promise((resolve) => {
      this.setState(newState, resolve);
    });
  };
  handleSubmit = async (values) => {
    const {
      onClose,
      onUpdate,
      itemType,
      itemName,
      itemId,
      client,
      access,
      workspaceId,
      companyId,
      successMessage,
      theme,
    } = this.props;

    try {
      await this.setStatePromise({
        saving: true,
      });

      const mutation = access ? UPDATE_LIBRARY_ITEM : CREATE_LIBRARY_ITEM;

      const companyIds = new Set();
      const firmIds = [];

      Object.keys(values).forEach((key) => {
        if (values[key]) {
          if (isNaN(key)) {
            const [firmKey, firmId, valueCompanyId] = key.split('_');

            if (firmKey === 'firm' && firmId && !valueCompanyId) {
              firmIds.push(parseInt(firmId, 10));
            } else if (firmKey === 'firm' && firmId && valueCompanyId) {
              companyIds.add(parseInt(valueCompanyId, 10));
            }
          } else {
            companyIds.add(parseInt(key, 10));
          }
        }
      });

      const refetchQueries = access ? ['KpiDrawerLibrary'] : undefined;

      await client.mutate({
        mutation,
        variables: {
          itemType: itemType === 'STATEMENT' ? 'TABLE' : itemType,
          itemId,
          itemName: values.name,
          settings: {
            personalLibrary: values.personalLibrary || false,
            workspace: values.workspace || false,
            personalFolder: values.personalFolder || false,
            companyIds: Array.from(companyIds),
            firmIds,
            workspaceId,
          },
        },
        refetchQueries,
        update: (cache) => {
          if (access) {
            if (values.name !== itemName) {
              updateName(
                cache,
                itemType,
                itemId,
                values.name,
                companyId,
                theme.mode.toUpperCase()
              );
            }

            setTimeout(() => {
              if (itemType === 'DASHBOARD') {
                cache.evict({
                  id: `Company:${companyId}`,
                  fieldName: 'libraryDashboards',
                });
                cache.gc();
              } else if (itemType === 'REPORT') {
                cache.evict({
                  id: `Company:${companyId}`,
                  fieldName: 'libraryReports',
                });
                cache.gc();
              } else if (itemType === 'TABLE') {
                cache.evict({
                  id: `Company:${companyId}`,
                  fieldName: 'libraryTables',
                });
                cache.gc();
              } else if (itemType === 'STATEMENT') {
                cache.evict({
                  id: `Company:${companyId}`,
                  fieldName: 'libraryStatements',
                });
                cache.gc();
              }
            }, 1);
          }
        },
      });

      // if (!access && onCreate) onCreate(result.data.createLibraryItem);
    } catch (err) {
      await this.setStatePromise({
        saving: false,
      });

      return captureException(err, true);
    }

    await this.setStatePromise({
      saving: false,
    });
    onUpdate && onUpdate();
    onClose();

    const metricName = {
      TABLE: 'metric',
      QUERY_TABLE: 'query table',
    };

    if (!access) {
      // eslint-disable-next-line no-new
      showSuccess({
        text:
          successMessage ||
          `Added to library. You can access your library when adding a new ${
            metricName[itemType] || itemType.toLowerCase()
          }.`,
      });
    }
  };
  render() {
    const { show, onClose, access, workspaceId, zIndex, itemName } = this.props;
    const { saving } = this.state;

    return (
      <Modal
        show={show}
        onClose={onClose}
        title={access ? 'Manage Access' : 'Save as Template'}
        width={600}
        zIndex={zIndex}
      >
        <Query query={QUERY} variables={{ workspaceId }}>
          {({ data, loading, error }) => {
            if (loading || error) {
              return <Spinner />;
            }

            let initialValues = {
              name: itemName,
            };
            if (access) {
              const { companies, firms } = data.currentUser;

              const companyIdToFirmId = {};

              const personalCompanyIds = new Set(companies.map((c) => c.id));

              firms.forEach((firm) => {
                firm.companies.forEach((company) => {
                  companyIdToFirmId[company.id] = firm.id;
                });
              });

              initialValues = {
                personalLibrary: access.personalLibrary,
                personalFolder: access.personalFolder,
                workspace: access.workspace,
                name: itemName,
              };
              access.companies.forEach((company) => {
                if (personalCompanyIds.has(company.id)) {
                  initialValues[company.id] = true;
                } else if (companyIdToFirmId[company.id]) {
                  initialValues[
                    `firm_${companyIdToFirmId[company.id]}_${company.id}`
                  ] = true;
                }
              });
              access.firms.forEach((firm) => {
                initialValues[`firm_${firm.id}`] = true;
              });
            }

            return (
              <List
                companies={data.currentUser.companies}
                firms={data.currentUser.firms}
                onSubmit={this.handleSubmit}
                onClose={onClose}
                initialValues={initialValues}
                saving={saving}
              />
            );
          }}
        </Query>
      </Modal>
    );
  }
}

export function ManageLibraryDropdownButton({
  itemId,
  itemType,
  successMessage,
  zIndex,
  itemName,
}) {
  const [showLibraryModal, setShowLibraryModal] = useState(false);

  const onClose = useCallback(() => setShowLibraryModal(false), []);

  return (
    <React.Fragment>
      <DropdownButton onClick={() => setShowLibraryModal(true)}>
        Save as Template
      </DropdownButton>
      <ManageLibraryModal
        itemType={itemType}
        itemId={itemId}
        show={showLibraryModal}
        onClose={onClose}
        successMessage={successMessage}
        zIndex={zIndex}
        itemName={itemName}
      />
    </React.Fragment>
  );
}

export default ManageLibraryModal;
