/**
 *
 * FloatingNotification
 *
 */
import React from 'react';
import styled from 'styled-components';
import { floatingNotificationZIndex } from 'utils/zIndex';

const Div = styled.div`
  background-color: ${(props) => props.theme.colors.secondary};
  border-radius: 4px;
  position: fixed;
  top: 6rem;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  padding: 0.5rem 1rem;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  z-index: ${floatingNotificationZIndex};
`;

interface FloatingNotificationProps {
  children: React.ReactNode;
  testId?: string;
}

const FloatingNotification: React.FunctionComponent<FloatingNotificationProps> =
  (props) => {
    const { children, testId } = props;
    return <Div data-testid={testId}>{children}</Div>;
  };

export default FloatingNotification;
