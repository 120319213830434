/**
 *
 * WorkspaceControls
 *
 */
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import useWorkspaceId from 'hooks/useWorkspaceId';
import CompanyButton from './CompanyButton';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const SWITCH_WORKSPACE = gql`
  mutation SwitchWorkspace {
    setWorkspace(workspaceId: 0) @client
    setCompany(companyId: 0) @client
  }
`;

interface Props {
  Button?: React.ReactElement;
}

const WorkspaceControls = ({ Button }: Props): React.ReactElement | null => {
  const history = useHistory();
  const workspaceId = useWorkspaceId();
  const [switchWorkspace] = useMutation(SWITCH_WORKSPACE);

  const handleSwitch = useCallback(() => {
    switchWorkspace();
    history.push('/workspaces');
  }, [switchWorkspace, history]);

  if (!workspaceId) return null;

  return (
    <>
      {Button ? (
        React.cloneElement(Button, { onClick: handleSwitch })
      ) : (
        <CompanyButton onClick={handleSwitch}>Switch Workspace</CompanyButton>
      )}
    </>
  );
};

export default WorkspaceControls;
