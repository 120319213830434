import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import useCompanyId from './useCompanyId';

const QUERY = gql`
  query UseAccountingBasis($companyId: Int!) {
    company(id: $companyId) {
      id
      accounting {
        accountingBasis
      }
    }
  }
`;

export default function useAccountingBasis({ companyId: _companyId } = {}) {
  const companyId = useCompanyId() || _companyId;

  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  if (!companyId) return undefined;
  if (loading) return undefined;
  if (error) return undefined;
  if (!data || !data.company) {
    return undefined;
  }

  return data.company.accounting
    ? data.company.accounting.accountingBasis
    : null;
}
