import styled, { css, keyframes } from 'styled-components';

export const ICON_COLOR = '#617182';

export const colorFade = keyframes`
  from {
    background: #dbdee2;
    color: ${ICON_COLOR};
  }

  to {
    background: #a5aab0;
    color: #465463;
  }
`;

export const Dropzone = styled.div<{ dragActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid rgb(74, 99, 110);
  padding: 10px 12px;
  width: 480px;
  height: 150px;
  text-align: center;
  margin-right: 14px;
  margin-bottom: 14px;
  cursor: pointer;
  ${(props) =>
    props.dragActive
      ? css`
          animation: ${colorFade} 0.5s ease-in-out infinite alternate;
        `
      : `background: white;`}

  max-height: calc(100vh - 450px);
`;

export const SmallDropzone = styled.div<{ dragActive: boolean }>`
  width: 100%;
  position: relative;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;
  border-radius: 5px;
  ${(props) =>
    props.dragActive
      ? css`
          animation: ${colorFade} 0.5s ease-in-out infinite alternate;
        `
      : `background: #dbdee2;`}
  @media (min-height: 750px) {
    display: none;
  }
`;

export const IconContainer = styled.div`
  height: 50px;
  width: 50px;
  position: absolute;
  left: 5px;
`;

export const Text = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;

  & > span {
    white-space: nowrap;
    overflow: hidden;
    width: auto;
    text-overflow: ellipsis;
  }
`;

export const Info = styled.div`
  font-size: 0.75rem;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  cursor: pointer;
  font-size: 1.8rem;
`;

export const HelpSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 0.25rem;
  max-width: 479px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

export const A1 = styled.a`
  color: ${(props) => props.theme.colors.link};
  font-size: 14px;
  width: 480px;
  border: 1px solid ${({ theme }) => theme.colors.link};
  background-color: transparent;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  margin-bottom: 1rem;
`;

export const A2 = styled.a`
  color: ${(props) => props.theme.colors.link};
  font-size: 14px;
  margin-left: auto;
`;
