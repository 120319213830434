/**
 *
 * BankAccountSelect
 *
 */
import { gql, useQuery } from '@apollo/client';
import Select from 'components/Select';
import useCompanyId from 'hooks/useCompanyId';
import React, { useMemo } from 'react';

const QUERY = gql`
  query BankAccountSelect($companyId: Int!) {
    company(id: $companyId) {
      id
      showAccountNumbers
      accounting {
        bankAccounts {
          id
          name
          accountNumber
        }
      }
    }
  }
`;

const BankAccountSelect = (props) => {
  const companyId = useCompanyId();

  const { data } = useQuery(QUERY, {
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  const bankAccountOptions = useMemo(() => {
    if (
      !data ||
      !data.company ||
      !data.company.accounting ||
      !data.company.accounting.bankAccounts
    ) {
      return [];
    }

    return data.company.accounting.bankAccounts.map((bankAccount) => ({
      value: bankAccount.id,
      label:
        data.company.showAccountNumbers && bankAccount.accountNumber
          ? `${bankAccount.accountNumber} ${bankAccount.name}`
          : bankAccount.name,
    }));
  }, [data]);

  return <Select options={bankAccountOptions} {...props} />;
};

export default BankAccountSelect;
