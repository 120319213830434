/**
 *
 * HorizontalTabs
 *
 */

import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

const Ul = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  ${({ noBorder }) =>
    !noBorder &&
    css`
      border-bottom: 1px solid #ddd;
    `}

  margin-bottom: ${(props) => 15 * props.scale}px;
  &:after {
    clear: both;
    content: '';
    display: block;
  }
`;

const Li = styled.li`
  margin-bottom: -1px;
  position: relative;
  display: flex;
`;

const Button = styled.button`
  color: ${({ theme }) => theme.horizontalTabs.color};
  border: 1px solid transparent;
  border-bottom: 1px solid ${({ theme }) => theme.horizontalTabs.borderColor};
  line-height: ${(props) => 1.42857143 * props.scale};
  padding: ${(props) => 0.5 * props.scale}rem ${(props) => 1.5 * props.scale}rem
    ${(props) => 0.2 * props.scale}rem;
  font-size: ${(props) => props.scale * 100}%;
  cursor: ${(props) =>
    props.disabled ? props.disabledCursor : 'pointer'}!important;
  &:hover {
    color: ${({ theme }) => theme.horizontalTabs.activeColor};
  }
  ${(props) =>
    props.active &&
    css`
      color: ${({ theme }) => theme.horizontalTabs.activeColor};
      cursor: default;
      border-bottom: solid 1px
        ${({ theme }) => theme.horizontalTabs.activeBorderColor};
    `};

  ${(props) =>
    props.beta &&
    css`
      position: relative;
      &&:after {
        content: 'Beta';
        position: absolute;
        top: 0;
        right: 0;
        font-size: 0.555rem;
        color: ${(p) => p.theme.colors.warning};
      }
    `}
`;

function HorizontalTabs(props) {
  const {
    className,
    noBorder,
    tabs,
    beta = [],
    hiddenIndices,
    onChange,
    current,
    children,
    scale = 1,
    disabled = false,
    disabledCursor = 'not-allowed',
  } = props;

  const tabsComponent = useMemo(() => {
    return tabs.reduce((prev, tab, index) => {
      if (!hiddenIndices || !hiddenIndices.includes(index)) {
        prev.push(
          <Li key={index}>
            <Button
              type="button"
              active={index === current}
              className={index === current ? 'active' : null}
              onClick={() => onChange(index)}
              beta={beta.includes(tab)}
              scale={scale}
              disabled={disabled}
              disabledCursor={disabledCursor}
            >
              {tab}
            </Button>
          </Li>
        );
      }
      return prev;
    }, []);
  }, [
    beta,
    current,
    disabled,
    disabledCursor,
    hiddenIndices,
    onChange,
    scale,
    tabs,
  ]);

  return (
    <Ul className={className} noBorder={noBorder} scale={scale}>
      {tabsComponent}
      {children}
    </Ul>
  );
}

export default HorizontalTabs;
