/**
 *
 * Button
 *
 */

import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import Dropdown from 'components/Dropdown';

const clockwise = keyframes`
  0% {
    transform:rotate(0);
    
  }
  100% {
    transform:rotate(360deg);
  }
`;

const Btn = styled.button<{
  color: string;
  outline?: boolean;
  round: boolean;
  block: boolean;
  large: boolean;
  compact: boolean;
  floating: boolean;
  hasRightMenu: boolean;
  loading: string;
  scale: number;
  isCaptureThumbnailButton: boolean;
}>`
  position: relative;
  border: 1px solid transparent;
  &&,
  && i {
    color: ${(props) => {
      if (props.outline) {
        return (
          props.theme.button[props.color].outlineColor ||
          props.theme.button[props.color].base
        );
      }
      return props.theme.button[props.color].color;
    }};
  }
  background: ${(props) =>
    props.outline ? 'transparent' : props.theme.button[props.color].base};
  border-color: ${(props) =>
    props.outline
      ? props.isCaptureThumbnailButton
        ? props.theme.button.default.outlineBorder ||
          props.theme.button.default.base
        : props.theme.button[props.color].outlineBorder ||
          props.theme.button[props.color].base
      : props.theme.button[props.color].border};
  border-width: ${(props) =>
    props.outline ? props.theme.button[props.color].outlineWidth : 0};

  &:hover {
    color: ${(props) =>
      props.outline
        ? props.theme.button[props.color].outlineHover || 'white'
        : props.theme.button[props.color].hoverColor ||
          props.theme.button[props.color].color};
  }
  &:hover i {
    color: ${(props) =>
      props.outline
        ? props.theme.button[props.color].outlineHover || 'white'
        : ''};
  }

  box-shadow: ${(props) =>
    props.floating ? '0 5px 10px rgba(115,108,199,.4)' : 'none'};
  font-size: ${({ scale }) => scale * 13}px;
  padding: ${({ compact, scale }) => (compact ? 0 : scale * 6)}px
    ${({ scale }) => scale * 12}px;
  line-height: ${({ compact, scale }) => (compact ? 1.5 : 1.42857143) * scale};
  cursor: pointer;
  vertical-align: middle;
  background-image: none;
  text-align: center;
  transition: all 0.2s ease;
  font-weight: 300;
  outline: 0;
  border-radius: ${(props) => (props.round ? '50px' : '0.25rem')};
  ${(props) =>
    props.hasRightMenu &&
    css`
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    `}
  ${(props) =>
    props.large &&
    `
    padding: 25px 50px;
    font-size: 1rem;
    line-height: .2;
  `}
  &:hover {
    background: ${(props) =>
      props.outline
        ? props.isCaptureThumbnailButton
          ? props.theme.button.default.outlineHoverBg ||
            props.theme.button.default.hoverBg ||
            props.theme.button.default.hover
          : props.theme.button[props.color].outlineHoverBg ||
            props.theme.button[props.color].hoverBg ||
            props.theme.button[props.color].hover
        : props.theme.button[props.color].hoverBg ||
          props.theme.button[props.color].hover};
    /*box-shadow: 0 5px 10px rgba(115, 108, 199, 0.4);*/
  }
  ${(props) => (props.block ? 'display: block; width: 100%' : '')};
  &:disabled {
    opacity: 0.65;
    cursor: not-allowed;
    &:hover {
      background-color: ${(props) => props.theme.button[props.color].base};
    }
  }

  ${(props) =>
    props.loading === 'true' &&
    css`
      padding-right: 35px;

      &:after {
        content: '';
        top: 57%;
        background: 0 0;
        margin-top: -0.6rem;
        opacity: 0.75;
        left: 0;
        box-sizing: border-box;

        width: 14px;
        height: 14px;
        border: 2px solid
          ${props.outline
            ? props.theme.button[props.color].outlineColor
            : 'white'};
      }

      ${props.outline &&
      css`
        &:after {
          border: 2px solid ${props.theme.button[props.color].color};
        }
        &:hover:after {
          border: 2px solid ${props.theme.button[props.color].color};
        }
      `}

      &:after, &:hover:after {
        border-radius: 50%;
        border-left-color: transparent;
        border-bottom-color: transparent;
        position: absolute;
        animation: ${clockwise} 0.5s linear infinite;

        left: auto;
        right: 0;
        margin-right: 13px;
      }
    `}
`;

const Inner = styled.span`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const Progress = styled.span<{
  progress: number;
  color: string;
}>`
  background: ${(props) => props.theme.button[props.color].base};
  height: 100%;
  width: ${(props) => props.progress}%;
  top: 0;
  left: 0;
  position: absolute;
  overflow: hidden;
`;

const LoadingText = styled.span`
  position: absolute;
  left: 21px;
  right: 40px;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  color: white;
`;

const Text = styled.span`
  vertical-align: middle;
`;

const TextDiv = styled.div``;

const Beta = styled.span`
  font-size: 11px;
  vertical-align: super;
  margin-left: 3px;
`;

const SideBtn = styled(Btn)`
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding: 0;
  height: 100%;
  font-size: ${({ scale }) => scale * 100}%;
  vertical-align: top;
`;

const MenuButtonWrapper = styled.div`
  display: inline-flex;
  align-content: stretch;
`;

const StyledDropdown = styled(Dropdown)`
  & > div:first-child {
    height: 100%;
  }
`;

const I = styled.i<{ scale: number }>`
  font-size: ${({ scale }) => scale * 1.3}rem;
  line-height: 0;
  transform: translateY(${({ scale }) => scale * 3}px);
`;

function SideButton(props) {
  return (
    <SideBtn {...props}>
      <I className="mdi-set mdi-menu-down" scale={props.scale} />
    </SideBtn>
  );
}

interface Props {
  progress?: number;
  color?: string;
  children?: React.ReactNode;
  beta?: boolean;
  dropdownContent?: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  scale?: number;
  onMouseOver?: React.MouseEventHandler;
  outline?: boolean;
  round?: boolean;
  block?: boolean;
  large?: boolean;
  compact?: boolean;
  floating?: boolean;
  hasRightMenu?: boolean;
  'data-testid'?: string;
  onClick?: React.MouseEventHandler;
  type?: HTMLButtonElement['type'];
  divContainer?: boolean;
  id?: string;
  isCaptureThumbnailButton?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      progress,
      color,
      children = undefined,
      beta,
      dropdownContent,
      disabled = false,
      loading,
      scale = 1,
      onMouseOver,
      round = false,
      compact = false,
      block = false,
      large = false,
      floating = false,
      divContainer = false,
      isCaptureThumbnailButton = false,
      ...rest
    },
    ref
  ) => {
    const mainContent = (
      <Btn
        ref={ref}
        {...rest}
        disabled={disabled}
        color={color || 'default'}
        hasRightMenu={!!dropdownContent}
        loading={loading ? 'true' : 'false'}
        scale={scale}
        onMouseOver={onMouseOver}
        round={round}
        compact={compact}
        block={block}
        large={large}
        floating={floating}
        isCaptureThumbnailButton={isCaptureThumbnailButton}
      >
        {divContainer ? <TextDiv>{children}</TextDiv> : <Text>{children}</Text>}
        {beta && <Beta>Beta</Beta>}
        {progress !== undefined && (
          <Inner>
            <Progress progress={progress} color={color || 'default'}>
              <LoadingText>{children}</LoadingText>
            </Progress>
          </Inner>
        )}
      </Btn>
    );

    let dropdown: React.ReactNode = null;

    if (dropdownContent) {
      dropdown = (
        <StyledDropdown
          trigger={
            <SideButton
              color={color || 'default'}
              disabled={disabled}
              scale={scale}
            />
          }
          scale={scale}
        >
          {dropdownContent}
        </StyledDropdown>
      );
    }

    if (dropdown) {
      return (
        <MenuButtonWrapper>
          {mainContent}
          {dropdown}
        </MenuButtonWrapper>
      );
    }

    return mainContent;
  }
);

Button.displayName = 'Button';

export default Button;
