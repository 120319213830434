import Highcharts from 'highcharts';

import exporting from 'highcharts/modules/exporting';
import patternFill from 'highcharts/modules/pattern-fill';
import moreCharts from 'highcharts/highcharts-more';
import theme from 'themes/quantum';

exporting(Highcharts);
patternFill(Highcharts);
moreCharts(Highcharts);

const font = 'Open Sans';

window.Highcharts = Highcharts;

Highcharts.seriesTypes.areaspline.prototype.drawLegendSymbol =
  Highcharts.seriesTypes.spline.prototype.drawLegendSymbol;

Highcharts.seriesTypes.area.prototype.drawLegendSymbol =
  Highcharts.seriesTypes.line.prototype.drawLegendSymbol;

Highcharts.setOptions({
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  lang: {
    thousandsSep: ',',
    drillUpText: 'Back',
  },
  plotOptions: {
    spline: {
      marker: {
        symbol: 'circle',
      },
    },
    area: {
      marker: {
        symbol: 'circle',
      },
    },
    areaspline: {
      marker: {
        symbol: 'circle',
      },
    },
    scatter: {
      marker: {
        symbol: 'circle',
      },
    },
    waterfall: {
      borderWidth: 0,
    },
    series: {
      marker: {
        lineColor: '#fff',
        lineWidth: 1,
      },
      dataLabels: {
        enabled: false,
        style: {
          fontFamily: font,
          fontWeight: 400,
          fontSize: '12px',
        },
      },
    },
  },
  title: {
    text: null,
    style: {
      fontFamily: font,
      fontWeight: 400,
      fontSize: '18px',
    },
  },
  subtitle: {
    text: null,
    style: {
      fontFamily: font,
      fontWeight: 400,
      fontSize: '12px',
    },
  },
  chart: {
    style: {
      fontFamily: font,
      fontWeight: 400,
      fontSize: '12px',
    },
  },
  xAxis: {
    title: {
      text: null,
      style: {
        fontFamily: font,
        fontWeight: 400,
        fontSize: '12px',
      },
    },
    labels: {
      style: {
        fontFamily: font,
        fontWeight: 400,
        fontSize: '12px',
      },
    },
  },
  yAxis: [
    {
      title: {
        text: null,
        style: {
          fontFamily: font,
          fontWeight: 400,
          fontSize: '12px',
        },
      },
      labels: {
        style: {
          fontFamily: font,
          fontWeight: 400,
          fontSize: '12px',
        },
      },
      scalable: false,
    },
    {
      title: {
        text: null,
        style: {
          fontFamily: font,
          fontWeight: 400,
          fontSize: '12px',
        },
      },
      labels: {
        style: {
          fontFamily: font,
          fontWeight: 400,
          fontSize: '12px',
        },
      },
      scalable: false,
    },
  ],
  legend: {
    enabled: true,
    align: 'center',
    verticalAlign: 'bottom',
    layout: 'horizontal',
    navigation: {
      enabled: false,
    },
    itemStyle: {
      fontFamily: font,
      fontWeight: 400,
      fontSize: '12px',
    },
    title: {
      style: {
        fontFamily: 'Open Sans',
        fontWeight: 400,
        fontSize: '12px',
      },
    },
  },
  /* responsive: {
    rules: [
      {
        chartOptions: {
          legend: {
            enabled: false,
            itemStyle: {
              fontSize: '9px',
              lineHeight: '9px',
              textOverflow: 'ellipsis',
            },
            symbolHeight: 6,
          },
        },
        condition: {
          maxWidth: 576,
        },
      },
    ],
  }, */
  colors: theme.chartColors,
});
