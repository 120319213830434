/**
 *
 * Result
 *
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ClassDepartmentFilter, Company, Country } from 'interfaces/company';
import { Firm } from 'interfaces/firm';
import {
  AbbreviationInput,
  CenteredTd,
  CenteredTh,
  CompanyList,
  ConsolidatedName,
  ConsolidatedNameInput,
  CurrencyCheckbox,
  CurrencyConversion,
  CurrencySelection,
  Footer,
  LaunchCurrencyModal,
  Submit,
  IconButton,
  TruncatedTd,
  Info,
  FiltersWarningIcon,
} from './styledComponents';
import { countries, formatCurrency } from 'library/currency';
import produce from 'immer';
import { useMutation } from '@apollo/client';
import {
  CREATE,
  handleCreate,
  handleInvalidate,
  UPDATE,
} from './cacheFunctions';
import useWorkspaceId from 'hooks/useWorkspaceId';
import Help from 'components/Help';
import CurrencyModal from 'components/CurrencyModal';
import { notificationToast } from 'utils/popups';
import { RightContainer } from 'components/ItemSelectFlowStructure';
import TrashIcon from 'components/Icons/TrashIcon';
import ClassDepartmentModal, {
  FilterTypes,
} from 'components/ClassDepartmentModal';
import { Accounting, Class, Department } from 'interfaces/accounting';
import BetaBadge from 'components/BetaBadge';
import Tooltip from 'components/Tooltip';
import ClassesDropdown from './ClassesDropdown';
interface ClassDepartmentProps {
  companyId?: number;
  type?: FilterTypes;
  selected?: number[];
}

interface Props {
  /**
   * A consolidated company to update
   */
  company?: Company;
  /**
   * All the different folders / groups the user has access to
   */
  folders: Firm[];
  /**
   * The currency the consolidated company is set to
   */
  consolidationCurrency: string;
  /**
   * A flag to apply currency conversion
   */
  applyCurrencyConversion: boolean;
  /**
   * Flag to hide the currency conversion options
   */
  hideConversion: boolean;
  /**
   * The accounting integration that will be assigned to the consolidated company
   */
  accountingIntegration?: string;
  /**
   * The IDs of all selected companies
   */
  selectedIds: number[];
  /**
   * Callback function invoked on close
   */
  onClose: () => void;
  /**
   * The workspace ID
   */
  firmId: number;
  /**
   * Callback function invoked on removal of selected company
   */
  onRemove: (id: number) => void;
  /**
   * Display in view only mode
   */
  viewOnly?: boolean;
}

const Result: React.FC<Props> = ({
  company,
  folders,
  consolidationCurrency,
  applyCurrencyConversion: _applyCurrencyConversion,
  hideConversion,
  accountingIntegration,
  selectedIds,
  onClose,
  firmId,
  onRemove,
  viewOnly = true,
}) => {
  const workspaceId = useWorkspaceId();

  /**
   * The name given to the consolidated company
   */
  const [name, setName] = useState<string>(company ? company.name : '');

  /**
   * Abbreviations given to selected companies
   */
  const [abbreviations, setAbbreviations] = useState<{ [x: string]: string }>(
    company
      ? company.consolidationCompanies.reduce<{ [x: string]: string }>(
          (_abbreviations, consolidatedCompany) => {
            if (consolidatedCompany.abbreviation) {
              _abbreviations[consolidatedCompany.id] =
                consolidatedCompany.abbreviation;
            }

            return _abbreviations;
          },
          {}
        )
      : {}
  );

  /**
   * The current currency set for the consolidation
   */
  const [currency, setCurrency] = useState<string>(
    consolidationCurrency || 'US'
  );

  /**
   * A flag to apply currency conversion
   */
  const [applyCurrencyConversion, setApplyCurrencyConversion] =
    useState<boolean>(_applyCurrencyConversion || false);

  /**
   * A flag to disable the ability to use currency conversion
   */
  const [disableConversion, setDisableConversion] = useState<boolean>(true);

  /**
   * A flag to hide or show the currency select modal
   */
  const [showCurrencyModal, setShowCurrencyModal] = useState<boolean>(false);

  /**
   * A flag to hide or show the class department modal
   */
  const [showClassDepartmentModal, setShowClassDepartmentModal] =
    useState<boolean>(false);

  /**
   * The props passed to the class department modal
   */
  const [classesAndDepartmentsProps, setClassesAndDepartmentsProps] =
    useState<ClassDepartmentProps>({});

  const [classesAndDepartments, setClassesAndDepartments] = useState<
    ClassDepartmentFilter[]
  >(company?.filters || []);

  /**
   * An object that converts a company ID to company object
   */
  const idToCompany = useMemo(() => {
    if (viewOnly) {
      return company?.consolidationCompanies.reduce<{ [x: string]: Company }>(
        (_idToCompany, childCompany) => {
          _idToCompany[childCompany.id] = childCompany;
          return _idToCompany;
        },
        {}
      );
    }

    return folders.reduce<{ [x: string]: Company }>((_idToCompany, folder) => {
      folder.companies.forEach(
        (folderCompany) => (_idToCompany[folderCompany.id] = folderCompany)
      );
      return _idToCompany;
    }, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folders]);

  /**
   * The variables passed to the create / update mutations
   */
  const variables = useMemo(
    () => ({
      [company ? 'companyId' : 'firmId']: company ? company.id : firmId,
      name,
      companyIds: selectedIds,
      workspaceId,
      abbreviations: selectedIds.map((id) => abbreviations[id] || null),
      currency,
      applyCurrencyConversion,
      filters: classesAndDepartments.map((item) => ({
        companyId: item.companyId,
        filters: {
          classes: item.filters.classes?.map((classItem) => classItem) || [],
          departments:
            item.filters.departments?.map((departmentItem) => departmentItem) ||
            [],
        },
      })),
    }),
    [
      abbreviations,
      applyCurrencyConversion,
      classesAndDepartments,
      company,
      currency,
      firmId,
      name,
      selectedIds,
      workspaceId,
    ]
  );

  /**
   * Retrieves a given company' set currency
   */
  const getCompanyCurrency = useCallback(
    (id: number) =>
      idToCompany && idToCompany[id] ? idToCompany[id].currency : 'US',
    [idToCompany]
  );

  /**
   * Retrieves a given company's name
   */
  const getCompanyName = useCallback(
    (id: number) =>
      idToCompany && idToCompany[id] ? idToCompany[id].name : 'Missing',
    [idToCompany]
  );

  /**
   * Updates the consolidated companies name on change event
   */
  const handleChangeName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value),
    []
  );

  /**
   * Updates a company's abbreviation
   * @param { string } value  The new abbreviation
   * @param { number } id     The ID of the company the abbreviation is assigned to
   */
  const handleChangeAbbreviation = useCallback(
    (value: string, id: number) =>
      setAbbreviations(
        produce((draft) => {
          draft[id] = value;
        })
      ),
    []
  );

  /**
   * Handles the close event
   */
  const handleClose = useCallback(() => onClose(), [onClose]);

  /**
   * Triggers the disabled currency conversion toast
   */
  const handleDisabledNotification = useCallback(() => {
    if (disableConversion) {
      notificationToast({
        title: 'Oops...',
        text: `Currency Conversion currently isn't available for this integration.`,
        icon: 'warning',
        timer: 3000,
        timerProgressBar: true,
      });
    }
  }, [disableConversion]);

  /**
   * Mutation to create a consolidated company
   */
  const [createConsolidation, { loading: loadingCreate }] = useMutation<{
    createConsolidatedCompany: Company;
  }>(CREATE, {
    variables,
    onCompleted: handleClose,
    update: (cache, result) => {
      handleCreate(cache, result, firmId);
    },
  });

  /**
   * Mutation to update a consolidated company
   */
  const [updateConsolidation, { loading: loadingUpdate }] = useMutation<{
    createConsolidatedCompany: Company;
  }>(UPDATE, {
    variables,
    onCompleted: handleClose,
    update: (cache) => {
      if (company) {
        handleInvalidate(cache, company.id, variables.filters);
      }
    },
  });

  // TODO: This is temporary until CSV is finished.
  /**
   * Flips the disable conversion flag depending on accounting integration
   */
  useEffect(() => {
    if (accountingIntegration === null || accountingIntegration === 'CSV') {
      setApplyCurrencyConversion(false);
      setDisableConversion(true);
    } else {
      setDisableConversion(false);
    }
  }, [accountingIntegration]);

  const handleShowClassDepartmentModal = useCallback(
    (companyId: number) => {
      const initialProps: ClassDepartmentProps = { companyId };
      const selectedCompany = classesAndDepartments.find(
        (item) => item.companyId === companyId
      );
      let type: FilterTypes;
      let selected: number[];
      if (selectedCompany) {
        type = selectedCompany.filters.classes?.length
          ? 'CLASSES'
          : 'DEPARTMENTS';
        selected =
          selectedCompany?.filters[type.toLowerCase()]?.map(
            (item: Class | Department | number | string) => {
              if (typeof item === 'number' || typeof item === 'string') {
                return item;
              } else {
                return item.id;
              }
            }
          ) || [];

        initialProps.type = type;
        initialProps.selected = selected;

        setClassesAndDepartmentsProps(initialProps);
      } else {
        setClassesAndDepartmentsProps(initialProps);
      }
      setShowClassDepartmentModal(true);
    },
    [classesAndDepartments]
  );

  const handleClassDepartmentSave = useCallback(
    (
      companyId: number,
      type: FilterTypes,
      selected: Accounting['classes'] | Accounting['departments']
    ) => {
      if (selected.length) {
        setClassesAndDepartments(
          produce((draft) => {
            const index = draft.findIndex(
              (item) => item.companyId === companyId
            );
            if (index === -1) {
              const filters = {};
              filters[type.toLowerCase()] = selected.map((item) => item.id);
              draft.push({ companyId, filters });
            } else {
              const filters = {};
              filters[type.toLowerCase()] = selected.map((item) => item.id);
              draft[index].filters = filters;
            }
          })
        );
      } else {
        setClassesAndDepartments(
          produce((draft) => {
            const index = draft.findIndex(
              (item) => item.companyId === companyId
            );

            if (index !== -1) {
              draft.splice(index, 1);
            }
          })
        );
      }
    },
    []
  );
  const classesAndDepartmentsCount = useMemo(() => {
    let count = 0;
    classesAndDepartments.forEach((item) => {
      if (item.filters.classes?.length) {
        count += item.filters.classes.length;
      } else if (item.filters.departments?.length) {
        count += item.filters.departments.length;
      }
    });
    return count;
  }, [classesAndDepartments]);

  const handleRemove = useCallback(
    (companyId) => {
      onRemove(companyId);
      setClassesAndDepartments(
        produce((draft) => {
          const index = draft.findIndex((item) => item.companyId === companyId);
          if (index > -1) {
            draft.splice(index, 1);
          }
        })
      );
    },
    [onRemove]
  );

  return (
    <>
      <RightContainer>
        <CompanyList>
          {selectedIds.length ? (
            <>
              <table>
                <colgroup>
                  <col span={1} style={{ width: '50%' }} />
                  <col span={1} style={{ width: '13%' }} />
                  <col span={1} style={{ width: '15%' }} />
                  <col span={1} style={{ width: '5%' }} />
                  <col span={1} style={{ width: '5%' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>Added</th>
                    <CenteredTh>Currency</CenteredTh>
                    <th>
                      Abbreviation{' '}
                      <Help forcePosition>
                        Allows for shorter titles when using the metric builder
                        when showing separate company data.
                      </Help>
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {selectedIds.map((companyId) => (
                    <tr key={companyId}>
                      <TruncatedTd title={getCompanyName(companyId)}>
                        {getCompanyName(companyId)}
                      </TruncatedTd>
                      <CenteredTd>{getCompanyCurrency(companyId)}</CenteredTd>
                      <td>
                        <AbbreviationInput
                          value={abbreviations[companyId] || ''}
                          onChange={(e) =>
                            handleChangeAbbreviation(e.target.value, companyId)
                          }
                          disabled={viewOnly}
                          viewOnly={viewOnly}
                        />
                      </td>
                      {!viewOnly && (
                        <>
                          {!!accountingIntegration &&
                            accountingIntegration !== 'CSV' &&
                            accountingIntegration !== 'SUMMIT' &&
                            accountingIntegration !== 'MYOB' && (
                              <td>
                                <ClassesDropdown
                                  classesAndDepartments={classesAndDepartments}
                                  companyId={companyId}
                                  getCompanyName={getCompanyName}
                                  handleShowClassDepartmentModal={
                                    handleShowClassDepartmentModal
                                  }
                                />
                              </td>
                            )}
                          <td>
                            <IconButton
                              onClick={() => handleRemove(companyId)}
                              type="button"
                              color="danger"
                            >
                              <TrashIcon />
                            </IconButton>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
              <Info hide={classesAndDepartments.length === 0}>
                <Tooltip content="Based on the number of classes/departments used, after saving it may take several minutes of syncing to update the necessary data.">
                  <FiltersWarningIcon className="mdi-set mdi-alert" />
                </Tooltip>
                <span id="classes-and-departments-counter">{`${classesAndDepartmentsCount}/50 Classes and Departments Used`}</span>
              </Info>
            </>
          ) : (
            <p style={{ textAlign: 'center' }}>No companies selected</p>
          )}
        </CompanyList>

        <Footer>
          {!hideConversion && (
            <>
              <CurrencySelection>
                Reporting Currency
                <LaunchCurrencyModal
                  disabled={viewOnly}
                  onClick={() => setShowCurrencyModal(true)}
                >
                  {`${countries[currency].name} ${countries[currency].currency} ${countries[currency].symbol}`}
                </LaunchCurrencyModal>
              </CurrencySelection>
              <div style={{ textAlign: 'right', marginBottom: '10px' }}>
                {formatCurrency(12345678.12, currency)}
              </div>
              <CurrencyConversion>
                <CurrencyCheckbox
                  id="currencyConversion"
                  disabled={viewOnly || disableConversion}
                  checked={applyCurrencyConversion}
                  onClick={handleDisabledNotification}
                  onChange={() =>
                    setApplyCurrencyConversion(!applyCurrencyConversion)
                  }
                />
                <label
                  htmlFor="currencyConversion"
                  onClick={handleDisabledNotification}
                  style={{
                    display: 'flex',
                    cursor:
                      viewOnly || disableConversion ? 'not-allowed' : 'pointer',
                  }}
                >
                  Enable Currency Conversion
                </label>
                <Help placement="left-end">
                  Currency conversion is available for QuickBooks and Xero
                  consolidations. Accounts coming from the balance sheet are
                  converted using the exchange rates at the end of the month,
                  and non balance sheet accounts use the monthly average
                  exchange rates.
                </Help>
              </CurrencyConversion>
            </>
          )}
          {!viewOnly && (
            <ConsolidatedName>
              <ConsolidatedNameInput
                placeholder="Consolidated Company Name"
                onChange={handleChangeName}
                value={name}
              />
              <Submit
                color="primary"
                loading={loadingCreate || loadingUpdate}
                disabled={
                  loadingCreate ||
                  loadingUpdate ||
                  !name ||
                  !name.trim() ||
                  classesAndDepartmentsCount > 50
                }
                onClick={company ? updateConsolidation : createConsolidation}
                id={
                  company
                    ? 'update-consolidated-company-button'
                    : 'create-consolidated-company-button'
                }
              >
                {company
                  ? loadingUpdate
                    ? 'Updating'
                    : 'Update'
                  : loadingCreate
                    ? 'Creating'
                    : 'Create'}
              </Submit>
            </ConsolidatedName>
          )}
        </Footer>
      </RightContainer>
      <CurrencyModal
        show={showCurrencyModal}
        onClose={() => setShowCurrencyModal(false)}
        currency={currency}
        onSubmit={(country: { country: string } & Country) =>
          setCurrency(country.country)
        }
      />
      <ClassDepartmentModal
        show={showClassDepartmentModal}
        onClose={() => setShowClassDepartmentModal(false)}
        onSave={handleClassDepartmentSave}
        {...classesAndDepartmentsProps}
      />
    </>
  );
};

export default Result;
