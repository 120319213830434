import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import GroupedDropdown, { DropdownItem } from 'components/GroupedDropdown';
import CompanyButton from './CompanyButton';
import { Company } from 'interfaces/company';
import {
  BUTTON_HEIGHT,
  HEADER_HEIGHT,
} from 'components/GroupedDropdown/styledComponents';

type CompanyDropdownItem = Partial<Company> & DropdownItem;

const QUERY = gql`
  query WorkspaceCompanyList($workspaceId: Int!) {
    groups(workspaceId: $workspaceId) {
      id
      name
      companies {
        id
        name
        isConsolidation
        accountingIntegration
        firmId
      }
    }
    workspace(id: $workspaceId) {
      sharedCompanies {
        id
        name
        isConsolidation
        accountingIntegration
      }
    }
  }
`;

const IS_DEMO = gql`
  query IsDemoCompany($companyId: Int!) {
    company(id: $companyId) {
      id
      name
      demo
    }
  }
`;

const compareByName = <T extends { name: string }>(a: T, b: T) =>
  a.name.toLowerCase() > b.name.toLowerCase()
    ? 1
    : b.name.toLowerCase() > a.name.toLowerCase()
      ? -1
      : 0;
interface CompanyDropdownProps {
  companyId: number;
  workspaceId: number;
  sidebarExpanded: boolean;
  clientPortal: boolean;
}

const CompanyDropdown: React.FunctionComponent<CompanyDropdownProps> = ({
  companyId,
  workspaceId,
  sidebarExpanded,
  clientPortal,
}) => {
  const { data, loading, error } = useQuery(QUERY, {
    variables: { workspaceId },
    skip: !workspaceId || clientPortal,
  });

  const {
    data: demoData,
    loading: demoDataLoading,
    error: demoDataError,
  } = useQuery(IS_DEMO, {
    variables: { companyId },
    skip: !companyId,
  });

  const { demoStatus, demoName } = useMemo(() => {
    const companyDemoData = !demoDataLoading && !demoDataError && demoData;
    const companyDemoStatus = companyDemoData?.company?.demo;
    const companyDemoName = companyDemoData?.company?.name;
    return { demoStatus: companyDemoStatus, demoName: companyDemoName };
  }, [demoData, demoDataLoading, demoDataError]);

  const { dropdownItems, currentCompany, selectedPosition } = useMemo<{
    dropdownItems: CompanyDropdownItem[];
    currentCompany: string;
    selectedPosition: number;
  }>(() => {
    if (loading || error || !data) {
      return {
        dropdownItems: [] as DropdownItem[],
        currentCompany: '',
        selectedPosition: 0,
      };
    }

    const groups = [...data.groups].sort(compareByName);
    const result: CompanyDropdownItem[] = [];
    let currentName = '';
    let position = 0;
    let currentSelectedPosition = 0;

    if (data.workspace.sharedCompanies.length) {
      result.push({ id: -1, type: 'header', name: 'Shared with me' });
      data.workspace.sharedCompanies.forEach((company) => {
        result.push({ type: 'item', ...company });
        if (company.id === companyId) currentName = company.name;
      });
    }

    groups.forEach((group) => {
      if (group.companies.length) {
        result.push({ id: -1, type: 'header', name: group.name });
        position += HEADER_HEIGHT;
        group.companies.forEach((company) => {
          result.push({ type: 'item', ...company });
          if (company.id === companyId) {
            currentName = company.name;
            currentSelectedPosition = position;
          }
          position += BUTTON_HEIGHT;
        });
      }
    });

    if (demoStatus) {
      currentName = demoName;
    }

    return {
      dropdownItems: result,
      currentCompany: currentName,
      selectedPosition: currentSelectedPosition,
    };
  }, [companyId, data, demoName, demoStatus, loading, error]);

  if (!data || !data.groups || !companyId) return null;

  return (
    <GroupedDropdown
      dropdownItems={dropdownItems}
      displayedName={currentCompany}
      selected={{ id: companyId, type: 'item', name: currentCompany }}
      selectedPosition={selectedPosition}
      sidebarExpanded={sidebarExpanded}
      ButtonComponent={CompanyButton}
      buttonType="company"
      buttonTypePlural="companies"
    />
  );
};

export default CompanyDropdown;
