/**
 *
 * ConsolidationFilter
 *
 */
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import gql from 'graphql-tag';

import FilterBox, { ParentItem } from 'components/FilterBox';
import Modal from 'components/Modal';
import { useQuery } from '@apollo/client';
import useCompanyId from 'hooks/useCompanyId';
import Checkbox from 'components/Checkbox';
import useLink from 'hooks/useLink';
import Spinner from 'components/Spinner';
import usePrevious from 'hooks/usePrevious';
import { parseStringFilterId } from 'library/table/utils';
import { Class, Department } from 'interfaces/accounting';
import { Company } from 'interfaces/company';

export const CONSOLIDATION_FILTERS_QUERY = gql`
  query ConsolidationFilter($companyId: Int!) {
    company(id: $companyId) {
      id
      consolidationCompanies {
        id
        name
        abbreviation
        classes {
          id
          name
          parentId
        }
        departments {
          id
          name
          parentId
        }
      }
    }
  }
`;

const getItems = (
  companies: Company[],
  selected: (string | number)[],
  allowedCompanyIds: Array<number | string> | undefined
) =>
  companies.map((c) => {
    const companyValue = `company_${c.id}`;

    return {
      id: c.id,
      value: companyValue,
      label: c.name,
      abbreviation: c.abbreviation,
      disabled: selected.some(
        (item) =>
          (item + '').includes(`class_${c.id}`) ||
          (item + '').includes(`department_${c.id}`)
      ),
      classesOrDepartments: [
        ...(c.classes || []).map((item: Class) => ({
          id: item.id,
          value: `class_${c.id}_${item.id}`,
          label: item.name,
          disabled: selected.includes(companyValue),
        })),
        ...(c.departments || []).map((item: Department) => ({
          id: item.id,
          value: `department_${c.id}_${item.id}`,
          label: item.name,
          parentId: c.id,
          disabled: selected.includes(companyValue),
        })),
      ].filter((item) => {
        return (
          !allowedCompanyIds ||
          allowedCompanyIds.includes(item.value) ||
          allowedCompanyIds.includes(companyValue) ||
          allowedCompanyIds.includes(c.id)
        );
      }),
    };
  });

interface ConsolidationFilterProps {
  onSave?: (
    ids: (string | number)[] | null,
    applyToAll: boolean,
    columnSubtitle: string
  ) => void;
  selected: (string | number)[];
  onClose?: () => void;
  onChange: (ids: Array<any>) => void;
  show?: boolean;
  embed?: boolean;
  allowedCompanyIds?: Array<number | string>;
  showApplyToAll?: boolean;
  options?: Array<any>;
  allowChildAndParentSelection?: boolean;
}
const emptyArray: string[] = [];

const ConsolidationFilter: React.FunctionComponent<ConsolidationFilterProps> =
  React.memo(function ConsolidationFilterInner(props) {
    const {
      show = false,
      onClose,
      onSave,
      embed,
      onChange: onChangeProp,
      allowedCompanyIds,
      showApplyToAll,
      allowChildAndParentSelection,
      selected: _defaultSelected = emptyArray,
    } = props;

    const defaultSelected = useMemo(() => {
      return (_defaultSelected || []).map((entry) => {
        if (typeof parseStringFilterId(entry) === 'number') {
          return `company_${entry}`;
        }
        return entry;
      });
    }, [_defaultSelected]);

    const link = useLink();
    const [searchValue, setSearchValue] = useState<string>('');

    const companyId = useCompanyId() || (link && link.companyId);
    const { data, loading } = useQuery(CONSOLIDATION_FILTERS_QUERY, {
      variables: {
        companyId,
      },
      skip: !companyId,
    });

    const [applyToAll, setApplyToAll] = useState<boolean>(false);

    const onChangeApplyToAll = useCallback((e) => {
      setApplyToAll(e.target.checked);
    }, []);

    const companies = useMemo(() => {
      let result = data && data.company && data.company.consolidationCompanies;

      if (result && allowedCompanyIds && allowedCompanyIds.length) {
        const allowed = allowedCompanyIds.map((id) =>
          typeof parseStringFilterId(id) === 'string'
            ? parseInt(parseStringFilterId(id).split('_')[1])
            : parseStringFilterId(id)
        );

        result = result.filter((c) => allowed.includes(c.id));
      }
      return result;
    }, [allowedCompanyIds, data]);

    const [selected, setSelected] = useState<(string | number)[]>(
      defaultSelected || emptyArray
    );

    const items: ParentItem[] = useMemo(() => {
      if (companies) {
        return getItems(companies, selected, allowedCompanyIds);
      }
      return [];
    }, [allowedCompanyIds, companies, selected]);

    const partialSelected: string[] = useMemo(() => {
      const partial: string[] = [];

      if (allowChildAndParentSelection || !companies || !selected.length) {
        return partial;
      }

      const insertChildren = (filterPrefix, cid, filterItems, parentId) => {
        if (!filterItems) return;

        const children = filterItems.filter(
          (item) => item.parentId === parentId
        );

        if (children.length) {
          partial.push(
            ...children.map((c) => `${filterPrefix}_${cid}_${c.id}`)
          );

          children.forEach((c) => {
            insertChildren(filterPrefix, cid, filterItems, c.id);
          });
        }
      };

      selected.forEach((value) => {
        if (typeof parseStringFilterId(value) === 'string') {
          const [type, _companyId, id] = parseStringFilterId(value).split('_');
          if (type === 'class' || type === 'department') {
            const company = companies.find(
              (c) => c.id === parseInt(_companyId)
            );
            if (!company) return;
            const filterItems =
              type === 'class' ? company?.classes : company?.departments;
            insertChildren(type, _companyId, filterItems, parseInt(id));
          }
        }
      });

      return partial;
    }, [allowChildAndParentSelection, companies, selected]);

    const [filterItems, setFilterItems] = useState<ParentItem[] | null>(null);

    useEffect(() => {
      onChangeProp && onChangeProp(selected);
    }, [selected, onChangeProp]);

    const prevDefault = usePrevious(defaultSelected);
    useEffect(() => {
      if (defaultSelected !== prevDefault && defaultSelected) {
        setSelected(defaultSelected);
      }
    }, [defaultSelected, items, prevDefault, setSelected]);

    const handleClose = useCallback(() => {
      setApplyToAll(false);
      setSearchValue('');
      setFilterItems(null);
      onClose && onClose();
    }, [onClose]);

    const buttons = useMemo(() => {
      let columnSubtitle = '';
      if (selected.length && items.length) {
        let firstItem;
        for (let i = 0; i < items.length; i++) {
          if (items[i].value === selected[0]) {
            firstItem = items[i];
            break;
          }
          const subItems = items[i].classesOrDepartments || [];
          for (let j = 0; j < subItems.length || 0; j++) {
            if (subItems[j].value === selected[0]) {
              firstItem = subItems[j];
              break;
            }
          }
          if (firstItem) {
            break;
          }
        }

        // const firstItem = items.find((item) => `${item.value}` === selected[0]);
        const subtitle = firstItem
          ? firstItem.abbreviation || firstItem.label
          : '';
        columnSubtitle = `${subtitle}${selected.length > 1 ? '...' : ''}`;
      }

      return [
        {
          text: 'Cancel',
          color: 'default',
          outline: true,
          onClick: handleClose,
        },
        {
          text: 'Save',
          color: 'success',
          onClick: () => {
            onSave &&
              onSave(
                selected && selected.length ? selected : null,
                applyToAll,
                columnSubtitle
              );
            handleClose();
          },
        },
      ];
    }, [selected, handleClose, items, onSave, applyToAll]);

    const onChangeSearch = useCallback(
      (e) => {
        const searchTerm = e.target.value.toLowerCase();

        setSearchValue(searchTerm);

        if (searchTerm === '') {
          setFilterItems(null);
          return;
        }

        const filteredItems = items
          .filter((item) => {
            const parentMatch = item.label.toLowerCase().includes(searchTerm);

            if (parentMatch) return true;

            if (item.classesOrDepartments) {
              return item.classesOrDepartments.some((child) =>
                child.label.toLowerCase().includes(searchTerm)
              );
            }

            return false;
          })
          .map((item) => {
            if (item.classesOrDepartments) {
              return {
                ...item,
                classesOrDepartments: item.classesOrDepartments.filter(
                  (child) => child.label.toLowerCase().includes(searchTerm)
                ),
              };
            }
            return item;
          });

        setFilterItems(filteredItems);
      },
      [items]
    );

    const handleSelect = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();
        const { checked, value } = e.target;
        if (checked) {
          setSelected((prev) => {
            const newValue = [...prev, value];

            if (allowChildAndParentSelection) {
              return newValue;
            }

            const [filterType, _companyId, filterId] = value.split('_');

            const filterKey =
              filterType === 'class' ? 'classes' : 'departments';

            const company = companies.find(
              (c) => c.id === parseInt(_companyId)
            );

            if (!company) return newValue;

            const itemsForCompany = company[filterKey];

            if (!itemsForCompany) return newValue;

            const filter = itemsForCompany.find(
              (item) => item.id === parseInt(filterId)
            );

            if (!filter) return newValue;

            const removeChildren = (parentId) => {
              const children = itemsForCompany.filter(
                (item) => item.parentId === parentId
              );

              children.forEach((childItem) => {
                const childIndex = newValue.indexOf(
                  `${filterType}_${_companyId}_${childItem.id}`
                );

                if (childIndex > -1) {
                  newValue.splice(childIndex, 1);
                }
              });

              children.forEach((child) => removeChildren(child.id));
            };

            removeChildren(filter.id);

            return newValue;
          });
        } else {
          setSelected((prev) => prev.filter((item) => item !== value));
        }
      },
      [allowChildAndParentSelection, companies]
    );

    const onSelectAll = useCallback(
      (e) => {
        if (e.target.checked) {
          const newSelected = (filterItems || items).map((item) => item.value);
          setSelected(newSelected);
        } else {
          setSelected([]);
        }
      },
      [filterItems, items]
    );

    const filterBox = (
      <FilterBox
        items={filterItems || items}
        selected={selected}
        partialSelected={partialSelected}
        onChange={handleSelect}
        search={searchValue}
        onChangeSearch={onChangeSearch}
        isConsolidation
        onSelectAll={onSelectAll}
      />
    );

    if (embed) {
      return filterBox;
    }

    return (
      <Modal
        title="Company Filter"
        show={show}
        onClose={handleClose}
        buttons={buttons}
      >
        {loading ? (
          <Spinner />
        ) : (
          <>
            {filterBox}
            {!!showApplyToAll && (
              <Checkbox
                noMargin
                checked={applyToAll}
                onChange={onChangeApplyToAll}
              >
                Apply to all columns
              </Checkbox>
            )}
          </>
        )}
      </Modal>
    );
  });

export default ConsolidationFilter;
