import React, { Fragment } from 'react';
import { TableState, useSortBy, useTable, useExpanded } from 'react-table';
import { Thead, Table, Th, Tbody, Td } from './styledComponents';
import getDimension from 'utils/getDimension';
import {
  CustomColumn,
  ExpandedContentProps,
  TableOptions,
} from './table.types';

interface Props<T extends object> {
  columns: CustomColumn<T>[];
  data: T[];
  emptyText?: string;
  ExpandedContent?: React.FC<ExpandedContentProps<T>>;
  options?: TableOptions<T>;
}

const StandardTable = <T extends object>({
  columns,
  data,
  emptyText = 'No data',
  ExpandedContent,
  options = {
    sorting: {
      enabled: false,
      defaultSortBy: [],
    },
  },
}: Props<T>): React.ReactElement => {
  const { sorting, expanding, paddingBottom } = options;
  const hooks: any[] = [useExpanded];
  const initialState: Partial<TableState<T>> = {};

  if (sorting?.enabled) {
    hooks.unshift(useSortBy);
  }

  if (sorting?.defaultSortBy) {
    initialState.sortBy = sorting.defaultSortBy;
  }

  const { getTableProps, getTableBodyProps, rows, prepareRow, headerGroups } =
    useTable<T>(
      {
        columns,
        data,
        initialState,
      },
      ...hooks
    );

  return (
    <Table {...getTableProps()} paddingBottom={paddingBottom}>
      <Thead>
        {headerGroups.map((headerGroup, i) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={i}>
            {headerGroup.headers.map((column, j) => {
              const customColumn = column as CustomColumn<T>;
              const sortByToggleProps = sorting?.enabled
                ? column.getSortByToggleProps()
                : {};

              const handleSort = (
                e: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>
              ) => {
                if (sorting?.enabled && sorting?.onSort) {
                  sorting.onSort(
                    column.id,
                    column.isSortedDesc === undefined
                      ? false
                      : !column.isSortedDesc
                      ? true
                      : undefined
                  );
                  if (sortByToggleProps.onClick) {
                    sortByToggleProps.onClick(e);
                  }
                }
              };

              return (
                <Th
                  {...column.getHeaderProps(
                    sorting?.enabled ? sortByToggleProps : undefined
                  )}
                  key={j}
                  style={{
                    minWidth: getDimension(customColumn.minWidth),
                    width: getDimension(customColumn.width),
                    maxWidth: getDimension(customColumn.maxWidth),
                    textAlign: customColumn.align,
                  }}
                  isSorted={column.isSorted}
                  onClick={handleSort}
                >
                  {column.render('Header')}
                  {sorting?.enabled && column.canSort ? (
                    column.isSorted ? (
                      column.isSortedDesc ? (
                        <i className={'mdi-set mdi-chevron-down'} />
                      ) : (
                        <i className={'mdi-set mdi-chevron-up'} />
                      )
                    ) : sorting?.enabled ? (
                      <i className={'mdi-set mdi-minus'} />
                    ) : null
                  ) : null}
                </Th>
              );
            })}
          </tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <Fragment key={i}>
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, j) => {
                  const { column } = cell;
                  const customColumn = column as CustomColumn<T>;
                  const expandingProps = expanding?.enabled
                    ? {
                        ...row.getToggleRowExpandedProps(),
                      }
                    : {};
                  return (
                    <Td
                      {...cell.getCellProps({
                        style: {
                          minWidth: getDimension(customColumn.minWidth),
                          width: getDimension(customColumn.width),
                          maxWidth: getDimension(customColumn.maxWidth),
                          textAlign: customColumn.align,
                        },
                      })}
                      {...expandingProps}
                      key={j}
                    >
                      {cell.render('Cell')}
                    </Td>
                  );
                })}
              </tr>
              {expanding?.enabled && row.isExpanded && ExpandedContent && (
                <tr className="expanded-row">
                  {expanding?.startCol && <td colSpan={expanding.startCol} />}
                  <td colSpan={columns.length - (expanding?.startCol || 0)}>
                    <ExpandedContent row={row.original} />
                  </td>
                </tr>
              )}
            </Fragment>
          );
        })}
        {paddingBottom && (
          <tr>
            <Td colSpan={columns.length} style={{ height: paddingBottom }} />
          </tr>
        )}
      </Tbody>
      {!rows.length && (
        <tfoot>
          <tr>
            <Td
              colSpan={columns.length}
              style={{
                textAlign: 'center',
                fontSize: '1rem',
                paddingTop: '2rem',
              }}
            >
              {emptyText}
            </Td>
          </tr>
        </tfoot>
      )}
    </Table>
  );
};

export default StandardTable;
