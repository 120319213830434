/**
 *
 * ItemTree
 *
 */
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import TreeRow from './TreeRow';
import { MiddleContainer } from 'components/ItemSelectFlowStructure';
import SearchBar from 'components/SearchBar';
import scrollbar from 'css/scrollbar';
import useDebounce from 'hooks/useDebounce';
import {
  buildItemList,
  filteredOnSearch,
  getAllValues,
  getDefaultExpanded,
} from './treeUtils';

const ListContainer = styled.div`
  list-style: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;

  & > div {
    ${scrollbar()}
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SearchBarContainer = styled.div`
  margin: 0 -10px;
  padding: 1rem 10px;
  /* position: sticky; */
  top: 0;
  z-index: 5;
  background-color: #ffffff;
`;

const Button = styled.button`
  color: ${(props) => props.theme.colors.link};
  font-size: 13px;
  vertical-align: middle;
`;

const ItemTree = (props) => {
  const {
    items,
    single,
    value: selected,
    onChange,
    allowSelectAll,
    showExpandCollapseAll,
    hasConsolidations,
    onChangeMultiple,
    defaultPath,
    disableTotals,
  } = props;

  const [expanded, setExpanded] = useState(
    getDefaultExpanded(single, defaultPath)
  );
  const [searchTerm, setSearchTerm] = useState('');

  const onSearchChange = useCallback(
    (value) => {
      setSearchTerm(value);
    },
    [setSearchTerm]
  );

  const handleCollapse = useCallback(
    (value) => {
      setExpanded(expanded.filter((v) => v !== value));
    },
    [expanded]
  );

  const handleExpand = useCallback(
    (value) => {
      setExpanded(expanded.concat(value));
    },
    [expanded]
  );

  const onToggle = useCallback(
    (value) => {
      if (expanded.includes(value)) {
        handleCollapse(value);
      } else {
        handleExpand(value);
      }
    },
    [expanded, handleCollapse, handleExpand]
  );

  const handleExpandAll = useCallback(() => {
    setExpanded(getAllValues(items));
  }, [items]);

  const handleCollapseAll = useCallback(() => {
    setExpanded([]);
  }, []);

  const onSelectAllForItem = useCallback(
    (item) => {
      onChangeMultiple(getAllValues(item.children), true);
    },
    [onChangeMultiple]
  );

  const onDeselectAllForItem = useCallback(
    (item) => {
      onChangeMultiple(getAllValues(item.children), false);
    },
    [onChangeMultiple]
  );

  const handleSelectAll = useCallback(() => {
    const allValues = getAllValues(items);
    onChangeMultiple(allValues, true);
  }, [items, onChangeMultiple]);

  const handleDeselectAll = useCallback(() => {
    onChangeMultiple(getAllValues(items), false);
  }, [items, onChangeMultiple]);

  const debouncedSearchTerm = useDebounce(searchTerm, 200);

  const filteredItems = useMemo(() => {
    return filteredOnSearch(items, debouncedSearchTerm);
  }, [items, debouncedSearchTerm]);

  const itemData = useMemo(() => {
    return buildItemList(
      filteredItems,
      single,
      disableTotals,
      expanded,
      selected,
      onToggle,
      onChange,
      onSelectAllForItem,
      onDeselectAllForItem
    );
  }, [
    disableTotals,
    expanded,
    filteredItems,
    onChange,
    onDeselectAllForItem,
    onSelectAllForItem,
    onToggle,
    selected,
    single,
  ]);

  return (
    <MiddleContainer hasConsolidations={hasConsolidations} paddingTop={0}>
      <SearchBarContainer>
        <SearchBar
          margin={false}
          onChange={onSearchChange}
          style={{ width: '100%' }}
        />
      </SearchBarContainer>
      {showExpandCollapseAll && searchTerm === '' && (
        <Actions>
          <Left>
            <Button onClick={handleExpandAll}>Expand All</Button>
            <Button onClick={handleCollapseAll}>Collapse All</Button>
          </Left>
          {allowSelectAll && (
            <Right>
              <Button onClick={handleSelectAll}>Select All</Button>
              <Button onClick={handleDeselectAll}>Deselect All</Button>
            </Right>
          )}
        </Actions>
      )}
      <AutoSizer>
        {({ width, height }) => (
          <ListContainer>
            <List
              width={width}
              height={height - 80}
              itemSize={28}
              itemCount={itemData.rows.length}
              itemData={itemData}
            >
              {TreeRow}
            </List>
          </ListContainer>
        )}
      </AutoSizer>
    </MiddleContainer>
  );
};

export default ItemTree;
