/**
 *
 * CompanyMessage
 *
 */
import { useCallback } from 'react';
import gql from 'graphql-tag';
import useCompanyId from 'hooks/useCompanyId';
import { useSubscription } from '@apollo/client';
import { showInfo } from 'utils/popups';

const SUB = gql`
  subscription CompanyMessage($companyId: Int!) {
    companyMessage(companyId: $companyId) {
      message
    }
  }
`;

const CompanyMessage = () => {
  const companyId = useCompanyId();

  const onSubscriptionData = useCallback((options) => {
    const message = options.data.data.companyMessage.message;

    showInfo({ text: message });
  }, []);

  useSubscription(SUB, {
    variables: {
      companyId,
    },
    skip: !companyId,
    onData: onSubscriptionData,
  });

  return null;
};

export default CompanyMessage;
