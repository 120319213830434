import usePrevious from 'hooks/usePrevious';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { StyleWrapper } from './styledComponents';

export interface DatePickerFieldProps {
  type?: string;
  showMonth?: boolean;
  initialDate?: Moment;
  minDate?: Moment;
  maxDate?: Moment;
  highlightRange?: boolean;
  onChange?: (newDate: Moment) => void;
  value?: Moment;
  selectorId?: string;
}

const DatePickerField: React.FC<DatePickerFieldProps> = ({
  type = 'default',
  showMonth = true,
  initialDate = moment.utc().startOf('day'),
  minDate: _minDate,
  maxDate: _maxDate,
  highlightRange,
  onChange,
  value,
  selectorId,
}) => {
  const [date, setDate] = useState<Moment>(initialDate);
  const previousShowMonth = usePrevious(showMonth);
  const previousInitialDate = usePrevious(initialDate);

  useEffect(() => {
    if (value) {
      setDate(value);
    }
  }, [value]);

  const minDate = _minDate || moment.utc().startOf('year').subtract(5, 'years');
  const maxDate = _maxDate || moment.utc().endOf('year').add(10, 'years');

  const getHighlightedDays = (startDate: Moment, endDate: Moment): Date[] => {
    const dates: Date[] = [];
    let currentDate = startDate.toDate();
    while (currentDate <= endDate.toDate()) {
      dates.push(new Date(currentDate));
      const newDate = new Date(currentDate.valueOf());
      newDate.setDate(newDate.getDate() + 1);
      currentDate = newDate;
    }

    return dates;
  };

  const handleDateChange = useCallback(
    (newDate: Moment) => {
      setDate(newDate);
      onChange && onChange(newDate);
    },
    [onChange]
  );

  useEffect(() => {
    if (!initialDate.isSame(previousInitialDate)) {
      setDate(initialDate);
    }
  }, [initialDate, previousInitialDate]);

  useEffect(() => {
    if (showMonth !== previousShowMonth && previousShowMonth !== undefined) {
      let newDate: React.SetStateAction<moment.Moment>;
      if (showMonth) {
        newDate = date.utc().startOf('month');
        handleDateChange(newDate);
        setDate(newDate);
      } else {
        handleDateChange(date.utc());
        setDate(date);
      }
    }
  }, [date, handleDateChange, previousShowMonth, showMonth, type]);

  return (
    <StyleWrapper
      data-testid={`datepicker${selectorId ? `-${selectorId}` : ''}`}
    >
      <DatePicker
        inline
        selected={new Date(date.format('YYYY-MM-DDTHH:mm:ss'))}
        minDate={minDate && new Date(minDate.format('YYYY-MM-DDTHH:mm:ss'))}
        maxDate={maxDate && new Date(maxDate.format('YYYY-MM-DDTHH:mm:ss'))}
        dateFormatCalendar={'MMM yyyy'}
        onChange={(newDate: Date) => {
          handleDateChange(
            moment.utc([
              newDate.getFullYear(),
              newDate.getMonth(),
              newDate.getDate(),
            ])
          );
        }}
        highlightDates={
          highlightRange
            ? getHighlightedDays(minDate || date, maxDate || date)
            : []
        }
        shouldCloseOnSelect={false}
        showMonthYearPicker={showMonth}
        disabledKeyboardNavigation
        startDate={new Date(date.format('YYYY-MM-DDTHH:mm:ss'))}
        renderCustomHeader={({
          date: _date,
          decreaseYear,
          increaseYear,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="react-datepicker__header">
            <button
              type="button"
              className="react-datepicker__navigation react-datepicker__navigation--previous"
              onClick={showMonth ? decreaseYear : decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              <span
                className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                data-testid="datepicker-prev"
              >
                &#x3c;
              </span>
            </button>
            <span className="react-datepicker__current-month">
              {/* {_date.toLocaleString('default', { month: 'long' })}{' '} */}
              {showMonth
                ? _date.getFullYear()
                : _date.toLocaleString('default', { month: 'short' }) +
                  ' ' +
                  _date.getFullYear()}
            </span>
            <button
              type="button"
              className="react-datepicker__navigation react-datepicker__navigation--next"
              onClick={showMonth ? increaseYear : increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              <span
                className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                data-testid="datepicker-next"
              >
                &#x3e;
              </span>
            </button>
          </div>
        )}
      />
    </StyleWrapper>
  );
};

export default DatePickerField;
