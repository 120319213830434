import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { GET_TWO_FACTOR, SETUP_TOTP, VERIFY } from '../queriesAndMutations';
import Modal from 'components/Modal';
import {
  CodeContainer,
  CodeInput,
  Container,
  Error,
  Header,
  P,
  QRSubtitle,
  Verifying,
} from './styledComponents';
import LoadingIndicator from 'components/LoadingIndicator';
import { Button } from '../styledComponents';
import { CurrentUser } from 'interfaces/user';

interface Props {
  show: boolean;
  triggerToast: (message: string) => void;
  onClose: () => void;
}

const ApplicationSetupModal = ({
  show,
  triggerToast,
  onClose,
}: Props): React.ReactElement => {
  const [qrCode, setQrCode] = useState<string | null>(null);
  const [manualKey, setManualKey] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [code, setCode] = useState<string>('');
  const [previousCode, setPreviousCode] = useState<string | null>(null);
  const [setup, { loading: settingUp }] = useMutation(SETUP_TOTP);
  const [verify, { loading: verifying }] = useMutation(VERIFY);

  useEffect(() => {
    if (show && !qrCode) {
      setup().then((res) => {
        if (res.data.setupTwoFactorTotpAuth.success) {
          setQrCode(res.data.setupTwoFactorTotpAuth.qrCode);
          setManualKey(res.data.setupTwoFactorTotpAuth.manualKey);
        }
      });
    }
  }, [qrCode, setup, show]);

  const handleClose = useCallback(() => {
    if (verifying) return;

    setQrCode(null);
    setManualKey(null);
    setCode('');
    setError(null);
    setPreviousCode(null);
    onClose();
  }, [verifying, onClose]);

  const handleVerify = useCallback(() => {
    if (error && previousCode === code) {
      setError('Please enter a new code');
      return;
    }

    setPreviousCode(code);

    verify({
      variables: { code, method: 'TOTP' },
      update: (cache, res) => {
        const getTwoFactorCache = cache.readQuery<{
          currentUser: CurrentUser;
        }>({ query: GET_TWO_FACTOR });

        if (!getTwoFactorCache) return;

        const { currentUser } = getTwoFactorCache;

        if (!res.data.verifyTwoFactorAuth.success) {
          setError(res.data.verifyTwoFactorAuth.error || 'Invalid code');
          return;
        }

        cache.writeQuery({
          query: GET_TWO_FACTOR,
          data: {
            currentUser: {
              ...currentUser,
              hasTwoFactorAuth:
                currentUser.hasTwoFactorAuth ||
                res.data.verifyTwoFactorAuth.success,
              verifiedTotp: res.data.verifyTwoFactorAuth.success,
            },
          },
        });
      },
    }).then((res) => {
      if (res.data.verifyTwoFactorAuth.success) {
        triggerToast('Two-factor authentication enabled');
        handleClose();
      }
    });
  }, [error, previousCode, code, verify, triggerToast, handleClose]);

  return (
    <Modal
      show={show}
      title={'Enable 2FA'}
      onClose={handleClose}
      preventClickOut={verifying}
    >
      <Container>
        {settingUp && !qrCode ? (
          <LoadingIndicator independent />
        ) : (
          <>
            {verifying && (
              <Verifying>
                <LoadingIndicator independent />
                <span>Verifying...</span>
              </Verifying>
            )}
            <Header>Download An Authenticator App</Header>
            <P>
              Download or install{' '}
              <a
                href="https://authy.com/download/"
                target="_blank"
                rel="noreferrer"
              >
                Authy
              </a>{' '}
              or{' '}
              <a
                href="https://support.google.com/accounts/answer/1066447?hl=en"
                target="_blank"
                rel="noreferrer"
              >
                Google Authenticator
              </a>{' '}
              or your preferred app for your phone or tablet.
            </P>
            <Header>Scan the QR Code</Header>
            <P>
              Open the authentication app and scan the QR code using your
              phone&apos;s camera.
            </P>
            <img src={qrCode || ''} alt="QR Code" />
            <QRSubtitle>
              <span>2FA Manual Key</span>
              <br />
              {manualKey}
            </QRSubtitle>
            <Header>Log in with your code</Header>
            <P>Enter the 6-digit code generated by your authentication app.</P>
            <CodeContainer>
              <CodeInput
                value={code}
                onChange={(e) => {
                  setCode(e.target.value);
                  setError(null);
                }}
                onKeyPress={(e) => e.key === 'Enter' && handleVerify()}
                error={!!error}
              />
              <Button onClick={handleVerify}>Verify Code</Button>
            </CodeContainer>
            {error && <Error>{error}</Error>}
          </>
        )}
      </Container>
    </Modal>
  );
};

export default ApplicationSetupModal;
