import { API_URL } from 'environment';
import { captureException } from './sentry';
import S3Upload from 'react-s3-uploader/s3upload';
import { ReactS3UploaderProps, S3Response } from 'react-s3-uploader';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { imagePreprocessor } from './imagePreprocessor';

interface Props {
  file: File;
  onFinish: (result: S3Response, file: File, hmac?: string) => any;
  onError?: (message: string) => any;
  maxFileSize: number;
}

const uploadImageToS3 = ({
  file,
  onFinish,
  onError,
  maxFileSize,
}: Props): void => {
  const preprocess = (
    fileToPreprocess: File,
    next: (fileToPass: File) => void
  ) =>
    imagePreprocessor({ file: fileToPreprocess, maxFileSize, next, onError });

  const _onError = (message: string) => {
    captureException(message, true);
    onError && onError(message);
  };

  let hmac: string;

  const options: ReactS3UploaderProps = {
    files: [file],
    signingUrl: `${API_URL}/s3/sign`,
    signingUrlMethod: 'GET',
    accept: 'image/*',
    signingUrlWithCredentials: true,
    preprocess,
    onFinishS3Put: !!onFinish && ((result) => onFinish(result, file, hmac)),
    onError: _onError,
    onSignedUrl: (response) => {
      hmac = (response as any).hmac as string;
    },
    uploadRequestHeaders: {
      'x-amz-server-side-encryption': 'AES256',
    },
  };

  // eslint-disable-next-line no-new
  new S3Upload(options);
};

export default uploadImageToS3;
