// contexts/WhitelabelContext.tsx

import React, {
  createContext,
  useCallback,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import { getWhitelabel } from 'hooks/useWhitelabel';

interface WhitelabelContextProps {
  setWhitelabelTitle: () => void;
}

const defaultContextValue: WhitelabelContextProps = {
  setWhitelabelTitle: () => {},
};

export const WhitelabelContext =
  createContext<WhitelabelContextProps>(defaultContextValue);

interface WhitelabelProviderProps {
  children: ReactNode;
}

export const WhitelabelProvider: React.FC<WhitelabelProviderProps> = ({
  children,
}) => {
  const [titleValue, setTitleValue] = useState<string>('');
  const [defaultTitle, setDefaultTitle] = useState<string>(document.title);

  useEffect(() => {
    getWhitelabel().then((response) => {
      setTitleValue(response.title);
    });
  }, []);

  const setWhitelabelTitle = useCallback(() => {
    if (titleValue) {
      document.title = titleValue;
    } else {
      document.title = defaultTitle;
    }
  }, [titleValue, defaultTitle]);

  useEffect(() => {
    setDefaultTitle(document.title);
  }, []);

  return (
    <WhitelabelContext.Provider value={{ setWhitelabelTitle }}>
      {children}
    </WhitelabelContext.Provider>
  );
};
