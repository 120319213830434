const { ranges, comparisons } = require('../../persistence/constants.json');
const moment = require('moment');

module.exports.ranges = ranges;
module.exports.quartersByMonth = {
  LAST_QUARTER_FIRST_MONTH: 1,
  LAST_QUARTER_SECOND_MONTH: 2,
  LAST_QUARTER_THIRD_MONTH: 3,
};

const getStartOfFiscalYear = (module.exports.getStartOfFiscalYear = function (
  bookMonth,
  fiscalYearMonth
) {
  if (bookMonth.month() >= fiscalYearMonth) {
    return bookMonth.clone().month(fiscalYearMonth);
  } else {
    return bookMonth.clone().month(fiscalYearMonth).subtract(1, 'year');
  }
});

const getQuarter = (monthIndex) => Math.floor(monthIndex / 3);

const getFiscalQuarterStart = (module.exports.getFiscalQuarterStart = function (
  now,
  fiscalYearMonth, // Months are 0 indexed
  rangeOffset, // difference in between the current month and the chosen month when metric was calculated
  past // ranges that reference a previous quarter (last quarter minus one, two, etc.)see lines 83-91*
) {
  if (rangeOffset % 3 !== 0) {
    throw new Error('rangeOffset must be a multiple of 3');
  }

  if (now.month() >= fiscalYearMonth) {
    const diff = now.month() - fiscalYearMonth;
    const quarter = getQuarter(diff);

    return now
      .clone()
      .month(fiscalYearMonth)
      .add(3 * quarter, 'months')
      .add(3 * -past, 'months')
      .add(rangeOffset, 'months');
  } else {
    const fiscalYearStart = now
      .clone()
      .month(fiscalYearMonth)
      .subtract(1, 'year');

    const diff = now.diff(fiscalYearStart, 'months');
    const quarter = getQuarter(diff);

    return now
      .clone()
      .month(fiscalYearMonth)
      .subtract(1, 'year')
      .add(3 * quarter, 'months')
      .add(3 * -past, 'months')
      .add(rangeOffset, 'months');
  }
});

function getFiscalQuarterEnd(now, fiscalYearMonth, rangeOffset, past) {
  return getFiscalQuarterStart(now, fiscalYearMonth, rangeOffset, past).add(
    2,
    'months'
  );
}

module.exports.getStartDate = function (
  range,
  customStartDate,
  now,
  _rangeOffset, // difference in between the current month and the chosen month when metric was calculated
  fiscalYearMonth = 0 // Months are 0 indexed
) {
  const isCustomLive =
    !isNaN(_rangeOffset) &&
    _rangeOffset !== null &&
    [ranges.CUSTOM, 'CUSTOM', ranges.CUSTOM_QUARTER, 'CUSTOM_QUARTER'].includes(
      range
    );
  const rangeOffset = _rangeOffset || 0;

  const bookMonth = now ? now.clone().subtract(1, 'month') : moment.utc();

  const pastFromRange = {
    LAST_FISCAL_QUARTER: 0,
    LAST_FISCAL_QUARTER_BACK_ONE: 1,
    LAST_FISCAL_QUARTER_BACK_TWO: 2,
    LAST_FISCAL_QUARTER_BACK_THREE: 3,
    LAST_FISCAL_QUARTER_BACK_FOUR: 4,
  };

  const past = pastFromRange[range] || 0;

  switch (range) {
    case ranges.CURRENT_MONTH:
    case 'CURRENT_MONTH':
      return now.clone().startOf('month').add(rangeOffset, 'months');
    case ranges.LAST_MONTH:
    case 'LAST_MONTH':
      return now
        .clone()
        .startOf('month')
        .subtract(1, 'month')
        .add(rangeOffset, 'months');
    case ranges.LAST_THREE_MONTHS:
    case 'LAST_THREE_MONTHS':
      return now
        .clone()
        .startOf('month')
        .subtract(2, 'months')
        .add(rangeOffset, 'months');
    case ranges.LAST_THREE_FULL_MONTHS:
    case 'LAST_THREE_FULL_MONTHS':
      return now
        .clone()
        .startOf('month')
        .subtract(3, 'months')
        .add(rangeOffset, 'months');
    case ranges.LAST_SIX_MONTHS:
    case 'LAST_SIX_MONTHS':
      return now
        .clone()
        .startOf('month')
        .subtract(5, 'months')
        .add(rangeOffset, 'months');
    case ranges.LAST_SIX_FULL_MONTHS:
    case 'LAST_SIX_FULL_MONTHS':
      return now
        .clone()
        .startOf('month')
        .subtract(6, 'months')
        .add(rangeOffset, 'months');
    case ranges.LAST_TWELVE_MONTHS:
    case 'LAST_TWELVE_MONTHS':
      return now
        .clone()
        .startOf('month')
        .subtract(11, 'months')
        .add(rangeOffset, 'months');
    case ranges.LAST_TWELVE_FULL_MONTHS:
    case 'LAST_TWELVE_FULL_MONTHS':
      return now
        .clone()
        .startOf('month')
        .subtract(12, 'months')
        .add(rangeOffset, 'months');
    case ranges.YEAR_TO_DATE:
    case 'YEAR_TO_DATE':
      return now.clone().startOf('year').add(rangeOffset, 'months');
    case ranges.YEAR_TO_DATE_BACK_ONE_YEAR:
    case 'YEAR_TO_DATE_BACK_ONE_YEAR':
      return now
        .clone()
        .startOf('year')
        .subtract(1, 'year')
        .add(rangeOffset, 'months');
    case ranges.YEAR_TO_DATE_BACK_TWO_YEARS:
    case 'YEAR_TO_DATE_BACK_TWO_YEARS':
      return now
        .clone()
        .startOf('year')
        .subtract(2, 'year')
        .add(rangeOffset, 'months');
    case ranges.YEAR_TO_LAST_MONTH:
    case 'YEAR_TO_LAST_MONTH':
      return now
        .clone()
        .startOf('month')
        .subtract(1, 'month')
        .startOf('year')
        .add(rangeOffset, 'months');
    case ranges.YEAR_TO_LAST_MONTH_BACK_ONE_YEAR:
    case 'YEAR_TO_LAST_MONTH_BACK_ONE_YEAR':
      return now
        .clone()
        .startOf('month')
        .subtract(1, 'month')
        .startOf('year')
        .subtract(1, 'year')
        .add(rangeOffset, 'months');
    case ranges.YEAR_TO_LAST_MONTH_BACK_TWO_YEARS:
    case 'YEAR_TO_LAST_MONTH_BACK_TWO_YEARS':
      return now
        .clone()
        .startOf('month')
        .subtract(1, 'month')
        .startOf('year')
        .subtract(2, 'years')
        .add(rangeOffset, 'months');
    case ranges.LAST_THREE_FORWARD_THREE:
    case 'LAST_THREE_FORWARD_THREE':
      return now
        .clone()
        .startOf('month')
        .subtract(3, 'months')
        .add(rangeOffset, 'months');
    case ranges.LAST_SIX_FORWARD_SIX:
    case 'LAST_SIX_FORWARD_SIX':
      return now
        .clone()
        .startOf('month')
        .subtract(6, 'months')
        .add(rangeOffset, 'months');
    case ranges.CURRENT_QUARTER:
    case 'CURRENT_QUARTER':
      return now.clone().startOf('quarter').add(rangeOffset, 'months');
    case ranges.CURRENT_FISCAL_QUARTER:
    case 'CURRENT_FISCAL_QUARTER':
      return getFiscalQuarterStart(now, fiscalYearMonth, rangeOffset, 0);
    case ranges.CURRENT_FISCAL_QUARTER_FIRST_MONTH:
    case 'CURRENT_FISCAL_QUARTER_FIRST_MONTH':
      return getFiscalQuarterStart(now, fiscalYearMonth, rangeOffset, 0);
    case ranges.CURRENT_FISCAL_QUARTER_SECOND_MONTH:
    case 'CURRENT_FISCAL_QUARTER_SECOND_MONTH':
      return getFiscalQuarterStart(now, fiscalYearMonth, rangeOffset, 0).add(
        1,
        'month'
      );
    case ranges.CURRENT_FISCAL_QUARTER_THIRD_MONTH:
    case 'CURRENT_FISCAL_QUARTER_THIRD_MONTH':
      return getFiscalQuarterStart(now, fiscalYearMonth, rangeOffset, 0).add(
        2,
        'months'
      );
    case ranges.LAST_FISCAL_QUARTER:
    case 'LAST_FISCAL_QUARTER':
      return getFiscalQuarterStart(now, fiscalYearMonth, rangeOffset, 1);
    case ranges.LAST_FISCAL_QUARTER_BACK_ONE:
    case 'LAST_FISCAL_QUARTER_BACK_ONE':
      return getFiscalQuarterStart(now, fiscalYearMonth, rangeOffset, 2);
    case ranges.LAST_FISCAL_QUARTER_BACK_TWO:
    case 'LAST_FISCAL_QUARTER_BACK_TWO':
      return getFiscalQuarterStart(now, fiscalYearMonth, rangeOffset, 3);
    case ranges.LAST_FISCAL_QUARTER_BACK_THREE:
    case 'LAST_FISCAL_QUARTER_BACK_THREE':
    case ranges.LAST_FOUR_FISCAL_QUARTERS:
    case 'LAST_FOUR_FISCAL_QUARTERS':
      return getFiscalQuarterStart(now, fiscalYearMonth, rangeOffset, 4);
    case ranges.LAST_FISCAL_QUARTER_BACK_FOUR:
    case 'LAST_FISCAL_QUARTER_BACK_FOUR':
      return getFiscalQuarterStart(now, fiscalYearMonth, rangeOffset, 5);
    case ranges.NEXT_FISCAL_QUARTER:
    case 'NEXT_FISCAL_QUARTER':
      return getFiscalQuarterStart(bookMonth, fiscalYearMonth, rangeOffset, -1);
    case ranges.NEXT_FISCAL_QUARTER_FORWARD_ONE:
    case 'NEXT_FISCAL_QUARTER_FORWARD_ONE':
      return getFiscalQuarterStart(bookMonth, fiscalYearMonth, rangeOffset, -2);
    case ranges.NEXT_FISCAL_QUARTER_FORWARD_TWO:
    case 'NEXT_FISCAL_QUARTER_FORWARD_TWO':
      return getFiscalQuarterStart(bookMonth, fiscalYearMonth, rangeOffset, -3);
    case ranges.NEXT_FISCAL_QUARTER_FORWARD_THREE:
    case 'NEXT_FISCAL_QUARTER_FORWARD_THREE':
      return getFiscalQuarterStart(bookMonth, fiscalYearMonth, rangeOffset, -4);
    case ranges.LAST_QUARTER:
    case 'LAST_QUARTER':
    case ranges.LAST_QUARTER_FIRST_MONTH:
    case 'LAST_QUARTER_FIRST_MONTH':
      return now
        .clone()
        .startOf('quarter')
        .subtract(1, 'quarter')
        .add(rangeOffset, 'months');
    case ranges.LAST_QUARTER_SECOND_MONTH:
    case 'LAST_QUARTER_SECOND_MONTH':
      return now
        .clone()
        .startOf('quarter')
        .subtract(1, 'quarter')
        .add(1, 'months')
        .add(rangeOffset, 'months');
    case ranges.LAST_QUARTER_THIRD_MONTH:
    case 'LAST_QUARTER_THIRD_MONTH':
      return now
        .clone()
        .startOf('quarter')
        .subtract(1, 'quarter')
        .add(2, 'months')
        .add(rangeOffset, 'months');
    case ranges.CURRENT_QUARTER_FIRST_MONTH:
    case 'CURRENT_QUARTER_FIRST_MONTH':
      return now.clone().startOf('quarter').add(rangeOffset, 'months');
    case ranges.CURRENT_QUARTER_SECOND_MONTH:
    case 'CURRENT_QUARTER_SECOND_MONTH':
      return now
        .clone()
        .startOf('quarter')
        .add(1, 'months')
        .add(rangeOffset, 'months');
    case ranges.CURRENT_QUARTER_THIRD_MONTH:
    case 'CURRENT_QUARTER_THIRD_MONTH':
      return now
        .clone()
        .startOf('quarter')
        .add(2, 'months')
        .add(rangeOffset, 'months');
    case ranges.LAST_FISCAL_QUARTER_FIRST_MONTH:
    case 'LAST_FISCAL_QUARTER_FIRST_MONTH':
      return getFiscalQuarterStart(now, fiscalYearMonth, rangeOffset, past)
        .clone()
        .subtract(1, 'quarter');
    case ranges.LAST_FISCAL_QUARTER_SECOND_MONTH:
    case 'LAST_FISCAL_QUARTER_SECOND_MONTH':
      return getFiscalQuarterStart(now, fiscalYearMonth, rangeOffset, past)
        .clone()
        .subtract(1, 'quarter')
        .add(1, 'months');
    case ranges.LAST_FISCAL_QUARTER_THIRD_MONTH:
    case 'LAST_FISCAL_QUARTER_THIRD_MONTH':
      return getFiscalQuarterStart(now, fiscalYearMonth, rangeOffset, past)
        .clone()
        .subtract(1, 'quarter')
        .add(2, 'month');
    case ranges.LAST_QUARTER_BACK_ONE:
    case 'LAST_QUARTER_BACK_ONE':
      return now
        .clone()
        .startOf('quarter')
        .subtract(2, 'quarter')
        .add(rangeOffset, 'months');
    case ranges.LAST_QUARTER_BACK_TWO:
    case 'LAST_QUARTER_BACK_TWO':
      return now
        .clone()
        .startOf('quarter')
        .subtract(3, 'quarter')
        .add(rangeOffset, 'months');
    case ranges.LAST_QUARTER_BACK_THREE:
    case 'LAST_QUARTER_BACK_THREE':
    case ranges.LAST_FOUR_QUARTERS:
    case 'LAST_FOUR_QUARTERS':
      return now
        .clone()
        .startOf('quarter')
        .subtract(4, 'quarter')
        .add(rangeOffset, 'months');
    case ranges.LAST_QUARTER_BACK_FOUR:
    case 'LAST_QUARTER_BACK_FOUR':
      return now
        .clone()
        .startOf('quarter')
        .subtract(5, 'quarter')
        .add(rangeOffset, 'months');
    case ranges.QUARTER_TO_LAST_MONTH:
    case 'QUARTER_TO_LAST_MONTH':
      return bookMonth.clone().startOf('quarter').add(rangeOffset, 'months');
    case ranges.FISCAL_QUARTER_TO_LAST_MONTH:
    case 'FISCAL_QUARTER_TO_LAST_MONTH':
      return getFiscalQuarterStart(bookMonth, fiscalYearMonth, rangeOffset, 0);
    case ranges.CURRENT_YEAR:
    case 'CURRENT_YEAR':
      return bookMonth.clone().startOf('year').add(rangeOffset, 'months');
    case ranges.LAST_YEAR:
    case 'LAST_YEAR':
      return bookMonth
        .clone()
        .startOf('year')
        .subtract(1, 'year')
        .add(rangeOffset, 'months');
    case ranges.LAST_YEAR_BACK_ONE:
    case 'LAST_YEAR_BACK_ONE':
      return bookMonth
        .clone()
        .startOf('year')
        .subtract(2, 'year')
        .add(rangeOffset, 'months');
    case 'LAST_EIGHT_FISCAL_QUARTERS':
    case ranges.LAST_EIGHT_FISCAL_QUARTERS:
      return getFiscalQuarterStart(now, fiscalYearMonth, rangeOffset, 8);
    case ranges.LAST_EIGHT_QUARTERS:
    case 'LAST_EIGHT_QUARTERS':
      return now
        .clone()
        .startOf('quarter')
        .subtract(8, 'quarters')
        .add(rangeOffset, 'months');
    case ranges.CUSTOM:
    case 'CUSTOM':
    case ranges.FORECAST:
    case 'FORECAST':
      if (isCustomLive) {
        return now.clone().startOf('month').add(rangeOffset, 'months');
      }
      return customStartDate;
    case ranges.CUSTOM_QUARTER:
    case 'CUSTOM_QUARTER':
      if (isCustomLive) {
        const originalPresentDate = customStartDate
          .clone()
          .subtract(rangeOffset, 'months');
        const quartersPassed = Math.floor(
          now.diff(originalPresentDate, 'month') / 3
        );
        return customStartDate.clone().add(quartersPassed * 3, 'months');
      }
      return customStartDate;
    case ranges.NEXT_FISCAL_YEAR:
    case 'NEXT_FISCAL_YEAR':
      return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
        .add(rangeOffset, 'months')
        .add(12, 'months');
    case ranges.TWELVE_FISCAL_MONTHS:
    case 'TWELVE_FISCAL_MONTHS':
      return getStartOfFiscalYear(bookMonth, fiscalYearMonth).add(
        rangeOffset,
        'months'
      );
    case ranges.TWELVE_FISCAL_MONTHS_LAST_YEAR:
    case 'TWELVE_FISCAL_MONTHS_LAST_YEAR':
      return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
        .subtract(1, 'year')
        .add(rangeOffset, 'months');
    case ranges.TWELVE_FISCAL_MONTHS_LAST_YEAR_BACK_TWO:
    case 'TWELVE_FISCAL_MONTHS_LAST_YEAR_BACK_TWO':
      return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
        .subtract(2, 'year')
        .add(rangeOffset, 'months');
    case ranges.NEXT_CALENDAR_YEAR:
    case 'NEXT_CALENDAR_YEAR':
      return bookMonth
        .clone()
        .startOf('year')
        .add(rangeOffset, 'months')
        .add(12, 'months');
    case ranges.TWELVE_CALENDAR_MONTHS:
    case 'TWELVE_CALENDAR_MONTHS':
      return bookMonth.clone().startOf('year').add(rangeOffset, 'months');
    case ranges.TWELVE_CALENDAR_MONTHS_LAST_YEAR:
    case 'TWELVE_CALENDAR_MONTHS_LAST_YEAR':
      return bookMonth
        .clone()
        .startOf('year')
        .subtract(1, 'year')
        .add(rangeOffset, 'months');
    case ranges.TWELVE_CALENDAR_MONTHS_LAST_YEAR_BACK_TWO:
    case 'TWELVE_CALENDAR_MONTHS_LAST_YEAR_BACK_TWO':
      return bookMonth
        .clone()
        .startOf('year')
        .subtract(2, 'year')
        .add(rangeOffset, 'months');
    case ranges.FINAL_FISCAL_MONTH:
    case 'FINAL_FISCAL_MONTH': {
      if (bookMonth.month() >= fiscalYearMonth) {
        return bookMonth
          .clone()
          .month(fiscalYearMonth)
          .add(11, 'months')
          .add(rangeOffset, 'months');
      } else {
        return bookMonth
          .clone()
          .month(fiscalYearMonth)
          .subtract(1, 'month')
          .add(rangeOffset, 'months');
      }
    }
    case ranges.NEXT_YEAR_FINAL_FISCAL_MONTH:
    case 'NEXT_YEAR_FINAL_FISCAL_MONTH': {
      if (bookMonth.month() >= fiscalYearMonth) {
        return bookMonth
          .clone()
          .month(fiscalYearMonth)
          .add(11, 'months')
          .add(rangeOffset, 'months')
          .add(1, 'years');
      } else {
        return bookMonth
          .clone()
          .month(fiscalYearMonth)
          .subtract(1, 'month')
          .add(rangeOffset, 'months')
          .add(1, 'years');
      }
    }
    case ranges.NEXT_YEAR_PLUS_ONE_FINAL_FISCAL_MONTH:
    case 'NEXT_YEAR_PLUS_ONE_FINAL_FISCAL_MONTH': {
      if (bookMonth.month() >= fiscalYearMonth) {
        return bookMonth
          .clone()
          .month(fiscalYearMonth)
          .add(11, 'months')
          .add(rangeOffset, 'months')
          .add(2, 'years');
      } else {
        return bookMonth
          .clone()
          .month(fiscalYearMonth)
          .subtract(1, 'month')
          .add(rangeOffset, 'months')
          .add(2, 'years');
      }
    }
    case ranges.NEXT_YEAR_PLUS_TWO_FINAL_FISCAL_MONTH:
    case 'NEXT_YEAR_PLUS_TWO_FINAL_FISCAL_MONTH': {
      if (bookMonth.month() >= fiscalYearMonth) {
        return bookMonth
          .clone()
          .month(fiscalYearMonth)
          .add(11, 'months')
          .add(rangeOffset, 'months')
          .add(3, 'years');
      } else {
        return bookMonth
          .clone()
          .month(fiscalYearMonth)
          .subtract(1, 'month')
          .add(rangeOffset, 'months')
          .add(3, 'years');
      }
    }
    case ranges.NEXT_YEAR_PLUS_THREE_FINAL_FISCAL_MONTH:
    case 'NEXT_YEAR_PLUS_THREE_FINAL_FISCAL_MONTH': {
      if (bookMonth.month() >= fiscalYearMonth) {
        return bookMonth
          .clone()
          .month(fiscalYearMonth)
          .add(11, 'months')
          .add(rangeOffset, 'months')
          .add(4, 'years');
      } else {
        return bookMonth
          .clone()
          .month(fiscalYearMonth)
          .subtract(1, 'month')
          .add(rangeOffset, 'months')
          .add(4, 'years');
      }
    }
    case ranges.NEXT_YEAR_PLUS_FOUR_FINAL_FISCAL_MONTH:
    case 'NEXT_YEAR_PLUS_FOUR_FINAL_FISCAL_MONTH': {
      if (bookMonth.month() >= fiscalYearMonth) {
        return bookMonth
          .clone()
          .month(fiscalYearMonth)
          .add(11, 'months')
          .add(rangeOffset, 'months')
          .add(5, 'years');
      } else {
        return bookMonth
          .clone()
          .month(fiscalYearMonth)
          .subtract(1, 'month')
          .add(rangeOffset, 'months')
          .add(5, 'years');
      }
    }
    case ranges.FINAL_FISCAL_QUARTER:
    case 'FINAL_FISCAL_QUARTER': {
      return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
        .add(9, 'months')
        .add(rangeOffset, 'months');
    }
    case ranges.NEXT_YEAR_FINAL_FISCAL_QUARTER:
    case 'NEXT_YEAR_FINAL_FISCAL_QUARTER': {
      return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
        .add(1, 'years')
        .add(9, 'months')
        .add(rangeOffset, 'months');
    }
    case ranges.NEXT_YEAR_PLUS_ONE_FINAL_FISCAL_QUARTER:
    case 'NEXT_YEAR_PLUS_ONE_FINAL_FISCAL_QUARTER': {
      return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
        .add(2, 'years')
        .add(9, 'months')
        .add(rangeOffset, 'months');
    }
    case ranges.NEXT_YEAR_PLUS_TWO_FINAL_FISCAL_QUARTER:
    case 'NEXT_YEAR_PLUS_TWO_FINAL_FISCAL_QUARTER': {
      return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
        .add(3, 'years')
        .add(9, 'months')
        .add(rangeOffset, 'months');
    }
    case ranges.NEXT_YEAR_PLUS_THREE_FINAL_FISCAL_QUARTER:
    case 'NEXT_YEAR_PLUS_THREE_FINAL_FISCAL_QUARTER': {
      return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
        .add(4, 'years')
        .add(9, 'months')
        .add(rangeOffset, 'months');
    }
    case ranges.NEXT_YEAR_PLUS_FOUR_FINAL_FISCAL_QUARTER:
    case 'NEXT_YEAR_PLUS_FOUR_FINAL_FISCAL_QUARTER': {
      return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
        .add(5, 'years')
        .add(9, 'months')
        .add(rangeOffset, 'months');
    }
    case ranges.FOUR_FISCAL_QUARTERS:
    case 'FOUR_FISCAL_QUARTERS': {
      return getStartOfFiscalYear(bookMonth, fiscalYearMonth).add(
        rangeOffset,
        'months'
      );
    }
    case ranges.FINAL_FISCAL_MONTH_NEXT_YEAR:
    case 'FINAL_FISCAL_MONTH_NEXT_YEAR': {
      if (bookMonth.month() >= fiscalYearMonth) {
        return bookMonth
          .clone()
          .month(fiscalYearMonth)
          .add(11, 'months')
          .add(rangeOffset, 'months')
          .add(12, 'months');
      } else {
        return bookMonth
          .clone()
          .month(fiscalYearMonth)
          .subtract(1, 'month')
          .add(rangeOffset, 'months')
          .add(12, 'months');
      }
    }
    case ranges.FINAL_CALENDAR_MONTH:
    case 'FINAL_CALENDAR_MONTH': {
      return bookMonth
        .clone()
        .endOf('year')
        .startOf('month')
        .add(rangeOffset, 'months');
    }
    case ranges.NEXT_YEAR_FINAL_CALENDAR_MONTH:
    case 'NEXT_YEAR_FINAL_CALENDAR_MONTH': {
      return bookMonth
        .clone()
        .endOf('year')
        .startOf('month')
        .add(rangeOffset, 'months')
        .add(1, 'years');
    }
    case ranges.NEXT_YEAR_PLUS_ONE_FINAL_CALENDAR_MONTH:
    case 'NEXT_YEAR_PLUS_ONE_FINAL_CALENDAR_MONTH': {
      return bookMonth
        .clone()
        .endOf('year')
        .startOf('month')
        .add(rangeOffset, 'months')
        .add(2, 'years');
    }
    case ranges.NEXT_YEAR_PLUS_TWO_FINAL_CALENDAR_MONTH:
    case 'NEXT_YEAR_PLUS_TWO_FINAL_CALENDAR_MONTH': {
      return bookMonth
        .clone()
        .endOf('year')
        .startOf('month')
        .add(rangeOffset, 'months')
        .add(3, 'years');
    }
    case ranges.NEXT_YEAR_PLUS_THREE_FINAL_CALENDAR_MONTH:
    case 'NEXT_YEAR_PLUS_THREE_FINAL_CALENDAR_MONTH': {
      return bookMonth
        .clone()
        .endOf('year')
        .startOf('month')
        .add(rangeOffset, 'months')
        .add(4, 'years');
    }
    case ranges.NEXT_YEAR_PLUS_FOUR_FINAL_CALENDAR_MONTH:
    case 'NEXT_YEAR_PLUS_FOUR_FINAL_CALENDAR_MONTH': {
      return bookMonth
        .clone()
        .endOf('year')
        .startOf('month')
        .add(rangeOffset, 'months')
        .add(5, 'years');
    }
    case ranges.FINAL_CALENDAR_QUARTER:
    case 'FINAL_CALENDAR_QUARTER': {
      return bookMonth
        .clone()
        .endOf('year')
        .startOf('month')
        .subtract(2, 'months')
        .add(rangeOffset, 'months');
    }
    case ranges.NEXT_YEAR_FINAL_CALENDAR_QUARTER:
    case 'NEXT_YEAR_FINAL_CALENDAR_QUARTER': {
      return bookMonth
        .clone()
        .endOf('year')
        .add(1, 'years')
        .startOf('month')
        .subtract(2, 'months')
        .add(rangeOffset, 'months');
    }
    case ranges.NEXT_YEAR_PLUS_ONE_FINAL_CALENDAR_QUARTER:
    case 'NEXT_YEAR_PLUS_ONE_FINAL_CALENDAR_QUARTER': {
      return bookMonth
        .clone()
        .endOf('year')
        .add(2, 'years')
        .startOf('month')
        .subtract(2, 'months')
        .add(rangeOffset, 'months');
    }
    case ranges.NEXT_YEAR_PLUS_TWO_FINAL_CALENDAR_QUARTER:
    case 'NEXT_YEAR_PLUS_TWO_FINAL_CALENDAR_QUARTER': {
      return bookMonth
        .clone()
        .endOf('year')
        .add(3, 'years')
        .startOf('month')
        .subtract(2, 'months')
        .add(rangeOffset, 'months');
    }
    case ranges.NEXT_YEAR_PLUS_THREE_FINAL_CALENDAR_QUARTER:
    case 'NEXT_YEAR_PLUS_THREE_FINAL_CALENDAR_QUARTER': {
      return bookMonth
        .clone()
        .endOf('year')
        .add(4, 'years')
        .startOf('month')
        .subtract(2, 'months')
        .add(rangeOffset, 'months');
    }
    case ranges.NEXT_YEAR_PLUS_FOUR_FINAL_CALENDAR_QUARTER:
    case 'NEXT_YEAR_PLUS_FOUR_FINAL_CALENDAR_QUARTER': {
      return bookMonth
        .clone()
        .endOf('year')
        .add(5, 'years')
        .startOf('month')
        .subtract(2, 'months')
        .add(rangeOffset, 'months');
    }
    case ranges.FOUR_CALENDAR_QUARTERS:
    case 'FOUR_CALENDAR_QUARTERS': {
      return bookMonth.clone().startOf('year').add(rangeOffset, 'months');
    }
    case ranges.FINAL_CALENDAR_MONTH_NEXT_YEAR:
    case 'FINAL_CALENDAR_MONTH_NEXT_YEAR': {
      return bookMonth
        .clone()
        .endOf('year')
        .startOf('month')
        .add(rangeOffset, 'months')
        .add(12, 'months');
    }
    case ranges.FISCAL_YEAR_TO_DATE:
    case 'FISCAL_YEAR_TO_DATE':
      if (now.month() >= fiscalYearMonth) {
        return now.clone().month(fiscalYearMonth).add(rangeOffset, 'months');
      } else {
        return now
          .clone()
          .month(fiscalYearMonth)
          .subtract(1, 'year')
          .add(rangeOffset, 'months');
      }
    case ranges.CURRENT_FISCAL_YEAR:
    case 'CURRENT_FISCAL_YEAR':
      if (bookMonth.month() >= fiscalYearMonth) {
        return bookMonth
          .clone()
          .month(fiscalYearMonth)
          .add(rangeOffset, 'months');
      } else {
        return bookMonth
          .clone()
          .month(fiscalYearMonth)
          .subtract(1, 'year')
          .add(rangeOffset, 'months');
      }
    case ranges.FISCAL_YEAR_TO_DATE_BACK_ONE_YEAR:
    case 'FISCAL_YEAR_TO_DATE_BACK_ONE_YEAR':
      if (now.month() >= fiscalYearMonth) {
        return now
          .clone()
          .month(fiscalYearMonth)
          .subtract(1, 'year')
          .add(rangeOffset, 'months');
      } else {
        return now
          .clone()
          .month(fiscalYearMonth)
          .subtract(2, 'year')
          .add(rangeOffset, 'months');
      }
    case ranges.FISCAL_YEAR_TO_DATE_BACK_TWO_YEARS:
    case 'FISCAL_YEAR_TO_DATE_BACK_TWO_YEARS':
      if (now.month() >= fiscalYearMonth) {
        return now
          .clone()
          .month(fiscalYearMonth)
          .subtract(2, 'year')
          .add(rangeOffset, 'months');
      } else {
        return now
          .clone()
          .month(fiscalYearMonth)
          .subtract(3, 'year')
          .add(rangeOffset, 'months');
      }
    case ranges.LAST_FISCAL_YEAR:
    case 'LAST_FISCAL_YEAR':
      if (bookMonth.month() >= fiscalYearMonth) {
        return bookMonth
          .clone()
          .month(fiscalYearMonth)
          .subtract(1, 'year')
          .add(rangeOffset, 'months');
      } else {
        return bookMonth
          .clone()
          .month(fiscalYearMonth)
          .subtract(2, 'years')
          .add(rangeOffset, 'months');
      }
    case ranges.LAST_FISCAL_YEAR_BACK_ONE:
    case 'LAST_FISCAL_YEAR_BACK_ONE':
      if (bookMonth.month() >= fiscalYearMonth) {
        return bookMonth
          .clone()
          .month(fiscalYearMonth)
          .subtract(2, 'year')
          .add(rangeOffset, 'months');
      } else {
        return bookMonth
          .clone()
          .month(fiscalYearMonth)
          .subtract(3, 'years')
          .add(rangeOffset, 'months');
      }
    case ranges.LAST_FISCAL_YEAR_BACK_TWO:
    case 'LAST_FISCAL_YEAR_BACK_TWO':
      if (bookMonth.month() >= fiscalYearMonth) {
        return bookMonth
          .clone()
          .month(fiscalYearMonth)
          .subtract(3, 'year')
          .add(rangeOffset, 'months');
      } else {
        return bookMonth
          .clone()
          .month(fiscalYearMonth)
          .subtract(4, 'years')
          .add(rangeOffset, 'months');
      }
    case ranges.FISCAL_YEAR_TO_LAST_MONTH:
    case 'FISCAL_YEAR_TO_LAST_MONTH': {
      const lastMonth = now.clone().startOf('month').subtract(1, 'month');

      if (lastMonth.month() >= fiscalYearMonth) {
        return lastMonth
          .clone()
          .month(fiscalYearMonth)
          .add(rangeOffset, 'months');
      } else {
        return lastMonth
          .clone()
          .month(fiscalYearMonth)
          .subtract(1, 'year')
          .add(rangeOffset, 'months');
      }
    }
    case ranges.FISCAL_YEAR_TO_LAST_MONTH_BACK_ONE_YEAR:
    case 'FISCAL_YEAR_TO_LAST_MONTH_BACK_ONE_YEAR': {
      const lastMonth = now.clone().startOf('month').subtract(1, 'month');

      if (lastMonth.month() >= fiscalYearMonth) {
        return lastMonth
          .clone()
          .month(fiscalYearMonth)
          .subtract(1, 'year')
          .add(rangeOffset, 'months');
      } else {
        return lastMonth
          .clone()
          .month(fiscalYearMonth)
          .subtract(2, 'years')
          .add(rangeOffset, 'months');
      }
    }
    case ranges.FISCAL_YEAR_TO_LAST_MONTH_BACK_TWO_YEARS:
    case 'FISCAL_YEAR_TO_LAST_MONTH_BACK_TWO_YEARS': {
      const lastMonth = now.clone().startOf('month').subtract(1, 'month');

      if (lastMonth.month() >= fiscalYearMonth) {
        return lastMonth
          .clone()
          .month(fiscalYearMonth)
          .subtract(2, 'years')
          .add(rangeOffset, 'months');
      } else {
        return lastMonth
          .clone()
          .month(fiscalYearMonth)
          .subtract(3, 'years')
          .add(rangeOffset, 'months');
      }
    }
    case ranges.FISCAL_YEAR_TO_LAST_MONTH_BACK_ONE_MONTH:
    case 'FISCAL_YEAR_TO_LAST_MONTH_BACK_ONE_MONTH': {
      const lastMonth = now.clone().startOf('month').subtract(2, 'month');

      if (lastMonth.month() >= fiscalYearMonth) {
        return lastMonth
          .clone()
          .month(fiscalYearMonth)
          .add(rangeOffset, 'months');
      } else {
        return lastMonth
          .clone()
          .month(fiscalYearMonth)
          .subtract(1, 'year')
          .add(rangeOffset, 'months');
      }
    }
    case ranges.FISCAL_YEAR_TO_LAST_MONTH_BACK_TWO_MONTHS:
    case 'FISCAL_YEAR_TO_LAST_MONTH_BACK_TWO_MONTHS': {
      const lastMonth = now.clone().startOf('month').subtract(3, 'month');

      if (lastMonth.month() >= fiscalYearMonth) {
        return lastMonth
          .clone()
          .month(fiscalYearMonth)
          .add(rangeOffset, 'months');
      } else {
        return lastMonth
          .clone()
          .month(fiscalYearMonth)
          .subtract(1, 'year')
          .add(rangeOffset, 'months');
      }
    }
    case ranges.FISCAL_YEAR_TO_LAST_MONTH_BACK_THREE_MONTHS:
    case 'FISCAL_YEAR_TO_LAST_MONTH_BACK_THREE_MONTHS': {
      const lastMonth = now.clone().startOf('month').subtract(4, 'month');

      if (lastMonth.month() >= fiscalYearMonth) {
        return lastMonth
          .clone()
          .month(fiscalYearMonth)
          .add(rangeOffset, 'months');
      } else {
        return lastMonth
          .clone()
          .month(fiscalYearMonth)
          .subtract(1, 'year')
          .add(rangeOffset, 'months');
      }
    }
    case ranges.NEXT_FISCAL_YEAR_FORWARD_ONE:
    case 'NEXT_FISCAL_YEAR_FORWARD_ONE': {
      if (now.month() >= fiscalYearMonth) {
        return now
          .clone()
          .month(fiscalYearMonth)
          .add(2, 'year')
          .add(rangeOffset, 'months');
      } else {
        return now
          .clone()
          .month(fiscalYearMonth)
          .add(1, 'year')
          .add(rangeOffset, 'months');
      }
    }
    case ranges.NEXT_FISCAL_YEAR_FORWARD_TWO:
    case 'NEXT_FISCAL_YEAR_FORWARD_TWO': {
      if (now.month() >= fiscalYearMonth) {
        return now
          .clone()
          .month(fiscalYearMonth)
          .add(3, 'year')
          .add(rangeOffset, 'months');
      } else {
        return now
          .clone()
          .month(fiscalYearMonth)
          .add(2, 'years')
          .add(rangeOffset, 'months');
      }
    }
    case ranges.NEXT_FISCAL_YEAR_FORWARD_THREE:
    case 'NEXT_FISCAL_YEAR_FORWARD_THREE': {
      if (now.month() >= fiscalYearMonth) {
        return now
          .clone()
          .month(fiscalYearMonth)
          .add(4, 'year')
          .add(rangeOffset, 'months');
      } else {
        return now
          .clone()
          .month(fiscalYearMonth)
          .add(3, 'years')
          .add(rangeOffset, 'months');
      }
    }
    case ranges.NEXT_FISCAL_YEAR_FORWARD_FOUR:
    case 'NEXT_FISCAL_YEAR_FORWARD_FOUR': {
      if (now.month() >= fiscalYearMonth) {
        return now
          .clone()
          .month(fiscalYearMonth)
          .add(5, 'year')
          .add(rangeOffset, 'months');
      } else {
        return now
          .clone()
          .month(fiscalYearMonth)
          .add(4, 'years')
          .add(rangeOffset, 'months');
      }
    }
    case ranges.NEXT_THREE_MONTHS:
    case ranges.NEXT_SIX_MONTHS:
    case ranges.NEXT_TWELVE_MONTHS:
    case 'NEXT_THREE_MONTHS':
    case 'NEXT_SIX_MONTHS':
    case 'NEXT_TWELVE_MONTHS': {
      return bookMonth
        .clone()
        .startOf('month')
        .add(1, 'months')
        .add(rangeOffset, 'months');
    }
    case ranges.NEXT_CALENDAR_QUARTER:
    case 'NEXT_CALENDAR_QUARTER':
      return bookMonth
        .clone()
        .startOf('quarter')
        .add(1, 'quarters')
        .add(rangeOffset, 'months');
    case ranges.NEXT_CALENDAR_QUARTER_PLUS_ONE:
    case 'NEXT_CALENDAR_QUARTER_PLUS_ONE':
      return bookMonth
        .clone()
        .startOf('quarter')
        .add(2, 'quarters')
        .add(rangeOffset, 'months');
    case ranges.NEXT_CALENDAR_QUARTER_PLUS_TWO:
    case 'NEXT_CALENDAR_QUARTER_PLUS_TWO':
      return bookMonth
        .clone()
        .startOf('quarter')
        .add(3, 'quarters')
        .add(rangeOffset, 'months');
    case ranges.NEXT_CALENDAR_QUARTER_PLUS_THREE:
    case 'NEXT_CALENDAR_QUARTER_PLUS_THREE':
      return bookMonth
        .clone()
        .startOf('quarter')
        .add(4, 'quarters')
        .add(rangeOffset, 'months');
    case ranges.NEXT_YEAR_FOUR_FISCAL_QUARTERS:
    case 'NEXT_YEAR_FOUR_FISCAL_QUARTERS':
      return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
        .add(1, 'years')
        .add(rangeOffset, 'months');
    case ranges.NEXT_YEAR_FOUR_CALENDAR_QUARTERS:
    case 'NEXT_YEAR_FOUR_CALENDAR_QUARTERS':
      return bookMonth
        .clone()
        .startOf('year')
        .add(1, 'years')
        .add(rangeOffset, 'months');
    case ranges.NEXT_YEAR_PLUS_ONE_TWELVE_FISCAL_MONTHS:
    case 'NEXT_YEAR_PLUS_ONE_TWELVE_FISCAL_MONTHS':
      return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
        .add(2, 'years')
        .add(rangeOffset, 'months');
    case ranges.NEXT_YEAR_PLUS_ONE_TWELVE_CALENDAR_MONTHS:
    case 'NEXT_YEAR_PLUS_ONE_TWELVE_CALENDAR_MONTHS':
      return bookMonth
        .clone()
        .startOf('year')
        .add(2, 'years')
        .add(rangeOffset, 'months');
    case ranges.NEXT_YEAR_PLUS_ONE_FOUR_FISCAL_QUARTERS:
    case 'NEXT_YEAR_PLUS_ONE_FOUR_FISCAL_QUARTERS':
      return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
        .add(2, 'years')
        .add(rangeOffset, 'months');
    case ranges.NEXT_YEAR_PLUS_ONE_FOUR_CALENDAR_QUARTERS:
    case 'NEXT_YEAR_PLUS_ONE_FOUR_CALENDAR_QUARTERS':
      return bookMonth
        .clone()
        .startOf('year')
        .add(2, 'years')
        .add(rangeOffset, 'months');
    case ranges.NEXT_YEAR_PLUS_TWO_TWELVE_FISCAL_MONTHS:
    case 'NEXT_YEAR_PLUS_TWO_TWELVE_FISCAL_MONTHS':
      return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
        .add(3, 'years')
        .add(rangeOffset, 'months');
    case ranges.NEXT_YEAR_PLUS_TWO_TWELVE_CALENDAR_MONTHS:
    case 'NEXT_YEAR_PLUS_TWO_TWELVE_CALENDAR_MONTHS':
      return bookMonth
        .clone()
        .startOf('year')
        .add(3, 'years')
        .add(rangeOffset, 'months');
    case ranges.NEXT_YEAR_PLUS_TWO_FOUR_FISCAL_QUARTERS:
    case 'NEXT_YEAR_PLUS_TWO_FOUR_FISCAL_QUARTERS':
      return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
        .add(3, 'years')
        .add(rangeOffset, 'months');
    case ranges.NEXT_YEAR_PLUS_TWO_FOUR_CALENDAR_QUARTERS:
    case 'NEXT_YEAR_PLUS_TWO_FOUR_CALENDAR_QUARTERS':
      return bookMonth
        .clone()
        .startOf('year')
        .add(3, 'years')
        .add(rangeOffset, 'months');
    case ranges.NEXT_YEAR_PLUS_THREE_TWELVE_FISCAL_MONTHS:
    case 'NEXT_YEAR_PLUS_THREE_TWELVE_FISCAL_MONTHS':
      return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
        .add(4, 'years')
        .add(rangeOffset, 'months');
    case ranges.NEXT_YEAR_PLUS_THREE_TWELVE_CALENDAR_MONTHS:
    case 'NEXT_YEAR_PLUS_THREE_TWELVE_CALENDAR_MONTHS':
      return bookMonth
        .clone()
        .startOf('year')
        .add(4, 'years')
        .add(rangeOffset, 'months');
    case ranges.NEXT_YEAR_PLUS_THREE_FOUR_FISCAL_QUARTERS:
    case 'NEXT_YEAR_PLUS_THREE_FOUR_FISCAL_QUARTERS':
      return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
        .add(4, 'years')
        .add(rangeOffset, 'months');
    case ranges.NEXT_YEAR_PLUS_THREE_FOUR_CALENDAR_QUARTERS:
    case 'NEXT_YEAR_PLUS_THREE_FOUR_CALENDAR_QUARTERS':
      return bookMonth
        .clone()
        .startOf('year')
        .add(4, 'years')
        .add(rangeOffset, 'months');
    case ranges.NEXT_YEAR_PLUS_FOUR_TWELVE_FISCAL_MONTHS:
    case 'NEXT_YEAR_PLUS_FOUR_TWELVE_FISCAL_MONTHS':
      return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
        .add(5, 'years')
        .add(rangeOffset, 'months');
    case ranges.NEXT_YEAR_PLUS_FOUR_TWELVE_CALENDAR_MONTHS:
    case 'NEXT_YEAR_PLUS_FOUR_TWELVE_CALENDAR_MONTHS':
      return bookMonth
        .clone()
        .startOf('year')
        .add(5, 'years')
        .add(rangeOffset, 'months');
    case ranges.NEXT_YEAR_PLUS_FOUR_FOUR_FISCAL_QUARTERS:
    case 'NEXT_YEAR_PLUS_FOUR_FOUR_FISCAL_QUARTERS':
      return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
        .add(5, 'years')
        .add(rangeOffset, 'months');
    case ranges.NEXT_YEAR_PLUS_FOUR_FOUR_CALENDAR_QUARTERS:
    case 'NEXT_YEAR_PLUS_FOUR_FOUR_CALENDAR_QUARTERS':
      return bookMonth
        .clone()
        .startOf('year')
        .add(5, 'years')
        .add(rangeOffset, 'months');
    default:
      return now
        .clone()
        .startOf('month')
        .subtract(6, 'months')
        .add(rangeOffset, 'months');
  }
};

module.exports.getEndDate = function (
  range,
  customStartDate,
  customEndDate,
  now,
  _rangeOffset,
  fiscalYearMonth = 0
) {
  const bookMonth = now ? now.clone().subtract(1, 'month') : moment.utc();

  const isCustomLive =
    !isNaN(_rangeOffset) &&
    _rangeOffset !== null &&
    [ranges.CUSTOM, 'CUSTOM', ranges.CUSTOM_QUARTER, 'CUSTOM_QUARTER'].includes(
      range
    );
  const rangeOffset = _rangeOffset || 0;

  const pastFromRange = {
    LAST_FISCAL_QUARTER: 0,
    LAST_FISCAL_QUARTER_BACK_ONE: 1,
    LAST_FISCAL_QUARTER_BACK_TWO: 2,
    LAST_FISCAL_QUARTER_BACK_THREE: 3,
    LAST_FISCAL_QUARTER_BACK_FOUR: 4,
  };

  const past = pastFromRange[range] || 0;

  if ([ranges.CUSTOM, 'CUSTOM', ranges.FORECAST, 'FORECAST'].includes(range)) {
    if (isCustomLive) {
      const diff = customEndDate.diff(customStartDate, 'month');
      return now
        .clone()
        .startOf('month')
        .add(rangeOffset, 'months')
        .add(diff, 'months');
    }
    return customEndDate.clone();
  }
  if (range === ranges.CUSTOM_QUARTER || range === 'CUSTOM_QUARTER') {
    if (isCustomLive) {
      const originalPresentDate = customStartDate
        .clone()
        .subtract(rangeOffset, 'months');
      const quartersPassed = Math.floor(
        now.diff(originalPresentDate, 'month') / 3
      );
      return customEndDate.clone().add(quartersPassed * 3, 'months');
    }
    return customEndDate.clone();
  }

  if ([ranges.CURRENT_FISCAL_YEAR, 'CURRENT_FISCAL_YEAR'].includes(range)) {
    if (bookMonth.month() >= fiscalYearMonth) {
      return bookMonth
        .clone()
        .month(fiscalYearMonth)
        .add(11, 'months')
        .add(rangeOffset, 'months');
    } else {
      return bookMonth
        .clone()
        .month(fiscalYearMonth)
        .subtract(1, 'year')
        .add(11, 'months')
        .add(rangeOffset, 'months');
    }
  }

  if (
    [
      ranges.LAST_MONTH,
      ranges.LAST_THREE_FULL_MONTHS,
      ranges.LAST_SIX_FULL_MONTHS,
      ranges.LAST_TWELVE_FULL_MONTHS,
      ranges.YEAR_TO_LAST_MONTH,
      ranges.FISCAL_YEAR_TO_LAST_MONTH,
      'LAST_MONTH',
      'LAST_THREE_FULL_MONTHS',
      'LAST_SIX_FULL_MONTHS',
      'LAST_TWELVE_FULL_MONTHS',
      'YEAR_TO_LAST_MONTH',
      'FISCAL_YEAR_TO_LAST_MONTH',
      null,
    ].includes(range)
  ) {
    return now
      .clone()
      .startOf('month')
      .subtract(1, 'month')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.FISCAL_YEAR_TO_LAST_MONTH_BACK_ONE_MONTH,
      'FISCAL_YEAR_TO_LAST_MONTH_BACK_ONE_MONTH',
    ].includes(range)
  ) {
    return now
      .clone()
      .startOf('month')
      .subtract(2, 'month')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.FISCAL_YEAR_TO_LAST_MONTH_BACK_TWO_MONTHS,
      'FISCAL_YEAR_TO_LAST_MONTH_BACK_TWO_MONTHS',
    ].includes(range)
  ) {
    return now
      .clone()
      .startOf('month')
      .subtract(3, 'month')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.FISCAL_YEAR_TO_LAST_MONTH_BACK_THREE_MONTHS,
      'FISCAL_YEAR_TO_LAST_MONTH_BACK_THREE_MONTHS',
    ].includes(range)
  ) {
    return now
      .clone()
      .startOf('month')
      .subtract(4, 'month')
      .add(rangeOffset, 'months');
  }

  if ([ranges.LAST_FISCAL_YEAR, 'LAST_FISCAL_YEAR'].includes(range)) {
    if (bookMonth.month() >= fiscalYearMonth) {
      return bookMonth
        .clone()
        .month(fiscalYearMonth)
        .subtract(1, 'month')
        .add(rangeOffset, 'months');
    } else {
      return bookMonth
        .clone()
        .month(fiscalYearMonth)
        .subtract(1, 'years')
        .subtract(1, 'month')
        .add(rangeOffset, 'months');
    }
  }
  if (
    [ranges.LAST_FISCAL_YEAR_BACK_ONE, 'LAST_FISCAL_YEAR_BACK_ONE'].includes(
      range
    )
  ) {
    if (bookMonth.month() >= fiscalYearMonth) {
      return bookMonth
        .clone()
        .month(fiscalYearMonth)
        .subtract(1, 'year')
        .subtract(1, 'month')
        .add(rangeOffset, 'months');
    } else {
      return bookMonth
        .clone()
        .month(fiscalYearMonth)
        .subtract(2, 'years')
        .subtract(1, 'month')
        .add(rangeOffset, 'months');
    }
  }
  if (
    [ranges.LAST_FISCAL_YEAR_BACK_TWO, 'LAST_FISCAL_YEAR_BACK_TWO'].includes(
      range
    )
  ) {
    if (bookMonth.month() >= fiscalYearMonth) {
      return bookMonth
        .clone()
        .month(fiscalYearMonth)
        .subtract(2, 'year')
        .subtract(1, 'month')
        .add(rangeOffset, 'months');
    } else {
      return bookMonth
        .clone()
        .month(fiscalYearMonth)
        .subtract(3, 'years')
        .subtract(1, 'month')
        .add(rangeOffset, 'months');
    }
  }

  if (
    [ranges.CURRENT_FISCAL_QUARTER, 'CURRENT_FISCAL_QUARTER'].includes(range)
  ) {
    return getFiscalQuarterEnd(now, fiscalYearMonth, rangeOffset, 0);
  }

  if (
    [
      ranges.CURRENT_FISCAL_QUARTER_FIRST_MONTH,
      'CURRENT_FISCAL_QUARTER_FIRST_MONTH',
    ].includes(range)
  ) {
    return getFiscalQuarterStart(now, fiscalYearMonth, rangeOffset, 0);
  }

  if (
    [
      ranges.CURRENT_FISCAL_QUARTER_SECOND_MONTH,
      'CURRENT_FISCAL_QUARTER_SECOND_MONTH',
    ].includes(range)
  ) {
    return getFiscalQuarterStart(now, fiscalYearMonth, rangeOffset, 0).add(
      1,
      'month'
    );
  }

  if (
    [
      ranges.CURRENT_FISCAL_QUARTER_THIRD_MONTH,
      'CURRENT_FISCAL_QUARTER_THIRD_MONTH',
    ].includes(range)
  ) {
    return getFiscalQuarterStart(now, fiscalYearMonth, rangeOffset, 0).add(
      2,
      'months'
    );
  }

  if (
    [
      ranges.LAST_FISCAL_QUARTER,
      'LAST_FISCAL_QUARTER',
      ranges.LAST_FOUR_FISCAL_QUARTERS,
      'LAST_FOUR_FISCAL_QUARTERS',
    ].includes(range)
  ) {
    return getFiscalQuarterEnd(now, fiscalYearMonth, rangeOffset, 1);
  }
  if (
    [
      ranges.LAST_FISCAL_QUARTER_BACK_ONE,
      'LAST_FISCAL_QUARTER_BACK_ONE',
    ].includes(range)
  ) {
    return getFiscalQuarterEnd(now, fiscalYearMonth, rangeOffset, 2);
  }
  if (
    [
      ranges.LAST_FISCAL_QUARTER_BACK_TWO,
      'LAST_FISCAL_QUARTER_BACK_TWO',
    ].includes(range)
  ) {
    return getFiscalQuarterEnd(now, fiscalYearMonth, rangeOffset, 3);
  }
  if (
    [
      ranges.LAST_FISCAL_QUARTER_BACK_THREE,
      'LAST_FISCAL_QUARTER_BACK_THREE',
    ].includes(range)
  ) {
    return getFiscalQuarterEnd(now, fiscalYearMonth, rangeOffset, 4);
  }
  if (
    [
      ranges.LAST_FISCAL_QUARTER_BACK_FOUR,
      'LAST_FISCAL_QUARTER_BACK_FOUR',
    ].includes(range)
  ) {
    return getFiscalQuarterEnd(now, fiscalYearMonth, rangeOffset, 5);
  }

  if ([ranges.NEXT_FISCAL_QUARTER, 'NEXT_FISCAL_QUARTER'].includes(range)) {
    return getFiscalQuarterEnd(bookMonth, fiscalYearMonth, rangeOffset, -1);
  }
  if (
    [
      ranges.NEXT_FISCAL_QUARTER_FORWARD_ONE,
      'NEXT_FISCAL_QUARTER_FORWARD_ONE',
    ].includes(range)
  ) {
    return getFiscalQuarterEnd(bookMonth, fiscalYearMonth, rangeOffset, -2);
  }
  if (
    [
      ranges.NEXT_FISCAL_QUARTER_FORWARD_TWO,
      'NEXT_FISCAL_QUARTER_FORWARD_TWO',
    ].includes(range)
  ) {
    return getFiscalQuarterEnd(bookMonth, fiscalYearMonth, rangeOffset, -3);
  }
  if (
    [
      ranges.NEXT_FISCAL_QUARTER_FORWARD_THREE,
      'NEXT_FISCAL_QUARTER_FORWARD_THREE',
    ].includes(range)
  ) {
    return getFiscalQuarterEnd(bookMonth, fiscalYearMonth, rangeOffset, -4);
  }
  if (
    [
      ranges.LAST_FOUR_FISCAL_QUARTERS,
      ranges.LAST_EIGHT_FISCAL_QUARTERS,
      'LAST_FOUR_FISCAL_QUARTERS',
      'LAST_EIGHT_FISCAL_QUARTERS',
    ].includes(range)
  ) {
    return getFiscalQuarterEnd(now, fiscalYearMonth, rangeOffset, 1);
  }

  if (
    [
      ranges.YEAR_TO_LAST_MONTH_BACK_ONE_YEAR,
      'YEAR_TO_LAST_MONTH_BACK_ONE_YEAR',
      ranges.FISCAL_YEAR_TO_LAST_MONTH_BACK_ONE_YEAR,
      'FISCAL_YEAR_TO_LAST_MONTH_BACK_ONE_YEAR',
    ].includes(range)
  ) {
    return now
      .clone()
      .startOf('month')
      .subtract(1, 'month')
      .subtract(1, 'year')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.YEAR_TO_LAST_MONTH_BACK_TWO_YEARS,
      'YEAR_TO_LAST_MONTH_BACK_TWO_YEARS',
      ranges.FISCAL_YEAR_TO_LAST_MONTH_BACK_TWO_YEARS,
      'FISCAL_YEAR_TO_LAST_MONTH_BACK_TWO_YEARS',
    ].includes(range)
  ) {
    return now
      .clone()
      .startOf('month')
      .subtract(1, 'month')
      .subtract(2, 'year')
      .add(rangeOffset, 'months');
  }

  if (
    [ranges.YEAR_TO_DATE_BACK_ONE_YEAR, 'YEAR_TO_DATE_BACK_ONE_YEAR'].includes(
      range
    )
  ) {
    return now
      .clone()
      .startOf('month')
      .subtract(1, 'year')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.YEAR_TO_DATE_BACK_TWO_YEARS,
      'YEAR_TO_DATE_BACK_TWO_YEARS',
    ].includes(range)
  ) {
    return now
      .clone()
      .startOf('month')
      .subtract(2, 'year')
      .add(rangeOffset, 'months');
  }

  if (
    [
      ranges.YEAR_TO_DATE_BACK_TWO_YEARS,
      'YEAR_TO_DATE_BACK_TWO_YEARS',
    ].includes(range)
  ) {
    return now
      .clone()
      .startOf('month')
      .subtract(1, 'month')
      .subtract(2, 'year')
      .add(rangeOffset, 'months');
  }

  if (
    range === ranges.LAST_THREE_FORWARD_THREE ||
    range === 'LAST_THREE_FORWARD_THREE'
  ) {
    return now
      .clone()
      .startOf('month')
      .add(2, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    range === ranges.LAST_SIX_FORWARD_SIX ||
    range === 'LAST_SIX_FORWARD_SIX'
  ) {
    return now
      .clone()
      .startOf('month')
      .add(5, 'months')
      .add(rangeOffset, 'months');
  }

  if (range === ranges.CURRENT_QUARTER || range === 'CURRENT_QUARTER') {
    return now
      .clone()
      .endOf('quarter')
      .startOf('month')
      .add(rangeOffset, 'months');
  }
  if (
    range === ranges.CURRENT_QUARTER_FIRST_MONTH ||
    range === 'CURRENT_QUARTER_FIRST_MONTH'
  ) {
    return now.clone().startOf('quarter').add(rangeOffset, 'months');
  }

  if (
    range === ranges.CURRENT_QUARTER_SECOND_MONTH ||
    range === 'CURRENT_QUARTER_SECOND_MONTH'
  ) {
    return now
      .clone()
      .startOf('quarter')
      .add(1, 'month')
      .add(rangeOffset, 'months');
  }

  if (
    range === ranges.CURRENT_QUARTER_THIRD_MONTH ||
    range === 'CURRENT_QUARTER_THIRD_MONTH'
  ) {
    return now
      .clone()
      .startOf('quarter')
      .add(2, 'months')
      .add(rangeOffset, 'months');
  }

  if (
    range === ranges.LAST_QUARTER ||
    range === 'LAST_QUARTER' ||
    range === ranges.LAST_FOUR_QUARTERS ||
    range === 'LAST_FOUR_QUARTERS'
  ) {
    return now
      .clone()
      .startOf('quarter')
      .subtract(1, 'month')
      .add(rangeOffset, 'months');
  }
  if (
    range === ranges.LAST_QUARTER_FIRST_MONTH ||
    range === 'LAST_QUARTER_FIRST_MONTH'
  ) {
    return now
      .clone()
      .startOf('quarter')
      .subtract(1, 'quarter')
      .add(rangeOffset, 'months');
  }
  if (
    range === ranges.LAST_QUARTER_SECOND_MONTH ||
    range === 'LAST_QUARTER_SECOND_MONTH'
  ) {
    return now
      .clone()
      .startOf('quarter')
      .subtract(1, 'quarter')
      .add(1, 'month')
      .add(rangeOffset, 'months');
  }
  if (
    range === ranges.LAST_QUARTER_THIRD_MONTH ||
    range === 'LAST_QUARTER_THIRD_MONTH'
  ) {
    return now
      .clone()
      .startOf('quarter')
      .subtract(1, 'quarter')
      .add(2, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    range === ranges.LAST_FISCAL_QUARTER_FIRST_MONTH ||
    range === 'LAST_FISCAL_QUARTER_FIRST_MONTH'
  ) {
    return getFiscalQuarterStart(now, fiscalYearMonth, rangeOffset, past)
      .clone()
      .subtract(1, 'quarter');
  }
  if (
    range === ranges.LAST_FISCAL_QUARTER_SECOND_MONTH ||
    range === 'LAST_FISCAL_QUARTER_SECOND_MONTH'
  ) {
    return getFiscalQuarterStart(now, fiscalYearMonth, rangeOffset, past)
      .clone()
      .subtract(1, 'quarter')
      .add(1, 'months');
  }
  if (
    range === ranges.LAST_FISCAL_QUARTER_THIRD_MONTH ||
    range === 'LAST_FISCAL_QUARTER_THIRD_MONTH'
  ) {
    return getFiscalQuarterStart(now, fiscalYearMonth, rangeOffset, past)
      .clone()
      .subtract(1, 'quarter')
      .add(2, 'months');
  }
  if (
    range === ranges.LAST_QUARTER_BACK_ONE ||
    range === 'LAST_QUARTER_BACK_ONE'
  ) {
    return now
      .clone()
      .startOf('quarter')
      .subtract(1, 'quarter')
      .subtract(1, 'month')
      .add(rangeOffset, 'months');
  }
  if (
    range === ranges.LAST_QUARTER_BACK_TWO ||
    range === 'LAST_QUARTER_BACK_TWO'
  ) {
    return now
      .clone()
      .startOf('quarter')
      .subtract(2, 'quarter')
      .subtract(1, 'month')
      .add(rangeOffset, 'months');
  }
  if (
    range === ranges.LAST_QUARTER_BACK_THREE ||
    range === 'LAST_QUARTER_BACK_THREE'
  ) {
    return now
      .clone()
      .startOf('quarter')
      .subtract(3, 'quarter')
      .subtract(1, 'month')
      .add(rangeOffset, 'months');
  }
  if (
    range === ranges.LAST_QUARTER_BACK_FOUR ||
    range === 'LAST_QUARTER_BACK_FOUR'
  ) {
    return now
      .clone()
      .startOf('quarter')
      .subtract(4, 'quarter')
      .subtract(1, 'month')
      .add(rangeOffset, 'months');
  }

  if (
    range === ranges.QUARTER_TO_LAST_MONTH ||
    range === 'QUARTER_TO_LAST_MONTH' ||
    range === ranges.FISCAL_QUARTER_TO_LAST_MONTH ||
    range === 'FISCAL_QUARTER_TO_LAST_MONTH'
  ) {
    return bookMonth.clone().add(rangeOffset, 'months');
  }

  if (range === ranges.LAST_FOUR_QUARTERS || range === 'LAST_FOUR_QUARTERS') {
    return now
      .clone()
      .startOf('quarter')
      .subtract(1, 'month')
      .add(rangeOffset, 'months');
  }

  if (range === ranges.LAST_EIGHT_QUARTERS || range === 'LAST_EIGHT_QUARTERS') {
    return now
      .clone()
      .startOf('quarter')
      .subtract(1, 'month')
      .add(rangeOffset, 'months');
  }

  if (range === ranges.CURRENT_YEAR || range === 'CURRENT_YEAR') {
    return bookMonth
      .clone()
      .startOf('year')
      .add(11, 'months')
      .add(rangeOffset, 'months');
  }
  if (range === ranges.LAST_YEAR || range === 'LAST_YEAR') {
    return bookMonth
      .clone()
      .startOf('year')
      .subtract(1, 'month')
      .add(rangeOffset, 'months');
  }
  if (range === ranges.LAST_YEAR_BACK_ONE || range === 'LAST_YEAR_BACK_ONE') {
    return bookMonth
      .clone()
      .startOf('year')
      .subtract(1, 'year')
      .subtract(1, 'month')
      .add(rangeOffset, 'months');
  }

  if (
    [
      ranges.FISCAL_YEAR_TO_DATE_BACK_ONE_YEAR,
      'FISCAL_YEAR_TO_DATE_BACK_ONE_YEAR',
    ].includes(range)
  ) {
    return now.clone().subtract(1, 'year');
  }
  if (
    [
      ranges.FISCAL_YEAR_TO_DATE_BACK_TWO_YEARS,
      'FISCAL_YEAR_TO_DATE_BACK_TWO_YEARS',
    ].includes(range)
  ) {
    return now.clone().subtract(2, 'year');
  }

  if ([ranges.NEXT_FISCAL_YEAR, 'NEXT_FISCAL_YEAR'].includes(range)) {
    return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
      .add(11, 'months')
      .add(rangeOffset, 'months')
      .add(12, 'months');
  }
  if ([ranges.TWELVE_FISCAL_MONTHS, 'TWELVE_FISCAL_MONTHS'].includes(range)) {
    return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
      .add(11, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.TWELVE_FISCAL_MONTHS_LAST_YEAR,
      'TWELVE_FISCAL_MONTHS_LAST_YEAR',
    ].includes(range)
  ) {
    return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
      .subtract(1, 'year')
      .add(11, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.TWELVE_FISCAL_MONTHS_LAST_YEAR_BACK_TWO,
      'TWELVE_FISCAL_MONTHS_LAST_YEAR_BACK_TWO',
    ].includes(range)
  ) {
    return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
      .subtract(2, 'year')
      .add(11, 'months')
      .add(rangeOffset, 'months');
  }
  if ([ranges.NEXT_CALENDAR_YEAR, 'NEXT_CALENDAR_YEAR'].includes(range)) {
    return bookMonth
      .clone()
      .startOf('year')
      .add(11, 'months')
      .add(rangeOffset, 'months')
      .add(12, 'months');
  }
  if (
    [ranges.TWELVE_CALENDAR_MONTHS, 'TWELVE_CALENDAR_MONTHS'].includes(range)
  ) {
    return bookMonth
      .clone()
      .startOf('year')
      .add(11, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.TWELVE_CALENDAR_MONTHS_LAST_YEAR,
      'TWELVE_CALENDAR_MONTHS_LAST_YEAR',
    ].includes(range)
  ) {
    return bookMonth
      .clone()
      .startOf('year')
      .subtract(1, 'year')
      .add(11, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.TWELVE_CALENDAR_MONTHS_LAST_YEAR_BACK_TWO,
      'TWELVE_CALENDAR_MONTHS_LAST_YEAR_BACK_TWO',
    ].includes(range)
  ) {
    return bookMonth
      .clone()
      .startOf('year')
      .subtract(2, 'year')
      .add(11, 'months')
      .add(rangeOffset, 'months');
  }

  if ([ranges.FINAL_FISCAL_MONTH, 'FINAL_FISCAL_MONTH'].includes(range)) {
    if (bookMonth.month() >= fiscalYearMonth) {
      return bookMonth
        .clone()
        .month(fiscalYearMonth)
        .add(11, 'months')
        .add(rangeOffset, 'months');
    } else {
      return bookMonth
        .clone()
        .month(fiscalYearMonth)
        .subtract(1, 'month')
        .add(rangeOffset, 'months');
    }
  }
  if (
    [
      ranges.NEXT_YEAR_FINAL_FISCAL_MONTH,
      'NEXT_YEAR_FINAL_FISCAL_MONTH',
    ].includes(range)
  ) {
    if (bookMonth.month() >= fiscalYearMonth) {
      return bookMonth
        .clone()
        .month(fiscalYearMonth)
        .add(11, 'months')
        .add(rangeOffset, 'months')
        .add(1, 'years');
    } else {
      return bookMonth
        .clone()
        .month(fiscalYearMonth)
        .subtract(1, 'month')
        .add(rangeOffset, 'months')
        .add(1, 'years');
    }
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_ONE_FINAL_FISCAL_MONTH,
      'NEXT_YEAR_PLUS_ONE_FINAL_FISCAL_MONTH',
    ].includes(range)
  ) {
    if (bookMonth.month() >= fiscalYearMonth) {
      return bookMonth
        .clone()
        .month(fiscalYearMonth)
        .add(11, 'months')
        .add(rangeOffset, 'months')
        .add(2, 'years');
    } else {
      return bookMonth
        .clone()
        .month(fiscalYearMonth)
        .subtract(1, 'month')
        .add(rangeOffset, 'months')
        .add(2, 'years');
    }
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_TWO_FINAL_FISCAL_MONTH,
      'NEXT_YEAR_PLUS_TWO_FINAL_FISCAL_MONTH',
    ].includes(range)
  ) {
    if (bookMonth.month() >= fiscalYearMonth) {
      return bookMonth
        .clone()
        .month(fiscalYearMonth)
        .add(11, 'months')
        .add(rangeOffset, 'months')
        .add(3, 'years');
    } else {
      return bookMonth
        .clone()
        .month(fiscalYearMonth)
        .subtract(1, 'month')
        .add(rangeOffset, 'months')
        .add(3, 'years');
    }
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_THREE_FINAL_FISCAL_MONTH,
      'NEXT_YEAR_PLUS_THREE_FINAL_FISCAL_MONTH',
    ].includes(range)
  ) {
    if (bookMonth.month() >= fiscalYearMonth) {
      return bookMonth
        .clone()
        .month(fiscalYearMonth)
        .add(11, 'months')
        .add(rangeOffset, 'months')
        .add(4, 'years');
    } else {
      return bookMonth
        .clone()
        .month(fiscalYearMonth)
        .subtract(1, 'month')
        .add(rangeOffset, 'months')
        .add(4, 'years');
    }
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_FOUR_FINAL_FISCAL_MONTH,
      'NEXT_YEAR_PLUS_FOUR_FINAL_FISCAL_MONTH',
    ].includes(range)
  ) {
    if (bookMonth.month() >= fiscalYearMonth) {
      return bookMonth
        .clone()
        .month(fiscalYearMonth)
        .add(11, 'months')
        .add(rangeOffset, 'months')
        .add(5, 'years');
    } else {
      return bookMonth
        .clone()
        .month(fiscalYearMonth)
        .subtract(1, 'month')
        .add(rangeOffset, 'months')
        .add(5, 'years');
    }
  }
  if ([ranges.FINAL_FISCAL_QUARTER, 'FINAL_FISCAL_QUARTER'].includes(range)) {
    return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
      .add(11, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_FINAL_FISCAL_QUARTER,
      'NEXT_YEAR_FINAL_FISCAL_QUARTER',
    ].includes(range)
  ) {
    return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
      .add(1, 'year')
      .add(11, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_ONE_FINAL_FISCAL_QUARTER,
      'NEXT_YEAR_PLUS_ONE_FINAL_FISCAL_QUARTER',
    ].includes(range)
  ) {
    return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
      .add(2, 'year')
      .add(11, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_TWO_FINAL_FISCAL_QUARTER,
      'NEXT_YEAR_PLUS_TWO_FINAL_FISCAL_QUARTER',
    ].includes(range)
  ) {
    return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
      .add(3, 'year')
      .add(11, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_THREE_FINAL_FISCAL_QUARTER,
      'NEXT_YEAR_PLUS_THREE_FINAL_FISCAL_QUARTER',
    ].includes(range)
  ) {
    return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
      .add(4, 'year')
      .add(11, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_FOUR_FINAL_FISCAL_QUARTER,
      'NEXT_YEAR_PLUS_FOUR_FINAL_FISCAL_QUARTER',
    ].includes(range)
  ) {
    return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
      .add(5, 'year')
      .add(11, 'months')
      .add(rangeOffset, 'months');
  }
  if ([ranges.FOUR_FISCAL_QUARTERS, 'FOUR_FISCAL_QUARTERS'].includes(range)) {
    return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
      .add(11, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.FINAL_FISCAL_MONTH_NEXT_YEAR,
      'FINAL_FISCAL_MONTH_NEXT_YEAR',
    ].includes(range)
  ) {
    if (bookMonth.month() >= fiscalYearMonth) {
      return bookMonth
        .clone()
        .month(fiscalYearMonth)
        .add(11, 'months')
        .add(rangeOffset, 'months')
        .add(12, 'months');
    } else {
      return bookMonth
        .clone()
        .month(fiscalYearMonth)
        .subtract(1, 'month')
        .add(rangeOffset, 'months')
        .add(12, 'months');
    }
  }
  if ([ranges.FINAL_CALENDAR_MONTH, 'FINAL_CALENDAR_MONTH'].includes(range)) {
    return bookMonth
      .clone()
      .endOf('year')
      .startOf('month')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_FINAL_CALENDAR_MONTH,
      'NEXT_YEAR_FINAL_CALENDAR_MONTH',
    ].includes(range)
  ) {
    return bookMonth
      .clone()
      .endOf('year')
      .startOf('month')
      .add(rangeOffset, 'months')
      .add(1, 'year');
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_ONE_FINAL_CALENDAR_MONTH,
      'NEXT_YEAR_PLUS_ONE_FINAL_CALENDAR_MONTH',
    ].includes(range)
  ) {
    return bookMonth
      .clone()
      .endOf('year')
      .startOf('month')
      .add(rangeOffset, 'months')
      .add(2, 'year');
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_TWO_FINAL_CALENDAR_MONTH,
      'NEXT_YEAR_PLUS_TWO_FINAL_CALENDAR_MONTH',
    ].includes(range)
  ) {
    return bookMonth
      .clone()
      .endOf('year')
      .startOf('month')
      .add(rangeOffset, 'months')
      .add(3, 'year');
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_THREE_FINAL_CALENDAR_MONTH,
      'NEXT_YEAR_PLUS_THREE_FINAL_CALENDAR_MONTH',
    ].includes(range)
  ) {
    return bookMonth
      .clone()
      .endOf('year')
      .startOf('month')
      .add(rangeOffset, 'months')
      .add(4, 'year');
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_FOUR_FINAL_CALENDAR_MONTH,
      'NEXT_YEAR_PLUS_FOUR_FINAL_CALENDAR_MONTH',
    ].includes(range)
  ) {
    return bookMonth
      .clone()
      .endOf('year')
      .startOf('month')
      .add(rangeOffset, 'months')
      .add(5, 'year');
  }
  if (
    [ranges.FINAL_CALENDAR_QUARTER, 'FINAL_CALENDAR_QUARTER'].includes(range)
  ) {
    return bookMonth
      .clone()
      .endOf('year')
      .startOf('month')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_FINAL_CALENDAR_QUARTER,
      'NEXT_YEAR_FINAL_CALENDAR_QUARTER',
    ].includes(range)
  ) {
    return bookMonth
      .clone()
      .endOf('year')
      .add(1, 'years')
      .startOf('month')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_ONE_FINAL_CALENDAR_QUARTER,
      'NEXT_YEAR_PLUS_ONE_FINAL_CALENDAR_QUARTER',
    ].includes(range)
  ) {
    return bookMonth
      .clone()
      .endOf('year')
      .add(2, 'years')
      .startOf('month')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_TWO_FINAL_CALENDAR_QUARTER,
      'NEXT_YEAR_PLUS_TWO_FINAL_CALENDAR_QUARTER',
    ].includes(range)
  ) {
    return bookMonth
      .clone()
      .endOf('year')
      .add(3, 'years')
      .startOf('month')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_THREE_FINAL_CALENDAR_QUARTER,
      'NEXT_YEAR_PLUS_THREE_FINAL_CALENDAR_QUARTER',
    ].includes(range)
  ) {
    return bookMonth
      .clone()
      .endOf('year')
      .add(4, 'years')
      .startOf('month')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_FOUR_FINAL_CALENDAR_QUARTER,
      'NEXT_YEAR_PLUS_FOUR_FINAL_CALENDAR_QUARTER',
    ].includes(range)
  ) {
    return bookMonth
      .clone()
      .endOf('year')
      .add(5, 'years')
      .startOf('month')
      .add(rangeOffset, 'months');
  }
  if (
    [ranges.FOUR_CALENDAR_QUARTERS, 'FOUR_CALENDAR_QUARTERS'].includes(range)
  ) {
    return bookMonth
      .clone()
      .endOf('year')
      .startOf('month')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.FINAL_CALENDAR_MONTH_NEXT_YEAR,
      'FINAL_CALENDAR_MONTH_NEXT_YEAR',
    ].includes(range)
  ) {
    return bookMonth
      .clone()
      .endOf('year')
      .startOf('month')
      .add(rangeOffset, 'months')
      .add(12, 'months');
  }

  if ([ranges.NEXT_FISCAL_YEAR, 'NEXT_FISCAL_YEAR'].includes(range)) {
    if (now.month() >= fiscalYearMonth) {
      return now
        .clone()
        .month(fiscalYearMonth)
        .subtract(1, 'month')
        .add(2, 'years')
        .add(rangeOffset, 'months');
    } else {
      return now
        .clone()
        .month(fiscalYearMonth)
        .add(3, 'years')
        .subtract(1, 'month')
        .add(rangeOffset, 'months');
    }
  }
  if (
    [
      ranges.NEXT_FISCAL_YEAR_FORWARD_ONE,
      'NEXT_FISCAL_YEAR_FORWARD_ONE',
    ].includes(range)
  ) {
    if (now.month() >= fiscalYearMonth) {
      return now
        .clone()
        .month(fiscalYearMonth)
        .add(3, 'year')
        .subtract(1, 'month')
        .add(rangeOffset, 'months');
    } else {
      return now
        .clone()
        .month(fiscalYearMonth)
        .add(2, 'years')
        .subtract(1, 'month')
        .add(rangeOffset, 'months');
    }
  }
  if (
    [
      ranges.NEXT_FISCAL_YEAR_FORWARD_TWO,
      'NEXT_FISCAL_YEAR_FORWARD_TWO',
    ].includes(range)
  ) {
    if (now.month() >= fiscalYearMonth) {
      return now
        .clone()
        .month(fiscalYearMonth)
        .add(4, 'year')
        .subtract(1, 'month')
        .add(rangeOffset, 'months');
    } else {
      return now
        .clone()
        .month(fiscalYearMonth)
        .add(3, 'years')
        .subtract(1, 'month')
        .add(rangeOffset, 'months');
    }
  }
  if (
    [
      ranges.NEXT_FISCAL_YEAR_FORWARD_THREE,
      'NEXT_FISCAL_YEAR_FORWARD_THREE',
    ].includes(range)
  ) {
    if (now.month() >= fiscalYearMonth) {
      return now
        .clone()
        .month(fiscalYearMonth)
        .add(5, 'year')
        .subtract(1, 'month')
        .add(rangeOffset, 'months');
    } else {
      return now
        .clone()
        .month(fiscalYearMonth)
        .add(4, 'years')
        .subtract(1, 'month')
        .add(rangeOffset, 'months');
    }
  }
  if (
    [
      ranges.NEXT_FISCAL_YEAR_FORWARD_FOUR,
      'NEXT_FISCAL_YEAR_FORWARD_FOUR',
    ].includes(range)
  ) {
    if (now.month() >= fiscalYearMonth) {
      return now
        .clone()
        .month(fiscalYearMonth)
        .add(6, 'year')
        .subtract(1, 'month')
        .add(rangeOffset, 'months');
    } else {
      return now
        .clone()
        .month(fiscalYearMonth)
        .add(5, 'years')
        .subtract(1, 'month')
        .add(rangeOffset, 'months');
    }
  }
  if ([ranges.NEXT_THREE_MONTHS, 'NEXT_THREE_MONTHS'].includes(range)) {
    return bookMonth.clone().add(3, 'month').add(rangeOffset, 'months');
  }
  if ([ranges.NEXT_SIX_MONTHS, 'NEXT_SIX_MONTHS'].includes(range)) {
    return bookMonth.clone().add(6, 'month').add(rangeOffset, 'months');
  }
  if ([ranges.NEXT_TWELVE_MONTHS, 'NEXT_TWELVE_MONTHS'].includes(range)) {
    return bookMonth.clone().add(12, 'month').add(rangeOffset, 'months');
  }
  if ([ranges.NEXT_CALENDAR_QUARTER, 'NEXT_CALENDAR_QUARTER'].includes(range)) {
    return bookMonth
      .clone()
      .startOf('quarter')
      .add(1, 'quarter')
      .add(2, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_CALENDAR_QUARTER_PLUS_ONE,
      'NEXT_CALENDAR_QUARTER_PLUS_ONE',
    ].includes(range)
  ) {
    return bookMonth
      .clone()
      .startOf('quarter')
      .add(1, 'quarter')
      .add(5, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_CALENDAR_QUARTER_PLUS_TWO,
      'NEXT_CALENDAR_QUARTER_PLUS_TWO',
    ].includes(range)
  ) {
    return bookMonth
      .clone()
      .startOf('quarter')
      .add(1, 'quarter')
      .add(8, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_CALENDAR_QUARTER_PLUS_THREE,
      'NEXT_CALENDAR_QUARTER_PLUS_THREE',
    ].includes(range)
  ) {
    return bookMonth
      .clone()
      .startOf('quarter')
      .add(1, 'quarter')
      .add(11, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_FOUR_FISCAL_QUARTERS,
      'NEXT_YEAR_FOUR_FISCAL_QUARTERS',
    ].includes(range)
  ) {
    return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
      .add(1, 'years')
      .add(11, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_FOUR_CALENDAR_QUARTERS,
      'NEXT_YEAR_FOUR_CALENDAR_QUARTERS',
    ].includes(range)
  ) {
    return bookMonth
      .clone()
      .endOf('year')
      .add(1, 'years')
      .startOf('month')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_ONE_TWELVE_FISCAL_MONTHS,
      'NEXT_YEAR_PLUS_ONE_TWELVE_FISCAL_MONTHS',
    ].includes(range)
  ) {
    return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
      .add(2, 'years')
      .add(11, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_ONE_TWELVE_CALENDAR_MONTHS,
      'NEXT_YEAR_PLUS_ONE_TWELVE_CALENDAR_MONTHS',
    ].includes(range)
  ) {
    return bookMonth
      .clone()
      .startOf('year')
      .add(2, 'years')
      .add(11, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_ONE_FOUR_FISCAL_QUARTERS,
      'NEXT_YEAR_PLUS_ONE_FOUR_FISCAL_QUARTERS',
    ].includes(range)
  ) {
    return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
      .add(2, 'years')
      .add(11, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_ONE_FOUR_CALENDAR_QUARTERS,
      'NEXT_YEAR_PLUS_ONE_FOUR_CALENDAR_QUARTERS',
    ].includes(range)
  ) {
    return bookMonth
      .clone()
      .endOf('year')
      .add(2, 'years')
      .startOf('month')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_TWO_TWELVE_FISCAL_MONTHS,
      'NEXT_YEAR_PLUS_TWO_TWELVE_FISCAL_MONTHS',
    ].includes(range)
  ) {
    return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
      .add(3, 'years')
      .add(11, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_TWO_TWELVE_CALENDAR_MONTHS,
      'NEXT_YEAR_PLUS_TWO_TWELVE_CALENDAR_MONTHS',
    ].includes(range)
  ) {
    return bookMonth
      .clone()
      .startOf('year')
      .add(3, 'years')
      .add(11, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_TWO_FOUR_FISCAL_QUARTERS,
      'NEXT_YEAR_PLUS_TWO_FOUR_FISCAL_QUARTERS',
    ].includes(range)
  ) {
    return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
      .add(3, 'years')
      .add(11, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_TWO_FOUR_CALENDAR_QUARTERS,
      'NEXT_YEAR_PLUS_TWO_FOUR_CALENDAR_QUARTERS',
    ].includes(range)
  ) {
    return bookMonth
      .clone()
      .endOf('year')
      .add(3, 'years')
      .startOf('month')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_THREE_TWELVE_FISCAL_MONTHS,
      'NEXT_YEAR_PLUS_THREE_TWELVE_FISCAL_MONTHS',
    ].includes(range)
  ) {
    return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
      .add(4, 'years')
      .add(11, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_THREE_TWELVE_CALENDAR_MONTHS,
      'NEXT_YEAR_PLUS_THREE_TWELVE_CALENDAR_MONTHS',
    ].includes(range)
  ) {
    return bookMonth
      .clone()
      .startOf('year')
      .add(4, 'years')
      .add(11, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_THREE_FOUR_FISCAL_QUARTERS,
      'NEXT_YEAR_PLUS_THREE_FOUR_FISCAL_QUARTERS',
    ].includes(range)
  ) {
    return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
      .add(4, 'years')
      .add(11, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_THREE_FOUR_CALENDAR_QUARTERS,
      'NEXT_YEAR_PLUS_THREE_FOUR_CALENDAR_QUARTERS',
    ].includes(range)
  ) {
    return bookMonth
      .clone()
      .endOf('year')
      .add(4, 'years')
      .startOf('month')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_FOUR_TWELVE_FISCAL_MONTHS,
      'NEXT_YEAR_PLUS_FOUR_TWELVE_FISCAL_MONTHS',
    ].includes(range)
  ) {
    return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
      .add(5, 'years')
      .add(11, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_FOUR_TWELVE_CALENDAR_MONTHS,
      'NEXT_YEAR_PLUS_FOUR_TWELVE_CALENDAR_MONTHS',
    ].includes(range)
  ) {
    return bookMonth
      .clone()
      .startOf('year')
      .add(5, 'years')
      .add(11, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_FOUR_FOUR_FISCAL_QUARTERS,
      'NEXT_YEAR_PLUS_FOUR_FOUR_FISCAL_QUARTERS',
    ].includes(range)
  ) {
    return getStartOfFiscalYear(bookMonth, fiscalYearMonth)
      .add(5, 'years')
      .add(11, 'months')
      .add(rangeOffset, 'months');
  }
  if (
    [
      ranges.NEXT_YEAR_PLUS_FOUR_FOUR_CALENDAR_QUARTERS,
      'NEXT_YEAR_PLUS_FOUR_FOUR_CALENDAR_QUARTERS',
    ].includes(range)
  ) {
    return bookMonth
      .clone()
      .endOf('year')
      .add(5, 'years')
      .startOf('month')
      .add(rangeOffset, 'months');
  }

  return now.clone().startOf('month').add(rangeOffset, 'months');
};

module.exports.getComparisonStartDate = function (
  startDate,
  endDate,
  comparison,
  customComparisonStartDate,
  now
) {
  const length = endDate.diff(startDate, 'month') + 1;
  switch (comparison) {
    case comparisons.PREVIOUS_PERIOD:
    case 'PREVIOUS_PERIOD':
      return startDate.clone().subtract(length, 'months');
    case comparisons.PREVIOUS_YEAR:
    case 'PREVIOUS_YEAR':
      return startDate.clone().subtract(1, 'year');
    case comparisons.CUSTOM:
    case 'CUSTOM':
      return customComparisonStartDate.clone();
    default:
      return startDate.clone();
  }
};

module.exports.getComparisonEndDate = function (
  startDate,
  endDate,
  comparison,
  customComparisonStartDate,
  now
) {
  const length = endDate.diff(startDate, 'month');
  switch (comparison) {
    case comparisons.PREVIOUS_PERIOD:
    case 'PREVIOUS_PERIOD':
      return startDate.clone().subtract(1, 'month');
    case comparisons.PREVIOUS_YEAR:
    case 'PREVIOUS_YEAR':
      return endDate.clone().subtract(1, 'year');
    case comparisons.CUSTOM:
    case 'CUSTOM':
      return customComparisonStartDate.clone().add(length, 'month');
    default:
      return endDate.clone();
  }
};

module.exports.getLabel = function (
  range,
  customStartDate,
  now,
  rangeOffset,
  fiscalYearMonth
) {
  const startDate = module.exports.getStartDate(
    range,
    customStartDate,
    now,
    rangeOffset,
    fiscalYearMonth
  );

  if (
    range === ranges.CURRENT_QUARTER ||
    range === 'CURRENT_QUARTER' ||
    range === ranges.LAST_QUARTER ||
    range === 'LAST_QUARTER'
  ) {
    return `Q${startDate.quarter()} ${startDate.year()}`;
  }

  if (range === ranges.YEAR_TO_DATE || range === 'YEAR_TO_DATE') {
    return 'YTD';
  }
  if (range === ranges.YEAR_TO_LAST_MONTH || range === 'YEAR_TO_LAST_MONTH') {
    return 'Year to Month';
  }
  if (range === ranges.FISCAL_YEAR_TO_DATE || range === 'FISCAL_YEAR_TO_DATE') {
    return 'Fiscal Year to Date';
  }
  if (
    range === ranges.FISCAL_YEAR_TO_LAST_MONTH ||
    range === 'FISCAL_YEAR_TO_LAST_MONTH'
  ) {
    return 'Fiscal Year to Last Month';
  }

  return null;
};

module.exports.formatRange = function (
  range,
  customStartDate,
  customEndDate,
  now,
  format,
  rangeOffset,
  fiscalYearMonth,
  datesOnly
) {
  if (!range) {
    return '';
  }

  const startDate = module.exports.getStartDate(
    range,
    customStartDate,
    now,
    rangeOffset,
    fiscalYearMonth
  );
  const endDate = module.exports.getEndDate(
    range,
    customStartDate,
    customEndDate,
    now,
    rangeOffset,
    fiscalYearMonth
  );

  if (
    !datesOnly &&
    [
      ranges.CURRENT_QUARTER,
      'CURRENT_QUARTER',
      ranges.LAST_QUARTER,
      'LAST_QUARTER',
      ranges.LAST_QUARTER_BACK_ONE,
      'LAST_QUARTER_BACK_ONE',
      ranges.LAST_QUARTER_BACK_TWO,
      'LAST_QUARTER_BACK_TWO',
      ranges.LAST_QUARTER_BACK_THREE,
      'LAST_QUARTER_BACK_THREE',
      ranges.LAST_QUARTER_BACK_FOUR,
      'LAST_QUARTER_BACK_FOUR',
      ranges.NEXT_CALENDAR_QUARTER,
      'NEXT_CALENDAR_QUARTER',
      ranges.NEXT_CALENDAR_QUARTER_PLUS_ONE,
      'NEXT_CALENDAR_QUARTER_PLUS_ONE',
      ranges.NEXT_CALENDAR_QUARTER_PLUS_TWO,
      'NEXT_CALENDAR_QUARTER_PLUS_TWO',
      ranges.NEXT_CALENDAR_QUARTER_PLUS_THREE,
      'NEXT_CALENDAR_QUARTER_PLUS_THREE',
      ranges.FINAL_CALENDAR_QUARTER,
      'FINAL_CALENDAR_QUARTER',
    ].includes(range)
  ) {
    return `Q${startDate.quarter()} ${startDate.year()}`;
  }

  if (
    range === ranges.CURRENT_YEAR ||
    range === 'CURRENT_YEAR' ||
    range === ranges.LAST_YEAR ||
    range === 'LAST_YEAR' ||
    range === ranges.LAST_YEAR_BACK_ONE ||
    range === 'LAST_YEAR_BACK_ONE' ||
    range === ranges.TWELVE_CALENDAR_MONTHS ||
    range === 'TWELVE_CALENDAR_MONTHS' ||
    range === ranges.TWELVE_CALENDAR_MONTHS_LAST_YEAR ||
    range === 'TWELVE_CALENDAR_MONTHS_LAST_YEAR' ||
    range === ranges.TWELVE_CALENDAR_MONTHS_LAST_YEAR_BACK_TWO ||
    range === 'TWELVE_CALENDAR_MONTHS_LAST_YEAR_BACK_TWO' ||
    range === ranges.NEXT_CALENDAR_YEAR ||
    range === 'NEXT_CALENDAR_YEAR' ||
    range === ranges.NEXT_YEAR_PLUS_ONE_TWELVE_CALENDAR_MONTHS ||
    range === 'NEXT_YEAR_PLUS_ONE_TWELVE_CALENDAR_MONTHS' ||
    range === ranges.NEXT_YEAR_PLUS_TWO_TWELVE_CALENDAR_MONTHS ||
    range === 'NEXT_YEAR_PLUS_TWO_TWELVE_CALENDAR_MONTHS' ||
    range === ranges.NEXT_YEAR_PLUS_THREE_TWELVE_CALENDAR_MONTHS ||
    range === 'NEXT_YEAR_PLUS_THREE_TWELVE_CALENDAR_MONTHS' ||
    range === ranges.NEXT_YEAR_PLUS_FOUR_TWELVE_CALENDAR_MONTHS ||
    range === 'NEXT_YEAR_PLUS_FOUR_TWELVE_CALENDAR_MONTHS' ||
    range === ranges.NEXT_YEAR_FOUR_CALENDAR_QUARTERS ||
    range === 'NEXT_YEAR_FOUR_CALENDAR_QUARTERS' ||
    range === ranges.NEXT_YEAR_PLUS_ONE_FOUR_CALENDAR_QUARTERS ||
    range === 'NEXT_YEAR_PLUS_ONE_FOUR_CALENDAR_QUARTERS' ||
    range === ranges.NEXT_YEAR_PLUS_TWO_FOUR_CALENDAR_QUARTERS ||
    range === 'NEXT_YEAR_PLUS_TWO_FOUR_CALENDAR_QUARTERS' ||
    range === ranges.NEXT_YEAR_PLUS_THREE_FOUR_CALENDAR_QUARTERS ||
    range === 'NEXT_YEAR_PLUS_THREE_FOUR_CALENDAR_QUARTERS' ||
    range === ranges.NEXT_YEAR_PLUS_FOUR_FOUR_CALENDAR_QUARTERS ||
    range === 'NEXT_YEAR_PLUS_FOUR_FOUR_CALENDAR_QUARTERS'
  ) {
    return startDate.year().toString();
  }

  if (startDate.isSame(endDate, 'month')) {
    return startDate.format(format);
  }
  return `${startDate.format(format)} - ${endDate.format(format)}`;
};

module.exports.formatComparison = function (
  range,
  customStartDate,
  customEndDate,
  now,
  format,
  rangeOffset,
  comparison,
  customComparisonStartDate,
  fiscalYearMonth
) {
  if (!range) {
    return '';
  }

  const startDate = module.exports.getStartDate(
    range,
    customStartDate,
    now,
    rangeOffset,
    fiscalYearMonth
  );
  const endDate = module.exports.getEndDate(
    range,
    customStartDate,
    customEndDate,
    now,
    rangeOffset,
    fiscalYearMonth
  );

  const comparisonStartDate = module.exports.getComparisonStartDate(
    startDate,
    endDate,
    comparison,
    customComparisonStartDate,
    now
  );

  const comparisonEndDate = module.exports.getComparisonEndDate(
    startDate,
    endDate,
    comparison,
    customComparisonStartDate,
    now
  );

  if (
    [
      ranges.CURRENT_QUARTER,
      'CURRENT_QUARTER',
      ranges.LAST_QUARTER,
      'LAST_QUARTER',
      ranges.LAST_QUARTER_BACK_ONE,
      'LAST_QUARTER_BACK_ONE',
      ranges.LAST_QUARTER_BACK_TWO,
      'LAST_QUARTER_BACK_TWO',
      ranges.LAST_QUARTER_BACK_THREE,
      'LAST_QUARTER_BACK_THREE',
      ranges.LAST_QUARTER_BACK_FOUR,
      'LAST_QUARTER_BACK_FOUR',
      ranges.NEXT_CALENDAR_QUARTER,
      'NEXT_CALENDAR_QUARTER',
      ranges.NEXT_CALENDAR_QUARTER_PLUS_ONE,
      'NEXT_CALENDAR_QUARTER_PLUS_ONE',
      ranges.NEXT_CALENDAR_QUARTER_PLUS_TWO,
      'NEXT_CALENDAR_QUARTER_PLUS_TWO',
      ranges.NEXT_CALENDAR_QUARTER_PLUS_THREE,
      'NEXT_CALENDAR_QUARTER_PLUS_THREE',
      ranges.FINAL_CALENDAR_QUARTER,
      'FINAL_CALENDAR_QUARTER',
    ].includes(range)
  ) {
    return `Q${comparisonStartDate.quarter()} ${comparisonStartDate.year()}`;
  }

  if (
    range === ranges.CURRENT_YEAR ||
    range === 'CURRENT_YEAR' ||
    range === ranges.LAST_YEAR ||
    range === 'LAST_YEAR' ||
    range === ranges.LAST_YEAR_BACK_ONE ||
    range === 'LAST_YEAR_BACK_ONE' ||
    range === ranges.TWELVE_CALENDAR_MONTHS ||
    range === 'TWELVE_CALENDAR_MONTHS' ||
    range === ranges.TWELVE_CALENDAR_MONTHS_LAST_YEAR ||
    range === 'TWELVE_CALENDAR_MONTHS_LAST_YEAR' ||
    range === ranges.TWELVE_CALENDAR_MONTHS_LAST_YEAR_BACK_TWO ||
    range === 'TWELVE_CALENDAR_MONTHS_LAST_YEAR_BACK_TWO' ||
    range === ranges.NEXT_CALENDAR_YEAR ||
    range === 'NEXT_CALENDAR_YEAR' ||
    range === ranges.NEXT_YEAR_PLUS_ONE_TWELVE_CALENDAR_MONTHS ||
    range === 'NEXT_YEAR_PLUS_ONE_TWELVE_CALENDAR_MONTHS' ||
    range === ranges.NEXT_YEAR_PLUS_TWO_TWELVE_CALENDAR_MONTHS ||
    range === 'NEXT_YEAR_PLUS_TWO_TWELVE_CALENDAR_MONTHS' ||
    range === ranges.NEXT_YEAR_PLUS_THREE_TWELVE_CALENDAR_MONTHS ||
    range === 'NEXT_YEAR_PLUS_THREE_TWELVE_CALENDAR_MONTHS' ||
    range === ranges.NEXT_YEAR_PLUS_FOUR_TWELVE_CALENDAR_MONTHS ||
    range === 'NEXT_YEAR_PLUS_FOUR_TWELVE_CALENDAR_MONTHS' ||
    range === ranges.NEXT_YEAR_FOUR_CALENDAR_QUARTERS ||
    range === 'NEXT_YEAR_FOUR_CALENDAR_QUARTERS' ||
    range === ranges.NEXT_YEAR_PLUS_ONE_FOUR_CALENDAR_QUARTERS ||
    range === 'NEXT_YEAR_PLUS_ONE_FOUR_CALENDAR_QUARTERS' ||
    range === ranges.NEXT_YEAR_PLUS_TWO_FOUR_CALENDAR_QUARTERS ||
    range === 'NEXT_YEAR_PLUS_TWO_FOUR_CALENDAR_QUARTERS' ||
    range === ranges.NEXT_YEAR_PLUS_THREE_FOUR_CALENDAR_QUARTERS ||
    range === 'NEXT_YEAR_PLUS_THREE_FOUR_CALENDAR_QUARTERS' ||
    range === ranges.NEXT_YEAR_PLUS_FOUR_FOUR_CALENDAR_QUARTERS ||
    range === 'NEXT_YEAR_PLUS_FOUR_FOUR_CALENDAR_QUARTERS'
  ) {
    return comparisonStartDate.year().toString();
  }

  if (comparisonStartDate.isSame(comparisonEndDate, 'month')) {
    return comparisonStartDate.format(format);
  }
  return `${comparisonStartDate.format(format)} - ${comparisonEndDate.format(
    format
  )}`;
};

module.exports.getCustomLiveDateRange = function (
  startDate,
  endDate,
  bookMonth
) {
  const now = bookMonth || moment.utc();

  return {
    startDate: startDate.clone(),
    endDate: endDate.clone(),
    range: 'CUSTOM',
    rangeOffset: startDate.diff(now.startOf('month'), 'months'),
  };
};
