import React from 'react';

import { Clearfix } from '../common-styled-components';
import CreateDatasheetForm from 'components/CreateDatasheetForm';
import CreateLegacyDatasheetForm from 'components/CreateLegacyDatasheetForm';

const CreateReachDatasheet = (props) => {
  const { onClose, legacy, showCreateModal, onCloseCreateModal } = props;

  const FormComponent = legacy
    ? CreateLegacyDatasheetForm
    : CreateDatasheetForm;

  return (
    <div>
      <FormComponent
        onCompleted={onClose}
        autocomplete="off"
        showCreateModal={showCreateModal}
        onCloseCreateModal={onCloseCreateModal}
      />
      <Clearfix />
    </div>
  );
};

export default CreateReachDatasheet;
