/* global fetch */
/**
 *
 * TrialBanner
 *
 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import gql from 'graphql-tag';
import Swal from 'sweetalert2';
import { Redirect } from 'react-router-dom';
import { API_URL } from 'environment';
import { useQuery } from '@apollo/client';
import useCompanyId from 'hooks/useCompanyId';
import useWorkspaceId from 'hooks/useWorkspaceId';

import Link from 'components/Link';
import Spinner from 'components/Spinner';
import Banner from 'components/Banner';
import { notificationToast } from 'utils/popups';

const SmallSpinner = styled(Spinner)`
  min-height: unset;
  padding: unset;
  position: unset;
  display: flex;
  justify-content: center;
  > div {
    margin-top: unset;
    margin-left: unset;
    position: unset;
    height: 12px;
    width: 12px;
    border-width: 3.3px;
  }
`;

const StyledLink = styled(Link)`
  &,
  &:link,
  &:visited {
    color: white;
    text-decoration: underline;
    font-weight: 300;
  }
`;
const RequestLink = styled.a`
  &,
  &:link,
  &:visited,
  &:hover {
    color: white;
    text-decoration: underline;
    font-weight: 300;
  }
`;
const PromoStyledLink = styled(StyledLink)`
  &,
  &:link,
  &:visited {
    text-decoration: none;
  }
`;

const QUERY = gql`
  query TrialBanner($companyId: Int!) {
    company(id: $companyId) {
      id
      trialEnds
      isBillingUser
      demo
    }
    currentUser {
      email
      totalCompaniesOwned
      hasTrialPromo
      promo {
        id
        bannerMessage
      }
      stripeSubscription {
        id
        hasPaymentMethod
      }
      salesRegistration
    }
  }
`;
const WORKSPACE_QUERY = gql`
  query TrialBanner($workspaceId: Int!) {
    workspace(id: $workspaceId) {
      id
      trialEnds
      isBillingUser
    }
    currentUser {
      email
      totalCompaniesOwned
      hasFreeCompany
      hasTrialPromo(workspaceId: $workspaceId)
      promo {
        id
        bannerMessage
      }
      stripeSubscription {
        id
        hasPaymentMethod
      }
      salesRegistration
    }
  }
`;

const sendRequest = (email) => {
  fetch(`${API_URL}/hubspot/request`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({
      email,
    }),
  }).then(() => {
    return notificationToast({
      title: 'Your request has been sent!',
      timer: 1500,
    });
  });
};

function BannerMessage(props) {
  const {
    days,
    bannerMessage,
    showPurchaseLinks,
    isOver,
    hasFreeCompany,
    email,
    showPromo,
    salesRegistration,
  } = props;

  if (bannerMessage) {
    return (
      <PromoStyledLink to={`/subscribe/promo`}>
        {bannerMessage.replace('{{daysInTrial}}', days)}
      </PromoStyledLink>
    );
  }

  if (isOver) {
    if (salesRegistration) {
      return  <StyledLink to="/subscribe">Click Here to Subscribe</StyledLink> 
    } else {
      return (
        <>
          Trial Over.{' '}
          <StyledLink to="/subscribe">Click Here to Subscribe</StyledLink> or{' '}
          <RequestLink onClick={() => sendRequest(email)}>
            Request an Extension
          </RequestLink>
          .
        </>
      );
    }
  }

  if (!days) {
    return <SmallSpinner />;
  }

  // return <SmallSpinner />;

  return (
    <>
      {days} days left in free trial.{' '}
      {days > 0 && showPurchaseLinks && showPromo && (
        <StyledLink to="/subscribe">
          {hasFreeCompany
            ? 'Pay now to get 30% off* and 1 company free for life.'
            : 'Pay now to get 30% off for 3 months'}
        </StyledLink>
      )}
      {days > 0 && showPurchaseLinks && !showPromo && (
        <StyledLink to="/subscribe">Pay Now</StyledLink>
      )}
    </>
  );
}

const TrialBanner = (props) => {
  const { className } = props;

  const companyId = useCompanyId();
  const workspaceId = useWorkspaceId();
  const query = companyId ? QUERY : WORKSPACE_QUERY;
  const variables = companyId ? { companyId } : { workspaceId };
  const isWorkspaceOnly = !!workspaceId && !companyId;

  const { data } = useQuery(query, {
    variables,
    skip: !workspaceId && !companyId,
  });

  const item = companyId ? data && data.company : data && data.workspace;

  if (!item) return null;

  const needsPaymentMethod =
    data.currentUser.stripeSubscription &&
    !data.currentUser.stripeSubscription.hasPaymentMethod;

  if (needsPaymentMethod) {
    return <Redirect to="/payment" />;
  }

  if (!item.trialEnds || item.demo) {
    return null;
  }
  const days =
    Math.max(0, moment.utc(item.trialEnds).diff(moment.utc(), 'days')) + 1;

  const isOver = moment.utc().isAfter(moment.utc(item.trialEnds));

  if (isOver && !item.isBillingUser) {
    return <Redirect to="/inactive" />;
  }
  if (isOver && item.isBillingUser && !isWorkspaceOnly) {
    if (data.currentUser.promo) {
      return <Redirect to="/subscribe/promo" />;
    }
    return <Redirect to="/subscribe" />;
  }

  if (!isOver && !item.isBillingUser) return null;

  return (
    <Banner className={className}>
      <BannerMessage
        bannerMessage={
          data.currentUser.promo && data.currentUser.promo.bannerMessage
        }
        isOver={isOver}
        showPurchaseLinks
        showPromo={data.currentUser.hasTrialPromo}
        days={days}
        hasFreeCompany={data.currentUser.hasFreeCompany}
        salesRegistration={data.currentUser.salesRegistration}
      />
    </Banner>
  );
};

export default TrialBanner;
