import { css, ThemedCssFunction, DefaultTheme } from 'styled-components';

interface Media {
  large: ThemedCssFunction<DefaultTheme>;
  desktop: ThemedCssFunction<DefaultTheme>;
  tablet: ThemedCssFunction<DefaultTheme>;
  phone: ThemedCssFunction<DefaultTheme>;
}

export const sizes = {
  large: 1366,
  desktop: 992,
  tablet: 768,
  phone: 576,
};

const media: Media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `;

  return acc;
}, {});

export default media;
