import React, { useEffect, useLayoutEffect } from 'react';
import { AlertContainer, MobileAlert } from './styledComponents';

interface Props {
  alerts: { id: number; title: string; message: string }[];
}

const Alerts = ({ alerts }: Props): React.ReactNode => {
  const [alertInnerHeight, setAlertInnerHeight] = React.useState(0);
  const alertInnerRef = React.useRef<HTMLDivElement>(null);
  const [closedNotifications, setClosedNotifications] = React.useState<
    number[]
  >([]);

  const getAlertInnerHeight = () => {
    if (alertInnerRef.current) {
      setAlertInnerHeight(alertInnerRef.current.clientHeight);
    }
  };

  useLayoutEffect(() => {
    function handleResize() {
      getAlertInnerHeight();
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [alertInnerRef]);

  useEffect(() => {
    getAlertInnerHeight();
  }, [alerts]);

  useEffect(() => {
    getAlertInnerHeight();
  }, [closedNotifications]);

  const onNotificationClose = (id: number) => {
    setClosedNotifications([...closedNotifications, id]);
  };

  const filteredAlerts = alerts.filter(
    (alert) => !closedNotifications.includes(alert.id)
  );

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <AlertContainer height={alertInnerHeight}>
      <div ref={alertInnerRef}>
        {filteredAlerts.map((alert) => (
          <MobileAlert key={alert.id}>
            <div>
              <h2>{alert.title}</h2>
              <button onClick={() => onNotificationClose(alert.id)}>
                <span className="zmdi zmdi-close" />
              </button>
            </div>
            <p>{alert.message}</p>
          </MobileAlert>
        ))}
      </div>
    </AlertContainer>
  );
};

export default Alerts;
