/**
 *
 * TrainingLinks
 *
 */
import React, { useState } from 'react';
import styled from 'styled-components';
import Empty from './Empty';
import HelpBlock from './HelpBlock';

import helpCenterImage from './help-center.png';
import videosImage from './videos.png';
import demoImage from './demo.png';
import TrainingVideos from 'modals/TraningVideos';
import media from 'media';
import useTrialEnds from 'hooks/useTrialEnds';
import useSavedState from 'hooks/useSavedState';
import ToggleSection from 'components/ToggleSection';

const HelpBlocks = styled.div`
  justify-content: center;
  padding-bottom: 10rem;
  display: none;
  ${media.phone`
    display: block;
  `}
  ${media.large`
    display: flex;
  `}
`;

const Div = styled.div`
  margin-top: 2rem;
`;

const Button = styled.button`
  color: ${(props) => props.theme.colors.link};
  font-size: 0.75rem;
`;

const TrainingLinks = (props) => {
  const { onAddCompany, empty, sharedWithMe, canAdd, phone } = props;
  const { trialEnds, loading } = useTrialEnds();
  const [open, setOpen] = useSavedState('learnMoreOpen', true);

  const [showVideos, setShowVideos] = useState(false);

  if (loading) return null;

  return (
    <div>
      {empty && (
        <Empty
          onAddCompany={onAddCompany}
          sharedWithMe={sharedWithMe}
          canAdd={canAdd}
        />
      )}
      {!phone && (
        <Div>
          <Button onClick={() => setOpen(!open)}>
            <i className={`mdi-set mdi-chevron-${open ? 'down' : 'right'}`} />{' '}
            Learn More
          </Button>
          <ToggleSection show={open}>
            <HelpBlocks>
              {trialEnds ? (
                <HelpBlock
                  image={demoImage}
                  title="Let us help you get started"
                  description="Let us walk you through. Schedule a demo today."
                  link="https://knowledge.reachreporting.com/meetings/arthurboutin/reach-reporting-zoom-demo-app"
                  external
                  buttonText="Schedule a Demo"
                />
              ) : (
                <HelpBlock
                  image={helpCenterImage}
                  title="Have some questions you need answered?"
                  description="Visit our help center to view articles or send us a chat."
                  link="https://knowledge.reachreporting.com"
                  external
                  buttonText="Visit Help Center"
                  buttonId="visit-help-center-button"
                />
              )}
              <HelpBlock
                image={videosImage}
                title="Want to know how to do something?"
                description="Check out our library of tutorial videos to help answer any of your questions."
                buttonText="View Video Tutorials"
                buttonId="view-video-tutorials-button"
                onClick={() => setShowVideos(true)}
              />
            </HelpBlocks>
            <TrainingVideos
              onClose={() => setShowVideos(false)}
              show={showVideos}
            />
          </ToggleSection>
        </Div>
      )}
    </div>
  );
};

export default TrainingLinks;
