import { Variants } from 'framer-motion';
import { DETAILS_PADDING } from './styledComponents';

export const detailsVariants = (descHeight: number): Variants => ({
  rest: {
    bottom: -(descHeight + DETAILS_PADDING),
    borderTop: '0px solid #4fbade',
    transition: {
      bottom: {
        type: 'spring',
        stiffness: 300,
        damping: 20,
      },
      borderTop: {
        ease: 'linear',
      },
    },
  },
  hover: {
    bottom: 0,
    borderTop: '5px solid #4fbade',
    transition: {
      bottom: {
        ease: 'easeOut',
      },
      borderTop: {
        ease: 'linear',
      },
    },
  },
});

export const thumbnailVariants = {
  rest: {
    scale: 1,
    transition: {
      scale: {
        type: 'spring',
        stiffness: 300,
        damping: 20,
      },
    },
  },
  hover: {
    scale: 1.1,
    transition: {
      scale: {
        ease: 'easeOut',
      },
    },
  },
};
