/**
 *
 * HideHubspot
 *
 */
import { useEffect } from 'react';

const HideHubspot = () => {
  useEffect(() => {
    const styleEl = document.createElement('style');

    styleEl.innerHTML =
      '#hubspot-messages-iframe-container,#hubspot-messages-iframe-container.widget-align-right {display: none!important;}';

    document.body.insertBefore(styleEl, document.body.firstChild);

    return () => {
      document.body.removeChild(styleEl);
    };
  }, []);

  return null;
};

export default HideHubspot;
