/**
 *
 * RegisterPlan
 *
 */
import React, { useState, useCallback, useEffect } from 'react';
import RegisterPlanForm from 'components/RegisterPlanForm';

import usePricingPlan from 'hooks/usePricingPlan';
import { useHistory } from 'react-router-dom';

const RegisterPlan = props => {
  const { match } = props;
  const [page, setPage] = useState('register');
  const history = useHistory();

  const pricingPlan = usePricingPlan({
    name: match.params.planName,
    // skip: page !== 'checkout',
  });

  const handleCompleteRegister = useCallback(() => {
    setPage('checkout');
  }, []);

  useEffect(() => {
    if (pricingPlan && page === 'checkout') {
      window.sessionStorage.setItem(
        'completeRedirect',
        `/subscribe/plan/${pricingPlan.id}/${match.params.interval}/presignup`
      );
      history.push('/register/complete');
    }
  }, [pricingPlan, history, match.params.interval, page]);

  if (page === 'register') {
    return (
      <RegisterPlanForm
        planName={match.params.planName}
        isAnnual={match.params.interval === 'annual'}
        onSuccess={handleCompleteRegister}
        pricingGroup={pricingPlan && pricingPlan.group}
      />
    );
  }

  return null;
};

export default RegisterPlan;
