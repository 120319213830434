import { useMutation, useSubscription } from '@apollo/client';
import { useCallback, useRef, useState } from 'react';
import { captureException } from 'utils/sentry';
import { BUDGET_JOB_FINISHED, VALIDATE } from './mutations';

export function useValidateExcel({ companyId, onSetFile }) {
  const [validate] = useMutation(VALIDATE);
  const [waitingForSub, setWaitingForSub] = useState(false);
  const [validationErrors, setValidationErrors] = useState(null);
  const fileRef = useRef(null);

  const onData = useCallback(
    ({
      data: {
        data: {
          budgetJobFinished: { validation },
        },
      },
    }) => {
      if (!validation || !fileRef.current) return;

      if (validation.valid) {
        onSetFile(fileRef.current);
      } else {
        setValidationErrors({
          errors: validation.errors,
          empty: validation.empty,
          missingColumns: validation.missingColumns,
        });
      }

      setWaitingForSub(false);
    },
    [onSetFile]
  );

  useSubscription(BUDGET_JOB_FINISHED, {
    variables: {
      companyId,
    },
    onData,
  });

  const validateExcel = useCallback(
    async (e) => {
      try {
        setValidationErrors(null);
        const files = e.target.files;
        if (!files.length) return;
        const file = files[0];
        fileRef.current = file;
        setWaitingForSub(true);
        await validate({
          variables: {
            companyId,
            excelFile: file,
          },
        });
      } catch (err) {
        setWaitingForSub(false);
        captureException(err, true);
      }
    },
    [companyId, validate]
  );

  return { validateExcel, loading: waitingForSub, validationErrors };
}
