/**
 *
 * StripePortalButton
 *
 */
import React, { useCallback } from 'react';
import { API_URL } from 'environment';

const StripePortalButton = (props) => {
  const { text = 'Billing/Plan', className, Button } = props;

  // callback to fetch stripe portal url instead
  // of submitting form
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    const res = await fetch(`${API_URL}/stripe/portal`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ returnUrl: window.location.href }),
      credentials: 'include',
    });

    const { url } = await res.json();

    window.location.href = url;
  }, []);

  return Button ? (
    React.cloneElement(
      <Button>
        <i className="mdi-set mdi-credit-card" />
        {text}
      </Button>,
      {
        type: 'submit',
        className,
        onClick: handleSubmit,
      }
    )
  ) : (
    <button className={className} type="button" onClick={handleSubmit}>
      {text}
    </button>
  );
};

export default StripePortalButton;
