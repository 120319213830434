import React from 'react';

const ExcelFileIcon = ({
  color = 'white', //#617182
  width = '24px',
  height = '24px',
  cursor,
  onClick,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  cursor?: string;
  onClick?: (any) => void;
}) => (
  <svg width={width} height={height} viewBox="0 0 60.77 71.96">
    <path
      fill={color}
      d="M36.63,2H10.53A6.55,6.55,0,0,0,4,8.53V60.74a6.55,6.55,0,0,0,6.53,6.52H49.68a6.55,6.55,0,0,0,6.53-6.52V21.58ZM33.37,6.89l17.94,18H33.37ZM16.18,43.06H11.71v1.82h4v2.29h-4V49h4.47v2.29H9.32V40.76h6.86Zm7.39,8.23-2.1-3.47-2.1,3.47H16.43l3.45-5.49-3.2-5h2.93l1.86,3.14,1.86-3.14h2.94l-3.2,5,3.45,5.49Zm12.19-1.7A5.27,5.27,0,0,1,33.93,51a5.54,5.54,0,0,1-2.36.49,5.61,5.61,0,0,1-2.18-.42,5.33,5.33,0,0,1-2.9-2.9,5.79,5.79,0,0,1,0-4.33,5.46,5.46,0,0,1,5.08-3.32,5.36,5.36,0,0,1,2.22.44,5.3,5.3,0,0,1,1.71,1.28l.17.18L34,44.08l-.17-.2a3.36,3.36,0,0,0-1-.77,3.17,3.17,0,0,0-2.48,0,3,3,0,0,0-1,.64,3.08,3.08,0,0,0-.67,1A3.54,3.54,0,0,0,28.46,46a3.49,3.49,0,0,0,.24,1.32,3.24,3.24,0,0,0,.67,1,3,3,0,0,0,1,.63,3.26,3.26,0,0,0,2.6-.08A3.4,3.4,0,0,0,34,48l.17-.21,1.71,1.62Zm7.93-6.53H39.22v1.82h4v2.29h-4V49h4.47v2.29H36.83V40.76h6.86Zm7.87,8.23H44.92V40.76h2.39V49h4.25Z"
    />
  </svg>
);

export default ExcelFileIcon;
