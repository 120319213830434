/**
 *
 * MultiAccountSelect
 *
 */
import React from 'react';
import styled from 'styled-components';
import Select from 'components/Select';

const StyledSelect = styled(Select)`
  width: 400px;
  && input {
    height: 35px;
  }
`;

const styles = {
  valueContainer: (provided, state) => {
    return {
      ...provided,
      display: state.hasValue ? 'block' : 'flex',
      maxHeight: 'calc(100vh - 385px)',
      overflowY: 'auto',
    };
  },
  multiValue: (provided) => ({
    ...provided,
    justifyContent: 'space-between',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    justifyContent: 'space-between',
    transform: 'scale(1) translateX(-11px)',
  }),
};

const MultiAccountSelect = (props) => {
  return <StyledSelect menuPlacement="bottom" {...props} styles={styles} />;
};

export default MultiAccountSelect;
