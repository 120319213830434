import fullHeaderLogo from 'images/logo-white.png';
import favicon from 'images/icon-white.png';
import ieHeaderLogo from 'images/logo-white.png?size=160';

import colors from './darkColors';

import { detect } from 'detect-browser';
const browser = detect();

let headerLogo = fullHeaderLogo;
if (browser && browser.name === 'ie') {
  headerLogo = ieHeaderLogo;
}

export default {
  mode: 'dark',
  body: {
    // When changing the background theme color, make sure to update the colors in the following files:
    // - frontend\src\utils\theme.ts
    // - frontend\src\index.html
    background: '#181922',
    color: 'hsla(0,0%,100%,.8)',
    border: '#35394A',
  },
  colors: {
    icon: '#d8dadf',
    link: '#5c95c5',
    gear: '#D1D1D3',
  },
  content: {
    marginX: 30,
    sidebarWidth: 200,
  },
  table: {
    header: {
      background: '#494d5b',
      color: '#ffffff',
      sortedBackground: '#30313a',
    },
    row: {
      color: '#ffffff',
      hover: '#313139',
    },
  },
  headings: {
    color: 'hsla(0,0%,100%,.8)',
  },
  header: {
    logo: headerLogo,
    backgroundHover: '#303442',
    background: colors.headerPrimaryBackground,
    height: '60px',
    color: colors.headerPrimaryColor,
    hoverColor: colors.headerPrimaryHoverColor,
    buttonHighlight: colors.headerButtonHoverBackground,
  },
  minimalHeader: {
    background: '#222532',
    color: 'hsla(0,0%,100%,.8)',
    hoverColor: '#fff',
    logo: headerLogo,
    backgroundHover: '#303442',
  },
  workspaceHeader: {
    background: '#222532',
    color: 'hsla(0,0%,100%,.8)',
  },
  panel: {
    background: '#222532',
    titleBorder: 'none',
    boxShadow: '0 1px 20px 0 rgba(0,0,0,.1)',
  },
  sidebar: {
    width: '60px',
    boxShadow: '0 1px 20px 0 rgba(0,0,0,.3)',
    background: colors.primaryBackground,
    color: colors.primaryText,
    hoverColor: colors.hoverText,
    favicon: favicon.src,
    buttonHighlight: colors.hoverButton,
    buttonActive: colors.activeButton,
    logo: fullHeaderLogo,
    syncColor: colors.syncIcon,
    syncHoverColor: colors.hoverSyncIcon,
    syncContainerColor: colors.syncContainer,
    syncContainerHoverColor: colors.hoverSyncContainer,
  },
  dropdown: {
    background: '#161822',
    boxShadow: 'none',
    color: '#d3d3d6',
    scrollbarBackground: '#eeeeee',
    scrollbar: '#617182',
  },
  modal: {
    color: '#617182',
  },
  formControl: {
    background: '#212532',
    border: 'none',
    color: '#d7d9de',
    placeholder: '#6f727c',
    focus: {
      border: 'none',
      background: '#212532',
    },
  },
  select: {
    background: '#313139',
    color: '#d7d9de',
    hoverBackground: '#40424b',
    openBackground: '#40424b',
    selectedBackground: '#40424b',
    hoverColor: '#d7d9de',
    borderColor: null,
    outline: null,
    boxShadow: null,
  },
  datePicker: {
    color: colors.datePickerColor,
    toggleBackgroundColor: colors.inputBackgroundColor,
    toggleBackgroundColorHover: colors.inputBackgroundHoverColor,
    selectedBackgroundColor: colors.inputBackgroundColor,
    selectedBackgroundHoverColor: colors.inputBackgroundHoverColor,
    keyboardSelectedBackgroundColor:
      colors.inputKeyboardSelectedBackgroundColor,
    launcherColor: colors.linkColor,
  },
  tableKpiBuilder: {
    addButtonColor: '#fff',
    addButtonIconColor: '#333',
  },
  spreadsheet: {
    input: {
      backgroundColor: '#222532',
    },
  },
  reportBuilder: {
    header: {
      background: colors.headerBackground,
      toolbarBackground: colors.toolbarBackground,
    },
    sidebar: {
      background: colors.sidebarBackground,
      color: colors.primaryText,
      hoverColor: colors.hoverText,
      hoverBackground: colors.hoverButton,
      activeBackground: colors.activeDrawerBackground,
      activeColor: colors.primaryText,
    },
    drawers: {
      background: colors.activeDrawerBackground,
      color: colors.primaryText,
      disabledColor: colors.disabledDrawerText,
      activeColor: colors.primaryText,
      hoverColor: colors.hoverText,
      kpiThumbnailBackground: colors.kpiThumbnailBackground,
      hoverKpiThumbnailBackground: colors.hoverKpiThumbnailBackground,
      buttonColor: colors.primaryBackground,
      buttonSecondaryColor: colors.activeDrawerBackground,
      itemColor: colors.drawerItemColor,
      itemSecondaryColor: colors.drawerItemSecondaryColor,
      hoverItemColor: colors.hoverDrawerItemColor,
      hoverItemTextColor: colors.hoverDrawerItemTextColor,
      tabText: colors.drawerTabText,
      activeTabText: colors.drawerTabActiveText,
      cornerButton: colors.drawerCornerButton,
      hoverCornerButton: colors.hoverDrawerCornerButton,
    },
  },
  clientPortal: {
    fileUploader: {
      color: colors.fileUploaderPrimaryColor,
    },
    viewButton: {
      activeColor: '#FFFFFF',
      activeBackground: '#617182',
      color: '#FFFFFF',
      background: 'unset',
      border: '#313139',
    },
  },
  button: {
    default: {
      base: '#21222d',
      color: 'hsla(0,0%,100%,.8)',
      hoverColor: 'white',
      outlineColor: 'hsla(0,0%,100%,.8)',
      outlineBorder: '#617182',
      hover: '#393a44',
      hoverBg: '#393a44',
      outlineHover: 'white',
      border: 'transparent',
      disabled: 'hsla(0,0%,100%,.3)',
    },
    secondary: {
      outlineColor: 'hsla(0,0%,100%,.8)',
    },
  },
  chartStyles: {
    chart: {
      backgroundColor: 'transparent',
    },
    title: {
      style: {
        color: 'hsla(0,0%,100%,.8)',
      },
    },
    subtitle: {
      style: {
        color: 'hsla(0,0%,100%,.8)',
      },
    },
    plotOptions: {
      bar: {
        borderWidth: 0,
      },
      column: {
        borderWidth: 0,
      },
      pie: {
        borderWidth: 1,
        borderColor: null,
      },
      series: {
        dataLabels: {
          style: {
            color: 'white',
          },
        },
      },
    },
    legend: {
      itemStyle: {
        color: 'hsla(0,0%,100%,.8)',
      },
      itemHoverStyle: {
        color: '#fff',
      },
      itemHiddenStyle: {
        color: '#666',
      },
      title: {
        style: {
          color: 'hsla(0,0%,100%,.8)',
        },
      },
    },
    xAxis: {
      lineColor: 'transparent',
      tickColor: '#666',
      labels: {
        style: {
          color: 'hsla(0,0%,100%,.8)',
        },
      },
    },
    yAxis: {
      gridLineColor: '#666',
      labels: {
        style: {
          color: 'hsla(0,0%,100%,.8)',
        },
      },
    },
  },
  dropdownDrawer: {
    backgroundColor: colors.dropdownDrawerBackground,
    expandedBackgroundColor: colors.dropdownDrawerExpandedBackground,
    color: colors.dropdownDrawerColor,
    scrollFadeBackground: colors.scrollBarFade,
    borders: colors.dropdownDrawerExpandedBackground,
  },
  metricLibrary: {
    subSectionBackground: colors.subSectionBackground,
    subSectionColor: colors.subSectionColor,
  },
  scrollBar: {
    track: colors.scrollBarTrack,
    thumb: colors.scrollBarThumb,
  },
  searchBar: {
    backgroundColor: '#212532',
    color: '#d7d9de',
    placeholderColor: '#6f727c',
  },
  horizontalTabs: {
    color: '#617182',
    activeColor: '#FFFFFF',
    borderColor: '#617182',
    activeBorderColor: '#FFFFFF',
  },
  tableEditor: {
    header: {
      backgroundColor: '#3D3E50',
    },
    table: {
      cell: {
        actualBackgroundColor: '#637680',
        varianceBackgroundColor: '#5E616C',
        customValueBackgroundColor: '#919272',
        selectionOverlay: 'rgba(163, 255, 251, 0.07)',
      },
    },
    contextMenu: {
      boxShadow: 'rgb(0, 0, 0, 0.46)',
    },
    borderColor: '#666666',
  },
  maintenanceBanner: {
    backgroundColor: '#494D5B',
  },
};
