import { gql } from '@apollo/client';

export const GET_COMPANY_FOLDERS = gql`
  query getCompanyFolders($companyId: Int!, $userId: Int) {
    company(id: $companyId) {
      id
      usedStorage
      storageCapacity
      folders(userId: $userId) {
        id
        name
      }
    }
  }
`;

export const GET_PORTAL_STORAGE = gql`
  query getPortalStorage($companyId: Int!) {
    clientPortal(companyId: $companyId) {
      id
      usedStorage
      storageCapacity
    }
  }
`;

export const UPDATE_COMPANY_FOLDERS = gql`
  fragment updateCompanyFolders on Company {
    id
    folders {
      id
      name
    }
  }
`;

export const GET_CLIENT_PORTAL_USERS = gql`
  query ClientPortalUsers($companyId: Int!) {
    company(id: $companyId) {
      id
      clientPortalUsers {
        id
        fullName
        email
        clientPortalOnly
      }
    }
  }
`;

export const CONTENT_QUERY = gql`
  query FolderContents($folderId: Int!, $companyId: Int!, $theme: Theme!) {
    getFolderContents(folderId: $folderId, companyId: $companyId) {
      id
      dashboards {
        id
        name
        thumbnail(theme: $theme)
        createdAt
        lastModified
      }
      reports {
        id
        name
        signedUrl
        createdAt
        lastModified
        size
      }
      files {
        id
        name
        signedUrl
        createdAt
        lastModified
        size
      }
      budgets {
        id
        name
        createdAt
        lastModified
        status
        viewOnly
        locked
      }
      users {
        id
        fullName
        email
      }
    }
  }
`;

export const CONTENT_QUERY_FILES_FRAGMENT = gql`
  fragment FolderFiles on FolderContent {
    id
    files {
      id
      name
      signedUrl
      createdAt
      size
    }
  }
`;

export const FOLDER_ITEMS_QUERY = gql`
  query FolderContents($folderId: Int!, $companyId: Int!) {
    getFolderContents(folderId: $folderId, companyId: $companyId) {
      id
      files {
        id
        name
        signedUrl
        createdAt
        size
      }
    }
  }
`;

export const FOLDER_USERS_QUERY = gql`
  query FolderContents($folderId: Int!, $companyId: Int!) {
    getFolderContents(folderId: $folderId, companyId: $companyId) {
      id
      users {
        id
        fullName
        email
      }
    }
  }
`;

export const UPDATE_FOLDERS_USERS = gql`
  fragment updateFolderUsers on FolderContent {
    id
    users {
      id
      fullName
      email
    }
  }
`;

export const INVITE = gql`
  mutation InviteCompanyUser(
    $companyId: Int!
    $email: Email!
    $firstName: String!
    $lastName: String!
    $folderIds: [Int!]
  ) {
    inviteCompanyUser(
      companyId: $companyId
      email: $email
      firstName: $firstName
      lastName: $lastName
      folderIds: $folderIds
    ) {
      success
      error
      invite {
        id
        user {
          id
          email
          fullName
          ... on OtherUser {
            inviteLink
          }
          clientPortalOnly
        }
        folderIds
        companyId
        link
      }
    }
  }
`;

export const RESEND_INVITE = gql`
  mutation ResendCompanyInvite($companyId: Int!, $userId: Int!) {
    resendCompanyInvite(companyId: $companyId, userId: $userId) {
      success
      error
    }
  }
`;

export const REMOVE_INVITE = gql`
  mutation RemoveCompanyInvite($inviteId: Int!) {
    removeCompanyInvite(inviteId: $inviteId) {
      success
      error
    }
  }
`;

export const UPDATE_INVITE_FOLDERS = gql`
  mutation UpdateInviteFolderAccess($inviteId: Int!, $folderIds: [Int!]!) {
    updateInviteFolderAccess(inviteId: $inviteId, folderIds: $folderIds) {
      success
      error
      invite {
        id
        folderIds
      }
    }
  }
`;

export const REMOVE_PORTAL_USER = gql`
  mutation RemoveClientPortalUser($companyId: Int!, $userId: Int!) {
    removeClientPortalUser(companyId: $companyId, userId: $userId) {
      success
      error
      user {
        id
        fullName
      }
    }
  }
`;

/* Client Portal Folder Queries */

// Gets all folders accessible by a user by user ID
export const USER_FOLDER_QUERY = gql`
  query UsersFolders($userId: Int!, $companyId: Int!) {
    getUsersFolders(userId: $userId, companyId: $companyId) {
      userId
      folders {
        id
        name
      }
    }
  }
`;

// Updates folders accessible by a user
export const UPDATE_USER_FOLDERS = gql`
  mutation UpdateUserFolders(
    $userId: Int!
    $addFolderIds: [Int!]!
    $removeFolderIds: [Int!]!
    $companyId: Int!
  ) {
    updateUserFolders(
      userId: $userId
      addFolderIds: $addFolderIds
      removeFolderIds: $removeFolderIds
      companyId: $companyId
    ) {
      success
      error
      user {
        id
        email
        fullName
      }
      addedFolders {
        id
        name
      }
      removedFolders {
        id
      }
    }
  }
`;

// Adds users that can access a single folder
export const UPDATE_FOLDER_USERS = gql`
  mutation UpdateFolderUsers(
    $folderId: Int!
    $addUserIds: [Int!]!
    $removeUserIds: [Int!]!
  ) {
    updateFolderUsers(
      folderId: $folderId
      addUserIds: $addUserIds
      removeUserIds: $removeUserIds
    ) {
      success
      error
      folderId
      addedUsers {
        id
        email
        fullName
      }
      removedUsers {
        id
      }
    }
  }
`;

export const NEW_FOLDER = gql`
  mutation AddNewFolderToCompany($companyId: Int!, $folderName: String!) {
    addNewFolder(companyId: $companyId, name: $folderName) {
      success
      error
      folder {
        id
        name
      }
    }
  }
`;

export const NEW_FOLDER_FRAGMENT = gql`
  fragment NewFolder on Folder {
    id
    name
  }
`;

export const RENAME_FOLDER = gql`
  mutation RenameFolder($folderId: Int!, $name: String!) {
    renameFolder(folderId: $folderId, name: $name) {
      id
      name
    }
  }
`;

export const REMOVE_FOLDER = gql`
  mutation RemoveFolderFromCompany($folderId: Int!) {
    removeFolder(folderId: $folderId)
  }
`;

export const GET_ITEM_FOLDERS = gql`
  query GetItemFolders($itemId: Int!, $itemType: Int!, $companyId: Int!) {
    itemFolders(itemId: $itemId, itemType: $itemType, companyId: $companyId) {
      itemId
      itemType
      folders {
        id
        name
      }
    }
  }
`;

export const UPDATE_ITEM_FOLDERS = gql`
  mutation UpdateItemFolders(
    $addFolderIds: [Int!]!
    $removeFolderIds: [Int!]!
    $itemId: Int!
    $itemType: Int!
    $theme: Theme!
    $viewOnly: Boolean
  ) {
    updateItemFolders(
      addFolderIds: $addFolderIds
      removeFolderIds: $removeFolderIds
      itemId: $itemId
      itemType: $itemType
      theme: $theme
      viewOnly: $viewOnly
    ) {
      success
      error
      itemId
      itemType
      item
      viewOnly
      locked
      addedFolders {
        id
        name
      }
      removedFolders {
        id
      }
    }
  }
`;

export const UPDATE_FOLDER_ITEMS_FRAGMENT = gql`
  fragment UpdateFolderItems on FolderContent {
    id
    dashboards {
      id
      name
      thumbnail(theme: $theme)
    }
    reports {
      id
      name
      signedUrl
      createdAt
      size
    }
    files {
      id
      name
      signedUrl
      createdAt
      size
    }
    budgets {
      id
      name
      createdAt
    }
  }
`;

export const UPDATE_ITEM_FOLDERS_FRAGMENT = gql`
  fragment UpdateItemFoldersFragment on ItemFoldersResult {
    itemId
    itemType
    folders {
      id
      name
    }
  }
`;

export const RENAME_REPORT = gql`
  mutation RenameClientReport($reportId: Int!, $name: String!) {
    renameFolderReport(reportId: $reportId, name: $name) {
      success
      error
      report {
        id
        name
      }
    }
  }
`;

export const RENAME_FILE = gql`
  mutation RenameClientFile($fileId: Int!, $name: String!) {
    renameFolderFile(fileId: $fileId, name: $name) {
      success
      error
      file {
        id
        name
      }
    }
  }
`;

export const REMOVE_ITEM = gql`
  mutation removeItem(
    $itemId: Int!
    $itemType: Int!
    $folderId: Int!
    $companyId: Int!
  ) {
    removeItem(
      itemId: $itemId
      itemType: $itemType
      folderId: $folderId
      companyId: $companyId
    ) {
      success
      error
      itemId
      itemType
      folders {
        id
        name
      }
      usedStorage
    }
  }
`;
