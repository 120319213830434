/**
 *
 * BudgetsConsolidationModal
 *
 */
import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { captureException } from 'utils/sentry';
import { get } from 'lodash';
import gql from 'graphql-tag';
import useConsolidationCompanies from 'hooks/useConsolidationCompanies';
import useCompanyId from 'hooks/useCompanyId';
import usePrevious from 'hooks/usePrevious';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';

import CompanyBudgetSelect from './CompanyBudgetSelect';
import {
  CompanyGroup,
  ErrorText,
  Scroll,
  SelectWrapper,
  SourceGroup,
} from './styledComponents';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import Select from 'components/Select';
import { notificationToast } from 'utils/popups';
import useAccountingIntegrationId from 'hooks/useAccountingIntegrationId';
import HideScrollbar from 'components/HideScrollbar';

const GET_CONSOLIDATION_BUDGETS = gql`
  query ConsolidationBudgets($companyId: Int!) {
    company(id: $companyId) {
      id
      consolidationBudgets {
        childCompanyId
        budgetId
        budgetType
      }
    }
  }
`;

const SAVE = gql`
  mutation UpdateConsolidationBudgets(
    $companyId: Int!
    $consolidationBudgets: [ConsolidationBudgetInput!]!
  ) {
    updateConsolidationBudgets(
      companyId: $companyId
      consolidationBudgets: $consolidationBudgets
    ) {
      id
      consolidationBudgets {
        childCompanyId
        budgetId
        budgetType
      }
    }
  }
`;

interface BudgetsConsolidationModalProps {
  show: boolean;
  onClose: () => void;
}

const BudgetsConsolidationModal: React.FC<BudgetsConsolidationModalProps> = ({
  show,
  onClose,
}) => {
  const companyId = useCompanyId();
  const consolidationCompanies = useConsolidationCompanies(companyId);
  const accountingIntegration = useAccountingIntegrationId();
  const { reset, getValues, handleSubmit, control, setValue } = useForm();

  const [showRequiredError, setShowRequired] = useState(false);

  const sourceOptions = useMemo(() => {
    const opt = [{ value: 'COMPANY', label: 'Reach Reporting' }];
    if (accountingIntegration !== 'CSV' && accountingIntegration !== 'MYOB') {
      opt.push({ value: 'INTEGRATION', label: accountingIntegration });
    }
    return opt;
  }, [accountingIntegration]);

  const [source, setSource] = useState('COMPANY');

  const handleChangeSource = useCallback(
    (newSource) => {
      setSource(newSource);
      consolidationCompanies.forEach((company) => {
        setValue(company.id.toString(), null);
      });
    },
    [consolidationCompanies, setValue]
  );

  const prevShow = usePrevious(show);
  useEffect(() => {
    if (prevShow === false && show === true) {
      setShowRequired(false);
    }
  }, [prevShow, show]);

  const { data, loading, error } = useQuery(GET_CONSOLIDATION_BUDGETS, {
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  useEffect(() => {
    if (data && data.company && data.company.consolidationBudgets.length) {
      setSource(data.company.consolidationBudgets[0].budgetType);
    }
  }, [data]);

  const [save, { loading: loadingSave }] = useMutation(SAVE);

  /**
   * Parses form data and saves to database
   */
  const handleSave = useCallback(async () => {
    try {
      const values = getValues();
      const consolidationBudgets: Array<{
        childCompanyId: number;
        budgetId: number;
        budgetType: string;
      }> = [];
      Object.keys(values).forEach((key) => {
        consolidationBudgets.push({
          childCompanyId: parseInt(key),
          budgetId: values[key].budgetId,
          budgetType: values[key].budgetType,
        });
      });

      if (consolidationBudgets.some((budget) => !budget.budgetId)) {
        return setShowRequired(true);
      }

      setShowRequired(false);

      const { data: saveData } = await save({
        variables: {
          companyId,
          consolidationBudgets,
        },
      });
      if (saveData && saveData.updateConsolidationBudgets) {
        notificationToast({
          title: 'Saved',
          timer: 1500,
        });

        onClose();
        window.location.reload();
      }
    } catch (err) {
      captureException(err, true);
    }
  }, [companyId, getValues, onClose, save]);

  /**
   * Handles submit event
   */
  const onSubmit = useMemo(
    () => handleSubmit(handleSave),
    [handleSave, handleSubmit]
  );

  /**
   * Buttons passed to modal for save functionality
   */
  const buttons = useMemo(
    () => [
      {
        text: 'Cancel',
        color: 'default',
        outline: true,
        onClick: () => {
          reset();
          onClose();
        },
        disabled: loadingSave,
      },
      {
        text: 'Save',
        color: 'success',
        onClick: () => {
          onSubmit();
        },
        disabled: loadingSave,
        loading: loadingSave,
      },
    ],
    [loadingSave, reset, onClose, onSubmit]
  );

  return (
    <Modal
      title={'Budgets / Forecasts'}
      show={show}
      onClose={onClose}
      width={600}
      buttons={buttons}
    >
      <HideScrollbar />
      {!data || !data.company || loading || error || !consolidationCompanies ? (
        <Spinner />
      ) : (
        <Scroll>
          {showRequiredError && (
            <ErrorText>You must select a budget for each company</ErrorText>
          )}
          <SourceGroup>
            Budget Source
            <SelectWrapper>
              <Select
                options={sourceOptions}
                value={source}
                onChange={handleChangeSource}
                portal
              />
            </SelectWrapper>
          </SourceGroup>
          {consolidationCompanies &&
            consolidationCompanies.map((company, index) => {
              const consolidationBudgets = get(
                data,
                'company.consolidationBudgets',
                []
              );
              const budget = consolidationBudgets.find(
                (b) => b.childCompanyId === company.id
              );
              const defaultValue = {
                budgetType: budget ? budget.budgetType : null,
                budgetId: budget ? budget.budgetId : null,
              };
              return (
                <CompanyGroup key={index}>
                  {company.name}
                  <CompanyBudgetSelect
                    control={control}
                    name={company.id.toString()}
                    defaultValue={defaultValue}
                    companyId={company.id}
                    budgetType={source}
                  />
                </CompanyGroup>
              );
            })}
        </Scroll>
      )}
    </Modal>
  );
};

export default BudgetsConsolidationModal;
