import gql from 'graphql-tag';
import { getTheme, setTheme } from 'utils/theme';

export default {
  defaults: {
    notAuthenticatedTheme: getTheme(),
  },
  resolvers: {
    Mutation: {
      setNotAuthenticatedTheme: (_, { theme }, { cache }) => {
        const data = {
          notAuthenticatedTheme: theme,
        };
        setTheme(theme);
        cache.writeQuery({
          query: gql`
            query NotAuthenticatedTheme {
              notAuthenticatedTheme
            }
          `,
          data,
        });
        return null;
      },
    },
  },
};
