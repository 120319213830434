import React, { ReactElement } from 'react';

const CheckCircleIcon = ({
  color = '#f9fafc',
  diameter = 40,
  checked,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  checked?: boolean;
  diameter?: number;
}): ReactElement => {
  return (
    <>
      {checked ? (
        <svg {...props} height={diameter} width={diameter} viewBox="0 0 40 40">
          <path
            fill={color}
            d="m17.5 28.167 12.125-12.084-2.917-2.916-9.208 9.166-4.417-4.416-2.875 2.916Zm2.5 9.375q-3.667 0-6.854-1.375-3.188-1.375-5.563-3.75-2.375-2.375-3.75-5.563Q2.458 23.667 2.458 20q0-3.667 1.375-6.875t3.75-5.583q2.375-2.375 5.563-3.75Q16.333 2.417 20 2.417q3.667 0 6.875 1.375t5.583 3.75q2.375 2.375 3.75 5.583T37.583 20q0 3.667-1.375 6.854-1.375 3.188-3.75 5.563-2.375 2.375-5.583 3.75T20 37.542Z"
          />
        </svg>
      ) : (
        <svg
          {...props}
          width={diameter}
          height={diameter}
          viewBox={`0 0 ${diameter} ${diameter}`}
        >
          <circle
            stroke={color}
            cx={diameter / 2}
            cy={diameter / 2}
            r={(diameter / 2) * 0.9}
          />
        </svg>
      )}
    </>
  );
};

export default CheckCircleIcon;
