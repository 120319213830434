/**
 *
 * Consolidation Picker
 *
 */

import React, { useState, useEffect, useMemo } from 'react';
import Categories from './Categories';
import Tree from './Tree';
import Result from './Result';
import { Company } from 'interfaces/company';
import { Firm } from 'interfaces/firm';
import { ParentContainer } from 'components/ItemSelectFlowStructure';

interface Props {
  /**
   * A consolidated company to update
   */
  company?: Company;
  /**
   * Flag to hide the currency conversion options
   */
  hideConversion: boolean;
  /**
   * The currency the consolidated company is set to
   */
  consolidationCurrency: string;
  /**
   * A flag to apply currency conversion
   */
  applyCurrencyConversion: boolean;
  /**
   * All the different folders / groups the user has access to
   */
  folders: Firm[];
  /**
   * The workspace ID
   */
  firmId: number;
  /**
   * Callback function invoked on close
   */
  onClose: () => void;
  /**
   * Whether to show the consolidation in manage or view mode
   */
  canManageConsolidation?: boolean;
  /**
   * The owner of the consolidation
   */
  consolidationOwner?: string | null;
}

const ConsolidatedPicker: React.FC<Props> = ({
  company,
  hideConversion: _hideConversion,
  consolidationCurrency,
  applyCurrencyConversion,
  folders,
  firmId,
  onClose,
  canManageConsolidation = false,
  consolidationOwner,
}) => {
  const [tab, setTab] = useState<number>(0);
  const [selectedIds, setSelectedIds] = useState<number[]>(
    company ? company.consolidationCompanies.map((c) => c.id) : []
  );
  const [hideConversion, setHideConversion] =
    useState<boolean>(_hideConversion);
  const viewOnly = !canManageConsolidation && !!company;

  /**
   * Hide currency conversion if SUMMITf
   */
  useEffect(() => {
    if (folders.length) {
      for (const folder of folders) {
        if (
          folder.companies.length &&
          folder.companies[0].accountingIntegration === 'SUMMIT'
        ) {
          setHideConversion(true);
          break;
        }
      }
    }
  }, [folders]);

  /**
   * Handles when tab (Folder) gets changed
   */
  const onChangeTab = (selectedTab: number) => setTab(selectedTab);

  /**
   * Handle when an ID is removed
   */
  const onRemove = (id: number) =>
    setSelectedIds((prev) => prev.filter((selectedId) => selectedId !== id));

  /**
   * Handle when an ID is selected/unselected
   */
  const onChange = (id: number, selected: boolean) => {
    if (selected) {
      setSelectedIds((prev) => [...prev, id]);
    } else {
      onRemove(id);
    }
  };

  /**
   * The first selected company
   */
  const firstValue = selectedIds.length ? selectedIds[0] : null;

  /**
   * The designated accounting integration for the consolidation
   */
  const accountingIntegration = useMemo<string | undefined>(() => {
    if (firstValue) {
      let foundCompany: Company | undefined;

      for (const folder of folders) {
        foundCompany = folder.companies.find((folderCompany) => {
          return folderCompany.id === firstValue;
        });

        if (foundCompany) {
          break;
        }
      }

      if (foundCompany) {
        return foundCompany.accountingIntegration;
      }
    }
  }, [firstValue, folders]);

  const companies = useMemo(() => {
    if (viewOnly && !company) {
      return [];
    }

    if (viewOnly) {
      return company.consolidationCompanies;
    } else if (folders[tab]) {
      return folders[tab].companies;
    }
    return [];
  }, [company, folders, tab, viewOnly]);

  return (
    <ParentContainer>
      {!viewOnly && (
        <Categories folders={folders} current={tab} onChange={onChangeTab} />
      )}
      <Tree
        companies={companies}
        selectedIds={selectedIds}
        accountingIntegration={accountingIntegration}
        onChange={onChange}
        viewOnly={viewOnly}
        consolidationOwner={consolidationOwner}
      />
      <Result
        company={company}
        consolidationCurrency={consolidationCurrency}
        applyCurrencyConversion={applyCurrencyConversion}
        hideConversion={hideConversion}
        accountingIntegration={accountingIntegration}
        folders={folders}
        selectedIds={selectedIds}
        onClose={onClose}
        firmId={firmId}
        onRemove={onRemove}
        viewOnly={viewOnly}
      />
    </ParentContainer>
  );
};

export default ConsolidatedPicker;
