import React from 'react';
import { Query } from '@apollo/client/react/components';
import gql from 'graphql-tag';
import * as Sentry from '@sentry/browser';

const QUERY = gql`
  query SentryUser {
    currentUser {
      email
    }
  }
`;

class SentryUser extends React.PureComponent {
  render() {
    return (
      <Query query={QUERY}>
        {({ data, error, loading }) => {
          if (loading || error) {
            return null;
          }

          Sentry.configureScope((scope) => {
            scope.setUser({ email: data.currentUser.email });
          });

          return null;
        }}
      </Query>
    );
  }
}

export default SentryUser;
