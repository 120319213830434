/**
 *
 * Themes
 *
 */
import React, { useEffect, useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { equals } from 'ramda';
import { ChromePicker } from 'react-color';
import produce from 'immer';
import CustomScroll from 'react-custom-scroll';

import aloeVera from './images/aloe-vera.jpg?sizes[]=246,sizes[]=332,sizes[]=193';
import berries2 from './images/berries-2.jpg?sizes[]=246,sizes[]=332,sizes[]=193';
import citrus from './images/citrus.jpg?sizes[]=246,sizes[]=332,sizes[]=193';
import enchanted from './images/enchanted.jpg?sizes[]=246,sizes[]=332,sizes[]=193';
import highrise from './images/highrise.jpg?sizes[]=246,sizes[]=332,sizes[]=193';
import jungle from './images/jungle.jpg?sizes[]=246,sizes[]=332,sizes[]=193';
import milkyWay from './images/milky-way.jpg?sizes[]=246,sizes[]=332,sizes[]=193';
import paintedSky from './images/painted-sky.jpg?sizes[]=246,sizes[]=332,sizes[]=193';
import planter from './images/planter.jpg?sizes[]=246,sizes[]=332,sizes[]=193';
import rainbow from './images/rainbow.jpg?sizes[]=246,sizes[]=332,sizes[]=193';
import retro from './images/retro.jpg?sizes[]=246,sizes[]=332,sizes[]=193';
import sprinkles from './images/sprinkles.jpg?sizes[]=246,sizes[]=332,sizes[]=193';
import pontoon from './images/pontoon.jpg?sizes[]=246,sizes[]=332,sizes[]=193';
import waterford from './images/waterford.jpg?sizes[]=246,sizes[]=332,sizes[]=193';

import brushes from './images/brushes.png';

import Modal from 'components/Modal';
import { showInfo } from 'utils/popups';
import LogoUploader from 'components/LogoUploader';
import OutsideClickDetector from 'hooks/useOutsideDetector';
import getImagePalette from 'utils/getImagePalette';
import usePrevious from 'hooks/usePrevious';

const Container = styled.div`
  display: flex;
`;

const Left = styled.div`
  width: 60%;
`;

const Content = styled.div`
  padding: 0 6px;
`;

const Presets = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const PaletteDiv = styled.div`
  width: 200px;
  height: 154px;
  margin: 6px;
  padding: 0;
  transition: border-color 0.2s;
  border: 3px solid #e9ecef;
  &:hover {
    border-color: #617182;
  }
  flex: auto;
  ${(props) =>
    !!props.onClick &&
    css`
      cursor: pointer;
    `}
  img {
    max-height: 148px;
  }
`;

const SelectedPalette = styled.div`
  width: 332px;
  height: 210px;
  flex-shrink: 0;
  margin: 15px auto;
  text-align: center;
`;

const PreviewPalette = styled.div`
  flex-shrink: 0;
  margin: 0 auto;
  margin-top: 8px;
  ${(props) =>
    !!props.onClick &&
    css`
      cursor: pointer;
    `}
`;

const LogoPlaceholder = styled.div`
  width: 246px;
  height: 156px;
  border: 1px dashed #ccc;
  margin: 6px;
`;

const LogoPaletteDiv = styled(PaletteDiv)`
  background: #fff;
  display: flex;
  align-items: center;
  ${(props) =>
    !!props.onClick &&
    css`
      cursor: pointer;
    `}
`;

const LogoSelectedPalette = styled(SelectedPalette)`
  background: #fff;
  display: flex;
  align-items: center;
`;

const LogoPreviewPalette = styled(PreviewPalette)`
  background: #fff;
  display: flex;
  align-items: center;
  width: 160px;
  height: 100px;
  ${(props) =>
    !!props.onClick &&
    css`
      cursor: pointer;
    `}
`;

const ColorsList = styled.div`
  padding: 4px;
  display: flex;
  flex-direction: column;
  width: 44%;
  height: 100%;
  flex-shrink: 0;
`;

const LogoWrapper = styled.div`
  text-align: center;
  flex-grow: 1;
`;

const Color = styled.div`
  flex-grow: 1;
  margin: 2px;
  ${({ selected, theme }) =>
    selected &&
    css`
      outline: 2px solid ${theme.colors.primary};
    `}
`;

const CustomPaletteDiv = styled(LogoPaletteDiv)`
  height: 148px;
  width: 247px;
  flex: unset;
  & .chrome-picker {
    box-shadow: none !important;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.9) !important;
  }
  & .chrome-picker > div:first-child {
    padding-bottom: 45% !important;
  }
`;

const SelectedCustomPaletteDiv = styled(CustomPaletteDiv)`
  width: 332px;
  height: 210px;
  margin: 15px auto;
  flex-shrink: 0;
`;

const PreviewCustomPaletteDiv = styled(CustomPaletteDiv)`
  height: 110px;
  width: 171px;
`;

const ColorPickerWrapper = styled.div`
  flex-grow: 1;
  color: #333;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-image: url(${brushes.src});
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 10;
`;

const ScrollWrapper = styled.div`
  height: calc(100vh - 200px);
`;

export const palettes = [
  {
    colors: [
      '#707c7d',
      '#c3c3c3',
      '#6bbcc0',
      '#bcdcd8',
      '#7aa7a9',
      '#38595d',
      '#152d2e',
    ],
    image: aloeVera,
  },
  {
    colors: [
      '#173249',
      '#6f859d',
      '#e65663',
      '#f38e9a',
      '#c9cddd',
      '#4756a5',
      '#bf1f28',
    ],
    image: berries2,
  },
  {
    colors: [
      '#ed644c',
      '#dd2227',
      '#f9ac8f',
      '#ffde69',
      '#f37c8f',
      '#3c672f',
      '#9ed299',
    ],
    image: citrus,
  },
  {
    colors: [
      '#dae1bd',
      '#bac58f',
      '#859c59',
      '#938652',
      '#65552a',
      '#405131',
      '#322b1d',
    ],
    image: enchanted,
  },
  {
    colors: [
      '#1f5c81',
      '#6f94a5',
      '#6f94a5',
      '#4c5662',
      '#79c4d8',
      '#262633',
      '#022d49',
    ],
    image: highrise,
  },
  {
    colors: [
      '#77a577',
      '#234f37',
      '#428b6b',
      '#93a19a',
      '#00717d',
      '#3f9ba7',
      '#254f54',
    ],
    image: jungle,
  },
  {
    colors: [
      '#7470a6',
      '#3e3884',
      '#291e56',
      '#587db5',
      '#254b83',
      '#474d62',
      '#090f23',
    ],
    image: milkyWay,
  },
  {
    colors: [
      '#00659c',
      '#9e4c9d',
      '#bd4679',
      '#ba1f26',
      '#ee3c4e',
      '#f37c5d',
      '#00adbe',
    ],
    image: paintedSky,
  },
  {
    colors: [
      '#605b58',
      '#abb8ae',
      '#80625b',
      '#98d071',
      '#006439',
      '#5d8182',
      '#2a4246',
    ],
    image: planter,
  },
  {
    colors: [
      '#de2728',
      '#de7f2c',
      '#e2e246',
      '#4ab747',
      '#2f90cf',
      '#3e5aa8',
      '#9a5ba5',
    ],
    image: rainbow,
  },
  {
    colors: [
      '#52341b',
      '#943d0b',
      '#bd732c',
      '#a78762',
      '#ced6df',
      '#5b5552',
      '#33485c',
    ],
    image: retro,
  },
  {
    colors: [
      '#49b7c4',
      '#005667',
      '#ff85a3',
      '#ff862f',
      '#fcc168',
      '#00d282',
      '#afbbf0',
    ],
    image: sprinkles,
  },
  {
    colors: [
      '#124885',
      '#05b195',
      '#0b8ca4',
      '#b5b4b9',
      '#c2a48e',
      '#bf003b',
      '#e4a15a',
    ],
    image: pontoon,
  },
  {
    colors: [
      '#6a9cbf',
      '#a3d4f5',
      '#8a6479',
      '#66292a',
      '#c05548',
      '#1f84bf',
      '#425865',
    ],
    image: waterford,
  },
];

const QUERY = gql`
  query CompanyLogoForColors($id: Int!) {
    company(id: $id) {
      id
      colors
      customColors
      logo {
        id
        url
      }
    }
  }
`;

export const SET_COLORS = gql`
  mutation SetCompanyColors($id: Int!, $colors: [String!]!) {
    setCompanyColors(companyId: $id, colors: $colors) {
      id
      colors
    }
  }
`;

export const SET_CUSTOM_COLORS = gql`
  mutation SetCompanyCustomColors($id: Int!, $customColors: [String!]!) {
    setCompanyCustomColors(companyId: $id, customColors: $customColors) {
      id
      colors
      customColors
    }
  }
`;

const WORKSPACE_QUERY = gql`
  query WorkspaceLogoForColors($id: Int!) {
    workspace(id: $id) {
      id
      defaultLogoUrl
      defaultTheme
      defaultThemeCustom
    }
  }
`;

export const SET_WORKSPACE_COLORS = gql`
  mutation SetWorkspaceTheme($id: Int!, $defaultTheme: [String]) {
    setWorkspaceDefaultTheme(workspaceId: $id, defaultTheme: $defaultTheme) {
      id
      defaultTheme
    }
  }
`;

export const SET_WORKSPACE_CUSTOM_COLORS = gql`
  mutation SetWorkspaceThemeCustom($id: Int!, $defaultThemeCustom: [String]) {
    setWorkspaceDefaultThemeCustom(
      workspaceId: $id
      defaultThemeCustom: $defaultThemeCustom
    ) {
      id
      defaultThemeCustom
      defaultTheme
    }
  }
`;

export const logoColorsCache = {};

export function rgbToHex(r, g, b) {
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

export function Palette({ image, colors, selected, onClick, preview }) {
  let Div = PaletteDiv;
  if (selected) {
    Div = SelectedPalette;
  } else if (preview) {
    Div = PreviewPalette;
  }
  let imageIndex = 0;
  if (selected) {
    imageIndex = 1;
  } else if (preview) {
    imageIndex = 2;
  }

  return (
    <Div onClick={onClick ? () => onClick(colors) : null}>
      {image ? (
        <img src={image.images[imageIndex].path} />
      ) : (
        colors.map((color, index) => (
          <Color key={index} style={{ background: color }} />
        ))
      )}
    </Div>
  );
}

export class CustomPalette extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedColor: null,
    };
  }
  onSelectColor = (index) => {
    this.setState({
      selectedColor: index,
    });
  };
  onChangeComplete = (color) => {
    this.props.onChangeColors(
      produce(this.props.customColors, (draft) => {
        draft[this.state.selectedColor] = color.hex;
      })
    );
  };
  render() {
    const { customColors, selected, preview, onClick } = this.props;
    const { selectedColor } = this.state;

    const currentColor = customColors[selectedColor];

    let Div = CustomPaletteDiv;
    if (selected) {
      Div = SelectedCustomPaletteDiv;
    } else if (preview) {
      Div = PreviewCustomPaletteDiv;
    }

    return (
      <Div
        onClick={
          selected || preview
            ? onClick || null
            : () => this.props.setColors(this.props.customColors)
        }
      >
        <ColorsList>
          {customColors.map((color, index) => (
            <Color
              key={index}
              style={{ background: color }}
              selected={index === selectedColor && selected}
              onClick={selected ? () => this.onSelectColor(index) : null}
              as={selected ? 'button' : 'div'}
            />
          ))}
        </ColorsList>
        <ColorPickerWrapper>
          <OutsideClickDetector
            callback={() => this.setState({ selectedColor: null })}
          >
            {selectedColor !== null && selected && (
              <ChromePicker
                color={currentColor}
                onChangeComplete={this.onChangeComplete}
              />
            )}
          </OutsideClickDetector>
        </ColorPickerWrapper>
      </Div>
    );
  }
}

export class LogoPalette extends React.PureComponent {
  constructor(props) {
    super(props);

    if (logoColorsCache[props.logo]) {
      this.state = {
        colors: logoColorsCache[props.logo],
      };
      if (props.getLogoColors) {
        props.getLogoColors(this.state.colors);
      }
    } else {
      this.state = {
        colors: [],
      };
      getImagePalette(`${props.src}?${new Date().getTime()}`, (colors) => {
        this.setState(
          {
            colors,
          },
          () => {
            if (this.props.getLogoColors) {
              this.props.getLogoColors(this.state.colors);
            }
          }
        );
        logoColorsCache[props.src] = colors;
      });
    }
  }
  componentDidMount() {
    if (
      (this.props.logoPreview || this.props.logo) &&
      (this.props.type === 'workspace' || !this.props.disabled)
    ) {
      this.setupLogoPreview();
    }
  }
  setupLogoPreview = () => {
    getImagePalette(
      this.props.disableLogoUpload
        ? this.props.defaultLogoUrl
        : this.props.logoPreview || this.props.logo,
      (colors) => {
        this.setState(
          {
            colors,
          },
          () => {
            if (this.props.getLogoColors) {
              this.props.getLogoColors(colors);
            }
            if (this.props.previewSelected) {
              this.onSetColors();
            }
          }
        );
      }
    );
  };
  componentDidUpdate(prevProps) {
    if (
      (this.props.logoPreview &&
        this.props.logoPreview !== prevProps.logoPreview) ||
      (this.props.logo && this.props.logo !== prevProps.logo) ||
      this.props.disableLogoUpload !== prevProps.disableLogoUpload
    ) {
      this.setupLogoPreview();
    }
  }
  onSetColors = () => {
    if (!this.state.colors.length) {
      return;
    }
    if (this.props.onSetColors) {
      this.props.onSetColors(this.state.colors);
    }
  };

  render() {
    const {
      logo,
      alt,
      selected,
      preview,
      logoPreview,
      onClick,
      disableLogoUpload,
      defaultLogoUrl,
    } = this.props;
    const { colors } = this.state;
    let Div = LogoPaletteDiv;
    if (selected) {
      Div = LogoSelectedPalette;
    } else if (preview) {
      Div = LogoPreviewPalette;
    }

    let logoWidth = 120;

    if (preview) {
      logoWidth = 70;
    }
    return (
      <Div onClick={selected || preview ? onClick || null : this.onSetColors}>
        <ColorsList>
          {colors.map((color, index) => (
            <Color key={index} style={{ background: color }} />
          ))}
        </ColorsList>
        <LogoWrapper>
          <img
            src={disableLogoUpload ? defaultLogoUrl : logoPreview || logo}
            width={logoWidth}
            alt={alt}
          />
        </LogoWrapper>
      </Div>
    );
  }
}

const ThemesModal = ({
  type,
  id,
  onAddLogo,
  onClose,
  show,
  onChangeColors,
  onChangeCustomColors,
  colors: colorsProp,
  customColors: customColorsProp,
  noMessage,
  disabled = false,
  defaultImageUrl,
  onLogoUploadError,
}) => {
  const [logoColors, setLogoColors] = useState([]);
  const [stagedColors, setStagedColors] = useState(colorsProp);
  const [stagedCustomColors, setStagedCustomColors] =
    useState(customColorsProp);
  const [selectedEditor, setSelectedEditor] = useState(null);
  const prevUrl = usePrevious(defaultImageUrl);

  const getLogoColors = (colors) => {
    setLogoColors(colors);
  };

  useEffect(() => {
    if (defaultImageUrl && defaultImageUrl !== prevUrl) {
      getImagePalette(defaultImageUrl, (_logoColors) => {
        setLogoColors(_logoColors);
        if (selectedEditor === 'LogoPalette') {
          setStagedColors(_logoColors);
        }
      });
    }
  }, [defaultImageUrl, prevUrl, selectedEditor]);

  const onSetCustomColors = useCallback((values) => {
    setStagedCustomColors(values);
    setStagedColors(values);
    setSelectedEditor('CustomPalette');
  }, []);

  const { data, loading, error } = useQuery(
    type === 'workspace' ? WORKSPACE_QUERY : QUERY,
    {
      variables: { id },
    }
  );

  useEffect(() => {
    getImagePalette(defaultImageUrl, (_logoColors) => {
      const selectedPalette = palettes.find((palette) =>
        equals(stagedColors, palette.colors)
      );
      const logoSelected =
        selectedPalette === undefined && equals(stagedColors, _logoColors);
      const customSelected = equals(stagedColors, stagedCustomColors);
      const otherSelected =
        !selectedPalette && !logoSelected && !customSelected;

      if (selectedPalette) {
        setSelectedEditor('Palette');
      } else if (logoSelected) {
        setSelectedEditor('LogoPalette');
      } else if (customSelected || otherSelected) {
        setSelectedEditor('CustomPalette');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const save = useCallback(() => {
    if (!noMessage) {
      showInfo({
        title: 'Changes Saved',
        text: 'If in a report, refresh your browser to have the changes applied.',
      });
    }
    onChangeColors(stagedColors);
    if (!equals(customColorsProp, stagedCustomColors)) {
      onChangeCustomColors(stagedCustomColors);
    }
    onClose();
  }, [
    customColorsProp,
    noMessage,
    onChangeColors,
    onChangeCustomColors,
    onClose,
    stagedColors,
    stagedCustomColors,
  ]);

  const buttons = [
    {
      color: 'default',
      outline: true,
      text: 'Cancel',
      onClick: onClose,
    },

    {
      color: 'success',
      text: 'Save',
      onClick: save,
    },
  ];

  if (loading || error) {
    if (error) console.log(error);
    return null;
  }

  const logo = data[type].logo;

  const selectedPalette = palettes.find((palette) =>
    equals(stagedColors, palette.colors)
  );

  const logoSelected =
    logo && !selectedPalette && equals(stagedColors, logoColors);

  const customSelected = equals(stagedColors, stagedCustomColors);

  const otherSelected = !selectedPalette && !logoSelected && !customSelected;

  let editor = null;

  if (selectedEditor === 'Palette') {
    editor = (
      <Palette
        colors={selectedPalette.colors}
        image={selectedPalette.image}
        selected
      />
    );
  } else if (selectedEditor === 'LogoPalette') {
    editor = (
      <LogoPalette
        type={type}
        key={defaultImageUrl}
        id={id}
        src={defaultImageUrl}
        logoPreview={defaultImageUrl}
        selected
      />
    );
  } else if (selectedEditor === 'CustomPalette') {
    editor = (
      <CustomPalette
        type={type}
        id={id}
        customColors={otherSelected ? stagedColors : stagedCustomColors}
        onChangeColors={onSetCustomColors}
        selected
      />
    );
  }

  return (
    <>
      <Modal
        title={'Color Theme'}
        show={show}
        onClose={onClose}
        width={660}
        buttons={buttons}
      >
        <Container>
          <Left>{editor}</Left>
          <div>
            <ScrollWrapper>
              <CustomScroll heightRelativeToParent="calc(100vh - 200px)">
                <Content>
                  <Presets>
                    {defaultImageUrl ? (
                      <LogoPalette
                        type={type}
                        id={id}
                        src={defaultImageUrl}
                        logoPreview={defaultImageUrl}
                        previewSelected={logoSelected}
                        onSetColors={(values) => {
                          setStagedColors(values);
                          setSelectedEditor('LogoPalette');
                        }}
                        currentColors={stagedColors}
                        getLogoColors={getLogoColors}
                      />
                    ) : (
                      <LogoPlaceholder onClick={onAddLogo}>
                        <LogoUploader
                          loading={loading}
                          onStage={onAddLogo}
                          defaultImageUrl={defaultImageUrl}
                          onError={onLogoUploadError}
                          disabled={disabled}
                        />
                      </LogoPlaceholder>
                    )}
                    {palettes.map((palette, index) => (
                      <Palette
                        key={index}
                        colors={palette.colors}
                        image={palette.image}
                        onClick={() => {
                          setStagedColors(palette.colors);
                          setSelectedEditor('Palette');
                        }}
                      />
                    ))}
                    <CustomPalette
                      type={type}
                      id={id}
                      customColors={stagedCustomColors}
                      setColors={onSetCustomColors}
                    />
                  </Presets>
                </Content>
              </CustomScroll>
            </ScrollWrapper>
          </div>
        </Container>
      </Modal>
    </>
  );
};

export default ThemesModal;
