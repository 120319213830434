import styled from 'styled-components';
import Button from 'components/Button';
import _HorizontalTabs from 'components/HorizontalTabs';

export const Container = styled.div``;

export const Row = styled.div`
  display: flex;
`;

export const MenuDiv = styled.div`
  border-right: 1px solid #cdcdcd;
  margin-right: 30px;
  padding-right: 30px;
`;

export const Col = styled.div<{
  size: number;
  borderRight?: string;
  marginRight?: string;
  paddingRight?: string;
}>`
  flex: ${({ size = 1 }) => size};
  border-right: ${({ borderRight }) => borderRight};
  margin-right: ${({ marginRight }) => marginRight};
  overflow-y: auto;
  /* max-height: calc(100% - 56px); */
`;

export const ToggleVisible = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  margin-left: 2px;
`;

export const BulkUpdateDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const BulkUpdateCheckboxDiv = styled.div`
  flex-shrink: 0;
`;

export const BulkUpdateText = styled.label`
  font-size: 0.75rem;
`;

export const Option = styled(Button)`
  border: unset;
  border-radius: 40px;
  &:hover {
    background-color: #e7f1fd;
    color: #0076fe;
  }
`;

export const LabelOption = styled(Button)<{ size?: number }>`
  flex: ${(props) => props.size};
  display: flex;
  border: unset;
  align-items: center;
  font-size: 0.75rem;
  &:hover {
    border-bottom: 2px solid #0076fe;
    background-color: unset;
  }
  span {
    margin: 0 auto;
  }
`;

export const Description = styled.div`
  font-size: 0.75rem;
  margin-bottom: 10px;
`;

export const Div = styled.div`
  max-height: calc(100vh - 250px);
  overflow-y: auto;
`;

export const SettingTitle = styled.div`
  margin-bottom: 1rem;
`;

export const FieldGroup = styled.div`
  margin-bottom: 0.25rem;
  display: flex;
  align-items: center;
`;

export const LabelCol = styled.div`
  width: 260px;
  font-size: 0.875rem;
`;

export const InputCol = styled.div`
  width: 435px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 3px;
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 14rem;
  font-size: 0.75rem;
`;
export const Input = styled.input`
  width: 100%;
`;
export const PreparedByInput = styled.input<{ warning?: boolean }>`
  width: 100%;
  border: 1px solid
    ${(props) =>
      props.warning ? props.theme.colors.warning : '#dfe7f3'}!important;
`;

export const BetaTestMode = styled.div`
  display: flex;
  border-top: solid 1px #cdcdcd;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding: 15px 0;
  & > span {
    color: #ff865e;
  }
`;

export const CheckBox = styled.input`
  width: 14px;
  height: 14px;
  margin-right: 5px;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
`;

export const ErrorMsg = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.danger};
`;

export const LaunchCurrencyModal = styled(Button)`
  border: 1px solid #cccccc;
  border-radius: 8px;
  width: calc(50% - 4px);
`;

export const HorizontalTabs = styled(_HorizontalTabs)`
  button {
    font-size: 0.75rem;
  }
`;

export const BookMonthLauncher = styled.div`
  color: ${({ theme }) => theme.colors.link};
  font-size: 14px;
  cursor: pointer;
`;

export const DescriptionContainer = styled.div`
  width: fit-content;
  margin-bottom: 2rem;
`;

export const Where = styled.p`
  font-size: 0.75rem;
  margin-bottom: 10px;
  text-align: center;
`;
