/**
 *
 * StripeCheckoutComplete
 *
 */
import React from 'react';
import LoadingIndicator from 'components/LoadingIndicator';
import useTimeout from 'hooks/useTimeout';

const StripeCheckoutComplete = props => {
  useTimeout(() => {
    const redirect = '/';
    window.location.href = redirect;
  }, 1000);

  return <LoadingIndicator />;
};

export default StripeCheckoutComplete;
