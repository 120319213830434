import React from 'react';
import { Bottom, A } from '../common-styled-components';
import Button from 'components/Button';
import {
  AccountDiv,
  DivSuccess,
  DivSuccessContainer,
  DivConnected,
  SuccessText,
} from './styled-components';

import SuccessIcon from './SuccessIcon.svg';

const GoogleSheetSuccess = (props) => {
  const {
    helpLink,
    emailSuccess,
    onClose,
    googleSpreadsheetSuccess,
    dispatch,
    setHideModalBackButton,
    onResetExcelStates,
  } = props;

  setHideModalBackButton(true);

  return (
    <div>
      <AccountDiv>
        <DivSuccessContainer>
          <DivSuccess>
            <img src={SuccessIcon} width={16} />
            <SuccessText>Success</SuccessText>
          </DivSuccess>
          <DivConnected>
            Connected to: Google Drive {emailSuccess} Google Sheet:{' '}
            {googleSpreadsheetSuccess.name}
          </DivConnected>
        </DivSuccessContainer>
      </AccountDiv>
      <Bottom>
        <div>
          <Button
            onClick={() => {
              dispatch({ type: 'DATASHEET_LIST' });
              onResetExcelStates();
            }}
            color="primary"
          >
            Done
          </Button>
        </div>
      </Bottom>
    </div>
  );
};

export default GoogleSheetSuccess;
