import styled from 'styled-components';
import ButtonBase from 'components/Button';

export const Container = styled.div<{
  marginBottom?: React.CSSProperties['marginBottom'];
}>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  & > button:not(:first-child) {
    margin-left: 0.5rem;
  }
`;

export const Button = styled(ButtonBase).attrs({
  color: 'success',
})``;

export const RemoveButton = styled(ButtonBase).attrs({
  outline: true,
  color: 'danger',
})``;

export const RetryButton = styled.button`
  color: ${(props) => props.theme.colors.link};
  font-size: 0.75rem;
`;

export const Header = styled.h4``;

export const Info = styled.div`
  font-size: 0.6rem;
  margin-top: 0.25;
  margin-bottom: 0.5rem;
`;

export const EmailVerification = styled.div`
  & > button:last-of-type {
    margin-left: 4px;

    div {
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        margin-left: 4px;
        width: 15px;
      }
    }
  }
`;

export const VerifyCode = styled.div<{ error?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-center;
  margin-bottom: 1rem;

  input {
    margin-right: 0.5rem;
    width: 261px;
    flex-grow: unset;
    ${({ error, theme }) =>
      error && `border-color: ${theme.colors.danger} !important;`}
  }
`;

export const DisplayPhoneNumber = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 14px;
  p {
    margin-bottom: unset;
  }
`;
