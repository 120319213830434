import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import moment, { Moment } from 'moment';

import {
  Buttons,
  Container,
  CONTAINER_HEIGHT,
  CONTAINER_WIDTH,
  DateDisplay,
  Footer,
  Header,
  LauncherButton,
  OutlineButton,
} from './styledComponents';
import Button from 'components/Button';
import { DayMonthToggle } from 'components/DateRangePicker/styledComponents';
import DatePickerField, {
  DatePickerFieldProps,
} from 'components/DatePickerField';
import { getDateText } from 'utils/dates';

const WINDOW_BUFFER = 50;

interface Props
  extends Pick<DatePickerFieldProps, 'selectorId' | 'minDate' | 'maxDate'> {
  initialDate: Moment;
  launcher?: React.ReactElement;
  top?: number | string;
  left?: number | string;
  showMonthToggle?: boolean;
  showMonth?: boolean;
  portal?: boolean; // Added portal prop
  hideCancel?: boolean;
  changeOnClick?: boolean;
  onShowMonthChange?: (showMonth: boolean | undefined) => void;
  onChange: (
    newDate: Moment,
    showMonth: boolean | undefined,
    comparison: boolean
  ) => void;
  onRevert?: () => void;
  comparison?: boolean;
}

const DatePicker: React.FC<Props> = ({
  initialDate,
  launcher,
  top = 0,
  left = 0,
  showMonthToggle = false,
  showMonth: _showMonth,
  hideCancel,
  changeOnClick,
  onShowMonthChange,
  onChange,
  comparison = false,
  portal = false, // Default to false
  selectorId,
  onRevert,
  ...datePickerFieldProps
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [current, setCurrent] = useState<Moment>(initialDate);
  const [showMonth, setShowMonth] = useState<boolean | undefined>(_showMonth);
  const [topPos, setTopPos] = useState<number>(0);
  const [leftPos, setLeftPos] = useState<number>(0);
  const launcherRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const setPositions = useCallback(() => {
    if (launcherRef.current && containerRef.current) {
      const launcherRect = launcherRef.current.getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();

      const spaceBelowLauncher = window.innerHeight - launcherRect.bottom;
      const spaceRightOfLauncher = window.innerWidth - launcherRect.right;

      const containerHeight = containerRect.height || CONTAINER_HEIGHT;
      const containerWidth = containerRect.width || CONTAINER_WIDTH;

      if (spaceBelowLauncher < containerHeight) {
        const topShift =
          launcherRect.bottom -
          (containerHeight + WINDOW_BUFFER - spaceBelowLauncher);
        console.log('setting top position', topShift);
        setTopPos(topShift);
      } else {
        console.log('setting top position', launcherRect.bottom);
        setTopPos(launcherRect.bottom);
      }

      if (spaceRightOfLauncher < containerWidth) {
        const leftShift =
          launcherRect.right -
          (containerWidth + WINDOW_BUFFER - spaceRightOfLauncher);
        console.log('setting left position', leftShift);
        setLeftPos(leftShift);
      } else {
        console.log('setting left position', launcherRect.left);
        setLeftPos(launcherRect.left);
      }
    }
  }, []);

  useEffect(() => setPositions, [setPositions]);

  /**
   * Update dates while date picker is hidden
   */
  useEffect(() => {
    if (!show && !current.isSame(initialDate)) {
      setCurrent(initialDate);
    }
  }, [current, initialDate, show]);

  const handleShowMonthChange = useCallback(
    (state: boolean) => {
      setShowMonth(state);

      if (state) {
        setCurrent(current.startOf('month'));
      }

      if (onShowMonthChange) {
        onShowMonthChange(state);
      }
    },
    [current, onShowMonthChange]
  );

  const applyAndClose = useCallback(
    (date: Moment) => {
      if (onChange) {
        onChange(date, showMonth, comparison);
      }
      setShow(false);
    },
    [comparison, onChange, showMonth]
  );

  const handleDateChange = useCallback(
    (newDate: Moment) => {
      setCurrent(newDate);
      if (changeOnClick) {
        applyAndClose(newDate);
      }
    },
    [applyAndClose, changeOnClick]
  );

  const onApply = useCallback(() => {
    applyAndClose(current);
  }, [applyAndClose, current]);

  const onCancel = useCallback(() => {
    setCurrent(initialDate);
    if (onShowMonthChange) {
      onShowMonthChange(_showMonth);
    }
    setShowMonth(_showMonth);
    setShow(false);
  }, [_showMonth, initialDate, onShowMonthChange]);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setShow(false);
    }
  };

  const handleLauncherClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setPositions();
    setShow(true);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleRevert = () => {
    if (onRevert) {
      onRevert();
    }
    setShow(false);
  };

  const dateRangeContent = (
    <Container
      ref={containerRef}
      show={show}
      top={Number(topPos) || Number(top)}
      left={Number(leftPos) || Number(left)}
    >
      {showMonthToggle && (
        <Header>
          <span>D</span>
          <DayMonthToggle
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleShowMonthChange(e.target.checked)
            }
            checked={showMonth}
            icons={false}
          />
          <span>M</span>
        </Header>
      )}
      <DatePickerField
        initialDate={moment(initialDate)}
        showMonth={showMonth}
        onChange={handleDateChange}
        value={current}
        selectorId={selectorId}
        {...datePickerFieldProps}
      />
      <Footer>
        {(hideCancel || !onRevert || changeOnClick) && (
          <DateDisplay>{moment.utc(current).format('MMM YYYY')}</DateDisplay>
        )}
        <Buttons>
          {!hideCancel && (
            <OutlineButton onClick={onCancel}>Cancel</OutlineButton>
          )}
          {onRevert && (
            <OutlineButton onClick={handleRevert}>Revert</OutlineButton>
          )}
          {!changeOnClick && (
            <Button color="info" onClick={onApply}>
              Apply
            </Button>
          )}
        </Buttons>
      </Footer>
    </Container>
  );

  return (
    <>
      {launcher ? (
        React.cloneElement(launcher, {
          onClick: handleLauncherClick,
          ref: launcherRef,
          type: 'button',
        })
      ) : (
        <LauncherButton
          type="button"
          ref={launcherRef}
          onClick={handleLauncherClick}
        >
          {getDateText(
            current.toDate(),
            current.toDate(),
            'CUSTOM',
            true,
            false,
            true
          )}
        </LauncherButton>
      )}
      {portal
        ? ReactDOM.createPortal(dateRangeContent, document.body)
        : dateRangeContent}
    </>
  );
};

export default DatePicker;
