import styled, { css } from 'styled-components';

/* import Avatar from '@material-ui/core/Avatar'; */
import Avatar from 'react-avatar';

import Button from 'components/Button';
import ButtonLink from 'components/ButtonLink';

import theme from 'styled-theming';
import media from 'media';

const containerBackground = theme('mode', {
  dark: '#222532',
  light: 'white',
});

const tableHeaderBackground = theme('mode', {
  light: '#EAEBEF',
  dark: '#494d5b',
});

const backgroundColor2 = theme('mode', {
  light: '#EAEBEF',
  dark: '#494D5B',
});

const colorFont1 = theme('mode', {
  light: '#181922',
  dark: '#fcfdff',
});

const rowHoverColor = theme('mode', {
  light: '#f4f4f7',
  dark: '#35394a',
});

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  width: 30%;
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  justify-content: flex-start;
  width: 100%;
`;

export const ParentDiv = styled.div`
  justify-content: space-around;
  width: 100%;

  background-color: ${containerBackground};
  padding-top: ${({ headerHeight }) => `${headerHeight}px`};
  transition: padding-top 250ms ease-in-out;
`;

export const GroupDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ minimal }) =>
    minimal &&
    css`
      margin-bottom: 5px;
    `}
`;

export const AvatarCustom = styled(Avatar)`
  height: 30px !important;
  width: 30px !important;
  font-size: medium !important;
  padding-left: 1px !important;
  margin-right: 3px !important;
  background-color: ${backgroundColor2} !important;
  color: ${colorFont1} !important;
`;

export const StyledAvatar = styled(Avatar)`
  margin-right: 4px;
`;

export const GroupItem1 = styled.div`
  flex: 1;
  height: 29px;
`;

export const GroupItem3 = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
`;

export const SubTitle = styled.div`
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
`;

export const InputGroupName = styled.div`
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  width: 80%;
`;

export const LogoHolder = styled.div`
  border-radius: 5px;
  border: 1px solid gray;
  width: 150px;
  position: relative;
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const FolderIconComponent = styled.div`
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
  cursor: pointer;
`;

export const Title = styled.div`
  font-size: 15px;
  font-weight: bold;
`;

export const GroupContainerTitle = styled.div`
  height: 41px;
  background-color: #222532;
  font-size: 15px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 42px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
`;

export const CustomInput = styled.input`
    border: none;
    color: ${colorFont1};
    background: ${backgroundColor2};
    border-top-right-radius: 0px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 0px;
    margin-left: ${({ minimal }) => (minimal ? '0px' : '10px')};
    padding-left: 10px;
    height: 30px;
    font-size: 13px;
    width: 100%;
    ${({ minimal }) =>
      !minimal &&
      css`
        max-width: 168px;
      `}
}
`;

export const TeamAccessButtonC = styled(Button)`
  background-color: ${backgroundColor2};
  height: 30;
  min-width: 122;
  color: ${colorFont1} !important;
  white-space: nowrap;
  margin-right: 0.5rem;
`;
export const CustomInputIcon = styled.button`
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    color: ${colorFont1};
    background: ${backgroundColor2} !important;
    border-top-right-radius: 15px;
    height: 30px;
    padding-left: 10px;
    padding-top:3px;
    padding-right: 10px;
    border-bottom-right-radius: 15px;
} 
`;

export const ActionButtonsContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  flex: 1;
`;

export const AlertContainer = styled.div`
  margin-top: ${({ height }) => height && height > 0 && '1rem'};
  transition: height 250ms ease-out;
  height: ${({ height }) => `${height || 0}px`};
  overflow: hidden;
`;

export const MobileAlert = styled.div`
  border: 2px solid ${({ theme: _theme }) => _theme.body.border};
  border-radius: 10px;
  padding: 1rem;
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const DivRow = styled.div`
  display: inline-flex;
  justify-content: space-between;
  ${({ minimal }) =>
    minimal &&
    css`
      flex: 2;
    `}
`;

export const AvatarContainer = styled.div`
  display: none;

  ${media.phone`
    display: block;
  `}

  ${media.desktop`
    display: none;
  `}
  
  @media (min-width: 1156px) {
    display: block;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: ${({ minimal }) => (minimal ? 'flex-start' : 'center')};
  justify-content: ${({ minimal }) =>
    minimal ? 'flex-start' : 'space-between'};
  flex-direction: ${({ minimal }) => (minimal ? 'column' : 'row')};
  padding: 1rem;
`;

export const GroupNameTitle = styled.div`
  font-size: 1rem;
  margin-right: 1rem;
  flex-shrink: 0;
`;

export const PrimaryButtonStyle = styled(ButtonLink)`
  margin-left: 10px;
  white-space: nowrap;
`;

export const SecondaryButtonStyle = styled(Button)`
  margin-left: 10px;
`;

export const TableStyle = styled.div`
  padding: 1rem 0 0 0;

  table {
    font-size: 12px;
    border-spacing: 0;
    table-layout: fixed;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    ${media.tablet`
      td:first-child,
      th:first-child {
        width: 2rem;
        padding-left: 1rem;
      }
    `}

    thead {
      background-color: ${tableHeaderBackground};
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;

      :last-child {
        border-right: 0;
      }
    }

    tbody tr:hover {
      background-color: ${rowHoverColor};
    }
  }
`;

export const ExpandedTableRow = styled.tr`
  background-color: ${rowHoverColor};
`;

export const ExpandedStyleForGroupName = styled.div`
  color: ${colorFont1};
  font-size: 18px;
  font-weight: bold;
`;

export const CustomSelectClass = styled.select`
  font-size: 12px;
  color: ${colorFont1};
  background-color: ${(props) => props.theme.panel.background};
  border: none;
  padding: 0;
  cursor: pointer;
  & option {
    color: ${colorFont1};
  }
`;

export const ClientPortal = styled(Button)`
  width: 6.5rem;
  font-size: 0.675rem;
  margin-bottom: 6px;
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${containerBackground};
  z-index: -1;
`;
