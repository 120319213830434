/*
 *
 * Filters
 *
 */
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useMemo } from 'react';

import useCompanyId from './useCompanyId';

const QUERY = gql`
  query FiltersModal($companyId: Int!) {
    company(id: $companyId) {
      id
      accounting {
        hasFilter
        classes {
          id
          name
          fullyQualifiedName
          parentId
        }
        departments {
          id
          name
          fullyQualifiedName
          parentId
        }
      }
    }
  }
`;

interface UseFiltersOptions {
  companyId?: number;
}

export default function useFilters(options: UseFiltersOptions = {}) {
  const currentCompanyId = useCompanyId();
  const companyId = options.companyId || currentCompanyId;

  const { data } = useQuery(QUERY, {
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  return useMemo(() => {
    if (!data || !data.company || !data.company.accounting) {
      return { classes: null, departments: null, hasFilter: null };
    }

    return data.company.accounting;
  }, [data]);
}
