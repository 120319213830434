/**
 *
 * AvatarList
 *
 */
import React, { useMemo } from 'react';
import styled from 'styled-components';
import Avatar, { ConfigProvider } from 'react-avatar';

import Tooltip from 'components/Tooltip';

const Div = styled.div`
  display: flex;
`;

const StyledAvatar = styled(Avatar)`
  margin-right: 4px;
  pointer-events: none;
`;

const OverflowAvatar = styled.div<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background: #bbb;
  border-radius: ${(props) => props.size}px;
  text-align: center;
  color: white;
`;

const I = styled.i<{ size: number }>`
  color: white;
  line-height: ${(props) => props.size}px;
`;

const colors = [
  '#1f5c81',
  '#6f94a5',
  '#6f94a5',
  '#4c5662',
  '#79c4d8',
  '#262633',
  '#022d49',
  '#6a9cbf',
  '#a3d4f5',
  '#8a6479',
  '#66292a',
  '#c05548',
  '#1f84bf',
  '#425865',
];

interface AvatarUser {
  id: number;
  email: string;
  fullName: string;
}

interface AvatarListProps {
  users: Array<AvatarUser>;
  size?: number;
  className?: string;
}

const AvatarList: React.FunctionComponent<AvatarListProps> = (props) => {
  const { users, className, size = 30 } = props;

  const firstUsers = useMemo(() => {
    return users.length > 3 ? users.slice(0, 3) : users;
  }, [users]);

  const otherUsers = useMemo(() => users.slice(3), [users]);

  const tooltipContent = useMemo(() => {
    const result = [];

    otherUsers.forEach((user) => {
      result.push(<div key={user.id}>{user.fullName}</div>);
    });

    return result;
  }, [otherUsers]);

  return (
    <ConfigProvider colors={colors}>
      <Div className={className}>
        {firstUsers.map((item) => (
          <Tooltip key={item.id} content={item.fullName}>
            <StyledAvatar
              name={item.fullName}
              email={item.email}
              size={`${size}px`}
              round={`${size}px`}
              title=""
            />
          </Tooltip>
        ))}
        {otherUsers.length > 0 && (
          <Tooltip content={tooltipContent}>
            <OverflowAvatar size={size}>
              <I className="mdi-set mdi-dots-horizontal" size={size} />
            </OverflowAvatar>
          </Tooltip>
        )}
      </Div>
    </ConfigProvider>
  );
};

export default AvatarList;
