/**
 *
 * Dropdown
 *
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, keyframes } from 'styled-components';
import $ from 'jquery';
import * as Sentry from '@sentry/browser';
import { Content, ErrorMsg, Div, ButtonWrapper } from './styledComponents';

import quantum from 'themes/quantum';

const portalRoot = process.env.SERVER
  ? null
  : document.getElementById('portals');

const fadeInTop = keyframes`${{
  from: {
    transform: 'translate(0, -110%)',
  },
  to: {
    transform: 'translate(0, -120%)',
  },
}}`;

class Dropdown extends React.PureComponent {
  static defaultProps = {
    width: 180,
    align: 'right',
    verticalAlign: 'bottom',
    context: false,
    portal: false,
    fixed: false,
    offsetX: 0,
    offsetY: 0,
    stopPropagation: false,
    preventDefault: false,
    showScroll: false,
    position: 0,
    buttonAlign: 'left',
    overflowXHidden: false,
    noAnimation: false,
    onClick: () => {},
  };
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      widthOverride: null,
    };
    this.closeEvent = props.closeOnClick ? 'click' : 'mousedown';
    this.offset = {
      top: 0,
      left: 0,
      offsetWidth: 0,
      offsetHeight: 0,
    };
    if (!process.env.SERVER) {
      this.el = document.createElement('div');
    }
  }
  static getDerivedStateFromError(error) {
    return { error };
  }
  componentDidCatch(error) {
    Sentry.captureException(error);
  }
  componentDidMount() {
    if (portalRoot) {
      portalRoot.appendChild(this.el);
    }
    if (this.dropdownMenu && this.props.showScroll && this.props.position) {
      this.dropdownMenu.scrollTop = this.props.position;
    }
  }
  componentWillUnmount() {
    document.removeEventListener(this.closeEvent, this.handleClose);
    document.removeEventListener('contextmenu', this.handleClose);

    if (portalRoot) {
      portalRoot.removeChild(this.el);
    }
  }
  componentDidUpdate(_, prevState) {
    if (
      this.dropdownMenu &&
      this.props.showScroll &&
      this.props.position &&
      !prevState.open
    ) {
      this.dropdownMenu.scrollTop = this.props.position;
    }
  }
  updateOffset = () => {
    if (this.ref) {
      this.offset = $(this.ref).offset();
      this.offset.offsetHeight = this.ref.offsetHeight;
      this.offset.offsetWidth = this.ref.offsetWidth;
      if (this.offset.left + this.props.width > window.innerWidth - 20) {
        this.offset.left -=
          this.offset.left + this.props.width - (window.innerWidth - 20);
      }
      if (this.props.fixed) {
        this.offset.top -= window.pageYOffset;
      }
    }
  };
  handleEvent = (event) => {
    if (this.props.context || this.props.preventDefault) {
      event.preventDefault();
    }
    if (this.props.stopPropagation) {
      event.stopPropagation();
    }
  };
  handleShow = async (event) => {
    if (this.props.context || this.props.preventDefault) {
      event.preventDefault();
    }
    if (this.props.stopPropagation) {
      event.stopPropagation();
    }

    if (this.props.disabled) return;

    if (this.props.checkCanOpen) {
      const canOpen = await this.props.checkCanOpen();

      if (!canOpen) {
        return;
      }
    }

    if (this.props.onOpen) {
      this.props.onOpen();
    }

    if (this.props.portal) {
      this.updateOffset();
    }

    this.setState(
      {
        open: true,
      },
      () => {
        document.addEventListener(this.closeEvent, this.handleClose, {
          capture: true,
        });
        document.addEventListener('contextmenu', this.handleClose, {
          capture: true,
        });
      }
    );
  };
  hasPreventClass = (element) => {
    let el = element;
    do {
      if (el.className.indexOf && el.className.indexOf('in-dropdown') > -1) {
        return true;
      }
      el = el.parentElement;
    } while (el);
    return false;
  };
  handleClose = (event) => {
    if (
      this.dropdownMenu &&
      event.target.id.indexOf('react-select') === -1 && // handle select elements on popup
      !this.hasPreventClass(event.target) &&
      (!this.dropdownMenu.contains(event.target) || this.props.closeOnClick)
    ) {
      if (this.props.onClose) {
        this.props.onClose();
      }
      this.setState(
        {
          open: false,
          widthOverride: null,
        },
        () => {
          document.removeEventListener(this.closeEvent, this.handleClose);
          document.removeEventListener('contextmenu', this.handleClose);
        }
      );
    }
  };
  handleChildClose = () => {
    this.setState({
      open: false,
      widthOverride: null,
    });
    document.removeEventListener(this.closeEvent, this.handleClose);
  };
  setupRef = (ref) => {
    this.ref = ref;
  };
  setWidth = (newWidth) => {
    this.setState({
      widthOverride: newWidth,
    });
  };
  render() {
    const style = {};
    const { showOnTop, scale = 1, noAnimation, onClick } = this.props;

    const { error } = this.state;

    if (this.props.offsetX) {
      style.left = this.props.offsetX;
    }
    if (this.props.offsetY) {
      style.top = this.props.offsetY;
    }

    let animation;

    if (this.props.portal) {
      style.position = this.props.fixed ? 'fixed' : 'absolute';
      style.top = this.offset.top + this.props.offsetY;
      style.left = this.offset.left + this.props.offsetX;
      if (this.props.verticalAlign === 'bottom') {
        style.top += this.offset.offsetHeight;
      } else if (this.props.verticalAlign === 'middle') {
        style.left += this.offset.offsetWidth;
      } else if (this.props.verticalAlign === 'top') {
        style.left += this.offset.offsetWidth;
        style.top -= this.offset.offsetHeight;
      }

      if (showOnTop) {
        style.transform = `translateY(calc(-100% - ${
          this.offset.offsetHeight + 10
        }px)`;
        style.animation = 'none';
        animation = fadeInTop;
      }
    }

    const event = this.props.context ? 'onContextMenu' : 'onClick';

    let content = (
      <Content
        style={
          this.props.contentStyle
            ? { ...style, ...this.props.contentStyle }
            : style
        }
        width={(this.state.widthOverride || this.props.width) * scale}
        align={this.props.align}
        ref={(element) => {
          this.dropdownMenu = element;
        }}
        open={this.state.open}
        className="dropdown-content"
        onMouseOver={this.props.onMouseOver}
        animation={animation}
        noAnimation={noAnimation}
        showScroll={this.props.showScroll}
        zIndex={this.props.zIndex}
        overflowXHidden={this.props.overflowXHidden}
      >
        {!!error && <ErrorMsg>An Error Occured</ErrorMsg>}

        {!error &&
          (typeof this.props.children === 'function'
            ? this.props.children({
                closeDropdown: this.handleChildClose,
                open: this.state.open,
                setWidth: this.setWidth,
              })
            : this.props.children)}
      </Content>
    );

    if (!this.props.matchTheme) {
      content = <ThemeProvider theme={quantum}>{content}</ThemeProvider>;
    }

    const triggerProps = {
      [event]: (e) => {
        if (onClick) {
          onClick(e);
        }
        this.handleShow(e);
      },
      onMouseDown: this.handleEvent,
    };
    if (this.props.context && this.props.allowClick) {
      delete triggerProps.onMouseDown;
    }

    return (
      <Div className={this.props.className}>
        <ButtonWrapper align={this.props.buttonAlign} ref={this.setupRef}>
          {typeof this.props.trigger === 'function'
            ? this.props.trigger({ [event]: this.handleShow })
            : React.cloneElement(this.props.trigger, triggerProps)}
        </ButtonWrapper>
        {this.props.portal && this.el
          ? ReactDOM.createPortal(content, this.el)
          : content}
      </Div>
    );
  }
}

export default Dropdown;
