/**
 *
 * XeroReauthBanner
 *
 */
import React, { useCallback } from 'react';
import styled from 'styled-components';

import Banner from 'components/Banner';
import useWorkspaceId from 'hooks/useWorkspaceId';
import useGroupId from 'hooks/useGroupId';
import { reconnect } from 'utils/connections';
import useXeroNeedsReauth from 'hooks/useXeroNeedsReauth';
import useIsConsolidation from 'hooks/useIsConsolidation';

const StyledBanner = styled(Banner)`
  background-color: ${props => props.theme.colors.warning};
`;

const Button = styled.button`
  text-decoration: underline;
`;

const XeroReauthBanner = props => {
  const xeroNeedsReauth = useXeroNeedsReauth();
  const workspaceId = useWorkspaceId();
  const groupId = useGroupId();

  // NOTE: the banner won't show up in consolidated companies
  const isConsolidation = useIsConsolidation();

  const handleClick = useCallback(() => {
    reconnect(groupId, workspaceId, 'XERO');
  }, [groupId, workspaceId]);

  if (groupId === -1 || !xeroNeedsReauth) return null;

  return (
    !isConsolidation && (
      <StyledBanner>
        Xero aging reports now available.{' '}
        <Button onClick={handleClick}>
          Re-authenticate and sync each company to gain access.
        </Button>
      </StyledBanner>
    )
  );
};

export default XeroReauthBanner;
