import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Button from 'components/Button';
import ButtonLink from 'components/ButtonLink';
import AvatarList from 'components/AvatarList';

import theme from 'styled-theming';

const colorFont1 = theme('mode', {
  light: '#181922',
  dark: '#fcfdff',
});

export const DisconnectButton = styled.button`
  padding: 0;
  color: ${(props) => props.theme.colors[props.color || 'danger']};
  margin-bottom: 0.25rem;
  margin-top: 0.5rem;
`;
export const ManageButton = styled.button`
  padding: 0;
  color: ${(props) => props.theme.colors.warning};
`;

export const StyledAvatarList = styled(AvatarList)`
  margin-left: 6px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const SyncDateContainer = styled.div`
  display: flex;
`;

export const SyncDateColumn = styled.td`
  vertical-align: top;
`;

export const ActionButtonsContainer = styled.div`
  justify-content: space-evenly;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  height: 100px;
`;

export const PrimaryButtonStyle = styled(Button)`
  width: 6.5rem;
  font-size: 0.675rem;
  padding-left: 3px;
  padding-right: 3px;
  margin-bottom: 6px;
`;

export const PrimaryButton = styled(Button)`
  width: 6.5rem;
  font-size: 0.675rem;
  margin-bottom: 6px;
`;

export const PrimaryButtonLinkStyle = styled(ButtonLink)`
  width: 6.5rem;
  font-size: 0.675rem;
  margin-bottom: 6px;
`;

export const SecondaryButtonStyle = styled(Button)`
  border-color: #d6d8dd !important;
  color: ${colorFont1} !important;
  margin-left: 10px;
  max-width: 60px;
  min-width: 60px;
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
`;

export const ParentContainer = styled.form`
    display: flex;
    padding:10px;
    flex-direction: column;
    background-color: ${(props) => props.theme.body.background}
    justify-content: space-around;
    width:100%;
`;

export const DivRow = styled.div`
  display: flex;
  flex-direction: Row;
  justify-content: space-between;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const GroupNameTitle = styled.div`
  font-size: large;
  width: 20%;
  min-width: 300px;
  padding-left: 10px;
`;

export const LogoDiv = styled.div`
  display: inline-flex;
  flex-direction: Row;
  justify-content: flex-start;
`;

export const LogoHolder = styled.div`
  border-radius: 5px;
  border: 1px solid gray;
  height: 100px;
  width: 150px;
  align-items: center;
  position: relative;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 12px;
  font-weight: bold;
  width: 100%;
`;

export const Div10 = styled.div`
  font-size: 12px;
`;

export const ActionButton = styled.button`
  margin-bottom: 0.25rem;
  padding: 0;
`;

export const ActionButtonLink = styled(Link)`
  margin-bottom: 0.25rem;
  color: ${(props) => props.theme.body.color};
  font-weight: 400;
`;
export const ActionButtonExternalLink = styled.a`
  margin-bottom: 0.25rem;
  color: ${(props) => props.theme.body.color};
  font-weight: 400;
`;

export const Logo = styled.img`
  width: 100%;
`;
