import React from 'react';
import {
  FieldGroup,
  Label,
  Textarea,
  LabelCol,
  InputCol,
} from './styledComponents';

interface AlertTextBoxProps {
  textArea: string;
  handleSetTextArea: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const AlertTextBox: React.FC<AlertTextBoxProps> = ({
  textArea,
  handleSetTextArea,
}) => {
  return (
    <>
      <FieldGroup>
        <LabelCol>
          <Label htmlFor="alertText">Add Note:</Label>
        </LabelCol>
        <InputCol>
          <Textarea
            value={textArea}
            id="alertText"
            name="alertText"
            onChange={handleSetTextArea}
          />
        </InputCol>
      </FieldGroup>
    </>
  );
};

export default AlertTextBox;
