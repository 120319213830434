/**
 *
 * Checkbox
 *
 */

import React, { useMemo } from 'react';
import styled, { css, useTheme } from 'styled-components';

import newId from 'utils/newId';

import checkboxChecked from 'images/checkbox-checked.png';
import checkboxUnchecked from 'images/checkbox-unchecked.png';
import { useController } from 'react-hook-form';
import CheckedIcon from 'components/Icons/CheckedIcon';
import UncheckedIcon from 'components/Icons/UncheckedIcon';

interface ILabel {
  disabled?: boolean;
  hide?: boolean;
  small?: boolean;
  checked?: boolean;
  styleAsDisabled?: boolean;
  partial?: boolean;
  outline?: boolean;
  state?: boolean;
  leftAlign?: boolean;
  checkedColor?: string;
  scale: number;
}

const Div = styled.div<{
  noMargin: boolean;
  small: boolean;
  hide: boolean;
  leftAlign: boolean;
  scale: number;
}>`
  margin-bottom: ${({ noMargin, scale }) => (noMargin ? 0 : 6) * scale}px;
  position: relative;
  display: inline-flex;
  align-items: center;
  min-height: ${(props) => `${(props.small ? 1.2 : 1.5) * props.scale}rem`};
  padding-left: ${(props) =>
    props.leftAlign ? 'none' : `${(props.small ? 1 : 1.5) * props.scale}rem`};
  margin-right: ${(props) => props.scale}rem;
  &:after {
    content: '';
    display: ${(props) => (props.hide ? 'none' : 'block')};
    background-image: url(${checkboxChecked.src});
  }
  &:before {
    content: '';
    display: ${(props) => (props.hide ? 'none' : 'block')};
    background-image: url(${checkboxUnchecked.src});
  }
`;

const Input = styled.input`
  display: none;
`;

const Label = styled.label<ILabel>`
  padding-top: 1px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  &:before {
    position: absolute;
    top: 0.25rem;
    display: ${(props) => (props.hide ? 'none' : 'block')};
    width: ${(props) => `${(props.small ? 0.75 : 1) * (props.scale || 1)}rem`};
    height: ${(props) => `${(props.small ? 0.75 : 1) * (props.scale || 1)}rem`};
    content: '';
    left: 0;
    color: white;
    border-radius: ${({ scale }) => scale * 0.25}rem;
    pointer-events: none;
    user-select: none;
    background-color: ${(props) => {
      if (props.checked || props.state) {
        return props.disabled || props.styleAsDisabled
          ? '#e0ecff'
          : props.theme.colors.info;
      }
      return props.disabled || props.styleAsDisabled ? '#e0ecff' : '#dee2e6';
    }};
  }
  &:after {
    position: absolute;
    top: 0.25rem;
    display: ${(props) => (props.hide ? 'none' : 'block')};
    width: ${(props) => `${(props.small ? 0.75 : 1) * (props.scale || 1)}rem`};
    height: ${(props) => `${(props.small ? 0.75 : 1) * (props.scale || 1)}rem`};
    content: '';
    left: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }
  ${Input}:checked + &&:after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
    background-color: ${({ disabled, styleAsDisabled, theme, checkedColor }) =>
      disabled || styleAsDisabled
        ? '#abb6c7'
        : checkedColor || theme.colors.info};
    border-radius: ${({ scale }) => scale * 0.25}rem;
  }
  ${(props) =>
    props.partial &&
    css`
      &:before {
        background-color: ${props.disabled || props.styleAsDisabled
          ? '#abb6c7'
          : props.checkedColor || props.theme.colors.info};
      }
      ${Input}:checked + &&:after {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E %3Crect fill='%23fff' x='0' y='0' rx='2' ry='2' width='8' height='8'/%3E %3C/svg%3E");
      }
    `}
  ${(props) =>
    props.small &&
    css`
      font-size: 0.6rem;
    `}
`;

const OutlineLabel = styled.label<ILabel>`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  min-width: 17px;
  min-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OutlineCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
`;

const CheckboxSvg = ({ checked, theme }) =>
  checked ? (
    <CheckedIcon
      height={17}
      width={17}
      color={theme.reportBuilder.drawers.color}
    />
  ) : (
    <UncheckedIcon
      height={17}
      width={17}
      color={theme.reportBuilder.drawers.disabledColor}
    />
  );

function CheckboxInner(props) {
  const {
    children,
    name,
    className,
    disabled,
    styleAsDisabled,
    partial,
    onClick,
    outline,
    onChange,
    checked,
    forwardedRef,
    hide,
    hidden,
    noMargin,
    title,
    state,
    leftAlign = false,
    checkedColor,
    scale,
    testId,
    ...rest
  } = props;
  const checkboxId = useMemo(() => props.id || newId(), [props.id]);
  const theme = useTheme();
  const Component = outline ? (
    <OutlineLabel disabled={disabled} />
  ) : (
    <Label checkedColor={checkedColor} disabled={disabled} scale={scale} />
  );
  const small = props.small ? props.small : false;

  const componentChildren = (
    <>
      {leftAlign && outline && (
        <OutlineCheckbox>
          <CheckboxSvg checked={checked} theme={theme} />
        </OutlineCheckbox>
      )}
      {children}
      {!leftAlign && outline && <CheckboxSvg checked={checked} theme={theme} />}
    </>
  );

  return (
    <Div
      className={className}
      onClick={onClick}
      hide={hide}
      hidden={hidden}
      small={small}
      noMargin={noMargin}
      title={title}
      leftAlign={leftAlign}
      scale={scale}
    >
      <Input
        name={name}
        small={small}
        disabled={disabled}
        styleAsDisabled={styleAsDisabled}
        checked={checked}
        {...rest}
        id={checkboxId}
        type="checkbox"
        onChange={onChange}
        ref={forwardedRef}
      />
      {React.cloneElement(Component, {
        htmlFor: checkboxId,
        disabled: disabled,
        checked: checked,
        styleAsDisabled: styleAsDisabled,
        partial: partial,
        small: small,
        hide: hide,
        state: state,
        leftAlign: leftAlign,
        children: componentChildren,
        'data-testid': testId,
      })}
    </Div>
  );
}

const Checkbox = React.forwardRef<
  HTMLInputElement,
  {
    children?: React.ReactNode;
    id?: string | number;
    checked?: boolean;
    defaultChecked?: boolean;
    name?: string | number;
    disabled?: boolean;
    state?: boolean;
    onChange?: (e?: React.ChangeEvent<HTMLInputElement>) => void;
    onClick?: (e: React.MouseEvent) => void;
    outline?: boolean;
    leftAlign?: boolean;
    checkedColor?: string;
    noMargin?: boolean;
    scale?: number;
    testId?: string;
  }
>(function CheckboxRef(props, ref) {
  const { children, scale = 1, disabled = false, ...rest } = props;
  return (
    <CheckboxInner
      scale={scale}
      {...rest}
      disabled={disabled}
      forwardedRef={ref}
    >
      {children}
    </CheckboxInner>
  );
});

export default Checkbox;

export const HookFormCheckbox: React.FC<{
  children: any;
  control: any;
  name: any;
  rules: any;
  defaultValue: any;
}> = ({ children, control, name, rules, defaultValue }) => {
  const {
    field: { ref, value, ...inputProps },
  } = useController({
    name,
    control,
    rules,
    defaultValue: defaultValue || false,
  });

  return (
    <Checkbox {...inputProps} ref={ref} checked={value}>
      {children}
    </Checkbox>
  );
};
