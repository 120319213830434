/**
 *
 * FileUploader
 *
 */

import React, { useCallback, useMemo } from 'react';
import { FileError, useDropzone } from 'react-dropzone';

import { showWarning } from 'utils/popups';
import { get } from 'lodash';
import {
  Dropzone,
  Info,
  LogoContainer,
  Text,
  A1,
  A2,
  HelpSection,
} from './styledComponents';
import fileSize from 'utils/fileSize';
import { CREATE_EXCEL_DATASHEET, GOOGLE_SHEET_QUERY } from '../mutations';
import { useMutation } from '@apollo/client';
import useCompanyId from '../../../hooks/useCompanyId';
import sampleFile from './Excel Sheet Template.xlsx';
import produce from 'immer';
import Spinner from 'components/Spinner';

const MAX_SIZE_IN_BYTES = 10485760; // 10 megabytes
const MAX_NAME_LENGTH = 60;

export type AcceptedFileTypes = 'xlsx' | 'xls' | string;

export interface FileUploaderProps {
  acceptedFileTypes: AcceptedFileTypes[];
  onStage: <T extends File>(files: T[]) => void;
  onExcelSuccess: (googleSpreadsheet: any) => void;
  onExcelError: (errors: any, excelFile: any) => void;
  onError?: ({ title, message }: { title: string; message: string }) => void;
  usedStorage?: number;
  storageCapacity?: number;
  maxFileSize?: number;
  maxNameLength?: number;
  CustomDropzone?: React.ReactElement;
  showConstraints?: boolean;
  defaultImageUrl?: string;
  loading?: boolean;
  disabled?: boolean;
  dispatch: any;
  googleSpreadsheetId?: number;
}

const FileUploader: React.FunctionComponent<FileUploaderProps> = ({
  acceptedFileTypes,
  usedStorage,
  storageCapacity,
  maxFileSize = MAX_SIZE_IN_BYTES,
  maxNameLength = MAX_NAME_LENGTH,
  CustomDropzone,
  disabled,
  onExcelSuccess,
  onExcelError,
  googleSpreadsheetId,
}) => {
  const [
    createExcelDatasheet,
    { loading: loadingCreateExcel, error: errorCreateExcel },
  ] = useMutation(CREATE_EXCEL_DATASHEET);
  const companyId = useCompanyId();

  const validator = useCallback(
    <T extends File>(file: T): FileError | FileError[] | null => {
      const pathSplit = file.name.split('.');
      const extension = pathSplit[pathSplit.length - 1];

      if (acceptedFileTypes.indexOf(extension) === -1) {
        return {
          code: 'file-type-not-allowed',
          message: `The file type ${extension} is not allowed.`,
        };
      }

      if (usedStorage && storageCapacity) {
        if (file.size + usedStorage > storageCapacity) {
          return {
            code: 'max-capacity-reached',
            message: 'The file size exceeds the available storage space.',
          };
        }
      }
      if (file.name.length > maxNameLength) {
        return {
          code: 'file-name-too-long',
          message: `The file name exceeds the maximum length of ${maxNameLength} characters.`,
        };
      }
      return null;
    },
    [acceptedFileTypes, usedStorage, storageCapacity, maxNameLength]
  );

  const onDrop = useCallback(
    async (input) => {
      const excelFile = input[0];
      validator(excelFile);
      const { data } = await createExcelDatasheet({
        variables: {
          companyId: companyId,
          googleSpreadsheetId: googleSpreadsheetId,
          excelFile: excelFile,
        },
        update: (
          cache,
          {
            data: {
              createExcelDatasheet: { success, googleSpreadsheet },
            },
          }
        ) => {
          if (success) {
            const cacheData = cache.readQuery({
              query: GOOGLE_SHEET_QUERY,
              variables: {
                companyId,
              },
            });

            cache.writeQuery({
              query: GOOGLE_SHEET_QUERY,
              variables: {
                companyId,
              },
              data: produce(cacheData, (draft) => {
                const existing = draft.company.googleSpreadsheets.find(
                  (item) => item.id === googleSpreadsheet.id
                );

                if (!existing) {
                  draft.company.googleSpreadsheets = [
                    ...draft.company.googleSpreadsheets,
                    googleSpreadsheet,
                  ];
                }
              }),
            });
          }
        },
      });
      if (
        !data.createExcelDatasheet.success &&
        data.createExcelDatasheet.errors
      ) {
        onExcelError(data.createExcelDatasheet.errors, excelFile);
      } else if (data.createExcelDatasheet.success) {
        onExcelSuccess(data.createExcelDatasheet.googleSpreadsheet);
      }
    },
    [
      createExcelDatasheet,
      companyId,
      onExcelSuccess,
      onExcelError,
      googleSpreadsheetId,
      validator,
    ]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected: (files) => {
      if (files.length) {
        let message = '';
        const errorCode = get(files[0], 'errors[0].code', '');
        const errorMessage = get(files[0], 'errors[0].message', '');
        if (errorCode === 'file-too-large') {
          message = `Maximum file upload size is ${fileSize(maxFileSize)}.`;
        } else if (files.length > 1) {
          message = 'Only 1 file is allowed at a time';
        } else if (
          errorCode === 'max-capacity-reached' ||
          errorCode === 'file-name-too-long' ||
          errorCode === 'file-type-not-allowed'
        ) {
          message = errorMessage;
        }
        showWarning({
          title: 'Oops...',
          text: message,
        });
      }
    },
    validator,
    multiple: false,
    maxSize: maxFileSize,
  });

  const acceptedFiles = acceptedFileTypes
    .map((fileType) => `.${fileType}`)
    .join(', ');

  const DropzoneInput = useMemo(
    () =>
      loadingCreateExcel || errorCreateExcel ? (
        <Spinner style={{ marginTop: '20px' }} />
      ) : (
        <>
          <input
            disabled={disabled}
            {...getInputProps()}
            accept={acceptedFiles}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              <LogoContainer>
                <span className="mdi mdi-plus-circle-outline"></span>
              </LogoContainer>
            </div>
            <div>
              <Text>Drag & drop or click to choose a file</Text>
              <Info style={{ fontSize: '8pt' }}>
                {<span className="mdi mdi-circle-medium"></span>} Max File Size{' '}
                {<span>{fileSize(maxFileSize)}</span>}
              </Info>
            </div>
          </div>
        </>
      ),
    [
      acceptedFiles,
      disabled,
      getInputProps,
      maxFileSize,
      loadingCreateExcel,
      errorCreateExcel,
    ]
  );

  return (
    <div>
      <HelpSection>Step 1: Download Template</HelpSection>
      <A1 href={`${sampleFile}`} download={'Excel Sheet Template.xlsx'}>
        <span className="mdi mdi-download" />
        <span style={{ paddingLeft: '5px' }}>Download Excel Template</span>
      </A1>
      <HelpSection>
        Step 2: Upload Excel Sheet
        <A2
          href="https://knowledge.reachreporting.com/how-to-use-excel-sheet-as-a-datasheet"
          target="_blank"
        >
          <span className="mdi mdi-school" />
          <span style={{ paddingLeft: '5px' }}>Help Center Article</span>
        </A2>
      </HelpSection>
      <div {...getRootProps()}>
        {CustomDropzone ? (
          React.cloneElement(CustomDropzone, {
            isDragActive,
            children: DropzoneInput,
          })
        ) : (
          <Dropzone dragActive={isDragActive}>{DropzoneInput}</Dropzone>
        )}
      </div>
    </div>
  );
};

export default FileUploader;
