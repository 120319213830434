import styled, { css } from 'styled-components';
import Button from 'components/Button';

export const AccountDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
export const AccountList = styled.div`
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  padding: 5px;
`;

export const AccountEntry = styled.div`
  display: flex;
`;

export const GoogleButton = styled.button`
  width: 480px;
  padding: 0;
  margin: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  border: solid 1px ${({ theme }) => theme.colors.default};
  border-radius: 4px;
  padding: 7px;
  font-size: 0.875rem;
  img {
    position: absolute;
    left: 20px;
  }
`;

export const AccountButton = styled(Button).attrs({
  color: 'default',
  outline: true,
})`
  max-width: 430px;
  border: line;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-right: 3px;
  flex-grow: 1;
  ${(props) =>
    props.arrow &&
    css`
      & i {
        visibility: hidden;
        position: absolute;
        right: 0.5rem;
        font-size: 1.3rem;
        top: 50%;
        transform: translateY(-50%);
      }
      &:hover i {
        visibility: visible;
      }
    `}
`;

export const DeleteButton = styled(Button).attrs({
  color: 'danger',
  outline: true,
})`
  margin: 3px;
`;

export const ConfirmButton = styled(Button).attrs({
  color: 'success',
  outline: true,
})``;

export const Title = styled.h4`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const DivSuccess = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;
  align-items: baseline;
`;
export const DivSuccessContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;
export const DivConnected = styled.p`
  flex: 2;
`;

export const SuccessText = styled.p`
  color: #4bb543;
  padding-left: 10px;
`;
export const HelpSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 0.25rem;
  max-width: 479px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
`;

export const A1 = styled.a`
  color: ${(props) => props.theme.colors.link};
  font-size: 14px;
  width: 480px;
  border: 1px solid ${({ theme }) => theme.colors.link};
  background-color: transparent;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  margin-bottom: 1rem;
  font-size: 12px;
`;

export const A2 = styled.a`
  color: ${(props) => props.theme.colors.link};
  font-size: 14px;
  margin-left: auto;
`;
