/**
 *
 * Button
 *
 */

import React from 'react';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';

const borderColor = theme('mode', {
  light: '#617182',
  dark: '#30313a',
});

const color = theme('mode', {
  light: '#617182',
  dark: '#94959c',
});

const hoverColor = theme('mode', {
  light: '#f0f6ff',
  dark: '#d7d9de',
});

const hoverBgColor = theme('mode', {
  light: '#617182',
  dark: '#30313a',
});

const Button = styled.button`
  border: 2px solid ${borderColor};
  font-size: 14px;
  padding: 11px 34px;
  letter-spacing: 1.43px;
  border-radius: 6px;
  font-weight: bold;
  color: ${color};
  background: transparent;
  transition: background 0.25s, color 0.25s;
  &:hover:not([disabled]) {
    background: ${hoverBgColor};
    color: ${hoverColor};
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
    background: ${({ theme }) => theme.button.default.disabled} !important};
  }
  ${(props) =>
    props.active &&
    css`
      background: ${hoverBgColor};
      color: ${hoverColor};
    `}
`;

export default Button;
