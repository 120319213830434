import media from 'media';
import React from 'react';
import styled, { css } from 'styled-components';
import { headerZIndex } from 'utils/zIndex';

type BlurProps = {
  className?: string;
  children?: React.ReactNode;
};

export const Blur = React.forwardRef<HTMLDivElement, BlurProps>(
  ({ className, children }, ref) => {
    return (
      <div className={`modal-blur ${className}`} ref={ref}>
        {children}
      </div>
    );
  }
);

Blur.displayName = 'Blur';

export const Container = styled(Blur)<{
  sidebarMargin: boolean;
  minimal: boolean;
}>`
  display: flex;
  flex-direction: column;
  color: ${(props) =>
    props.minimal ? props.theme.minimalHeader.color : props.theme.header.color};
  background: ${(props) =>
    props.minimal
      ? props.theme.minimalHeader.background
      : props.theme.header.background};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
  z-index: ${headerZIndex};

  ${(props) =>
    props.sidebarMargin
      ? css`
          padding-left: ${(p) => p.theme.sidebar.width};
          margin-right: ${(p) => p.theme.content.marginX}px;
        `
      : css`
          padding-left: 0px;
          ${media.phone`
              padding-left: 5px;
            `}
        `}

  ${(props) =>
    props.minimal &&
    css`
      padding-left: 0;
    `}

  & svg path {
    fill: ${(props) =>
      props.minimal
        ? props.theme.minimalHeader.color
        : props.theme.header.color};
  }
`;

export const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: ${(props) => props.theme.header.height};
`;

export const Bottom = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  max-width: 100%;
  flex: 1 1 auto;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-width: 0;
  max-width: 50%;
  flex: 0 0 auto;
`;

export const Fade = styled.div`
  height: 3px;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    ${(props) => props.theme.header.background} 100%
  );
`;

export const CompanyDropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px;
  transition: background-color 0.15s ease-out, color 0.15s ease-out;
  color: ${(props) => props.theme.header.color};
  &:hover {
    background-color: ${(props) =>
      props.theme.mode === 'light'
        ? props.theme.header.buttonHighlight
        : '#393E46'};
  }
  svg > path {
    fill: ${(props) => props.theme.header.color};
    transition: fill 0.15s ease-out;
  }

  svg {
    min-width: 24px;
  }
`;

export const NoCompaniesDropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 10px;
  color: ${(props) => props.theme.header.color};
`;

export const CompanyName = styled.div`
  height: 100%;
  font-size: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DropdownContainer = styled.div<{ sidebarExpanded: boolean }>`
  overflow: hidden;
  transition: margin-left 0.15s ease-out;
  ${(props) =>
    props.sidebarExpanded &&
    css`
      margin-left: 100px;
    `};
`;
