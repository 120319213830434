import { useEffect } from 'react';
import PubSub from 'pubsub-js';

export const OPEN_BUDGETS_MODAL = 'OPEN_BUDGETS_MODAL';

function unsubscribe(subscription) {
  PubSub.unsubscribe(subscription);
}

export function publish(topic) {
  PubSub.publish(topic);
}

export function usePubSub(topic, callback) {
  useEffect(() => {
    const subscription = PubSub.subscribe(topic, callback);
    return () => unsubscribe(subscription);
  }, [callback, topic]);
}
