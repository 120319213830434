import R from 'ramda';
import deepmerge from 'deepmerge';

function getEmptyArray(length, value = 0) {
  return Array.apply(null, Array(length)).map(Number.prototype.valueOf, value);
}

export function getLast6MonthAverage(values) {
  return R.mean(values.slice(values.length - 7, values.length - 1));
}

export function sumArrays(arrays, length) {
  if (!arrays.length) {
    return getEmptyArray(length);
  }
  return R.pipe(R.transpose, R.map(R.sum))(arrays.filter((array) => !!array));
}

const emptyTarget = (value) => (Array.isArray(value) ? [] : {});
const clone = (value, options) => deepmerge(emptyTarget(value), value, options);

function combineMerge(target, source, options) {
  const destination = target.slice();

  source.forEach(function (e, i) {
    if (typeof destination[i] === 'undefined') {
      const cloneRequested = options.clone !== false;
      const shouldClone = cloneRequested && options.isMergeableObject(e);
      destination[i] = shouldClone ? clone(e, options) : e;
    } else if (
      (e === null || e === undefined) &&
      options.isMergeableObject(target[i])
    ) {
      destination[i] = target[i];
    } else if (options.isMergeableObject(e)) {
      destination[i] = deepmerge(target[i], e, options);
    } else if (target.indexOf(e) === -1) {
      destination.push(e);
    }
  });
  return destination;
}

export function merge(a, b) {
  return deepmerge(a, b, { arrayMerge: combineMerge });
}

const arrayMoveMutate = (array, from, to) => {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
};

export function arrayMove(array, from, to) {
  array = array.slice();
  arrayMoveMutate(array, from, to);
  return array;
}

export function arrayElementsEqual(array1, array2) {
  return (
    array1 &&
    array2 &&
    array1.length === array2.length &&
    array1.every((value, index) => value === array2[index])
  );
}
