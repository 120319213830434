import React from 'react';
import styled from 'styled-components';

import { useHistory } from 'react-router-dom';
import useWorkspaceId from 'hooks/useWorkspaceId';

import HomeIcon from 'components/Icons/HomeIcon';

const Container = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  transition: background-color 0.15s ease-out;
  svg > path {
    fill: ${(props) => props.theme.header.color};
    transition: fill 0.15s ease-out, stroke 0.15s ease-out,
      stroke-width 0.15s ease-out;
  }
  &:hover {
    background-color: ${(props) =>
      props.theme.mode === 'light'
        ? props.theme.header.buttonHighlight
        : '#393E46'};
  }
`;

interface WorkspaceHomeButtonProps {
  height?: number;
  width?: number;
  style?: React.CSSProperties;
}

const WorkspaceHomeButton: React.FC<WorkspaceHomeButtonProps> = ({
  height = 24,
  width = 24,
  style,
}) => {
  const history = useHistory();
  const workspaceId = useWorkspaceId();

  const handleClick = () => {
    history.push(`/workspaces/${workspaceId}`);
  };

  return (
    <Container onClick={handleClick} style={style}>
      <HomeIcon height={height} width={width} />
    </Container>
  );
};

export default WorkspaceHomeButton;
