import React from 'react';
import gql from 'graphql-tag';
import hoistNonReactStatics from 'hoist-non-react-statics';

import getDisplayName from 'utils/getDisplayName';
import useWorkspaceId from 'hooks/useWorkspaceId';

export const WORKSPACE_ID_QUERY = gql`
  query WithWorkspaceId {
    currentWorkspaceId @client
  }
`;

export interface WithWorkspaceIdProps {
  workspaceId: number;
}

const withWorkspaceId = <P extends WithWorkspaceIdProps>(
  Component: React.ComponentType<P>
) => {
  const WithWorkspaceId = props => {
    const workspaceId = useWorkspaceId();

    return <Component {...props} workspaceId={workspaceId} />;
  };

  WithWorkspaceId.displayName = `WithWorkspaceId(${getDisplayName(Component)})`;

  hoistNonReactStatics(WithWorkspaceId, Component);

  return WithWorkspaceId;
};

export default withWorkspaceId;
