import styled from 'styled-components';
import ButtonBase from 'components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: right;
  align-items: center;
  margin-top: 10px;
`;

export const Limit = styled.span`
  font-size: 0.875rem;
  i {
    color: ${({ theme }) => theme.colors.warning};
    margin: 0 0.5rem;
  }
`;

export const Button = styled(ButtonBase)<{
  onClick: () => void;
  color: string;
  children: string;
}>``;
