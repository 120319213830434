import gql from 'graphql-tag';

export const ACCOUNT_INFO = gql`
  query UserSettings {
    currentUser {
      email
      firstName
      lastName
      hasTwoFactorAuth
    }
  }
`;
