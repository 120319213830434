const startMeasure =
  typeof window === 'undefined'
    ? () => process.hrtime()
    : () => window.performance.now();
const endMeasure =
  typeof window === 'undefined'
    ? (start) => process.hrtime(start)
    : () => window.performance.now();
const BROWSER = typeof window !== 'undefined';

function handleCallback(name, callback, companyId = -1) {
  const start = startMeasure();
  const result = callback();
  const end = endMeasure(start);

  if (BROWSER) {
    console.info('Execution time (%s): %dms', name, end - start);
  } else {
    console.info(
      `Execution time ${
        companyId === -1 ? '' : ` (companyId: ${companyId})`
      } (%s): %ds %dms`,
      name,
      end[0],
      end[1] / 1000000
    );
  }

  return result;
}

module.exports = function (funcToWrap, callback, companyId = -1) {
  if (typeof funcToWrap === 'string') {
    return handleCallback(funcToWrap, callback, companyId);
  }

  return function (...args) {
    const start = process.hrtime();
    const result = funcToWrap(...args);
    const end = process.hrtime(start);

    console.info(
      `Execution time ${
        companyId === -1 ? '' : ` (companyId: ${companyId})`
      } (%s): %ds %dms`,
      funcToWrap.name,
      end[0],
      end[1] / 1000000
    );

    return result;
  };
};
