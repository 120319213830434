import React, { memo, useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';

import Modal from 'components/Modal';
import gql from 'graphql-tag';
import { captureException } from 'utils/sentry';
import { showSuccess, showWarning, showError } from 'utils/popups';
import AlertTextBox from './AlertTextBox';
import {
  DivRightActionButtons,
  DivRightCheckbox,
  ActionButton,
  SendToSelfCheckbox,
} from './styledComponents';

const SEND_CLIENT_ALERT = gql`
  mutation SendClientAlerts(
    $workspaceId: Int!
    $companyId: Int!
    $userIdsList: [Int!]!
    $selfCopy: Boolean!
    $alertText: String
  ) {
    sendClientAlerts(
      workspaceId: $workspaceId
      companyId: $companyId
      userIdsList: $userIdsList
      selfCopy: $selfCopy
      alertText: $alertText
    ) {
      success
      error
    }
  }
`;

interface Props {
  workspaceId: number;
  companyId: number;
  show: boolean;
  userFullName: string | null;
  userIdsList: any;
  onClose: () => void;
}

// eslint-disable-next-line
const ClientAlertModal = memo<Props>(
  ({ workspaceId, companyId, userFullName, show, onClose, userIdsList }) => {
    const [checked, setChecked] = useState(false);
    const [textArea, setTextArea] = useState('');
    const handleSetTextArea = useCallback(
      (e) => {
        setTextArea(e.target.value);
      },
      [setTextArea]
    );

    const [sendClientAlerts] = useMutation(SEND_CLIENT_ALERT);

    const handleSend = useCallback(() => {
      const alertText = textArea;

      sendClientAlerts({
        variables: {
          workspaceId,
          companyId,
          userIdsList,
          selfCopy: checked,
          alertText,
        },
      })
        .then((result) => {
          if (result.data.sendClientAlerts.success !== true) {
            showError({
              title: 'There was a problem sending the alert(s).',
              text:
                result.data.sendClientAlerts.error ||
                'An error occurred while sending the alert(s). Please try again.',
            });
          } else {
            setTextArea('');
            setChecked(false);
            onClose && onClose();
            showSuccess({
              text: 'Alert(s) sent successfully!',
            });
          }
        })
        .catch((error) => {
          captureException(error);
          showError({
            text: 'An error occurred while sending the alert(s). Please try again.',
          });
          onClose && onClose();
        });
    }, [
      sendClientAlerts,
      companyId,
      workspaceId,
      userIdsList,
      textArea,
      onClose,
      checked,
    ]);

    return (
      <Modal
        title={userFullName ? `Alert ${userFullName}` : 'Alert All Clients'}
        show={show}
        onClose={() => {
          setTextArea('');
          setChecked(false);
          onClose && onClose();
        }}
        width={600}
      >
        <AlertTextBox
          textArea={textArea}
          handleSetTextArea={handleSetTextArea}
        />
        <DivRightCheckbox>
          <SendToSelfCheckbox
            checked={checked}
            onChange={() => {
              setChecked(!checked);
            }}
          >
            Email myself a copy
          </SendToSelfCheckbox>
        </DivRightCheckbox>
        <DivRightActionButtons>
          <ActionButton
            color="primary"
            onClick={() => {
              handleSend();
            }}
          >
            Send
          </ActionButton>
          <ActionButton
            color="default"
            outline={true}
            onClick={() => {
              showWarning({
                text: 'Are you sure you want to cancel? This will discard your message.',
                allowOutsideClick: false,
                showConfirmButton: true,
                showDenyButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: 'No',
              }).then((result) => {
                if (result.value) {
                  setTextArea('');
                  setChecked(false);
                  onClose && onClose();
                }
              });
            }}
          >
            Cancel
          </ActionButton>
        </DivRightActionButtons>
      </Modal>
    );
  }
);

export default ClientAlertModal;
