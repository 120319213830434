/**
 *
 * UserSettingsModal
 *
 */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';

import Modal from 'components/Modal';
import VerticalTabs from 'components/VerticalTabs';
import Spinner from 'components/Spinner';
import AccountInfo from './AccountInfo';
import { ACCOUNT_INFO } from './queries';
import ChangePassword from './ChangePassword';
import TwoFactorAuthForm from '../TwoFactorAuthForm/index';

const tabs = ['Account Info', 'Change Password', '2FA'];

const Div = styled.div`
  display: flex;
`;

const Left = styled.div`
  width: 11rem;
  border-right: 1px solid #ccc;
  margin-right: 1rem;
`;

const UserSettingsModal = ({ show, onClose }) => {
  const [tab, setTab] = useState(0);

  const { data } = useQuery(ACCOUNT_INFO);

  return (
    <Modal title="User Settings" show={show} width={800} onClose={onClose}>
      {data && data.currentUser ? (
        <Div>
          <Left>
            <VerticalTabs tabs={tabs} current={tab} onChange={setTab} />
          </Left>
          {tab === 0 && <AccountInfo user={data.currentUser} />}
          {tab === 1 && <ChangePassword />}
          {tab === 2 && (
            <TwoFactorAuthForm preface={'Two-factor Authentication'} />
          )}
        </Div>
      ) : (
        <Spinner />
      )}
    </Modal>
  );
};

export default UserSettingsModal;
