import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import useCompanyId from './useCompanyId';

const QUERY = gql`
  query UseCompanyName($companyId: Int!) {
    company(id: $companyId) {
      id
      name
    }
  }
`;

export default function useCompanyName() {
  const companyId = useCompanyId();

  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  if (loading) return null;
  if (error) return null;
  if (!data || !data.company) {
    return null;
  }

  return data.company.name;
}
