import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useMemo } from 'react';
import useWorkspaceId from './useWorkspaceId';

const QUERY = gql`
  query StripeUnpaid($workspaceId: Int!) {
    workspace(id: $workspaceId) {
      id
      unpaid
      isBillingUser
    }
  }
`;

export default function useStripeUnpaid() {
  const workspaceId = useWorkspaceId();
  const { data } = useQuery(QUERY, {
    variables: {
      workspaceId,
    },
    skip: !workspaceId,
  });

  return useMemo(() => {
    if (!data || !data.workspace) return { unpaid: null, isBillingUser: null };

    const unpaid = data && data.workspace && data.workspace.unpaid;
    const isBillingUser =
      data && data.workspace && data.workspace.isBillingUser;

    return { unpaid, isBillingUser };
  }, [data]);
}
