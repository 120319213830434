/**
 *
 * BookMonth
 *
 */
import React, { useMemo, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import styled, { css } from 'styled-components';
import moment from 'moment';
import gql from 'graphql-tag';

import DatePicker from 'components/DatePicker';

const defaultBookMonth = moment.utc().startOf('month');

const Div = styled.div`
  display: flex;
  align-items: center;
  ${({ minimal }) =>
    minimal &&
    css`
      flex-direction: column;
      justify-content: center;
    `}
`;

const Launcher = styled.div`
  width: 4rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.link};
`;

const UPDATE = gql`
  mutation SetBookMonth($companyId: Int!, $bookMonth: Date) {
    setCompanyBookMonth(companyId: $companyId, bookMonth: $bookMonth) {
      id
      bookMonth
      isBookMonthFixed
    }
  }
`;

const BookMonth = ({ bookMonth, isBookMonthFixed, companyId, minimal }) => {
  const [updateBookMonth] = useMutation(UPDATE);

  const lastMonth = useMemo(
    () => moment.utc(bookMonth || defaultBookMonth).subtract(1, 'month'),
    [bookMonth]
  );

  const handleRevert = useCallback(() => {
    updateBookMonth({
      variables: {
        companyId,
        bookMonth: null,
      },
    });
  }, [companyId, updateBookMonth]);

  const onBookMonthChange = useCallback(
    (newLastMonth) => {
      updateBookMonth({
        variables: {
          companyId,
          bookMonth: newLastMonth.clone().add(1, 'month'),
        },
        optimisticResponse: {
          __typename: 'Mutation',
          setCompanyBookMonth: {
            __typename: 'Company',
            id: companyId,
            bookMonth: newLastMonth.clone().add(1, 'month').format(),
            isBookMonthFixed: true,
          },
        },
      });
    },
    [companyId, updateBookMonth]
  );

  return (
    <Div onClick={(e) => e.stopPropagation()} minimal={minimal}>
      <DatePicker
        initialDate={lastMonth}
        onChange={onBookMonthChange}
        launcher={<Launcher>{lastMonth.format('MMM YYYY')}</Launcher>}
        portal
        changeOnClick
        hideCancel
      />
      {isBookMonthFixed && <button onClick={handleRevert}>Revert</button>}
    </Div>
  );
};

export default BookMonth;
