import React from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const QUERY = gql`
  query CompanyRoute {
    isLoggedIn @client
  }
`;

export default function useIsLoggedIn() {
  const { data } = useQuery(QUERY);

  return data && data.isLoggedIn;
}
