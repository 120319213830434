/**
 *
 * List
 *
 */

import React from 'react';
import styled from 'styled-components';
import { Form } from 'informed';

import CheckboxField from 'components/CheckboxField';
import Button from 'components/Button';
import TextField from 'components/TextField';

const Div = styled.div``;

const StyledForm = styled(Form)``;

const Scroll = styled.div`
  margin-top: 5px;
  max-height: calc(100vh - 270px);
  overflow-y: auto;

  @media screen and (min-height: 663px) {
    max-height: 400px;
  }
  @media screen and (min-height: 763px) {
    max-height: 500px;
  }
`;

const Section = styled.div``;

const StyledCheckboxField = styled(CheckboxField)`
  & label {
    font-size: 0.775rem;
  }
  margin-bottom: 0;
  vertical-align: middle;
`;

const SubItem = styled.div`
  margin-left: 15px;
`;

const SubText = styled.span`
  font-size: 10px;
  margin-left: 5px;
  vertical-align: middle;
  display: none;
  ${StyledCheckboxField}:hover & {
    display: inline;
  }
`;

const Toggle = styled.div`
  display: ${(props) => (props.visible ? 'block' : 'none')};
`;

const Bottom = styled.div`
  text-align: right;
  margin-top: 20px;
  display: flex;
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
`;

const FolderIcon = styled.i.attrs({
  className: 'mdi-set mdi-folder',
})`
  font-size: 18px;
  vertical-align: middle;
`;

const noneSelected = (values) =>
  !Object.keys(values).find((key) => values[key]);

const disableSave = (values, saving) => {
  const { name, ...rest } = values;
  const none = noneSelected(rest);

  return none || !name || saving;
};

function List(props) {
  const { companies, firms, onSubmit, onClose, initialValues, saving } = props;
  return (
    <Div>
      <StyledForm onSubmit={onSubmit} initialValues={initialValues}>
        {({ formState }) => {
          return (
            <React.Fragment>
              <div>Select who will have access to the template.</div>
              <Scroll>
                <Section>
                  <StyledCheckboxField field="workspace">
                    <span>Entire Workspace</span>
                    <SubText>
                      Available to users in all companies in the workspace.
                    </SubText>
                  </StyledCheckboxField>
                </Section>
                <Section>
                  <StyledCheckboxField field="personalLibrary">
                    <span>Only Me</span>
                    <SubText>Available to just you in any company.</SubText>
                  </StyledCheckboxField>
                </Section>

                {firms.map((firm) => (
                  <Section key={firm.id}>
                    <StyledCheckboxField field={`firm_${firm.id}`}>
                      {firm.name} <FolderIcon />
                      <SubText>
                        Available to users to all companies in this folder.
                      </SubText>
                    </StyledCheckboxField>
                    <Toggle visible={!formState.values[`firm_${firm.id}`]}>
                      {firm.companies.map((company) => (
                        <SubItem key={company.id}>
                          <StyledCheckboxField
                            field={`firm_${firm.id}_${company.id}`}
                          >
                            {company.name}
                            <SubText>
                              Available to users in this company.
                            </SubText>
                          </StyledCheckboxField>
                        </SubItem>
                      ))}
                    </Toggle>
                  </Section>
                ))}
              </Scroll>
              <Bottom>
                <TextField field="name" />
                <StyledButton
                  color="success"
                  type="submit"
                  disabled={disableSave(formState.values, saving)}
                  loading={saving}
                >
                  Save
                </StyledButton>
              </Bottom>
            </React.Fragment>
          );
        }}
      </StyledForm>
    </Div>
  );
}

export default List;
