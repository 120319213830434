/**
 *
 * Tooltip
 *
 */

import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Manager, Reference, Popper } from 'react-popper';
import ReactTimeout from 'react-timeout';
import PubSub from 'pubsub-js';
import { tooltipZIndex } from 'utils/zIndex';

const Div = styled.div`
  z-index: ${tooltipZIndex};
  margin: 0;
  text-align: left;
  text-align: start;
  position: absolute;
  display: block;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  text-decoration: none;
  line-height: 1.5;
  text-shadow: none;
  pointer-events: none;

  padding: 0.4rem 0;
`;

const Inner = styled.div`
  ${(props) => !!props.width && `width: ${props.width}px;`} max-width: 200px;
  background-color: #151726;
  color: #e7f1fd;
  box-shadow: 0 1px 3px 0 rgba(108, 158, 195, 0.35);
  font-size: 0.6875rem;
  border-radius: 30px;
  padding: 7px 15px;
  text-align: center;
  min-width: 80px;
`;

const Arrow = styled.div`
  &[data-placement*='top'] {
    bottom: 0px;
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
    &:before {
      position: absolute;
      display: block;
      border-color: transparent;
      border-style: solid;
      content: '';
      border-top-color: #151726;
      border-width: 0.4rem 0.4rem 0;
    }
  }
  &[data-placement*='bottom'] {
    top: 0px;
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
    &:before {
      position: absolute;
      display: block;
      border-color: transparent;
      border-style: solid;
      content: '';
      border-bottom-color: #151726;
      border-width: 0 0.4rem 0.4rem 0.4rem;
    }
  }
  &[data-placement*='right'] {
    left: -0.3rem;
    position: absolute;
    display: block;
    height: 0.8rem;
    width: 0.4rem;
    &:before {
      position: absolute;
      display: block;
      border-color: transparent;
      border-style: solid;
      content: '';
      border-right-color: #151726;
      border-width: 0.4rem 0.4rem 0.4rem 0;
    }
  }
`;

function Tooltip(props) {
  const {
    placement = 'top',
    delay = 500,
    positionFixed = false,
    forcePosition = false,
    offset,
    disabled = false,
    setTimeout,
    clearTimeout,
    container,
    children,
    onClick,
    onMouseDown,
    className,
    width,
    content,
  } = props;

  const [open, setOpen] = useState(false);
  const timeout = useRef(null);

  const handleScroll = useCallback(() => {
    if (open) {
      setOpen(false);
    }

    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
  }, [clearTimeout, open]);

  const handleOpen = useCallback(() => {
    setOpen(true);
    timeout.current = null;
  }, []);

  const handleOpenDelay = useCallback(
    (e) => {
      e.persist();
      if (!disabled && !timeout.current) {
        timeout.current = setTimeout(handleOpen, delay);
      }
    },
    [delay, disabled, handleOpen, setTimeout]
  );

  const handleClose = useCallback(
    (e) => {
      e.persist();
      setOpen(false);
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
    },
    [clearTimeout]
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    const subscription = PubSub.subscribe('SCROLL', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      PubSub.unsubscribe(subscription);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (disabled && open) {
      setOpen(false);
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
    }
  }, [clearTimeout, disabled, open]);

  const modifiers = useMemo(() => {
    const mods = [];

    if (forcePosition) {
      mods.push({
        name: 'preventOverflow',
        enabled: false,
      });
      mods.push({
        name: 'hide',
        enabled: false,
      });
    }
    if (offset) {
      mods.push({
        name: 'offset',
        enabled: true,
        options: {
          offset,
        },
      });
    }

    return mods;
  }, [forcePosition, offset]);

  const childResult = useMemo(() => {
    if (typeof children === 'function') {
      return children({
        close: this.handleClose,
      });
    }
    return children;
  }, [children]);

  const Container = container || 'span';

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <Container
            ref={ref}
            onMouseOver={handleOpenDelay}
            onMouseOut={handleClose}
            onClick={onClick}
            onMouseDown={onMouseDown}
          >
            {childResult}
          </Container>
        )}
      </Reference>
      {open && !disabled && (
        <Popper
          placement={placement}
          positionFixed={positionFixed}
          modifiers={modifiers}
        >
          {({ ref, style, placement: popperPlacement, arrowProps }) =>
            ReactDOM.createPortal(
              <Div
                ref={ref}
                style={style}
                data-placement={popperPlacement}
                className={className}
                onClick={handleClose}
              >
                <Arrow
                  ref={arrowProps.ref}
                  style={arrowProps.style}
                  data-placement={popperPlacement}
                />
                <Inner width={width}>{content}</Inner>
              </Div>,
              document.querySelector('#tooltip')
            )
          }
        </Popper>
      )}
    </Manager>
  );
}

export default memo(ReactTimeout(Tooltip));
