/**
 *
 * ErrorCodePopup
 *
 */
import { useEffect } from 'react';
import errorCodes from 'utils/errorCodes';
import { showError } from 'utils/popups';

const ErrorCodePopup = () => {
  useEffect(() => {
    const error = window.sessionStorage.getItem('error-code');
    if (error && errorCodes[error]) {
      showError({ text: errorCodes[error] });
      window.sessionStorage.removeItem('error-code');
    }
  }, []);

  return null;
};

export default ErrorCodePopup;
