/**
 *
 * RegisterAfter
 *
 */
import OnboardingForm from 'components/OnboardingForm';
import React from 'react';
import styled from 'styled-components';

const RegisterAfter = props => {
  return <OnboardingForm />;
};

export default RegisterAfter;
