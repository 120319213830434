import gql from 'graphql-tag';
import { useQuery, NetworkStatus } from '@apollo/client';
import { useCallback, useMemo } from 'react';

const QUERY = gql`
  query UseAllIntegrationStatus($companyId: Int!) {
    company(id: $companyId) {
      id
      workspaceId
      firmId
      isConsolidation
      isConsolidationEmpty
      consolidationError
      allIntegrationStatus {
        integration
        active
        inactiveCompanyNames
      }
    }
  }
`;

const defaultActive = {
  isConsolidation: false,
  isConsolidationEmpty: false,
  allIntegrationStatus: [],
};

export default function useAllIntegrationStatus(companyId) {
  const { data, loading, error, refetch, networkStatus } = useQuery(QUERY, {
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  const handleRefetch = useCallback(() => {
    return refetch().then(({ data: newData }) => {
      const firstInactive =
        newData.company.allIntegrationStatus.find((item) => !item.active) ||
        null;

      return {
        isConsolidation: newData.company.isConsolidation,
        consolidationError: newData.company.consolidationError,
        firstInactive,
        isConsolidationEmpty: newData.company.isConsolidationEmpty,
        allIntegrationStatus: newData.company.allIntegrationStatus,
      };
    });
  }, [refetch]);

  return useMemo(() => {
    if (loading || error || !data || !data.company) {
      return { loading, error, refetch: handleRefetch, connectionActive: null };
    }

    if (!data || !data.company) {
      return {
        ...defaultActive,
        refetch: handleRefetch,
        loading: loading,
        refetching: networkStatus === NetworkStatus.refetch,
      };
    }

    const firstInactive =
      data.company.allIntegrationStatus.find((item) => !item.active) || null;

    return {
      isConsolidation: data.company.isConsolidation,
      isConsolidationEmpty: data.company.isConsolidationEmpty,
      consolidationError: data.company.consolidationError,
      allIntegrationStatus: data.company.allIntegrationStatus,
      firstInactive,
      refetch: handleRefetch,
      loading: loading,
      refetching: networkStatus === NetworkStatus.refetch,
    };
  }, [data, error, handleRefetch, loading, networkStatus]);
}
