import { useMemo, useState, useCallback } from 'react';
import { useQuery, gql } from '@apollo/client';
import moment from 'moment';
import useTimeout from './useTimeout';

const QUERY = gql`
  query MaintenanceBanner {
    maintenanceModeStart
    maintenanceModeMessage
    showMaintenanceMode
  }
`;

export default function useMaintenanceMode() {
  const [nowTs, setNowTs] = useState(null);

  const { data } = useQuery(QUERY, {
    pollInterval: 120000,
  });

  const start = data?.maintenanceModeStart;
  const message = data?.maintenanceModeMessage;
  const show = data?.showMaintenanceMode;

  const startDate = useMemo(() => {
    if (start) {
      return moment(start);
    }
    return null;
  }, [start]);

  const startTs = useMemo(
    () => startDate && startDate.toDate().valueOf(),
    [startDate]
  );

  const updateNowTs = useCallback(
    (resetTimeout) => {
      if (startTs) {
        setNowTs(new Date().valueOf());
        resetTimeout();
      }
    },
    [startTs]
  );

  useTimeout(updateNowTs, 3000);

  const loading =
    !data ||
    (data.maintenanceModeStart === undefined && !data.showMaintenanceMode) ||
    (!nowTs && data.maintenanceModeStart);

  return useMemo(() => {
    if (!start || !nowTs) {
      return { downDate: null, down: false, show, message, loading };
    }

    return {
      downDate: startDate,
      down: startTs && nowTs > startTs,
      show,
      startTs,
      nowTs,
      message,
      loading,
    };
  }, [loading, message, nowTs, show, start, startDate, startTs]);
}
