import { useCallback, useState } from 'react';
import { SYNC } from '../mutations';
import { useMutation } from '@apollo/client';
import { launchPopup, showConfirm } from 'utils/popups';
import { API_URL } from 'environment';
import { captureException } from 'utils/sentry';
import useCompanyId from 'hooks/useCompanyId';
import { clearTableValues } from 'utils/cache';

export default function useSyncGoogleSheet({ dispatch }) {
  const [sync] = useMutation(SYNC);
  const companyId = useCompanyId();
  const [loading, setLoading] = useState(false);

  const onSync = useCallback(
    async (googleSpreadsheetId, ignoreErrors = false) => {
      try {
        setLoading(true);
        const { data } = await sync({
          variables: {
            googleSpreadsheetId,
            ignoreErrors,
          },
          update: cache => {
            clearTableValues(cache, companyId);
          },
        });

        if (
          data &&
          data.syncGoogleSpreadsheet &&
          data.syncGoogleSpreadsheet.needsRefresh
        ) {
          const shouldConnect = await showConfirm({
            title: 'Connection Failed',
            text: `The Google Account (${data.syncGoogleSpreadsheet.googleAccountEmail}) associated with this google sheet needs to be authorized again. Would you like to sign into google?`,
          });

          if (shouldConnect) {
            launchPopup(`${API_URL}/google_sheets/connect`);
          }
        } else if (
          data &&
          data.syncGoogleSpreadsheet &&
          data.syncGoogleSpreadsheet.errors
        ) {
          dispatch({
            type: 'GOOGLE_SHEET_ERRORS',
            errors: data.syncGoogleSpreadsheet.errors,
            googleSpreadsheetId,
            sync: true,
          });
        } else if (
          data &&
          data.syncGoogleSpreadsheet &&
          data.syncGoogleSpreadsheet.success
        ) {
          if (
            await showConfirm({
              title: 'Sync Successful',
              text: 'Would you like to refresh?',
            })
          ) {
            window.location.reload();
          }
        }
      } catch (err) {
        setLoading(false);
        captureException(err, true);
      }
    },
    [companyId, dispatch, sync]
  );

  return { onSync, loading };
}
