import React, { useState, useCallback } from 'react';
import {
  Control,
  DeepMap,
  FieldError,
  FieldValue,
  FieldValues,
  useController,
} from 'react-hook-form';

import {
  SettingTitle,
  FieldGroup,
  Label,
  ErrorMsg,
  Textarea,
  Bottom,
  LabelCol,
  InputCol,
  PreparedByInput,
} from './styledComponents';

const defaultDisclaimer =
  'The information contained in this report is provided for informational purposes only and is not intended to substitute for obtaining accounting, ' +
  'tax, or financial advice from a professional accountant. Any tax advice contained in this report is not intended to be used for the purpose of ' +
  'avoiding penalties under tax law. While we use reasonable efforts to furnish accurate and up-to-date information, we do not warrant that any ' +
  'information contained in or made available through this report is accurate, complete, reliable, current or error-free. We assume no liability or ' +
  'responsibility for any errors or omissions in the content of this report or delivered information.';

interface ReportSettingsProps {
  errors: DeepMap<FieldValue<any>, FieldError>;
  disclaimer: any;
  register: (any) => any;
  handleUnsavedChanges: () => void;
  getValues?: () => any;
  control: Control<FieldValues>;
}

const PreparedBy = ({ formControl, handleChange }) => {
  const {
    field: { onChange, value },
  } = useController({
    name: 'preparedBy',
    control: formControl,
  });

  const _onChange = useCallback(
    (e) => {
      onChange(e);
      handleChange && handleChange(e);
    },
    [handleChange, onChange]
  );

  return (
    <PreparedByInput
      value={value}
      onChange={_onChange}
      id="preparedBy"
      name="preparedBy"
      type="text"
      placeholder="Reach Reporting"
      required
      warning={value === 'Reach Reporting'}
    />
  );
};

const ReportSettings: React.FC<ReportSettingsProps> = ({
  errors,
  disclaimer,
  register,
  handleUnsavedChanges,
  control,
}) => {
  const [change, setChange] = useState(false);

  const handleChange = useCallback(() => {
    if (!change) {
      setChange(true);
      handleUnsavedChanges();
    }
  }, [change, setChange, handleUnsavedChanges]);

  return (
    <>
      <SettingTitle>Report Settings</SettingTitle>
      <FieldGroup>
        <LabelCol>
          <Label htmlFor="preparedBy">Reports Prepared By</Label>
        </LabelCol>
        <InputCol>
          <PreparedBy formControl={control} handleChange={handleChange} />
        </InputCol>
        {errors && !!errors.preparedBy && <ErrorMsg>Required</ErrorMsg>}
      </FieldGroup>
      <FieldGroup>
        <LabelCol>
          <Label htmlFor="disclaimer">Disclaimer</Label>
        </LabelCol>
        <InputCol>
          <Textarea
            {...register('disclaimer')}
            id="disclaimer"
            name="disclaimer"
            placeholder={defaultDisclaimer}
            onChange={handleChange}
          />
          <Bottom>
            <div>{(disclaimer || '').length} / 750</div>
            <div>Best if no more than 750 characters.</div>
          </Bottom>
          {errors && !!errors.disclaimer && <ErrorMsg>Required</ErrorMsg>}
        </InputCol>
      </FieldGroup>
    </>
  );
};

export default ReportSettings;
