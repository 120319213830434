/**
 *
 * UserListModal
 *
 */
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { FetchResult, useLazyQuery, useQuery } from '@apollo/client';
import styled from 'styled-components';

import Modal, { ModalProps } from 'components/Modal';
import Spinner from 'components/Spinner';

import Button from 'components/Button';
import Table from './Table';
import UserFolderSelection from 'components/UserFolderSelection';

import { USER_FOLDER_QUERY } from 'queries/ClientPortal';
import InviteForm from 'components/InviteForm/InviteForm';
import { WORKSPACE_OWNER_AND_SECONDARY_ADMIN } from './queries';
import useWorkspaceId from 'hooks/useWorkspaceId';
import { WorkspaceInvite } from 'interfaces/workspace';
import { User } from 'interfaces/user';
import HideScrollbar from 'components/HideScrollbar';
import useUserId from 'hooks/useUserId';
import { Invite as InviteType } from 'components/InviteButton';
import { InviteWorkspaceUserResult } from 'queries/workspaceUsers';
import ClientAlertModal from 'components/ClientAlertModal';

export const isWorkspaceInvite = (
  invite: InviteType
): invite is WorkspaceInvite =>
  (invite as WorkspaceInvite).workspace !== undefined;

export const isInviteWorkspaceUserResult = (
  result: FetchResult<InviteWorkspaceUserResult>
): result is FetchResult<InviteWorkspaceUserResult> =>
  result.data?.inviteWorkspaceUser !== undefined;

const DivRight = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const ActionButton = styled(Button)`
  margin-left: 5px;
`;

type NewClient = {
  firstName: string;
  lastName: string;
  email: string;
};

type Invite = {
  newClient: NewClient;
  selectedFolders?: number[];
};

type Result = InviteWorkspaceUserResult;

interface UserListModalProps<T extends InviteType, U extends Result>
  extends Pick<ModalProps, 'zIndex'> {
  width?: number;
  className?: string;
  title: string;
  show: boolean;
  companyId: number;
  users: User[];
  invites?: T[] | null;
  folders?: Array<{ id: number; name: string }>;
  showChecks?: boolean;
  showWorkspaceOwner?: boolean;
  showSecondaryAdmin?: boolean;
  showClientUserOptions?: boolean;
  showSave?: boolean;
  groupAccess?: boolean;
  loadingUsers?: boolean;
  loadingFolders?: boolean;
  loadingInvite?: boolean;
  loadingResendInvite?: boolean;
  loadingUpdateInviteFolders?: boolean;
  selectedUserIds?: Array<number>;
  selectedInviteIds?: number[];
  folderAccess?: boolean;
  readOnlyUserIds?: Array<number>;
  error?: string | null;
  preventClickOut?: boolean;
  inviteButtonId?: string;
  buttons?:
    | Array<{
        text: string;
        color: string;
        outline: boolean;
        onClick: () => void;
      }>
    | undefined;
  onClose: () => void;
  onInvite?:
    | ((
        invite: Invite
      ) => Promise<FetchResult<U, Record<string, any>, Record<string, any>>>)
    | null;
  onResendInvite?: (user: User) => void;
  onRemove?: (user: User) => void;
  onSelect?: (id: number, selected: boolean, type?: 'invite' | 'user') => void;
  onUpdate?: (any) => void;
  onSave?: (any) => void;
  onPreview?: (int) => void;
  onRemoveInvite?: (inviteId: number, user?: User) => void;
  onShowUpdateInviteFolders?: (inviteId: number) => void;
  onClientUserChange?: (user: User) => void;
  onWorkspaceOwnerChange?: (user: User) => void;
  onWorkspaceSecondoryAdminChange?: (user: User, revoke: boolean) => void;
  isClientPortalUser?: boolean;
}

const UserListModal = <T extends InviteType, U extends Result>({
  className,
  title,
  width,
  show,
  companyId,
  users = [],
  invites = [] as T[],
  folders = [],
  showChecks = true,
  showSave = false,
  showWorkspaceOwner = false,
  showSecondaryAdmin = false,
  showClientUserOptions = false,
  groupAccess = false,
  loadingUsers,
  loadingFolders,
  loadingInvite,
  loadingResendInvite,
  loadingUpdateInviteFolders,
  folderAccess = true,
  preventClickOut,
  onClose,
  onInvite,
  onResendInvite,
  onPreview,
  onRemove,
  onSelect,
  onUpdate,
  onSave,
  onRemoveInvite,
  onShowUpdateInviteFolders,
  selectedUserIds = [],
  selectedInviteIds = [],
  onClientUserChange,
  onWorkspaceOwnerChange,
  onWorkspaceSecondoryAdminChange,
  readOnlyUserIds,
  error,
  zIndex,
  buttons,
  isClientPortalUser,
  inviteButtonId,
}: UserListModalProps<T, U>): React.ReactElement => {
  const [showUserFolderSelection, setShowUserFolderSelection] =
    useState<boolean>(false);
  const [folderModalTitle, setFolderModalTitle] = useState<string>('');
  const [showInvite, setShowInvite] = useState<boolean>(false);
  const [userId, setUserId] = useState<number | null>(null);
  const workspaceId = useWorkspaceId();
  const [currentUserId] = useUserId();
  const userListGreaterThanFifty = users.length > 50;

  const {
    data: ownerAndSecondaryAdminData,
    loading: loadingOwnerAndSecondaryAdminData,
  } = useQuery(WORKSPACE_OWNER_AND_SECONDARY_ADMIN, {
    variables: {
      workspaceId,
    },
    skip: !workspaceId,
  });

  const owner = useMemo(() => {
    if (!showWorkspaceOwner) return null;

    return (
      ownerAndSecondaryAdminData &&
      ownerAndSecondaryAdminData.workspace &&
      ownerAndSecondaryAdminData.workspace.owner
    );
  }, [ownerAndSecondaryAdminData, showWorkspaceOwner]);

  const isOwner = useMemo(
    () => ownerAndSecondaryAdminData?.workspace.owner.id === currentUserId,
    [ownerAndSecondaryAdminData, currentUserId]
  );

  const secondaryAdmin = useMemo(() => {
    if (!showSecondaryAdmin) return null;
    return (
      ownerAndSecondaryAdminData &&
      ownerAndSecondaryAdminData.workspace &&
      ownerAndSecondaryAdminData.workspace.secondaryAdmin
    );
  }, [ownerAndSecondaryAdminData, showSecondaryAdmin]);

  const hasSecondaryAdmin = useMemo(
    () => !!ownerAndSecondaryAdminData?.workspace?.secondaryAdmin,
    [ownerAndSecondaryAdminData]
  );

  const isSecondaryAdmin = useMemo(
    () =>
      ownerAndSecondaryAdminData?.workspace?.secondaryAdmin?.id ===
      currentUserId,
    [ownerAndSecondaryAdminData, currentUserId]
  );

  const [
    handleUsersFolders,
    { data: usersFoldersData, loading: loadingUsersFolders },
  ] = useLazyQuery(USER_FOLDER_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  // If inviting client, no folders should be selected
  // otherwise, get selected user's current folder access selection
  const selectedFolderIds = useMemo(() => {
    if (showInvite) {
      return [];
    } else {
      return (
        usersFoldersData &&
        usersFoldersData.getUsersFolders.folders.map((folder) => folder.id)
      );
    }
  }, [usersFoldersData, showInvite]);

  const [selectedFolders, setSelectedFolders] = useState(selectedFolderIds);

  // Re-render if selectedFolderIds is altered/updated
  useEffect(() => {
    setSelectedFolders(selectedFolderIds);
  }, [selectedFolderIds]);

  const getUserFolders = useCallback(
    (userIdToQuery) => {
      handleUsersFolders({
        variables: {
          userId: userIdToQuery,
          companyId,
        },
      });
    },
    [companyId, handleUsersFolders]
  );

  // If callback has a user object then open user's folder access options.
  // Otherwise, open invite client options
  const handleFolderAccessButton = useCallback(
    (user?: User) => {
      if (user) {
        getUserFolders(user.id);
        setUserId(user.id);
        setFolderModalTitle(`${user.fullName}'s Folder Access`);
        setShowInvite(false);
        setShowUserFolderSelection(true);
      } else {
        setFolderModalTitle('Invite Client');
        setShowInvite(true);
        setShowUserFolderSelection(true);
      }
    },
    [getUserFolders]
  );

  const handleFolderAccessUpdate = useCallback(
    (folderAccessUpdate) => {
      folderAccessUpdate.userId = userId;
      if (onUpdate) {
        onUpdate(folderAccessUpdate);
      }
    },
    [userId, onUpdate]
  );

  // Hide modal on close
  const handleUserFolderSelectionClose = useCallback(() => {
    setShowUserFolderSelection(false);
  }, []);
  const [showClientAlertModal, setShowClientAlertModal] = useState(false);

  return (
    <Modal
      className={className}
      title={title}
      onClose={onClose}
      show={show}
      width={width || 600}
      preventClickOut={preventClickOut}
      zIndex={zIndex}
      buttons={buttons}
    >
      <HideScrollbar />
      {loadingUsers || loadingOwnerAndSecondaryAdminData ? (
        <Spinner />
      ) : (
        <>
          <Table
            users={users}
            owner={owner}
            secondaryAdmin={secondaryAdmin}
            hasSecondaryAdmin={hasSecondaryAdmin}
            isSecondaryAdmin={isSecondaryAdmin}
            onRemove={onRemove}
            invites={invites}
            onRemoveInvite={onRemoveInvite}
            onShowUpdateInviteFolders={onShowUpdateInviteFolders}
            onSelect={onSelect}
            onPreview={onPreview}
            onClientUserChange={onClientUserChange}
            onWorkspaceOwnerChange={onWorkspaceOwnerChange}
            onWorkspaceSecondaryAdminChange={onWorkspaceSecondoryAdminChange}
            showChecks={showChecks}
            loadingUsers={loadingUsers}
            onFolderAccessButton={
              folderAccess ? handleFolderAccessButton : undefined
            }
            selectedUserIds={selectedUserIds}
            selectedInviteIds={selectedInviteIds}
            readOnlyUserIds={readOnlyUserIds}
            isOwner={isOwner}
            showClientUserOptions={showClientUserOptions}
            currentUserId={currentUserId}
            onResendInvite={onResendInvite}
            loadingResendInvite={loadingResendInvite}
            isClientPortalUser={isClientPortalUser}
            workspaceId={workspaceId}
            companyId={companyId}
          />
          {groupAccess && onInvite ? (
            <InviteForm
              onSubmit={(newClient) => onInvite({ newClient })}
              loading={loadingInvite}
              error={error}
              inviteButtonId={inviteButtonId}
            />
          ) : (
            <DivRight>
              {showSave && (
                <ActionButton
                  color="primary"
                  onClick={() => onSave && onSave(undefined)}
                  loading={loadingUsers}
                  disabled={loadingUsers}
                >
                  Save
                </ActionButton>
              )}
              {!!onInvite && (
                <>
                  <ActionButton
                    color="primary"
                    onClick={() => handleFolderAccessButton(undefined)}
                    loading={loadingUsers}
                    disabled={loadingUsers}
                  >
                    Invite New Client
                  </ActionButton>
                  {!!onPreview && (
                    <ActionButton
                      color={userListGreaterThanFifty ? 'secondary' : 'primary'}
                      outline={true}
                      onClick={() =>
                        setShowClientAlertModal(!showClientAlertModal)
                      }
                      loading={loadingUsers}
                      disabled={userListGreaterThanFifty || loadingUsers}
                    >
                      Alert All Clients
                    </ActionButton>
                  )}
                </>
              )}
            </DivRight>
          )}
        </>
      )}
      <ClientAlertModal
        workspaceId={workspaceId}
        companyId={companyId}
        userFullName={null}
        show={showClientAlertModal}
        onClose={() => setShowClientAlertModal(!showClientAlertModal)}
        userIdsList={users.map((user) => user.id)}
      />
      <UserFolderSelection
        show={showUserFolderSelection}
        showInvite={showInvite}
        groupAccess={groupAccess}
        title={folderModalTitle}
        folders={folders}
        selectedFolderIds={selectedFolders}
        loadingFolders={loadingFolders}
        loadingSelectedFolders={loadingUsersFolders}
        loadingInvite={loadingInvite}
        loadingUpdateInviteFolders={loadingUpdateInviteFolders}
        onUpdate={handleFolderAccessUpdate}
        onInvite={onInvite}
        onClose={handleUserFolderSelectionClose}
        inviteButtonId={inviteButtonId}
      />
    </Modal>
  );
};

export default UserListModal;
