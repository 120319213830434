import moment from 'moment';

/**
 * Returns formatted date text
 * @param { Date } startDate
 * @param { Date } endDate
 * @param { string } range The preset range selected
 * @param { boolean } monthOnly Whether to display only the month or not
 */
export const getDateText = (
  startDate: Date,
  endDate: Date,
  range: string,
  monthOnly: boolean = true,
  allCaps: boolean = false,
  abbreviate: boolean = false
): string => {
  let monthFormat = abbreviate ? 'MMM' : 'MMMM';
  let dateFormat = `${monthFormat} yyyy`;
  let text = '';
  if (range === 'CUSTOM') {
    if (!monthOnly) {
      dateFormat = `${monthFormat} DD, yyyy`;
    }
  }
  const momentStartDate = moment.utc(startDate);
  const momentEndDate = moment.utc(endDate);
  if (momentStartDate.isSame(momentEndDate, 'month')) {
    text = momentStartDate.format(dateFormat);
  } else {
    text = `${momentStartDate.format(dateFormat)} - 
    ${momentEndDate.format(dateFormat)}`;
  }

  return allCaps ? text.toUpperCase() : text;
};
