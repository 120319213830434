import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';

const QUERY = gql`
  query ThemeMode {
    currentUser {
      theme
    }
  }
`;

export default function useThemeMode() {
  const { data } = useQuery(QUERY);

  return useMemo(() => {
    if (data && data.currentUser) {
      return data.currentUser.theme;
    }
    return null;
  }, [data]);
}
