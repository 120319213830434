import React, { useCallback, useReducer } from 'react';
import IntlTelInput from 'components/IntlTelInput';
import {
  Container,
  Button,
  Buttons,
  Info,
  RetryButton,
  VerifyCode,
  RemoveButton,
} from './styledComponents';
import { useMutation } from '@apollo/client';
import { captureException } from 'utils/sentry';
import {
  CHECK,
  DISABLE,
  GET_TWO_FACTOR,
  SETUP_PHONE,
  VERIFY,
} from './queriesAndMutations';
import { CurrentUser } from 'interfaces/user';
import { TwoFactorAuthQuery } from '__generated__/graphql';

interface State {
  phoneNumber: string;
  phoneIsValid: boolean;
  sent: boolean;
  sentPhoneNumber: string;
  code: string;
  previousCode: string;
  verified: boolean;
}

type Action =
  | { type: 'SET_PHONE_NUMBER'; payload: string }
  | { type: 'SET_PHONE_VALIDITY'; payload: boolean }
  | { type: 'SET_SENT'; payload: boolean }
  | { type: 'SET_SENT_PHONE_NUMBER'; payload: string }
  | { type: 'SET_CODE'; payload: string }
  | { type: 'SET_PREVIOUS_CODE'; payload: string }
  | { type: 'SET_VERIFIED'; payload: boolean }
  | { type: 'RESET_STATE' };

interface Props {
  enabled: TwoFactorAuthQuery['currentUser']['verifiedTwoFactorPhone'];
  disable: boolean;
  totpEnabled: TwoFactorAuthQuery['currentUser']['verifiedTotp'];
  phoneLast4: TwoFactorAuthQuery['currentUser']['phoneLast4'];
  error?: boolean;
  setError: React.Dispatch<React.SetStateAction<null | string>>;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  triggerToast: (message: string) => void;
}

const initialState: State = {
  phoneNumber: '',
  phoneIsValid: false,
  sent: false,
  sentPhoneNumber: '',
  code: '',
  previousCode: '',
  verified: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_PHONE_NUMBER':
      return { ...state, phoneNumber: action.payload };
    case 'SET_PHONE_VALIDITY':
      return { ...state, phoneIsValid: action.payload };
    case 'SET_SENT':
      return { ...state, sent: action.payload };
    case 'SET_SENT_PHONE_NUMBER':
      return { ...state, sentPhoneNumber: action.payload };
    case 'SET_CODE':
      return { ...state, code: action.payload };
    case 'SET_PREVIOUS_CODE':
      return { ...state, previousCode: action.payload };
    case 'SET_VERIFIED':
      return { ...state, verified: action.payload };
    case 'RESET_STATE':
      return initialState;
    default:
      return state;
  }
};

const Sms = ({
  enabled,
  disable,
  totpEnabled,
  phoneLast4,
  error,
  setError,
  setDisable,
  triggerToast,
}: Props): React.ReactElement => {
  const [
    {
      phoneNumber,
      phoneIsValid,
      sent,
      sentPhoneNumber,
      code,
      previousCode,
      // verified,
    },
    dispatch,
  ] = useReducer(reducer, {
    ...initialState,
  });

  const [setup, { loading: setupLoading }] = useMutation(SETUP_PHONE);
  const [verify, { loading: verifyLoading }] = useMutation(VERIFY);
  const [check, { loading: checkLoading }] = useMutation(CHECK);
  const [disableAuth] = useMutation(DISABLE);

  /**
   * Handle setting up two-factor authentication
   */
  const handleSetup = useCallback(async () => {
    try {
      if (!phoneIsValid) {
        return setError('Invalid phone number');
      } else {
        setError(null);
      }

      await setup({
        variables: {
          phone: phoneNumber,
        },
      });
      dispatch({ type: 'SET_SENT', payload: true });
      dispatch({ type: 'SET_SENT_PHONE_NUMBER', payload: phoneNumber });
    } catch (err) {
      captureException(err, true);
    }
  }, [phoneIsValid, phoneNumber, setError, setup]);

  /**
   * Handle changing phone number
   * @param value - phone number
   */
  const handleChangeNumber = useCallback(
    (value) => {
      if (phoneNumber !== value)
        dispatch({ type: 'SET_PHONE_NUMBER', payload: value });
    },
    [phoneNumber]
  );

  /**
   * Handle changing phone number validity
   * @param isValid - phone number validity
   */
  const handleChangeValidity = useCallback(
    (isValid) => {
      if (phoneIsValid !== isValid)
        dispatch({ type: 'SET_PHONE_VALIDITY', payload: isValid });
    },
    [phoneIsValid]
  );

  /**
   * Handle changing code
   * @param e - event
   */
  const handleChangeCode = useCallback(
    (e) => {
      setError(null);
      dispatch({
        type: 'SET_CODE',
        payload: e.target.value,
      });
    },
    [setError]
  );

  /**
   * Handle disabling two-factor authentication
   */
  const handleClickDisable = useCallback(async () => {
    try {
      const { data: checkData } = await check({
        variables: {
          method: 'PHONE',
        },
      });
      setError(null);

      if (
        checkData &&
        checkData.checkTwoFactorAuth &&
        checkData.checkTwoFactorAuth.success
      ) {
        dispatch({ type: 'SET_SENT', payload: true });
        dispatch({ type: 'SET_CODE', payload: '' });
        dispatch({ type: 'SET_VERIFIED', payload: false });
        setDisable(true);
      } else if (
        checkData &&
        checkData.checkTwoFactorAuth &&
        checkData.checkTwoFactorAuth.error
      ) {
        setError(checkData.checkTwoFactorAuth.error);
      }
    } catch (err) {
      captureException(err);
    }
  }, [check, setDisable, setError]);

  /**
   * Handle validating code
   */
  const validateCode = useCallback(() => {
    if (code.length !== 6) {
      setError('Code should be 6 numbers');
      return false;
    } else if (code === previousCode) {
      setError('Please enter a new code');
      return false;
    } else {
      setError(null);
      return true;
    }
  }, [code, previousCode, setError]);

  /**
   * Handle disabling two-factor authentication
   */
  const handleDisableAuthentication = useCallback(async () => {
    try {
      const validCode = validateCode();

      if (!validCode) return;

      await disableAuth({
        variables: {
          method: 'PHONE',
          code,
        },
        update: (cache, { data: { disableTwoFactorAuth } }) => {
          dispatch({ type: 'SET_PREVIOUS_CODE', payload: code });

          if (disableTwoFactorAuth.success) {
            const getTwoFactorCache = cache.readQuery<{
              currentUser: CurrentUser;
            }>({ query: GET_TWO_FACTOR });

            if (!getTwoFactorCache) return;

            const { currentUser } = getTwoFactorCache;

            cache.writeQuery({
              query: GET_TWO_FACTOR,
              data: {
                currentUser: {
                  ...currentUser,
                  verifiedTwoFactorPhone: false,
                  hasTwoFactorAuth: currentUser.verifiedTotp,
                },
              },
            });

            setDisable(false);
            dispatch({ type: 'RESET_STATE' });
            setError(null);
            triggerToast('Two-factor authentication disabled');
          } else if (disableTwoFactorAuth.error) {
            setError(disableTwoFactorAuth.error);
          }
        },
      });
    } catch (err) {
      captureException(err, true);
    }
  }, [code, disableAuth, setDisable, setError, triggerToast, validateCode]);

  /**
   * Handle verifying code
   */
  const handleVerify = useCallback(async () => {
    try {
      const validCode = validateCode();

      if (!validCode) return;

      const { data: verifyData } = await verify({
        variables: {
          method: 'PHONE',
          phone: sentPhoneNumber,
          code,
        },
        update: (cache, { data: { verifyTwoFactorAuth } }) => {
          dispatch({ type: 'SET_PREVIOUS_CODE', payload: code });

          if (verifyTwoFactorAuth.success) {
            triggerToast('Two-factor authentication enabled');
            const currentUser = cache.readQuery<{ currentUser: CurrentUser }>({
              query: GET_TWO_FACTOR,
            });

            cache.writeQuery({
              query: GET_TWO_FACTOR,
              data: {
                currentUser: {
                  ...currentUser,
                  hasTwoFactorAuth: true,
                  phoneLast4: sentPhoneNumber.slice(sentPhoneNumber.length - 4),
                  verifiedTwoFactorPhone: true,
                },
              },
            });
            dispatch({ type: 'RESET_STATE' });
          }
        },
      });
      if (
        verifyData &&
        verifyData.verifyTwoFactorAuth &&
        verifyData.verifyTwoFactorAuth.success
      ) {
        dispatch({ type: 'SET_VERIFIED', payload: true });
        setError(null);
      } else if (
        verifyData &&
        verifyData.verifyTwoFactorAuth &&
        verifyData.verifyTwoFactorAuth.error
      ) {
        setError(verifyData.verifyTwoFactorAuth.error);
      }
    } catch (err) {
      captureException(err, true);
    }
  }, [code, sentPhoneNumber, setError, triggerToast, validateCode, verify]);

  /**
   * Handle verifying code and submitting on enter
   * @param e - event
   */
  const handleVerifyEnterSubmit = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        enabled ? handleDisableAuthentication() : handleVerify();
      }
    },
    [handleDisableAuthentication, handleVerify, enabled]
  );

  return (
    <>
      <p>SMS Backup Authentication</p>

      {totpEnabled && !enabled ? (
        <p>
          Add your phone as a backup authentication method. When logging in you
          will receive a text message with a code to confirm your identity.
        </p>
      ) : !enabled ? (
        <p>
          Verify your phone number to set up two factor authentication. When
          logging in you will receive a text message with a code to confirm your
          identity.
        </p>
      ) : enabled && disable ? (
        <p>
          To disable two-factor authentication, please verify the code we have
          sent to your phone ending in {phoneLast4}
        </p>
      ) : (
        <p>Your current phone number is: {`******${phoneLast4}`}.</p>
      )}

      {!enabled && (
        <Container>
          <Buttons>
            <IntlTelInput
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onChangeNumber={handleChangeNumber}
              onChangeValidity={handleChangeValidity}
              initOptions={{
                initialCountry: 'us',
              }}
            />
            <Button
              color="success"
              onClick={handleSetup}
              disabled={setupLoading}
              loading={setupLoading}
            >
              Send SMS Message
            </Button>
          </Buttons>
          <Info>Message and data rates may apply</Info>
        </Container>
      )}

      {enabled && !disable && (
        <RemoveButton onClick={handleClickDisable}>
          Remove SMS Authentication
        </RemoveButton>
      )}

      {sent && (
        <VerifyCode error={!!error}>
          <input
            type="text"
            onChange={handleChangeCode}
            onKeyPress={handleVerifyEnterSubmit}
            autoComplete="off"
            data-1p-ignore
            placeholder="Enter 6-digit code"
          />
          <Buttons>
            <Button
              loading={verifyLoading || checkLoading}
              disabled={verifyLoading || checkLoading}
              onClick={enabled ? handleDisableAuthentication : handleVerify}
            >
              Verify Code
            </Button>
            <RetryButton onClick={disable ? handleClickDisable : handleSetup}>
              Resend code
            </RetryButton>
          </Buttons>
        </VerifyCode>
      )}
    </>
  );
};

export default Sms;
