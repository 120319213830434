import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { components as SelectComponents } from 'react-select';
import Help from 'components/Help';

export const helpIconStyles = {
  fontSize: '13px',
};

const GroupHeaderContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #999;
  font-size: 0.675rem;
  font-weight: 500;
  margin-bottom: 0.25em;
  padding-left: 12px;
  padding-right: 12px;
  text-transform: uppercase;
  box-sizing: border-box;
  height: 36px;
  max-height: 36px;

  span {
    margin-right: 5px;
  }
`;

const Options = styled.div`
  height: ${({ isOpen, height }) => (isOpen ? `${height}px` : 0)};
  overflow: hidden;
`;

const Chevron = styled.div`
  transform: ${({ isOpen }) => (isOpen ? 'rotateZ(90deg)' : 'rotateZ(0deg)')};
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  ${({ isOpen }) =>
    !isOpen &&
    css`
      padding-bottom: 2px;
    `}
`;

const SelectAllButton = styled.button`
  margin-left: 1rem;
`;

export const CollapsibleOption = ({
  scrollTo,
  selectHelpIconClass,
  selectHelpText,
  children,
  ...props
}) => {
  const ref = useRef();

  useEffect(() => {
    if (props.isSelected) {
      setTimeout(() => scrollTo(ref), 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SelectComponents.Option {...props} innerRef={ref}>
      {children}{' '}
      {!!selectHelpText && (
        <Help iconClass={selectHelpIconClass} style={helpIconStyles}>
          {selectHelpText}
        </Help>
      )}
    </SelectComponents.Option>
  );
};

const GroupHeader = React.forwardRef(function GroupHeaderRef(
  { data, onClick, isOpen, isMulti, selectProps },
  ref
) {
  const onSelectAll = useCallback(
    (e) => {
      e.stopPropagation();
      const newOptions = selectProps.value ? selectProps.value.slice() : [];
      data.options.forEach((option) => {
        if (!newOptions.includes(option)) {
          newOptions.push(option);
        }
      });
      selectProps.onChange(newOptions);
    },
    [data.options, selectProps]
  );

  return (
    <GroupHeaderContainer onClick={onClick} ref={ref}>
      <span>{data.label}</span>
      <Chevron isOpen={isOpen}>›</Chevron>
      {isMulti && (
        <SelectAllButton type="button" onClick={onSelectAll}>
          Select All
        </SelectAllButton>
      )}
    </GroupHeaderContainer>
  );
});

export const CollapsibleGroup = ({ data, children, ...props }) => {
  const [_isOpen, setIsOpen] = useState(
    props.selectProps.openGroupIndex === props.headingProps.data.index || false
  );
  const isOpen = _isOpen || !!props.selectProps.inputValue;
  const [optionsHeight, setOptionsHeight] = useState(0);
  const optionsRef = useRef(null);
  const headerRef = useRef(null);

  const checkIndexed = useCallback(
    (callback) => {
      if (
        props.headingProps.data.index ||
        props.headingProps.data.index === 0
      ) {
        callback();
      } else {
        console.error(
          'When using collapseGroups property, the options passed need include an index.'
        );
      }
    },
    [props.headingProps.data.index]
  );

  const handleClick = useCallback(
    (e) => {
      checkIndexed(() => {
        setIsOpen(!isOpen);
        if (props.selectProps.onGroupToggle) {
          props.selectProps.onGroupToggle(props.headingProps.data.index);
        }
        if (props.headingProps.onClick) {
          props.headingProps.onClick(
            e,
            headerRef,
            props.headingProps.data.index
          );
        }
      });
    },
    [checkIndexed, isOpen, props.headingProps, props.selectProps, headerRef]
  );

  useEffect(() => {
    if (isOpen) {
      setIsOpen(
        props.selectProps.openGroupIndex === props.headingProps.data.index
      );
    }
  }, [
    isOpen,
    props.selectProps.onGroupToggle,
    props.selectProps,
    props.headingProps.data.index,
  ]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Observer detects if a line wrap incurs due to the scrollbar
      // appearing and triggering the overflow then recalculates
      // The container height for the expanding options drawer
      const observer = new window.ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.target === optionsRef.current) {
            setOptionsHeight(entry.target.clientHeight);
            break;
          }
        }
      });
      if (optionsRef.current && headerRef.current) {
        observer.observe(optionsRef.current);
        setOptionsHeight(optionsRef.current.clientHeight);
      }
      return () => {
        observer.disconnect();
      };
    }
  }, [isOpen]);

  return (
    <div>
      <GroupHeader
        ref={headerRef}
        data={data}
        onClick={handleClick}
        isOpen={isOpen}
        isMulti={props.selectProps.isMulti}
        selectProps={props.selectProps}
      />
      <Options isOpen={isOpen} height={optionsHeight}>
        <div ref={optionsRef}>
          {React.Children.map(children, (child, i) => {
            return React.cloneElement(child, {
              scrollTo: props.headingProps.scrollTo,
              selectHelpIconClass: props.options[i]?.data?.selectHelpIconClass,
              selectHelpText: props.options[i]?.data?.selectHelpText,
            });
          })}
        </div>
      </Options>
    </div>
  );
};

export const CollapsibleMenuList = (props) => {
  const { children, maxHeight } = props;
  const containerRef = useRef(null);

  const scrollTo = (position) => {
    // Scroll the container to the new position
    containerRef.current.scrollTo({
      top: position,
      behavior: 'smooth',
    });
  };

  const handleGroupHeaderClick = (e, headerRef, index) => {
    e.preventDefault();
    if (headerRef && headerRef.current) {
      const headerRect = headerRef.current.getBoundingClientRect();

      // Calculate the new scroll position
      const newScrollPosition = headerRect.height * index;

      scrollTo(newScrollPosition);
    }
  };

  const handleScrollToSelected = (optionRef) => {
    if (optionRef && optionRef.current) {
      const optionsRect = optionRef.current.getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();

      // Calculate the new scroll position
      const newScrollPosition = optionsRect.y - containerRect.y;

      scrollTo(newScrollPosition);
    }
  };

  return (
    <div
      ref={containerRef}
      style={{ maxHeight: `${maxHeight}px`, overflowY: 'auto' }}
    >
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          headingProps: {
            ...child.props.headingProps,
            onClick: handleGroupHeaderClick,
            scrollTo: handleScrollToSelected,
          },
        });
      })}
    </div>
  );
};
