import useMedia from 'hooks/useMedia';
import { User } from 'interfaces/user';
import { sizes } from 'media';
import React, { ReactElement, ReactNode } from 'react';
import {
  HeaderGroup,
  Row as RowType,
  TablePropGetter,
  TableProps,
  TableBodyProps,
  TableBodyPropGetter,
} from 'react-table';
import styled from 'styled-components';
import Row from './row';

const Table = styled.table`
  margin-bottom: 1rem;
  & td,
  th {
    font-size: 0.75rem;
    padding-bottom: 0em;
  }
`;

export interface TableInnerProps {
  loadingUsers?: boolean;
  selectedItemIds?: number[];
  showChecks?: boolean;
  readOnlyUserIds?: number[];
  onRemove?: (user: User) => void;
  onRemoveInvite?: (inviteId: number, user: User) => void;
  onPreview?: (int) => void;
  onSelect?: (id: number, selected: boolean, type?: 'user' | 'invite') => void;
  onFolderAccessButton?: (user: User) => void;
  onClientUserChange?: (user: User) => void;
  onWorkspaceOwnerChange?: (user: User) => void;
  onWorkspaceSecondaryAdminChange?: (user: User, revoke: boolean) => void;
  showClientUserOptions?: boolean;
  isOwner?: boolean;
  currentUserId: number;
  workspaceId: number;
  companyId: number;
  secondaryAdmin?: User;
  hasSecondaryAdmin?: boolean;
  isSecondaryAdmin?: boolean;
}

export type UserRow = User & {
  id: number;
  fullName: string;
  email: string;
  owner?: boolean;
  secondaryAdmin?: boolean;
  inviteId?: number;
  link?: string;
  inviteLink?: string;
};

interface Props<T extends UserRow> extends TableInnerProps {
  getTableProps: (propGetter?: TablePropGetter<T> | undefined) => TableProps;
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<T> | undefined
  ) => TableBodyProps;
  headerGroups: HeaderGroup<T>[];
  rows: RowType<T>[];
  prepareRow: (row: RowType<T>) => void;
  onResendInvite?: (user: User) => void;
  onShowUpdateInviteFolders?: (invite: number) => void;
  loadingResendInvite?: boolean;
  isClientPortalUser?: boolean;
  workspaceId: number;
  companyId: number;
}

const TableInner = <T extends UserRow>(
  props: Props<T> & { children?: ReactNode }
): ReactElement => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    loadingUsers,
    selectedItemIds,
    showChecks,
    readOnlyUserIds,
    onRemove,
    onRemoveInvite,
    onResendInvite,
    onPreview,
    onSelect,
    onFolderAccessButton,
    onShowUpdateInviteFolders,
    onClientUserChange,
    onWorkspaceOwnerChange,
    onWorkspaceSecondaryAdminChange,
    showClientUserOptions,
    isOwner,
    hasSecondaryAdmin,
    isSecondaryAdmin,
    currentUserId,
    loadingResendInvite,
    isClientPortalUser,
    workspaceId,
    companyId,
  } = props;

  const phone = useMedia([`(min-width: ${sizes.phone}px)`], [false], true);
  const desktop = useMedia([`(min-width: ${sizes.desktop}px)`], [false], true);

  return (
    <Table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          // eslint-disable-next-line react/jsx-key
          <tr {...headerGroup.getHeaderGroupProps()}>
            {(!!onSelect || showChecks) && <th />}
            {headerGroup.headers.map((column) => (
              // Add the sorting props to control sorting. For this example
              // we can add them into the header props
              // eslint-disable-next-line react/jsx-key
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                {/* Add a sort direction indicator */}
                <span>
                  <i
                    className={`mdi-set mdi-${
                      column.isSortedDesc ? 'chevron-down' : 'chevron-up'
                    }`}
                  />
                </span>
              </th>
            ))}
            {(!!onRemove || isOwner) && <th />}
            {!!onFolderAccessButton && !phone && <th />}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          if (
            row.original.id === currentUserId &&
            !isOwner &&
            !isSecondaryAdmin
          ) {
            return null;
          }
          prepareRow(row);
          return (
            <Row
              key={i}
              row={row}
              showChecks={showChecks}
              selectedItemIds={selectedItemIds}
              readOnlyUserIds={readOnlyUserIds}
              currentUserId={currentUserId}
              phone={phone}
              desktop={desktop}
              isOwner={isOwner}
              hasSecondaryAdmin={hasSecondaryAdmin}
              isSecondaryAdmin={isSecondaryAdmin}
              isClientPortalUser={isClientPortalUser}
              showClientUserOptions={showClientUserOptions}
              loadingUsers={loadingUsers}
              loadingResendInvite={loadingResendInvite}
              onSelect={onSelect}
              onFolderAccessButton={onFolderAccessButton}
              onPreview={onPreview}
              onRemove={onRemove}
              onRemoveInvite={onRemoveInvite}
              onResendInvite={onResendInvite}
              onShowUpdateInviteFolders={onShowUpdateInviteFolders}
              onWorkspaceOwnerChange={onWorkspaceOwnerChange}
              onWorkspaceSecondaryAdminChange={onWorkspaceSecondaryAdminChange}
              onClientUserChange={onClientUserChange}
              workspaceId={workspaceId}
              companyId={companyId}
            />
          );
        })}
      </tbody>
    </Table>
  );
};

export default TableInner;
