/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
/* global fetch */
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ToastContainer } from 'react-toastify';
import gql from 'graphql-tag';
import { Query } from '@apollo/client/react/components';

import { API_URL } from 'environment';

import quantum from 'themes/quantum';
import Login from 'routes/Login/Loadable';
import Companies from 'routes/Companies/Loadable';
import AddCompany from 'routes/AddCompany/Loadable';
import Desktop from 'routes/Desktop/Loadable';
import DesktopSyncInfo from 'routes/DesktopSyncInfo/Loadable';
import Register from 'routes/Register/Loadable';
import RegisterComplete from 'routes/RegisterComplete/Loadable';
import Forgot from 'routes/Forgot/Loadable';
import SetupPassword from 'routes/SetupPassword/Loadable';
import Root from 'routes/Root/Loadable';
import Dashboard from 'routes/Dashboard/Loadable';
import DashboardList from 'routes/DashboardList/Loadable';
import CreateDashboard from 'routes/CreateDashboard/Loadable';
import DashboardLibrary from 'routes/DashboardLibrary/Loadable';
import ReportList from 'routes/ReportList/Loadable';
import AddKpi from 'routes/AddKpi/Loadable';
import Onboarding from 'routes/Onboarding/Loadable';
import Welcome from 'routes/Welcome/Loadable';
import Resources from 'routes/Resources/Loadable';
import Connecting from 'routes/Connecting/Loadable';
import NotFound from 'routes/NotFound/Loadable';
import Logout from 'routes/Logout/Loadable';
import Subscribe from 'routes/Subscribe/Loadable';
import SubscribePromo from 'routes/SubscribePromo/Loadable';
import StripeCheckout from 'routes/StripeCheckout/Loadable';
import PaymentInfo from 'routes/PaymentInfo/Loadable';
import CreateReport from 'routes/CreateReport/Loadable';
import ReportLibrary from 'routes/ReportLibrary/Loadable';
import ResetPassword from 'routes/ResetPassword/Loadable';
import ErrorPage from 'routes/ErrorPage';
import DisconnectedQuickbooks from 'routes/DisconnectedQuickbooks/Loadable';
import CompletePresignup from 'routes/CompletePresignup/Loadable';
import CompletePresignupSuccess from 'routes/CompletePresignupSuccess/Loadable';
import EditSpreadsheet from 'routes/EditSpreadsheet/Loadable';
import EditCanvasReport from 'routes/EditCanvasReport/Loadable';
import EditTable from 'routes/EditTable/Loadable';
import EditEntityTable from 'routes/EditEntityTable/Loadable';
import CompanySettings from 'routes/CompanySettings/Loadable';
import AccountSettings from 'routes/AccountSettings/Loadable';
import PublishedItem from 'routes/PublishedItem/Loadable';
import EditStatement from 'routes/EditStatement/Loadable';
import OwnerTrialEnded from 'routes/OwnerTrialEnded/Loadable';
import EditQueryTable from 'routes/EditQueryTable/Loadable';
import CreateQueryTable from 'routes/CreateQueryTable/Loadable';
import Workspaces from 'routes/Workspaces/Loadable';
import ClientPortal from 'routes/ClientPortal/Loadable';
import ClientPortalPreview from 'routes/ClientPortalPreview/Loadable';
import ClientPortalCompanies from 'routes/ClientPortalCompanies/Loadable';
import PortalDashboard from 'routes/PortalDashboard/Loadable';
import Eliminations from 'routes/Eliminations/Loadable';

import Route from 'components/Route';
import Switch from 'components/Switch';
import PrivateRoute from 'components/PrivateRoute';
import PortalRoute from 'components/PortalRoute';
import NotAuthenticatedRoute from 'components/NotAuthenticatedRoute';
import CompanyRoute from 'components/CompanyRoute';
import Sentry from 'components/Sentry';
import LogRocket from 'components/LogRocket';
import Hubspot from 'components/Hubspot';
import WhitelabelTitle from 'components/WhitelabelTitle';
// import SessionStorage from 'components/SessionStorage';

import Minimal from 'layouts/Minimal';
import Main from 'layouts/Main';
import WorkspaceLayout from 'layouts/Workspace';
import PortalLayout from 'layouts/Portal';

import withIsLoggedIn from 'hoc/withIsLoggedIn';

import * as SentryApi from '@sentry/browser';
import debounce from 'utils/debounce';

import {
  DashboardLocation,
  WorkspacesLocation,
  AddWorkspaceCompanyLocation,
  ClientPortalPreviewLocation,
  ClientPortalCompanyLocation,
  ClientPortalDashboardLocation,
  ClientPortalPreviewDashboardLocation,
  ClientPortalClientEditBudgetLocation,
} from 'locations';
import RegisterPlan from './RegisterPlan';
import StripeCheckoutComplete from './StripeCheckoutComplete';
import LoginModal from 'components/LoginModal';
import { showLoginModal } from 'graphql/variables';

import Unpaid from './Unpaid';
import GoogleAnalyticsStripe from 'components/GoogleAnalyticsStripe';
import Usetiful from 'components/Usetiful';
import RegisterAfter from './RegisterAfter';
import XeroLauncher from 'components/XeroLauncher';
import GaAuthEvents from 'components/GaAuthEvents';
import IntuitEmailNotVerified from './IntuitEmailNotVerified';

const QUERY = gql`
  query WithCompanyId {
    currentCompanyId @client
  }
`;

const STATUS_CHECK_INTERVAL = 30000;
const SESSION_TRACK_INTERVAL = 90000;

@withIsLoggedIn
class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      showLogoutWarning: false,
    };

    this.keepSessionActiveDebounced = debounce(this.keepSessionActive, 2000);

    this.checkSessionStatusInterval = setInterval(
      this.checkSessionStatus,
      STATUS_CHECK_INTERVAL
    );

    this.sessionTrackInterval = setInterval(
      this.trackSession,
      SESSION_TRACK_INTERVAL
    );
  }
  static getDerivedStateFromError() {
    return { hasError: true };
  }
/*   componentDidMount() {
    document.documentElement.style.backgroundColor = '';
  } */
  componentDidCatch(error, errorInfo) {
    SentryApi.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      SentryApi.captureException(error);
    });
  }
  componentWillUnmount() {
    clearInterval(this.checkSessionStatusInterval);
    clearInterval(this.sessionTrackInterval);
  }
  trackSession = async () => {
    if (window.location.pathname === '/logout') return;

    await fetch(`${API_URL}/sess`, {
      credentials: 'include',
    });
  };
  checkSessionStatus = async (keepAlive) => {
    if (window.location.pathname === '/logout') return;
    if (window.location.pathname.includes('/forgot')) return;

    const result = await fetch(`${API_URL}${keepAlive ? '' : '/status'}`, {
      credentials: 'include',
    });

    const { active, totalSessionTime, email } = await result.json();

    if (this.props.isLoggedIn && !active) {
      showLoginModal(true);
    }

    if (totalSessionTime && window._hsq) {
      window._hsq.push([
        'identify',
        {
          email,
          user_session_time: Math.round(totalSessionTime / 60),
        },
      ]);
      window._hsq.push(['trackPageView']);
    }

    /* if (this.props.isLoggedIn && active && !keepAlive) {
      try {
        const data = client.cache.readQuery({ query: SESSION_TIME });
        const result = produce(data, draft => {
          draft.currentUser.totalSessionTime += 10;
        });
        client.cache.writeQuery({
          query: SESSION_TIME,
          data: result,
        });
      } catch {
        // data must not be cached already
      }
    } */
  };
  keepSessionActive = () => {
    this.checkSessionStatus(true);
  };
  getTimestamp = () => +new Date();
  render() {
    return (
      <Query query={QUERY}>
        {({ data }) => (
          <DndProvider backend={HTML5Backend}>
            <ThemeProvider theme={quantum}>
              <div
                onClick={this.keepSessionActiveDebounced}
                onKeyDownCapture={this.keepSessionActiveDebounced}
              >
                {this.state.hasError ? (
                  <ErrorPage />
                ) : (
                  <Switch>
                    <CompanyRoute
                      path="/"
                      exact
                      component={Root}
                      layout={Main}
                    />
                    <PortalRoute
                      path="/portal"
                      component={ClientPortalCompanies}
                      layout={PortalLayout}
                      exact
                    />
                    <PortalRoute
                      loc={ClientPortalCompanyLocation}
                      path="/portal/:companyId"
                      component={ClientPortal}
                      layout={PortalLayout}
                      exact
                    />
                    <PortalRoute
                      loc={ClientPortalDashboardLocation}
                      path="/portal/:companyId/dashboard/:dashboardId"
                      component={PortalDashboard}
                      layout={PortalLayout}
                      exact
                    />
                    <PortalRoute
                      loc={ClientPortalClientEditBudgetLocation}
                      path="/portal/:companyId/folder/:folderId/budget/:tableId"
                      component={EditTable}
                      layout={PortalLayout}
                      exact
                    />
                    <CompanyRoute
                      path="/settings"
                      component={CompanySettings}
                      layout={Main}
                      exact
                    />
                    <PrivateRoute
                      path="/profile"
                      component={AccountSettings}
                      layout={data && data.currentCompanyId ? Main : Minimal}
                      exact
                    />
                    <CompanyRoute
                      path="/dashboards"
                      component={DashboardList}
                      layout={Main}
                      exact
                    />
                    <CompanyRoute
                      path="/dashboards/create"
                      component={CreateDashboard}
                      layout={Main}
                      exact
                    />
                    <CompanyRoute
                      path="/dashboards/library"
                      component={DashboardLibrary}
                      layout={Main}
                      exact
                    />
                    <CompanyRoute
                      path="/reports"
                      component={ReportList}
                      layout={Main}
                      exact
                      layoutProps={{ alwaysLightCharts: true }}
                    />
                    <CompanyRoute
                      loc={DashboardLocation}
                      component={Dashboard}
                      layout={Main}
                      exact
                    />
                    <CompanyRoute
                      path="/:itemType(dashboard)/kpi/:itemId"
                      exact
                      component={AddKpi}
                      layout={Main}
                    />
                    <CompanyRoute
                      path="/:itemType(dashboard)/kpi/library/:itemId"
                      exact
                      component={AddKpi}
                      layout={Main}
                      componentProps={{ library: true }}
                    />
                    <CompanyRoute
                      path="/:itemType(dashboard|reportPage)/table/:itemId/:type?"
                      exact
                      component={EditTable}
                      layout={Main}
                    />
                    <CompanyRoute
                      path="/reports/create"
                      exact
                      component={CreateReport}
                      layout={Main}
                    />
                    <CompanyRoute
                      path="/reports/library"
                      exact
                      component={ReportLibrary}
                      layout={Main}
                    />
                    <CompanyRoute
                      path="/report/edit/:reportId"
                      exact
                      component={EditCanvasReport}
                      layout={Main}
                      layoutProps={{ noFooter: true, alwaysLightCharts: true }}
                    />
                    <CompanyRoute
                      path="/table/:tableId?"
                      exact
                      component={EditTable}
                      layout={Main}
                    />
                    <CompanyRoute
                      path="/datasheet/:tableId?"
                      exact
                      component={EditTable}
                      layout={Main}
                    />
                    <CompanyRoute
                      path="/table-list/:tableId?"
                      exact
                      component={EditEntityTable}
                      layout={Main}
                    />
                    <CompanyRoute
                      path="/sync/desktop"
                      exact
                      component={DesktopSyncInfo}
                      layout={Main}
                    />
                    <CompanyRoute
                      path="/spreadsheet/:spreadsheetId"
                      exact
                      component={EditSpreadsheet}
                      layout={Main}
                    />
                    <CompanyRoute
                      path="/statement/:statementId"
                      exact
                      component={EditStatement}
                      layout={Main}
                    />
                    <CompanyRoute
                      path="/queryTable/:queryTableId"
                      exact
                      component={EditQueryTable}
                      layout={Main}
                    />
                    <CompanyRoute
                      path="/dashboard/:dashboardId/queryTable"
                      exact
                      component={CreateQueryTable}
                      layout={Main}
                    />
                    <CompanyRoute
                      path="/eliminations"
                      exact
                      component={Eliminations}
                      layout={Main}
                    />
                    <PrivateRoute
                      path="/workspaces"
                      exact
                      component={Workspaces}
                      layout={Minimal}
                    />
                    <PrivateRoute
                      loc={WorkspacesLocation}
                      path="/workspaces/:workspaceId?"
                      exact
                      component={Workspaces}
                      layout={WorkspaceLayout}
                    />
                    <PrivateRoute
                      loc={AddWorkspaceCompanyLocation}
                      path="/workspaces/:workspaceId/add"
                      exact
                      component={AddCompany}
                      layout={WorkspaceLayout}
                    />
                    <PrivateRoute
                      loc={ClientPortalPreviewLocation}
                      exact
                      component={ClientPortalPreview}
                      layout={PortalLayout}
                    />
                    <PrivateRoute
                      loc={ClientPortalPreviewDashboardLocation}
                      exact
                      component={PortalDashboard}
                      layout={PortalLayout}
                    />
                    <PrivateRoute
                      path="/companies"
                      exact
                      component={Companies}
                      layout={Minimal}
                    />
                    <PrivateRoute
                      path="/companies/add"
                      exact
                      component={AddCompany}
                      layout={Minimal}
                    />
                    <PrivateRoute
                      path="/companies/add/desktop"
                      exact
                      component={Desktop}
                      layout={Minimal}
                    />
                    <PrivateRoute
                      path="/start"
                      exact
                      component={Onboarding}
                      layout={Minimal}
                    />
                    <PrivateRoute
                      path="/welcome"
                      exact
                      component={Welcome}
                      layout={Minimal}
                    />
                    <PrivateRoute
                      path="/resources"
                      exact
                      component={Resources}
                      layout={Minimal}
                    />
                    <PrivateRoute
                      path="/signup"
                      exact
                      component={CompletePresignup}
                      layout={Minimal}
                    />
                    <PrivateRoute
                      path="/signup/complete"
                      exact
                      component={CompletePresignupSuccess}
                      layout={Minimal}
                    />
                    <PrivateRoute
                      path="/subscribe"
                      exact
                      component={Subscribe}
                      layout={Minimal}
                    />
                    <PrivateRoute
                      path="/subscribe/promo"
                      exact
                      component={SubscribePromo}
                      layout={Minimal}
                    />
                    <PrivateRoute
                      path="/subscribe/thank-you"
                      exact
                      component={StripeCheckoutComplete}
                      layout={Minimal}
                    />
                    <PrivateRoute
                      path="/subscribe/:presignup?"
                      exact
                      component={Subscribe}
                      layout={Minimal}
                    />
                    <PrivateRoute
                      path="/subscribe/plan/:pricingPlanId/:interval/:presignup?"
                      exact
                      component={StripeCheckout}
                      layout={Minimal}
                    />
                    <PrivateRoute
                      path="/payment"
                      exact
                      component={PaymentInfo}
                      layout={Minimal}
                      allowLogin
                    />
                    <PrivateRoute
                      path="/inactive"
                      exact
                      component={OwnerTrialEnded}
                      layout={Minimal}
                    />
                    <PrivateRoute
                      path="/unpaid"
                      exact
                      component={Unpaid}
                      layout={Minimal}
                    />
                    <PrivateRoute
                      path="/connecting/:companyId/:complete?"
                      exact
                      component={Connecting}
                      layout={Minimal}
                    />
                    <Route
                      path="/shared/:code"
                      exact
                      component={PublishedItem}
                    />
                    <Route
                      path="/embed/:code"
                      exact
                      component={PublishedItem}
                    />
                    <NotAuthenticatedRoute
                      path="/login/intuit-email-not-verified"
                      exact
                      component={IntuitEmailNotVerified}
                    />
                    <NotAuthenticatedRoute
                      path="/login/:link?"
                      exact
                      component={Login}
                    />
                    <Route
                      path="/shared/:code"
                      exact
                      component={PublishedItem}
                    />
                    <Route
                      path="/embed/:code"
                      exact
                      component={PublishedItem}
                    />
                    <NotAuthenticatedRoute
                      path="/login/:link?"
                      exact
                      component={Login}
                    />
                    <NotAuthenticatedRoute
                      path="/register/complete"
                      exact
                      component={RegisterComplete}
                    />
                    <NotAuthenticatedRoute
                      path="/register/invite/:code?"
                      exact
                      component={SetupPassword}
                    />
                    <NotAuthenticatedRoute
                      path="/register/plan/:planName/:interval"
                      exact
                      component={RegisterPlan}
                    />
                    <NotAuthenticatedRoute
                      path="/register/:page?"
                      exact
                      component={Register}
                    />
                    <NotAuthenticatedRoute
                      path="/registersales/:page?"
                      exact
                      component={Register}
                      componentProps={{ salesRegistration: true }}
                    />
                    <PrivateRoute
                      path="/onboarding"
                      exact
                      component={RegisterAfter}
                      layout={Minimal}
                    />
                    <NotAuthenticatedRoute
                      path="/forgot"
                      exact
                      component={Forgot}
                    />
                    <NotAuthenticatedRoute
                      path="/forgot/:code"
                      exact
                      component={ResetPassword}
                    />
                    <Route path="/logout" component={Logout} />
                    <Route
                      path="/quickbooks/disconnected"
                      component={DisconnectedQuickbooks}
                    />
                    <Route component={NotFound} />
                  </Switch>
                )}
                <GoogleAnalyticsStripe />
                {/* <HeapAnalytics /> */}
                <XeroLauncher />
                <GaAuthEvents />
                <Sentry />
                <Hubspot />
                <Usetiful />
                <LogRocket />
                <ToastContainer />
                <WhitelabelTitle />
                {/* <SessionStorage /> */}
                <LoginModal isLoggedIn={this.props.isLoggedIn} />
              </div>
            </ThemeProvider>
          </DndProvider>
        )}
      </Query>
    );
  }
}

export default App;
