import React from 'react';
import styled, { css } from 'styled-components';
import { sidebarZIndex } from 'utils/zIndex';
import media from 'media';

const Blur: React.FC<{
  className?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}> = ({ className, children, onMouseEnter, onMouseLeave }) => (
  <div
    className={`modal-blur ${className}`}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    {children}
  </div>
);

export const Bar = styled(Blur)<BarProps>`
  display: flex;
  flex-direction: column;
  width: 60px;
  position: fixed;
  z-index: ${sidebarZIndex};
  left: 0;
  top: 0;
  transition: left, width 0.15s ease-out;
  background: ${(props) => props.theme.sidebar.background};
  height: 100%;

  &:hover {
    width: ${(props) => props.theme.content.sidebarWidth}px;
  }

  ${(props) =>
    props.anonymous &&
    css`
      display: none;
      ${media.phone`
      display: flex;
    `}
    `}
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: 20px;
`;

type BarProps = {
  theme: any;
  anonymous: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

export const Header = styled.div`
  font-size: 0.6rem;
  margin-left: -70px;
  opacity: 0;
  white-space: nowrap;
  margin-bottom: 10px;
  margin-top: 10px;
  transition: margin-left 0.15s ease-out, opacity 0.4s ease-out;
  ${Bar}:hover & {
    margin-left: 18px;
    opacity: 1;
  }
`;
