import styled from 'styled-components';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';

export const DivRightActionButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const DivRightCheckbox = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
`;

export const SendToSelfCheckbox = styled(Checkbox)``;

export const ActionButton = styled(Button)`
  margin-left: 5px;
`;

export const FieldGroup = styled.div`
  margin-bottom: 0.75rem;
  display: flex;
`;

export const LabelCol = styled.div`
  width: 100px;
  font-size: 0.875rem;
`;

export const InputCol = styled.div`
  width: 500px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 3px;
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 8rem;
  font-size: 0.75rem;
  resize: none;
`;
