import validate from 'validate.js';

validate.validators.hasLetter = function (value, options) {
  const regex = /[a-z]|[A-Z]/;
  if (!regex.test(value)) {
    if (options === true) {
      return 'must contain a letter';
    } else {
      return null;
    }
  } else {
    if (options === true) {
      return null;
    } else {
      return 'must not contain a letter';
    }
  }
};

validate.validators.hasNumber = function (value, options) {
  const regex = /[0-9]/;
  if (!regex.test(value)) {
    if (options === true) {
      return 'must contain a number';
    } else {
      return null;
    }
  } else {
    if (options === true) {
      return null;
    } else {
      return 'must not contain a number';
    }
  }
};

function doValidation(value, criteria) {
  const errors = validate.single(value, criteria);
  if (errors && errors.length) {
    return errors[0];
  }
  return undefined;
}

export function isEmail(value) {
  const trimmed = typeof value === 'string' ? value.trim() : value;
  return doValidation(trimmed, {
    presence: { allowEmpty: false },
    email: true,
  });
}

export function isPhone(value) {
  if (value && value.length > 9) {
    return undefined;
  }
  return 'Required';
}

export function isRequired(value) {
  return doValidation(value, {
    presence: { allowEmpty: false },
  });
}

export function isPassword(value) {
  return doValidation(value, {
    presence: {
      allowEmpty: { allowEmpty: false },
    },
    length: {
      minimum: 8,
      maximum: 128,
    },
    hasLetter: true,
    hasNumber: true,
  });
}

export function isResourceName(value) {
  if (!value) return 'Name is required';

  const trimmed = value.trim();

  if (!trimmed) return 'Name is required';
  if (value.length > 35) return 'Name cannot be more than 35 characters';
  return undefined;
}

export function isFolderName(value, checked) {
  if (checked && !value) return 'Folder name is required';

  const trimmed = value.trim();

  if (!trimmed) return 'Folder name is required';
  if (value.length > 35) return 'Folder name cannot be more than 35 characters';
  return undefined;
}
