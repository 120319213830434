import { makeVar } from '@apollo/client';

export const showCreateDashboardModal = makeVar(false);
export const showCreateReportModal = makeVar(false);
export const showRenameDashboardModal = makeVar(false);
export const showRenameReportModal = makeVar(false);
export const showRenameKpiModal = makeVar(false);
export const showRenameSpreadsheetModal = makeVar(false);
export const showDashboardLibraryModal = makeVar(false);
export const showReportLibraryModal = makeVar(false);
export const showTableLibraryModal = makeVar(false);
export const tableLibraryItemId = makeVar(0);
export const tableLibraryItemType = makeVar(null);
export const showDashboardPreviewModal = makeVar(false);
export const dashboardPreviewId = makeVar(null);
export const showReportPreviewModal = makeVar(false);
export const reportPreviewId = makeVar(null);
export const showSpreadsheetModal = makeVar(false);
export const renameDashboardId = makeVar(0);
export const renameSpreadsheetId = makeVar(0);
export const renameReportId = makeVar(0);
export const renameKpiId = makeVar(0);
export const showDuplicateReportModal = makeVar(false);
export const showSelectDataModal = makeVar(false);
export const selectDataModalSubscription = makeVar(null);
export const selectDataModalSingle = makeVar(false);
export const selectDataModalValue = makeVar(null);
export const selectDataModalDates = makeVar(false);
export const selectDataModalDisableTotals = makeVar(false);
export const selectDataModalFilteredCompanyId = makeVar(false);
export const selectDataModalElimination = makeVar(false);
export const selectDataModalLiveText = makeVar(false);
export const duplicateReportId = makeVar(0);
export const duplicateDashboardId = makeVar(0);
export const showDuplicateDashboardModal = makeVar(false);
export const showFiltersModal = makeVar(false);
export const filterItemType = makeVar(null);
export const filterShowApplyAll = makeVar(false);
export const filterItemId = makeVar(null);
export const filterClassId = makeVar([]);
export const filterDepartmentId = makeVar([]);
export const filterPartialClassId = makeVar([]);
export const filterPartialDepartmentId = makeVar([]);
export const filterIsBudget = makeVar(false);
export const showPublishModal = makeVar(false);
export const publishModalItemId = makeVar(null);
export const publishModalItemType = makeVar(null);
export const showTrainingVideosModal = makeVar(false);
export const showFullscreen = makeVar(false);
export const selectDataModalGoogleSheets = makeVar(false);
export const selectDataModalSummitAndersWebApp = makeVar(false);
export const showAiDashboardGeneratorModal = makeVar(false);
export const showAiReportGeneratorModal = makeVar(false);

export const closeAiDashboardGeneratorModal = () => {
  showAiDashboardGeneratorModal(false);
};

export const closeAiReportGeneratorModal = () => {
  showAiReportGeneratorModal(false);
};

export const closeCreateReport = () => {
  showCreateReportModal(false);
};

export const showRenameReport = (id) => {
  renameReportId(id);
  showRenameReportModal(true);
};
export const closeRenameReport = () => {
  showRenameReportModal(false);
  renameReportId(0);
};

export const showRenameDashboard = (id) => {
  renameDashboardId(id);
  showRenameDashboardModal(true);
};
export const closeRenameDashboard = () => {
  showRenameDashboardModal(false);
  renameDashboardId(0);
};

export const showRenameKpi = (id) => {
  renameKpiId(id);
  showRenameKpiModal(true);
};
export const closeRenameKpi = () => {
  showRenameKpiModal(false);
  renameKpiId(0);
};

export const showRenameSpreadsheet = (id) => {
  renameSpreadsheetId(id);
  showRenameSpreadsheetModal(true);
};
export const closeRenameSpreadsheet = () => {
  showRenameSpreadsheetModal(false);
  renameSpreadsheetId(0);
};

export const showFilters = (
  itemType,
  itemId,
  classId,
  departmentId,
  isBudget,
  showApplyAll
) => {
  filterItemType(itemType);
  filterItemId(itemId);
  filterClassId(classId || null);
  filterDepartmentId(departmentId || null);
  filterIsBudget(isBudget || false);
  filterShowApplyAll(showApplyAll || false);
  showFiltersModal(true);
};

export const integrationFiltersStatus = makeVar({
  show: false,
  itemType: null,
  itemId: null,
  isBudget: null,
  showApplyAll: null,
  currentFilters: null,
  integrationToShow: null,
});
export const showIntegrationFilters = (
  itemType,
  itemId,
  isBudget,
  showApplyAll,
  currentFilters,
  integrationToShow = null
) => {
  const { classId, departmentId, gustoLocationIds } = currentFilters;

  integrationFiltersStatus({
    show: true,
    itemType,
    itemId,
    isBudget,
    showApplyAll,
    currentFilters: { classId, departmentId, gustoLocationIds },
    integrationToShow,
  });
};
export const hideIntegrationFilters = () => {
  integrationFiltersStatus({
    show: false,
    itemType: null,
    itemId: null,
    isBudget: null,
    showApplyAll: null,
    currentFilters: null,
    integrationToShow: null,
  });
};

export const closeFilters = () => {
  showFiltersModal(false);
};

export const showDashboardLibrary = () => showDashboardLibraryModal(true);
export const closeDashboardLibrary = () => showDashboardLibraryModal(false);

export const showSelectData = (
  subscription,
  single,
  value,
  dates,
  disableTotals = false,
  companyId = null,
  elimination = false,
  liveText = false,
  googleSheetsOnly = false,
  summitAndersWebApp = false
) => {
  selectDataModalSubscription(subscription || null);
  selectDataModalSingle(single || false);
  selectDataModalValue(value || null);
  selectDataModalDates(dates || false);
  selectDataModalDisableTotals(disableTotals);
  selectDataModalFilteredCompanyId(companyId);
  selectDataModalElimination(elimination);
  selectDataModalLiveText(liveText);
  selectDataModalGoogleSheets(googleSheetsOnly);
  selectDataModalSummitAndersWebApp(summitAndersWebApp);
  showSelectDataModal(true);
};
export const closeSelectData = () => {
  showSelectDataModal(false);
};

export const showReportPreview = (id) => {
  reportPreviewId(id);
  showReportPreviewModal(true);
};
export const closeReportPreview = () => {
  reportPreviewId(0);
  showReportPreviewModal(false);
};

export const showPublish = (itemType, itemId) => {
  publishModalItemType(itemType);
  publishModalItemId(itemId);
  showPublishModal(true);
};
export const closePublish = () => {
  publishModalItemType(null);
  publishModalItemId(0);
  showPublishModal(false);
};

export default {
  showAiDashboardGeneratorModal,
  showAiReportGeneratorModal,
  showCreateDashboardModal,
  showCreateReportModal,
  showRenameDashboardModal,
  showRenameReportModal,
  showRenameKpiModal,
  showRenameSpreadsheetModal,
  showDashboardLibraryModal,
  showReportLibraryModal,
  showTableLibraryModal,
  tableLibraryItemId,
  tableLibraryItemType,
  showDashboardPreviewModal,
  dashboardPreviewId,
  showReportPreviewModal,
  reportPreviewId,
  showSpreadsheetModal,
  renameDashboardId,
  renameSpreadsheetId,
  renameReportId,
  renameKpiId,
  showDuplicateReportModal,
  showSelectDataModal,
  selectDataModalSubscription,
  selectDataModalSingle,
  selectDataModalValue,
  selectDataModalDates,
  selectDataModalDisableTotals,
  selectDataModalFilteredCompanyId,
  selectDataModalElimination,
  selectDataModalLiveText,
  duplicateReportId,
  duplicateDashboardId,
  showDuplicateDashboardModal,
  showFiltersModal,
  integrationFiltersStatus,
  filterItemType,
  filterItemId,
  filterClassId,
  filterDepartmentId,
  filterPartialClassId,
  filterPartialDepartmentId,
  filterIsBudget,
  filterShowApplyAll,
  showPublishModal,
  publishModalItemId,
  publishModalItemType,
  showTrainingVideosModal,
  showFullscreen,
  selectDataModalGoogleSheets,
  selectDataModalSummitAndersWebApp,
};
