import { useState, useEffect, useCallback } from 'react';

export default function useTimeout(callback, delay, lazy = false) {
  const [resetCount, setResetCount] = useState(0);

  const resetTimeout = useCallback(() => {
    setResetCount(resetCount + 1);
  }, [resetCount]);

  useEffect(() => {
    if (lazy && resetCount === 0) return;
    const id = setTimeout(() => callback(resetTimeout), delay);
    return () => clearTimeout(id);
  }, [callback, delay, lazy, resetCount, resetTimeout]);

  return { resetTimeout };
}
