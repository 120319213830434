import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import useCompanyId from './useCompanyId';

export const ACCOUNTING_INTEGRATION = gql`
  query UseAccountingIntegration($companyId: Int!) {
    company(id: $companyId) {
      id
      accountingIntegration
    }
  }
`;

export default function useAccountingIntegrationId({
  companyId: _companyId,
} = {}) {
  const companyId = useCompanyId() || _companyId;

  const { data } = useQuery(ACCOUNTING_INTEGRATION, {
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  if (!companyId) return null;

  if (!data || !data.company) {
    return null;
  }

  return data.company.accountingIntegration || false;
}
