import { motion } from 'framer-motion';
import styled from 'styled-components';

export const DETAILS_PADDING = 10;

export const Container = styled(motion.div)`
  position: relative;
  cursor: pointer;
  margin: 10px;
  flex: 0 0 calc(33.33% - 20px);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  overflow: hidden;
`;

export const Thumbnail = styled(motion.img)`
  width: 100%;
`;

export const Details = styled(motion.div)<{ descHeight: number }>`
  position: absolute;
  height: auto;
  width: 100%;
  bottom: ${({ descHeight }) => -(descHeight + DETAILS_PADDING)}px;
  background-color: black;
  opacity: 0.8;
  color: white;
  padding: ${DETAILS_PADDING}px;
  box-sizing: border-box;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 0.8rem;

  span {
    margin-left: 5px;
  }

  path {
    fill: #4fbade;
  }

  circle {
    stroke: #4fbade;
  }
`;

export const Description = styled.div`
  font-size: 0.75rem;
`;
