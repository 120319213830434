/**
 *
 * CreateConsolidation
 *
 */

import React, { useMemo } from 'react';
import gql from 'graphql-tag';

import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import ConsolidatedPicker from 'components/ConsolidatedPicker/index.tsx';
import { useQuery } from '@apollo/client';
import HideScrollbar from 'components/HideScrollbar';
import HideHubspot from 'components/HideHubspot';

const QUERY = gql`
  query CreateConsolidation($workspaceId: Int!) {
    workspace(id: $workspaceId) {
      id
      groups {
        id
        name
        companies(integrationType: ACCOUNTING) {
          id
          name
          currency
          applyCurrencyConversion
          accountingIntegration
          hasPermission(
            permission: CREATE_CONSOLIDATION
            firmPermission: CREATE_CONSOLIDATION
          )
          accounting {
            lastSynced
            accountingBasis
          }
          isConsolidation
          consolidationCompanies {
            id
            name
            currency
            parentConsolidationId
            abbreviation
          }
        }
      }
    }
  }
`;

const PARENT = gql`
  query ParentCompany($companyId: Int!) {
    company(id: $companyId) {
      id
      name
      accountingIntegration
      hasPermission(
        permission: CREATE_CONSOLIDATION
        firmPermission: CREATE_CONSOLIDATION
      )
      isConsolidation
      currency
      applyCurrencyConversion
      consolidationCompanies {
        id
        name
        currency
        parentConsolidationId
        abbreviation
        accounting {
          lastSynced
          accountingBasis
        }
        accountingIntegration
      }
      filters
      consolidationOwner {
        id
        email
      }
    }
  }
`;

function CreateConsolidation(props) {
  const {
    show,
    onClose,
    firmId,
    companyId,
    companyName,
    workspaceId,
    canManageConsolidation,
  } = props;
  const { data: parentData } = useQuery(PARENT, {
    variables: {
      companyId,
    },
    skip: !companyId,
  });
  const { data, loading } = useQuery(QUERY, {
    variables: {
      workspaceId,
    },
  });

  const company = parentData && parentData.company;
  const consolidationOwner =
    company && company.consolidationOwner && company.consolidationOwner.email;
  const consolidationCurrency = company && company.currency;
  const applyCurrencyConversion = company && company.applyCurrencyConversion;

  const folders = useMemo(() => {
    if (!data || !data.workspace) return null;

    const folderResult = [];

    const firms = data.workspace.groups;

    firms.forEach((firm) => {
      const companies = firm.companies.filter((c) => {
        return !c.isConsolidation && c.hasPermission;
      });
      if (companies.length) {
        folderResult.push({
          name: firm.name,
          companies,
        });
      }
    });

    return folderResult;
  }, [data]);

  const manage = !!companyId;

  const showLoading = loading || !data || !data.workspace;

  return (
    <Modal
      title={
        canManageConsolidation
          ? `Manage ${companyName}`
          : manage
          ? companyName
          : 'Create a Consolidated Company'
      }
      show={show}
      width={1200}
      fullscreen
      onClose={onClose}
    >
      <HideScrollbar />
      {showLoading && <Spinner />}

      {canManageConsolidation && folders && !folders.length && (
        <p>You don&apos;t have any companies to put in a consolidation.</p>
      )}

      {folders &&
        (!!folders.length || !canManageConsolidation) &&
        (company || !companyId) && (
          <ConsolidatedPicker
            key={manage ? `manage_${companyId}` : 'create'}
            company={company}
            consolidationCurrency={consolidationCurrency}
            applyCurrencyConversion={applyCurrencyConversion}
            folders={folders}
            firmId={firmId}
            onClose={onClose}
            showExpandCollapseAll={false}
            canManageConsolidation={canManageConsolidation}
            consolidationOwner={consolidationOwner}
          />
        )}

      {show && <HideHubspot />}
    </Modal>
  );
}

export default CreateConsolidation;
