import styled from 'styled-components';

export const StyleWrapper = styled.div`
  & .react-datepicker {
    border: none;
    font-family: 'Open Sans', sans-serif;
    color: ${(props) => props.theme.datePicker.color};
  }

  & .react-datepicker__header {
    background-color: #ffffff;
    border-bottom: unset;
  }

  & .react-datepicker-year-header,
  .react-datepicker__current-month {
    font-size: 1.5rem;
    font-weight: 200;
    color: ${(props) => props.theme.datePicker.color};
  }

  & .react-datepicker__navigation {
    top: 15px;
    color: ${(props) => props.theme.datePicker.color};
  }

  & .react-datepicker__monthPicker {
    width: 200px;
  }

  & .react-datepicker__month .react-datepicker__month-text,
  .react-datepicker__month .react-datepicker__quarter-text {
    width: 4.5rem;
    height: 2rem;
  }

  & .react-datepicker__month-text,
  .react-datepicker__month-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .react-datepicker__day--selected,
  .react-datepicker__month--selected {
    background-color: ${(props) =>
      props.theme.datePicker.selectedBackgroundColor} !important;
    &:hover {
      background-color: ${(props) =>
        props.theme.datePicker.selectedBackgroundHoverColor} !important;
    }
  }

  .react-datepicker__month-text--keyboard-selected {
    background: none;
    // aria-selected === true
    color: unset;
    &[aria-selected='true'] {
      color: white !important;
    }
  }

  /* & .react-datepicker__month-text--keyboard-selected {
    background-color: ${(props) =>
    props.theme.datePicker.keyboardSelectedBackgroundColor};
    &:hover {
      background-color: ${(props) =>
    props.theme.datePicker.keyboardSelectedBackgroundColor};
    }
  } */
`;
