import { gql } from '@apollo/client';
import { DASHBOARD_LIST } from 'fragments';

const DASHBOARD_LIST_QUERY = gql`
  query DashboardList($companyId: Int!, $theme: Theme) {
    company(id: $companyId) {
      id
      aiEnabled
      dashboards {
        ...DashboardListFields
      }
    }
  }
  ${DASHBOARD_LIST}
`;

export default DASHBOARD_LIST_QUERY;
