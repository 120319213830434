import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Bar } from '../styledComponents';
interface StyleProps {
  $mouseEntered?: boolean;
  colors?: {
    warning: string;
  };
  beta?: string;
}

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  transition: background-color 0.15s ease-out;
  &:hover {
    background-color: ${(props) => props.theme.sidebar.buttonHighlight};
    color: ${(props) => props.theme.sidebar.background};
  }
`;

const buttonStyles = `
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px 0;
  font-weight: 300;
  width: 100%;
  ${(props: StyleProps) =>
    props.beta === 'true' &&
    css`
      &:after {
        content: 'Beta';
        position: absolute;
        bottom: 0px;
        right: 2px;
        font-size: 0.575rem;
        color: ${(p) => p.theme.colors.warning};
      }
    `}
`;

export const Button = styled.button<StyleProps>`
  color: ${(props) => props.theme.sidebar.color};
  ${Bar}:hover {
    color: ${(props) => props.theme.sidebar.hoverColor};
  }

  ${buttonStyles}

  &:hover {
    color: ${(props) => props.theme.sidebar.hoverColor};
    svg > path {
      fill: ${(props) => props.theme.sidebar.hoverColor};
    }
  }
`;

export const ButtonLink = styled(NavLink)<StyleProps>`
  &.active {
    color: ${(props) => props.theme.sidebar.hoverColor};
    svg > path {
      fill: ${(props) => props.theme.sidebar.hoverColor};
    }
    background-color: ${(props) => props.theme.sidebar.buttonActive};
  }
  &:hover {
    color: ${(props) => props.theme.sidebar.hoverColor};
    svg > path {
      fill: ${(props) => props.theme.sidebar.hoverColor};
    }
  }
  color: ${(props) => props.theme.sidebar.color};
  ${buttonStyles}
`;

interface ColorProps extends StyleProps {
  showText?: boolean;
  mouseEntered?: boolean;
}

export const Text = styled.div<ColorProps>`
  width: 0;
  text-align: left;
  font-size: 0.85rem;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.4s ease-out, color 0.15s ease-out;
  ${Bar}:hover & {
    opacity: 1;
  }
`;

export const Icon = styled.div<ColorProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  svg > path {
    fill: ${(props) => props.theme.sidebar.color};
    transition: fill 0.15s ease-out;
  }
`;
