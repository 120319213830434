import * as Yup from 'yup';
import Location from 'react-app-location';

export const DashboardLocation = new Location('/dashboard/:dashboardId', {
  dashboardId: Yup.number().integer().positive().required(),
});

export const EditCustomKpiLocation = new Location('/kpi/:kpiId', {
  kpiId: Yup.number().integer().positive().required(),
});

export const EditCanvasReportLocation = new Location('/report/edit/:reportId', {
  reportId: Yup.number().integer().positive().required(),
});

export const WorkspacesLocation = new Location('/workspaces/:workspaceId?', {
  workspaceId: Yup.number().integer().positive(),
});

export const AddWorkspaceCompanyLocation = new Location(
  '/workspaces/:workspaceId/add',
  {
    workspaceId: Yup.number().integer().positive().required(),
  }
);

export const ClientPortalPreviewLocation = new Location(
  '/workspaces/:workspaceId/client/:companyId/:previewUserId?',
  {
    workspaceId: Yup.number().integer().positive().required(),
    companyId: Yup.number().integer().positive().required(),
    previewUserId: Yup.number().integer().positive().optional(),
  }
);
export const ClientPortalPreviewDashboardLocation = new Location(
  '/workspaces/:workspaceId/client/:companyId/folder/:folderId/dashboard/:dashboardId',
  {
    workspaceId: Yup.number().integer().positive().required(),
    companyId: Yup.number().integer().positive().required(),
    folderId: Yup.number().integer().positive().required(),
    dashboardId: Yup.number().integer().positive().required(),
  }
);

export const ClientPortalClientEditBudgetLocation = new Location(
  '/portal/:companyId/folder/:folderId/budget/:tableId',
  {
    folderId: Yup.number().integer().positive().required(),
    companyId: Yup.number().integer().positive().required(),
    tableId: Yup.number().integer().positive().required(),
  }
);

export const ClientPortalCompanyLocation = new Location('/portal/:companyId', {
  companyId: Yup.number().integer().positive().required(),
});

export const ClientPortalDashboardLocation = new Location(
  '/portal/:companyId/folder/:folderId/dashboard/:dashboardId',
  {
    companyId: Yup.number().integer().positive().required(),
    folderId: Yup.number().integer().positive().required(),
    dashboardId: Yup.number().integer().positive().required(),
  }
);
