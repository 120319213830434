import React, { useMemo, useCallback } from 'react';
import gql from 'graphql-tag';
import Button from 'components/Button';
import {
  AvatarCustom,
  CustomInput,
  CustomInputIcon,
  DivRow,
  ErrorDiv,
  MissingLabel,
  TableStyle,
  TeamAccessButtonC,
  ErrorWrapper,
  ExcelIcon,
} from './styledComponents';

import SearchIcon from '@material-ui/icons/Search';
import { useTable } from 'react-table';

import GroupCompanyListItem from '../GroupCompanyListItem';

import { useHistory } from 'react-router-dom';
import Dropdown from 'components/Dropdown';
import DropdownButton from 'components/DropdownButton';

export const ValidationErrors = ({ validationErrors }) => {
  return (
    <ErrorWrapper>
      {!!validationErrors && validationErrors.empty && (
        <ErrorDiv>File is empty</ErrorDiv>
      )}

      {!!validationErrors && !!validationErrors.missingColumns && (
        <>
          <ErrorDiv>
            <div>Missing Columns:</div>
            {validationErrors.missingColumns.map((name, index) => (
              <MissingLabel key={index}>{name}</MissingLabel>
            ))}
          </ErrorDiv>
        </>
      )}
      {!!validationErrors &&
        !!validationErrors.errors &&
        validationErrors.errors.map((error, index) => {
          return (
            <React.Fragment key={index}>
              {error.columnErrors.map((colError) => {
                return (
                  <ErrorDiv key={index}>
                    Line: {error.line}, {colError.error}
                  </ErrorDiv>
                );
              })}
            </React.Fragment>
          );
        })}
    </ErrorWrapper>
  );
};

export const AvatarLetter = ({ content }) => (
  <AvatarCustom>
    {' '}
    {content[0] || ''}
    {content[1] || ''}{' '}
  </AvatarCustom>
);

export const TeamAccessButton = ({ onClick, content = 'Team Access' }) => {
  return <TeamAccessButtonC onClick={onClick}>{content}</TeamAccessButtonC>;
};

export const SearchBar = ({ onChange }) => {
  return (
    <DivRow>
      <CustomInput onChange={onChange} autoComplete="off" data-1p-ignore />
      <CustomInputIcon aria-label="search">
        <SearchIcon style={{ width: '0.8em' }} />
      </CustomInputIcon>
    </DivRow>
  );
};

export const ZoomSection = () => <h4>ZoomSection</h4>;

const TdCell = ({ cell, j, style }) => {
  return (
    <td key={j} {...cell.getCellProps()} style={style}>
      {cell.render('Cell')}
    </td>
  );
};

const Table = ({
  dataColumns,
  data,
  items,
  minimal,
  onClose,
  onDelete,
  onDuplicate,
  onRename,
  onClickUpdateExcel,
  downloadingId,
}) => {
  const settingsColumn = useMemo(
    () => ({
      // Make an expander cell
      Header: () => null, // No header
      id: 'expander', // It needs an ID
      width: 25,
      // eslint-disable-next-line react/display-name
      Cell: ({ row }) => (
        // Use Cell to render an expander for each row.
        // We can use the getToggleRowExpandedProps prop-getter
        // to build the expander.
        <Dropdown
          portal
          contentStyle={{ zIndex: 100000 }}
          offsetY={-90}
          offsetX={-160}
          verticalAlign="top"
          align="right"
          trigger={
            <button data-testid={`budget-settings-button-${row.index}`}>
              <i className="mdi-set mdi-cog" />
            </button>
          }
        >
          {({ closeDropdown, open }) => (
            <div data-testid={open ? `budget-settings-dropdown` : undefined}>
              <DropdownButton
                onClick={() => {
                  onRename(row.original.id);
                  closeDropdown();
                }}
              >
                Rename
              </DropdownButton>
              {!row.original.pending && (
                <DropdownButton
                  onClick={() => {
                    onDuplicate(row.original.id);
                    closeDropdown();
                  }}
                >
                  Duplicate
                </DropdownButton>
              )}

              <DropdownButton
                onClick={() => {
                  onDelete(row.original.id);
                }}
              >
                Delete
              </DropdownButton>
            </div>
          )}
        </Dropdown>
      ),
    }),
    [onDelete, onDuplicate, onRename]
  );

  const history = useHistory();

  const excelColumn = useMemo(
    () => ({
      accessor: 'isExcel',
      width: 120,
      Cell: function BudgetTypeCell({ row, cell: { value } }) {
        if (value) {
          let text = 'Download';
          if (downloadingId === row.original.id) {
            text = 'Downloading...';
          }
          return (
            <button
              onClick={() =>
                row.original.handleDownloadExcel(
                  row.original.id,
                  row.original.name
                )
              }
              disabled={!!downloadingId}
            >
              <ExcelIcon /> {text}
            </button>
          );
        }
        return null;
      },
    }),
    [downloadingId]
  );

  const launchColumn = useMemo(
    () => ({
      // Make an expander cell
      Header: () => null, // No header
      id: 'launch', // It needs an ID
      width: 60,
      // eslint-disable-next-line react/display-name
      Cell: ({ row }) => {
        if (row.original.isExcel) {
          return (
            <button
              onClick={() => onClickUpdateExcel(row.original.id)}
              disabled={row.original.pending || !!downloadingId}
            >
              {row.original.pending ? 'Creating...' : 'Update'}
            </button>
          );
        }

        return (
          <Button
            color="primary"
            compact
            onClick={() => {
              history.push(`/table/${row.original.id}`);
              onClose();
            }}
            disabled={row.original.pending || !!downloadingId || false}
            loading={row.original.pending || false}
          >
            {row.original.pending ? 'Creating...' : 'Open'}
          </Button>
        );
      },
    }),
    [downloadingId, history, onClickUpdateExcel, onClose]
  );

  const _columns = useMemo(
    () =>
      minimal
        ? [...dataColumns, excelColumn, launchColumn]
        : [...dataColumns, excelColumn, launchColumn, settingsColumn],
    [dataColumns, excelColumn, settingsColumn, minimal, launchColumn]
  );

  const {
    getTableProps,
    getTableBodyProps,
    columns,
    rows,
    prepareRow,
    // visibleColumns
  } = useTable({
    columns: _columns,
    data,
  });

  return (
    <table {...getTableProps()}>
      <thead>
        <tr>
          {columns.map((column, j) => (
            <th
              key={j}
              {...column.getHeaderProps()}
              style={column.width ? { width: column.width } : null}
            >
              {column.render('Header')}
            </th>
          ))}
        </tr>
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <React.Fragment key={i}>
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, j) => {
                  return (
                    <TdCell key={j} row={row} cell={cell} j={j} items={items} />
                  );
                })}
              </tr>
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

export const ReactTable = ({
  data,
  dataColumns,
  items,
  workspaceId,
  groupId,
  minimal,
  expanded,
  onClose,
  onDelete,
  onDuplicate,
  onRename,
  onClickUpdateExcel,
  downloadingId,
}) => {
  const renderRowSubComponent = React.useCallback(
    ({ row, groupId, toggleRowExpanded }) => (
      <GroupCompanyListItem
        row={row}
        workspaceId={workspaceId}
        groupId={groupId}
        toggleRowExpanded={toggleRowExpanded}
      />
    ),
    [workspaceId]
  );

  return (
    <TableStyle>
      <Table
        data={data}
        workspaceId={workspaceId}
        groupId={groupId}
        dataColumns={dataColumns}
        items={items}
        minimal={minimal}
        expanded={expanded}
        renderRowSubComponent={renderRowSubComponent}
        onClose={onClose}
        onDelete={onDelete}
        onDuplicate={onDuplicate}
        onRename={onRename}
        onClickUpdateExcel={onClickUpdateExcel}
        downloadingId={downloadingId}
      />
    </TableStyle>
  );
};
