import { Company, CompanyInvite } from './company';
import { StripeSubscription } from './stripeSubscription';
import { Workspace, WorkspaceInvite } from './workspace';

export enum Theme {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
}

export enum TwoFactorAuthenticationMethod {
  TOTP = 'TOTP',
  PHONE = 'PHONE',
}

export interface ScratchPadItem {
  id: number;
  name: string;
  objects: JSON;
  svg: string;
  width: number;
  height: number;
  isPage: boolean;
}

export interface SetThemeResult {
  success: boolean;
  theme: Theme;
}

export interface MyobCompany {
  id: string;
  name: string;
  alreadyAttached: boolean;
}

export interface MyobConnectionStatus {
  connecting: boolean | null;
  companies: MyobCompany[] | null;
  attachingCompanyId: string | null;
}

export interface User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  lastLoggedIn: Date;
  createdAt: Date;
  theme: Theme;
  clientPortalOnly: boolean;
  phoneLast4: string;
}

export interface CurrentUser extends User {
  workspaces: Workspace[];
  workspaceInvites: WorkspaceInvite[];
  userId: number;
  stripeSubscription: StripeSubscription;
  companyInvites: CompanyInvite[];
  companies: Company[];
  hasTwoFactorAuth: boolean;
  verifiedEmail: boolean;
  verifiedTotp: boolean;
  verifiedTwoFactorPhone: boolean;
  backupCodes: string[];
  downloadedBackupCodes: boolean;
  myobConnectionStatus: MyobConnectionStatus | null;
}

export interface DeleteUserVariables {
  forceDelete: boolean;
  code?: string;
  method?: TwoFactorAuthenticationMethod;
}

export interface DeleteUserResult {
  deleteUser: {
    id: number;
    success: boolean;
    error?: string;
  };
}
