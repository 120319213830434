import React from 'react';
import PropTypes from 'prop-types';
import invariant from 'tiny-invariant';
import warning from 'tiny-warning';

import { withRouter, matchPath } from 'react-router';

/**
 * The public API for rendering the first <Route> that matches.
 */
@withRouter
class Switch extends React.Component {
  render() {
    const { location } = this.props;
    let element, match;

    // We use React.Children.forEach instead of React.Children.toArray().find()
    // here because toArray adds keys to all child elements and we do not want
    // to trigger an unmount/remount for two <Route>s that render the same
    // component at different URLs.
    React.Children.forEach(this.props.children, child => {
      if (match == null && React.isValidElement(child)) {
        element = child;

        let path = child.props.path || child.props.from;
        if (child.props.loc) {
          path = child.props.loc.path;
        }

        match = path
          ? matchPath(location.pathname, { ...child.props, path })
          : context.match;
      }
    });

    return match
      ? React.cloneElement(element, { location, computedMatch: match })
      : null;
  }
}

if (process.env.NODE_ENV !== 'production') {
  Switch.propTypes = {
    children: PropTypes.node,
    location: PropTypes.object,
  };

  Switch.prototype.componentDidUpdate = function(prevProps) {
    warning(
      !(this.props.location && !prevProps.location),
      '<Switch> elements should not change from uncontrolled to controlled (or vice versa). You initially used no "location" prop and then provided one on a subsequent render.'
    );

    warning(
      !(!this.props.location && prevProps.location),
      '<Switch> elements should not change from controlled to uncontrolled (or vice versa). You provided a "location" prop initially but omitted it on a subsequent render.'
    );
  };
}

export default Switch;
