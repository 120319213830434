import styled from 'styled-components';
import ReactToggle from 'react-toggle';
import BaseButton from 'components/Button';

const SIDE_MARGIN = 10;
export const CONTAINER_WIDTH = 446;
export const CONTAINER_HEIGHT = 303;

export const DayMonthToggle = styled(ReactToggle)`
  margin: 0 5px;
`;

export const Container = styled.div<{
  show: boolean;
  top: number;
  left: number;
}>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  position: fixed;
  flex-direction: column;
  justify-content: center;
  z-index: 4000;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.5s ease-out;
  background-color: white;
  border-radius: 4px;
  padding: 20px 10px;
  box-shadow: 0 4px 14px rgb(174 197 231 / 50%);
  width: ${CONTAINER_WIDTH}px;
  height: ${CONTAINER_HEIGHT}px;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
`;

export const Header = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  color: ${(props) => props.theme.datePicker.color};
  left: ${SIDE_MARGIN}px;
  top: 23px;

  & span {
    font-size: 0.5rem;
  }

  & .react-toggle-track {
    width: 25px;
    height: 11px;
    background-color: ${(props) =>
      props.theme.datePicker.toggleBackgroundColor};
  }

  & .react-toggle-thumb {
    width: 11px;
    height: 11px;
    top: 0;
  }

  & .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: ${(props) =>
      props.theme.datePicker.toggleBackgroundColorHover};
  }

  & .react-toggle--checked .react-toggle-thumb {
    left: 13px;
  }

  & .react-toggle--focus .react-toggle-thumb {
    box-shadow: unset;
  }

  & .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    box-shadow: unset;
  }
`;

export const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DateHeader = styled.span`
  color: ${(props) => props.theme.datePicker.color};
  font-size: 0.75rem;
`;

export const DateRangeContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  margin: 0 ${SIDE_MARGIN}px;
  color: ${(props) => props.theme.datePicker.color};
`;

export const DateDisplay = styled.div`
  font-size: 0.95rem;
`;

export const LauncherButton = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.datePicker.launcherColor};
`;

export const Buttons = styled.div`
  margin-left: auto;
  & button:nth-of-type(1) {
    margin-right: 10px;
  }
`;

export const CancelButton = styled(BaseButton)`
  color: ${({ theme }) => theme.colors.default} !important;
  &:hover {
    color: ${({ theme }) => theme.colors.white} !important;
  }
`;
