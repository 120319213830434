import gql from 'graphql-tag';
import useWorkspaceId from './useWorkspaceId';
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

const QUERY = gql`
  query NeedsToPay($workspaceId: Int!) {
    workspace(id: $workspaceId) {
      id
      inactive
      isBillingUser
    }
  }
`;

export default function useNeedsToPay() {
  const workspaceId = useWorkspaceId();

  const { data } = useQuery(QUERY, {
    variables: {
      workspaceId,
    },
  });

  const workspace = useMemo(() => {
    if (!data || !data.workspace) return null;
    return data.workspace;
  }, [data]);

  const result = useMemo(() => {
    if (workspace) {
      if (workspace.inactive && workspace.isBillingUser) {
        return {
          needsToPay: true,
          ownerNeedsToPay: false,
        };
      } else if (workspace.inactive && !workspace.isBillingUser) {
        return {
          needsToPay: false,
          ownerNeedsToPay: true,
        };
      }
      return {
        needsToPay: false,
        ownerNeedsToPay: false,
      };
    } else {
      return {
        needsToPay: null,
        ownerNeedsToPay: null,
      };
    }
  }, [workspace]);

  return result;
}
