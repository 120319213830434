import React from 'react';
import { Query } from '@apollo/client/react/components';
import gql from 'graphql-tag';
import hoistNonReactStatics from 'hoist-non-react-statics';

import getDisplayName from 'utils/getDisplayName';
import LoadingIndicator from 'components/LoadingIndicator';

const QUERY = gql`
  query CompanyRoute {
    isLoggedIn @client
  }
`;

function withIsLoggedIn(Component) {
  const WithIsLoggedIn = (props) => (
    <Query query={QUERY}>
      {({ data, loading, error }) => {
        if (loading || error) {
          return <LoadingIndicator />;
        }

        return <Component {...props} isLoggedIn={data.isLoggedIn} />;
      }}
    </Query>
  );

  WithIsLoggedIn.displayName = `WithIsLoggedIn(${getDisplayName(Component)})`;

  hoistNonReactStatics(WithIsLoggedIn, Component);

  return WithIsLoggedIn;
}

export default withIsLoggedIn;
