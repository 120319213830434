import Checkbox from 'components/Checkbox';
import React, { useMemo, useRef, useState } from 'react';
import { Row as RowType } from 'react-table';
import { UserRow } from './TableInner';
import styled from 'styled-components';
import Button from 'components/Button';
import { User } from 'interfaces/user';
import Dropdown from 'components/Dropdown';
import DropdownButton from 'components/DropdownButton';
import ClientAlertModal from 'components/ClientAlertModal';
import Tooltip from 'components/Tooltip';

const INITIAL_OFFSET_Y = -60;
const SHIFT_PADDING = 40;
const BUTTON_HEIGHT = 21;
const HEADER_HEIGHT = 42;

const FolderButton = styled(Button)`
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 0.75rem;
  &:hover {
    background-color: rgb(97, 113, 130);
  }
`;

const AlertButton = styled(Button)`
  font-size: 1.25rem;
  height: 35px;
  padding: unset;
  &:hover {
    background-color: unset;
  }
`;

const UserButton = styled(Button)`
  font-size: 1.25rem;
  height: 35px;
  padding: unset;
  &:hover {
    background-color: unset;
  }
`;

const GearButton = styled(Button)`
  font-size: 1.25rem;
  height: 35px;
  padding: unset;
  &:hover {
    background-color: unset;
  }
`;

const DropdownHeader = styled.span`
  display: flex;
  justify-content: center;
  padding-bottom: 5px;
  border-bottom: 1px solid ${({ theme }) => theme.body.gearColor};
  font-size: 0.85rem;
`;

const ChangeToButton = styled(DropdownButton)`
  color: ${({ theme }) => theme.colors.warning} !important;
`;

const RemoveSecondaryAdminButton = styled(DropdownButton)`
  color: ${({ theme }) => theme.colors.danger} !important;
`;

const I = styled.i`
  color: ${({ theme }) => theme.colors.gear} !important;
`;

const RemoveButton = styled(DropdownButton)`
  color: ${({ theme }) => theme.colors.danger} !important;
`;

export interface Props<T extends UserRow> {
  row: RowType<T>;
  showChecks?: boolean;
  selectedItemIds?: number[];
  readOnlyUserIds?: number[];
  currentUserId?: number;
  phone?: boolean;
  desktop?: boolean;
  isOwner?: boolean;
  hasSecondaryAdmin?: boolean;
  isSecondaryAdmin?: boolean;
  isClientPortalUser?: boolean;
  showClientUserOptions?: boolean;
  loadingUsers?: boolean;
  loadingResendInvite?: boolean;
  onSelect?: (id: number, checked: boolean, type: 'user' | 'invite') => void;
  onFolderAccessButton?: (user: User) => void;
  onPreview?: (id: number) => void;
  onRemove?: (user: User) => void;
  onRemoveInvite?: (id: number, user: User) => void;
  onResendInvite?: (user: User) => void;
  onShowUpdateInviteFolders?: (id: number) => void;
  onWorkspaceOwnerChange?: (user: User) => void;
  onWorkspaceSecondaryAdminChange?: (user: User, revoke: boolean) => void;
  onClientUserChange?: (user: User) => void;
  workspaceId: number;
  companyId: number;
}

const Row = <T extends UserRow>({
  row,
  showChecks,
  selectedItemIds,
  readOnlyUserIds,
  currentUserId,
  phone,
  desktop,
  isOwner,
  hasSecondaryAdmin,
  isSecondaryAdmin,
  isClientPortalUser,
  showClientUserOptions,
  loadingUsers,
  loadingResendInvite,
  onSelect,
  onFolderAccessButton,
  onPreview,
  onRemove,
  onRemoveInvite,
  onResendInvite,
  onShowUpdateInviteFolders,
  onWorkspaceOwnerChange,
  onWorkspaceSecondaryAdminChange,
  onClientUserChange,
  workspaceId,
  companyId,
}: Props<T>): React.ReactElement => {
  const ref = useRef<HTMLTableRowElement>(null);
  const [offsetY, setOffsetY] = useState(INITIAL_OFFSET_Y);
  const [showClientAlertModal, setShowClientAlertModal] = useState(false);
  const dropdownButtonCount = useMemo(() => {
    let count = 0;
    if (onResendInvite && row.original.inviteId) count++;
    if (isClientPortalUser) count++;
    if (row.original.link || row.original.inviteLink) count++;
    if (onShowUpdateInviteFolders && row.original.inviteId) count++;
    if (onFolderAccessButton && phone) count++;
    if (onPreview && phone) count++;
    if (isOwner && showClientUserOptions && onWorkspaceOwnerChange) count++;
    if (
      isOwner &&
      showClientUserOptions &&
      onWorkspaceSecondaryAdminChange &&
      !hasSecondaryAdmin
    )
      count++;
    if (
      (isOwner || isSecondaryAdmin) &&
      showClientUserOptions &&
      onClientUserChange
    )
      count++;
    if (onRemove || onRemoveInvite) count++;
    return count;
  }, [
    isClientPortalUser,
    isOwner,
    hasSecondaryAdmin,
    isSecondaryAdmin,
    onClientUserChange,
    onFolderAccessButton,
    onPreview,
    onRemove,
    onRemoveInvite,
    onResendInvite,
    onShowUpdateInviteFolders,
    onWorkspaceOwnerChange,
    onWorkspaceSecondaryAdminChange,
    phone,
    row.original.inviteId,
    row.original.inviteLink,
    row.original.link,
    showClientUserOptions,
  ]);

  const calculateOffset = () => {
    if (ref.current) {
      const bottom = ref.current.getBoundingClientRect().bottom;
      const dropdownMenuY =
        bottom +
        BUTTON_HEIGHT * dropdownButtonCount +
        HEADER_HEIGHT -
        INITIAL_OFFSET_Y;

      if (dropdownMenuY > window.innerHeight) {
        setOffsetY(window.innerHeight - dropdownMenuY - SHIFT_PADDING);
      } else {
        setOffsetY(INITIAL_OFFSET_Y);
      }
    }
  };

  const handleAlert = () => {
    setShowClientAlertModal(true);
  };
  return (
    <>
      <tr {...row.getRowProps()} ref={ref}>
        {showChecks && selectedItemIds && (
          <td>
            <Checkbox
              checked={
                selectedItemIds.includes(
                  row.original.inviteId
                    ? row.original.inviteId
                    : row.original.id
                ) ||
                row.original.owner ||
                row.original.secondaryAdmin ||
                false
              }
              disabled={
                (readOnlyUserIds &&
                  readOnlyUserIds.includes(row.original.id)) ||
                row.original.owner ||
                row.original.secondaryAdmin ||
                row.original.id === currentUserId
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (row.original.owner || row.original.secondaryAdmin) return;
                if (onSelect) {
                  onSelect(
                    row.original.inviteId
                      ? row.original.inviteId
                      : row.original.id,
                    e.target.checked,
                    row.original.inviteId ? 'invite' : 'user'
                  );
                }
              }}
            />
          </td>
        )}

        {row.cells.map((cell, index) => (
          <td {...cell.getCellProps()} key={index}>
            {cell.render('Cell')}
          </td>
        ))}
        {!!onFolderAccessButton && !phone && !!onPreview && (
          <td>
            <FolderButton
              loading={loadingUsers}
              disabled={loadingUsers}
              color="secondary"
              outline
              onClick={() =>
                onFolderAccessButton({
                  id: row.original.id,
                  fullName: row.original.fullName,
                  email: row.original.email,
                } as User)
              }
            >
              Folder Access
            </FolderButton>
          </td>
        )}
        {!!onPreview && !phone && (
          <td align="right">
            <Tooltip content="Preview User">
              <UserButton onClick={() => onPreview(row.original.id)}>
                <I className="mdi-set mdi-account-eye" />
              </UserButton>
            </Tooltip>
          </td>
        )}

        {(!!onRemove ||
          (isOwner && showClientUserOptions) ||
          onRemoveInvite ||
          (phone && (!!onFolderAccessButton || !!onPreview))) && (
          <>
            {isClientPortalUser && !phone && !!onPreview && (
              <td align="right">
                <Tooltip content="Alert Client">
                  <AlertButton onClick={handleAlert}>
                    <I className="mdi-set mdi-bell" />
                  </AlertButton>
                </Tooltip>
              </td>
            )}
            <td>
              <Dropdown
                portal
                zIndex={3000}
                offsetY={offsetY}
                offsetX={desktop ? 13 : 62}
                buttonAlign={'right'}
                onClick={calculateOffset}
                trigger={
                  <GearButton
                    disabled={
                      !isOwner && onRemove && row?.original?.secondaryAdmin
                    }
                  >
                    <I className="mdi-set mdi-cog" />
                  </GearButton>
                }
              >
                {({ closeDropdown }) => (
                  <>
                    <DropdownHeader>{row.cells[0].value}</DropdownHeader>
                    {!!onResendInvite && row.original.inviteId && (
                      <DropdownButton
                        onClick={() => onResendInvite(row.original)}
                        disabled={loadingResendInvite}
                      >
                        {loadingResendInvite ? 'Resending...' : 'Resend Invite'}
                      </DropdownButton>
                    )}
                    {isClientPortalUser && (
                      <DropdownButton
                        onClick={() => {
                          navigator.clipboard.writeText(window.location.origin);
                          closeDropdown();
                        }}
                      >
                        Copy Login Link
                      </DropdownButton>
                    )}
                    {(!!row.original.link || !!row.original.inviteLink) && (
                      <DropdownButton
                        onClick={() => {
                          navigator.clipboard.writeText(
                            row.original.link || row.original.inviteLink || ''
                          );
                          closeDropdown();
                        }}
                      >
                        Copy Invite Link
                      </DropdownButton>
                    )}
                    {!!onFolderAccessButton && phone && !!onPreview && (
                      <DropdownButton
                        onClick={() => {
                          closeDropdown();
                          onFolderAccessButton({
                            id: row.original.id,
                            fullName: row.original.fullName,
                            email: row.original.email,
                          } as User);
                        }}
                      >
                        Folder Access
                      </DropdownButton>
                    )}
                    {!!onShowUpdateInviteFolders && row.original.inviteId && (
                      <DropdownButton
                        onClick={() => {
                          closeDropdown();
                          onShowUpdateInviteFolders(
                            row.original?.inviteId || -1
                          );
                        }}
                      >
                        Folder Access
                      </DropdownButton>
                    )}
                    {!!onPreview && phone && !!onPreview && (
                      <DropdownButton
                        onClick={() => {
                          closeDropdown();
                          onPreview(row.original.id);
                        }}
                      >
                        Preview User
                      </DropdownButton>
                    )}
                    {isClientPortalUser && phone && !!onPreview && (
                      <DropdownButton
                        onClick={() => {
                          closeDropdown();
                          handleAlert();
                        }}
                      >
                        Alert Client
                      </DropdownButton>
                    )}
                    {isOwner &&
                      showClientUserOptions &&
                      onWorkspaceOwnerChange && (
                        <ChangeToButton
                          onClick={() => {
                            closeDropdown();
                            onWorkspaceOwnerChange(row.original);
                          }}
                        >
                          Change to Workspace Owner
                        </ChangeToButton>
                      )}
                    {(isOwner || isSecondaryAdmin) &&
                      showClientUserOptions &&
                      onClientUserChange && (
                        <ChangeToButton
                          onClick={() => onClientUserChange(row.original)}
                        >
                          Change to{' '}
                          {row.original.clientPortalOnly
                            ? 'Workspace Member'
                            : 'Client'}
                        </ChangeToButton>
                      )}
                    {isOwner &&
                      !hasSecondaryAdmin &&
                      showClientUserOptions &&
                      onWorkspaceSecondaryAdminChange && (
                        <ChangeToButton
                          onClick={() => {
                            closeDropdown();
                            onWorkspaceSecondaryAdminChange(
                              row.original,
                              false
                            );
                          }}
                        >
                          Make Secondary Admin
                        </ChangeToButton>
                      )}
                    {isOwner &&
                      hasSecondaryAdmin &&
                      row.original.secondaryAdmin &&
                      showClientUserOptions &&
                      onWorkspaceSecondaryAdminChange && (
                        <RemoveSecondaryAdminButton
                          onClick={() => {
                            closeDropdown();
                            onWorkspaceSecondaryAdminChange(row.original, true);
                          }}
                        >
                          Remove Secondary Admin
                        </RemoveSecondaryAdminButton>
                      )}
                    {(onRemove || onRemoveInvite) &&
                      !row?.original?.secondaryAdmin && (
                        <RemoveButton
                          onClick={() => {
                            if (onRemoveInvite && row.original.inviteId) {
                              onRemoveInvite(
                                row.original.inviteId,
                                row.original
                              );
                            } else if (onRemove) {
                              onRemove({
                                id: row.original.id,
                                fullName: row.original.fullName,
                                email: row.original.email,
                              } as User);
                            }
                            closeDropdown();
                          }}
                        >
                          Remove{row.original.inviteId ? ' Invite' : ''}
                        </RemoveButton>
                      )}
                  </>
                )}
              </Dropdown>
            </td>
          </>
        )}
      </tr>
      <ClientAlertModal
        workspaceId={workspaceId}
        companyId={companyId}
        userFullName={row.original.fullName}
        show={showClientAlertModal}
        onClose={() => setShowClientAlertModal(false)}
        userIdsList={[row.original.id]}
      />
    </>
  );
};

export default Row;
