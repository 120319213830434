import darkThemeA from './dark/theme-a';
import darkThemeB from './dark/theme-b';
import darkThemeC from './dark/theme-c';
import darkThemeD from './dark/theme-d';
import darkThemeE from './dark/theme-e';
import darkThemeF from './dark/theme-f';
import darkThemeG from './dark/theme-g';
import darkThemeH from './dark/theme-h';

import lightThemeI from './light/theme-i';
import lightThemeJ from './light/theme-j';
import lightThemeK from './light/theme-k';
import lightThemeL from './light/theme-l';
import lightThemeM from './light/theme-m';
import lightThemeN from './light/theme-n';
import lightThemeO from './light/theme-o';
import lightThemeP from './light/theme-p';

export default {
  dark: [
    darkThemeA,
    darkThemeB,
    darkThemeC,
    darkThemeD,
    darkThemeE,
    darkThemeF,
    darkThemeG,
    darkThemeH,
  ],
  light: [
    lightThemeI,
    lightThemeJ,
    lightThemeK,
    lightThemeL,
    lightThemeM,
    lightThemeN,
    lightThemeO,
    lightThemeP,
  ],
};
