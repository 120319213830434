/**
 *
 * Toggle
 *
 */
import React from 'react';
import styled from 'styled-components';
import ReactToggle from 'react-toggle';

const StyledToggle = styled(ReactToggle)`
  margin-left: 3px;
  &.react-toggle--checked .react-toggle-track {
    background-color: ${(props) => props.theme.colors.brand};
  }
  &.react-toggle--checked .react-toggle-thumb {
    border-color: ${(props) => props.theme.colors.brand};
  }
  &.react-toggle--checked:hover:not(.react-toggle--disabled)
    .react-toggle-track {
    background-color: #55abc7;
  }
`;

interface ToggleProps {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  checked: boolean;
  className?: string;
  icons?: {
    checked?: React.ReactNode;
    unchecked?: React.ReactNode;
  };
}

const Toggle: React.FunctionComponent<ToggleProps> = ({
  onChange,
  checked,
  className,
  icons,
}) => {
  return (
    <StyledToggle
      className={className}
      onChange={onChange}
      checked={checked}
      icons={icons}
    />
  );
};

export default Toggle;
