/**
 *
 * ToggleVisible
 *
 */

import React, { useMemo } from 'react';

function ToggleVisible(props) {
  const { show, children, style: _style, flex } = props;

  const style = useMemo(
    () => ({
      ...(_style || {}),
      display: show ? (flex ? 'flex' : 'block') : 'none',
    }),
    [_style, flex, show]
  );

  return <div style={style}>{children}</div>;
}

export default ToggleVisible;
