import { ApolloCache } from '@apollo/client';
import { gql } from '@apollo/client';
import { LIBRARY_INFO, LIBRARY_TABLE_FIELDS, QUERY_TABLE } from 'fragments';
import useCompanyId from 'hooks/useCompanyId';
import useTheme from 'hooks/useTheme';
import produce from 'immer';
import { Company } from 'interfaces/company';

type Theme = 'LIGHT' | 'DARK';

export const REPORT_LIBRARY_QUERY = gql`
  query ReportLibrary($companyId: Int!) {
    company(id: $companyId) {
      id
      libraryReports {
        id
        name
        thumbnail
        pending
        dataSheetCount
        hasUnlockedStatement
        companyId
        libraryInfo {
          ...LibraryInfoFields
        }
      }
    }
  }

  ${LIBRARY_INFO}
`;

const DASHBOARD_LIBRARY_QUERY = gql`
  query DashboardLibrary($companyId: Int!, $theme: Theme) {
    company(id: $companyId) {
      id
      libraryDashboards {
        id
        name
        thumbnail(theme: $theme)
        libraryInfo {
          ...LibraryInfoFields
        }
        dataSheetCount
        hasUnlockedStatement
        companyId
      }
    }
  }
  ${LIBRARY_INFO}
`;

const TABLE_LIBRARY_QUERY = gql`
  query AddTableKpiPanel($companyId: Int!) {
    company(id: $companyId) {
      id
      libraryTables(excludeStatements: false) {
        ...LibraryTableFields
        libraryInfo {
          ...LibraryInfoFields
        }
      }
      libraryQueryTables {
        ...QueryTableFields
        libraryInfo {
          ...LibraryInfoFields
        }
      }
    }
  }
  ${QUERY_TABLE}
  ${LIBRARY_INFO}
  ${LIBRARY_TABLE_FIELDS}
`;

const libraryQueries = {
  DASHBOARD: DASHBOARD_LIBRARY_QUERY,
  TABLE: TABLE_LIBRARY_QUERY,
  STATEMENT: TABLE_LIBRARY_QUERY,
  REPORT: REPORT_LIBRARY_QUERY,
};

const updateName = (
  cache: ApolloCache<any>,
  itemType: 'REPORT' | 'DASHBOARD' | 'TABLE' | 'STATEMENT',
  id: number,
  name: string,
  companyId: number,
  theme?: Theme
) => {
  const query = libraryQueries[itemType];
  const variables: { companyId: number; theme?: Theme } = {
    companyId,
  };

  if (itemType === 'DASHBOARD') {
    variables.theme = (theme || 'LIGHT') as Theme;
  }

  const cacheData = cache.readQuery<{ company: Company }>({
    query,
    variables,
  });

  if (cacheData?.company) {
    const { company } = cacheData;
    const itemTypeCapitalized =
      itemType.slice(0, 1).toUpperCase() + itemType.slice(1).toLowerCase();

    const itemIndex = company[`library${itemTypeCapitalized}s`].findIndex(
      (i: { id: number }) => i.id === id
    );

    const newData = produce(company, (draft) => {
      draft[`library${itemTypeCapitalized}s`][itemIndex].name = name;
    });

    cache.writeQuery({
      query,
      variables,
      data: {
        company: newData,
      },
    });
  }
};

export default updateName;
