/*
 *
 * TransferCompany
 *
 */

import React from 'react';
import styled from 'styled-components';
import { Query } from '@apollo/client/react/components';
import gql from 'graphql-tag';
import Select from 'react-select';

import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import Button from 'components/Button';
import Mutation from 'components/Mutation';

const Div = styled.div`
  /*height: 300px;*/
`;

const Fields = styled.div`
  display: flex;
`;

const SelectWrapper = styled.div`
  flex-grow: 1;
  margin-right: 10px;
`;

const Label = styled.div`
  font-size: 13px;
`;

const QUERY = gql`
  query TransferCompany($companyId: Int!) {
    company(id: $companyId) {
      id
      name
      firm {
        id
        name
      }
    }
    currentUser {
      ownerFirms {
        id
        name
      }
    }
  }
`;

const selectStyles = {
  control: provided => ({
    ...provided,
    fontSize: '14px',
  }),
  option: provided => ({
    ...provided,
    fontSize: '14px',
  }),
};

const TRANSFER = gql`
  mutation TransferCompany($companyId: Int!, $firmId: Int!) {
    moveCompany(companyId: $companyId, firmId: $firmId) {
      firmId
      companyId
      success
    }
  }
`;

class TransferCompany extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
    };
  }
  handleChange = option => {
    this.setState({
      selected: option.value,
    });
  };
  render() {
    const { show, companyId, onClose } = this.props;
    const { selected } = this.state;

    return (
      <Modal
        title="Move Company"
        show={show}
        onClose={onClose}
        width={400}
        fixed={false}
      >
        <Query
          query={QUERY}
          variables={{ companyId }}
          fetchPolicy="network-only"
        >
          {({ data, loading, error }) => {
            if (loading || error) {
              return <Spinner />;
            }

            const currentGroup = data.company.firm ? data.company.firm.id : -1;

            const options = [
              {
                label: 'Select destination folder',
                value: 0,
              },
              {
                label: 'Personal Folder',
                value: -1,
              },
              ...data.currentUser.ownerFirms.map(firm => ({
                value: firm.id,
                label: firm.name,
              })),
            ].filter(option => option.value !== currentGroup);

            const value = options.find(option => option.value === selected);

            return (
              <Div>
                <p>
                  Move company <strong>{data.company.name}</strong>.
                </p>
                <Label>Destination</Label>
                <Fields>
                  <SelectWrapper>
                    <Select
                      options={options}
                      styles={selectStyles}
                      value={value}
                      onChange={this.handleChange}
                    />
                  </SelectWrapper>
                  <Mutation
                    mutation={TRANSFER}
                    variables={{ companyId, firmId: selected }}
                    refetchQueries={() => ['Companies']}
                    onCompleted={onClose}
                  >
                    {(transfer, { loading }) => (
                      <Button
                        color="success"
                        onClick={transfer}
                        disabled={loading || !selected}
                      >
                        Transfer
                      </Button>
                    )}
                  </Mutation>
                </Fields>
              </Div>
            );
          }}
        </Query>
      </Modal>
    );
  }
}

export default TransferCompany;
