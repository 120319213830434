import * as SentryApi from '@sentry/browser';

import { showError } from './popups';

export function captureException(err, shouldShowError) {
  console.error(err);
  SentryApi.captureException(err);
  if (shouldShowError) {
    showError();
  }
}
