/**
 *
 * Table
 *
 */
import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { useTable, useSortBy } from 'react-table';

import Checkbox from 'components/Checkbox';
import InviteForm from 'components/InviteForm/InviteForm';
import Spinner from 'components/Spinner';
import { Folder } from 'interfaces/folder';

const Scroll = styled.div<{ folderCount: number }>`
  max-height: calc(100vh - 25rem);
  overflow-y: auto;
  min-height: ${(props) => {
    if (props.folderCount === 1) {
      return 33;
    } else if (props.folderCount === 2) {
      return 66;
    } else {
      return 100;
    }
  }}px;
`;

const columns = [
  {
    Header: 'Select Folder(s)',
    accessor: 'name',
  },
];

const Table = styled.table`
min-height: 
  margin-bottom: 0.3rem;
  font-size: 0.75rem;
  & td {
    vertical-align: middle;
  }
  & th {
    padding-bottom: 0em;
  }
  & tr {
    font-size: 0rem;
  }
  & tr > td:nth-child(1) {
    width: 10%;
  }
  & tr > td:nth-child(2) {
    width: 100px;
    label {
      font-size: 0.75rem;
    }
  }
`;

const Header = styled.div`
  font-size: 0.75rem;
`;

interface TableProps {
  folders: Folder[];
  selectedFolderIds?: number[];
  loadingSelectedFolders?: boolean;
  loadingInvite?: boolean;
  loadingUpdateInviteFolders?: boolean;
  error?: string;
  showInvite?: boolean;
  disableChecks?: boolean;
  onSelect?: (id: number, selected: boolean) => void;
  onInvite?: (any) => void;
  scrollStyle?: CSSProperties;
}

const FolderTable: React.FC<TableProps> = ({
  folders,
  selectedFolderIds = [],
  loadingSelectedFolders, 
  loadingInvite,
  loadingUpdateInviteFolders,
  error,
  showInvite = false,
  disableChecks = false,
  onSelect,
  onInvite,
  scrollStyle,
}) => {
  const { getTableProps, getTableBodyProps, rows, prepareRow } = useTable(
    {
      columns,
      data: folders,
    },
    useSortBy
  );

  return (
    <Scroll folderCount={rows.length} style={scrollStyle}>
      {loadingSelectedFolders ? (
        <Spinner />
      ) : (
        <>
          <Header>Select Folder(s):</Header>
          <Table {...getTableProps()}>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr key={i} {...row.getRowProps()}>
                    <td>
                      <Checkbox
                        id={`folder-checkbox-${row.original.id}`}
                        checked={selectedFolderIds.includes(row.original.id)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (onSelect) {
                            onSelect(row.original.id, e.target.checked);
                          }
                        }}
                        disabled={disableChecks || loadingUpdateInviteFolders}
                        noMargin
                      />
                    </td>
                    {row.cells.map((cell, index) => {
                      return (
                        <td key={index} {...cell.getCellProps()}>
                          <label htmlFor={`folder-checkbox-${row.original.id}`}>
                            {cell.render('Cell')}
                          </label>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      )}
      {showInvite && onInvite ? (
        <InviteForm
          onSubmit={onInvite}
          loading={loadingInvite || false}
          error={error}
        />
      ) : (
        <></>
      )}
    </Scroll>
  );
};

export default FolderTable;
