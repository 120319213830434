/* global fetch */
import React, {
  useState,
  useReducer,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import download from 'downloadjs';
import { sizes } from 'media';
import moment from 'moment';
import {
  useQuery,
  useMutation,
  useSubscription,
  useApolloClient,
} from '@apollo/client';

import { Table } from './table';

import Modal from 'components/Modal';
import DataPicker from 'components/DataPicker';
import useMedia from 'hooks/useMedia';
import useAccountingBasis from 'hooks/useAccountingBasis';
import {
  QUERY,
  CREATE,
  BUDGET_JOB_FINISHED,
  DELETE,
  DUPLICATE,
  CREATE_EXCEL,
  UPDATE_EXCEL,
  RENAME,
  CREATE_EXCEL_TEMPLATE,
  DOWNLOAD_EXCEL,
  CREATE_BUDGET_GROUP,
  UPDATE_BUDGET_GROUP,
  DELETE_BUDGET_GROUP,
} from './mutations';
import useCompanyId from 'hooks/useCompanyId';

import {
  Header,
  GroupNameTitle,
  SecondaryButtonStyle,
  ActionButtonsContainer,
  DataPickerContainer,
} from './styledComponents';
import { useHistory, useLocation } from 'react-router-dom';
import {
  showConfirmDelete,
  showError,
  showInfo,
  notificationToast,
} from 'utils/popups';
import produce from 'immer';
import NameModal from 'components/NameModal';
import usePrevious from 'hooks/usePrevious';
import useFilters from 'hooks/useFilters';
import { captureException } from 'utils/sentry';
import Create, { ADVANCED_TAB, EXCEL_TAB } from './Create';
import useSupportedStatements from 'hooks/useSupportedStatements';
import useAsync from 'hooks/useAsync';
import Update from './Update';
import useCompanyName from 'hooks/useCompanyName';
import useDataVariables from 'hooks/useDataVariables';
import HideScrollbar from 'components/HideScrollbar';
import useBookMonth from 'hooks/useBookMonth';
import BetaBadge from 'components/BetaBadge';
import { BUDGET_GROUP_FIELDS, BUDGET_LIST_FIELDS } from 'fragments';
import { DEV, STAGING } from 'environment';
import { getDates } from './utils';
import Group from './Groups';
import Spinner from 'components/Spinner';
import CreateButton from './CreateButton';
import Tooltip from 'components/Tooltip';
import ManageFileLocationModal from 'components/ManageFileLocationModal';
import { FOLDER_ITEM_TYPES } from 'utils/constants';
import { ITEMS, MULTI_YEAR_GROUP_DIVIDER_ID } from './constants';
import StatusHistoryModal from 'components/StatusHistoryModal';
import DOMPurify from 'dompurify';

const reducer = (state, action) => {
  switch (action.type) {
    case 'TABLE':
      return {
        ...state,
        content: 'TABLE',
        group: undefined,
      };
    case 'CREATE':
      return {
        ...state,
        content: 'CREATE',
      };
    case 'UPDATE_EXCEL':
      return {
        ...state,
        content: 'UPDATE_EXCEL',
        excelBudgetId: action.excelBudgetId,
      };
    case 'ADD_EDIT':
      return {
        ...state,
        content: 'ADD_EDIT',
      };
    case 'GROUP':
      return {
        ...state,
        group: action.group,
        content: 'GROUP',
      };
  }
};
const initialState = {
  content: 'TABLE',
  group: undefined,
};

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
    width: 200,
    textAlign: 'left',
    Cell: ({ cell: { value }, row }) => {
      if (row.original.isAdvancedBudget) {
        return (
          <BetaBadge text="pro" fontSize="0.7rem" left="104%">
            {value}
          </BetaBadge>
        );
      }

      return value;
    },
  },
  {
    Header: 'Created in Basis',
    accessor: 'createdBasis',
    Cell: ({ cell: { value } }) => {
      if (!value) {
        return '';
      }
      return value === 'ACCRUAL' ? 'Accrual' : 'Cash';
    },
    width: 60,
    maxWidth: 60,
  },
  {
    Header: 'Last Updated',
    accessor: 'lastUpdated',
    width: 110,
    Cell: ({ row }) => {
      if (
        row.original.needsDriverRefresh &&
        row.original?.datasheet?.needsDatasheetRefresh
      ) {
        return (
          <span>
            {row.original.lastUpdated}
            <Tooltip content="Referenced data for this budget was recently updated. Open the budget and navigate to the Advanced Settings to select the Refresh All Row Drivers option if you would like to update referenced data.">
              <i
                className="mdi-set mdi-alert"
                style={{ color: '#FFC107', marginLeft: '5px' }}
              />
            </Tooltip>
            <Tooltip content="Google/Excel Sheet data for this budget was recently updated. Open and save the budget for the changes to take effect.">
              <i
                className="mdi-set mdi-table-large"
                style={{ color: '#FFC107', marginLeft: '5px' }}
              />
            </Tooltip>
          </span>
        );
      } else if (row.original.needsDriverRefresh) {
        return (
          <span>
            {row.original.lastUpdated}
            <Tooltip content="Referenced data for this budget was recently updated. Open the budget and navigate to the Advanced Settings to select the Refresh All Row Drivers option if you would like to update referenced data.">
              <i
                className="mdi-set mdi-alert"
                style={{ color: '#FFC107', marginLeft: '5px' }}
              />
            </Tooltip>
          </span>
        );
      } else if (row.original?.datasheet?.needsDatasheetRefresh) {
        return (
          <span>
            {row.original.lastUpdated}
            <Tooltip content="Google/Excel Sheet data for this budget was recently updated. Open and save the budget for the changes to take effect.">
              <i
                className="mdi-set mdi-table-large"
                style={{ color: '#FFC107', marginLeft: '5px' }}
              />
            </Tooltip>
          </span>
        );
      }

      return row.original.lastUpdated || '';
    },
  },
  {
    Header: 'Budget Date Range',
    accessor: 'budgetDateRange',
    width: 90,
    Cell: ({ row }) => {
      if (row.original.id === MULTI_YEAR_GROUP_DIVIDER_ID) {
        return null;
      }

      if (row.original.budgetGroupDateRange) {
        return row.original.budgetGroupDateRange;
      }

      if (row.original.isAdvancedBudget && row.original.datasheet) {
        if (row.original.datasheet.startDate === null) {
          return 'Creating...';
        } else {
          const startDate = moment
            .utc(row.original.datasheet.startDate)
            .format('MMM YY');
          const endDate = moment
            .utc(row.original.datasheet.endDate)
            .format('MMM YY');
          return startDate + '-' + endDate;
        }
      }

      return 'N/A';
    },
  },
];

const budgetColumns = [
  ...columns,
  {
    Header: 'Forecast Month',
    accessor: 'budgetBookMonth',
    width: 90,
    Cell: ({ row }) => {
      if (row.original.budgetBookMonth) {
        return moment.utc(row.original.budgetBookMonth).format('MMM YY');
      }
      return '';
    },
  },
];

let tempId = -1;

const BudgetsModal = (props) => {
  const { title, width, show, onClose } = props;
  const createdBasis = useAccountingBasis({ debug: true });
  const companyId = useCompanyId();
  const companyName = useCompanyName();
  const dataVariablesByIntegration = useDataVariables();
  const [renamingId, setRenamingId] = useState(null);
  const [downloadingId, setDownloadingId] = useState(null);
  const [creatingTemplate, setCreatingTemplate] = useState(false);
  const [highlightedGroupBudgetId, setHighlightedGroupBudgetId] =
    useState(null);
  const [selectedBudget, setSelectedBudget] = useState(null);
  const [showManageFileLocationModal, setShowManageFileLocationModal] =
    useState(false);
  const { supportedStatements, loading: supportedStatementsLoading } =
    useSupportedStatements();
  const location = useLocation();
  const history = useHistory();
  const { hasFilter } = useFilters();
  const { data, loading, error } = useQuery(QUERY, {
    variables: {
      companyId,
    },
    skip: !companyId || !show,
  });
  const { fiscalYearMonth } = useBookMonth();

  const { cache: apolloCache } = useApolloClient();

  const downloadExcelFile = useCallback(async (url, name) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return download(blob, name);
  }, []);

  const onHighlightGroupBudget = useCallback((budgetId) => {
    setHighlightedGroupBudgetId(budgetId);
  }, []);

  const onSubscriptionData = useCallback(
    async ({
      client,
      data: {
        data: {
          budgetJobFinished: {
            budget,
            templateUrl,
            existingExcel,
            existingExcelName,
            updated,
            validation,
          },
        },
      },
    }) => {
      if (validation) {
        return;
      }
      if (templateUrl) {
        try {
          return downloadExcelFile(templateUrl, `${companyName} template.xlsx`);
        } catch (err) {
          captureException(err, true);
        } finally {
          setCreatingTemplate(false);
        }
      } else if (existingExcel) {
        await downloadExcelFile(existingExcel, `${existingExcelName}.xlsx`);
        return setDownloadingId(null);
      }

      if (updated) {
        notificationToast({
          title: `Budget is updated`,
          timer: 1500,
          position: 'top-end',
          fixed: true,
        });
      }

      if (updated) return;

      client.cache.updateQuery(
        { query: QUERY, variables: { companyId } },
        produce((draft) => {
          if (draft?.company?.budgets) {
            if (draft.company.budgets.find((b) => b.id === budget.id)) return;

            const pendingIndex = draft.company.budgets.findIndex(
              (item) =>
                item.id < 0 &&
                item.name === budget.name &&
                item.isExcel === budget.isExcel
            );
            if (pendingIndex > -1) {
              draft.company.budgets.splice(pendingIndex, 1, budget);
            } else {
              draft.company.budgets.unshift(budget);
            }
          }
        })
      );
    },
    [companyId, companyName, downloadExcelFile]
  );

  useSubscription(BUDGET_JOB_FINISHED, {
    variables: {
      companyId,
    },
    onData: onSubscriptionData,
  });

  const [create] = useMutation(CREATE);
  const [createBudgetGroup] = useMutation(CREATE_BUDGET_GROUP);
  const [updateBudgetGroup] = useMutation(UPDATE_BUDGET_GROUP);
  const [deleteBudgetGroup] = useMutation(DELETE_BUDGET_GROUP);
  const [createExcelTemplate] = useMutation(CREATE_EXCEL_TEMPLATE);
  const [createExcel] = useMutation(CREATE_EXCEL);
  const [updateExcel, { loading: updateExcelLoading }] =
    useMutation(UPDATE_EXCEL);
  const [deleteBudget] = useMutation(DELETE);
  const [duplicateBudget] = useMutation(DUPLICATE);
  const [downloadExcel] = useMutation(DOWNLOAD_EXCEL);
  const [renameBudget, { loading: renameLoading }] = useMutation(RENAME);

  const [state, dispatch] = useReducer(reducer, initialState);

  const { content, excelBudgetId, group } = state;

  const [additionalAccounts, setAdditionalAccounts] = useState(false);

  const isSummit = data?.company?.accounting?.integration === 'SUMMIT';
  const isMyob = data?.company?.accounting?.integration === 'MYOB';
  const hasSubdividedProBudgets =
    data?.company?.accounting?.hasSubdividedProBudgets;
  const budgetFilterLimit = data?.company?.budgetFilterLimit;

  const initialBudget = useMemo(
    () => ({
      name: '',
      createdBasis: null,
      forecastRangeDates: getDates('CURRENT_FISCAL_YEAR', fiscalYearMonth || 0),
      prefillDates: getDates('LAST_FISCAL_YEAR', fiscalYearMonth || 0),
      prefillBudgetId: null,
      prefillData: false,
      profitAndLoss: true,
      balanceSheet: false,
      cashFlow: false,
      threeWayEnabled: false,
      includeSubAccounts: false,
      additionalAccounts: [],
      classIds: null,
      departmentIds: null,
      growthRate: null,
      hideInactiveAccounts: true,
      threeWaySettings: null,
      subdividedEnabled: null,
      subdivide: null,
    }),
    [fiscalYearMonth]
  );

  const [budget, setBudget] = useState(initialBudget);
  const [showHistory, setShowHistory] = useState(false);
  const [statusHistoryRow, setStatusHistoryRow] = useState([]);

  const [tab, setTab] = useState(0);
  const prevTab = usePrevious(tab);
  const [file, setFile] = useState(null);
  const [creating, setCreating] = useState(false);
  const [validationErrors, setValidationErrors] = useState(null);

  const prevShow = usePrevious(show);
  useEffect(() => {
    if (!prevShow && show) {
      setTab(0);
      setBudget(initialBudget);
      setAdditionalAccounts(false);
      setFile(null);
    }
  }, [show, prevShow, initialBudget]);

  useEffect(() => {
    const switchedFromAdvanced = prevTab === 2 && (tab === 0 || tab === 1);
    if (switchedFromAdvanced) {
      setBudget((prev) => ({
        ...prev,
        forecastRangeDates: initialBudget.forecastRangeDates,
        prefillDates: initialBudget.prefillDates,
      }));
    }
  }, [
    initialBudget.forecastRangeDates,
    initialBudget.prefillDates,
    prevTab,
    tab,
  ]);

  const minimal = useMedia([`(min-width: ${sizes.tablet}px)`], [false], true);

  const handleToggleAdditionalAccounts = useCallback(
    (e) => {
      setAdditionalAccounts(e.target.checked);
      if (!e.target.checked) {
        setBudget({
          ...budget,
          additionalAccounts: [],
        });
      }
    },
    [budget]
  );

  const handleToggleThreeWayEnabled = useCallback(
    (e) => {
      setBudget({
        ...budget,
        threeWayEnabled: e.target.checked,
      });
    },
    [budget]
  );

  const handleToggleSubdivided = useCallback(
    (e) => {
      setBudget({
        ...budget,
        subdividedEnabled: e.target.checked,
      });
    },
    [budget]
  );

  const handleSubdivide = useCallback(
    (subdivide) => {
      setBudget({
        ...budget,
        subdivide,
      });
    },
    [budget]
  );

  const getExcelFile = useCallback(
    async (id) => {
      try {
        setDownloadingId(id);
        await downloadExcel({
          variables: {
            budgetId: id,
          },
        });
      } catch (err) {
        captureException(err, true);
      }
    },
    [downloadExcel]
  );

  const { execute: handleDownloadExcel } = useAsync(getExcelFile, false);

  const updateExcelBudget = useCallback(
    async (newFile) => {
      try {
        await updateExcel({
          variables: {
            tableId: excelBudgetId,
            excelFile: newFile,
          },
        });
        dispatch({ type: 'TABLE' });
      } catch (err) {
        captureException(err);
      }
    },
    [excelBudgetId, updateExcel]
  );

  const createBudget = useCallback(async () => {
    setCreating(true);
    try {
      if (tab === EXCEL_TAB) {
        apolloCache.updateQuery(
          { query: QUERY, variables: { companyId } },
          produce((draft) => {
            draft.company.budgets.unshift({
              id: tempId,
              name: DOMPurify.sanitize(budget.name),
              createdBasis: createdBasis || 'CASH',
              lastUpdated: moment.utc().format(),
              isExcel: true,
              pending: true,
              isAdvancedBudget: false,
              datasheet: null,
              budgetBookMonth: null,
              needsDriverRefresh: false,
              status: null,
              inClientPortal: false,
              locked: false,
              viewOnly: true,
              statusHistory: [],
              subdivideEnabled: false,
              budgetFilterType: null,
              __typename: 'Table',
            });

            tempId -= 1;
          })
        );

        await createExcel({
          variables: {
            companyId,
            name: DOMPurify.sanitize(budget.name),
            excelFile: file,
          },
        });

        dispatch({ type: 'TABLE' });
      } else {
        const {
          profitAndLoss,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          balanceSheet,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          cashFlow,
          additionalAccounts: budgetAdditionalAccounts,
          ...restBudget
        } = budget;

        const isAdvancedBudget = tab === ADVANCED_TAB;

        apolloCache.updateQuery(
          { query: QUERY, variables: { companyId } },
          produce((draft) => {
            draft.company.budgets.unshift({
              id: tempId,
              name: DOMPurify.sanitize(restBudget.name),
              createdBasis: createdBasis || 'CASH',
              lastUpdated: moment.utc().format(),
              isExcel: false,
              pending: true,
              isAdvancedBudget,
              budgetBookMonth: null,
              needsDriverRefresh: false,
              status: null,
              datasheet: {
                id: tempId,
                startDate: null,
                endDate: null,
                needsDatasheetRefresh: false,
              },
              inClientPortal: false,
              locked: false,
              viewOnly: true,
              statusHistory: [],
              subdivideEnabled: restBudget.subdividedEnabled,
              budgetFilterType: null,
              __typename: 'Table',
            });

            tempId -= 1;
          })
        );

        await create({
          variables: {
            companyId,
            budgetSettings: {
              ...restBudget,
              createdBasis: createdBasis || 'CASH',
              isAdvancedBudget,
              profitAndLoss: isAdvancedBudget ? true : profitAndLoss,
              additionalAccounts: isAdvancedBudget
                ? null
                : budgetAdditionalAccounts,
            },
          },
        });
      }
      dispatch({ type: 'TABLE' });
    } catch (err) {
      captureException(err, true);
      setCreating(false);
    }
  }, [
    tab,
    apolloCache,
    companyId,
    createExcel,
    budget,
    file,
    createdBasis,
    create,
  ]);

  const createBudgetGroupFn = useCallback(
    async (name, budgetIds) => {
      setCreating(true);
      try {
        await createBudgetGroup({
          variables: {
            companyId,
            name,
            budgetIds,
          },
          update: (
            cache,
            { data: { createBudgetGroup: createBudgetGroupResult } }
          ) => {
            cache.modify({
              id: `Company:${companyId}`,
              fields: {
                budgetGroups(existingBudgetGroups = []) {
                  const newBudgetGroupRef = cache.writeFragment({
                    data: createBudgetGroupResult,
                    fragment: BUDGET_GROUP_FIELDS,
                  });

                  return [...existingBudgetGroups, newBudgetGroupRef];
                },
              },
            });
          },
        });
        dispatch({ type: 'TABLE' });
        setCreating(false);
      } catch (err) {
        showError();
        setCreating(false);
        captureException(err, true);
      }
    },
    [companyId, createBudgetGroup]
  );

  const updateBudgetGroupFn = useCallback(
    async (budgetGroupId, name, budgetIds) => {
      setCreating(true);
      try {
        await updateBudgetGroup({
          variables: {
            budgetGroupId,
            name,
            budgetIds,
          },
        });
        dispatch({ type: 'TABLE' });
        setCreating(false);
      } catch (err) {
        showError();
        setCreating(false);
        captureException(err, true);
      }
    },
    [updateBudgetGroup]
  );

  const handleExcelTemplate = useCallback(async () => {
    try {
      setCreatingTemplate(true);

      await createExcelTemplate({
        variables: {
          companyId,
          budgetSettings: {
            ...budget,
            createdBasis: createdBasis || 'CASH',
          },
        },
      });
    } catch (err) {
      setCreatingTemplate(false);
      captureException(err, true);
    }
  }, [budget, companyId, createExcelTemplate, createdBasis]);

  const handleSaveAddEdit = useCallback(
    (result) => {
      setBudget({
        ...budget,
        additionalAccounts: result.items.map((item) => {
          const [itemType, identifier, id] = item.value.split('_');
          return {
            itemType,
            identifier,
            companyId: id,
            integration: item.integration,
            name: item.name,
          };
        }),
      });
      dispatch({ type: 'CREATE' });
    },
    [budget]
  );

  const onClickUpdateExcel = useCallback((updateExcelBudgetId) => {
    dispatch({ type: 'UPDATE_EXCEL', excelBudgetId: updateExcelBudgetId });
  }, []);

  const onRename = useCallback((budgetId) => {
    setRenamingId(budgetId);
  }, []);

  const handleRename = useCallback(
    async ({ name }) => {
      try {
        await renameBudget({
          variables: { budgetId: renamingId, name: name.trim() },
        });
        setRenamingId(null);
      } catch (err) {
        captureException(err, true);
      }
    },
    [renameBudget, renamingId]
  );

  const handleCloseRename = useCallback(() => setRenamingId(null), []);

  const onDuplicate = useCallback(
    async (budgetId) => {
      const duplicateToast = notificationToast({
        title: 'Duplicating budget...',
        icon: 'info',
        position: 'top-end',
        fixed: true,
      });

      try {
        await duplicateBudget({
          variables: {
            budgetId,
          },
          update: (cache, { data: { duplicateTable } }) => {
            cache.modify({
              id: `Company:${companyId}`,
              fields: {
                budgets(existingBudgets = []) {
                  const newBudgetRef = cache.writeFragment({
                    data: duplicateTable,
                    fragment: BUDGET_LIST_FIELDS,
                  });

                  return [...existingBudgets, newBudgetRef];
                },
              },
            });
          },
        });
      } catch (err) {
        captureException(err, true);
      }

      duplicateToast.close();
    },
    [companyId, duplicateBudget]
  );

  const onDelete = useCallback(
    async (budgetId) => {
      if (location.pathname === `/table/${budgetId}`) {
        return showInfo({
          title: 'Budget is Open',
          text: `You can't delete this budget while editing it`,
        });
      }

      if (await showConfirmDelete()) {
        await deleteBudget({
          variables: {
            budgetId,
          },
          optimisticResponse: {
            __typename: 'Mutation',
            deleteBudget: true,
          },
          update: (cache) => {
            const cacheData = cache.readQuery({
              query: QUERY,
              variables: {
                companyId,
              },
            });

            const resultData = produce(cacheData, (draft) => {
              draft.company.budgets = draft.company.budgets.filter(
                (b) => b.id !== budgetId
              );
            });

            cache.writeQuery({
              query: QUERY,
              variables: {
                companyId,
              },
              data: resultData,
            });

            if (location.pathname === `/table/${budgetId}`) {
              history.push('/');
            }
          },
        });
      }
    },
    [companyId, deleteBudget, history, location.pathname]
  );

  const onDeleteBudgetGroup = useCallback(
    async (budgetGroup) => {
      try {
        if (
          await showConfirmDelete({
            text: 'This will delete the budget group. Budgets contained in the group will not be deleted.',
          })
        ) {
          await deleteBudgetGroup({
            variables: {
              budgetGroupId: budgetGroup.id,
            },
            optimisticResponse: {
              __typename: 'Mutation',
              deleteBudgetGroup: {
                success: true,
                error: null,
                __typename: 'DeleteBudgetGroupResult',
              },
            },
            update: (cache) => {
              const cacheData = cache.readQuery({
                query: QUERY,
                variables: {
                  companyId,
                },
              });

              const resultData = produce(cacheData, (draft) => {
                draft.company.budgetGroups = draft.company.budgetGroups.filter(
                  (b) => b.id !== budgetGroup.id
                );
              });

              cache.writeQuery({
                query: QUERY,
                variables: {
                  companyId,
                },
                data: resultData,
              });
            },
          });
        }
      } catch (err) {
        captureException(err, true);
      }
    },
    [companyId, deleteBudgetGroup]
  );

  const handleChangeGrowthRate = useCallback(
    (value) => {
      setBudget({
        ...budget,
        growthRate: value,
      });
    },
    [budget]
  );

  const handleToggleManageFileLocation = useCallback(
    (open) => {
      if (open === true || open === false) {
        if (!open) {
          setSelectedBudget(null);
        }
        setShowManageFileLocationModal(open);
      } else {
        setShowManageFileLocationModal(!showManageFileLocationModal);
      }
    },
    [showManageFileLocationModal]
  );

  const handleBeginClientPortalFlow = (_budget) => {
    setSelectedBudget(_budget);
    setShowManageFileLocationModal(true);
  };

  const dataPickerOptions = useMemo(() => {
    if (!dataVariablesByIntegration) return [];
    const valid = ['balanceSheet', 'cashFlow'];
    const byIntegration = dataVariablesByIntegration.map(
      (variablesForIntegration) => {
        return {
          ...variablesForIntegration,
          data: variablesForIntegration.data.filter((item) => {
            return valid.includes(item.value);
          }),
        };
      }
    );

    return byIntegration.filter(
      (variablesForIntegration) => variablesForIntegration.data.length
    );
  }, [dataVariablesByIntegration]);

  const selectedAdditionalAccounts = useMemo(() => {
    return budget.additionalAccounts.map(
      ({ itemType, identifier, companyId: accountCompanyId }) =>
        `${itemType}_${identifier}_${accountCompanyId}`
    );
  }, [budget.additionalAccounts]);

  const [rows, rowsNoGroups] = useMemo(() => {
    if (
      loading ||
      error ||
      supportedStatementsLoading ||
      !data ||
      !data.company
    ) {
      return [null, null, null];
    }

    const {
      company: { budgets, budgetGroups },
    } = data;

    const budgetIdsInGroup = new Set();

    const allBudgetRows = [];

    const groups = budgetGroups.map((g) => {
      let groupStartDate;
      let groupEndDate;

      const row = {
        ...g,
        budgetsInGroup: budgets
          .filter((b) => {
            const inGroup = g.budgetIds.includes(b.id);

            if (inGroup) {
              budgetIdsInGroup.add(b.id);

              if (groupStartDate) {
                groupStartDate = moment.min(
                  moment.utc(groupStartDate),
                  moment.utc(b.datasheet.startDate)
                );
                groupEndDate = moment.max(
                  moment.utc(groupEndDate),
                  moment.utc(b.datasheet.endDate)
                );
              } else {
                groupStartDate = moment.utc(b.datasheet.startDate);
                groupEndDate = moment.utc(b.datasheet.endDate);
              }
            }

            return inGroup;
          })
          .map((b) => {
            const r = {
              ...b,
              isGroupSubRow: true,
              lastUpdated: b.lastUpdated
                ? moment(b.lastUpdated).format('MM/DD/YYYY h:mm a')
                : '',
              createdBasis:
                data.company.accounting?.integration === 'SUMMIT'
                  ? ''
                  : b.createdBasis,
            };

            return r;
          }),
      };

      if (groupStartDate) {
        row.budgetGroupDateRange = `${moment
          .utc(groupStartDate)
          .format('MMM YY')}-${moment.utc(groupEndDate).format('MMM YY')}`;
      }

      return row;
    });

    if (groups.length) {
      groups.unshift({
        id: MULTI_YEAR_GROUP_DIVIDER_ID,
        name: 'Multi-Year Groups',
      });
    }

    const rowList = [...budgets, ...groups].map((item) => {
      if (item.id === MULTI_YEAR_GROUP_DIVIDER_ID) {
        return item;
      }

      const row = {
        ...item,
        lastUpdated: item.lastUpdated
          ? moment(item.lastUpdated).format('MM/DD/YYYY h:mm a')
          : '',
        handleDownloadExcel,
        createdBasis:
          data.company.accounting?.integration === 'SUMMIT'
            ? ''
            : item.createdBasis,
      };

      if (!row.budgetsInGroup && budgetIdsInGroup.has(row.id)) {
        row.isInGroup = true;
      }

      if (row.budgetsInGroup && row.budgetsInGroup.length) {
        row.canExpand = true;
      }

      if (!row.budgetsInGroup) {
        allBudgetRows.push(row);
      }

      return row;
    });

    return [rowList, allBudgetRows];
  }, [data, error, loading, supportedStatementsLoading, handleDownloadExcel]);

  const handleShowStatusHistoryModal = useCallback(
    (budgetId) => {
      setStatusHistoryRow(rows.find((row) => row.id === budgetId));
      setShowHistory(true);
    },
    [rows]
  );

  const hideLegacyBudgets = useMemo(() => {
    if (DEV || STAGING) {
      return false;
    }

    if (!data || !data.company || !data.company.budgets) {
      return true;
    }

    if (!data.company.budgets.length) {
      return true;
    }

    return data.company.budgets.every((b) => b.isAdvancedBudget || b.isExcel);
  }, [data]);

  const renamingBudget = useMemo(() => {
    if (
      !renamingId ||
      loading ||
      error ||
      supportedStatementsLoading ||
      !data ||
      !data.company
    ) {
      return null;
    }

    const {
      company: { budgets },
    } = data;

    return budgets.find((b) => b.id === renamingId);
  }, [data, error, loading, renamingId, supportedStatementsLoading]);

  const renamingBudgetName = renamingBudget && renamingBudget.name;

  if (
    loading ||
    error ||
    supportedStatementsLoading ||
    companyName === null ||
    !dataVariablesByIntegration ||
    !data ||
    !data.company
  ) {
    return (
      <Modal
        show={show}
        title={title}
        width={width}
        fullscreen
        onClose={onClose}
        onExited={() => setCreating(false)}
        hideHeader
        marginTop={50}
      >
        <Spinner />
      </Modal>
    );
  }

  return (
    <Modal
      show={show}
      title={title}
      width={width}
      fullscreen
      onClose={onClose}
      onExited={() => setCreating(false)}
      hideHeader
      marginTop={50}
    >
      <Header>
        <GroupNameTitle>{title}</GroupNameTitle>
        {!minimal && (
          <ActionButtonsContainer>
            <SecondaryButtonStyle
              color="default"
              onClick={() => {
                switch (content) {
                  case 'ADD_EDIT':
                    dispatch({ type: 'CREATE' });
                    break;
                  case 'CREATE':
                  case 'UPDATE_EXCEL':
                  case 'GROUP':
                    dispatch({ type: 'TABLE', group: undefined });
                    break;
                  default:
                    onClose();
                    break;
                }
              }}
              outline
            >
              {content === 'CREATE' || content === 'GROUP' ? 'Back' : 'Cancel'}
            </SecondaryButtonStyle>

            {content === 'TABLE' && (
              <CreateButton dispatch={dispatch} setCreating={setCreating}>
                Create New
              </CreateButton>
            )}
          </ActionButtonsContainer>
        )}
      </Header>
      {content === 'TABLE' && (
        <Table
          data={rows}
          dataColumns={budgetColumns}
          items={ITEMS}
          minimal={minimal}
          onClose={onClose}
          onDelete={onDelete}
          onDeleteBudgetGroup={onDeleteBudgetGroup}
          onDuplicate={onDuplicate}
          onRename={onRename}
          onClickUpdateExcel={onClickUpdateExcel}
          downloadingId={downloadingId}
          dispatch={dispatch}
          onHighlightGroupBudget={onHighlightGroupBudget}
          highlightedGroupBudgetId={highlightedGroupBudgetId}
          integration={data.company.accounting?.integration}
          onStartClientPortalFlow={handleBeginClientPortalFlow}
          defaults={{
            defaultBudgetId: data.company.defaultBudgetId,
            defaultBudgetType: data.company.defaultBudgetType,
            defaultForecastId: data.company.defaultForecastId,
            defaultForecastType: data.company.defaultForecastType,
          }}
          currentUser={data.currentUser}
          handleShowStatusHistoryModal={handleShowStatusHistoryModal}
        />
      )}
      {content === 'UPDATE_EXCEL' && (
        <Update
          updateExcelBudget={updateExcelBudget}
          companyId={companyId}
          loading={updateExcelLoading}
        />
      )}
      {content === 'CREATE' && (
        <Create
          budget={budget}
          budgets={data?.company?.budgets}
          setBudget={setBudget}
          additionalAccounts={additionalAccounts}
          handleToggleAdditionalAccounts={handleToggleAdditionalAccounts}
          handleToggleThreeWayEnabled={handleToggleThreeWayEnabled}
          dispatch={dispatch}
          hasFilter={hasFilter}
          hasSubdividedProBudgets={hasSubdividedProBudgets}
          handleChangeGrowthRate={handleChangeGrowthRate}
          createBudget={createBudget}
          createExcelTemplate={handleExcelTemplate}
          creating={creating}
          companyId={companyId}
          tab={tab}
          prevTab={prevTab}
          setTab={setTab}
          file={file}
          setFile={setFile}
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
          supportedStatements={supportedStatements}
          companyName={companyName}
          creatingTemplate={creatingTemplate}
          fiscalYearMonth={fiscalYearMonth}
          hideLegacyBudgets={hideLegacyBudgets}
          isSummit={isSummit}
          isMyob={isMyob}
          handleToggleSubdivided={handleToggleSubdivided}
          handleSubdivide={handleSubdivide}
          budgetFilterLimit={budgetFilterLimit}
        />
      )}
      {content === 'ADD_EDIT' && (
        <DataPickerContainer>
          <DataPicker
            company={data.company}
            dataVariablesByIntegration={dataPickerOptions}
            onSave={handleSaveAddEdit}
            value={selectedAdditionalAccounts}
          />
        </DataPickerContainer>
      )}
      {content === 'GROUP' && (
        <Group
          rows={rowsNoGroups.filter(
            (row) => row.isAdvancedBudget && row.datasheet
          )}
          columns={columns}
          group={group}
          onCreateGroup={createBudgetGroupFn}
          onUpdateGroup={updateBudgetGroupFn}
          loading={creating}
          defaults={{
            defaultBudgetId: data.company.defaultBudgetId,
            defaultBudgetType: data.company.defaultBudgetType,
            defaultForecastId: data.company.defaultForecastId,
            defaultForecastType: data.company.defaultForecastType,
          }}
        />
      )}

      <NameModal
        show={!!renamingId}
        title="Rename Budget"
        defaultName={renamingBudgetName}
        onSubmit={handleRename}
        onClose={handleCloseRename}
        loading={renameLoading}
      />

      {selectedBudget && (
        <ManageFileLocationModal
          show={showManageFileLocationModal}
          title={`Add ${selectedBudget.name} to Client Portal`}
          viewOnly={selectedBudget.viewOnly}
          locked={selectedBudget.locked}
          companyId={companyId}
          itemId={selectedBudget.id}
          itemType={FOLDER_ITEM_TYPES.TABLE}
          onClose={() => handleToggleManageFileLocation(false)}
        />
      )}

      <StatusHistoryModal
        showHistory={showHistory}
        setShowHistory={setShowHistory}
        row={statusHistoryRow || []}
      />

      {show && <HideScrollbar />}
    </Modal>
  );
};

export default BudgetsModal;
