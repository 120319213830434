import styled, { css } from 'styled-components';
import Button from 'components/Button';

const StyledButton = styled(Button).attrs({
  color: 'default',
  outline: true,
})`
  width: 207px;
  border-width: 2px;
  border-color: #617181;
  color: #617181;
  font-weight: bold;
  &:hover {
    color: white;
  }
  &:disabled {
    color: #617181;
  }
  margin: 0 6px;
  margin-bottom: 15px;
  font-size: 14px;
  padding: 8px 10px;
  ${props =>
    props.selected &&
    css`
      background: ${props => props.theme.button[props.color].hover};
      color: white;
    `}
`;

export default StyledButton;
