import gql from 'graphql-tag';

export const GET_TWO_FACTOR = gql`
  query TwoFactorAuth {
    currentUser {
      hasTwoFactorAuth
      phoneLast4
      verifiedEmail
      verifiedTotp
      verifiedTwoFactorPhone
      downloadedBackupCodes
    }
  }
`;

export const SETUP_PHONE = gql`
  mutation SetupTwoFactorPhoneAuth($phone: String!) {
    setupTwoFactorPhoneAuth(phone: $phone) {
      success
      error
    }
  }
`;

export const VERIFY = gql`
  mutation VerifyTwoFactorAuth(
    $code: String!
    $method: TwoFactorAuthenticationMethod!
    $phone: String
  ) {
    verifyTwoFactorAuth(code: $code, method: $method, phone: $phone) {
      success
      error
    }
  }
`;

export const CHECK = gql`
  mutation CheckTwoFactorAuth($method: TwoFactorAuthenticationMethod) {
    checkTwoFactorAuth(method: $method) {
      success
      error
      phoneLast4
    }
  }
`;

export const DISABLE = gql`
  mutation DisableTwoFactorAuth(
    $method: TwoFactorAuthenticationMethod!
    $code: String!
  ) {
    disableTwoFactorAuth(method: $method, code: $code) {
      success
      error
    }
  }
`;

export const RESEND_VERIFICATION_EMAIL = gql`
  mutation ResendVerificationEmail {
    resendVerificationEmail
  }
`;

export const SETUP_TOTP = gql`
  mutation SetupTotp {
    setupTwoFactorTotpAuth {
      success
      qrCode
      manualKey
      otpauthUrl
      error
    }
  }
`;

export const GET_BACKUP_CODES = gql`
  query GetBackupCodes {
    currentUser {
      backupCodes
    }
  }
`;
