interface Params {
  type: 'company' | 'workspace';
  name?: string;
  contact?: string;
}

const getTwoFactorAuthPreface = ({ type, name, contact }: Params): string => {
  const entity =
    name || (type === 'company' ? 'the company' : 'the workspace owner');
  const contactSentence = contact
    ? ` For any questions, please contact ${entity} at ${contact}. `
    : ``;

  return `To ensure a secure environment, ${entity} requires the use of Two-Factor Authentication (2FA) for all participants.${contactSentence}`;
};

export default getTwoFactorAuthPreface;
