import React from 'react';
import { Instruction as Container } from 'components/DashboardLiveTextModal/styledComponents';
import DownArrowIcon from 'components/Icons/DownArrowicon';

interface Props {
  instructions: string;
}

const Instructions = ({ instructions }: Props): React.ReactElement => {
  return (
    <Container>
      <span>{instructions}</span>
      <DownArrowIcon width={15} />
    </Container>
  );
};

export default Instructions;
