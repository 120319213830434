/**
 *
 * AddCompanyPanel
 *
 */

import React from 'react';
import styled from 'styled-components';
import theme from 'styled-theming';
import { Redirect, withRouter } from 'react-router-dom';
import moment from 'moment';

import Panel from 'components/Panel';
import Animation from 'components/Animation';
import Link from 'components/Link';
import MoneyValue from 'components/MoneyValue';
import Button from 'components/Button';
import CreateCsvCompany from 'components/CreateCsvCompany';

import withGroupId from 'hoc/withGroupId';

import {
  connectQbo,
  connectXero,
  connectGusto,
  connectAcs,
  connectMyob,
} from 'utils/connections';

import csv from './csv.svg';
import online from './qbo-connect.png?size=350';
import onlineHover from './qbo-connect-hover.png?size=350';
import myob from './myob.svg';

import { WorkspacesLocation } from 'locations';
import { API_URL } from 'environment';

import XeroButton from './XeroButton';
import GustoButton from './GustoButton';
import SvgButton from './SvgButton';
import BetaBadge from 'components/BetaBadge';

const Div = styled.div`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
`;

const StyledPanel = styled(Panel)`
  padding: 20px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-bottom: 40px;
`;

const Heading = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

const GustoDemoButton = styled.button`
  color: ${(props) => props.theme.colors.link}!important;
  padding-left: 0;
  padding-right: 0;
`;

const StyledLink = styled(Link)``;

const TopTitle = styled.h2`
  margin-bottom: 0;
`;

const Options = styled.div`
  display: flex;
  /* justify-content: center; */
  margin-bottom: 1.5rem;
  align-items: flex-start;
  text-align: center;
`;

const OptionWrapper = styled.div`
  width: 350px;
  margin: 16px;
  margin-bottom: 0.5rem;
  cursor: pointer;
  transition: box-shadow 0.25s;
  position: relative;
  text-align: center;
`;

export const QbLogo = styled.button`
  width: 100%;
  height: ${(props) => props.height || 60}px;
  background: url(${(props) => props.image || online.src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: left;
  &:hover {
    background: url(${(props) => props.hoverImage || onlineHover.src});
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: left;
  }
  &:after {
    content: '';
    background: url(${(props) => props.hoverImage || onlineHover.src});
  }
`;

const Beta = styled.div`
  position: absolute;
  bottom: calc(100%);
  left: 0;
  bottom: -19px;
  color: ${(props) => props.theme.colors.warning};
  font-size: 0.875rem;
`;

const A = styled.a`
  color: ${(props) => props.theme.colors.link};
`;

const BillingInfoP = styled.p`
  max-width: 450px;
  margin: 0 auto;
  margin-bottom: 20px;
`;

const backgroundColor = theme('mode', {
  light: '#EAEBEF',
  dark: '#494D5B',
});

export const TextButton = styled(Button).attrs({
  color: 'default',
  outline: false,
  type: 'button',
})`
  background-color: ${backgroundColor};
  display: block;
  width: 100%;
  height: 60px;
  font-size: 1.2rem;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.25rem;
`;

const billingPeriodUnitToText = {
  MONTH: 'monthly',
  YEAR: 'annual',
};

function BillingInfo({ newCompanyBillingEstimate }) {
  const {
    proratedAmount,
    nextBill,
    nextBillDate,
    immediateProrate,
    billingPeriodUnit,
    increase,
  } = newCompanyBillingEstimate || {};

  const billDate = moment(nextBillDate).format('MM/DD/YYYY');

  if (increase !== null) {
    if (immediateProrate) {
      return (
        <BillingInfoP>
          The next billing period you will be billed an additional{' '}
          {billingPeriodUnitToText[billingPeriodUnit]} amount of{' '}
          <MoneyValue value={increase} precision={2} />.
          {proratedAmount !== null && (
            <>
              {' '}
              You will be billed immediately a one time prorated amount of{' '}
              <MoneyValue value={proratedAmount} precision={2} /> for the
              remainder of the current billing period.
            </>
          )}
        </BillingInfoP>
      );
    } else {
      return (
        <BillingInfoP>
          The next billing period you will be billed an additional{' '}
          {billingPeriodUnitToText[billingPeriodUnit]} amount of{' '}
          <MoneyValue value={increase} precision={2} />
          {proratedAmount !== null && (
            <>
              {' '}
              plus a one time prorated amount of{' '}
              <MoneyValue value={proratedAmount} precision={2} /> for the
              remainder of the current billing period.
            </>
          )}
        </BillingInfoP>
      );
    }
  }

  if (immediateProrate) {
    return (
      <BillingInfoP>
        {proratedAmount !== null && (
          <>
            You will be billed a prorated amount of{' '}
            <MoneyValue value={proratedAmount} precision={2} />.
          </>
        )}{' '}
        Your next {billingPeriodUnitToText[billingPeriodUnit]} bill on{' '}
        {billDate} will be <MoneyValue value={nextBill} precision={2} />
      </BillingInfoP>
    );
  }

  if (proratedAmount !== null) {
    return (
      <BillingInfoP>
        Your next {billingPeriodUnitToText[billingPeriodUnit]} bill will include
        an additional <MoneyValue value={proratedAmount} precision={2} /> for
        this company, which is the prorated amount for this month.
      </BillingInfoP>
    );
  }

  return (
    <BillingInfoP>
      Your next {billingPeriodUnitToText[billingPeriodUnit]} bill on {billDate}{' '}
      will be <MoneyValue value={nextBill} precision={2} />, whicn includes the
      prorated amount for this month.
    </BillingInfoP>
  );
}

const Option = ({ onClick, helpLink, image, hoverImage, height, beta, id }) => (
  <div>
    <OptionWrapper onClick={onClick}>
      {/* <Title color={headerColor}>{title}</Title> */}
      <QbLogo
        image={image.src}
        hoverImage={hoverImage.src}
        height={height}
        className="connection-button"
        id={id}
      />
      {!!beta && <Beta>beta</Beta>}
    </OptionWrapper>
    {!!helpLink && (
      <A href={helpLink} target="_blank">
        View Help Article
      </A>
    )}
  </div>
);

@withRouter
@withGroupId
class AddCompanyPanel extends React.PureComponent {
  constructor(props) {
    super(props);
    window.addEventListener('message', this.handleClose, false);
    this.state = {
      companyId: null,
      showCsv: false,
    };
  }
  componentWillUnmount() {
    window.removeEventListener('message', this.handleClose, false);
  }
  handleClose = (event) => {
    if (event.origin === API_URL) {
      this.setState({
        companyId: event.data.companyId,
      });
    }
  };
  handleClickOnline = () => {
    connectQbo(this.props.groupId, this.props.workspaceId);
  };
  handleClickGusto = () => {
    connectGusto(this.props.groupId, this.props.workspaceId);
  };
  handleClickGustoDemo = () => {
    connectGusto(this.props.groupId, this.props.workspaceId, null, true);
  };
  handleClickXero = () => {
    connectXero(this.props.groupId, this.props.workspaceId);
  };
  handleClickAcs = () => {
    connectAcs(this.props.groupId, this.props.workspaceId);
  };
  handleClickMyob = () => {
    connectMyob(this.props.groupId, this.props.workspaceId);
  };
  handleClickCsv = () => {
    this.setState({
      showCsv: true,
    });
  };
  handleCloseCsv = () => {
    this.setState({
      showCsv: false,
    });
  };
  render() {
    if (this.state.companyId) {
      return <Redirect to={`/connecting/${this.state.companyId}`} />;
    }

    const { newCompanyBillingEstimate, workspaceId, administrator } =
      this.props;
    const { showCsv } = this.state;

    return (
      <Animation type="fadeInUp">
        <Div>
          <Heading>
            <TopTitle>Connect your company</TopTitle>
            <StyledLink to={WorkspacesLocation.toUrl({ workspaceId })}>
              Back
            </StyledLink>
          </Heading>
          <StyledPanel className="add-company-panel">
            {!!newCompanyBillingEstimate && (
              <BillingInfo
                newCompanyBillingEstimate={newCompanyBillingEstimate}
              />
            )}
            <Options>
              <Option
                title="Online"
                headerColor="info"
                image={online}
                hoverImage={onlineHover}
                onClick={this.handleClickOnline}
                helpLink="https://knowledge.reachreporting.com/connect-to-quickbooks-online-to-reach-reporting"
                id="qbo-connection-button"
              />
              <OptionWrapper>
                <TextButton
                  onClick={() =>
                    this.props.history.push('/companies/add/desktop')
                  }
                  className="connection-button"
                  id="qbd-connection-button"
                >
                  QuickBooks Desktop
                </TextButton>
                <a
                  href="https://knowledge.reachreporting.com/quickbooks-desktop-integration"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Help Article
                </a>
              </OptionWrapper>
            </Options>

            <Options>
              <div>
                <OptionWrapper>
                  <XeroButton
                    onClick={this.handleClickXero}
                    id="xero-connection-button"
                  />
                  <a
                    href="https://knowledge.reachreporting.com/getting-started-with-xero"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Help Article
                  </a>
                </OptionWrapper>
              </div>
              <BetaBadge top="5" right="0" style={{ zIndex: 1 }}>
                <SvgButton
                  src={myob}
                  helpLink="https://knowledge.reachreporting.com/connect-to-myob-to-reach-reporting"
                  helpText="View Help Article"
                  onClick={this.handleClickMyob}
                  id="myob-connection-button"
                />
              </BetaBadge>
            </Options>

            <Options>
              <GustoButton
                helpLink="https://knowledge.reachreporting.com/connect-to-gusto-to-reach-reporting"
                helpText="View Help Article"
                onClick={this.handleClickGusto}
                id="gusto-connection-button"
              />
              <SvgButton
                src={csv}
                helpLink="https://knowledge.reachreporting.com/import-accounting-data-via-csv-trial-balance"
                helpText="View Help Article"
                onClick={this.handleClickCsv}
                id="csv-connection-button"
              />
            </Options>

            {administrator && (
              <GustoDemoButton
                type="button"
                onClick={this.handleClickGustoDemo}
              >
                Connect Gusto Demo
              </GustoDemoButton>
            )}
          </StyledPanel>
          <CreateCsvCompany show={showCsv} onClose={this.handleCloseCsv} />
        </Div>
      </Animation>
    );
  }
}

export default AddCompanyPanel;
