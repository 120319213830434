import React from 'react';
import styled, { keyframes } from 'styled-components';

const progressAnimation = keyframes`
  from {
    background-position:40px 0;
  }
  to {
    background-position:0 0;
  }
`;

const Div = styled.div`
  height: 20px;
  overflow: hidden;
  background-color: ${props => props.theme.progress.background};
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: ${props => (props.round ? '30px' : '2px')};
`;

const Bar = styled.div`
  float: left;
  width: ${props => props.progress * 100}%;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: ${props => props.theme.progress.bar.color};
  text-align: center;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  transition: width 0.6s ease;
  animation: ${progressAnimation} 2s linear infinite;
  background-color: ${props => props.theme.progress.bar.background};
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
`;

class ProgressBar extends React.PureComponent {
  render() {
    const { progress, ...rest } = this.props;

    return (
      <Div {...rest}>
        <Bar progress={progress} />
      </Div>
    );
  }
}

export default ProgressBar;
