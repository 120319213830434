/**
 *
 * MaintenanceBanner
 *
 */
import React, { useMemo, useEffect, useState } from 'react';
import styled from 'styled-components';
import TimeAgo from 'react-timeago';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import useMaintenanceMode from 'hooks/useMaintenanceMode';

const DEFAULT_MESSAGE =
  'We are currently experiencing technical difficulties. Our team is working diligently to resolve these issues. We apologize for any inconvenience and appreciate your patience.';

const Div = styled.div`
  position: relative;
  display: flex;
  padding: 5px;
  margin-left: ${(props) => (props.marginLeft ? 'auto' : 0)};
  margin-right: auto;
  font-weight: 600;
  font-size: 0.8rem;
  align-items: center;
  text-align: center;
  @media (min-width: 1156px) {
    ${({ align }) =>
      align !== 'none' &&
      `
      position: absolute;
      top: 50%;
    `}

    left: ${(props) => {
      if (props.align === 'center') return '50%';
      if (props.align === 'left') return '10px';
    }};

    transform: ${(props) => {
      if (props.align === 'center') return 'translateX(-50%) translateY(-50%)';
      if (props.align === 'left') return 'translateY(-50%)';
    }};
  }

  ${({ banner, theme, show }) =>
    banner &&
    `
      display: ${show ? 'flex' : 'none'};
      justify-content: center;
      width: 100%;
      background-color: ${theme.maintenanceBanner.backgroundColor};
      color: ${theme.body.color};
    `}

  ${({ showNotification, banner, showOverride }) =>
    showNotification || (banner && !showOverride)
      ? `padding: 5px 25px 5px 5px;`
      : ''}
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const NotificationBadge = styled.div`
  background-color: ${(props) => props.theme.colors.warning};
  border-radius: 50%;
  width: 15px;
  height: 15px;
  position: absolute;
  right: 5px;
`;

const MaintenanceBanner = (props) => {
  const {
    className,
    align = 'center',
    banner,
    onLoad,
    onClose,
    showOverride,
    style,
    showNotification,
  } = props;
  const loggedIn = useIsLoggedIn();
  const {
    downDate,
    down,
    show,
    message: maintenanceMessage,
    startTs,
    nowTs,
    loading,
  } = useMaintenanceMode();
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const maintenanceBannerClosed = localStorage.getItem(
      'maintenanceBannerClosed'
    );
    if (
      (downDate || show) &&
      (maintenanceBannerClosed === 'false' || !maintenanceBannerClosed)
    ) {
      setShowBanner(true);
    } else if (downDate && maintenanceBannerClosed === 'true') {
      setShowBanner(false);
    }

    if (!(downDate || show) && !loading) {
      if (maintenanceBannerClosed === 'true') {
        localStorage.setItem('maintenanceBannerClosed', 'false');
      }
    }
  }, [downDate, loading, show]);

  useEffect(() => {
    if (down && loggedIn) {
      window.location.pathname = '/logout';
    }
  }, [down, loggedIn]);

  useEffect(() => {
    if (onLoad) {
      onLoad(showBanner);
    }
  }, [onLoad, showBanner]);

  const beforeMessage = useMemo(() => {
    if (startTs && nowTs) {
      if (nowTs > startTs) {
        return 'Down for maintenance';
      } else {
        return `Down for maintenance at ${downDate.format('h:mm A')}`;
      }
    } else if (show) {
      return DEFAULT_MESSAGE;
    }
    return '';
  }, [downDate, nowTs, show, startTs]);

  const afterMessage = useMemo(() => {
    if (maintenanceMessage) {
      if (show) {
        return maintenanceMessage;
      } else {
        return <span> - {maintenanceMessage}</span>;
      }
    } else {
      if (show) {
        return DEFAULT_MESSAGE;
      }
    }
    return '';
  }, [maintenanceMessage, show]);

  const message = useMemo(() => {
    if (show) {
      return afterMessage;
    } else {
      return (
        <span>
          {beforeMessage}{' '}
          {nowTs < startTs && (
            <span>
              (<TimeAgo date={downDate.toDate()} />)
            </span>
          )}
          {afterMessage}
        </span>
      );
    }
  }, [show, afterMessage, beforeMessage, nowTs, startTs, downDate]);

  const handleClose = () => {
    localStorage.setItem('maintenanceBannerClosed', 'true');
    setShowBanner(false);
    if (onClose) {
      setTimeout(() => onClose(), 1);
    }
  };

  if (!(downDate || show)) {
    if (!loading) {
      localStorage.setItem('maintenanceBannerClosed', 'false');
    }
    return null;
  }

  return (
    <Div
      className={className}
      marginLeft={loggedIn}
      align={align}
      banner={banner}
      show={(banner && showBanner) || !banner || showOverride}
      style={style}
    >
      {message}
      {banner && !showOverride && (
        <CloseButton onClick={handleClose}>
          <i className="zmdi zmdi-close" />
        </CloseButton>
      )}
      {showNotification && <NotificationBadge />}
    </Div>
  );
};

export default MaintenanceBanner;
