/**
 *
 * WorkspaceLayout
 *
 */
import React, { useEffect, useMemo, useContext } from 'react';
import { ThemeContext, ThemeProvider } from 'styled-components';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

import darkTheme from 'themes/dark';
import { merge } from 'utils/arrayUtils';

import GlobalStyles from 'components/GlobalStyles';
import TrialBanner from 'components/TrialBanner';
import UnpaidBanner from 'components/UnpaidBanner';
import ErrorCodePopup from 'components/ErrorCodePopup';
import GustoConnectionModal from 'components/GustoConnectionModal';
import Masquerade from 'components/Masquerade';
import TwoFactorAuthModal from 'components/TwoFactorAuthModal';
import useWorkspaceId from 'hooks/useWorkspaceId';
import getTwoFactorAuthPreface from 'utils/getTwoFactorAuthPreface';
import { setTheme } from 'utils/theme';

const QUERY = gql`
  query WorkspaceLayout($workspaceId: Int!) {
    currentUser {
      theme
      hasTwoFactorAuth
    }
    workspace(id: $workspaceId) {
      id
      twoFactorAuthentication
      owner {
        email
      }
    }
  }
`;

const WorkspaceLayout: React.FunctionComponent = (props) => {
  const workspaceId = useWorkspaceId();
  const { children } = props;

  const { data } = useQuery(QUERY, { variables: { workspaceId } });

  const theme = useContext(ThemeContext);

  useEffect(() => {
    if (data && data.currentUser) {
      setTheme(data.currentUser.theme);
    }
  }, [data]);

  const newTheme = useMemo(() => {
    if (data && data.currentUser) {
      const dark = data.currentUser.theme === 'DARK';
      return dark ? merge(theme, darkTheme) : theme;
    }

    return theme;
  }, [data, theme]);

  return (
    <ThemeProvider theme={newTheme}>
      <div>
        <GlobalStyles />
        <TrialBanner />
        <UnpaidBanner />
        <ErrorCodePopup />
        <GustoConnectionModal />
        <Masquerade />
        <TwoFactorAuthModal
          show={
            data?.workspace?.twoFactorAuthentication &&
            !data?.currentUser?.hasTwoFactorAuth
          }
          preface={getTwoFactorAuthPreface({
            type: 'workspace',
            contact: data?.workspace?.owner?.email,
          })}
          workspaceControls
        />
        {children}
      </div>
    </ThemeProvider>
  );
};

export default WorkspaceLayout;
