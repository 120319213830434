/**
 *
 * XeroLauncher
 *
 */
import { useApolloClient, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import { useEffect } from 'react';

const QUERY = gql`
  query HasXeroAccess($tenantId: String!) {
    hasXeroAccess(tenantId: $tenantId) {
      access
      companyId
      groupId
      workspaceId
    }
  }
`;

const SET_WORKSPACE_COMPANY = gql`
  mutation SetWorkspaceGroupCompany(
    $workspaceId: Int!
    $groupId: Int!
    $companyId: Int!
  ) {
    setWorkspaceGroupCompany(
      workspaceId: $workspaceId
      groupId: $groupId
      companyId: $companyId
    ) @client
  }
`;

const XeroLauncher = () => {
  const isLoggedIn = useIsLoggedIn();
  const client = useApolloClient();

  const [setIds] = useMutation(SET_WORKSPACE_COMPANY);

  useEffect(() => {
    async function checkXeroLauncher(tenantId) {
      const result = await client.query({
        query: QUERY,
        variables: {
          tenantId,
        },
      });

      if (result.data.hasXeroAccess.access) {
        await setIds({
          variables: {
            workspaceId: result.data.hasXeroAccess.workspaceId,
            groupId: result.data.hasXeroAccess.groupId,
            companyId: result.data.hasXeroAccess.companyId,
          },
        });

        window.location.pathname = '/';
      }
    }

    const tenantId = window.sessionStorage.getItem('xeroLauncherId');
    if (isLoggedIn && tenantId) {
      window.sessionStorage.removeItem('xeroLauncherId');

      checkXeroLauncher(tenantId);
    }
  }, [client, isLoggedIn, setIds]);

  return null;
};

export default XeroLauncher;
