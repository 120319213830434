/**
 *
 * Update
 *
 */
import Button from 'components/Button';
import React, { useState } from 'react';
import { ValidationErrors } from './components';
import { useValidateExcel } from './hooks';
import { FileInput } from './styledComponents';

const Update = props => {
  const { companyId, updateExcelBudget, loading } = props;
  const [file, setFile] = useState(null);

  const {
    validateExcel,
    loading: validationLoading,
    validationErrors,
  } = useValidateExcel({
    companyId,
    onSetFile: setFile,
  });

  return (
    <div>
      <div>Update excel</div>
      <FileInput onChange={validateExcel} />
      {validationLoading && <div>Validating Excel File</div>}
      <ValidationErrors validationErrors={validationErrors} />
      {!!file && (
        <Button
          color="success"
          onClick={() => updateExcelBudget(file)}
          disabled={loading}
          loading={loading}
        >
          Upload Excel File
        </Button>
      )}
    </div>
  );
};

export default Update;
