// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from 'images/logo-white.png';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import darkLogo from 'images/logo.png';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import favicon from 'images/icon-white.png';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import darkFavicon from 'images/icon-dark.png';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import shortLogo from 'images/logo-white-short.png';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import reportConstants from 'constants/reports.json';

import colors from './lightColors';
import { DefaultTheme } from 'styled-components';

const reportChartColors = reportConstants.chartColors;

const Quantum: DefaultTheme = {
  mode: 'light',
  logo,
  shortLogo,
  body: {
    fontFamily: `'Open Sans' ,sans-serif`,
    // When changing the background theme color, make sure to update the colors in the following files:
    // - frontend\src\utils\theme.ts
    // - frontend\src\index.html
    background: '#f0f6ff',
    color: '#617182',
    fontSize: '100%',
    lineHeight: 1.5,
    gearColor: '#D6D7DC',
    border: '#f4f4f7',
  },
  logoFiles: {
    light: {
      logo: darkLogo,
      favicon: darkFavicon.src,
    },
    dark: {
      logo,
      favicon: favicon.src,
    },
  },
  fontSizes: {
    normal: '13px',
    small: '12px',
    title: '100%',
    totalNumber: '18px',
    table: '100%',
  },
  colors: {
    primary: '#399AF2',
    secondary: '#788db4',
    accent: '#F64A91',
    success: '#2fbfa0',
    danger: '#ff5c75',
    warning: '#FF865E',
    info: '#399AF2',
    brand: '#52badb',
    link: '#399AF2',
    light: '#f0f6ff',
    dark: '#15182b',
    default: '#4E5E6A',
    white: '#fff',
    icon: '#788db4',
    pro: '#E08320',
    progress: '#EBBB46',
    gear: '#617182',
  },
  content: {
    marginX: 30,
    sidebarWidth: 200,
  },
  table: {
    header: {
      background: '#eaebef',
      color: '#617182',
      sortedBackground: '#d6d6d6',
    },
    row: {
      color: '#617182',
      hover: '#FCFDFF',
    },
  },
  chartColors: reportChartColors[0],
  reportColorThemes: reportChartColors,
  header: {
    background: colors.headerPrimaryBackground,
    secondaryBackground: colors.headerSecondaryBackground,
    height: '60px',
    color: colors.headerPrimaryColor,
    logo: darkLogo,
    hoverColor: colors.headerPrimaryHoverColor,
    buttonHighlight: colors.headerButtonHoverBackground,
  },
  minimalHeader: {
    background: '#fff',

    height: '60px',
    color: '#788db4',
    logo: darkLogo,
  },
  workspaceHeader: {
    background: 'white',
    height: '60px',
    color: '#788db4',
  },
  workspaceSidebar: {
    width: '20rem',
    minimalWidth: '15rem',
  },
  profile: {
    color: '#5f5f5f',
  },
  sidebar: {
    width: '60px',
    boxShadow: '0 1px 20px 0 rgba(0,0,0,.3)',
    background: colors.primaryBackground,
    color: colors.primaryText,
    hoverColor: colors.hoverText,
    favicon: favicon.src,
    darkFavicon: darkFavicon.src,
    buttonHighlight: colors.hoverButton,
    buttonActive: colors.activeButton,
    logo: logo,
    syncColor: colors.syncIcon,
    syncHoverColor: colors.hoverSyncIcon,
    syncContainerColor: colors.syncContainer,
    syncContainerHoverColor: colors.hoverSyncContainer,
  },
  formControl: {
    background: colors.formPrimaryBackground,
    borderRadius: '.25rem',
    border: '1px solid #dfe7f3',
    boxShadow: 'none',
    fontSize: '.875rem',
    fontSizeScaled: (scale: number) => `${0.875 * scale}rem`,
    padding: '.375rem .75rem',
    paddingScaled: (scale: number) => `${scale * 0.375}rem ${scale * 0.75}rem`,
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    color: '#323a48',
    placeholder: 'rgba(120,141,180,.7)',
    lineHeight: 1.5,
    focus: {
      border: '1px solid #399AF2',
      background: '#fff',
    },
  },
  select: {
    background: 'white',
  },
  datePicker: {
    color: colors.datePickerColor,
    toggleBackgroundColor: colors.inputBackgroundColor,
    toggleBackgroundColorHover: colors.inputBackgroundHoverColor,
    selectedBackgroundColor: colors.inputBackgroundColor,
    selectedBackgroundHoverColor: colors.inputBackgroundHoverColor,
    keyboardSelectedBackgroundColor:
      colors.inputKeyboardSelectedBackgroundColor,
    launcherColor: colors.linkColor,
  },
  headings: {
    color: '#4d5a68',
    h1: {
      fontSize: '30px',
      fontWeight: 300,
      lineHeight: 1.1,
      color: 'inherit',
    },
    h2: {
      fontSize: '24px',
      fontWeight: 300,
      lineHeight: 1.1,
      color: 'inherit',
    },
  },
  button: {
    default: {
      base: '#fff',
      color: '#333',
      hover: '#333',
      hoverBg: '#dddddd',
      border: '#e5e5e5',

      outlineColor: '#617182',
      outlineBorder: '#617182',
      outlineHoverBg: '#617182',
      outlineHover: 'white',
      disabled: 'hsla(0, 0%, 20%, 0.3)',
    },
    primary: {
      base: '#12AFCB',
      hover: '#08A7C3',
      color: 'white',
      border: 'transparent',
    },
    success: {
      base: '#52badb',
      hover: '#08A7C3',
      color: 'white',
      border: 'transparent',
    },
    info: {
      base: '#52badb',
      hover: '#08A7C3',
      color: 'white',
      border: 'transparent',
    },
    warning: {
      base: '#FF865E',
      hover: '#ea7149',
      color: 'white',
      border: 'transparent',
    },
    danger: {
      base: '#f25656',
      hover: '#e14444',
      color: 'white',
      border: 'transparent',
    },
    secondary: {
      base: '#848484',
      hover: '#666',
      color: 'white',
      border: 'transparent',
    },
    white: {
      base: 'white',
      hover: '#eeeeee',
      color: '#333',
      border: '#fff',
    },
  },
  panel: {
    background: '#fff',
    titleBorder: '1px solid rgba(234,243,253,0.9)',
    boxShadow:
      '0 10px 40px 0 rgba(18,106,211,0.07), 0 2px 9px 0 rgba(18,106,211,0.06)',
  },
  dropdown: {
    icon: '#788db4',
    background: '#fff',
    boxShadow: '0 4px 14px rgba(174,197,231,.5)',
    color: '#839bb3',
    disabledColor: colors.disabledDropdownColor,
    disabledBackground: colors.disabledDropdownBackground,
    hover: {
      background: '#e7f1fd',
      color: '#399AF2',
    },
    scrollbarBackground: '#eeeeee',
    scrollbar: '#617182',
  },
  progress: {
    background: '#f5f5f5',
    bar: {
      background: '#52badb',
      color: 'white',
    },
  },
  modal: {
    color: '#617182',
  },
  tableKpiBuilder: {
    addButtonColor: '#333',
    addButtonIconColor: '#fff',
  },
  spreadsheet: {
    input: {
      backgroundColor: 'white',
    },
  },
  reportBuilder: {
    header: {
      background: colors.headerBackground,
      toolbarBackground: colors.toolbarBackground,
    },
    sidebar: {
      background: colors.sidebarBackground,
      color: colors.primaryText,
      hoverColor: colors.hoverText,
      hoverBackground: colors.hoverButton,
      activeBackground: colors.activeDrawerBackground,
      activeColor: colors.primaryText,
    },
    drawers: {
      background: colors.activeDrawerBackground,
      color: colors.primaryText,
      disabledColor: colors.disabledDrawerText,
      activeColor: colors.primaryText,
      hoverColor: colors.hoverText,
      kpiThumbnailBackground: colors.kpiThumbnailBackground,
      hoverKpiThumbnailBackground: colors.hoverKpiThumbnailBackground,
      buttonColor: colors.primaryBackground,
      buttonSecondaryColor: colors.activeDrawerBackground,
      itemColor: colors.drawerItemColor,
      itemSecondaryColor: colors.drawerItemSecondaryColor,
      hoverItemColor: colors.hoverDrawerItemColor,
      hoverItemTextColor: colors.hoverDrawerItemTextColor,
      tabText: colors.drawerTabText,
      activeTabText: colors.drawerTabActiveText,
      cornerButton: colors.drawerCornerButton,
      hoverCornerButton: colors.hoverDrawerCornerButton,
    },
  },
  clientPortal: {
    fileUploader: {
      color: colors.fileUploaderPrimaryColor,
    },
    viewButton: {
      activeColor: '#FFFFFF',
      activeBackground: '#617182',
      color: '#617182',
      background: 'unset',
      border: '#d6d8dd',
    },
  },
  chartStyles: {
    title: {
      style: {
        color: '#444',
      },
    },
    subtitle: {
      style: {
        color: '#444',
      },
    },
    plotOptions: {
      bar: {
        borderWidth: 0,
      },
      column: {
        borderWidth: 0,
      },
      pie: {
        borderWidth: 1,
        borderColor: null,
      },
      series: {
        dataLabels: {
          style: {
            color: 'contrast',
          },
        },
      },
    },
    legend: {
      itemStyle: {
        color: '#333',
      },
      itemHoverStyle: {
        color: '#000',
      },
      itemHiddenStyle: {
        color: '#ccc',
      },
      title: {
        style: {
          color: '#333333',
        },
      },
    },
    xAxis: {
      lineColor: 'transparent',
      tickColor: '#ccd6eb',
      labels: {
        style: {
          color: '#666666',
        },
      },
    },
    yAxis: {
      title: {
        text: null,
      },
      gridLineColor: '#e6e6e6',
      labels: {
        style: {
          color: '#666666',
        },
      },
    },
  },
  dropdownDrawer: {
    backgroundColor: colors.dropdownDrawerBackground,
    expandedBackgroundColor: colors.dropdownDrawerExpandedBackground,
    color: colors.dropdownDrawerColor,
    scrollFadeBackground: colors.scrollBarFade,
    borders: colors.dropdownDrawerExpandedBackground,
  },
  metricLibrary: {
    subSectionBackground: colors.subSectionBackground,
    subSectionColor: colors.subSectionColor,
  },
  scrollBar: {
    track: colors.scrollBarTrack,
    thumb: colors.scrollBarThumb,
  },
  searchBar: {
    backgroundColor: '#f0f6ff',
    color: '#617182',
    placeholderColor: '#9CACCA',
  },
  horizontalTabs: {
    color: '#718599',
    activeColor: '#353a40',
    borderColor: '#dddddd',
    activeBorderColor: '#353a40',
  },
  tableEditor: {
    header: {
      backgroundColor: '#fcfcfc',
    },
    table: {
      cell: {
        actualBackgroundColor: '#6ED9E766',
        varianceBackgroundColor: '#e9ebf1',
        customValueBackgroundColor: '#FFFF004D',
        selectionOverlay: 'rgba(125, 182, 253, 0.3)',
      },
    },
    contextMenu: {
      boxShadow: 'rgba(60, 64, 67, 0.15)',
    },
    commentHistory: {
      header: {
        backgroundColor: '#F1EDED',
      },
    },
    borderColor: '#e1e1e1',
  },
  maintenanceBanner: {
    backgroundColor: '#EAEBEF',
  },
};

export default Quantum;
