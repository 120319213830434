export default {
  primaryText: '#d8dadf',
  secondaryText: '#617182',
  tertiaryText: '#839bb3',
  hoverText: '#222532',
  modalText: '#617182',
  linkColor: '#52BADB',
  subSectionColor: '#617182',

  formPrimaryBackground: '#ffffff',

  activeButton: 'rgba(240, 246, 255, 0.4)',
  hoverButton: 'rgba(240, 246, 255, 0.7)',

  primaryBackground: '#222532',
  secondaryBackground: '#181922',
  subSectionBackground: '#ececec',

  headerPrimaryBackground: '#ffffff',
  headerPrimaryColor: '#222532',
  headerPrimaryHoverColor: '#222532',
  headerSecondaryBackground: '#CDCDCD',
  headerButtonHoverBackground: '#f5f5f5',

  syncContainer: '#81B9D9',
  syncIcon: '#222532',
  hoverSyncContainer: '#222532',
  hoverSyncIcon: '#d8dadf',

  inputBackgroundColor: '#52BADB',
  inputBackgroundHoverColor: '#08A7C3',
  inputKeyboardSelectedBackgroundColor: '#dcdcdc',

  // Report Builder
  headerBackground: '#ffffff',
  toolbarBackground: '#ffffff',
  sidebarBackground: '#2e313d',
  activeDrawerBackground: '#181922',
  kpiThumbnailBackground: '#222532',
  hoverKpiThumbnailBackground: '#40424B',
  drawerItemColor: '#313139',
  drawerItemSecondaryColor: '#353840',
  hoverDrawerItemColor: '#40424B',
  hoverDrawerItemTextColor: '#d8dadf',
  drawerTabText: '#718599',
  drawerTabActiveText: '#d8dadf',
  drawerCornerButton: 'transparent',
  hoverDrawerCornerButton: '#08a7c3',
  disabledDrawerText: '#47494f',

  // File Uploader
  fileUploaderPrimaryColor: '#617182',

  // Date Picker
  datePickerColor: '#617182',

  // Dropdown Drawer
  dropwdownDrawerBackground: '#ffffff',
  dropdownDrawerExpandedBackground: '#eeeeee',
  dropdownDrawerColor: '#617182',

  // Scrollbar
  scrollBarTrack: '#ffffff',
  scrollBarThumb: '#eeeeee',
  scrollBarFade: '#ffffff',

  // Dropdown Button
  disabledDropdownColor: '#CDCDCD',
  disabledDropdownBackground: '#CDCDCD',
};
