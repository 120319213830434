/**
 *
 * BudgetsButton
 *
 */
import React, { useCallback } from 'react';

import BudgetForecastIcon from 'components/Icons/BudgetForecastIcon';
import useIsConsolidation from 'hooks/useIsConsolidation';
import useIntegrationTypes from 'hooks/useIntegrationTypes';
import SidebarButton from './SidebarButton';

const consolidatedIntegrationsWithBudgets = new Set(['QB', 'XERO', 'CSV', 'MYOB']);

const BudgetsButton = ({ toggleSidebar, onClick }) => {
  const isConsolidation = useIsConsolidation();

  const { accountingIntegration, payrollIntegration } = useIntegrationTypes();

  const comingSoon = !accountingIntegration && !!payrollIntegration;

  const handleClick = useCallback(() => {
    toggleSidebar && toggleSidebar(false);
    onClick && onClick();
  }, [onClick, toggleSidebar]);

  if (!accountingIntegration && !payrollIntegration) {
    return null;
  }

  if (
    isConsolidation === null ||
    (isConsolidation &&
      !consolidatedIntegrationsWithBudgets.has(accountingIntegration))
  ) {
    return null;
  }

  if (comingSoon) return null;

  return (
    <SidebarButton
      icon={<BudgetForecastIcon height={'24px'} width={'24px'} />}
      text={'Budgets / Forecasts'}
      beta={isConsolidation || comingSoon}
      betaText={comingSoon ? 'coming soon' : 'beta'}
      onClick={comingSoon ? null : handleClick}
      id="sidebar-budgets-button"
      testId="budgets-button"
    />
  );
};

export default BudgetsButton;
