import gql from 'graphql-tag';

export const WORKSPACE_OWNER_AND_SECONDARY_ADMIN = gql`
  query WorkspaceOwner($workspaceId: Int!) {
    workspace(id: $workspaceId) {
      id
      owner {
        id
        email
        fullName
      }
      secondaryAdmin {
        id
        email
        fullName
      }
    }
  }
`;
