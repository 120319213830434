import gql from 'graphql-tag';
import produce from 'immer';

export function clearBudgetList(cache, companyId) {
  cache.evict({
    id: `Company:${companyId}`,
    fieldName: 'budgets',
  });

  cache.gc();
}

export function clearDataPickerList(cache, companyId) {
  cache.evict({
    id: `Company:${companyId}`,
    fieldName: 'sharedDataVariables',
  });

  cache.gc();
}

export function clearWorkspaceUsersModalList(cache, workspaceId) {
  cache.evict({
    id: `Workspace:${workspaceId}`,
    fieldName: 'users',
  });

  cache.gc();
}

export function clearTableValues(cache, companyId) {
  cache.evict({
    id: `Company:${companyId}`,
    fieldName: 'metricData',
  });

  const data = cache.extract();

  Object.keys(data).forEach((key) => {
    if (key.indexOf('Table:') !== 0) return;

    if (data[key].isExcel !== undefined) return; // check if it is a budget

    if (data[key].values) {
      cache.evict({ id: key, field: 'values' });
    } else {
      if (
        Object.keys(data[key]).find(
          (itemKey) => itemKey.indexOf('values') === 0
        )
      ) {
        cache.evict({ id: key, field: 'values' });
      }
    }
  });

  cache.gc();
}

export function clearTables(cache) {
  const data = cache.extract();

  Object.keys(data).forEach((key) => {
    if (key.indexOf('Table:') === 0) {
      cache.evict({ id: key });
    } else if (key.indexOf('QueryTable:') === 0) {
      cache.evict({ id: key });
    } else if (key.indexOf('Dashboard:') === 0) {
      cache.evict({ id: key, fieldName: 'tables' });
    } else if (key.indexOf('ReportPage:') === 0) {
      cache.evict({ id: key });
    }
  });

  cache.gc();
}

const USER_HAS_CREATED = gql`
  query UserHasCreated {
    currentUser {
      hasCreatedDashboard
      hasCreatedReport
    }
  }
`;

export function setUserHasCreatedDashboard(cache) {
  const data = cache.readQuery({
    query: USER_HAS_CREATED,
  });

  cache.writeQuery({
    query: USER_HAS_CREATED,
    data: produce(data, (draft) => {
      draft.currentUser.hasCreatedDashboard = true;
    }),
  });
}

export function setUserHasCreatedReport(cache) {
  const data = cache.readQuery({
    query: USER_HAS_CREATED,
  });

  cache.writeQuery({
    query: USER_HAS_CREATED,
    data: produce(data, (draft) => {
      draft.currentUser.hasCreatedReport = true;
    }),
  });
}
