import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';

import Button from 'components/Button';
import FolderTable from 'components/FolderList';
import InviteForm from 'components/InviteForm/InviteForm';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import { captureException } from 'utils/sentry';

const DivRight = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px;
`;

const Divider = styled.div`
  width: 100%;
  margin: 10px 0 20px 0;
  border-top: 1px solid #e9ecef;
`;

interface FolderUpdate {
  toAdd: number[];
  toRemove: number[];
}

interface NewClient {
  firstName: string;
  lastName: string;
  email: string;
}

interface Invite {
  newClient: NewClient;
  selectedFolders: number[];
}

interface UserFolderSelectionProps {
  show: boolean;
  showInvite: boolean;
  groupAccess?: boolean;
  title: string;
  folders: { id: number; name: string }[];
  selectedFolderIds: number[];
  loadingFolders?: boolean;
  loadingSelectedFolders: boolean;
  loadingInvite?: boolean;
  loadingUpdateInviteFolders?: boolean;
  inviteButtonId?: string;
  onUpdate?: (update: FolderUpdate) => void;
  onInvite?: ((invite: Invite) => Promise<any>) | null;
  onClose: () => void;
}

const UserFolderSelection: React.FC<UserFolderSelectionProps> = ({
  show,
  showInvite,
  groupAccess = false,
  title,
  folders,
  selectedFolderIds,
  loadingFolders,
  loadingSelectedFolders,
  loadingInvite,
  loadingUpdateInviteFolders,
  inviteButtonId,
  onUpdate,
  onInvite,
  onClose,
}) => {
  const [selectedFolders, setSelectedFolders] = useState(selectedFolderIds);
  const [error, setError] = useState('');

  /**
   * Resets the selected folders to the initial state then closes the modal
   */
  const resetSelection = () => {
    setSelectedFolders(selectedFolderIds);
    onClose();
  };

  /**
   * Updates array of selected folders on checkbox check or uncheck
   */
  const handleFolderCheck = useCallback(
    (folderId, checked) => {
      const selectionChange = [...selectedFolders];
      if (checked) {
        selectionChange.push(folderId);
      } else {
        selectionChange.splice(
          selectionChange.findIndex((f) => f === folderId),
          1
        );
      }
      setSelectedFolders(selectionChange);
    },
    [selectedFolders]
  );

  /**
   * Creates an object of folder updates and uses onUpdate callback to trigger update in parent
   */
  const handleFolderUpdate = useCallback(() => {
    const updatedFolders: FolderUpdate = {
      toAdd: selectedFolders.filter((f) => !selectedFolderIds.includes(f)),
      toRemove: selectedFolderIds.filter((f) => !selectedFolders.includes(f)),
    };
    if (onUpdate) {
      onUpdate(updatedFolders);
    }
    onClose();
  }, [onClose, onUpdate, selectedFolderIds, selectedFolders]);

  /**
   * Initialize selected folders
   */
  useEffect(() => {
    setSelectedFolders(selectedFolderIds);
  }, [selectedFolderIds]);

  /**
   * Callback for when a client is invited from the Invite Form
   */
  const handleClientInvite = useCallback(
    async (newClient) => {
      if (onInvite) {
        try {
          const { data } = await onInvite({ newClient, selectedFolders });
          if (
            data &&
            data.inviteCompanyUser &&
            data.inviteCompanyUser.success
          ) {
            onClose();
          } else if (
            data &&
            data.inviteCompanyUser &&
            data.inviteCompanyUser.error
          ) {
            setError(data.inviteCompanyUser.error);
          } else {
            setError('An unexpected error occurred.');
          }
        } catch (err) {
          captureException(err, true);
        }
      }
    },
    [onClose, onInvite, selectedFolders]
  );

  /**
   * Returns boolean to determine divider visibility
   */
  const displayDivider = useCallback(() => {
    return showInvite && !groupAccess;
  }, [showInvite, groupAccess]);

  return (
    <Modal
      show={show}
      title={title}
      onClose={resetSelection}
      width={showInvite ? 700 : 300}
    >
      {loadingFolders ? (
        <Spinner />
      ) : (
        <>
          {groupAccess ? (
            <></>
          ) : (
            <FolderTable
              folders={folders}
              selectedFolderIds={selectedFolders}
              loadingSelectedFolders={loadingSelectedFolders}
              loadingInvite={loadingInvite}
              loadingUpdateInviteFolders={loadingUpdateInviteFolders}
              onSelect={handleFolderCheck}
            />
          )}
          {displayDivider() ? <Divider /> : <></>}
          {showInvite ? (
            <InviteForm
              loading={
                loadingFolders || loadingInvite || loadingUpdateInviteFolders
              }
              onSubmit={handleClientInvite}
              error={error}
              inviteButtonId={inviteButtonId}
            />
          ) : (
            <DivRight>
              <Button
                color="primary"
                type="submit"
                onClick={handleFolderUpdate}
                loading={loadingSelectedFolders}
                disabled={loadingSelectedFolders}
              >
                Save
              </Button>
            </DivRight>
          )}
        </>
      )}
    </Modal>
  );
};

export default UserFolderSelection;
