/**
 *
 * Table
 *
 */
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTable, useSortBy, Column } from 'react-table';

import TableInner, { TableInnerProps } from './TableInner';
import scrollbar from 'css/scrollbar';
import { User } from 'interfaces/user';
import { Invite } from 'components/InviteButton';
import { isWorkspaceInvite } from '.';

const Scroll = styled.div`
  max-height: calc(100vh - 15rem);
  overflow-y: auto;
  ${scrollbar()}
`;

const Title = styled.p`
  margin-bottom: 0.1rem;
`;

const Message = styled.span`
  width: 100%;
  text-align: center;
  display: inline-block;
  font-size: 0.85rem;
  margin-bottom: 1rem;
`;

const Divider = styled.span`
  width: 100%;
  display: inline-block;
  margin-bottom: 1rem;
  border-top: solid 1px #e9ecef;
`;

interface Row extends Record<'id' | 'fullName' | 'email', unknown> {
  id: number;
  fullName: string;
  email: string;
}

interface RowWithOwnerOrSecondaryAdmin extends Row, User {
  owner?: boolean;
  secondaryAdmin?: boolean;
}

interface RowWithInviteId extends Row, User {
  inviteId: number;
}

const columnsWithOwnerOrSecondaryAdmin: Column<RowWithOwnerOrSecondaryAdmin>[] =
  [
    {
      Header: 'Name',
      accessor: 'fullName',
      Cell: ({ row }) => {
        if (row.original.owner && row.original.owner !== undefined) {
          return `${row.original.fullName} (Workspace Owner)`;
        } else if (
          row.original.secondaryAdmin &&
          row.original.secondaryAdmin !== undefined
        ) {
          return `${row.original.fullName} (Secondary Admin)`;
        } else {
          return row.original.fullName;
        }
      },
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
  ];

const columnsWithInviteId: Column<RowWithInviteId>[] = [
  {
    Header: 'Name',
    accessor: 'fullName',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
];

interface TableProps<T extends Invite> extends TableInnerProps {
  invites?: T[] | null;
  selectedUserIds: number[];
  selectedInviteIds: number[];
  users: User[];
  owner?: User;
  secondaryAdmin?: User;
  isSecondaryAdmin?: boolean;
  loadingUsers?: boolean;
  loadingResendInvite?: boolean;
  showChecks?: boolean;
  readOnlyUserIds?: Array<number>;
  currentUserId: number;
  onRemove?: (any) => void;
  onPreview?: (int) => void;
  onSelect?: (id: number, selected: boolean) => void;
  onFolderAccessButton?: (user: User) => void;
  onResendInvite?: (user: User) => void;
  onShowUpdateInviteFolders?: (invite: number) => void;
  isClientPortalUser?: boolean;
  workspaceId: number;
  companyId: number;
}

const UserTable = <T extends Invite>({
  users,
  owner,
  secondaryAdmin,
  isSecondaryAdmin,
  invites,
  loadingUsers,
  loadingResendInvite,
  showChecks,
  onRemove,
  onRemoveInvite,
  onSelect,
  onPreview,
  onFolderAccessButton,
  onClientUserChange,
  onWorkspaceOwnerChange,
  onWorkspaceSecondaryAdminChange,
  onResendInvite,
  onShowUpdateInviteFolders,
  selectedUserIds,
  selectedInviteIds,
  readOnlyUserIds,
  showClientUserOptions = false,
  isOwner = false,
  currentUserId,
  isClientPortalUser,
  workspaceId,
  companyId,
}: TableProps<T>): React.ReactElement => {
  const usersWithOwnerOrSecondaryAdmin = useMemo<
    RowWithOwnerOrSecondaryAdmin[]
  >(() => {
    let newUsers = [...users];
    if (secondaryAdmin) {
      newUsers = newUsers.filter((user) => user.id !== secondaryAdmin.id);
      newUsers.unshift({ ...secondaryAdmin, secondaryAdmin: true });
    }
    if (owner && (secondaryAdmin || users.length > 0)) {
      newUsers.unshift({ ...owner, owner: true });
    }
    return newUsers;
  }, [owner, secondaryAdmin, users]);

  const invitedUsers = useMemo<RowWithInviteId[]>(
    () =>
      invites
        ? invites.reduce<RowWithInviteId[]>((_invitedUsers, invite) => {
            if (invite.user) {
              if (isWorkspaceInvite(invite)) {
                const invitedUser = {
                  ...invite.user,
                  inviteId: invite.id,
                  link: invite.link,
                };
                _invitedUsers.push(invitedUser);
              } else {
                const invitedUser = {
                  ...invite.user,
                  inviteId: invite.id,
                  link: invite?.link,
                };
                _invitedUsers.push(invitedUser);
              }
            }
            return _invitedUsers;
          }, [])
        : [],
    [invites]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<RowWithOwnerOrSecondaryAdmin>(
      {
        columns: columnsWithOwnerOrSecondaryAdmin,
        data: usersWithOwnerOrSecondaryAdmin,
      },
      useSortBy
    );

  const {
    getTableProps: getInviteTableProps,
    getTableBodyProps: getInviteTableBodyProps,
    headerGroups: inviteHeaderGroups,
    rows: inviteRows,
    prepareRow: prepareInviteRow,
  } = useTable<RowWithInviteId>(
    {
      columns: columnsWithInviteId,
      data: invitedUsers,
    },
    useSortBy
  );

  return usersWithOwnerOrSecondaryAdmin.length > 0 ||
    (invites && invites.length > 0) ? (
    <Scroll>
      {usersWithOwnerOrSecondaryAdmin.length > 0 && (
        <>
          <Title>Users</Title>
          <TableInner<RowWithOwnerOrSecondaryAdmin>
            loadingUsers={loadingUsers}
            selectedItemIds={selectedUserIds}
            showChecks={showChecks}
            readOnlyUserIds={readOnlyUserIds}
            onRemove={onRemove}
            onPreview={onPreview}
            onSelect={onSelect}
            onFolderAccessButton={onFolderAccessButton}
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            rows={rows}
            prepareRow={prepareRow}
            onClientUserChange={onClientUserChange}
            onWorkspaceOwnerChange={onWorkspaceOwnerChange}
            onWorkspaceSecondaryAdminChange={onWorkspaceSecondaryAdminChange}
            onResendInvite={onResendInvite}
            showClientUserOptions={showClientUserOptions}
            isOwner={isOwner}
            hasSecondaryAdmin={!!secondaryAdmin}
            isSecondaryAdmin={isSecondaryAdmin}
            currentUserId={currentUserId}
            isClientPortalUser={isClientPortalUser}
            workspaceId={workspaceId}
            companyId={companyId}
          />
        </>
      )}
      {invites && invites.length > 0 && (
        <>
          {users.length > 0 && <Divider />}
          <Title>Pending Invites</Title>
          <TableInner<RowWithInviteId>
            loadingUsers={loadingUsers}
            onResendInvite={onResendInvite}
            onRemoveInvite={onRemoveInvite}
            getTableProps={getInviteTableProps}
            getTableBodyProps={getInviteTableBodyProps}
            headerGroups={inviteHeaderGroups}
            rows={inviteRows}
            prepareRow={prepareInviteRow}
            onSelect={onSelect}
            selectedItemIds={selectedInviteIds}
            showChecks={showChecks}
            currentUserId={currentUserId}
            onShowUpdateInviteFolders={onShowUpdateInviteFolders}
            loadingResendInvite={loadingResendInvite}
          />
        </>
      )}
    </Scroll>
  ) : (
    <Message>There are currently no users or pending invites.</Message>
  );
};

export default UserTable;
