/**
 * Sets the theme in local storage and on the document
 * @param { 'DARK' | 'LIGHT' } theme The theme to set (DARK or LIGHT)
 */
export const setTheme = (theme: 'DARK' | 'LIGHT'): void => {
  if (theme !== 'DARK' && theme !== 'LIGHT') {
    console.error('Invalid theme', theme);
    return;
  }

  if (document && document.documentElement) {
    // When changing the theme colors, make sure to update the colors in the following files:
    // - frontend\src\index.html
    // - frontend\src\themes\dark.js
    // - frontend\src\themes\quantum.js
    const color = theme === 'DARK' ? '#181922' : '#f0f6ff';
    document.documentElement.style.backgroundColor = color;
  } else {
    console.error(
      'document.documentElement is not available. Theme will not be set.'
    );
  }

  if (window && window.localStorage) {
    window.localStorage.setItem('theme', theme);
  } else {
    console.error(
      'window.localStorage is not available. Theme will not be saved.'
    );
  }
};

/**
 * Gets the theme from local storage or the user's system
 * @returns { 'DARK' | 'LIGHT' } The theme from local storage or the user's system
 */
export const getTheme = (): 'DARK' | 'LIGHT' => {
  if (window && window.localStorage) {
    const theme =
      window.localStorage.getItem('theme') ||
      (window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'DARK'
        : 'LIGHT') ||
      'LIGHT';

    return theme as 'DARK' | 'LIGHT';
  } else {
    console.error('window.localStorage is not available. Using LIGHT theme.');
    return 'LIGHT';
  }
};
