export default {
  defaults: {
    showCreateFirmModal: false,
    showCollaboratorsModal: false,
    collaboratorsModalCompanyId: 0,
    showSetFirmNameModal: false,
    setFirmNameModalFirmId: 0,
    showFirmManagersModal: false,
    firmManagersModalFirmId: 0,
    showTransferCompanyModal: false,
    transferCompanyId: 0,
  },
  resolvers: {
    Mutation: {
      setShowCreateFirmModal: (_, { show }, { cache }) => {
        const data = {
          data: {
            showCreateFirmModal: show,
          },
        };
        cache.writeData(data);
        return null;
      },
      setShowCollaboratorsModal: (_, { show, companyId }, { cache }) => {
        const data = {
          data: {
            showCollaboratorsModal: show,
            collaboratorsModalCompanyId: companyId || 0,
          },
        };
        cache.writeData(data);
        return null;
      },
      setShowSetFirmNameModal: (_, { show, firmId }, { cache }) => {
        const data = {
          data: {
            showSetFirmNameModal: show,
            setFirmNameModalFirmId: firmId || 0,
          },
        };
        cache.writeData(data);
        return null;
      },
      setShowFirmManagersModal: (_, { show, firmId }, { cache }) => {
        const data = {
          data: {
            showFirmManagersModal: show,
            firmManagersModalFirmId: firmId || 0,
          },
        };
        cache.writeData(data);
        return null;
      },
      setShowTransferCompanyModal: (_, { show, companyId }, { cache }) => {
        const data = {
          data: {
            showTransferCompanyModal: show,
            transferCompanyId: companyId || 0,
          },
        };
        cache.writeData(data);
        return null;
      },
    },
  },
};
