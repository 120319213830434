import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

const QUERY = gql`
  query GetWorkspaceId {
    currentWorkspaceId @client
  }
`;

export default function useWorkspaceId() {
  const { workspaceId } = useParams();
  const { data } = useQuery(QUERY);

  return workspaceId || (data && data.currentWorkspaceId);
}
