import DropdownButtonBase from 'components/DropdownButton';
import styled from 'styled-components';

export const DropdownButton = styled(DropdownButtonBase)<{
  selected: boolean;
}>`
  height: 26px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 0.75rem;
  background: ${(props) =>
    props.selected ? props.theme.colors.info : 'transparent'};
  color: ${(props) =>
    props.selected ? 'white' : props.theme.dropdown.color}!important;

  &:hover {
    background: ${(props) =>
      props.selected
        ? props.theme.colors.info
        : props.theme.dropdown.hover.background}!important;
    color: ${(props) =>
      props.selected ? 'white' : props.theme.dropdown.hover.color}!important;
  }

  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: inherit;
`;

export const TriggerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px;
  transition: background-color 0.15s ease-out, color 0.15s ease-out;
  color: ${(props) => props.theme.header.color};
  &:hover {
    background-color: ${(props) => props.theme.header.buttonHighlight};
    color: ${(props) => props.theme.header.hoverColor};
    svg > path {
      fill: ${(props) => props.theme.header.hoverColor};
    }
  }
  svg > path {
    fill: ${(props) => props.theme.header.color};
    transition: fill 0.15s ease-out;
  }
`;

export const Label = styled.div`
  height: 100%;
  font-size: 1.2rem;
  max-width: 600px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
`;
