import React from 'react';
import { useField } from 'informed';

import Checkbox from 'components/Checkbox';
import styled from 'styled-components';

const StyledCheckbox = styled(Checkbox)`
  display: ${(props) => (props.hidden ? 'none' : 'inline-flex')};
`;

const CheckboxField = (props) => {
  const { fieldState, fieldApi, render, ref, userProps } = useField(props);

  const { value } = fieldState;
  const { setValue } = fieldApi;
  const { onChange, hidden, override, ...rest } = userProps;
  const small = props.small ? props.small : false;

  return render(
    <StyledCheckbox
      ref={ref}
      checked={value || false}
      hidden={hidden}
      onChange={(e) => {
        if (!override) {
          setValue(e.target.checked);
        }
        if (onChange) {
          onChange(e);
        }
      }}
      small={small}
      {...rest}
    />
  );
};

export default CheckboxField;
