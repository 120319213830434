import styled, { css } from 'styled-components';
import styledTheming from 'styled-theming';
import TreeViewComponent from '@material-ui/lab/TreeView';
import ReactPlayer from 'react-player';
import media from 'media';
import { makeStyles } from '@material-ui/core/styles';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import university from 'images/training-light-theme.png';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import universityDark from 'images/training-dark-theme.png';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import playIcon from 'images/play-icon.png';
import scrollbar from 'css/scrollbar';
import { motion } from 'framer-motion';

export const BORDER_RADIUS = '3px';

export const colorFont1 = styledTheming('mode', {
  dark: '#fcfdff',
  light: '#181922',
});

export const colorFontInverted = styledTheming('mode', {
  light: '#fcfdff',
  dark: '#fcfdff',
});

export const colorFont2 = styledTheming('mode', {
  dark: '#dbd9d8',
  light: '#5f5f65',
});

export const bgColor = styledTheming('mode', {
  dark: '#1b1c22',
  light: '#ffffff',
});

export const bgColorSide = styledTheming('mode', {
  light: '#f0f6ff',
  dark: '#181922',
});

export const imgSources = styledTheming('mode', {
  light: university.src,
  dark: universityDark.src,
});

export const useStyles = makeStyles({
  label: {
    borderRadius: 10,
    fontSize: 15,
    // height: 23,
    marginTop: 3,
    marginBottom: 3,
    backgroundColor: 'transparent',
  },
  labelPrimary: {
    fontSize: 18,
    fontWeight: 400,
    marginTop: 5,
    marginBottom: 5,
  },
  iconContainer: {
    color: '#4fbade',
  },
  iconBackContainer: {
    color: '#99aac8',
    fontSize: 12,
    textTransform: 'unset',
    marginTop: -7,
  },
});

export const Search = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;
`;

export const TreeView = styled(TreeViewComponent)`
  flex-grow: 1;
  max-width: 400;
`;

export const Div = styled.div<{
  flex?: boolean;
  overflowY?: string;
  overflowX?: string;
  wrap?: boolean;
  height?: string;
  width?: string;
  column?: boolean;
  center?: boolean;
  justify?: string;
  left?: number;
  padding?: string | number;
  minHeight?: string;
}>`
  display: ${(props) => (props.flex ? 'flex' : 'block')};
  overflow-y: ${(prop) => prop.overflowY || 'hidden'};
  overflow-x: ${(prop) => prop.overflowX || 'hidden'};
  flex-wrap: ${(prop) => (prop.wrap ? 'wrap' : 'no-wrap')};
  height: ${(prop) => prop.height || 'auto'};
  max-height: calc(100vh - 130px);
  width: ${(prop) => prop.width || 'auto'};
  flex-direction: ${(prop) => (prop.column ? 'column' : 'row')};
  align-items: ${(prop) => (prop.center ? 'center' : 'stretch')};
  justify-content: ${(prop) => prop.justify || 'flex-start'};
  margin-left: ${(prop) => prop.left || 0};
  padding: ${(prop) => prop.padding || 0};
  color: ${colorFont2};
  ${(prop) =>
    prop.minHeight &&
    css`
      min-height: ${prop.minHeight};
    `}
`;

export const VideoContainer = styled.div`
  padding: 1rem 1.25rem;
  width: 100%;
  overflow-y: auto;
  ${scrollbar()}
`;

export const Container = styled(Div)`
  height: 670px;
`;

export const ListSideContainer = styled.div<{
  column?: boolean;
  center?: boolean;
  justify?: string;
  left?: number;
}>`
  display: flex;
  padding: 1rem;
  flex-shrink: 0;
  overflow-y: auto;
  width: 290px;
  flex-direction: ${(prop) => (prop.column ? 'column' : 'row')};
  align-items: ${(prop) => (prop.center ? 'center' : 'flex-start')};
  justify-content: ${(prop) => prop.justify || 'flex-start'};
  margin-left: ${(prop) => prop.left || 0};
  background: ${bgColorSide};
  overflow-x: hidden;
  position: relative;
  border-radius: 6px 0 0 6px;
  & .MuiTreeItem-root {
    color: ${colorFont2};
  }
  &
    .MuiTreeItem-root.Mui-selected.video-link
    > .MuiTreeItem-content
    .MuiTreeItem-label {
    background-color: transparent;
    color: ${colorFontInverted};
    display: flex;
    align-items: center;
  }
  & .MuiTreeItem-root.Mui-selected.video-link > .MuiTreeItem-content:before {
    content: '';
    display: block;
    position: absolute;
    background-color: #4fbade;
    left: -100px;
    right: -50px;
    top: 0;
    bottom: 0;
  }
  & .Mui-selected {
    position: relative;
  }
  ${scrollbar()}
`;

export const DivVideoList = styled.div<{
  flex?: number;
  width?: string;
  column?: boolean;
  center?: boolean;
  justify?: string;
  left?: number;
}>`
  display: flex;
  overflow-y: auto;
  flex-wrap: wrap;
  width: ${(prop) => prop.width || '100%'} !important;
  flex-direction: ${(prop) => (prop.column ? 'column' : 'row')};
  margin-left: ${(prop) => prop.left || 0};
`;

export const Logo = styled.div<{ src: string }>`
  min-height: 75px;
  min-width: 230px;
  margin-bottom: 25px;
  margin-right: 10px;
  margin-left: 10px;
  background-repeat: no-repeat;
  background-image: url(${(prop) => prop.src});
  background-size: contain;
`;

export const LargeThumbnailContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

export const LargeThumbnail = styled.div<{ background: string }>`
  min-height: 427.2px;
  min-width: 732px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${(props) => props.background});
  position: relative;
  opacity: 0.72;
  background-color: ${bgColor};
`;

export const LargeThumbnailPlay = styled.div`
  border-radius: 3px;
  min-height: 427.2px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${playIcon.src});
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

export const Side = styled.div<{ width?: number }>`
  width: ${(props) => props.width}%;
  margin: 0 0.5rem;
`;

export const ListSide = styled(Side)`
  min-width: 250px;
  ${media.desktop`
  min-width: 250px;
  `}
`;

export const VideoSide = styled(Side)`
  width: 100%;
  margin: 0;
  ${media.desktop`
    width: 100%;
  `}
  height: 100%;
`;

export const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
`;

export const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const ProgressBar = styled.div`
  max-width: 200px;
  width: 100%;
  height: 6px;
  background-color: #d8dadf3d;
  border-radius: ${BORDER_RADIUS};
  overflow: hidden;
  margin: 0 10px;

  span {
    display: block;
  }
`;

export const Bar = styled.span`
  width: 100%;
`;

export const Progress = styled.span<{ progress: number }>`
  background: ${({ progress }) => (progress ? '#4FBADE' : 'none')};
  color: #fff;
  padding: 3px;
  border-radius: ${BORDER_RADIUS};
  width: ${({ progress }) => (progress ? `${progress}%` : '0')};
  transition: 0.5s ease-in-out;
`;

export const BackButton = styled(motion.button)`
  font-size: 2rem;
  border: none;
  overflow: hidden;
  padding: 0;
  width: 0;

  & :hover {
    background-color: unset;
    color: unset;
  }
`;
