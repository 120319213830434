import logo from 'images/logo-white.png';

export default {
  id: 'darkThemeE',
  name: 'theme-e',
  color: '#f2989d',
  colors: {
    primary: '#f2989d',
    secondary: '#788db4',
    accent: '#F64A91',
    success: '#2fbfa0',
    danger: '#ff5c75',
    warning: '#FFCE67',
    info: '#399AF2',
    link: '#337ab7',
    light: '#f0f6ff',
    dark: '#15182b',
    default: '#4E5E6A',
  },
  chartColors: [
    '#f2989d',
    '#F64A91',
    '#2fbfa0',
    '#ff5c75',
    '#FFCE67',
    '#399AF2',
  ],
  header: {
    background: '#f2989d',
    color: 'white',
    logo,
  },
};
