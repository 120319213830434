import Button from 'components/Button';
import React, { useEffect, useState, useCallback, useRef, memo } from 'react';
import { Input, InputContainer } from './styledComponents';

enum RecaptchaAction {
  SIGNUP = 'signup',
  LOGIN = 'login',
  PASSWORD_RESET = 'password_reset',
}

const SITE_KEYS_DEFAULT = '6LeAj_EpAAAAAFzkrWKwedEeKpW4HQQfm8OUDr9N';
const SET_SITE_KEY_DELAY = 500;

const SiteKeyInput = memo<{
  initialKey: string;
  handleSetSiteKey: (key: string) => void;
  loading: boolean;
}>(({ initialKey, handleSetSiteKey, loading }) => {
  const [stagedKey, setStagedKey] = useState<string>(initialKey);
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setStagedKey(e.target.value);
  }, []);

  return (
    <InputContainer>
      <Input
        id="site-key-input"
        value={stagedKey}
        onChange={handleChange}
        placeholder="Paste ReCAPTCHA Key"
        autoComplete="off" data-1p-ignore
      />
      <Button
        id="set-site-key-button"
        onClick={() => handleSetSiteKey(stagedKey)}
        disabled={loading}
      >
        Set ReCAPTCHA Key
      </Button>
    </InputContainer>
  );
});

SiteKeyInput.displayName = 'SiteKeyInput';

const useRecaptcha = (
  action: RecaptchaAction,
  callback?: (siteKey: string | null) => void
): [boolean, string | null, React.FC] => {
  const [token, setToken] = useState<string | null>(null);
  const [siteKey, setSiteKey] = useState<string>(SITE_KEYS_DEFAULT);
  const [loading, setLoading] = useState<boolean>(true);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const prevSiteKeyRef = useRef<string | null>(null);

  const executeRecaptcha = useCallback(() => {
    if (window.grecaptcha?.enterprise?.ready) {
      window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise
          .execute(siteKey, { action })
          .then((result) => {
            setToken(result);
            setInitialLoad(false);
            setLoading(false);
          })
          .catch((error) => {
            console.error('Error executing reCAPTCHA:', error);
            setToken(null);
          });
      });
    } else {
      setLoading(false);
      console.error('reCAPTCHA script is not loaded correctly');
    }
  }, [action, siteKey]);

  const delayedExecuteRecaptcha = useCallback(
    (resolve: (value: void | PromiseLike<void>) => void) => {
      setTimeout(
        () => {
          window.grecaptcha.enterprise.ready(() => {
            try {
              executeRecaptcha();
            } catch (err) {
              console.log('there was an error executing recaptcha');
            }
            if (resolve) {
              resolve();
            }
          });
        },
        initialLoad ? 0 : SET_SITE_KEY_DELAY
      );
    },
    [executeRecaptcha, initialLoad]
  );

  const loadRecaptcha = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      setLoading(true);
      const existingScript = document.querySelector(
        `script[src="https://www.google.com/recaptcha/enterprise.js?render=${siteKey}"]`
      );

      if (!existingScript) {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/enterprise.js?render=${siteKey}`;
        script.async = true;
        script.defer = true;
        script.onload = () => {
          delayedExecuteRecaptcha(resolve);
        };
        script.onerror = (e) => {
          setLoading(false);
          reject(e);
        };
        document.head.appendChild(script);
      } else {
        delayedExecuteRecaptcha(resolve);
      }
    });
  }, [delayedExecuteRecaptcha, siteKey]);

  useEffect(() => {
    const loadAndExecuteRecaptcha = async () => {
      setLoading(true);
      const prevSiteKey = prevSiteKeyRef.current;

      if (prevSiteKey !== siteKey) {
        const oldScript = document.querySelector(
          `script[src="https://www.google.com/recaptcha/enterprise.js?render=${prevSiteKey}"]`
        );
        if (oldScript) {
          document.head.removeChild(oldScript);
        }
        prevSiteKeyRef.current = siteKey;
      }

      try {
        await loadRecaptcha();
        if (window.grecaptcha?.enterprise?.ready) {
          window.grecaptcha.enterprise.ready(executeRecaptcha);
        } else {
          console.error('reCAPTCHA script is not loaded correctly');
        }
      } catch (error) {
        console.error('Failed to load reCAPTCHA script', error);
      }
    };

    if (siteKey !== prevSiteKeyRef.current) {
      loadAndExecuteRecaptcha();
    }

    return () => {
      const currentSiteKey = prevSiteKeyRef.current || SITE_KEYS_DEFAULT;
      const existingScript = document.querySelector(
        `script[src="https://www.google.com/recaptcha/enterprise.js?render=${currentSiteKey}"]`
      );
      if (existingScript) {
        document.head.removeChild(existingScript);
      }
    };
  }, [siteKey, loadRecaptcha, executeRecaptcha]);

  const handleSetSiteKey = useCallback(
    (key: string) => {
      setSiteKey(key || SITE_KEYS_DEFAULT);
      if (callback) {
        callback(key || null);
      }
      setToken(null);
    },
    [callback]
  );

  return [
    loading,
    token,
    () => (
      <SiteKeyInput
        initialKey={siteKey === SITE_KEYS_DEFAULT ? '' : siteKey}
        handleSetSiteKey={handleSetSiteKey}
        loading={loading}
      />
    ),
  ];
};

export default useRecaptcha;
