import React, { useCallback } from 'react';

import useTheme from 'hooks/useTheme';
import useWhitelabel from 'hooks/useWhitelabel';
import { useHistory } from 'react-router-dom';
import useWorkspaceId from 'hooks/useWorkspaceId';
import { DefaultLogo, WhiteLabelLogo } from './styledComponents';

const Logo: React.FC<{
  clientPortal?: boolean;
  minimal?: boolean;
  phone?: boolean;
  forcedTheme?: 'light' | 'dark';
  full?: boolean;
  style?: React.CSSProperties;
  fullWidth?: number;
}> = ({
  clientPortal = false,
  minimal,
  phone,
  forcedTheme,
  full,
  style,
  fullWidth,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const workspaceId = useWorkspaceId();
  const defaultLightLogo =
    theme.logoFiles.light.logo || theme.minimalHeader.logo;
  const defaultDarkLogo = theme.logoFiles.dark.logo || theme.sidebar.logo;

  const result = useWhitelabel(defaultLightLogo, defaultDarkLogo, forcedTheme);
  const clickable = clientPortal || workspaceId;
  const { logo, favicon, whiteLabel } = result;

  const handleClick = useCallback(() => {
    if (clientPortal) {
      history.push(`/portal`);
    } else if (workspaceId) {
      history.push(`/workspaces/${workspaceId}`);
    }
  }, [clientPortal, history, workspaceId]);

  return (
    <div>
      {whiteLabel ? (
        <WhiteLabelLogo
          className={'white-label-logo'}
          onClick={clickable && handleClick}
          minimal={minimal}
          showFull={full}
          style={style}
          fullWidth={fullWidth}
        >
          <img src={favicon} />
          <img src={logo} />
        </WhiteLabelLogo>
      ) : (
        <DefaultLogo
          className={'default-logo'}
          onClick={clickable && handleClick}
          showFull={(minimal && !phone) || full}
          style={style}
          fullWidth={fullWidth}
        >
          <img src={favicon} />
          <img src={logo} />
        </DefaultLogo>
      )}
    </div>
  );
};

export default Logo;
