import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { FaAngleDown, FaAngleRight, FaLock } from 'react-icons/fa';
import { StyledCheckbox as Checkbox, ExcelIcon } from '../styledComponents';
import { useHistory } from 'react-router-dom';
import Dropdown from 'components/Dropdown';
import DropdownButton from 'components/DropdownButton';
import {
  ColoredDropdownButton,
  ManageMultiYearButton,
  OpenButton,
  Tag,
  Tags,
  TooltipContainer,
  ExcelButton,
  LockContainer,
  IconContainer,
} from './styledComponents';
import { showInfo, showWarning } from 'utils/popups';
import Tooltip from 'components/Tooltip';
import { gql, useMutation } from '@apollo/client';
import useCompanyId from 'hooks/useCompanyId';
import { STATUS, STATUS_ID_TO_NAME } from 'utils/constants';
import { updateBudget, updateCompanyDefaultsCache } from './cache';
import ClientPortalIcon from 'components/Icons/ClientPortalIcon';

const SET_STATUS = gql`
  mutation SetStatus($budgetId: Int!, $statusId: Int) {
    setStatus(tableId: $budgetId, statusId: $statusId) {
      id
      status
      statusHistory {
        id
        user {
          id
          firstName
          lastName
        }
        status
        createdAt
      }
    }
  }
`;

const SET_DEFAULTS = gql`
  mutation SetCompanyDefaults(
    $companyId: Int!
    $defaults: CompanyDefaultsInput!
  ) {
    setCompanyDefaults(companyId: $companyId, defaults: $defaults) {
      id
      defaultBudgetId
      defaultBudgetType
      defaultForecastId
      defaultForecastType
    }
  }
`;

const SET_LOCK = gql`
  mutation SetLock($budgetId: Int!, $locked: Boolean!) {
    setLock(tableId: $budgetId, locked: $locked) {
      success
      table {
        id
        locked
        viewOnly
      }
      error
    }
  }
`;

export const IconColumn = ({ row }) => {
  const component = useMemo(() => {
    if (row.canExpand || row.original.canExpand) {
      return <span>{row.isExpanded ? <FaAngleDown /> : <FaAngleRight />}</span>;
    } else if (
      row.original.locked ||
      row.original.inClientPortal ||
      row.original.subdivideEnabled
    ) {
      return (
        <IconContainer>
          {row.original.inClientPortal && (
            <Tooltip
              content={
                row.original.viewOnly
                  ? 'View Only in Client Portal'
                  : 'Editable in Client Portal'
              }
            >
              <ClientPortalIcon height={15} width={15} />
            </Tooltip>
          )}
          {row.original.subdivideEnabled && (
            <Tooltip content={'Subdivided'}>
              <i className="mdi-set mdi-filter" />
            </Tooltip>
          )}
          {row.original.locked && (
            <Tooltip content={'View Only'}>
              <LockContainer>
                <FaLock />
              </LockContainer>
            </Tooltip>
          )}
        </IconContainer>
      );
    } else {
      return null;
    }
  }, [
    row.canExpand,
    row.isExpanded,
    row.original.canExpand,
    row.original.inClientPortal,
    row.original.locked,
    row.original.subdivideEnabled,
    row.original.viewOnly,
  ]);
  return component;
};

export const SelectColumn = ({ row }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onChange, title, ...rest } = row.getToggleRowSelectedProps();
  const handleSelect = useCallback(
    (e) => {
      onChange(e);
      row.original.toggleSelectedRow();
    },
    [onChange, row.original]
  );

  return (
    <span>
      <Checkbox
        {...rest}
        onChange={handleSelect}
        disabled={!row.original.canSelect}
        title={row.original.disabledMessage || 'Toggle Budget'}
        onClick={(e) => e.stopPropagation()}
      />
    </span>
  );
};

export const LaunchColumn = ({
  row,
  onClickUpdateExcel,
  downloadingId,
  onClose,
  dispatch,
}) => {
  const history = useHistory();

  if (row.original.isGroupSubRow) {
    return null;
  }

  if (row.original.budgetsInGroup) {
    return (
      <ManageMultiYearButton
        onClick={() => dispatch({ type: 'GROUP', group: row.original })}
      >
        Manage
      </ManageMultiYearButton>
    );
  }

  if (row.original.isExcel) {
    return (
      <button
        onClick={() => onClickUpdateExcel(row.original.id)}
        disabled={row.original.pending || !!downloadingId}
      >
        {row.original.pending ? 'Creating...' : 'Update'}
      </button>
    );
  }

  return (
    <OpenButton
      color="primary"
      compact
      onClick={() => {
        history.push(`/table/${row.original.id}`);
        onClose();
      }}
      disabled={row.original.pending || !!downloadingId || false}
      loading={row.original.pending || false}
    >
      {row.original.pending ? 'Creating...' : 'Open'}
    </OpenButton>
  );
};

export const SettingsColumn = ({
  row,
  onRename,
  onDuplicate,
  onDelete,
  onDeleteBudgetGroup,
  type,
  onStartClientPortalFlow,
  defaultBudgetId,
  defaultBudgetType,
  defaultForecastId,
  defaultForecastType,
  currentUser,
  handleShowStatusHistoryModal,
}) => {
  const ref = useRef();
  const [offsetY, setOffsetY] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const companyId = useCompanyId();
  const [setStatus] = useMutation(SET_STATUS, {
    optimisticResponse: ({ budgetId, statusId }) => {
      return {
        setStatus: {
          __typename: 'Budget',
          id: budgetId,
          status: statusId ? STATUS_ID_TO_NAME[statusId] : null,
          statusHistory: [
            {
              id: -1,
              user: {
                id: currentUser.userId,
                firstName: currentUser.firstName,
                lastName: currentUser.lastName,
              },
              status: statusId ? STATUS_ID_TO_NAME[statusId] : null,
              createdAt: new Date(),
            },
            ...row.original.statusHistory,
          ],
        },
      };
    },
    update: (cache, result) => {
      return updateBudget(cache, companyId, result.data.setStatus.id, {
        status: result.data.setStatus.status,
      });
    },
  });

  const [setLock] = useMutation(SET_LOCK, {
    optimisticResponse: ({ budgetId, locked }) => {
      return {
        setLock: {
          success: true,
          table: { __typename: 'Budget', id: budgetId, locked, viewOnly: true },
          error: '',
        },
      };
    },
    update: (cache, result) => {
      if (result.data.setLock.success) {
        updateBudget(cache, companyId, result.data.setLock.table.id, {
          locked: result.data.setLock.table.locked,
          viewOnly: true,
        });
      }
    },
  });

  const [setDefaults] = useMutation(SET_DEFAULTS, {
    optimisticResponse: ({ defaults }) => {
      if (!defaults.defaultBudgetId) {
        defaults.defaultBudgetId = defaultBudgetId;
      }

      if (!defaults.defaultBudgetType) {
        defaults.defaultBudgetType = defaultBudgetType;
      }

      if (!defaults.defaultForecastId) {
        defaults.defaultForecastId = defaultForecastId;
      }

      if (!defaults.defaultForecastType) {
        defaults.defaultForecastType = defaultForecastType;
      }

      return {
        setCompanyDefaults: {
          __typename: 'Company',
          id: companyId,
          ...defaults,
        },
      };
    },
    update: (cache, result) => {
      if (result.data.setCompanyDefaults) {
        return updateCompanyDefaultsCache(
          cache,
          companyId,
          result.data.setCompanyDefaults
        );
      }
    },
  });

  useEffect(() => {
    if (ref.current && dropdownOpen) {
      const dropdown = ref.current;
      const dropdownRect = dropdown.getBoundingClientRect();

      const dropdownBottom = dropdownRect.bottom;

      const spaceBelow = window.innerHeight - dropdownBottom;

      if (spaceBelow < 0) {
        setOffsetY(spaceBelow - 40);
      }
    }
  }, [dropdownOpen, row]);

  const handleDelete = useCallback(() => {
    if (type === 'BUDGET') {
      if (row.original.isInGroup) {
        showInfo({
          text: 'Cannot delete budget that is part of a multi-year group.',
        });
        return;
      }
      onDelete(row.original.id);
    } else {
      onDeleteBudgetGroup(row.original);
    }
  }, [onDelete, onDeleteBudgetGroup, row.original, type]);

  const handleSetDefault = useCallback(
    async (defaultType) => {
      if (defaultType === 'budget') {
        await setDefaults({
          variables: {
            companyId,
            defaults: {
              defaultBudgetId: row.original.id,
              defaultBudgetType: type === 'BUDGET' ? 'COMPANY' : 'GROUP',
            },
          },
        });
      }

      if (defaultType === 'forecast') {
        await setDefaults({
          variables: {
            companyId,
            defaults: {
              defaultForecastId: row.original.id,
              defaultForecastType: type === 'BUDGET' ? 'COMPANY' : 'GROUP',
            },
          },
        });
      }
    },
    [companyId, row.original.id, setDefaults, type]
  );

  const handleSetStatus = useCallback(
    (status) =>
      setStatus({
        variables: {
          budgetId: row.original.id,
          statusId: status === 'CLEAR' ? null : STATUS[status],
        },
      }),
    [row.original.id, setStatus]
  );

  if (row.original.isGroupSubRow) {
    return null;
  }

  if (row.original.id < 0) {
    return null;
  }

  return (
    <Dropdown
      portal
      contentStyle={{ zIndex: 100000 }}
      offsetY={offsetY}
      offsetX={-75}
      verticalAlign="top"
      align="right"
      width={200}
      trigger={
        <button data-testid={`budget-settings-button-${row.index}`}>
          <i className="mdi-set mdi-cog" style={{ color: '#617182' }} />
        </button>
      }
    >
      {({ closeDropdown, open }) => {
        if (open !== dropdownOpen) {
          setDropdownOpen(open);
        }
        return (
          <div
            ref={ref}
            data-testid={open ? `budget-settings-dropdown` : undefined}
          >
            {type === 'BUDGET' && (
              <Dropdown
                portal
                contentStyle={{ zIndex: 100000 }}
                offsetY={-offsetY + 33}
                offsetX={-340}
                verticalAlign="top"
                align="right"
                width={135}
                trigger={<DropdownButton>Budget Status</DropdownButton>}
              >
                {({ closeDropdown: closeInnerDropdown }) => (
                  <div>
                    <DropdownButton
                      onClick={() => {
                        handleSetStatus('IN_PROGRESS');
                        closeInnerDropdown();
                        setTimeout(() => closeDropdown(), 100);
                      }}
                    >
                      In Progress
                    </DropdownButton>
                    <DropdownButton
                      onClick={() => {
                        handleSetStatus('NEEDS_REVIEW');
                        closeInnerDropdown();
                        setTimeout(() => closeDropdown(), 100);
                      }}
                    >
                      Needs Review
                    </DropdownButton>
                    <DropdownButton
                      onClick={() => {
                        handleSetStatus('COMPLETED');
                        closeInnerDropdown();
                        setTimeout(() => closeDropdown(), 100);
                      }}
                    >
                      Completed
                    </DropdownButton>
                    <DropdownButton
                      onClick={() => {
                        handleSetStatus('CLEAR');
                        closeInnerDropdown();
                        setTimeout(() => closeDropdown(), 100);
                      }}
                    >
                      Clear Status
                    </DropdownButton>
                    <DropdownButton
                      onClick={() => {
                        handleShowStatusHistoryModal(row.original.id);
                        closeDropdown();
                      }}
                    >
                      History
                    </DropdownButton>
                  </div>
                )}
              </Dropdown>
            )}
            {/* {type === 'BUDGET' && (
              <>
                <DropdownButton
                  onClick={() => {
                    handleShowStatusHistoryModal(row.original.id);
                    closeDropdown();
                  }}
                >
                  See Status History
                </DropdownButton>
              </>
            )} */}
            {type === 'BUDGET' && row.original.isAdvancedBudget && (
              <>
                <DropdownButton
                  onClick={() => {
                    onStartClientPortalFlow(row.original);
                    closeDropdown();
                  }}
                >
                  {row.original.inClientPortal
                    ? 'Remove/Manage Client Portal'
                    : 'Include in Client Portal'}
                </DropdownButton>
              </>
            )}
            {(type === 'BUDGET' || type === 'GROUP') && (
              <>
                {defaultBudgetId !== row.original.id && (
                  <ColoredDropdownButton
                    color="success"
                    onClick={() => {
                      handleSetDefault('budget');
                      closeDropdown();
                    }}
                  >
                    Set as Default Budget
                  </ColoredDropdownButton>
                )}
                {defaultForecastId !== row.original.id && (
                  <ColoredDropdownButton
                    color="info"
                    onClick={() => {
                      handleSetDefault('forecast');
                      closeDropdown();
                    }}
                  >
                    Set as Default Forecast
                  </ColoredDropdownButton>
                )}
              </>
            )}
            {type === 'BUDGET' && row.original.isAdvancedBudget && (
              <DropdownButton
                onClick={() => {
                  if (!row.original.viewOnly && !row.original.locked) {
                    showWarning({
                      title: 'Lock Budget?',
                      text: 'Locking this budget will set it to view only for workspace users and clients.',
                      confirmButtonText: 'Lock',
                      denyButtonText: 'Cancel',
                      showDenyButton: true,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        setLock({
                          variables: {
                            budgetId: row.original.id,
                            locked: !row.original.locked,
                          },
                        });
                      }
                    });
                  } else {
                    setLock({
                      variables: {
                        budgetId: row.original.id,
                        locked: !row.original.locked,
                      },
                    });
                  }
                  closeDropdown();
                }}
              >
                {row.original.locked ? 'Unlock' : 'Lock'}
              </DropdownButton>
            )}
            {type === 'BUDGET' && (
              <>
                <DropdownButton
                  onClick={() => {
                    onRename(row.original.id);
                    closeDropdown();
                  }}
                >
                  Rename
                </DropdownButton>
                {!row.original.pending && (
                  <DropdownButton
                    onClick={() => {
                      onDuplicate(row.original.id);
                      closeDropdown();
                    }}
                  >
                    Duplicate
                  </DropdownButton>
                )}
              </>
            )}

            <DropdownButton onClick={handleDelete}>Delete</DropdownButton>
          </div>
        );
      }}
    </Dropdown>
  );
};

export const ExcelColumn = ({ row, cell, downloadingId }) => {
  const { value } = cell;
  if (value) {
    let text = 'Download';
    if (downloadingId === row.original.id) {
      text = 'Downloading...';
    }
    return (
      <ExcelButton
        onClick={() =>
          row.original.handleDownloadExcel(row.original.id, row.original.name)
        }
        disabled={!!downloadingId}
      >
        <ExcelIcon /> {text}
      </ExcelButton>
    );
  }
  return null;
};

const getColor = (status) => {
  switch (status) {
    case 'In Progress':
      return 'progress';
    case 'Needs Review':
      return 'danger';
    case 'Completed':
      return 'success';
    default:
  }
};

export const DefaultColumn = ({ row, defaultBudgetId, defaultForecastId }) => {
  const { id } = row.original;
  if (id) {
    const isDefault = id === defaultBudgetId || id === defaultForecastId;
    let color;
    let content;

    if (isDefault) {
      if (id === defaultBudgetId && id === defaultForecastId) {
        color = 'progress';
        content = 'Default Budget and Forecast';
      } else if (id === defaultBudgetId) {
        color = 'success';
        content = 'Default Budget';
      } else if (id === defaultForecastId) {
        color = 'info';
        content = 'Default Forecast';
      }

      return (
        <Tooltip content={content} container={TooltipContainer}>
          <Tag color={color}>Default</Tag>
        </Tooltip>
      );
    }
  }

  return null;
};

export const TagsColumn = ({ row }) => {
  const tags = [];

  if (row.original.status) {
    if (row?.original?.statusHistory?.length > 0) {
      const date = new Date(row.original.statusHistory[0]?.createdAt);
      const content = `Set by: ${
        row.original.statusHistory[0].user?.firstName
      } ${
        row.original.statusHistory[0].user?.lastName
      } \n ${date.toLocaleString()}`;
      tags.push(
        <Tooltip content={content} container={TooltipContainer}>
          <Tag key={`${row.id}-status`} color={getColor(row.original.status)}>
            {row.original.status}
          </Tag>
        </Tooltip>
      );
    } else {
      tags.push(
        <Tag key={`${row.id}-status`} color={getColor(row.original.status)}>
          {row.original.status}
        </Tag>
      );
    }
  }

  return <Tags>{tags}</Tags>;
};
