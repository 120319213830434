/**
 *
 * List
 *
 */
import React, { useState, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import useCompanyId from 'hooks/useCompanyId';
import moment from 'moment';
import ExcelJS from 'exceljs';
import download from 'downloadjs';
import { captureException } from 'utils/sentry';

import { showConfirmDelete } from 'utils/popups';
import produce from 'immer';

import useSyncGoogleSheet from './GoogleComponents/useSyncGoogleSheet';
import useSyncAllGoogleSheets from './GoogleComponents/useSyncAllGoogleSheets';

import SpreadsheetList from './SpreadsheetList';

import { DEV, STAGING } from 'environment';
import Spinner from 'components/Spinner';

import {
  LEGACY_QUERY,
  RENAME_LEGACY_DATA_SHEET,
  DELETE_LEGACY,
  GOOGLE_SHEET_QUERY,
  RENAME_GOOGLE_SHEET,
  DELETE_GOOGLE_SHEET,
  DATASHEETS_QUERY,
  DELETE_DATASHEET,
  RENAME_DATA_SHEET,
  ALL_SHEETS_QUERY,
  DOWNLOAD_EXCEL_DATASHEET,
  SET_NIGHTLY_GOOGLE_SHEETS_SYNC,
} from './mutations';
import { clearDataPickerList } from 'utils/cache';

const List = (props) => {
  const { cache, dispatch, onClose, handleUpdateExcelDatasheet } = props;
  const companyId = useCompanyId();
  const history = useHistory();
  const location = useLocation();
  let allSheetsCombined = [];

  const {
    data: allSheetsData,
    loading: allSheetsLoading,
    error: allSheetsError,
  } = useQuery(ALL_SHEETS_QUERY, {
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  if (
    allSheetsData &&
    allSheetsData.company &&
    allSheetsData.company.spreadsheets &&
    allSheetsData.company.googleSpreadsheets &&
    allSheetsData.company.datasheets
  ) {
    allSheetsCombined = [
      ...allSheetsData.company.spreadsheets,
      ...allSheetsData.company.googleSpreadsheets,
      ...allSheetsData.company.datasheets,
    ];
  }

  // LEGACY SHEETS CODE
  const [setLegacyDatasheetName, { loading: legacySheetLoading }] = useMutation(
    RENAME_LEGACY_DATA_SHEET
  );

  const handleSetLegacyDatasheetName = useCallback(
    async (spreadsheetId, name) => {
      try {
        await setLegacyDatasheetName({
          variables: {
            spreadsheetId,
            name,
          },
          optimisticResponse: {
            __typename: 'Mutation',
            setSpreadsheetName: {
              __typename: 'Spreadsheet',
              id: spreadsheetId,
              name,
              lastModified: moment.utc().format(),
            },
          },
        });
      } catch (err) {
        captureException(err, true);
      }
    },
    [setLegacyDatasheetName]
  );

  const handleOpenLegacyDatasheet = useCallback(
    (id) => {
      history.push(`/spreadsheet/${id}`);
      onClose();
    },
    [onClose, history]
  );

  const [deleteLegacyDatasheet] = useMutation(DELETE_LEGACY);

  const handleDeleteLegacyDatasheet = useCallback(
    async (spreadsheetId) => {
      if (!(await showConfirmDelete())) return;

      deleteLegacyDatasheet({
        variables: {
          spreadsheetId,
        },
        update: (cache) => {
          const cacheData = cache.readQuery({
            query: LEGACY_QUERY,
            variables: {
              companyId,
            },
          });

          cache.writeQuery({
            query: LEGACY_QUERY,
            variables: {
              companyId,
            },
            data: produce(cacheData, (draft) => {
              draft.company.spreadsheets = draft.company.spreadsheets.filter(
                (item) => item.id !== spreadsheetId
              );
            }),
          });

          if (location.pathname === `/spreadsheet/${spreadsheetId}`) {
            history.push('/');
          }
        },
      });
    },
    [companyId, deleteLegacyDatasheet, history, location.pathname]
  );
  // LEGACY SHEETS CODE END

  // GOOGLE SHEETS CODE
  const [buttonLoadingId, setButtonLoadingId] = useState(null);
  const [syncAllLoading, setSyncAllLoading] = useState(null);

  const { onSync: handleSync } = useSyncGoogleSheet({ dispatch });
  const { onSyncAll: handleSyncAll } = useSyncAllGoogleSheets();

  const onSync = useCallback(
    async (googleSpreadsheetId) => {
      setButtonLoadingId(googleSpreadsheetId);
      await handleSync(googleSpreadsheetId);
      setButtonLoadingId(null);
      clearDataPickerList(cache, companyId);
    },
    [cache, companyId, handleSync]
  );

  const onSyncAll = useCallback(
    async (spreadsheets) => {
      setSyncAllLoading(true);
      await handleSyncAll(spreadsheets);
      clearDataPickerList(cache, companyId);
      setSyncAllLoading(null);
    },
    [cache, companyId, handleSyncAll]
  );

  const [setGoogleSheetName, { loading: googleSheetLoading }] =
    useMutation(RENAME_GOOGLE_SHEET);

  const handleSetGoogleSheetName = useCallback(
    async (googleSpreadsheetId, name) => {
      try {
        await setGoogleSheetName({
          variables: {
            googleSpreadsheetId,
            name,
          },
          optimisticResponse: {
            __typename: 'Mutation',
            setGoogleSpreadsheetName: {
              __typename: 'GoogleSpreadsheet',
              id: googleSpreadsheetId,
              name,
              lastModified: moment.utc().format(),
              externalId: null,
            },
          },
        });

        clearDataPickerList(cache, companyId);
      } catch (err) {
        captureException(err, true);
      }
    },
    [cache, companyId, setGoogleSheetName]
  );

  const [deleteGoogleSheet] = useMutation(DELETE_GOOGLE_SHEET);

  const handleDeleteGoogleSheet = useCallback(
    async (googleSpreadsheetId) => {
      if (!(await showConfirmDelete())) return;

      deleteGoogleSheet({
        variables: {
          googleSpreadsheetId,
        },
        update: (cache) => {
          const _data = cache.readQuery({
            query: GOOGLE_SHEET_QUERY,
            variables: {
              companyId,
            },
          });

          cache.writeQuery({
            query: GOOGLE_SHEET_QUERY,
            variables: {
              companyId,
            },
            data: produce(_data, (draft) => {
              draft.company.googleSpreadsheets =
                draft.company.googleSpreadsheets.filter(
                  (item) => item.id !== googleSpreadsheetId
                );
            }),
          });

          clearDataPickerList(cache, companyId);
        },
      });
    },
    [companyId, deleteGoogleSheet]
  );

  const [setNightlyGoogleSheetsSync] = useMutation(
    SET_NIGHTLY_GOOGLE_SHEETS_SYNC
  );

  const handleToggleNightlyGoogleSheetSync = useCallback(() => {
    setNightlyGoogleSheetsSync({
      variables: {
        companyId,
        nightlyGoogleSheetSync: !allSheetsData.company.nightlyGoogleSheetSync,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        setNightlyGoogleSheetsSync: {
          __typename: 'Company',
          id: companyId,
          nightlyGoogleSheetSync: !allSheetsData.company.nightlyGoogleSheetSync,
        },
      },
      update: (cache) => {
        cache.writeQuery({
          query: ALL_SHEETS_QUERY,
          variables: {
            companyId,
          },
          data: produce(allSheetsData, (draft) => {
            draft.company.nightlyGoogleSheetSync =
              !allSheetsData.company.nightlyGoogleSheetSync;
          }),
        });
      },
    });
  }, [companyId, setNightlyGoogleSheetsSync, allSheetsData]);

  // GOOGLE SHEETS CODE END

  // NEW DATASHEETS CODE
  const [setNewDatasheetName, { loading: newDatasheetLoading }] =
    useMutation(RENAME_DATA_SHEET);

  const handleSetNewDatasheetName = useCallback(
    async (tableId, name) => {
      try {
        await setNewDatasheetName({
          variables: {
            tableId,
            name,
          },
          optimisticResponse: {
            __typename: 'Mutation',
            renameTable: {
              __typename: 'Table',
              id: tableId,
              name,
              lastUpdated: moment.utc().format(),
            },
          },
        });

        clearDataPickerList(cache, companyId);
      } catch (err) {
        captureException(err, true);
      }
    },
    [cache, companyId, setNewDatasheetName]
  );

  const [deleteNewDatasheet] = useMutation(DELETE_DATASHEET);

  const handleDeleteNewDatasheet = useCallback(
    async (tableId) => {
      if (!(await showConfirmDelete())) return;

      deleteNewDatasheet({
        variables: {
          tableId,
        },
        update: (cache) => {
          const cacheData = cache.readQuery({
            query: DATASHEETS_QUERY,
            variables: {
              companyId,
            },
          });

          cache.writeQuery({
            query: DATASHEETS_QUERY,
            variables: {
              companyId,
            },
            data: produce(cacheData, (draft) => {
              draft.company.datasheets = draft.company.datasheets.filter(
                (item) => item.id !== tableId
              );
            }),
          });

          clearDataPickerList(cache, companyId);

          if (location.pathname === `/datasheet/${tableId}`) {
            history.push('/');
          }
        },
      });
    },
    [cache, companyId, deleteNewDatasheet, history, location.pathname]
  );

  const handleOpenNewDatasheet = useCallback(
    (id) => {
      history.push(`/datasheet/${id}`);
      onClose();
    },
    [onClose, history]
  );
  // NEW DATASHEETS CODE END

  // EXCEL SHEETS CODE
  const [downloadExcelDatasheet] = useMutation(DOWNLOAD_EXCEL_DATASHEET);

  const handleDownloadExcelDatasheet = useCallback(
    async (googleSpreadsheetId) => {
      const excelDownload = await downloadExcelDatasheet({
        variables: {
          googleSpreadsheetId,
        },
      });
      const excelWorkbookName =
        excelDownload.data.downloadExcelDatasheet.excelWorkbookName;
      const excelSheetName =
        excelDownload.data.downloadExcelDatasheet.excelSheetName;
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(excelSheetName || 'Sheet1');
      worksheet.addRows(
        excelDownload.data.downloadExcelDatasheet.excelSheetRows
      );
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer]);
      await download(
        blob,
        `${excelWorkbookName || 'Workbook1'}`,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      );
    },
    [downloadExcelDatasheet]
  );

  // EXCEL SHEETS CODE END

  if (
    allSheetsLoading ||
    allSheetsError ||
    !allSheetsData ||
    !allSheetsData.company
  ) {
    return <Spinner />;
  }

  return (
    <SpreadsheetList
      spreadsheets={allSheetsCombined}
      buttonLabelLegacyDatasheet="Open"
      buttonLabelGoogleSheet="Sync"
      buttonLabelNewDatasheet="Open"
      syncAllButtonLabelGoogleSheet="Sync All"
      deleteButtonLabelLegacyDatasheet="Delete Legacy Sheet"
      deleteButtonLabelGoogleSheet="Disconnect"
      deleteButtonLabelNewDatasheet="Delete Data Sheet"
      addLabelLegacyDatasheet={DEV || STAGING ? 'Create' : null}
      addLabelGoogleSheet="Connect Google Sheet / Manage Accounts"
      addLabelNewDatasheet="Create"
      updateLabelExcel="Update"
      onDeleteLegacyDatasheet={handleDeleteLegacyDatasheet}
      onDeleteGoogleSheet={handleDeleteGoogleSheet}
      onDeleteNewDatasheet={handleDeleteNewDatasheet}
      onRenameLegacyDatasheet={handleSetLegacyDatasheetName}
      onRenameGoogleSheet={handleSetGoogleSheetName}
      onRenameNewDatasheet={handleSetNewDatasheetName}
      onButtonClickedLegacyDatasheet={handleOpenLegacyDatasheet}
      onButtonClickedGoogleSheet={onSync}
      onButtonClickedNewDatasheet={handleOpenNewDatasheet}
      onDownloadClickedExcelDatasheet={handleDownloadExcelDatasheet}
      onToggleNightlyGoogleSheetSync={handleToggleNightlyGoogleSheetSync}
      onUpdateClickedExcelDatasheet={handleUpdateExcelDatasheet}
      buttonLoadingIdGoogleSheet={buttonLoadingId}
      onSyncAllButtonClickedGoogleSheet={onSyncAll}
      displaySyncAllGoogleSheetsButton={
        !!allSheetsData.company.googleSpreadsheets.length
      }
      syncAllLoadingGoogleSheet={syncAllLoading}
      nightlyGoogleSheetSync={allSheetsData.company.nightlyGoogleSheetSync}
      showLibraryNewDatasheet
      dateAccessorGoogleSheet="lastModified"
      dateAccessorLegacyDatasheet="lastModified"
      dateAccessorNewDatasheet="lastUpdated"
      type="combinedDatasheets"
      renameLoading={
        legacySheetLoading || googleSheetLoading || newDatasheetLoading
      }
    />
  );
};

export default List;
