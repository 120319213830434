/**
 *
 * BudgetReferenceSelect
 *
 */
import React, { useMemo } from 'react';
import useBudgets from 'hooks/useBudgets';
import moment from 'moment';

import { getDates } from 'components/BudgetsModal/utils';
import { RangeType } from 'types/ranges';
import Select from 'components/Select';

interface BudgetReferenceSelectProps {
  companyId: number;
  budgetId: number;
  fiscalYearMonth: number;
  range: string;
  value: any;
  twoYearsPrior: boolean;
  testId?: string;
  portal?: boolean;
  onChange: (value: any) => void;
}

const BudgetReferenceSelect: React.FunctionComponent<BudgetReferenceSelectProps> =
  (props) => {
    const {
      companyId,
      budgetId,
      fiscalYearMonth,
      range,
      value,
      twoYearsPrior,
      onChange,
      testId,
      portal,
    } = props;

    const budgets = useBudgets(companyId);

    const advancedBudgets = useMemo(
      () =>
        budgets?.companyBudgets?.filter(
          (budget) => budget.isAdvancedBudget && budget.datasheet
        ) || [],
      [budgets?.companyBudgets]
    );

    const selectOptions = useMemo(() => {
      const options = [
        {
          label: 'Actuals',
          value: -1,
        },
      ];

      if (range) {
        advancedBudgets.forEach((budget) => {
          const budgetStartDate = budget.datasheet.startDate;
          const budgetEndDate = budget.datasheet.endDate;
          const { startDate, endDate } = getDates(
            range as RangeType,
            fiscalYearMonth
          );

          const isPriorYear =
            startDate.isSame(
              moment.utc(budgetStartDate).add(1, 'year'),
              'month'
            ) &&
            endDate.isSame(moment.utc(budgetEndDate).add(1, 'year'), 'month');
          const isTwoYearsPrior =
            startDate.isSame(
              moment.utc(budgetStartDate).add(2, 'year'),
              'month'
            ) &&
            endDate.isSame(moment.utc(budgetEndDate).add(2, 'year'), 'month');

          if (!twoYearsPrior && isPriorYear && budget.id !== budgetId) {
            options.push({
              label: budget.name,
              value: budget.id,
            });
          }

          if (twoYearsPrior && isTwoYearsPrior && budget.id !== budgetId) {
            options.push({
              label: budget.name,
              value: budget.id,
            });
          }
        });
      }

      return options;
    }, [advancedBudgets, budgetId, fiscalYearMonth, range, twoYearsPrior]);

    return (
      <Select
        options={selectOptions}
        onChange={onChange}
        value={value}
        testId={testId}
        portal={portal}
      />
    );
  };

export default BudgetReferenceSelect;
