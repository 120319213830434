import styled, { css } from 'styled-components';

export const List = styled.div`
  display: inline-flex;
  flex-direction: column;
`;

export const Title = styled.div`
  margin-bottom: 20px;
`;

export const Container = styled.button.attrs({ type: 'button' })<{
  selected: boolean;
}>`
  width: 100%;
  text-align: left;
  border-radius: 50px;
  margin-bottom: 5px;
  white-space: nowrap;
  color: #617182;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    background-color: #f5f5f5;
  }
  ${({ selected, theme }) =>
    selected &&
    css`
      &,
      &:hover {
        color: ${theme.colors.primary};
        background-color: #e7f1fd;
      }
    `}
`;

export const Icon = styled.i<{ iconColor: string | null }>`
  color: ${({ iconColor, theme }) =>
    iconColor ? theme.colors[iconColor] : theme.colors.icon}!important;
  margin-left: 1rem;
`;
