/**
 *
 * Usetiful
 *
 */
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import { useEffect, useState } from 'react';

const QUERY = gql`
  query Usetiful {
    currentUser {
      email
      plan
    }
  }
`;

const Usetiful = () => {
  const isLoggedIn = useIsLoggedIn();
  const [scriptAdded, setScriptAdded] = useState(false);

  const { data } = useQuery(QUERY, {
    skip: !isLoggedIn,
  });

  const user = data && data.currentUser;

  useEffect(() => {
    if (!user || !user.email || scriptAdded) return;
    window.usetifulTags = {
      email: user.email,
      plan: user.plan,
    };
    const a = document.getElementsByTagName('head')[0];
    const r = document.createElement('script');
    r.async = 1;
    r.src = 'https://www.usetiful.com/dist/usetiful.js';
    r.setAttribute('id', 'usetifulScript');
    r.dataset.token = '986ee050da4ebbfdb26632841969fbd6';
    a.appendChild(r);
    setScriptAdded(true);
  }, [scriptAdded, user]);

  return null;
};

export default Usetiful;
