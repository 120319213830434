import fileReaderStream from 'filereader-stream';

/**
 * Check if a PNG file is valid.
 * @param {File} file - The PNG file to check.
 * @param {function} onNext - The function to call if the PNG file is valid.
 * @param {function} onError - The function to call if the PNG file is invalid.
 */
export const checkPng = (file, onNext, onError) => {
  // Check PNG file signature
  const pngSignature = Buffer.from([137, 80, 78, 71, 13, 10, 26, 10]);
  const fileSignatureReader = fileReaderStream(file, { start: 0, end: 7 });

  // Initialize the file signature buffer and the offset variable
  const fileSignature = Buffer.alloc(8);
  let offset = 0;

  // Read the file signature into the file signature buffer
  fileSignatureReader.on('data', (chunk) => {
    chunk.copy(fileSignature, offset);
    offset += chunk.length;
  });

  // Handle any errors that occur while reading the file signature
  fileSignatureReader.on('error', (err) => {
    onError({
      title: 'Error reading file',
      message: err.message,
    });
  });

  // Check the file signature once it has been fully read
  fileSignatureReader.on('end', () => {
    if (!pngSignature.equals(fileSignature)) {
      // If the file signature is not valid, call the onError function
      onError({
        title: 'Invalid PNG file',
        message: 'Invalid file signature',
      });
    } else {
      // If the file signature is valid, continue checking the file
      const reader = fileReaderStream(file, { start: 8 });

      // Handle any errors that occur while reading the file
      reader.on('error', (err) => {
        onError({
          title: 'Error reading file',
          message: err.message,
        });
      });

      let isFinished = false;
      // Check the file header for palette and bit depth information
      reader.on('readable', () => {
        const chunk = reader.read();
        if (chunk && !isFinished) {
          const header = chunk.slice(0, 8);
          const isPalette = header[24] & 1;
          const bitDepth = header[16];
          if (isPalette && bitDepth < 8) {
            // If the file has a palette and a bit depth less than 8, call the onError function
            onError({
              title: 'Invalid PNG file',
              message: 'Palette and bit depth less than 8',
            });
          } else {
            // If the file is valid, call the onNext function
            onNext(file);
          }
          isFinished = true;
        }
      });
    }
  });
};
