/* global screen */

import Swal from 'swal';
import SweetAlert, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import theme from 'themes/quantum';
import aiIcon from 'images/ai-icon.png';
import './popup.css';

export const launchPopup = (
  path: string | URL,
  { width = 800, height = 650 } = {}
): void => {
  let parameters = `location=1,width=${width},height=${height}`;
  parameters +=
    ',left=' + (screen.width - 800) / 2 + ',top=' + (screen.height - 650) / 2;
  // Launch Popup
  window.open(path, 'connectPopup', parameters);
};

export const showError = ({
  title = 'Oops...',
  text = 'Something went wrong!',
  icon = 'error',
}: SweetAlertOptions = {}): Promise<SweetAlertResult<any>> => {
  const errorEventForCypressTests = new CustomEvent('app:error', { detail: { title, text } });
  window.dispatchEvent(errorEventForCypressTests);
  return Swal.fire({
    title,
    text,
    icon,
    allowOutsideClick: false,
    customClass: {
      container: 'custom-position-container',
      popup: 'custom-position-popup',
    },
  });
};

export const showWarning = ({
  title = undefined,
  text = undefined,
  html = undefined,
  allowOutsideClick = true,
  allowEscapeKey = true,
  showConfirmButton = true,
  showDenyButton = false,
  confirmButtonText = 'OK',
  denyButtonText = 'Cancel',
}: SweetAlertOptions = {}): Promise<SweetAlertResult<any>> => {
  return Swal.fire({
    title,
    text,
    html,
    icon: 'warning',
    allowOutsideClick,
    allowEscapeKey,
    showConfirmButton,
    showDenyButton,
    confirmButtonText,
    denyButtonText,
    denyButtonColor: '#FF865E',
    customClass: {
      container: 'custom-position-container',
      popup: 'custom-position-popup',
    },
  });
};

export const showSyncAllSheetsWarning = ({
  title = undefined,
  text = undefined,
  html = undefined,
  allowOutsideClick = true,
  allowEscapeKey = true,
  showConfirmButton = true,
  showDenyButton = false,
  confirmButtonText = 'OK',
  denyButtonText = 'Cancel',
}: SweetAlertOptions = {}): Promise<SweetAlertResult<any>> => {
  return Swal.fire({
    title,
    text,
    html,
    icon: 'warning',
    allowOutsideClick,
    allowEscapeKey,
    showConfirmButton,
    showDenyButton,
    confirmButtonText,
    denyButtonText,
    denyButtonColor: '#FF865E',
    customClass: {
      container: 'custom-position-container',
      popup: 'custom-position-popup',
    },
  });
};

export const showConfirm = async ({
  title, // Possibly turn into a promise. Result isn't getting returned properly
  text,
  confirmButtonText = 'Yes',
  cancelButtonText = 'No',
  confirmButtonColor,
  cancelButtonColor,
  ...rest
}: SweetAlertOptions = {}): Promise<boolean> => {
  const result = await Swal.fire({
    title,
    text,
    confirmButtonText,
    cancelButtonText,
    showCancelButton: true,
    confirmButtonColor: confirmButtonColor || theme.colors.success,
    cancelButtonColor: cancelButtonColor || theme.colors.danger,
    icon: 'info',
    customClass: {
      container: 'custom-position-container',
      popup: 'custom-position-popup',
    },
    ...rest,
  });
  return result.isConfirmed;
};

export const showConfirmDelete = async (
  options: Record<string, string> = {}
): Promise<boolean> => {
  const result = await Swal.fire({
    title: options?.title || 'Are you sure?',
    icon: 'warning',
    text: options?.text || "You won't be able to revert this!",
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'Cancel',
    reverseButtons: true,
    customClass: {
      container: 'custom-position-container',
      popup: 'custom-position-popup',
    },
  });
  return result.isConfirmed;
};

export const showUnsavedChanges = async (): Promise<boolean | null> => {
  const result = await Swal.fire({
    title: 'Unsaved Changes',
    text: 'Save your changes before leaving?',
    showConfirmButton: true,
    showCloseButton: true,
    showDenyButton: true,
    confirmButtonText: 'Save and Close',
    denyButtonText: `Leave without Saving`,
    confirmButtonColor: '#22BAA0',
    denyButtonColor: '#617182',
    icon: 'warning',
    customClass: {
      container: 'custom-position-container',
      popup: 'custom-position-popup',
    },
  });
  if (result.isConfirmed) {
    return true;
  } else if (result.isDenied) {
    return false;
  } else {
    return null;
  }
};

export const showInfo = ({
  title,
  text,
  ...rest
}: SweetAlertOptions = {}): Promise<SweetAlertResult<any>> => {
  return Swal.fire({
    icon: 'info',
    title,
    text,
    customClass: {
      container: 'custom-position-container',
      popup: 'custom-position-popup',
    },
    ...rest,
  });
};

export const showSuccess = ({
  title,
  text,
  ...rest
}: SweetAlertOptions = {}): Promise<SweetAlertResult<any>> => {
  return Swal.fire({
    icon: 'success',
    title: title || 'Success',
    text,
    customClass: {
      container: 'custom-position-container',
      popup: 'custom-position-popup',
    },
    ...rest,
  });
};

export const notificationToast = ({
  title = '',
  titleClass,
  target = '',
  position = 'center',
  timer,
  stopOnHover = false,
  fixed = false,
  icon = 'success',
  ...rest
}: SweetAlertOptions & {
  fixed?: boolean;
  stopOnHover?: boolean;
  titleClass?: string;
  type?: string;
}): Promise<SweetAlertResult<any>> => {
  const options: SweetAlertOptions = {
    toast: true,
    title,
    icon,
    showConfirmButton: false,
    customClass: {
      container: fixed ? 'custom-fixed-toast' : 'custom-absolute-toast',
      popup: 'custom-shadow-toast',
    },
    ...rest,
  };

  if (titleClass && options.customClass) {
    options.customClass.title = titleClass;
  }

  if (stopOnHover) {
    options.didOpen = (toast) => {
      toast.addEventListener('mouseenter', SweetAlert.stopTimer);
      toast.addEventListener('mouseleave', SweetAlert.resumeTimer);
    };
    options.willClose = (toast) => {
      toast.removeEventListener('mouseenter', SweetAlert.stopTimer);
      toast.removeEventListener('mouseleave', SweetAlert.resumeTimer);
    };
  }

  if (timer) {
    options.timer = timer;
  }

  if (target) {
    options.target = target;
  }

  if (position) {
    options.position = position;
  }

  return SweetAlert.fire(options);
};

export function showEnableAiPopup() {
  return Swal.fire({
    width: 600,
    iconHtml: `<img src="${aiIcon}" style="width: 59px; height: auto;">`,
    title: 'Getting started with AI Enhanced Reporting',
    confirmButtonColor: '#52badb',
    denyButtonColor: '#52badb',
    confirmButtonText: 'Enable',
    cancelButtonText: 'Cancel',
    cancelButtonColor: 'transparent',
    denyButtonText: 'Enable for All Companies',
    showCancelButton: true,
    showDenyButton: true,
    html:
      '<div style="font-size:16px;margin-bottom:1rem;">After turning on, navigate to the company settings of each company to enable AI Enhanced Reporting, or choose to enable for all companies.</div>' +
      '<div style="font-size:16px;margin-bottom:1rem;">Currently during BETA (until January 1, 2026) this feature will be free.</div>',
    icon: undefined,
    customClass: {
      container: 'custom-position-container',
      popup: 'ai-popup',
    },
  });
}
