/**
 *
 * useConsolidationCompanies
 *
 */
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { get } from 'lodash';
import { useMemo } from 'react';
import { Company } from '../interfaces/company';

// Add to this query as needed for more information on consolidated companies
const GET_CONSOLIDATION_COMPANIES = gql`
  query consolidationCompanies($companyId: Int!) {
    company(id: $companyId) {
      id
      consolidationCompanies {
        id
        name
        budgets {
          id
          name
        }
        accounting {
          budgets {
            id
            name
          }
        }
      }
    }
  }
`;

const useConsolidationCompanies = (companyId: number): Array<Company> => {
  const { data } = useQuery(GET_CONSOLIDATION_COMPANIES, {
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  return useMemo(() => {
    if (!data) {
      return null;
    }

    return get(data, 'company.consolidationCompanies', []);
  }, [data]);
};

export default useConsolidationCompanies;
