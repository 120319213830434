const countries = require('./countries.json');

module.exports.countries = countries;

module.exports.formatCurrency = function(value, countryCode, precision) {
  return new Intl.NumberFormat(countries[countryCode].locale, {
    style: 'currency',
    currency: countries[countryCode].currency,
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  }).format(value);
};
