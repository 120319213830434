import React, { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { showConfirm, showError, showInfo } from 'utils/popups';
import TimeAgo from 'react-timeago';
import moment from 'moment';
import { connectionLost } from 'utils/connections';

import CreateCsvCompany from 'components/CreateCsvCompany';
import useAccountingIntegrationId from 'hooks/useAccountingIntegrationId';
import useCompanyId from 'hooks/useCompanyId';
import useGroupId from 'hooks/useGroupId';
import useTheme from 'hooks/useTheme';
import useWorkspaceId from 'hooks/useWorkspaceId';

import OutsideClickDetector from 'hooks/useOutsideDetector';
import useIntegrations from 'hooks/useIntegrations';
import {
  integrationIdToName,
  integrationToId,
  integrationToName,
} from 'components/IntegrationLogos/util';
import { useHistory } from 'react-router';

import SyncIcon from 'components/Icons/SyncIcon';
import {
  Container,
  Text,
  Button,
  Icon,
  IconContainer,
  Progress,
  Info,
  SubMenu,
  SubMenuButton,
  SyncLimit,
} from './styledComponents';

import { QUERY, SYNC, SYNCED, SYNC_JOB } from './queries';
import { getUtcOffset } from 'utils/timezone';
import { Workspace } from 'interfaces/workspace';
import { Company, SyncTimestamp } from 'interfaces/company';
import Tooltip from 'components/Tooltip';

const bypassSyncLimit = (integration: string) => {
  if (integration === 'CSV') return true;
  if (integration === 'QBD') return true;
  return false;
};

const determineOldestSyncTimestamp = (
  company: Company,
  integration: number
) => {
  if (company.isConsolidation) {
    return company.consolidationCompanies.reduce(
      (acc, consolidationCompany) => {
        const consolidationSyncTimestamps =
          consolidationCompany.syncTimestamps.filter(
            (syncTimestamp) =>
              integrationIdToName[syncTimestamp.integration] === integration
          );
        if (consolidationSyncTimestamps.length) {
          const consolidationOldestSyncTimestamp =
            consolidationSyncTimestamps.reduce(
              (_acc, syncTimestamp) =>
                moment.utc(syncTimestamp.timestamp).isBefore(_acc.timestamp)
                  ? syncTimestamp
                  : _acc,
              {
                integration: 0,
                timestamp: moment.utc().format(),
              }
            );
          return moment
            .utc(consolidationOldestSyncTimestamp.timestamp)
            .isBefore(acc.timestamp)
            ? consolidationOldestSyncTimestamp
            : acc;
        }
        return acc;
      },
      {
        integration: 0,
        timestamp: moment.utc().format(),
      }
    );
  } else {
    return company.syncTimestamps
      .filter(
        (syncTimestamp) =>
          integrationIdToName[syncTimestamp.integration] === integration
      )
      .reduce(
        (acc, syncTimestamp) =>
          moment.utc(syncTimestamp.timestamp).isBefore(acc.timestamp)
            ? syncTimestamp
            : acc,
        {
          integration: 0,
          timestamp: moment.utc().format(),
        }
      );
  }
};

const LastSynced: React.FC = () => {
  const theme = useTheme();
  const groupId = useGroupId();
  const companyId = useCompanyId();
  const workspaceId = useWorkspaceId();
  const accountingIntegration = useAccountingIntegrationId();
  const integrations = useIntegrations();
  const history = useHistory();
  const [showSubMenu, setShowSubMenu] = useState<boolean>(false);
  const [mouseEntered, setMouseEntered] = useState<boolean>(false);
  const [showUpdateCsv, setShowUpdateCsv] = useState<boolean>(false);

  const { data, loading, error, refetch, startPolling, stopPolling } =
    useQuery<{
      workspace: Workspace;
      company: Company;
    }>(QUERY, {
      variables: {
        companyId,
        workspaceId,
      },
      skip: !companyId || !workspaceId,
    });

  const isConsolidation = data?.company?.isConsolidation;

  const onCloseCsv = useCallback(() => {
    setShowUpdateCsv(false);
  }, []);

  const showRefreshPopup = useCallback(
    () =>
      showConfirm({
        title: 'New Data Available',
        text: 'Would you like to refresh?',
        confirmButtonColor: theme.colors.success,
        cancelButtonColor: theme.colors.danger,
      }).then((result) => {
        if (result) {
          window.location.reload();
        }
      }),
    [theme.colors.danger, theme.colors.success]
  );

  const showPopup = useCallback(
    (unsyncedCompanies?: string[]) => {
      if (unsyncedCompanies?.length) {
        showInfo({
          title: 'Unsynced Companies',
          text: `The following company/companies were not synced due to reaching the sync limit: ${unsyncedCompanies.join(
            ', '
          )}`,
        }).then(() => {
          showRefreshPopup();
        });
      } else {
        showRefreshPopup();
      }
    },
    [showRefreshPopup]
  );

  const showSingularSyncLimitReached = useCallback(
    (oldestSyncTimestamp: { integration: number; timestamp: string }) =>
      showInfo({
        title: 'Sync Limit Reached',
        text: `You have reached your sync limit. The next available time to sync is ${moment(
          oldestSyncTimestamp.timestamp
        )
          .add(1, 'days')
          .format('MMMM Do, h:mm a')}.`,
      }),
    []
  );

  useSubscription(SYNC_JOB, {
    variables: {
      companyId,
    },
  });

  useSubscription(SYNCED, {
    variables: {
      companyId,
    },
    skip: !companyId,
    onData: ({
      data: {
        data: { syncJobFinished },
      },
    }) => {
      stopPolling();
      refetch();

      if (syncJobFinished.error) {
        showError({
          title: undefined,
          text: syncJobFinished.error,
          icon: undefined,
        });
        return;
      }

      showPopup(syncJobFinished.unsyncedCompanies);
    },
  });

  const [performSync] = useMutation(SYNC);

  const remainingSyncs = useMemo(() => {
    if (!data?.workspace || !integrations) return {};

    const { syncLimit } = data.workspace;

    // Initialize with default sync limits
    const initialRemainingSyncs: Record<string, number | string> = {};
    (integrations || []).forEach((integration) => {
      initialRemainingSyncs[integration] =
        syncLimit === null || bypassSyncLimit(integration) ? '∞' : syncLimit;
    });

    // If no timestamps are available, return initial
    if (!data.company.syncTimestamps) return initialRemainingSyncs;

    // Reduce to calculate remaining syncs
    const _remainingSyncs = data.company.syncTimestamps.reduce(
      (acc: Record<string, number | string>, syncTimestamp: SyncTimestamp) => {
        const integration = integrationIdToName[syncTimestamp.integration];

        if (syncLimit === null) {
          acc[integration] = '∞';
          return acc;
        }
        // Special cases: 'CSV' and 'QBD' have infinite syncs
        if (bypassSyncLimit(integration)) {
          acc[integration] = '∞';
          return acc;
        }

        // Standard cases: Decrease sync count
        acc[integration] = (acc[integration] as number) - 1;
        return acc;
      },
      initialRemainingSyncs // start with initial counts
    );

    return _remainingSyncs;
  }, [data, integrations]);

  const maxedConsolidationSyncsUsed = useMemo(() => {
    const initialMaxedSyncs: Record<string, boolean> = {};
    (integrations || []).forEach((integration) => {
      initialMaxedSyncs[integrationToId[integration]] = false;
    });

    if (data?.company?.consolidationCompanies.length && integrations) {
      const maxedSyncs = integrations.reduce(
        (acc: Record<string, boolean>, integration: string) => {
          const integrationId = integrationToId[integration];
          const companyMaxedSyncs: boolean[] = [];
          data.company.consolidationCompanies.forEach(
            (consolidationCompany) => {
              if (consolidationCompany.syncTimestamps) {
                const consolidationSyncTimestamps =
                  consolidationCompany.syncTimestamps.filter(
                    (syncTimestamp) =>
                      syncTimestamp.integration === integrationId
                  );

                if (consolidationSyncTimestamps.length) {
                  const syncLimit = data.workspace.syncLimit;
                  const _maxedSyncs =
                    syncLimit === null
                      ? false
                      : consolidationSyncTimestamps.length >= syncLimit;
                  companyMaxedSyncs.push(_maxedSyncs);
                } else {
                  companyMaxedSyncs.push(false);
                }
              }
            }
          );

          acc[integrationId] = companyMaxedSyncs.every(
            (maxedSync) => maxedSync
          );

          return acc;
        },
        initialMaxedSyncs
      );

      return maxedSyncs;
    }

    return initialMaxedSyncs;
  }, [data, integrations]);

  const handleSync = useCallback(
    async (integration) => {
      if (
        ((typeof remainingSyncs[integration] !== 'string' &&
          (remainingSyncs[integration] as number) <= 0) ||
          maxedConsolidationSyncsUsed[integrationToId[integration]]) &&
        data
      ) {
        const oldestSyncTimestamp = determineOldestSyncTimestamp(
          data.company,
          integration
        );

        return showSingularSyncLimitReached(oldestSyncTimestamp);
      } else {
        if (
          integration === 'QB' &&
          data &&
          data.company.accounting.integration === 'QBD' &&
          !isConsolidation
        ) {
          return history.push('/sync/desktop');
        }

        if (integration === 'CSV') {
          return setShowUpdateCsv(true);
        }

        const { data: syncData } = await performSync({
          variables: {
            integration,
            companyId,
            utcOffset: getUtcOffset(),
          },
        });
        if (syncData.syncCompanyIntegration.connectionLost) {
          return connectionLost(groupId, workspaceId, accountingIntegration);
        }

        if (data && syncData.syncCompanyIntegration.error) {
          if (syncData.syncCompanyIntegration.error === 'Sync Limit Reached') {
            const oldestSyncTimestamp = determineOldestSyncTimestamp(
              data.company,
              integration
            );
            return showSingularSyncLimitReached(oldestSyncTimestamp);
          }
        }

        if (!isConsolidation) {
          startPolling(5000);
        }
      }
    },
    [
      accountingIntegration,
      companyId,
      data,
      groupId,
      history,
      isConsolidation,
      maxedConsolidationSyncsUsed,
      performSync,
      remainingSyncs,
      showSingularSyncLimitReached,
      startPolling,
      workspaceId,
    ]
  );

  const handleClick = useCallback(() => {
    if (integrations.length > 1) {
      setShowSubMenu(true);
    } else {
      handleSync(integrations[0]);
    }
  }, [handleSync, integrations]);

  const lastSynced = useMemo(() => {
    if (!data || !data.company || !data.company.lastSyncedByIntegration.length)
      return null;

    return moment.min(
      data.company.lastSyncedByIntegration.map((item) =>
        moment.utc(item.lastSynced)
      )
    );
  }, [data]);

  if (!integrations || !integrations.length) {
    return <></>;
  } else if (error || loading) {
    return <></>;
  } else if (
    !data ||
    data.company.demo ||
    (data.company.isConsolidation && !data.company.canSyncConsolidation)
  ) {
    return <></>;
  }

  const syncing =
    data.company.currentSyncJob && data.company.currentSyncJob.syncing;
  const syncingIntegration =
    data.company.currentSyncJob && data.company.currentSyncJob.integration;

  return (
    <>
      <Container
        onMouseEnter={() => setMouseEntered(true)}
        onMouseLeave={() => setMouseEntered(false)}
        submenuOpened={showSubMenu}
      >
        <Button
          mouseEntered={mouseEntered}
          onClick={handleClick}
          id="sidebar-sync-button"
        >
          <IconContainer>
            <Icon syncing={syncing} mouseEntered={mouseEntered}>
              <SyncIcon width={12} height={12} />
            </Icon>
          </IconContainer>

          <Text mouseEntered={mouseEntered}>
            {syncing ? 'Syncing' : `Sync`}
            <Info syncing={syncing}>
              Last synced <TimeAgo date={lastSynced} minPeriod={30} />
            </Info>
          </Text>
          {integrations.length === 1 && !isConsolidation && (
            <SyncLimit>
              <Tooltip
                content={`Daily Sync Limit: ${
                  remainingSyncs[integrations[0]]
                } left`}
              >
                <span>{remainingSyncs[integrations[0]]}</span>
              </Tooltip>
            </SyncLimit>
          )}
        </Button>
        <Progress
          progress={
            data.company.currentSyncJob
              ? data.company.currentSyncJob.progress
              : null
          }
        />

        {showSubMenu && (
          <OutsideClickDetector callback={() => setShowSubMenu(false)}>
            <SubMenu>
              {integrations.map((integration) => (
                <SubMenuButton
                  key={integration}
                  onClick={() => handleSync(integration)}
                  disabled={syncing}
                >
                  {syncingIntegration === integration ? 'Syncing' : `Sync`}{' '}
                  {integrationToName[integration]}
                  {!isConsolidation && (
                    <SyncLimit noHoverChange>
                      <Tooltip
                        content={`Daily Sync Limit: ${remainingSyncs[integration]} left`}
                      >
                        <span>{remainingSyncs[integration]}</span>
                      </Tooltip>
                    </SyncLimit>
                  )}
                </SubMenuButton>
              ))}
            </SubMenu>
          </OutsideClickDetector>
        )}
      </Container>
      <CreateCsvCompany
        show={showUpdateCsv}
        companyId={companyId}
        onClose={onCloseCsv}
        onUpdate={showPopup}
      />
    </>
  );
};

export default LastSynced;
