import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import {
  SettingTitle,
  Description,
  ToggleVisible,
  HorizontalTabs,
} from './styledComponents';

const InputWrapper = styled.div`
  input {
    border: 1px dashed #cdcdcd;
  }
`;

const LabelInput = styled.input.attrs({
  type: 'text',
  maxLength: 60,
  autoComplete: 'off',
  'data-1p-ignore': 'true',
})`
  width: 100%;
  display: block;
  max-width: 500px;
  margin-bottom: 5px;
`;

const tabs = ['Profit and Loss', 'Balance Sheet', 'Cash Flow Statement'];
const showProfitAndLoss = 0;
const showBalanceSheet = 1;
const showCashFlow = 2;

// If adding more labels, be sure to update the companyFinancialLabels table and associated models, typeDefs, and resolve files
const profitAndLoss = [
  { id: 'income', name: 'labels.income', placeHolder: 'Income' },
  {
    id: 'cogs',
    name: 'labels.cogs',
    placeHolder: 'Cost of Goods Sold',
  },
  {
    id: 'grossProfit',
    name: 'labels.grossProfit',
    placeHolder: 'Gross Profit',
  },
  { id: 'expense', name: 'labels.expense', placeHolder: 'Expense' },
  {
    id: 'netOperatingIncome',
    name: 'labels.netOperatingIncome',
    placeHolder: 'Net Operating Income',
  },
  {
    id: 'otherIncomeExpense',
    name: 'labels.otherIncomeExpense',
    placeHolder: 'Other Income / (Expense)',
  },
  {
    id: 'otherIncome',
    name: 'labels.otherIncome',
    placeHolder: 'Other Income',
  },
  {
    id: 'otherExpense',
    name: 'labels.otherExpense',
    placeHolder: 'Other Expense',
  },
  { id: 'netProfit', name: 'labels.netProfit', placeHolder: 'Net Profit' },
];

const balanceSheet = [
  { id: 'assets', name: 'labels.assets', placeHolder: 'Assets' },
  {
    id: 'currentAssets',
    name: 'labels.currentAssets',
    placeHolder: 'Current Assets',
  },
  {
    id: 'bankAccounts',
    name: 'labels.bankAccounts',
    placeHolder: 'Bank Accounts',
  },
  {
    id: 'accountsReceivable',
    name: 'labels.accountsReceivable',
    placeHolder: 'Accounts Receivable',
  },
  {
    id: 'otherCurrentAssets',
    name: 'labels.otherCurrentAssets',
    placeHolder: 'Other Current Assets',
  },
  {
    id: 'fixedAssets',
    name: 'labels.fixedAssets',
    placeHolder: 'Fixed Assets',
  },
  {
    id: 'otherAssets',
    name: 'labels.otherAssets',
    placeHolder: 'Other Assets',
  },
  {
    id: 'liabilitiesAndEquity',
    name: 'labels.liabilitiesAndEquity',
    placeHolder: 'Liabilities and Equity',
  },
  { id: 'liabilities', name: 'labels.liabilities', placeHolder: 'Liability' },
  {
    id: 'currentLiabilities',
    name: 'labels.currentLiabilities',
    placeHolder: 'Current Liabilities',
  },
  {
    id: 'accountsPayable',
    name: 'labels.accountsPayable',
    placeHolder: 'Accounts Payable',
  },
  { id: 'creditCard', name: 'labels.creditCard', placeHolder: 'Credit Card' },
  {
    id: 'otherCurrentLiabilities',
    name: 'labels.otherCurrentLiabilities',
    placeHolder: 'Other Current Liabilities',
  },
  {
    id: 'longTermLiabilities',
    name: 'labels.longTermLiabilities',
    placeHolder: 'Long-Term Liabilities',
  },
  { id: 'equity', name: 'labels.equity', placeHolder: 'Equity' },
];

const cashFlowStatement = [
  {
    id: 'operatingActivities',
    name: 'labels.operatingActivities',
    placeHolder: 'Operating Activities',
  },
  {
    id: 'investingActivities',
    name: 'labels.investingActivities',
    placeHolder: 'Investing Activities',
  },
  {
    id: 'financingActivities',
    name: 'labels.financingActivities',
    placeHolder: 'Financing Activities',
  },
  {
    id: 'netCashIncreaseForPeriod',
    name: 'labels.netCashIncreaseForPeriod',
    placeHolder: 'Net Cash Increase For Period',
  },
];

interface LabelsSettingsProps {
  labels: any;
  register: (any) => any;
  handleUnsavedChanges: () => void;
  supportedStatements: Array<string>;
  supportedFinancialLabels: Array<string>;
}

const LabelsSettings: React.FC<LabelsSettingsProps> = ({
  labels,
  register,
  handleUnsavedChanges,
  supportedStatements,
  supportedFinancialLabels,
}: LabelsSettingsProps) => {
  const [tab, setTab] = useState(0);
  const [change, setChange] = useState(false);

  const handleChange = useCallback(() => {
    if (!change) {
      setChange(true);
      handleUnsavedChanges();
    }
  }, [change, setChange, handleUnsavedChanges]);

  const supportedFinancialLabelsSet = useMemo(() => {
    return new Set(supportedFinancialLabels);
  }, [supportedFinancialLabels]);

  const visibleTabs = useMemo(() => {
    if (supportedStatements && supportedStatements.includes('CASH_FLOW')) {
      return tabs;
    }
    return tabs.slice(0, tabs.length - 1);
  }, [supportedStatements]);

  const profitAndLossVisible = useMemo(() => {
    return profitAndLoss.filter((item) =>
      supportedFinancialLabelsSet.has(item.id)
    );
  }, [supportedFinancialLabelsSet]);
  const balanceSheetVisible = useMemo(() => {
    return balanceSheet.filter((item) =>
      supportedFinancialLabelsSet.has(item.id)
    );
  }, [supportedFinancialLabelsSet]);

  const cashFlowVisible = useMemo(() => {
    return cashFlowStatement.filter((item) =>
      supportedFinancialLabelsSet.has(item.id)
    );
  }, [supportedFinancialLabelsSet]);

  return (
    <>
      <SettingTitle>Financial Statement Labels</SettingTitle>
      <Description>
        You can overwrite the titles of the financial statement sections.
      </Description>
      <HorizontalTabs tabs={visibleTabs} current={tab} onChange={setTab} />

      <InputWrapper>
        <ToggleVisible visible={tab === showProfitAndLoss}>
          {profitAndLossVisible.map((label, i) => {
            return (
              <LabelInput
                key={i}
                {...register(label.name)}
                id={label.id}
                name={label.name}
                defaultValue={labels[label.id] ? labels[label.id] : ''}
                placeholder={label.placeHolder}
                onChange={handleChange}
              />
            );
          })}
        </ToggleVisible>
        <ToggleVisible visible={tab === showBalanceSheet}>
          {balanceSheetVisible.map((label, i) => {
            return (
              <LabelInput
                key={i}
                {...register(label.name)}
                id={label.id}
                name={label.name}
                defaultValue={labels[label.id] ? labels[label.id] : ''}
                placeholder={label.placeHolder}
                onChange={handleChange}
              />
            );
          })}
        </ToggleVisible>
        <ToggleVisible visible={tab === showCashFlow}>
          {cashFlowVisible.map((label, i) => {
            return (
              <LabelInput
                key={i}
                {...register(label.name)}
                id={label.id}
                name={label.name}
                defaultValue={labels[label.id] ? labels[label.id] : ''}
                placeholder={label.placeHolder}
                onChange={handleChange}
              />
            );
          })}
        </ToggleVisible>
      </InputWrapper>
    </>
  );
};

export default LabelsSettings;
