import styled from 'styled-components';

export const Input = styled.input<{ width?: number; percent?: boolean; margin?: boolean }>`
  background-color: ${({ theme }) => theme.searchBar.backgroundColor};
  margin: ${({ margin }) => margin ? '10px 0' : '0'};
  width: ${({ width, percent }) =>
    width ? `${width}${percent ? '%' : 'px'}` : 'auto'};
  border: none;
  padding: ${({ theme }) => theme.formControl.padding};
  border-radius: ${({ theme }) => theme.formControl.borderRadius};
  font-size: ${({ theme }) => theme.formControl.fontSize};
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.searchBar.placeholderColor};
  }
  color: ${({ theme }) => theme.searchBar.color};
`;
