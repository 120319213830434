/**
 *
 * Article
 *
 */
import moment from 'moment';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import theme from 'styled-theming';

const dividerColor = theme('mode', {
  light: 'rgba(0, 0, 0, 0.05)',
  dark: '#6d7686',
});

const Div = styled.div`
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  & h3 {
    font-size: 18px;
    color: #12afcb;
  }
  & h3 + div {
    margin-top: 0.5rem;
    margin: 0 0 0.625rem;
  }
  & a {
    color: #12afcb;
  }
  & hr {
    display: block;
    height: 3px;
    border-radius: 3px;
    background: ${dividerColor};
    border: none;
    margin: 1em 0;
  }
  & p {
    font-size: 14px;
  }
  & img {
    max-width: 100%;
  }
`;

const Article = props => {
  const { article } = props;
  const { body } = article;

  return (
    <Div>
      <div dangerouslySetInnerHTML={{ __html: body }} />
    </Div>
  );
};

export default Article;
