import styled from 'styled-components';

export const SourceGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 24px;
  margin-bottom: 1rem;
`;

export const CompanyGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 24px;
  margin-bottom: 0.25rem;
`;

export const SelectWrapper = styled.div`
  width: 14rem;
`;

export const ErrorText = styled.div`
  color: ${(props) => props.theme.colors.danger};
  margin-bottom: 1rem;
  font-size: 0.75rem;
`;

export const Scroll = styled.div`
  max-height: calc(100vh - 200px);
  overflow-y: auto;
`;
