/**
 *
 * IntegrationTabs
 *
 */
import React, { useCallback } from 'react';
import styled from 'styled-components';

import DropdownDrawer from 'components/DropdownDrawer';
import Checkbox from 'components/Checkbox';
import {
  integrationToLogo,
  integrationToName,
} from 'components/IntegrationLogos/util';
import scrollbar from 'css/scrollbar';

const IntegrationsContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  border-right: 1px solid #ccc;
  height: auto;
  width: 250px;
  padding-right: 2px;
  overflow: auto;
  ${scrollbar('#eee', '#617182')}
  flex-grow: 1;
`;

const CheckboxList = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;

/* 
const integrationSampleObject = (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '1rem',
    }}
  >
    {[
      'Classes',
      'Departments',
      'Employees',
      'Producst / Services',
      'Jobs',
      'Customers',
      'Vendords',
    ].map((checkbox, index) => (
      <Checkbox key={index}>{checkbox}</Checkbox>
    ))}
  </div>
);
 */

const IntegrationTabs = (props) => {
  const {
    integrations,
    filtersByIntegration,
    selectedFiltersIds,
    onClickFilter,
  } = props;

  const onChange = useCallback(
    (e) => {
      onClickFilter(e.target.name);
    },
    [onClickFilter]
  );

  if (!integrations || !filtersByIntegration) return null;

  return (
    <IntegrationsContainer>
      {/**
       * Possibly use a .map function on an array of integrations that exist with a company, then
       * use the appropriate logos and checkbox list as the content property. See integrationSampleObject
       * variable for possible structuring.
       *
       * Below is sample data to be replaced with array.map
       */}

      {integrations.map((integration, index) => (
        <DropdownDrawer
          key={integration}
          expanded={index === 0}
          attributes={{
            icon: integrationToLogo[integration].lg,
            text: integrationToName[integration],
            content: (
              <CheckboxList>
                {filtersByIntegration[integration].map((filter) => (
                  <Checkbox
                    key={filter.key}
                    name={`${integration}_${filter.key}`}
                    checked={selectedFiltersIds.includes(
                      `${integration}_${filter.key}`
                    )}
                    onChange={onChange}
                  >
                    {filter.name}
                  </Checkbox>
                ))}
              </CheckboxList>
            ),
          }}
        />
      ))}
    </IntegrationsContainer>
  );
};

export default IntegrationTabs;
