/**
 *
 * UpdatesModal
 *
 */
import { useQuery } from '@apollo/client';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import gql from 'graphql-tag';
import React from 'react';
import styled from 'styled-components';
import Article from './Article';

const QUERY = gql`
  query UpdatesModal {
    hubspotUpdates {
      id
      body
    }
  }
`;

const Div = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 200px);

  margin-right: -1rem;
`;

const titleStyles = {
  fontSize: 18,
  fontWeight: 700,
};

const UpdatesModal = props => {
  const { show, onClose } = props;
  const { data } = useQuery(QUERY, {
    skip: !show,
  });

  const loading = !data || !data.hubspotUpdates;

  return (
    <Modal
      title="What's New"
      show={show}
      onClose={onClose}
      width={700}
      titleStyles={titleStyles}
      useCurrentTheme
    >
      <Div>
        {loading ? (
          <Spinner />
        ) : (
          <Article key={data.hubspotUpdates.id} article={data.hubspotUpdates} />
        )}
      </Div>
    </Modal>
  );
};

export default UpdatesModal;
