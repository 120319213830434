import React from 'react';
import { Button, Container } from './styledComponents';

interface Props {
  show: boolean;
  style?: React.CSSProperties;
  size?: number;
  maxWidth?: number;
  onClick: () => void;
  children?: React.ReactNode;
}

const PasswordToggle = ({
  show,
  style,
  size = 1,
  onClick,
  maxWidth,
  children,
}: Props): React.ReactElement => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onClick();
  };

  return (
    <Container maxWidth={maxWidth}>
      {children}
      <Button type="button" onClick={handleClick} style={style}>
        <i
          style={{ fontSize: `${size}rem` }}
          className={`mdi-set mdi-${show ? 'eye' : 'eye-off'}`}
        />
      </Button>
    </Container>
  );
};

export default PasswordToggle;
