/**
 *
 * FieldError
 *
 */

import React from 'react';
import styled from 'styled-components';

const Span = styled.span`
  color: ${props => props.theme.colors.danger};
  font-size: ${props => props.theme.fontSizes.small};
`;

class FieldError extends React.PureComponent {
  render() {
    if (!this.props.error) {
      return null;
    }

    return <Span>{this.props.error}</Span>;
  }
}

export default FieldError;
