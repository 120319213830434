/**
 *
 * Categories
 *
 */

import React from 'react';
import { Firm } from 'interfaces/firm';

import { CategoriesHeader, Tabs } from './styledComponents';
import { LeftContainer } from 'components/ItemSelectFlowStructure';

interface Props {
  /**
   * All the different folders / groups the user has access to
   */
  folders: Firm[];
  /**
   * The current tab ID
   */
  current: number;
  /**
   * Callback invoked when tab is changed
   */
  onChange: (selectedTab: number) => void;
}

const Categories: React.FC<Props> = ({ folders, current, onChange }) => {
  const tabs = folders.map((folder) => folder.name);

  return (
    <LeftContainer>
      <CategoriesHeader>Folders</CategoriesHeader>
      <Tabs tabs={tabs} current={current} onChange={onChange} />
    </LeftContainer>
  );
};

export default Categories;
