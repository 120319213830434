import { useCallback, useState } from 'react';
import { SYNC } from '../mutations';
import { useMutation } from '@apollo/client';

import {
  showSyncAllSheetsWarning,
  showConfirm
} from 'utils/popups';
import { captureException } from 'utils/sentry';
import useCompanyId from 'hooks/useCompanyId';
import { clearTableValues } from 'utils/cache';

let couldNotSync = [];
export default function useSyncAllGoogleSheets() {
  const [sync] = useMutation(SYNC);
  const companyId = useCompanyId();
  const [loading, setLoading] = useState(true);

  const onSyncAll = useCallback(
    async (spreadsheets, ignoreErrors = false) => {
      for (const sheet of spreadsheets) {
        const id = sheet.id;
        const name = sheet.name;
        try {
          const { data, loading: isLoading } = await sync({
            variables: {
              googleSpreadsheetId: id,
              ignoreErrors,
            },
            update: (cache) => {
              clearTableValues(cache, companyId);
            },
          });

          if (
            !isLoading &&
            !(
              data &&
              data.syncGoogleSpreadsheet &&
              data.syncGoogleSpreadsheet.success
            )
          ) {
            couldNotSync.push(name);
          }
        } catch (err) {
          setLoading(false);
          captureException(err, true);
        }
      }
      setLoading(false);

      if (couldNotSync.length > 0) {
        const html = `
        <div>
          <p>The following sheets could not be synced. Please use the individual sync button.</p>
          <div style="display:flex; flex-direction:column; overflow-y:auto; max-height:200px;">
            ${couldNotSync.reduce(
              (namesHtml, name) => namesHtml + `<span>${name}</span>`,
              ''
            )}
          </div>
        </div>`;
      await showSyncAllSheetsWarning({
        html: html,
      });
    } else {
      if (
        await showConfirm({
          title: 'Sync Successful',
          text: 'Would you like to refresh?',
        })
      ) {
        window.location.reload();
      }
    }
      couldNotSync = [];
    },
    [companyId, sync]
  );

  return { onSyncAll, loading };
}
