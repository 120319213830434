import useCompanyId from './useCompanyId';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useMemo } from 'react';

export const QUERY = gql`
  query UseBudgets($companyId: Int!) {
    company(id: $companyId) {
      id
      budgetGroups {
        id
        name
        hasFilter
      }
      budgets {
        id
        name
        pending
        datasheet {
          id
          startDate
          endDate
        }
        isAdvancedBudget
        budgetBookMonth
        status
        hasFilter
        subdivideEnabled
      }
      accounting {
        hasFilter
        budgets {
          id
          name
          hasFilter
        }
      }
      defaultBudgetId
      defaultBudgetType
      defaultForecastId
      defaultForecastType
    }
  }
`;

export default function useBudgets(companyId) {
  const openCompanyId = useCompanyId();
  const currentCompanyId = companyId || openCompanyId;

  const { data, loading, error } = useQuery(QUERY, {
    variables: {
      companyId: currentCompanyId,
    },
    skip: !currentCompanyId,
  });
  const result = useMemo(() => {
    if (loading || error || !data || !data.company)
      return { loading: true, error };

    return {
      budgetGroups: data.company.budgetGroups || [],
      companyBudgets: data.company.budgets.filter((b) => !b.pending),
      // budgets={data.company.accounting.budgets}
      accountingBudgets:
        data.company.accounting && data.company.accounting.budgets
          ? data.company.accounting.budgets
          : [],
      defaultBudgetId: data.company.defaultBudgetId,
      defaultBudgetType: data.company.defaultBudgetType,
      defaultForecastId: data.company.defaultForecastId,
      defaultForecastType: data.company.defaultForecastType,
      loading,
      error,
    };
  }, [data, error, loading]);

  return result;
}
