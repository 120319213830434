import React from 'react';
import loadable from '@loadable/component';

function Loadable({ loader, loading: Loading }) {
  const Component = loadable(loader);

  return function(props) {
    return <Component fallback={Loading ? <Loading /> : null} {...props} />;
  };
}

export default Loadable;
