import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import useCompanyId from './useCompanyId';

const QUERY = gql`
  query IntegrationFilters($companyId: Int!) {
    company(id: $companyId) {
      id
      integrationFilters {
        key
        name
        integration
        items {
          id
          name
          parentId
        }
      }
    }
  }
`;

export default function useIntegrationFilters(_companyId) {
  const currentCompanyId = useCompanyId();
  const companyId = _companyId || currentCompanyId;

  const { data } = useQuery(QUERY, {
    variables: {
      companyId,
    },
  });

  return data && data.company && data.company.integrationFilters;
}
