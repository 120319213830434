import React, { useCallback, useState } from 'react';
import Dropdown from 'components/Dropdown';
import {
  TriggerContainer,
  DropdownContainer,
  Label,
  DropdownButton,
} from './styledComponents';
import DownArrowIcon from 'components/Icons/DownArrowicon';

export interface DropdownItem<T extends Record<string, unknown>> {
  label: string;
  value: string | number;
  item: T;
}

interface Props<T extends Record<string, unknown>> {
  selected: DropdownItem<T>;
  items: DropdownItem<T>[];
  onSelect: (result: DropdownItem<T>) => void;
}

const SelectLarge = <T extends Record<string, unknown>>({
  selected,
  items,
  onSelect,
}: Props<T>): React.ReactElement | null => {
  const [selectedValue, setSelectedValue] = useState<DropdownItem<T>>(selected);

  const handleSelect = useCallback(
    (value: DropdownItem<T>) => {
      onSelect(value);
      setSelectedValue(value);
    },
    [onSelect]
  );

  return (
    <DropdownContainer>
      <Dropdown
        offsetX={1}
        offsetY={65}
        width={250}
        trigger={
          <TriggerContainer>
            <Label>{selected.label}</Label>
            <DownArrowIcon height={24} width={24} />
          </TriggerContainer>
        }
        showScroll={true}
        position={0}
        overflowXHidden
      >
        {({ closeDropdown }) =>
          items.map((item: DropdownItem<T>) => (
            <DropdownButton
              key={item.value}
              selected={selectedValue.value === item.value}
              onClick={() => {
                closeDropdown();
                handleSelect(item);
              }}
            >
              {item.label}
            </DropdownButton>
          ))
        }
      </Dropdown>
    </DropdownContainer>
  );
};

export default SelectLarge;
