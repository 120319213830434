import React, { ReactElement } from 'react';

const HomeIcon = ({
  color = '#f9fafc',
  width = '100%',
  height = '100%',
  cursor,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  cursor?: string;
  onClick?: (any) => void;
}): ReactElement => {
  return (
    <>
      <svg
        {...props}
        width={width}
        height={height}
        cursor={cursor}
        viewBox="0 0 24 24"
      >
        <path fill={color} d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z" />
      </svg>
    </>
  );
};

export default HomeIcon;
