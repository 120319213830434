const getDimension = (
  dimension: string | number | undefined
): string | undefined => {
  if (typeof dimension === 'number') {
    return `${dimension}px`;
  }
  
  return dimension;
};

export default getDimension;
