import { Expanding } from 'components/DashboardLiveTextModal/styledComponents';
import Modal, { ModalProps } from 'components/Modal';
import useClassesAndDepartments from 'hooks/useClassesAndDepartments';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Container } from './styledComponents';
import LoadingIndicator from 'components/LoadingIndicator';
import FilterBox, { FilterItem } from 'components/FilterBox';
import { Accounting } from 'interfaces/accounting';
import Instructions from './Instructions';
import Actions from './Actions';
import Select from './Select';

const mapItems = (
  items: Accounting['classes'] | Accounting['departments'],
  search: string
): FilterItem[] =>
  items
    .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((item) => ({
      id: item.id,
      value: item.id,
      label: item.name,
      name: item.name,
      disabled: false,
      parentId: item.parentId,
    }));

function parseId(id) {
  // check if string and is only numbers
  if (typeof id === 'string' && /^\d+$/.test(id)) {
    return parseInt(id, 10);
  }
  return id;
}

const revertMapItemsById = (
  items: Accounting['classes'] | Accounting['departments'],
  selected: (string | number)[]
): Accounting['classes'] | Accounting['departments'] =>
  items.filter((item) => selected.includes(parseId(`${item.id}`)));

export type FilterTypes = 'CLASSES' | 'DEPARTMENTS';

interface Props extends Omit<ModalProps, 'children'> {
  companyId?: number;
  onSave: (
    companyId: number,
    type: FilterTypes,
    selected: Accounting['classes'] | Accounting['departments']
  ) => void;
  type?: FilterTypes;
  selected?: (number | string)[];
  limit?: number;
  disableChangeType?: boolean;
  disableRemoveAll?: boolean;
  disableSubs?: boolean;
}

const ClassDepartmentModal = ({
  show,
  companyId,
  onClose,
  onSave,
  type: _type,
  selected: _selected,
  limit,
  disableChangeType,
  disableRemoveAll,
  disableSubs,
}: Props): React.ReactElement | null => {
  const { classes, departments, loading } = useClassesAndDepartments(
    companyId,
    !show
  );
  const [type, setType] = useState<FilterTypes | undefined>(_type || undefined);
  const [expandHeight, setExpandHeight] = useState(0);
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState<(string | number)[]>(
    _selected || []
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const justShownRef = useRef(false);
  const instructions = useMemo(() => {
    if (!type) {
      return 'Select type';
    }
  }, [type]);

  useEffect(() => {
    if (show) {
      if (_type) {
        setType(_type);
      }

      if (_selected) {
        setSelected(_selected);
      }

      justShownRef.current = true;
    } else {
      setType(undefined);
      setSearch('');
      setSelected([]);
    }
  }, [_selected, _type, show]);

  useEffect(() => {
    if (type && !justShownRef.current) {
      setSelected([]);
    }

    justShownRef.current = false;
  }, [type]);

  useEffect(() => {
    if (containerRef.current) {
      const newHeight = containerRef.current.offsetHeight;
      setExpandHeight(newHeight);
    }
  }, [show, loading, type, selected.length]);

  const handleClose = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  const handleSave = useCallback(() => {
    if (type && companyId && classes && departments) {
      onSave(
        companyId,
        type,
        revertMapItemsById(type === 'CLASSES' ? classes : departments, selected)
      );
      handleClose();
    }
  }, [classes, companyId, departments, handleClose, onSave, selected, type]);

  const handleTypeChange = useCallback((value: FilterTypes) => {
    setType(value);
  }, []);

  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    },
    []
  );

  const items = useMemo(() => {
    if (type === 'CLASSES') {
      return mapItems(classes || [], search);
    }
    if (type === 'DEPARTMENTS') {
      return mapItems(departments || [], search);
    }

    return [];
  }, [type, classes, departments, search]);

  const onSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const id = parseId(e.target.value);
      if (selected.includes(id)) {
        setSelected(selected.filter((item) => item !== id));
      } else {
        setSelected([...selected, id]);
      }
    },
    [selected]
  );

  const onSelectAll = useCallback(() => {
    const totalLen = disableSubs
      ? items.filter((item) => !item.parentId).length
      : items.length;

    if (selected.length === totalLen) {
      setSelected([]);
    } else if (disableSubs) {
      setSelected(
        items
          .filter((item) => !item.parentId)
          .map((item) => parseId(`${item.id}`))
      );
    } else {
      setSelected(items.map((item) => parseId(`${item.id}`)));
    }
  }, [disableSubs, items, selected.length]);

  return (
    <Modal
      show={show}
      title="Add Classes or Departments"
      onClose={handleClose}
      preventClickOut={false}
    >
      <Expanding height={expandHeight}>
        <Container ref={containerRef}>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <>
              <Select
                handleTypeChange={handleTypeChange}
                type={type}
                disabled={disableChangeType}
              />
              {type && (
                <>
                  <FilterBox
                    items={items}
                    selected={selected}
                    onSelectAll={onSelectAll}
                    onChange={onSelect}
                    search={search}
                    onChangeSearch={onSearchChange}
                    limitReached={!!limit && selected.length >= limit}
                    disableSubs={disableSubs}
                  />
                  <Actions
                    limit={limit}
                    count={selected.length}
                    onSave={handleSave}
                    disableRemoveAll={disableRemoveAll}
                  />
                </>
              )}
              {instructions && <Instructions instructions={instructions} />}
            </>
          )}
        </Container>
      </Expanding>
    </Modal>
  );
};

export default ClassDepartmentModal;
