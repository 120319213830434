import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import * as Sentry from '@sentry/browser';

class MutationWithErrorMessage extends React.PureComponent {
  handleError = (error) => {
    Sentry.captureException(error);
  };
  render() {
    const { onError, ...rest } = this.props;

    return <Mutation {...rest} onError={onError || this.handleError} />;
  }
}

export default MutationWithErrorMessage;
