/**
 *
 * CreateLegacyDatasheetForm
 *
 */

import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Form } from 'informed';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import TextField from 'components/TextField';
import Button from 'components/Button';

import { captureException } from 'utils/sentry';
import useCompanyId from 'hooks/useCompanyId';
import produce from 'immer';
import { LEGACY_QUERY } from 'components/DataSheetModal/mutations';

const Inner = styled.div`
  display: flex;
`;

const ErrorDiv = styled.div`
  color: ${(props) => props.theme.colors.danger};
  font-size: 13px;
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
`;

const CREATE = gql`
  mutation CreateLegacyDatasheet($companyId: Int!, $name: String!) {
    createSpreadsheet(companyId: $companyId, name: $name) {
      id
      name
      lastModified
    }
  }
`;

const CREATE_LIBRARY = gql`
  mutation CreateLegacyDatasheetFromLibrary(
    $companyId: Int!
    $spreadsheetId: Int!
    $name: String!
  ) {
    createSpreadsheetFromLibrary(
      companyId: $companyId
      spreadsheetId: $spreadsheetId
      name: $name
    ) {
      id
      name
      lastModified
    }
  }
`;

function validate(value) {
  if (!value) return 'Name is required';
  if (value.length > 35) return 'Name cannot be more than 35 characters';
  return undefined;
}

function CreateLegacyDatasheetForm(props) {
  const { className, libraryDatasheetId, onCompleted } = props;
  const companyId = useCompanyId();
  const [formApi, setApi] = useState(null);
  const history = useHistory();
  const getApi = useCallback((api) => setApi(api), []);

  const [create, { loading }] = useMutation(
    libraryDatasheetId ? CREATE_LIBRARY : CREATE
  );

  const handleSubmit = useCallback(
    async ({ name }) => {
      try {
        await create({
          variables: {
            companyId,
            spreadsheetId: libraryDatasheetId,
            name,
          },
          update: (cache, { data }) => {
            const variables = {
              companyId,
            };
            const d = cache.readQuery({
              query: LEGACY_QUERY,
              variables,
            });
            const datasheet =
              data[
                libraryDatasheetId
                  ? 'createSpreadsheetFromLibrary'
                  : 'createSpreadsheet'
              ];

            cache.writeQuery({
              query: LEGACY_QUERY,
              variables,
              data: produce(d, (draft) => {
                draft.company.spreadsheets.push(datasheet);
              }),
            });
            formApi.reset();
            onCompleted && onCompleted();

            history.push(`/spreadsheet/${datasheet.id}`);
          },
        });
      } catch (err) {
        captureException(err, true);
      }
    },
    [create, companyId, libraryDatasheetId, formApi, onCompleted, history]
  );

  return (
    <Form
      id="create-legacy-datasheet-form"
      className={className}
      getApi={getApi}
      onSubmit={handleSubmit}
    >
      {({ formState }) => (
        <div>
          <Inner>
            <TextField
              id="legacy-datasheet-form-name"
              type="text"
              field="name"
              autoFocus
              validate={validate}
            />
            <StyledButton
              type="submit"
              color="primary"
              loading={loading}
              disabled={loading}
            >
              Create
            </StyledButton>
          </Inner>
          <ErrorDiv>{formState.errors.name || null}</ErrorDiv>
        </div>
      )}
    </Form>
  );
}

export default CreateLegacyDatasheetForm;
