import styled from 'styled-components';
import TextFieldBase from 'components/TextField';
import ButtonBase from 'components/Button';
import { FieldProps, Form as FormBase } from 'informed';
import Link from 'components/Link';
import LoginForm from '.';

export const TextField = styled(TextFieldBase)<
  FieldProps<string, LoginForm> & { error?: boolean }
>`
  height: 41px;
  text-align: center;
  max-width: 360px;
  padding-bottom: 10px;
`;

export const Button = styled(ButtonBase)`
  font-weight: bold;
  height: 43px;
  width: 114px;
  border-width: 2px;
`;

export const Fields = styled.div`
  margin-bottom: 15px;
  > div > div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    input {
      margin-bottom: 5px;
    }
  }
`;

export const Form = styled(FormBase as typeof FormBase<LoginForm>)`
  text-align: center;
`;

export const ResendButton = styled.button`
  display: block;
  color: ${(props) => props.theme.colors.link};
`;

export const ForgotLink = styled(Link)`
  display: block;
  font-size: 0.75rem;
  text-align: right;
`;

export const P = styled.p`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const PasswordToggleContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
