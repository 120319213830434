/**
 *
 * HelpBlock
 *
 */
import React from 'react';
import styled from 'styled-components';

import Button from 'components/Button';
import ButtonLink from 'components/ButtonLink';

const Div = styled.div`
  border: 2px solid #eaebef;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 24px 47px;
  padding: 2%;
  margin: 1.625rem 1%;
`;

const Img = styled.img`
  width: 40%;
  margin-right: 2.1875rem;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 1.75rem;
  margin-bottom: 1.375rem;
  max-width: 22rem;
`;
const Description = styled.div`
  font-weight: 400;
  font-size: 1.125rem;
  margin-bottom: 1.375rem;
  max-width: 22rem;
`;

const HelpBlock = (props) => {
  const {
    image,
    title,
    description,
    buttonText,
    onClick,
    link,
    external,
    buttonId,
  } = props;

  return (
    <Div>
      <Img src={image} />
      <div>
        <Title>{title}</Title>
        <Description>{description}</Description>
        {!!onClick && (
          <Button color="info" outline onClick={onClick} id={buttonId}>
            {buttonText}
          </Button>
        )}
        {!!link && (
          <ButtonLink
            color="info"
            outline
            to={link}
            href={link}
            external={external}
            target="__blank"
            id={buttonId}
          >
            {buttonText}
          </ButtonLink>
        )}
      </div>
    </Div>
  );
};

export default HelpBlock;
