/**
 *
 * HeaderButton
 *
 */

import React from 'react';
import styled from 'styled-components';
import { headerButtonIconZIndex } from 'utils/zIndex';

const Button = styled.button`
  height: 48.8px;
  font-size: 0.875rem;
  color: ${(props) =>
    props.workspace || props.minimal
      ? props.theme.workspaceHeader.color
      : props.theme.header.color};
  font-weight: 300;
  padding: 0;
  width: 48.8px;
  text-align: center;
  max-width: 100%;
  margin-right: 6px;
  &:hover {
    background-color: ${(props) =>
      props.theme.mode === 'light'
        ? props.theme.header.buttonHighlight
        : '#393E46'};
    border-radius: 5px;
  }
`;

const I = styled.i`
  color: ${(props) =>
    props.workspace || props.minimal
      ? props.theme.colors.gear
      : props.theme.header.color};
  font-size: 1.5rem;
  position: relative;
  height: 100%;
  line-height: calc(${(props) => props.theme.header.height} - 10px);
  display: inline-block;
  width: 63px;
  max-width: 100%;
  ${Button}:hover &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: ${headerButtonIconZIndex};
  }
`;

function HeaderButton(props, ref) {
  const { icon, minimal, workspace, mode, ...rest } = props;

  return (
    <Button ref={ref} {...rest} minimal={minimal} workspace={workspace}>
      <I className={icon} minimal={minimal} workspace={workspace} mode={mode} />
    </Button>
  );
}

export default React.forwardRef(HeaderButton);
