import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;

  img {
    max-width: 50%;
  }
`;

export const Header = styled.h3`
  font-size: 0.875rem;
  font-weight: 600;
  width: 100%;
  margin-bottom: 0;
}
`;

export const P = styled.p`
  font-size: 0.875rem;
`;

export const QRSubtitle = styled.p`
  font-size: 0.875rem;
  text-align: center;

  span {
    font-weight: 600;
  }
`;

export const CodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const CodeInput = styled.input.attrs({
  type: 'text',
  placeholder: 'Enter code',
  maxLength: 6,
  autoComplete: 'off',
  'data-1p-ignore': 'true',
})<{ error?: boolean }>`
  flex-grow: 1;
  margin-right: 0.5rem;
  ${({ error, theme }) =>
    error && `border-color: ${theme.colors.danger} !important;`}
`;

export const Verifying = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  color: #bbbbbb;
  border-radius: 3px;
`;

export const Error = styled.span`
  color: ${({ theme }) => theme.colors.danger};
  margin-top: 0.25rem;
  font-size: 0.85rem;
`;
