import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useMemo } from 'react';
import useIsLoggedIn from './useIsLoggedIn';

const QUERY = gql`
  query CurrentUserId {
    currentUser {
      userId
    }
  }
`;

export default function useUserId() {
  const isLoggedIn = useIsLoggedIn();
  const { data, loading, error } = useQuery(QUERY, {
    skip: !isLoggedIn,
  });

  const result = useMemo(() => {
    if (loading || !data || error || !data.currentUser) {
      return [null, { loading, error }];
    }
    return [data.currentUser.userId, { loading, error }];
  }, [data, error, loading]);

  return result;
}
