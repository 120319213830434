/**
 *
 * Spinner
 *
 */

import React, { CSSProperties } from 'react';
import styled, { keyframes } from 'styled-components';
import { spinnerZIndex } from 'utils/zIndex';

const spin = keyframes`
   0% {
     transform: rotate(0deg);
   }
   100% {
     transform: rotate(360deg);
   }
 `;

const Div = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  top: 0;
  left: 0;
  position: relative;
  min-height: 150px;
`;

const Inner = styled.div<{
  size: number;
}>`
  width: ${(props) => `${props.size}0px`};
  height: ${(props) => `${props.size}0px`};
  margin-top: -20px;
  margin-left: -20px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  border-width: 5.3px;
  border-color: rgb(52, 152, 219) rgb(243, 243, 243) rgb(243, 243, 243);
  position: absolute;
  left: 50%;
  top: 40%;
  z-index: ${spinnerZIndex};
  animation: ${spin} 1s ease-out infinite;
`;

interface SpinnerProps {
  className?: string;
  size?: number;
  style?: CSSProperties;
}

const Spinner: React.FC<SpinnerProps> = ({
  className = '',
  size = 4,
  style,
}) => {
  return (
    <Div className={`spinner ${className}`} style={style}>
      <Inner size={size} />
    </Div>
  );
};

export default Spinner;
