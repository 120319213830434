import scrollbar from 'css/scrollbar';
import styled, { css } from 'styled-components';

const PADDING = 10;

export const ParentContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: ${PADDING}px;
  overflow-y: auto;
  margin-right: 2px;
  ${scrollbar()}
`;

export const MiddleContainer = styled.div<{
  viewOnly?: boolean;
  paddingTop?: number;
}>`
  display: flex;
  flex-direction: column;
  flex: 2;
  padding: ${({ paddingTop }) =>
    `${paddingTop}px ${PADDING}px ${PADDING}px ${PADDING}px` || `${PADDING}px`};
  overflow: auto;
  margin-right: 2px;
  ${({ viewOnly }) =>
    !viewOnly &&
    css`
      border-left: 1px solid #ccc;
    `}
  ${scrollbar()}
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #eee;
  flex: 1.50;
  border-radius: 5px;
  padding: ${PADDING}px;
`;
