/**
 *
 * RenameModal
 *
 */
import React from 'react';
import styled from 'styled-components';
import { Form } from 'informed';

import Modal from 'components/Modal';
import TextField from 'components/TextField';
import Button from 'components/Button';

import { isResourceName } from 'validation';

const Inner = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
`;

const ErrorDiv = styled.div`
  color: ${(props) => props.theme.colors.danger};
  font-size: 13px;
`;

interface RenameModalProps {
  marginTop?: string;
  title: string;
  defaultName?: string;
  show: boolean;
  onClose: () => void;
  onSubmit: ({ name: string }) => void;
  loading?: boolean;
}

const RenameModal: React.FunctionComponent<RenameModalProps> = ({
  marginTop = '100px',
  onClose,
  show,
  title,
  onSubmit,
  defaultName,
  loading,
}) => {
  const test = defaultName && defaultName.split('.');
  let nameWithExtensionRemoved = defaultName;
  if (test && test.length > 1 && test[test.length - 1] === 'xlsx') {
    nameWithExtensionRemoved = defaultName?.replace('.xlsx', '');
  } else if (test && test.length > 1 && test[test.length - 1] === 'xls') {
    nameWithExtensionRemoved = defaultName?.replace('.xls', '');
  }
  return (
    <Modal
      show={show}
      title={title}
      onClose={onClose}
      width={500}
      marginTop={marginTop}
      zIndex={1005}
    >
      <Form
        id="name-modal"
        onSubmit={onSubmit}
        initialValues={{ name: nameWithExtensionRemoved }}
      >
        {({ formState, formApi }) => (
          <div>
            <Inner>
              <TextField
                id="dashboard-form-name"
                type="text"
                field="name"
                autoFocus
                validate={isResourceName}
              />
              <StyledButton
                type="button"
                color="primary"
                loading={loading}
                disabled={loading}
                onClick={formApi.submitForm}
              >
                Save
              </StyledButton>
            </Inner>
            <ErrorDiv>{formState.errors.name || null}</ErrorDiv>
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default RenameModal;
