/**
 *
 * AuthenticationPanel
 *
 */

import React from 'react';
import styled from 'styled-components';

import icon from 'images/icon.png?size=42';

import lightLogo from 'images/logo.png?size=280';
import darkLogo from 'images/logo-white.png?size=280';

import useWhitelabel from 'hooks/useWhitelabel';

const Div = styled.div`
  text-align: center;
`;

const Icon = styled.img`
  margin-bottom: 24px;
`;

const Logo = styled.img`
  margin-bottom: 39px;
  max-width: 280px;
`;

const Center = styled.div`
  text-align: center;
`;

const H5 = styled.h5`
  font-size: 1rem;
`;

function AuthenticationPanel({ title = '', children }) {
  const { logo, whiteLabel, loading } = useWhitelabel(lightLogo, darkLogo);

  if (loading) {
    return null;
  }

  return (
    <Div>
      <Center>
        {!whiteLabel && (
          <div>
            <Icon src={icon} />
          </div>
        )}
        <div>{!!logo && <Logo src={logo} alt="Logo" />}</div>
        {!!title && <H5>{title}</H5>}
      </Center>
      {children}
    </Div>
  );
}

export default AuthenticationPanel;
