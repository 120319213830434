/**
 *
 * AddCompanyPanel
 *
 */

import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { API_URL } from 'environment';

import {
  StyledPanel,
  Options,
  A,
  CsvButton,
  Bottom,
  OutlinedButton,
} from './styled-components';

const Option = ({ content, onClick, helpLink }) => (
  <div>
    <CsvButton onClick={onClick}>{content}</CsvButton>
    <A href={helpLink} target="_blank">
      View Help Article
    </A>
  </div>
);

const ChooseDatasheetType = props => {
  const { dispatch } = props;

  const [companyId, setCompanyId] = useState(null);

  // Handlers
  const handleClose = event => {
    if (event.origin === API_URL) {
      setCompanyId(event.data.companyId);
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleClose, false);

    return () => {
      window.removeEventListener('message', handleClose, false);
    };
  }, []);

  if (companyId) {
    return <Redirect to={`/connecting/${companyId}`} />;
  }

  return (
    <div>
      <StyledPanel>
        <Options>
          <Option
            content="Connect to a Google Sheet"
            onClick={() => dispatch({ type: 'GOOGLE_ACCOUNT_LIST' })}
            helpLink="https://knowledge.reachreporting.com/google-sheets-integration"
          />
          <Option
            content="Reach Data Sheet"
            onClick={() => dispatch({ type: 'CREATE_REACH_DATASHEET' })}
            helpLink="https://knowledge.reachreporting.com/the-power-of-reach-reporting-data-sheets"
          />
        </Options>
      </StyledPanel>
      <Bottom>
        <div>
          <OutlinedButton
            color="secondary"
            onClick={() => dispatch({ type: 'DATASHEET_LIST' })}
          >
            Back
          </OutlinedButton>
        </div>
      </Bottom>
    </div>
  );
};

export default ChooseDatasheetType;
