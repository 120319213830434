import history from 'routerHistory';

export function pushEvent(eventName, data = {}) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName,
    ...data,
  });
}

export function pushData(data) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
}

export function getUserIdentifier(user) {
  return `${user.firstName}${user.lastName ? user.lastName.slice(0, 1) : ''}_${
    user.userId
  }`;
}

export function setAuthSource(authEvent, authSource) {
  window.localStorage.setItem('authEvent', authEvent);
  window.localStorage.setItem('authSource', authSource);
}

export function navigateWithGtmDebug(pathname) {
  if (window.gtm_debug) {
    history.push(pathname);
    return;
  }

  window.location.pathname = pathname;
}
