/**
 *
 * OnboardingForm
 *
 */
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Form } from 'informed';
import media from 'media';

import SelectField from 'components/SelectField';
import ErrorText from 'components/ErrorText';
import { isRequired } from 'validation';
import Button from 'components/RegisterPlanForm/Button';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';
import { captureException } from 'utils/sentry';
import TextFieldBase from 'components/TextField';
import LoadingIndicator from 'components/LoadingIndicator';
import CheckboxField from 'components/CheckboxField';

const isChecked = (value) => (!value ? 'Must accept terms' : undefined);

const Div = styled.div`
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 10rem;
`;

const TextField = styled(TextFieldBase)`
  &,
  &[type='text'],
  &[type='email'],
  &[type='password'],
  &[type='tel'] {
    background: #313139;
    font-size: 0.675rem;
    height: 2.25rem;
    border-radius: 8px;
    &::-webkit-input-placeholder {
      color: hsl(0, 0%, 50%) !important;
    }

    &::-moz-placeholder {
      color: hsl(0, 0%, 50%) !important;
    }
  }
`;

const Row = styled.div`
  width: 100%;
  max-width: 20rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* ${media.tablet`
    display: flex;
    margin-bottom: 11px;
`} */
`;

const PhoneRow = styled.div`
  text-align: center;
`;

const FieldWrapper = styled.div`
  text-align: left;
  margin-bottom: 0.5rem;

  /* ${media.tablet`
    &:first-child {
      margin-right: 6px;
    }
    &:last-child {
      margin-left: 6px;
    }
    width: calc(50% - 6px); 
  `} */
`;

const SubmitButton = styled(Button)`
  margin-top: 1rem;
`;

const businessTypeOptions = [
  { value: 'smb', label: 'Business' },
  { value: 'cpa', label: 'CPA / Bookkeeper / Advisor' },
];

const clientOptions = [
  { value: '1', label: '1' },
  { value: '10', label: '2-10' },
  { value: '25', label: '11-25' },
  { value: '50', label: '26-50' },
  { value: '51', label: '51+' },
];

const roleOptions = [
  { value: 'Business Owner / CFO', label: 'Business Owner / CFO' },
  { value: 'Controller', label: 'Controller' },
  { value: 'CPA', label: 'CPA' },
  { value: 'Advisor / Fractional CFO', label: 'Advisor / Fractional CFO' },
  { value: 'Accountant / Bookkeeper', label: 'Accountant / Bookkeeper' },
];

const referenceOptions = [
  { value: 'search', label: 'Search' },
  { value: 'social', label: 'Social Media' },
  { value: 'tradeshow', label: 'Tradeshow / Event' },
  { value: 'referred', label: 'Referral' },
  { value: 'webinar', label: 'Webinar' },
  { value: 'other', label: 'Other' },
];

const ssoSources = ['GOOGLE', 'INTUIT', 'XERO'];

const QUERY = gql`
  query UserBusinessInfo {
    currentUser {
      businessType
      clients
      role
      referred
      otherReferred
      needsOnboarding
      clientPortalOnly
      phone
      company
      firstAuthMethod
      isCollaborator
    }
  }
`;

const SAVE = gql`
  mutation SetUserBusinessInfo(
    $businessType: String
    $clients: String
    $role: String
    $referred: String
    $otherReferred: String
    $phone: String
    $company: String
    $agree: Boolean
  ) {
    setUserBusinessInfo(
      businessType: $businessType
      clients: $clients
      role: $role
      referred: $referred
      otherReferred: $otherReferred
      phone: $phone
      company: $company
      agree: $agree
    ) {
      businessType
      clients
      role
      referred
      otherReferred
      needsOnboarding
      phone
      company
    }
  }
`;

const OnboardingForm = () => {
  const { data } = useQuery(QUERY);
  const [setInfo] = useMutation(SAVE);
  const handleSubmit = useCallback(
    async (values) => {
      try {
        await setInfo({
          variables: values,
          update: (cache, { data: { setUserBusinessInfo } }) => {
            cache.writeQuery({
              query: QUERY,
              data: { currentUser: { ...setUserBusinessInfo } },
            });
          },
        });
        const redirect =
          window.sessionStorage.getItem('completeSalesRedirect') || '/';
        window.location.href = redirect;
      } catch (err) {
        captureException(err, true);
      }
    },
    [setInfo]
  );

  if (!data || !data.currentUser) return <LoadingIndicator />;

  return (
    <Div>
      <h1>Welcome</h1>
      <p>
        {data.currentUser.isCollaborator || data.currentUser.clientPortalOnly
          ? 'Please accept the terms and conditions to continue'
          : 'Please tell us a little about your business'}
      </p>
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          businessType: '',
          clients: '',
          phone: '',
          company: '',
          agree: false,
        }}
      >
        {({ formState }) => {
          const incomplete = Object.keys(formState.values).some((key) => {
            if (
              !data.currentUser.isCollaborator &&
              !data.currentUser.clientPortalOnly
            ) {
              if (key === 'phone' && data.currentUser.phone) {
                return false;
              }

              if (key === 'company' && data.currentUser.company) {
                return false;
              }
            }

            if (
              ssoSources.includes(data.currentUser.firstAuthMethod) &&
              key === 'agree' &&
              !formState.values[key]
            ) {
              return true;
            }

            return formState.values[key] === '';
          });

          return (
            <>
              <Row>
                {!data.currentUser.isCollaborator &&
                  !data.currentUser.clientPortalOnly && (
                    <>
                      <FieldWrapper>
                        <SelectField
                          field="businessType"
                          options={businessTypeOptions}
                          validate={isRequired}
                          validateOnChange
                          placeholder="Business Type"
                        />
                        <ErrorText field="businessType" />
                      </FieldWrapper>
                      <FieldWrapper>
                        <SelectField
                          field="clients"
                          options={clientOptions}
                          validate={isRequired}
                          validateOnChange
                          placeholder="How many companies do you work with?"
                        />
                        <ErrorText field="clients" />
                      </FieldWrapper>
                      <FieldWrapper>
                        <SelectField
                          field="role"
                          options={roleOptions.sort(() => Math.random() - 0.5)}
                          validate={isRequired}
                          validateOnChange
                          placeholder="What role best describes you?"
                        />
                        <ErrorText field="role" />
                      </FieldWrapper>
                      <FieldWrapper>
                        <SelectField
                          field="referred"
                          options={referenceOptions.sort(
                            () => Math.random() - 0.5
                          )}
                          validate={isRequired}
                          validateOnChange
                          placeholder="How did you hear about Reach Reporting?"
                        />
                        <ErrorText field="referred" />
                      </FieldWrapper>
                      {formState.values.referred === 'other' && (
                        <FieldWrapper>
                          <TextField
                            type="text"
                            placeHolder="If other, how did you hear about us?"
                            field="otherReferred"
                            data-1p-ignore="false"
                            // add limit
                            maxLength={200}
                          />
                          <ErrorText field="other" />
                        </FieldWrapper>
                      )}
                      {!data.currentUser.phone && (
                        <FieldWrapper>
                          <TextField
                            type="text"
                            placeHolder="Phone Number"
                            field="phone"
                            validate={isRequired}
                            data-1p-ignore="false"
                          />
                          <ErrorText field="phone" />
                        </FieldWrapper>
                      )}
                      {!data.currentUser.company && (
                        <FieldWrapper>
                          <TextField
                            type="text"
                            placeHolder="Company"
                            field="company"
                            validate={isRequired}
                            data-1p-ignore="false"
                          />
                          <ErrorText field="company" />
                        </FieldWrapper>
                      )}
                    </>
                  )}

                {ssoSources.includes(data.currentUser.firstAuthMethod) && (
                  <CheckboxField
                    field="agree"
                    validate={isChecked}
                    validateOnChange
                    validateOnMount
                  >
                    I agree to the{' '}
                    <a
                      href="//reachreporting.com/terms"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      terms and conditions
                    </a>
                  </CheckboxField>
                )}
              </Row>

              <PhoneRow></PhoneRow>
              <SubmitButton disabled={incomplete}>Finish</SubmitButton>
            </>
          );
        }}
      </Form>
    </Div>
  );
};

export default OnboardingForm;
