import React from 'react';

const CsvLogoSmall = ({
  width = '80px',
  height = null,
  cursor = null,
}: React.SVGProps<SVGSVGElement> & {
  cursor?: string;
}): React.ReactElement => {
  return (
    <svg
      style={{ cursor: cursor }}
      width={width}
      height={height}
      viewBox="0 0 500 500"
    >
      <circle cx="250" cy="250" r="250" fill="#86bbdf" />
      <rect
        x="117.91"
        y="117.91"
        width="264.18"
        height="264.18"
        rx="26.48"
        fill="#fff"
      />
      <polygon
        points="339.81 183.37 216.11 183.37 216.11 137.01 183.37 137.01 183.37 183.37 137.01 183.37 137.01 216.11 183.37 216.11 183.37 339.81 216.11 339.81 216.11 216.11 339.81 216.11 339.81 183.37"
        fill="#86bbdf"
      />
    </svg>
  );
};

export default CsvLogoSmall;
