/* global google */
import React, { useEffect, useCallback, useState } from 'react';
import { gapi } from 'gapi-script';
import { API_URL } from 'environment';
import gql from 'graphql-tag';
import {
  AccountDiv,
  AccountList,
  AccountEntry,
  AccountButton,
  DeleteButton,
  GoogleButton,
  HelpSection,
  A1,
  A2,
} from './styled-components';
import { useQuery, useMutation } from '@apollo/client';

import { captureException } from 'utils/sentry';
import { showConfirm, launchPopup } from 'utils/popups';
import Spinner from 'components/Spinner';
import useCreateGoogleSheet from './useCreateGoogleSheet';
import produce from 'immer';

import googleSignInImage from './googleSignInImage.svg';

const QUERY = gql`
  query GoogleAccountList {
    currentUser {
      googleAccounts {
        id
        email
        token
        needsRefresh
      }
    }
  }
`;

const REMOVE = gql`
  mutation DisconnectGoogleAccount($googleAccountId: Int!) {
    disconnectGoogleAccount(googleAccountId: $googleAccountId)
  }
`;

const GoogleAccountList = (props) => {
  const [pickerApiLoaded, setPickerApiLoaded] = useState(false);

  const { dispatch } = props;

  const { data, loading, refetch } = useQuery(QUERY);

  const [removeAccount] = useMutation(REMOVE);

  const handleClose = useCallback(
    (event) => {
      if (event.origin === API_URL) {
        refetch();
      }
    },
    [refetch]
  );

  const handleDisconnect = useCallback(
    async (googleAccountId) => {
      try {
        if (
          !(await showConfirm({
            title: 'Are you sure?',
            text: 'Google sheets connected with this account will no longer be updated.',
          }))
        ) {
          return;
        }

        await removeAccount({
          variables: {
            googleAccountId,
          },
          optimisticResponse: {
            __typename: 'Mutation',
            disconnectGoogleAccount: true,
          },
          update: (cache) => {
            const cacheData = cache.readQuery({
              query: QUERY,
            });

            cache.writeQuery({
              query: QUERY,
              data: produce(cacheData, (draft) => {
                draft.currentUser.googleAccounts =
                  draft.currentUser.googleAccounts.filter(
                    (item) => item.id !== googleAccountId
                  );
              }),
            });
          },
        });
      } catch (err) {
        captureException(err, true);
      }
    },
    [removeAccount]
  );

  const { onCreate, loading: loadingCreate } = useCreateGoogleSheet();

  const pickerCallback = useCallback(
    async (pickerData, googleAccount) => {
      try {
        if (pickerData.action === google.picker.Action.PICKED) {
          const fileId = pickerData.docs[0].id;

          await onCreate({
            fileId,
            googleAccountId: googleAccount.id,
            dispatch,
            email: googleAccount.email,
          });
        }
      } catch (err) {
        captureException(err, true);
      }
    },
    [dispatch, onCreate]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const createPicker = useCallback(
    (googleAccount) => {
      if (pickerApiLoaded && googleAccount) {
        const docsView = new google.picker.DocsView(
          google.picker.ViewId.SPREADSHEETS
        )
          .setIncludeFolders(true)
          .setMimeTypes('application/vnd.google-apps.spreadsheet')
          .setSelectFolderEnabled(true)
          .setEnableTeamDrives(true);

        const ownDocuments = new google.picker.DocsView(
          google.picker.ViewId.SPREADSHEETS
        )
          .setOwnedByMe(true)
          .setMimeTypes('application/vnd.google-apps.spreadsheet');

        const picker = new google.picker.PickerBuilder()
          .addView(ownDocuments)
          .addView(docsView)
          .enableFeature(google.picker.Feature.SUPPORT_TEAM_DRIVES)
          /* (
            new google.picker.ViewGroup(google.picker.ViewId.SPREADSHEETS)
          ) */
          .setOAuthToken(googleAccount.token)
          .setAppId('reach-reporting-281417')
          .setDeveloperKey('AIzaSyDoi22umOloOmUlANL0ypeKi1uUJxT6KCI')
          .setCallback((dataResult) =>
            pickerCallback(dataResult, googleAccount)
          )
          .build();
        picker.setVisible(true);
      }
    },
    [pickerCallback, pickerApiLoaded]
  );

  const onPickerApiLoad = useCallback(
    function (googleAccount) {
      setPickerApiLoaded(true);
      createPicker(googleAccount);
    },
    [createPicker]
  );

  useEffect(() => {
    gapi.load('picker', onPickerApiLoad);
  }, [onPickerApiLoad]);

  useEffect(() => {
    window.addEventListener('message', handleClose, false);

    return () => {
      window.removeEventListener('message', handleClose, false);
    };
  }, [handleClose]);

  const handleGooglePicker = (googleAccount) => {
    onPickerApiLoad(googleAccount);
  };

  const handleOpenGoogleDrive = () => {
    launchPopup(`${API_URL}/google_sheets/connect`);
  };

  const googleAccounts =
    (data && data.currentUser && data.currentUser.googleAccounts) || [];

  return (
    <div>
      <AccountDiv>
        <HelpSection>Step 1: Make Copy of Template</HelpSection>
        <A1
          href="https://docs.google.com/spreadsheets/d/1msB7on7kxaRzsNfVJL6vq7Dv9btNZ9mkPJXGjKWJa68/copy"
          target="_blank"
        >
          <span className="mdi-set mdi-content-copy" />
          <span style={{ paddingLeft: '5px' }}>Copy Google Sheet Template</span>
        </A1>
        {loadingCreate ? (
          <Spinner />
        ) : (
          <>
            <HelpSection>Step 2: Select/Connect Google Account:</HelpSection>
            <AccountList>
              {loading && <Spinner />}
              {googleAccounts.map((item) => (
                <AccountEntry key={item.id}>
                  <AccountButton
                    arrow
                    onClick={() =>
                      item.needsRefresh
                        ? handleOpenGoogleDrive()
                        : handleGooglePicker(item)
                    }
                  >
                    {item.email}
                    <i className="mdi-set mdi-arrow-right" />
                  </AccountButton>
                  <DeleteButton onClick={() => handleDisconnect(item.id)}>
                    <i className="mdi-set mdi-delete" />
                  </DeleteButton>
                </AccountEntry>
              ))}
              <GoogleButton onClick={handleOpenGoogleDrive}>
                <img src={googleSignInImage} />
                Sign in with Google
              </GoogleButton>
              <HelpSection>
                <A2
                  href="https://knowledge.reachreporting.com/google-sheets-integration"
                  target="_blank"
                >
                  <span className="mdi mdi-school" />
                  <span style={{ paddingLeft: '5px' }}>Help Article</span>
                </A2>
              </HelpSection>
              <p>
                Make sure to enable all permissions to grant access to your
                Google sheets.
              </p>
              <p>
                Having trouble with an already connected account?
                <br />
                - Try clicking the &quot;Sign in with Google&quot; button to
                reestablish the connection.
                <br />- Check that cookies are not being blocked in your browser
                settings.
              </p>
            </AccountList>
          </>
        )}
      </AccountDiv>
    </div>
  );
};

export default GoogleAccountList;
