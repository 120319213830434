/**
 *
 * IntegrationFiltersModal
 *
 */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import PubSub from 'pubsub-js';

import Modal from 'components/Modal';
import FilterBox from 'components/FilterBox';
import ConsolidationFilter from 'components/ConsolidationFilter';
import Checkbox from 'components/Checkbox';
import { useReactiveVar } from '@apollo/client';
import { parentWindowScrollInfo } from 'graphql/variables';
import IntegrationTabs from './IntegrationTabs';
import media from 'media';
import { integrationToName } from 'components/IntegrationLogos/util';
import produce from 'immer';
import {
  hideIntegrationFilters,
  integrationFiltersStatus,
} from 'layouts/Main/variables';
import usePrevious from 'hooks/usePrevious';
import { convertKey } from 'components/ExposedFiltersModal/hooks';
import useIntegrationFilters from 'hooks/useIntegrationFilters';
import isNullUndefinedOrEmptyArray from 'utils/isNullUndefinedOrEmptyArray';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 400px;
  height: calc(100vh - 300px);
`;

const Div = styled.div`
  display: flex;
  ${media.tablet`
  justify-content: space-between;
  `}
  flex-grow: 1;
  flex-shrink: 1;
  max-height: ${(props) => (props.showApplyAll ? 'calc(100% - 30px)' : '100%')};
`;

const ApplyToAll = styled.div`
  text-align: right;
`;

const StyledCheckbox = styled(Checkbox)`
  & label {
    font-size: 0.75rem;
  }
  margin-bottom: 0;
`;

const StyledFilterBox = styled(FilterBox)`
  width: ${(props) => (props.half ? '50%' : 'auto')};
  overflow: auto;
`;

const defaultAllowed = ['classIds', 'departmentIds'];

const IntegrationFiltersModal = (props) => {
  const {
    title = '',
    show = false,
    allowedFilters = null,
    allowed: _allowed,
    options = [],
    optionsList = [],
    optionsListSetValues,
    optionsListValues,
    onSave = null,
    onClose = undefined,
    onChange = null,
    onSelectFilter = null,
    currentFilters = null,
    hideFilterSelect = false,
    selected = [],
    filterType = 'integrations',
    disableNumberParse = false,
    readVariables = false,
    modal = true,
    companyId,
    allowChildAndParentSelection = false,
    hasDashboardFilter = false,
  } = props;

  const allowed = _allowed || defaultAllowed;

  const [valuesByIntegration, setValues] = useState({});

  const [searchValues, setSearchValues] = useState({});
  const [filterItems, setFilterItems] = useState({});
  const [initialFilterItems, setInitialFilterItems] = useState({});
  const [applyToAll, setApplyToAll] = useState(false);

  const [selectedFiltersIds, setSelectedFiltersIds] = useState([]);

  const varStatus = useReactiveVar(integrationFiltersStatus);

  const handleClickFilter = useCallback(
    (entryKey) => {
      if (selectedFiltersIds.includes(entryKey)) {
        setSelectedFiltersIds((prevSelected) => {
          const newList = prevSelected.filter((item) => item !== entryKey);
          onSelectFilter &&
            onSelectFilter(newList.map((item) => item.split('_')[1]));
          return newList;
        });
      } else {
        setSelectedFiltersIds((prevSelected) => {
          const newList = [...prevSelected, entryKey];
          onSelectFilter &&
            onSelectFilter(newList.map((item) => item.split('_')[1]));
          return newList;
        });
      }
    },
    [onSelectFilter, selectedFiltersIds]
  );

  const scrollInfo = useReactiveVar(parentWindowScrollInfo);
  const integrationFilters = useIntegrationFilters(companyId || undefined);

  const { integrations, filtersByIntegration } = useMemo(() => {
    if (integrationFilters) {
      const result = [];
      const byIntegration = {};

      integrationFilters.forEach((filter) => {
        if (
          readVariables &&
          varStatus.integrationToShow &&
          filter.integration !== varStatus.integrationToShow
        ) {
          return;
        }

        if (!byIntegration[filter.integration]) {
          byIntegration[filter.integration] = [];
        }
        byIntegration[filter.integration].push(filter);
        if (!result.includes(filter.integration)) {
          result.push(filter.integration);
        }
      });
      return { integrations: result, filtersByIntegration: byIntegration };
    }
    return {};
  }, [integrationFilters, readVariables, varStatus.integrationToShow]);

  const convertFiltersToState = useCallback(
    (newCurrentFilters) => {
      const newValues = {};
      const newSelectedFilterIds = new Set();

      allowed.forEach((key) => {
        for (let i = 0; i < integrations.length; i++) {
          const integration = integrations[i];
          const filter = filtersByIntegration[integration].find(
            (f) => convertKey(f.key) === key || f.key === key
          );
          if (filter) {
            newSelectedFilterIds.add(`${integration}_${filter.key}`);
            newValues[`${integration}_${filter.key}`] =
              newCurrentFilters[filter.key] ||
              newCurrentFilters[convertKey(filter.key)];
          }
        }
      });

      return {
        newValues,
        newSelectedFilterIds: Array.from(newSelectedFilterIds),
      };
    },
    [allowed, filtersByIntegration, integrations]
  );

  useEffect(() => {
    if (
      !readVariables &&
      currentFilters &&
      integrations &&
      Object.keys(currentFilters).length
    ) {
      const { newValues, newSelectedFilterIds } =
        convertFiltersToState(currentFilters);

      if (newValues) {
        setValues(newValues);
        setSelectedFiltersIds(Array.from(newSelectedFilterIds));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrations, show]);

  useEffect(() => {
    if (allowedFilters && integrations) {
      const { newSelectedFilterIds } = convertFiltersToState(allowedFilters);

      setSelectedFiltersIds(Array.from(newSelectedFilterIds));
    }
  }, [allowedFilters, convertFiltersToState, integrations]);

  const prevShow = usePrevious(varStatus.show);
  useEffect(() => {
    if (
      readVariables &&
      varStatus.show &&
      !prevShow &&
      varStatus.currentFilters &&
      filtersByIntegration
    ) {
      const { newValues, newSelectedFilterIds } = convertFiltersToState(
        varStatus.currentFilters
      );

      if (newValues) {
        setValues(newValues);
        setSelectedFiltersIds(Array.from(newSelectedFilterIds));
      }
    }
  }, [
    convertFiltersToState,
    filtersByIntegration,
    integrations,
    prevShow,
    readVariables,
    varStatus.currentFilters,
    varStatus.show,
  ]);

  const prevHasDashboardFilter = usePrevious(hasDashboardFilter);
  useEffect(() => {
    if (hasDashboardFilter && !prevHasDashboardFilter) {
      setValues({});
    }
  }, [hasDashboardFilter, prevHasDashboardFilter]);

  const selectedFilters = useMemo(() => {
    const result = selectedFiltersIds.map((entry) => {
      const [integration, key] = entry.split('_');

      const filters = filtersByIntegration[integration];
      return filters.find((filter) => filter.key === key);
    });

    const { newSearchValues, newFilterItems } = result.reduce(
      (hashmap, filter) => {
        hashmap.newSearchValues[`${filter.integration}_${filter.key}`] = '';
        hashmap.newFilterItems[`${filter.integration}_${filter.key}`] =
          filter.items;
        return hashmap;
      },
      { newSearchValues: {}, newFilterItems: {} }
    );

    setSearchValues(newSearchValues);
    setInitialFilterItems(newFilterItems);
    setFilterItems(newFilterItems);

    return result;
  }, [filtersByIntegration, selectedFiltersIds]);

  const marginTop = useMemo(() => {
    if (scrollInfo) {
      return Math.max(100, scrollInfo.scrollY - scrollInfo.offsetTop + 100);
    }
    return null;
  }, [scrollInfo]);

  const optionsListSaveValue = useMemo(() => {
    if (!optionsList) return null;

    const result = {};
    optionsList.forEach((item, index) => {
      result[item.key] = optionsListValues[index];
    });
    return result;
  }, [optionsList, optionsListValues]);

  const handleClose = useCallback(() => {
    setApplyToAll(false);
    onClose && onClose();
  }, [onClose]);

  const handleSaveValue = useCallback(
    (saveValue) => {
      onSave && onSave(saveValue);
      hideIntegrationFilters();
    },
    [onSave]
  );

  const handleSave = useCallback(() => {
    if (filterType === 'queryTableFilter') {
      handleSaveValue(optionsListSaveValue);
      return;
    }

    const filterValues = {};
    let columnSubtitle = '';
    let hasMultiple = false;
    Object.keys(valuesByIntegration).forEach((integrationFilter) => {
      const [, key] = integrationFilter.split('_');
      filterValues[key] = valuesByIntegration[integrationFilter];

      if (isNullUndefinedOrEmptyArray(filterValues[key])) {
        filterValues[key] = undefined;
      } else {
        if (columnSubtitle === '') {
          if (filterItems[integrationFilter]) {
            const items = filterItems[integrationFilter].map((item) => ({
              id: item.id,
              name: item.name,
            }));

            const foundItem = items.find(
              (item) => item.id === filterValues[key][0]
            );

            if (foundItem) {
              columnSubtitle = foundItem?.name || '';
            }
            if (filterValues[key].length > 1) {
              hasMultiple = true;
            }
          }
        } else {
          hasMultiple = true;
        }
      }
    });

    if (readVariables) {
      PubSub.publish('INTEGRATION_FILTERS', {
        ...varStatus,
        filterValues,
        applyToAll,
        columnSubtitle: `${columnSubtitle}${hasMultiple ? '...' : ''}`,
      });
      hideIntegrationFilters();
    }
    onSave && onSave(filterValues);
    setApplyToAll(false);
    handleClose();
  }, [
    applyToAll,
    filterItems,
    filterType,
    handleClose,
    handleSaveValue,
    onSave,
    optionsListSaveValue,
    readVariables,
    valuesByIntegration,
    varStatus,
  ]);

  const buttons = useMemo(
    () => [
      {
        color: 'default',
        outline: true,
        text: 'Cancel',
        onClick: () => {
          if (readVariables) {
            hideIntegrationFilters();
          }
          handleClose();
        },
      },
      {
        color: 'success',
        text: 'Save',
        onClick: handleSave,
      },
    ],
    [handleClose, handleSave, readVariables]
  );

  const onChangeFilter = useCallback(
    (e, filter) => {
      const parsed =
        isNaN(e.target.value) || disableNumberParse
          ? e.target.value
          : parseInt(e.target.value, 10);

      setValues((prevState) => {
        const filterKey = `${filter.integration}_${filter.key}`;

        const newValues = produce(prevState, (draft) => {
          if (!draft[filterKey]) {
            draft[filterKey] = [];
          }
          const index = draft[filterKey].indexOf(parsed);
          if (index > -1) {
            draft[filterKey].splice(index, 1);
          } else {
            draft[filterKey].push(parsed);

            if (!allowChildAndParentSelection) {
              const removeChildren = (parentId) => {
                const children = filter.items.filter(
                  (item) => item.parentId === parentId
                );

                children.forEach((childItem) => {
                  const childIndex = draft[filterKey].indexOf(childItem.id);

                  if (childIndex > -1) {
                    draft[filterKey].splice(childIndex, 1);
                  }
                });

                children.forEach((child) => removeChildren(child.id));
              };

              removeChildren(parsed);
            }
          }
        });

        onChange &&
          onChange(
            filter.key,
            newValues[`${filter.integration}_${filter.key}`]
          );

        return newValues;
      });
    },
    [allowChildAndParentSelection, disableNumberParse, onChange]
  );

  const partialSelectedByIntegration = useMemo(() => {
    const result = {};

    if (allowChildAndParentSelection) {
      return result;
    }

    selectedFilters.forEach((filter) => {
      const values = valuesByIntegration[`${filter.integration}_${filter.key}`];

      if (!values) return;

      const partial = [];

      const insertChildren = (parentId) => {
        const children = filter.items.filter(
          (item) => item.parentId === parentId
        );

        if (children.length) {
          partial.push(...children.map((c) => c.id));

          children.forEach((c) => {
            insertChildren(c.id);
          });
        }
      };

      values.forEach((value) => {
        const item = filter.items.find((filterItem) => filterItem.id === value);

        if (item) {
          insertChildren(item.id);
        }
      });

      result[`${filter.integration}_${filter.key}`] = partial;
    });

    return result;
  }, [allowChildAndParentSelection, selectedFilters, valuesByIntegration]);

  const onChangeSearch = useCallback(
    (e, filter) => {
      const searchValue = e.target.value;

      // Update search value in input box
      setSearchValues(
        produce((draft) => {
          draft[`${filter.integration}_${filter.key}`] = searchValue;
        })
      );

      // Update filter items
      setFilterItems(
        produce((draft) => {
          draft[`${filter.integration}_${filter.key}`] = initialFilterItems[
            `${filter.integration}_${filter.key}`
          ].filter((item) =>
            searchValue === ''
              ? true
              : item.name.toLowerCase().includes(searchValue.toLowerCase())
          );
        })
      );
    },
    [initialFilterItems]
  );

  const optionListCallbacks = useMemo(() => {
    if (!optionsList) return [];

    return optionsList.map((item, i) => {
      return (e) => {
        const newSelected = e.target.value;
        optionsListSetValues(
          produce((draft) => {
            if (e.target.checked) {
              draft[i].push(newSelected);
            } else {
              const index = draft[i].indexOf(newSelected);
              if (index > -1) {
                draft[i].splice(index, 1);
              }
            }
          })
        );
      };
    });
  }, [optionsList, optionsListSetValues]);

  const optionListSelectAllCallbacks = useMemo(() => {
    if (!optionsList) return [];

    return optionsList.map((item, i) => {
      return (e) => {
        optionsListSetValues(
          produce((draft) => {
            if (e.target.checked) {
              draft[i] = item.options.map(
                (option) => option.value || option.id
              );
            } else {
              draft[i] = [];
            }
          })
        );
      };
    });
  }, [optionsList, optionsListSetValues]);

  const callbacks = useMemo(() => {
    return selectedFilters.map((filter) => {
      return (e) => onChangeFilter(e, filter);
    });
  }, [onChangeFilter, selectedFilters]);

  const searchCallbacks = useMemo(() => {
    return selectedFilters.map((filter) => {
      return (e) => onChangeSearch(e, filter);
    });
  }, [onChangeSearch, selectedFilters]);

  const selectAllCallbacks = useMemo(() => {
    const classIds = allowedFilters?.classIds || [];
    const departmentIds = allowedFilters?.departmentIds || [];
    return selectedFilters.map((filter) => {
      return (e) => {
        setValues((prevState) => {
          const newState = produce(prevState, (draft) => {
            if (!draft[`${filter.integration}_${filter.key}`]) {
              draft[`${filter.integration}_${filter.key}`] = [];
            }
            if (e.target.checked) {
              draft[`${filter.integration}_${filter.key}`] = filter.items
                .filter(
                  (item) => allowChildAndParentSelection || !item.parentId
                )
                .filter((item) => {
                  if (filter.key === 'classId' && classIds.length > 0) {
                    return classIds.includes(item.id);
                  }
                  if (
                    filter.key === 'departmentId' &&
                    departmentIds.length > 0
                  ) {
                    return departmentIds.includes(item.id);
                  }
                  return true;
                })
                .map((item) => item.id);
            } else {
              draft[`${filter.integration}_${filter.key}`] = [];
            }
          });

          onChange &&
            onChange(
              filter.key,
              newState[`${filter.integration}_${filter.key}`]
            );

          return newState;
        });
      };
    });
  }, [allowChildAndParentSelection, onChange, selectedFilters, allowedFilters]);

  const onApplyToAll = (e) => setApplyToAll(e.target.checked);

  const content = useMemo(() => {
    return (
      <Container>
        <Div showApplyAll={varStatus.showApplyAll}>
          {!hideFilterSelect && integrations && !!integrations.length && (
            <IntegrationTabs
              integrations={integrations}
              filtersByIntegration={filtersByIntegration}
              onClickFilter={handleClickFilter}
              selectedFiltersIds={selectedFiltersIds}
            />
          )}

          {filterType === 'queryTableFilter' &&
            !!optionsList &&
            optionsList.length > 0 &&
            optionsList.map((item, index) => (
              <StyledFilterBox
                key={index}
                items={item.options}
                title={item.title}
                onChange={optionListCallbacks[index]}
                selected={optionsListValues[index]}
                onSelectAll={optionListSelectAllCallbacks[index]}
                style={{
                  width:
                    optionsList.length > 1
                      ? `calc(${100 / optionsList.length}% - ${
                          24 / optionsList.length
                        }px)`
                      : 'auto',
                }}
              />
            ))}

          {selectedFilters.map((filter, index) => (
            <StyledFilterBox
              key={filter.key}
              half
              items={
                allowedFilters && allowedFilters[convertKey(filter.key)]
                  ? (
                      filterItems[`${filter.integration}_${filter.key}`] || []
                    ).filter((item) =>
                      allowedFilters[convertKey(filter.key)].includes(item.id)
                    )
                  : filterItems[`${filter.integration}_${filter.key}`]
              }
              onChange={callbacks[index]}
              selected={
                valuesByIntegration[`${filter.integration}_${filter.key}`]
              }
              partialSelected={
                partialSelectedByIntegration[
                  `${filter.integration}_${filter.key}`
                ]
              }
              onSelectAll={selectAllCallbacks[index]}
              title={`${filter.name} (${
                integrationToName[filter.integration]
              })`}
              onChangeSearch={searchCallbacks[index]}
              search={searchValues[`${filter.integration}_${filter.key}`]}
              showApplyAll={true}
            />
          ))}
        </Div>
        {varStatus.showApplyAll && (
          <ApplyToAll>
            <StyledCheckbox
              onChange={(e) => {
                onApplyToAll(e);
              }}
              checked={applyToAll}
            >
              Apply to all columns
            </StyledCheckbox>
          </ApplyToAll>
        )}
      </Container>
    );
  }, [
    hideFilterSelect,
    integrations,
    filtersByIntegration,
    handleClickFilter,
    selectedFiltersIds,
    filterType,
    optionsList,
    selectedFilters,
    varStatus.showApplyAll,
    applyToAll,
    optionListCallbacks,
    optionsListValues,
    optionListSelectAllCallbacks,
    allowedFilters,
    filterItems,
    callbacks,
    valuesByIntegration,
    partialSelectedByIntegration,
    selectAllCallbacks,
    searchCallbacks,
    searchValues,
  ]);

  return modal ? (
    filterType === 'consolidationFilter' ? (
      <ConsolidationFilter
        show={show}
        onClose={() => {
          hideIntegrationFilters();
          onClose && onClose();
        }}
        onSave={handleSaveValue}
        selected={selected}
        allowedCompanyIds={options}
        allowChildAndParentSelection={allowChildAndParentSelection}
      />
    ) : (
      <Modal
        title={title || 'Filters'}
        show={show}
        width={400 + selectedFilters.length * 200}
        buttons={buttons}
        marginTop={marginTop}
        className="in-dropdown"
        onClose={handleClose}
      >
        {content}
      </Modal>
    )
  ) : (
    content
  );
};

export default IntegrationFiltersModal;
