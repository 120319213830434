export const REFRESH_COMPANY = 'REFRESH COMPANY';
export const INTEGRATIONS = {
  QB: 1,
  XERO: 2,
  COMPANY_INFO: 3,
  SUMMIT: 4,
  CSV: 5,
  GOOGLE_SHEETS: 6,
  ACS: 7,
  GUSTO: 8,
};

export const FOLDER_ITEM_TYPES = {
  DASHBOARD: 1,
  REPORT: 2,
  TABLE: 7,
  CLIENT_PORTAL_FILE: 10,
};

export const STATUS = {
  IN_PROGRESS: 1,
  NEEDS_REVIEW: 2,
  COMPLETED: 3,
};

export const STATUS_ID_TO_NAME = {
  [STATUS.IN_PROGRESS]: 'In Progress',
  [STATUS.NEEDS_REVIEW]: 'Needs Review',
  [STATUS.COMPLETED]: 'Completed',
};

export const STATEMENT_TYPE_ID_TO_NAME = {
  1: 'Profit and Loss',
  2: 'Balance Sheet',
  3: 'Cash Flow',
};
