import React from 'react';
import { CSSProperties } from 'styled-components';
import { Input } from './styledComponents';

interface Props {
  onChange: (newSearch: string) => void;
  searchValue: string;
  placeholder?: string;
  width?: { width?: number; percent?: boolean };
  margin?: boolean;
  style?: CSSProperties;
}

const SearchBar: React.FC<Props> = ({
  onChange,
  searchValue,
  placeholder = 'Search',
  width,
  margin = true,
  style,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    onChange(e.target.value);

  return (
    <Input
      onChange={handleChange}
      value={searchValue}
      placeholder={placeholder}
      width={width?.width}
      percent={width?.percent}
      margin={margin}
      style={style}
      autoComplete="off" data-1p-ignore
    />
  );
};

export default SearchBar;
