import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

export const COMPANY_ID_QUERY = gql`
  query UseCompanyId {
    currentCompanyId @client
  }
`;

export default function useCompanyId(companyId) {
  const { loading, error, data } = useQuery(COMPANY_ID_QUERY, {
    skip: !!companyId,
  });
  
  if (companyId) return companyId;
  if (loading) return null;
  if (error) return null;
  if (!data || !data.currentCompanyId) {
    return null;
  }

  return data.currentCompanyId;
}
