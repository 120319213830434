/**
 *
 * PercentInput
 *
 */
import React from 'react';

import NumberInput from 'components/NumberInput';

function format(num) {
  return `${num}%`;
}

const PercentInput = (props) => {
  return <NumberInput {...props} formatter={format || null} />;
};

export default PercentInput;
