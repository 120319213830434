import React, { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import styled from 'styled-components';

import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import useNeedsToPay from 'hooks/useNeedsToPay';

import { captureException } from 'utils/sentry';

import DropdownButton from 'components/DropdownButton';
import { showError } from 'utils/popups';
import useImperativeQuery from 'hooks/useImperativeQuery';
import { setSelectedGroup } from 'graphql/variables';
import useMedia from 'hooks/useMedia';
import { sizes } from 'media';
import { ClientPortalPreviewLocation } from 'locations';
import useWorkspaceId from 'hooks/useWorkspaceId';
import { navigateWithGtmDebug } from 'utils/ga';
import { Company } from 'interfaces/company';
import { BUTTON_HEIGHT } from 'components/GroupedDropdown/styledComponents';

const StyledDropdownButton = styled(DropdownButton)<{ selected: boolean }>`
  height: ${BUTTON_HEIGHT}px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 0.75rem;
  background: ${(props) =>
    props.selected ? props.theme.colors.info : 'transparent'};
  color: ${(props) =>
    props.selected ? 'white' : props.theme.dropdown.color}!important;

  &:hover {
    background: ${(props) =>
      props.selected
        ? props.theme.colors.info
        : props.theme.dropdown.hover.background}!important;
    color: ${(props) =>
      props.selected ? 'white' : props.theme.dropdown.hover.color}!important;
  }

  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: inherit;
`;

const OPEN_COMPANY = gql`
  mutation WorkspaceOpenCompany($companyId: Int!) {
    setCompany(companyId: $companyId) @client
  }
`;

const CHECK_CONNECTION = gql`
  query UseConnectionActive($companyId: Int!) {
    company(id: $companyId) {
      id
      isConsolidationEmpty
      consolidationError
      allIntegrationStatus {
        integration
        active
        inactiveCompanyNames
      }
    }
  }
`;

interface CompanyButtonProps {
  item: Company;
  selected: boolean;
  closeDropdown: () => void;
}

const CompanyButton: React.FC<CompanyButtonProps> = ({
  item: { id: companyId, name, isConsolidation, firmId },
  selected,
  closeDropdown,
}) => {
  const { needsToPay, ownerNeedsToPay } = useNeedsToPay();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const phone = useMedia([`(min-width: ${sizes.phone}px)`], [false], true);
  const workspaceId = useWorkspaceId();

  const { callQuery: checkConnection } = useImperativeQuery(CHECK_CONNECTION);

  const [setCompany] = useMutation(OPEN_COMPANY, {
    variables: {
      companyId,
    },
  });

  const onOpen = useCallback(() => {
    closeDropdown();
    phone
      ? history.push({
          pathname: ClientPortalPreviewLocation.toUrl({
            workspaceId,
            companyId,
          }),
          state: {
            expandedCompanyId: companyId,
            pathname: history.location.pathname,
          },
        })
      : navigateWithGtmDebug('/');

    setCompany();
  }, [closeDropdown, companyId, history, phone, setCompany, workspaceId]);

  const onOpenCompany = useCallback(async () => {
    try {
      if (selected) return;
      const {
        data: {
          company: {
            allIntegrationStatus,
            isConsolidationEmpty,
            consolidationError,
          },
        },
      } = await checkConnection({ companyId });

      if (consolidationError) {
        setLoading(false);
        return showError({
          title: 'Consolidation Error',
          text: consolidationError,
          icon: 'info',
        });
      }

      const firstInactive =
        allIntegrationStatus.find((item) => !item.active) || null;

      if (firstInactive) {
        setLoading(false);
        return showError({
          title: 'Connection Lost',
          text: isConsolidation
            ? `The connection${
                firstInactive.inactiveCompanyNames.length > 1 ? 's' : ''
              } to ${firstInactive.inactiveCompanyNames.join(
                ', '
              )} needs to be restored.`
            : 'The connection needs to be restored.',
          icon: 'info',
        });
      }

      if (isConsolidation && isConsolidationEmpty) {
        setLoading(false);
        return showError({
          title: 'Consolidation is empty',
          text: 'The consolidation owner needs to add companies to the consolidation',
          icon: 'info',
        });
      } else if (needsToPay) {
        history.push('/subscribe');
      } else if (ownerNeedsToPay) {
        history.push('/inactive');
      } else {
        onOpen();
        setSelectedGroup(firmId);
      }
    } catch (err) {
      captureException(err, true);
      setLoading(false);
    }
  }, [
    selected,
    checkConnection,
    companyId,
    isConsolidation,
    needsToPay,
    ownerNeedsToPay,
    history,
    onOpen,
    firmId,
  ]);

  return (
    <StyledDropdownButton
      onClick={onOpenCompany}
      disabled={loading}
      selected={selected}
    >
      {name}
    </StyledDropdownButton>
  );
};

export default CompanyButton;
