/**
 *
 * LogRocket
 *
 */

import React from 'react';
import { Query } from '@apollo/client/react/components';
import gql from 'graphql-tag';
import LogRocketApi from 'logrocket';

import logRocketId from 'utils/logRocketId';

const AUTH_QUERY = gql`
  query SessionStackAuth {
    isLoggedIn @client
  }
`;

const QUERY = gql`
  query SessionStackUser {
    currentUser {
      email
      fullName
    }
  }
`;

class LogRocket extends React.PureComponent {
  render() {
    if (!logRocketId) {
      return null;
    }

    return (
      <Query query={AUTH_QUERY}>
        {({ data, loading, error }) => {
          if (loading || error) {
            return null;
          }

          if (data.isLoggedIn) {
            return (
              <Query query={QUERY}>
                {({ data, loading, error }) => {
                  if (loading || error) {
                    return null;
                  }

                  LogRocketApi.identify(data.currentUser.email, {
                    name: data.currentUser.fullName,
                    email: data.currentUser.email,
                  });

                  return null;
                }}
              </Query>
            );
          }

          return null;
        }}
      </Query>
    );
  }
}

export default LogRocket;
