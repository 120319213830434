/**
 *
 * CsvValidationTable
 *
 */
import React from 'react';
import styled from 'styled-components';

import Tooltip from 'components/Tooltip';
import Spinner from 'components/Spinner';
import { Span as ErrorSpan } from 'components/ErrorText';

import { CsvValidationResult } from './types';

const Div = styled.div`
  max-height: calc(100vh - 200px);
  overflow-y: auto;
`;

const Title = styled.h4`
  margin-bottom: 0.5rem;
`;

const Table = styled.table`
  width: 100%;
  font-size: 0.75rem;
  margin-bottom: 1rem;
`;

const Th = styled.th<{ missing: boolean }>`
  color: ${(props) =>
    props.missing ? props.theme.colors.danger : props.theme.body.color};
`;

const ErrorTd = styled.td`
  color: ${(props) => props.theme.colors.danger};
  font-weight: 700;
`;

function Header({ name, missingColumns }) {
  const missing = missingColumns.includes(name);

  return (
    <Th missing={missing}>
      {name} {missing ? '(Missing)' : null}
    </Th>
  );
}

function ErrorCell({ name, columnErrors, actualData }) {
  const columnError = columnErrors.find((item) => item.column === name);
  if (!columnError) {
    return <td>{actualData[name] === undefined ? '' : actualData[name]}</td>;
  }
  return (
    <ErrorTd>
      <Tooltip content={columnError.error}>{columnError.actual}</Tooltip>
    </ErrorTd>
  );
}

interface CsvValidationTableProps {
  validationResult: CsvValidationResult | null;
  loading: boolean;
}

const CsvValidationTable: React.FunctionComponent<CsvValidationTableProps> = (
  props
) => {
  const { validationResult, loading } = props;

  if (loading) return <Spinner />;

  if (!validationResult || validationResult.valid) return null;

  const { missingColumns, errors, errorMessage } = validationResult;

  if (missingColumns.length === 0 && errors.length === 0 && !errorMessage)
    return null;

  const showTable = missingColumns.length > 0 || errors.length > 0;

  return (
    <Div>
      <Title>Issues found</Title>
      {!!errorMessage && <ErrorSpan>{errorMessage}</ErrorSpan>}
      {showTable && (
        <Table>
          <thead>
            <tr>
              <th>Line</th>
              <Header name="Account ID" missingColumns={missingColumns} />
              <Header name="Account Name" missingColumns={missingColumns} />
              <Header
                name="Account Parent ID"
                missingColumns={missingColumns}
              />
              <Header name="Account Type" missingColumns={missingColumns} />
            </tr>
          </thead>
          <tbody>
            {errors.map(({ line, columnErrors, actualData }) => (
              <tr key={line}>
                <td>{line}</td>
                <ErrorCell
                  name="Account ID"
                  columnErrors={columnErrors}
                  actualData={actualData}
                />
                <ErrorCell
                  name="Account Name"
                  columnErrors={columnErrors}
                  actualData={actualData}
                />
                <ErrorCell
                  name="Account Parent ID"
                  columnErrors={columnErrors}
                  actualData={actualData}
                />
                <ErrorCell
                  name="Account Type"
                  columnErrors={columnErrors}
                  actualData={actualData}
                />
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Div>
  );
};

export default CsvValidationTable;
