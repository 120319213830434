/**
 *
 * AiSettings
 *
 */
import React from 'react';
import styled from 'styled-components';
import { SettingTitle } from './styledComponents';
import Toggle from 'components/Toggle';
import ProgressBar from 'components/ProgressBar';

const Row = styled.div`
  font-size: 14px;
  margin-top: 10px;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ToggleLabel = styled.div`
  margin-left: 10px;
  /* Make a gradient that goes from #52badb to #572d6a */
  background: linear-gradient(90deg, #52badb, #572d6a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
`;

const AiIcon = styled.i`
  color: white !important;
  margin-top: 4px;
`;

const ProgressLabel = styled.div`
  font-size: 0.7rem;
`;

const ProgressPercentage = styled.div`
  font-size: 0.7rem;
  margin-left: 5px;
`;

const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const VideoHeader = styled.div`
  font-weight: 600;
  margin-top: 10px;
`;

const StyledProgressBar = styled(ProgressBar)`
  height: 10px;
  max-width: 250px;
  border-radius: 10px;
  flex-grow: 1;

  & > div {
    animation: none;
    background-image: none;
    /* background: linear-gradient(90deg, #52badb, #572d6a); */
  }
`;

const Video = styled.iframe`
  margin-top: 7px;
`;

interface AiSettingsProps {
  aiEnabled: boolean;
  visible: boolean;
  demo: boolean;
  aiUsage: {
    tokensSent: number;
    tokensReceived: number;
    percentageUsed: number;
  };
  onChangeAiEnabled: (aiEnabled: boolean) => void;
}

const AiSettings: React.FC<AiSettingsProps> = (props) => {
  const { aiEnabled, aiUsage, onChangeAiEnabled, visible, demo } = props;

  return (
    <div>
      <SettingTitle>AI Enhanced Reporting</SettingTitle>
      <ToggleContainer>
        <Toggle
          checked={aiEnabled}
          onChange={(e) => onChangeAiEnabled(e?.target.checked || false)}
          icons={{
            checked: <AiIcon className="mdi-set mdi-shimmer" />,
            unchecked: <AiIcon className="mdi-set mdi-shimmer" />,
          }}
        />
        <ToggleLabel>
          {aiEnabled ? 'Disable' : 'Enable'} AI Enhanced Reporting (Beta)
        </ToggleLabel>
      </ToggleContainer>
      {aiEnabled ? (
        <>
          <SettingTitle>
            Monthly usage report. (
            {new Date().toLocaleString('default', {
              month: 'long',
              year: 'numeric',
            })}
            )
          </SettingTitle>
          <ProgressLabel>Tokens Used</ProgressLabel>
          <ProgressBarContainer>
            <StyledProgressBar progress={aiUsage.percentageUsed / 100} />
            <ProgressPercentage>{aiUsage.percentageUsed}%</ProgressPercentage>
          </ProgressBarContainer>
          <Row>
            Currently there is a set amount of tokens each month that can be
            used.
          </Row>
        </>
      ) : (
        <>
          <Row>
            Unlock the full potential of your reports with AI Enhanced
            Reporting.
            <br />
            AI Enhanced Reporting will provide you with insights and summaries
            for your financial data.
            <br />
            <br />
            Your data is securely processed and is not stored by any third
            party.
            {demo && (
              <>
                <br />
                <br />
                <strong>
                  You will not be billed for using AI Enhanced Reporting inside
                  this demo company.
                </strong>
              </>
            )}
          </Row>
          {visible && (
            <>
              <Row>
                <VideoHeader>Generate Reports/Dashboards</VideoHeader>
                <Video
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/8V8B5HaV45I?si=Th1dicT-bfctUGd-"
                  title="AI Generated Reports and Dashboards"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></Video>
              </Row>
              <Row>
                <VideoHeader>AI Analysis</VideoHeader>
                <Video
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/_eux1We2t1Y?si=jL4haWxh7z_zMEgF"
                  title="Introducing AI Enhanced Reporting"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></Video>
              </Row>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AiSettings;
