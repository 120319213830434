import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const QUERY = gql`
  query UserEmail {
    currentUser {
      email
    }
  }
`;

export default function useUserEmail() {
  const { data } = useQuery(QUERY);

  return data && data.currentUser && data.currentUser.email;
}
