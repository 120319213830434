import FileUploader, { FileUploaderProps } from 'components/FileUploader';
import React from 'react';
import CustomDropzone from './CustomDropzone';

export const MAX_FILE_SIZE = 5242880; // 5 megabytes

const LogoUploader = (
  props: Pick<
    FileUploaderProps,
    'onStage' | 'onError' | 'defaultImageUrl' | 'loading' | 'disabled'
  >
): React.ReactElement => (
  <FileUploader
    acceptedFileTypes={['png', 'jpeg', 'jpg']}
    maxFileSize={MAX_FILE_SIZE}
    CustomDropzone={<CustomDropzone />}
    {...props}
  />
);

export default LogoUploader;
