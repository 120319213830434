import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';

import getDisplayName from 'utils/getDisplayName';
import { useReactiveVar } from '@apollo/client';
import { companiesSelectedGroup } from 'graphql/variables';

function withGroupId(Component) {
  const WithGroupId = props => {
    const groupId = useReactiveVar(companiesSelectedGroup);

    return <Component {...props} groupId={groupId} />;
  };

  WithGroupId.displayName = `WithGroupId(${getDisplayName(Component)})`;

  hoistNonReactStatics(WithGroupId, Component);

  return WithGroupId;
}

export default withGroupId;
