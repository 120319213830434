/* global fetch */
import { useEffect, useReducer } from 'react';

import { API_URL } from 'environment';
import useTheme from './useTheme';

let resultPromise;

export function getWhitelabel() {
  if (resultPromise) return resultPromise;

  resultPromise = fetch(`${API_URL}/whitelabel`).then((result) =>
    result.json()
  );

  return resultPromise;
}

function reducer(state, action) {
  switch (action.type) {
    case 'RESPONSE': {
      const { response, theme, defaultLight, defaultDark, forcedTheme } =
        action;

      const result = {};
      if (response.whiteLabel) {
        if (forcedTheme) {
          result.logo =
            forcedTheme === 'dark' ? response.darkLogo : response.lightLogo;
        } else {
          result.logo =
            theme.mode === 'dark' ? response.darkLogo : response.lightLogo;
        }
        result.sidebarLogo = response.darkLogo;
        result.whiteLabel = true;
        result.favicon = response.favicon;
        result.iphoneIcon = response.iphoneIcon;
        result.sidebarFavicon = response.favicon;
      } else {
        if (forcedTheme) {
          if (forcedTheme === 'dark' && defaultDark && defaultDark.src) {
            result.logo = defaultDark.src;
            result.sidebarLogo = defaultDark.src;
            result.favicon = theme.logoFiles.dark.favicon;
          } else if (defaultLight && defaultLight.src) {
            result.logo = defaultLight.src;
            result.sidebarLogo = defaultLight.src;
            result.favicon = theme.logoFiles.light.favicon;
          } else {
            result.logo = theme.sidebar.logo;
            result.sidebarLogo = theme.sidebar.logo;
            result.favicon = theme.sidebar.favicon;
          }
        } else {
          if (theme.mode === 'dark' && defaultDark && defaultDark.src) {
            result.logo = defaultDark.src;
            result.sidebarLogo = defaultDark.src;
            result.favicon = theme.logoFiles.dark.favicon;
          } else if (defaultLight && defaultLight.src) {
            result.logo = defaultLight.src;
            result.sidebarLogo = defaultLight.src;
            result.favicon = theme.logoFiles.light.favicon;
          } else {
            result.logo = theme.sidebar.logo;
            result.sidebarLogo = theme.sidebar.logo;
            result.favicon = theme.sidebar.favicon;
          }
        }
        result.sidebarFavicon = theme.sidebar.favicon;
      }
      result.title = response.title;
      result.loading = false;
      return result;
    }
    default:
      return state;
  }
}

export default function useWhitelabel(defaultLight, defaultDark, forcedTheme) {
  const theme = useTheme();
  const [state, dispatch] = useReducer(reducer, {
    logo: null,
    loading: true,
    whiteLabel: true,
    title: '',
    favicon: '',
  });

  useEffect(() => {
    getWhitelabel().then((response) => {
      dispatch({
        type: 'RESPONSE',
        theme,
        defaultDark,
        defaultLight,
        response,
        forcedTheme,
      });
    });
  }, [
    defaultDark,
    defaultLight,
    theme.mode,
    theme.sidebar.logo,
    theme,
    forcedTheme,
  ]);

  return state;
}
