/**
 *
 * GoogleAnalyticsStripe
 *
 */
import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import Promise from 'bluebird';
import { useEffect, useState } from 'react';
import { sendDebugData } from 'utils/debug';
import { captureException } from 'utils/sentry';
import { getUserIdentifier } from 'utils/ga';

const QUERY = gql`
  query GoogleAnalyticsStripe {
    currentUser {
      firstName
      lastName
      userId
      plan
      businessType
      needsOnboarding
      masquerade
      stripeGoogleAnalytics {
        subscriptionId
        revenue
        tax
        pricingPlanName
        quantity
        variant
      }
      stripeInvoicesToPush {
        id
        date
        amount
      }
    }
  }
`;

const SET_TRIGGERED = gql`
  mutation SetStripeGoogleAnalyticsPushed {
    setStripeGoogleAnalyticsPushed {
      stripeGoogleAnalytics {
        subscriptionId
        revenue
        tax
        pricingPlanName
        quantity
        variant
      }
    }
  }
`;

const SET_INVOICES_TRIGGERED = gql`
  mutation SetStripeGoogleAnalyticsInvoicesPushed($stripeInvoiceIds: [Int!]!) {
    setStripeGoogleAnalyticsInvoicesPushed(
      stripeInvoiceIds: $stripeInvoiceIds
    ) {
      stripeInvoicesToPush {
        id
        date
        amount
      }
    }
  }
`;

const GoogleAnalyticsStripe = () => {
  const isLoggedIn = useIsLoggedIn();

  const { data } = useQuery(QUERY, {
    skip: !isLoggedIn,
  });

  const [setTriggered] = useMutation(SET_TRIGGERED);
  const [setInvoicesTriggered] = useMutation(SET_INVOICES_TRIGGERED);

  const [hasTriggered, setHasTriggered] = useState(false);
  const [hasTriggeredRecurring, setHasTriggeredRecurring] = useState(false);

  const user = data && data.currentUser;

  const isMasquerade = user && user.masquerade;

  useEffect(() => {
    if (
      isLoggedIn &&
      data &&
      data.currentUser &&
      !data.currentUser.stripeGoogleAnalytics &&
      !hasTriggered &&
      !isMasquerade &&
      window.dataLayer
    ) {
      if (window.ga) {
        window.ga(
          'set',
          'dimension3',
          data.currentUser.plan !== 'TRIAL' ? 'true' : 'false'
        );
      }
    }
  }, [data, hasTriggered, isLoggedIn, isMasquerade]);

  useEffect(() => {
    if (user && window.dataLayer && !user.needsOnboarding && !isMasquerade) {
      window.ga && window.ga('set', 'userId', user.userId);
      window.dataLayer.push({
        Authenticated: true,
      });
      window.dataLayer.push({
        userIdentifier: getUserIdentifier(user),
        userId: getUserIdentifier(user),
      });
    }
  }, [isMasquerade, user]);

  useEffect(() => {
    if (
      hasTriggeredRecurring ||
      !data ||
      !data.currentUser ||
      !data.currentUser.stripeInvoicesToPush ||
      !window.dataLayer ||
      isMasquerade
    )
      return;

    const { stripeInvoicesToPush } = data.currentUser;

    if (!stripeInvoicesToPush.length) return;

    const events = stripeInvoicesToPush.map((invoice) => ({
      event: 'stripe-recurring-payment',
      date: invoice.date,
      amount: invoice.amount,
      userId: data.currentUser.userId,
      userIdentifier: getUserIdentifier(data.currentUser),
    }));

    events.forEach((e) => window.dataLayer.push(e));

    Promise.each(events, (e) =>
      sendDebugData('stripe-recurring-payment', JSON.stringify(e))
    ).catch((e) => {
      captureException(e, false);
    });

    setInvoicesTriggered({
      variables: {
        stripeInvoiceIds: stripeInvoicesToPush.map((i) => i.id),
      },
    }).catch((err) => {
      captureException(err, false);
    });

    setHasTriggeredRecurring(true);
  }, [data, hasTriggeredRecurring, isMasquerade, setInvoicesTriggered]);

  useEffect(() => {
    if (
      hasTriggered ||
      !data ||
      !data.currentUser ||
      !data.currentUser.stripeGoogleAnalytics ||
      isMasquerade
    ) {
      return;
    }

    const { subscriptionId, revenue, tax, pricingPlanName, quantity, variant } =
      data.currentUser.stripeGoogleAnalytics;

    if (window.dataLayer) {
      const event = {
        ecommerce: {
          purchase: {
            actionField: {
              id: subscriptionId,
              revenue,
              tax,
            },
            products: [
              {
                name: pricingPlanName,
                quantity,
                variant,
              },
            ],
          },
        },
      };

      window.dataLayer.push(event);

      const otherEvent = {
        event: 'stripe-first-payment',
      };

      window.dataLayer.push(otherEvent);

      sendDebugData('ecommerce tag', JSON.stringify(event, null, 2))
        .then(() =>
          sendDebugData('event tag', JSON.stringify(otherEvent, null, 2)).catch(
            (err) => {
              captureException(err, false);
            }
          )
        )
        .catch((err) => {
          captureException(err, false);
        });

      setHasTriggered(true);
      setTriggered().catch((err) => {
        captureException(err, false);
      });
    }
  }, [data, hasTriggered, isMasquerade, setTriggered]);

  return null;
};

export default GoogleAnalyticsStripe;
