import { QUERY } from 'hooks/useBudgets';

export const updateBudget = (cache, companyId, budgetId, updatedProps) => {
  const fields = {};
  for (const key of Object.keys(updatedProps)) {
    fields[key] = () => updatedProps[key];
  }

  cache.modify({
    id: `Table:${budgetId}`,
    fields,
  });

  cache.modify({
    id: `FolderBudget:${budgetId}`,
    fields,
  });
};

export const updateCompanyDefaultsCache = (cache, companyId, company) => {
  const data = cache.readQuery({
    query: QUERY,
    variables: { companyId },
  });
  const defaults = {
    defaultBudgetId: company.defaultBudgetId,
    defaultBudgetType: company.defaultBudgetType,
    defaultForecastId: company.defaultForecastId,
    defaultForecastType: company.defaultForecastType,
  };

  cache.writeQuery({
    query: QUERY,
    variables: { companyId: companyId },
    data: {
      ...data,
      company: {
        ...data.company,
        ...defaults,
      },
    },
  });
};
