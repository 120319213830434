import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { Query } from '@apollo/client/react/components';

import NotFound from 'routes/NotFound/Loadable';

const QUERY = gql`
  query PrivateRoute {
    isLoggedIn @client
  }
`;

const PORTAL_QUERY = gql`
  query PrivateRoutePortalQuery {
    currentUser {
      clientPortalOnly
      needsOnboarding
    }
  }
`;

class PrivateRoute extends React.PureComponent {
  renderRoute = (props) => {
    const {
      component: Component,
      layout: Layout,
      allowLogin,
      loc,
      path,
    } = this.props;

    return (
      <Query query={QUERY}>
        {({ data, error, loading }) => {
          if (loading) {
            return null;
          }
          if (error) {
            return <Redirect to="/error" />;
          }
          if (!data.isLoggedIn) {
            const to = `/login${
              this.props.allowLogin ? `?redirect=${path}` : ''
            }`;
            return <Redirect to={to} />;
          }
          return (
            <Query query={PORTAL_QUERY}>
              {({ data, loading, error }) => {
                if (loading || error) return null;

                if (
                  data.currentUser.clientPortalOnly &&
                  path !== '/onboarding' &&
                  !data.currentUser.needsOnboarding
                ) {
                  return <Redirect to="/portal" />;
                }

                if (Layout) {
                  return (
                    <Layout>
                      <Component {...props} />
                    </Layout>
                  );
                }
                return <Component {...props} />;
              }}
            </Query>
          );
        }}
      </Query>
    );
  };
  render() {
    const { component, layout, loc, exact, ...rest } = this.props;

    if (loc) {
      return loc.toRoute(
        {
          render: this.renderRoute,
          invalid: NotFound,
        },
        !!exact
      );
    }

    return <Route render={this.renderRoute} exact={exact} {...rest} />;
  }
}

export default PrivateRoute;
