/**
 *
 * Main
 *
 */

import React, { Suspense } from 'react';
import styled, { ThemeProvider, withTheme } from 'styled-components';
import { Query } from '@apollo/client/react/components';
import gql from 'graphql-tag';
import { withRouter } from 'react-router-dom';
import Highcharts from 'highcharts';
import 'css/antd.css';

import withCompanyId from 'hoc/withCompanyId';
import Header from 'components/Header/index.tsx';
import Sidebar from 'components/Sidebar';
import ThemeSidebar from 'components/ThemeSidebar';
import TrialBanner from 'components/TrialBanner';
import Masquerade from 'components/Masquerade';
import DemoBanner from 'components/DemoBanner';
import SetCurrencySymbol from 'components/SetCurrencySymbol';
import ErrorCodePopup from 'components/ErrorCodePopup';

import colors from 'themes/colors';

import GlobalStyles from 'components/GlobalStyles';
import { merge } from 'utils/arrayUtils';

import lightTheme from 'themes/quantum';
import darkTheme from 'themes/dark';
import {
  closeCreateReport,
  closeFilters,
  closePublish,
  closeRenameDashboard,
  closeRenameKpi,
  closeRenameReport,
  closeRenameSpreadsheet,
  closeReportPreview,
  closeSelectData,
  showCreateDashboardModal,
  showAiDashboardGeneratorModal,
  showAiReportGeneratorModal,
} from './variables';
import RateLimitNotification from 'components/RateLimitNotification';
import XeroReauthBanner from 'components/XeroReauthBanner';
import UnpaidBanner from 'components/UnpaidBanner';
import IntegrationFiltersModal from 'components/IntegrationFiltersModal';
import GustoConnectionModal from 'components/GustoConnectionModal';
import CompanyMessage from 'components/CompanyMessage';
import LoadingIndicator from 'components/LoadingIndicator';
import TemplateJobWatcher from 'components/TemplateJobWatcher';
import DatasheetsUpdatingStatus from 'components/DatasheetsUpdatingStatus';
import media from 'media';
import withWorkspaceId from 'hoc/withWorkspaceId';
import TwoFactorAuthModal from 'components/TwoFactorAuthModal';
import getTwoFactorAuthPreface from 'utils/getTwoFactorAuthPreface';
import { getTheme, setTheme } from 'utils/theme';

const AiGeneratorModal = React.lazy(
  () => import('components/AiGeneratorModal')
);

const CreateDashboard = React.lazy(() => import('modals/CreateDashboard'));
const CreateReport = React.lazy(() => import('modals/CreateReport'));
const EditDashboardName = React.lazy(() => import('modals/EditDashboardName'));
const EditReportName = React.lazy(() => import('modals/EditReportName'));
const EditKpiName = React.lazy(() => import('modals/EditKpiName'));
const EditSpreadsheetName = React.lazy(
  () => import('modals/EditSpreadsheetName')
);

const Filters = React.lazy(() => import('modals/Filters'));
// const TableLibrary = React.lazy(() => import('modals/TableLibrary'));
const SelectData = React.lazy(() => import('modals/SelectData'));
const PreviewReport = React.lazy(() => import('modals/PreviewReport'));
const Publish = React.lazy(() => import('modals/Publish'));

const Content = styled.div`
  padding-top: ${(props) =>
    props.showFullscreen ? 0 : `${props.headerHeight}px`};
  padding-left: 0;
  ${media.phone`
      padding-left: ${(props) =>
        props.showFullscreen ? 0 : props.theme.sidebar.width};
    `}
  padding-bottom: ${(props) =>
    props.noFooter || props.showFullscreen ? 0 : '70px'};
  transition: padding-top 250ms ease-in-out;
`;

const StyledTrialBanner = styled(TrialBanner)`
  left: ${(props) => props.theme.sidebar.width};
`;
const StyledUnpaidBanner = styled(UnpaidBanner)`
  left: ${(props) => props.theme.sidebar.width};
`;

const QUERY = gql`
  query MainLayout($companyId: Int!, $workspaceId: Int!) {
    showAiDashboardGeneratorModal @client
    showAiReportGeneratorModal @client
    showCreateDashboardModal @client
    showCreateReportModal @client
    showRenameDashboardModal @client
    showRenameReportModal @client
    showRenameKpiModal @client
    showRenameSpreadsheetModal @client
    showDashboardLibraryModal @client
    showReportLibraryModal @client
    showTableLibraryModal @client
    tableLibraryItemType @client
    tableLibraryItemId @client
    showSpreadsheetModal @client
    showSelectDataModal @client
    showDashboardPreviewModal @client
    dashboardPreviewId @client
    showReportPreviewModal @client
    reportPreviewId @client
    selectDataModalSubscription @client
    selectDataModalSingle @client
    selectDataModalValue @client
    selectDataModalDates @client
    selectDataModalDisableTotals @client
    selectDataModalFilteredCompanyId @client
    selectDataModalElimination @client
    selectDataModalLiveText @client
    renameDashboardId @client
    renameReportId @client
    renameKpiId @client
    renameSpreadsheetId @client
    showDuplicateReportModal @client
    showDuplicateDashboardModal @client
    duplicateDashboardId @client
    duplicateReportId @client
    showFiltersModal @client
    integrationFiltersStatus @client {
      show
    }
    filterItemType @client
    filterItemId @client
    filterClassId @client
    filterDepartmentId @client
    filterPartialClassId @client
    filterPartialDepartmentId @client
    filterIsBudget @client
    filterShowApplyAll @client
    showPublishModal @client
    publishModalItemId @client
    publishModalItemType @client
    # showTrainingVideosModal @client
    showFullscreen @client
    selectDataModalGoogleSheets @client
    selectDataModalSummitAndersWebApp @client
    currentUser {
      theme
      hasTwoFactorAuth
    }
    company(id: $companyId) {
      id
      colors
      demo
      currency
      isConsolidation
    }
    workspace(id: $workspaceId) {
      id
      twoFactorAuthentication
      owner {
        email
      }
    }
  }
`;

const themes = {};

const mergedDarkTheme = merge(lightTheme, darkTheme);

colors.dark.forEach((t) => {
  themes[t.id] = t;
});
colors.light.forEach((t) => {
  themes[t.id] = t;
});

@withTheme
@withCompanyId
@withRouter
@withWorkspaceId
class Main extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
      headerOpen: false,
      themeSidebarOpen: false,
      sidebarCollapsed: true,
      sidebarExpanded: false,
      headerHeight: 0,
    };
    this.headerRef = null;
    this.setHeaderRef = this.setHeaderRef.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.connectionLostShown = false;
  }
  componentDidMount() {
    this.updateHeight();
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  setHeaderRef(node) {
    if (node !== null) {
      this.setState({ headerHeight: node.clientHeight });
      this.headerRef = node;
    }
  }
  onMaintenanceBannerLoad = (displayBanner) => {
    if (this.headerRef && displayBanner) {
      this.setState({
        headerHeight: this.headerRef.clientHeight,
      });
    }
  };
  onMaintenanceBannerClose = () => {
    if (this.headerRef) {
      this.setState({
        headerHeight: this.headerRef.clientHeight,
      });
    }
  };
  handleResize() {
    if (this.headerRef) {
      this.setState({ headerHeight: this.headerRef.clientHeight });
    }
  }
  handleToggleSidbarExpanded = (sidebarState) => {
    this.setState({
      sidebarExpanded: sidebarState,
    });
  };
  handleToggleSidebar = () => {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen,
      headerOpen: false,
    });
  };
  handleToggleHeader = () => {
    this.setState({
      headerOpen: !this.state.headerOpen,
      sidebarOpen: false,
    });
  };
  handleToggleThemeSidebar = () => {
    this.setState({
      themeSidebarOpen: !this.state.themeSidebarOpen,
    });
  };
  handleCloseSidebar = () => {
    this.setState({
      sidebarOpen: false,
    });
  };
  handleToggleCollapseSidebar = () => {
    this.setState(
      {
        sidebarCollapsed: !this.state.sidebarCollapsed,
      },
      () => {
        !!window.localStorage &&
          window.localStorage.setItem(
            'sidebarCollapsed',
            this.state.sidebarCollapsed
          );
        const resizeEvent = window.document.createEvent('UIEvents');
        resizeEvent.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(resizeEvent);
      }
    );
  };
  updateHeight() {
    if (!this.headerRef) return;

    const height = this.headerRef.clientHeight;
    this.setState({
      headerHeight: height,
    });
  }
  render() {
    const { theme, alwaysLightCharts, companyId, workspaceId } = this.props;

    if (!companyId || !workspaceId) {
      return <LoadingIndicator />;
    }

    return (
      <Query query={QUERY} variables={{ companyId, workspaceId }}>
        {({ data }) => {
          const dark =
            (data && data.currentUser && data.currentUser.theme === 'DARK') ||
            getTheme() === 'DARK';
          if (data && data.currentUser) {
            setTheme(data.currentUser.theme);
          }

          const newTheme = dark ? mergedDarkTheme : theme;

          if (alwaysLightCharts) {
            Highcharts.setOptions({
              ...theme.chartStyles,
            });
          } else {
            Highcharts.setOptions({
              ...newTheme.chartStyles,
            });
          }

          if (data && data.company && data.company.colors) {
            Highcharts.setOptions({
              colors: data.company.colors,
            });
          }

          const showFullscreen = !!data && data.showFullscreen;
          return (
            <ThemeProvider theme={newTheme}>
              <div>
                <GlobalStyles />
                <ErrorCodePopup />
                {!showFullscreen && (
                  <Sidebar toggleSidebar={this.handleToggleSidbarExpanded} />
                )}
                {!showFullscreen && (
                  <Header
                    ref={this.setHeaderRef}
                    videoTours
                    sidebarExpanded={this.state.sidebarExpanded}
                    onMaintenanceBannerClose={this.onMaintenanceBannerClose}
                    onMaintenanceBannerLoad={this.onMaintenanceBannerLoad}
                  />
                )}
                <Content
                  headerHeight={this.state.headerHeight}
                  showFullscreen={showFullscreen}
                  sidebarOpen={this.state.sidebarOpen}
                  sidebarCollapsed={this.state.sidebarCollapsed}
                  noFooter={this.props.noFooter}
                  className="modal-blur"
                >
                  {!!data && !!data.company && data.company.demo && (
                    <DemoBanner />
                  )}
                  {data && data.company ? (
                    this.props.children
                  ) : (
                    <LoadingIndicator />
                  )}
                </Content>
                <StyledTrialBanner />
                <XeroReauthBanner />
                <StyledUnpaidBanner redirect />
                <ThemeSidebar
                  open={this.state.themeSidebarOpen}
                  onClose={this.handleToggleThemeSidebar}
                  selected={!!data && data.theme}
                />
                <Masquerade />
                {!!data && !!data.company && (
                  <Suspense fallback={<div />}>
                    <CreateDashboard
                      show={data.showCreateDashboardModal}
                      onClose={() => showCreateDashboardModal(false)}
                    />

                    <CreateReport
                      show={data.showCreateReportModal}
                      onClose={closeCreateReport}
                    />

                    <AiGeneratorModal
                      show={data.showAiDashboardGeneratorModal}
                      isConsolidation={data.company.isConsolidation}
                      onClose={() => showAiDashboardGeneratorModal(false)}
                    />

                    <AiGeneratorModal
                      show={data.showAiReportGeneratorModal}
                      onClose={() => showAiReportGeneratorModal(false)}
                      generateReport
                    />

                    <EditDashboardName
                      show={data.showRenameDashboardModal}
                      dashboardId={data.renameDashboardId}
                      onClose={closeRenameDashboard}
                    />

                    <EditReportName
                      show={data.showRenameReportModal}
                      reportId={data.renameReportId}
                      onClose={closeRenameReport}
                    />

                    <EditKpiName
                      show={data.showRenameKpiModal}
                      kpiId={data.renameKpiId}
                      onClose={closeRenameKpi}
                    />

                    <EditSpreadsheetName
                      show={data.showRenameSpreadsheetModal}
                      spreadsheetId={data.renameSpreadsheetId}
                      onClose={closeRenameSpreadsheet}
                    />

                    <Filters
                      show={data.showFiltersModal}
                      itemType={data.filterItemType}
                      itemId={data.filterItemId}
                      classId={data.filterClassId}
                      departmentId={data.filterDepartmentId}
                      partialClassId={data.filterPartialClassId}
                      partialDepartmentId={data.filterPartialDepartmentId}
                      isBudget={data.filterIsBudget}
                      showApplyAll={data.filterShowApplyAll}
                      onClose={closeFilters}
                    />

                    <SelectData
                      show={data.showSelectDataModal}
                      subscription={data.selectDataModalSubscription}
                      single={data.selectDataModalSingle}
                      value={data.selectDataModalValue}
                      dates={data.selectDataModalDates}
                      disableTotals={data.selectDataModalDisableTotals}
                      filteredCompanyId={data.selectDataModalFilteredCompanyId}
                      elimination={data.selectDataModalElimination}
                      liveText={data.selectDataModalLiveText}
                      googleSheetsOnly={data.selectDataModalGoogleSheets}
                      summitAndersWebApp={
                        data.selectDataModalSummitAndersWebApp
                      }
                      onClose={closeSelectData}
                    />

                    <PreviewReport
                      show={data.showReportPreviewModal}
                      reportId={data.reportPreviewId}
                      onClose={closeReportPreview}
                    />
                    <Publish
                      show={data.showPublishModal}
                      itemId={data.publishModalItemId}
                      itemType={data.publishModalItemType}
                      onClose={closePublish}
                    />

                    <IntegrationFiltersModal
                      readVariables
                      show={data?.integrationFiltersStatus?.show}
                    />

                    <CompanyMessage />

                    <SetCurrencySymbol />

                    <RateLimitNotification />

                    <GustoConnectionModal />

                    <TemplateJobWatcher />

                    <DatasheetsUpdatingStatus />
                  </Suspense>
                )}
                <TwoFactorAuthModal
                  show={
                    data?.workspace?.twoFactorAuthentication &&
                    !data?.currentUser?.hasTwoFactorAuth
                  }
                  preface={getTwoFactorAuthPreface({
                    type: 'workspace',
                    contact: data?.workspace?.owner?.email,
                  })}
                  workspaceControls
                />
              </div>
            </ThemeProvider>
          );
        }}
      </Query>
    );
  }
}

export default Main;
