/**
 *
 * ExcelSheetErrors
 *
 */
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { CREATE_EXCEL_DATASHEET, GOOGLE_SHEET_QUERY } from '../mutations';
import { BottomExcel, Scroll, Table, Tr } from '../common-styled-components';
import { TableStyle } from '../styledComponents';
import { ConfirmButton } from './styled-components';
import { useMutation } from '@apollo/client';
import { captureException } from 'utils/sentry';
import useCompanyId from 'hooks/useCompanyId';
import produce from 'immer';

const Th = styled.th`
  color: ${(props) =>
    props.missing ? props.theme.colors.danger : props.theme.body.color};
`;

const ErrorTd = styled.td`
  color: ${(props) => props.theme.colors.danger};
  font-weight: 700;
`;

function Header({ name, missingColumns }) {
  const missing = missingColumns && missingColumns.includes(name);

  return (
    <Th missing={missing}>
      {name} {missing ? '(Missing)' : null}
    </Th>
  );
}

function ErrorCell({ name, errors, actualData }) {
  const columnError = errors.find((item) => item.column === name);

  if (!columnError) {
    return <td>{actualData[name] === undefined ? '' : actualData[name]}</td>;
  }
  return (
    <ErrorTd>
      {`${columnError.actual || ''} (${columnError.message || ''})`}
    </ErrorTd>
  );
}

const ExcelSheetErrors = (props) => {
  const {
    dispatch,
    googleSpreadsheetErrors: {
      errors: { missingColumns, rowErrors, excelTitleError },
    },
    excelFile,
    googleSpreadsheetId,
    onExcelSuccess,
  } = props;

  const [createExcelDatasheet, { loading }] = useMutation(
    CREATE_EXCEL_DATASHEET
  );
  const companyId = useCompanyId();

  const handleSkipExcel = useCallback(async () => {
    try {
      const { data } = await createExcelDatasheet({
        variables: {
          companyId: companyId,
          googleSpreadsheetId: googleSpreadsheetId,
          excelFile: excelFile,
          isExcel: true,
          ignoreErrors: true,
        },
        update: (
          cache,
          {
            data: {
              createExcelDatasheet: { success, googleSpreadsheet },
            },
          }
        ) => {
          if (success) {
            const cacheData = cache.readQuery({
              query: GOOGLE_SHEET_QUERY,
              variables: {
                companyId,
              },
            });

            cache.writeQuery({
              query: GOOGLE_SHEET_QUERY,
              variables: {
                companyId,
              },
              data: produce(cacheData, (draft) => {
                const existing = draft.company.googleSpreadsheets.find(
                  (item) => item.id === googleSpreadsheet.id
                );

                if (!existing) {
                  draft.company.googleSpreadsheets = [
                    ...draft.company.googleSpreadsheets,
                    googleSpreadsheet,
                  ];
                }
              }),
            });
          }
        },
      });
      onExcelSuccess(data.createExcelDatasheet.googleSpreadsheet);
    } catch (err) {
      captureException(err, true);
    }
  }, [
    companyId,
    createExcelDatasheet,
    excelFile,
    googleSpreadsheetId,
    onExcelSuccess,
  ]);

  let duplicateError = false;

  if (rowErrors && rowErrors.length > 0) {
    duplicateError = rowErrors.find((rowError) => {
      return rowError.errors.find((error) => {
        return error.message === 'duplicate';
      });
    });
  }

  return (
    <div>
      {!excelTitleError && (
        <Scroll>
          <TableStyle hasFooter={true}>
            <Table>
              <thead>
                <Tr>
                  <th>Line</th>
                  <Header name="Id" missingColumns={missingColumns} />
                  <Header name="Name" missingColumns={missingColumns} />
                </Tr>
              </thead>
              <tbody>
                {rowErrors &&
                  rowErrors.map((rowError, i) => (
                    <Tr key={i}>
                      <td>{rowError.line}</td>
                      <ErrorCell
                        name="Id"
                        errors={rowError.errors}
                        actualData={rowError.actualData}
                      />
                      <ErrorCell
                        name="Name"
                        errors={rowError.errors}
                        actualData={rowError.actualData}
                      />
                    </Tr>
                  ))}
              </tbody>
              <tfoot></tfoot>
            </Table>
          </TableStyle>
        </Scroll>
      )}
      {excelTitleError && (
        <div style={{ paddingTop: '20px' }}>
          <p>
            <span style={{ color: '#ff5c75' }}>Warning:</span> This title{' '}
            <span
              style={{ color: '#ff5c75' }}
            >{`${excelTitleError[0].oldName}`}</span>{' '}
            does not match the previously uploaded Excel sheet title{' '}
            <span
              style={{ color: '#ff5c75' }}
            >{`${excelTitleError[0].newName}`}</span>
          </p>
        </div>
      )}
      <BottomExcel>
        {(!missingColumns || missingColumns.length === 0) &&
          !duplicateError && (
            <div>
              <ConfirmButton
                onClick={handleSkipExcel}
                disabled={loading}
                loading={loading}
              >
                Ignore Errors
              </ConfirmButton>
            </div>
          )}
        {missingColumns && missingColumns.length !== 0 && (
          <div style={{ fontSize: '10pt' }}>
            Missing ID/Name Errors Must Be Resolved
          </div>
        )}
        {duplicateError && (
          <div style={{ fontSize: '10pt' }}>
            Duplicate Errors Must Be Resolved
          </div>
        )}
      </BottomExcel>
    </div>
  );
};

export default ExcelSheetErrors;
