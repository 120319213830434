import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
`

const DateColumn = ({ createdAt }) => {
  const date = new Date(createdAt)
  return (
    <Container>
      <span>{date.toLocaleString()}</span>
    </Container>
  );
};

export default DateColumn;
