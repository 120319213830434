import React, { ReactElement } from 'react';

const GearIcon = ({
  color = '#f9fafc',
  width = '100%',
  height = '100%',
  cursor,
  onClick,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  cursor?: string;
  onClick?: (any) => void;
}): ReactElement => {
  return (
    <>
      <svg
        {...props}
        width={width}
        height={height}
        onClick={(e) => onClick && onClick(e)}
        cursor={cursor}
        viewBox="0 0 13 14"
      >
        <path
          fill={color}
          d="M4.391 7.584a2.222 2.222 0 1 1 2.054 1.373 2.226 2.226 0 0 1-2.054-1.373zm6.928 3.44a.747.747 0 0 0 .084-.957l-.566-.807c.218-.38.387-.786.501-1.21l.97-.17a.744.744 0 0 0 .624-.734v-.833a.745.745 0 0 0-.625-.734l-.969-.17a5.039 5.039 0 0 0-.502-1.21l.567-.798a.745.745 0 0 0-.084-.956l-.583-.583a.747.747 0 0 0-.957-.084l-.807.565v.001a5.043 5.043 0 0 0-1.21-.5l-.17-.97a.744.744 0 0 0-.734-.625h-.833a.746.746 0 0 0-.735.624l-.17.97c-.424.114-.83.282-1.21.501l-.797-.566a.747.747 0 0 0-.957.084l-.583.583a.746.746 0 0 0-.077.956l.566.808a5.03 5.03 0 0 0-.502 1.21l-.97.17a.747.747 0 0 0-.624.734v.833a.747.747 0 0 0 .624.734l.97.171c.114.424.283.83.502 1.21l-.566.796a.748.748 0 0 0 .083.957l.583.583c.257.257.66.292.958.084l.807-.566c.38.218.786.387 1.208.501l.171.97c.06.36.371.623.735.624h.833a.749.749 0 0 0 .735-.625l.17-.969c.423-.114.83-.283 1.21-.5l.79.558c.295.21.7.178.957-.077z"
        />
      </svg>
    </>
  );
};

export default GearIcon;
