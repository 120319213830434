import CheckCircleIcon from 'components/Icons/CheckICirclecon';
import { Video } from 'interfaces/videos';
import React, { useEffect, useRef, useState } from 'react';
import {
  detailsVariants as _detailsVariants,
  thumbnailVariants,
} from './animations';
import {
  Container,
  Description,
  Details,
  Thumbnail,
  Title,
} from './styledComponents';

interface Props {
  onClick: (video: Video) => void;
  video: Video;
  watched: boolean;
}

const VideoItem = ({ video, onClick, watched }: Props): React.ReactNode => {
  const [descHeight, setDescHeight] = useState<number>(0);
  const descRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      descRef &&
      descRef.current &&
      descHeight !== descRef.current.offsetHeight
    ) {
      setDescHeight(descRef.current.offsetHeight);
    }
  }, [descHeight]);

  const detailsVariants = descHeight !== 0 ? _detailsVariants(descHeight) : {};

  return (
    <Container
      onClick={() => onClick(video)}
      initial="rest"
      whileHover="hover"
      animate="rest"
    >
      <Thumbnail src={video.thumbnail} variants={thumbnailVariants} />
      <Details descHeight={descHeight} variants={detailsVariants}>
        <Title>
          <CheckCircleIcon diameter={20} checked={watched} />
          <span>{`${video.order + 1}. ${video.name}`}</span>
        </Title>
        <Description ref={descRef}>{video.description}</Description>
      </Details>
    </Container>
  );
};

export default VideoItem;
