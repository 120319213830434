/**
 *
 * TrainingVideos
 *
 */

import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Fuse from 'fuse.js';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CloseIcon from '@material-ui/icons/Close';
import TreeItem from '@material-ui/lab/TreeItem';
import { Typography, IconButton, Box } from '@material-ui/core';
import Modal from 'components/Modal';
import { SearchBar } from 'components/GroupCompanyList/components';
import largeThumbnail from 'images/large-video-thumbnail.png';
import {
  BackButton,
  Bar,
  Container,
  Div,
  DivVideoList,
  imgSources,
  LargeThumbnail,
  LargeThumbnailContainer,
  LargeThumbnailPlay,
  ListSide,
  ListSideContainer,
  Logo,
  PlayerWrapper,
  Progress,
  ProgressBar,
  Search,
  StyledReactPlayer,
  TreeView,
  useStyles,
  VideoContainer,
  VideoSide,
} from './styledComponents';
import VideoItem from './VideoItem';
import { produce } from 'immer';
import Spinner from 'components/Spinner';

const getVideosList = (list) => {
  const result = [];

  list.forEach((item) => {
    if (item.youtubeId) {
      result.push(item);
    } else if (item.items && item.items.length) {
      result.push(...getVideosList(item.items));
    }
  });

  return result;
};

const VideoWidgetLarge = ({ onClick, videoYoutubeId }) => {
  return (
    <LargeThumbnailContainer onClick={onClick}>
      <LargeThumbnail
        background={
          videoYoutubeId
            ? `https://img.youtube.com/vi/${videoYoutubeId}/0.jpg`
            : largeThumbnail.src
        }
      >
        <LargeThumbnailPlay></LargeThumbnailPlay>
      </LargeThumbnail>
    </LargeThumbnailContainer>
  );
};

const QUERY = gql`
  query getTrainingVideos {
    basicTrainingVideos: getTrainingVideosBySectionName(sectionName: "BASIC") {
      id
      name
      description
      youtubeId
      thumbnail
      order
      trainingVideoSectionId
    }
    completedTrainingVideos {
      id
      trainingId
    }

    trainingVideos: setting(name: "trainingVideos")
  }
`;

const SET_VIDEO_WATCHED = gql`
  mutation setVideoWatched($videoId: Int!) {
    setVideoWatched(videoId: $videoId) {
      id
      trainingId
    }
  }
`;

const COMPLETED_VIDEOS = gql`
  query completedTrainingVideos {
    completedTrainingVideos {
      id
      trainingId
    }
  }
`;

const TrainingVideos = (props) => {
  const { show, onClose } = props;
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const [searchValue, setSearchValue] = useState();

  const [videoUrl, setVideoUrl] = useState();
  const [videoTitle, setVideoTitle] = useState();
  const [videoDescription, setVideoDescription] = useState();
  const [videoYoutubeId, setVideoYoutubeId] = useState();
  const [loadedImages, setLoadedImages] = useState(false);
  const { data, loading, error } = useQuery(QUERY);
  const [setVideoWatched] = useMutation(SET_VIDEO_WATCHED);

  const [singleVideoView, setSingleVideoView] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);

  const classes = useStyles();
  const { trainingVideos, basicTrainingVideos, completedTrainingVideos } =
    useMemo(() => {
      if (
        data &&
        data.trainingVideos &&
        data.basicTrainingVideos &&
        data.completedTrainingVideos
      ) {
        setLoadedImages(new Array(data.basicTrainingVideos.length).fill(false));
        return {
          trainingVideos: JSON.parse(data.trainingVideos),
          basicTrainingVideos: data.basicTrainingVideos,
          completedTrainingVideos: data.completedTrainingVideos.map(
            (v) => v.trainingId
          ),
        };
      }
      return {
        trainingVideos: [],
        basicTrainingVideos: [],
        completedTrainingVideos: [],
      };
    }, [data]);

  const videoSearchList = useMemo(
    () => getVideosList(trainingVideos),
    [trainingVideos]
  );

  const fuse = useMemo(
    () =>
      new Fuse(videoSearchList, {
        keys: ['title', 'description'],
        threshold: 0.4,
      }),
    [videoSearchList]
  );

  const searchResults = useMemo(
    () => (searchValue ? fuse.search(searchValue) : []),
    [fuse, searchValue]
  );

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  const handleBack = () => {
    setSingleVideoView(false);
    setPlayVideo(false);
    setVideoTitle(null);
    setVideoDescription(null);
  };

  const handleClose = () => {
    onClose && onClose();
    handleBack();
  };

  const handleClickVideo = useCallback(
    ({ id, name, description, youtubeId, trainingVideoSectionId }) => {
      setVideoUrl(
        `https://www.youtube.com/watch?v=${youtubeId}&feature=emb_title`
      );
      setVideoTitle(name);
      setVideoDescription(description);
      setVideoYoutubeId(youtubeId);
      setSingleVideoView(true);
      setPlayVideo(true);
      if (trainingVideoSectionId && !completedTrainingVideos.includes(id)) {
        setVideoWatched({
          variables: { videoId: id },
          update: (cache, result) => {
            const cachedData = cache.readQuery({
              query: COMPLETED_VIDEOS,
            });
            if (cachedData) {
              cache.writeQuery({
                query: COMPLETED_VIDEOS,
                data: produce(cachedData, (draft) => {
                  draft.completedTrainingVideos = [
                    ...draft.completedTrainingVideos,
                    result.data.setVideoWatched,
                  ];
                }),
              });
            }
          },
        });
      }
    },
    [completedTrainingVideos, setVideoWatched]
  );

  const progress = useMemo(
    () =>
      Math.round(
        (completedTrainingVideos.length / basicTrainingVideos.length) * 100
      ),
    [basicTrainingVideos.length, completedTrainingVideos.length]
  );

  const handleOnPlayVideo = () => {
    setPlayVideo(true);
  };

  const handleOnSearchText = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    if (basicTrainingVideos.length) {
      const loadImage = (src) => {
        return new Promise((resolve, reject) => {
          // eslint-disable-next-line no-undef
          const _loadImg = new Image();
          _loadImg.src = src;
          _loadImg.onload = () => resolve(src);
          _loadImg.onerror = (err) => reject(err);
        });
      };

      Promise.all(
        basicTrainingVideos.map((video) => loadImage(video.thumbnail))
      )
        .then(() => setLoadedImages(true))
        .catch((err) => console.log('Failed to load images', err));
    }
  }, [basicTrainingVideos, basicTrainingVideos.length]);

  if (loading || error || !data || !data.trainingVideos) {
    return null;
  }

  let index = 1;

  return (
    <Modal
      show={show}
      title="Training Videos"
      width={1200}
      blur={false}
      onClose={handleClose}
      useCurrentTheme
      hideHeader
      bodyClass={{ padding: 0 }}
    >
      <Container flex>
        <ListSideContainer flex={1} column>
          <Logo src={imgSources}></Logo>
          <Search>
            <SearchBar onChange={handleOnSearchText} value={searchValue} />
          </Search>
          <ListSide>
            <TreeView
              key={searchValue}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              expanded={expanded}
              selected={selected}
              onNodeToggle={handleToggle}
              onNodeSelect={handleSelect}
            >
              {!searchValue &&
                trainingVideos.map((item) => {
                  return (
                    <TreeItem
                      classes={{
                        label: classes.labelPrimary,
                        iconContainer: classes.iconContainer,
                      }}
                      key={item.title}
                      nodeId={index++}
                      label={item.title}
                    >
                      {item.items &&
                        item.items.length > 0 &&
                        item.items.map((sub) => (
                          <TreeItem
                            key={sub.title}
                            nodeId={index++}
                            label={sub.title}
                            classes={{
                              label: classes.label,
                              iconContainer: classes.iconContainer,
                            }}
                            className={
                              sub.youtubeId ? 'video-link' : 'category'
                            }
                            onClick={
                              sub.youtubeId ? () => handleClickVideo(sub) : null
                            }
                          >
                            {sub.items &&
                              sub.items.length > 0 &&
                              sub.items
                                .filter((ob) =>
                                  searchValue && searchValue !== ''
                                    ? ob.title
                                        .toLowerCase()
                                        .search(searchValue.toLowerCase()) !==
                                      -1
                                    : item
                                )
                                .map((video) => (
                                  <TreeItem
                                    classes={{
                                      label: classes.label,
                                      iconContainer: classes.iconContainer,
                                    }}
                                    onClick={() => handleClickVideo(video)}
                                    key={video.title + video.id}
                                    nodeId={index++}
                                    label={video.title}
                                    className={
                                      video.youtubeId
                                        ? 'video-link'
                                        : 'category'
                                    }
                                  />
                                ))}
                          </TreeItem>
                        ))}
                    </TreeItem>
                  );
                })}
              {!!searchValue &&
                searchResults.map((video) => (
                  <TreeItem
                    classes={{
                      label: classes.label,
                      iconContainer: classes.iconContainer,
                    }}
                    className="video-link"
                    onClick={() => handleClickVideo(video)}
                    key={video.title}
                    nodeId={index++}
                    label={video.title}
                  />
                ))}
            </TreeView>
          </ListSide>
        </ListSideContainer>

        <VideoContainer>
          <Div
            minHeight="30px"
            height="auto"
            justify="space-between"
            style={{ flexDirection: 'row', display: 'flex' }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <BackButton
                onClick={handleBack}
                animate={
                  singleVideoView
                    ? { width: 50, transition: { duration: 0.05 } }
                    : { width: 0, transition: { duration: 0.05 } }
                }
              >
                <ChevronLeftIcon
                  fontSize="large"
                  style={{ fontSize: '2rem' }}
                />
              </BackButton>

              <Typography
                variant="h4"
                style={{ fontSize: '2rem', marginBottom: '5px' }}
              >
                {videoTitle || 'Learn the Basics'}
              </Typography>
            </div>
            <IconButton
              size="small"
              onClick={handleClose}
              className={classes.iconBackContainer}
              style={{ height: 30, width: 30, marginTop: '10px' }}
            >
              <CloseIcon />
            </IconButton>
          </Div>
          {!singleVideoView && (
            <Div
              minHeight="5px"
              height="auto"
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '0.75rem',
                color: '#4FBADE',
              }}
            >
              Progress
              <ProgressBar>
                <Bar>
                  <Progress progress={progress} />
                </Bar>
              </ProgressBar>
              <span>{progress}%</span>
            </Div>
          )}

          <Div minHeight="30px" height="auto" style={{ fontSize: '14px' }}>
            <Typography variant="p">
              {videoDescription ||
                (basicTrainingVideos.length ===
                completedTrainingVideos.length ? (
                  <>
                    🥳 Congrats! You&apos;re a pro! Download your Partner Badge
                    <a
                      href="https://reachreporting.com/partner-badge"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginLeft: '5px' }}
                    >
                      HERE
                    </a>
                  </>
                ) : (
                  'Quickly learn the basics of Reach Reporting. Get the Partner Badge after watching all the Learn the Basic videos.'
                ))}
            </Typography>
          </Div>
          <Box pt={1} />
          {singleVideoView ? (
            <Div flex width="100%">
              {!playVideo ? (
                <VideoWidgetLarge
                  onClick={handleOnPlayVideo}
                  videoYoutubeId={videoYoutubeId}
                />
              ) : (
                <VideoSide>
                  <PlayerWrapper>
                    <StyledReactPlayer
                      url={videoUrl}
                      playing
                      controls
                      width="100%"
                      height="100%"
                      config={{
                        youtube: {
                          playerVars: {
                            showinfo: 0,
                            modestbranding: 1,
                          },
                        },
                      }}
                    />
                  </PlayerWrapper>
                </VideoSide>
              )}
            </Div>
          ) : loadedImages ? (
            <DivVideoList>
              {basicTrainingVideos.map((item, i) => (
                <VideoItem
                  key={i}
                  onClick={handleClickVideo}
                  video={item}
                  watched={completedTrainingVideos.includes(item.id)}
                />
              ))}
            </DivVideoList>
          ) : (
            <div>
              <Spinner />
            </div>
          )}
        </VideoContainer>
      </Container>
    </Modal>
  );
};

export default TrainingVideos;
