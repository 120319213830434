import { useState, useCallback } from 'react';
import produce, { Draft, original } from 'immer';
import { Moment } from 'moment';

import {
  RowChange,
  TableColumn,
  CompanyFilterOverride,
  DateOverride,
  FilterOverride,
} from 'components/TableBuilder/types';
import { arrayElementsEqual, arrayMove } from 'utils/arrayUtils';

const getDefaultDateOverride = (
  _columns
): { default: boolean; columns: JSON; title: string } => {
  const columns = {} as JSON;

  _columns.forEach((column) => {
    if (column.type === 'DATE') {
      columns[column.id] = {
        title: column.title,
        type: column.type,
        range: column.range,
        startDate: column.startDate,
        endDate: column.endDate,
        rangeOffset: column.rangeOffset,
      };
    }
  });

  return {
    title: '',
    columns,
    default: true,
  };
};

export interface ExposedFilterState {
  readonly dateOverride: DateOverride;
  readonly filterOverride: FilterOverride;
  readonly companyFilterOverride: CompanyFilterOverride;
  readonly rowChange: RowChange;
}

interface ExposedFilterParams extends ExposedFilterState {
  columns: Array<TableColumn>;
}

export type SetOverrideEnabledFunc = (enabled: boolean) => void;
export type SetOverrideTitleFunc = (title: string) => void;
export type SetOverrideEntryTitleFunc = (
  index: number,
  newTitle: string
) => void;
export type SetDateOverrideColumnRangeFunc = (
  tab: number,
  colId: string,
  startDate: Moment,
  endDate: Moment,
  range: string,
  rangeOffset: number
) => void;
export type SetDateOverrideColumnTitleFunc = (
  tab: number,
  colId: string,
  title: string
) => void;
export type SetDateOverrideColumnUseTopItemRangeFunc = (
  tab: number,
  colId: string,
  useTopItemRange: boolean
) => void;
export type SetFilterOverrideIdsFunc = (ids: Array<number | string>) => void;
export type SetKeyFilterOverrideIdsFunc = (
  key: string | number,
  ids: Array<number | string>
) => void;
export type SetRowChangeNumberFunc = (number) => void;
export type RemoveOverrideEntryFunc = (number) => void;
export type SetRowChangeEntryValueFunc = (number, string) => void;
export type MoveOverrideEntryFunc = (
  oldIndex: number,
  newIndex: number
) => void;

export type ExposedFilterCallbacks = {
  resetState: () => void;
  setDateOverrideEnabled: SetOverrideEnabledFunc;
  addDateOverrideEntry: () => void;
  removeDateOverrideEntry: RemoveOverrideEntryFunc;
  moveDateOverrideEntry: MoveOverrideEntryFunc;
  setDateOverrideTitle: SetOverrideEntryTitleFunc;
  setDateOverrideColumnRange: SetDateOverrideColumnRangeFunc;
  setDateOverrideColumnTitle: SetDateOverrideColumnTitleFunc;
  setDateOverrideColumnUseTopItemRange: SetDateOverrideColumnUseTopItemRangeFunc;

  setFilterOverrideEnabled: SetOverrideEnabledFunc;
  setFilterOverrideTitle: SetOverrideTitleFunc;
  setFilterOverrideClassIds: SetFilterOverrideIdsFunc;
  setFilterOverrideDepartmentIds: SetFilterOverrideIdsFunc;
  setFilterOverrideIds: SetKeyFilterOverrideIdsFunc;
  setFilterOverrideFilterKeys: SetFilterOverrideIdsFunc;

  setCompanyFilterOverrideEnabled: SetOverrideEnabledFunc;
  setCompanyFilterOverrideTitle: SetOverrideTitleFunc;
  setCompanyFilterOverrideIds: SetFilterOverrideIdsFunc;

  setRowChangeEnabled: SetOverrideEnabledFunc;
  setRowChangeTitle: SetOverrideTitleFunc;
  setRowChangeNumber: SetRowChangeNumberFunc;
  addRowChangeEntry: () => void;
  removeRowChangeEntry: RemoveOverrideEntryFunc;
  setRowChangeEntryTitle: SetOverrideEntryTitleFunc;
  setRowChangeEntryValue: SetRowChangeEntryValueFunc;
  moveRowChangeEntry: MoveOverrideEntryFunc;
};

type UseExposedFilterStateResult = [ExposedFilterState, ExposedFilterCallbacks];

type UpdateFunc = (draft: Draft<ExposedFilterState>) => void;

export function convertKey(key: string): string {
  if (key === 'classId') {
    return 'classIds';
  }
  if (key === 'departmentId') {
    return 'departmentIds';
  }

  return key;
}

export function useExposedFilterState(
  params: ExposedFilterParams
): UseExposedFilterStateResult {
  const { columns, ...rest } = params;

  const [state, setState] = useState(rest);

  const resetState = useCallback(() => setState(rest), [rest]);

  const update = useCallback(
    (callback: UpdateFunc) => setState(produce(callback)),
    []
  );

  /**
   * Date Overrides
   */
  const setDateOverrideEnabled = useCallback(
    (enabled) =>
      update((draft: Draft<ExposedFilterState>) => {
        draft.dateOverride.enabled = enabled;
      }),
    [update]
  );

  const addDateOverrideEntry = useCallback(
    () =>
      update((draft: Draft<ExposedFilterState>) => {
        draft.dateOverride.entries.push({
          ...getDefaultDateOverride(columns),
          default: false,
          title: 'New Option',
        });
      }),
    [update, columns]
  );

  const removeDateOverrideEntry = useCallback(
    (index) =>
      update((draft: Draft<ExposedFilterState>) => {
        draft.dateOverride.entries.splice(index, 1);
      }),
    [update]
  );

  const moveDateOverrideEntry = useCallback(
    (oldIndex, newIndex) =>
      update((draft) => {
        draft.dateOverride.entries = arrayMove(
          draft.dateOverride.entries,
          oldIndex,
          newIndex
        );
      }),
    [update]
  );

  const setDateOverrideTitle = useCallback(
    (tab, newTitle) =>
      setState(
        produce((draft: Draft<ExposedFilterState>) => {
          const dateOverride = draft.dateOverride;
          if (!dateOverride.entries[tab]) {
            dateOverride.entries[tab] = { ...getDefaultDateOverride(columns) };
          }
          dateOverride.entries[tab].title = newTitle;
        })
      ),
    [columns]
  );

  const setDateOverrideColumnRange = useCallback(
    (tab, colId, startDate, endDate, range, rangeOffset) => {
      setState(
        produce((draft: Draft<ExposedFilterState>) => {
          const dateOverride = draft.dateOverride;

          if (!dateOverride.entries[tab]) {
            dateOverride.entries[tab] = { ...getDefaultDateOverride(columns) };
          }
          if (!dateOverride.entries[tab].columns[colId]) {
            dateOverride.entries[tab].columns[colId] = {
              title: '',
              startDate,
              endDate,
              range,
              rangeOffset,
              useTopItemRange: false,
            };
          } else {
            dateOverride.entries[tab].columns[colId].startDate = startDate;
            dateOverride.entries[tab].columns[colId].endDate = endDate;
            dateOverride.entries[tab].columns[colId].range = range;
            dateOverride.entries[tab].columns[colId].rangeOffset = rangeOffset;
          }
        })
      );
    },
    [columns]
  );

  const setDateOverrideColumnTitle = useCallback(
    (tab, colId, title) =>
      setState(
        produce((draft: Draft<ExposedFilterState>) => {
          const dateOverride = draft.dateOverride;

          if (!dateOverride.entries[tab]) {
            dateOverride.entries[tab] = { ...getDefaultDateOverride(columns) };
          }
          if (!dateOverride.entries[tab].columns[colId]) {
            const column = columns.find((c) => c.id === colId);

            dateOverride.entries[tab].columns[colId] = {
              title,
              startDate: column?.startDate,
              endDate: column?.endDate,
              range: column?.range,
              rangeOffset: column?.rangeOffset,
              useTopItemRange: false,
            };
          } else {
            dateOverride.entries[tab].columns[colId].title = title;
          }
        })
      ),
    [columns]
  );

  const setDateOverrideColumnUseTopItemRange = useCallback(
    (tab, colId, useTopItemRange) =>
      setState(
        produce((draft: Draft<ExposedFilterState>) => {
          const dateOverride = draft.dateOverride;

          if (!dateOverride.entries[tab]) {
            dateOverride.entries[tab] = { ...getDefaultDateOverride(columns) };
          }
          if (!dateOverride.entries[tab].columns[colId]) {
            const column = columns.find((c) => c.id === colId);

            dateOverride.entries[tab].columns[colId] = {
              title: '',
              startDate: column?.startDate,
              endDate: column?.endDate,
              range: column?.range,
              rangeOffset: column?.rangeOffset,
              useTopItemRange,
            };
          } else {
            dateOverride.entries[tab].columns[colId].useTopItemRange =
              useTopItemRange;
          }
        })
      ),
    [columns]
  );

  /**
   * Filter Overrides
   */
  const setFilterOverrideEnabled = useCallback(
    (enabled) =>
      update((draft: Draft<ExposedFilterState>) => {
        draft.filterOverride.enabled = enabled;
      }),
    [update]
  );

  const setFilterOverrideTitle = useCallback(
    (title) =>
      update((draft: Draft<ExposedFilterState>) => {
        draft.filterOverride.title = title;
      }),
    [update]
  );

  const setFilterOverrideIds = useCallback(
    (filterKey, ids) =>
      update((draft: Draft<ExposedFilterState>) => {
        draft.filterOverride[convertKey(filterKey)] =
          ids && ids.length > 0 ? ids : null;
      }),
    [update]
  );

  const setFilterOverrideFilterKeys = useCallback(
    (_filterKeys) =>
      update((draft: Draft<ExposedFilterState>) => {
        const filterKeys = _filterKeys.map((key) => convertKey(key));

        const originalFilterOverride = original(draft.filterOverride);
        draft.filterOverride.allowed = filterKeys.slice(0).sort();

        filterKeys.forEach((key) => {
          if (originalFilterOverride && !originalFilterOverride[key]) {
            draft.filterOverride[key] = null;
          }
        });
        if (originalFilterOverride) {
          Object.keys(originalFilterOverride).forEach((key) => {
            if (key === 'enabled' || key === 'title' || key === 'allowed')
              return;
            if (!filterKeys.includes(key)) {
              delete draft.filterOverride[key];
            }
          });
        }
      }),
    [update]
  );

  const setFilterOverrideClassIds = useCallback(
    (classIds) =>
      update((draft: Draft<ExposedFilterState>) => {
        draft.filterOverride.classIds =
          classIds && classIds.length > 0 ? classIds : null;
      }),
    [update]
  );

  const setFilterOverrideDepartmentIds = useCallback(
    (departmentIds) =>
      update((draft: Draft<ExposedFilterState>) => {
        draft.filterOverride.departmentIds =
          departmentIds && departmentIds.length ? departmentIds : null;
      }),
    [update]
  );

  /**
   * Company filter override
   */
  const setCompanyFilterOverrideEnabled = useCallback(
    (enabled) =>
      update((draft: Draft<ExposedFilterState>) => {
        draft.companyFilterOverride.enabled = enabled;
      }),
    [update]
  );

  const setCompanyFilterOverrideTitle = useCallback(
    (title) =>
      update((draft: Draft<ExposedFilterState>) => {
        draft.companyFilterOverride.title = title;
      }),
    [update]
  );

  const setCompanyFilterOverrideIds = useCallback(
    (companyIds) => {
      if (
        arrayElementsEqual(companyIds, state?.companyFilterOverride?.companyIds)
      )
        return;

      return update((draft: Draft<ExposedFilterState>) => {
        draft.companyFilterOverride.companyIds =
          companyIds && companyIds.length ? companyIds : null;
      });
    },
    [state?.companyFilterOverride?.companyIds, update]
  );

  /**
   * Row Change
   */
  const setRowChangeEnabled = useCallback(
    (enabled) =>
      update((draft) => {
        draft.rowChange.enabled = enabled;
      }),
    [update]
  );

  const setRowChangeTitle = useCallback(
    (title) =>
      update((draft: Draft<ExposedFilterState>) => {
        draft.rowChange.title = title;
      }),
    [update]
  );

  const setRowChangeNumber = useCallback(
    (number) =>
      update((draft: Draft<ExposedFilterState>) => {
        draft.rowChange.row = number;
      }),
    [update]
  );

  const addRowChangeEntry = useCallback(
    () =>
      update((draft: Draft<ExposedFilterState>) => {
        draft.rowChange.entries.push({
          value: 0,
          title: '',
        });
      }),
    [update]
  );

  const removeRowChangeEntry = useCallback(
    (index) =>
      update((draft: Draft<ExposedFilterState>) => {
        draft.rowChange.entries.splice(index, 1);
      }),
    [update]
  );

  const setRowChangeEntryValue = useCallback(
    (index, value) =>
      update((draft: Draft<ExposedFilterState>) => {
        draft.rowChange.entries[index].value = value;
      }),
    [update]
  );
  const setRowChangeEntryTitle = useCallback(
    (index, title) =>
      update((draft: Draft<ExposedFilterState>) => {
        draft.rowChange.entries[index].title = title;
      }),
    [update]
  );

  const moveRowChangeEntry = useCallback(
    (oldIndex, newIndex) =>
      update((draft) => {
        draft.rowChange.entries = arrayMove(
          draft.rowChange.entries,
          oldIndex,
          newIndex
        );
      }),
    [update]
  );

  return [
    state,
    {
      resetState,
      setDateOverrideEnabled,
      addDateOverrideEntry,
      removeDateOverrideEntry,
      moveDateOverrideEntry,
      setDateOverrideTitle,
      setDateOverrideColumnRange,
      setDateOverrideColumnTitle,
      setDateOverrideColumnUseTopItemRange,
      setFilterOverrideEnabled,
      setFilterOverrideTitle,
      setFilterOverrideClassIds,
      setFilterOverrideDepartmentIds,
      setCompanyFilterOverrideEnabled,
      setCompanyFilterOverrideTitle,
      setCompanyFilterOverrideIds,
      setFilterOverrideIds,
      setFilterOverrideFilterKeys,
      setRowChangeEnabled,
      setRowChangeTitle,
      setRowChangeNumber,
      addRowChangeEntry,
      removeRowChangeEntry,
      setRowChangeEntryValue,
      setRowChangeEntryTitle,
      moveRowChangeEntry,
    },
  ];
}
