/**
 *
 * TwoFactorAuth
 *
 */
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import Spinner from 'components/Spinner';
import Alert from 'components/Alert';
import AuthenticatorApp from './AuthenticatorApp';
import Sms from './Sms';
import { GET_TWO_FACTOR } from './queriesAndMutations';
import VerifyEmail from './VerifyEmail';
import { notificationToast } from 'utils/popups';
import {
  TwoFactorAuthQuery,
  TwoFactorAuthQueryVariables,
} from '__generated__/graphql';

interface Props {
  preface?: string;
}

const TwoFactorAuthForm = ({ preface }: Props): React.ReactElement => {
  const [disable, setDisable] = useState(false);
  const [error, setError] = useState(null);

  const { data, refetch } = useQuery<
    TwoFactorAuthQuery,
    TwoFactorAuthQueryVariables
  >(GET_TWO_FACTOR);

  const triggerToast = (message: string) =>
    notificationToast({
      title: message,
      type: 'success',
      timer: 2000,
    });

  if (!data || !data.currentUser) return <Spinner />;

  return (
    <div>
      {data.currentUser.verifiedEmail ? (
        <>
          {preface && <p>{preface}</p>}

          <p>
            Two-Factor authentication (2FA for short) is a good way to add an
            extra layer of security to your Reach Reporting account to ensure
            that you are the only person who can access your account, even if
            someone knows your password.
          </p>

          <AuthenticatorApp
            enabled={data.currentUser.verifiedTotp}
            downloadBackupCodes={data.currentUser.downloadedBackupCodes}
            error={!!error}
            setError={setError}
            triggerToast={triggerToast}
          />

          <Sms
            enabled={data.currentUser.verifiedTwoFactorPhone}
            disable={disable}
            totpEnabled={data.currentUser.verifiedTotp}
            phoneLast4={data.currentUser.phoneLast4}
            error={!!error}
            setError={setError}
            setDisable={setDisable}
            triggerToast={triggerToast}
          />

          {error && (
            <Alert color="danger" outline>
              {error}
            </Alert>
          )}
        </>
      ) : (
        <VerifyEmail
          verifiedEmail={data.currentUser.verifiedEmail}
          disable={disable}
          refetch={refetch}
        />
      )}
    </div>
  );
};

export default TwoFactorAuthForm;
