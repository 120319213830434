import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Route, Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { Query } from '@apollo/client/react/components';

import GlobalStyles from 'components/GlobalStyles';
import Sidebar from 'components/Sidebar';
import Header from 'components/Header';

import quantum from 'themes/quantum';
import dark from 'themes/dark';

import { merge } from 'utils/arrayUtils';
import RedirectMaintenanceMode from 'hoc/redirectMaintenanceMode';
import { getTheme } from 'utils/theme';

const newTheme = merge(quantum, dark);

const QUERY = gql`
  query PrivateRouteQuery {
    isLoggedIn @client
  }
`;

class NotAuthenticatedRoute extends React.PureComponent {
  renderRoute = (props) => {
    const Component = this.props.component;
    const theme = getTheme();
    return (
      <Query query={QUERY}>
        {({ data, error, loading }) => {
          if (loading) {
            return null;
          }
          if (error) {
            return <Redirect to="/error" />;
          }
          if (data.isLoggedIn) {
            return <Redirect to="/" />;
          }
          return (
            <ThemeProvider
              theme={theme === 'DARK' ? newTheme : quantum}
            >
              <React.Fragment>
                <GlobalStyles />
                <Sidebar noCompany anonymous />
                <Header sidebar sidebarCollapsed anonymous minimal />
                <Component {...props} {...this.props.componentProps} />
              </React.Fragment>
            </ThemeProvider>
          );
        }}
      </Query>
    );
  };
  render() {
    return <Route render={this.renderRoute} path={this.props.path} />;
  }
}

export default RedirectMaintenanceMode(NotAuthenticatedRoute);
