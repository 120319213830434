export const sketchPickerZIndex = 100000;
export const rightSidebarZIndex = 1000004;
export const dataPickerResultItemZIndex = 10000;
export const exposedDateListColumnZIndex = 10000;
export const exposedDateListEntryZIndex = 10000;
export const tooltipZIndex = 100001;
export const topItemZIndex = 300;

export const pillsInModalZIndex = 1002;

export const monthPickerZIndex = 1001;
export const modalZIndex = 1001;
export const loginModalZIndex = 1002;

export const dropdownContentZIndex = 1000;
export const conditionalFormattingModalZIndex = 1000;

export const mobileHeaderZIndex = 201;
export const sidebarZIndex = 201;
export const headerZIndex = 200;

export const animationZIndex = 100;
export const formattingToolbarZIndex = 100;
export const workspaceSettingsContainerZIndex = 100;
export const floatingNotificationZIndex = 110;

export const workspaceSettingsBackgroundZIndex = 50;

export const pageHeaderZIndex = 30;

export const tableBuilderTopControlsZIndex = 15;
export const tableBuilderTopItemsZIndex = 16;

export const sketchPickerFieldZIndex = 10;
export const tableAutoCompletePopupZIndex = 10;
export const tableCellInputZIndex = 10;
export const demoBannerZIndex = 10;
export const trialBannerZIndex = 10;
export const reportSidebarZIndex = 10;
export const reportLiveTextSettingsZIndex = 10;
export const reportChartColorsZIndex = 10;
export const tableBuilderThZIndex = 10;
export const workspaceHeaderZIndex = 10;

export const reportPropertiesZIndex = 5;
export const reportDrawersZIndex = 5;
// also check TableBuilder/css/metricBuilder.css
export const tableBuilderDragCornerZIndex = 3;
// also check TableBuilder/css/metricBuilder.css
export const selectionOverlayZIndex = 2;
export const tableBuilderLowerThZIndex = 5;

export const clientPortalIconHolderZIndex = 1;
export const resourceListButtonZIndex = 1;
export const spinnerZIndex = 1;

export const tableTitlePillsZIndex = 0;
export const pillsZIndex = 0;

export const headerButtonIconZIndex = -1;

export const globalEditorZIndex = -1;
