import gql from 'graphql-tag';
import { KPI, GOOGLE_SHEET_FIELDS } from 'fragments';

export const CREATE = gql`
  mutation CreateSpreadsheet($companyId: Int!, $name: String!) {
    createSpreadsheet(companyId: $companyId, name: $name) {
      id
      name
      lastModified
    }
  }
`;

export const DELETE_LEGACY = gql`
  mutation DeleteSpreadsheet($spreadsheetId: Int!) {
    deleteSpreadsheet(spreadsheetId: $spreadsheetId) {
      ...KpiFields
    }
  }
  ${KPI}
`;

export const DELETE_DATASHEET = gql`
  mutation DeleteDatasheet($tableId: Int!) {
    deleteTable(tableId: $tableId)
  }
`;

export const DELETE_GOOGLE_SHEET = gql`
  mutation DeleteGoogleSpreadsheet($googleSpreadsheetId: Int!) {
    disconnectGoogleSpreadsheet(googleSpreadsheetId: $googleSpreadsheetId)
  }
`;

export const SET_NIGHTLY_GOOGLE_SHEETS_SYNC = gql`
  mutation SetNightlyGoogleSheetsSync(
    $companyId: Int!
    $nightlyGoogleSheetSync: Boolean!
  ) {
    setNightlyGoogleSheetsSync(
      companyId: $companyId
      nightlyGoogleSheetSync: $nightlyGoogleSheetSync
    ) {
      id
      nightlyGoogleSheetSync
    }
  }
`;

export const RENAME_DATA_SHEET = gql`
  mutation RenameDataSheet($tableId: Int!, $name: String!) {
    renameTable(tableId: $tableId, name: $name) {
      id
      name
      lastUpdated
    }
  }
`;
export const RENAME_LEGACY_DATA_SHEET = gql`
  mutation RenameDataSheet($spreadsheetId: Int!, $name: String!) {
    setSpreadsheetName(spreadsheetId: $spreadsheetId, name: $name) {
      id
      name
      lastModified
    }
  }
`;
export const RENAME_GOOGLE_SHEET = gql`
  mutation RenameGoogleSheet($googleSpreadsheetId: Int!, $name: String!) {
    setGoogleSpreadsheetName(
      googleSpreadsheetId: $googleSpreadsheetId
      name: $name
    ) {
      id
      name
      lastModified
      externalId
    }
  }
`;

export const DELETE_LIBRARY = gql`
  mutation deleteDataSheetFromLibrary($dataSheetId: Int!) {
    deleteDataSheetFromLibrary(dataSheetId: $dataSheetId)
  }
`;

export const DATASHEETS_QUERY = gql`
  query SpreadsheetModal($companyId: Int!) {
    company(id: $companyId) {
      id
      name
      datasheets {
        id
        name
        lastUpdated
      }
    }
  }
`;
export const LEGACY_QUERY = gql`
  query SpreadsheetModal($companyId: Int!) {
    company(id: $companyId) {
      id
      name
      spreadsheets {
        id
        name
        lastModified
      }
    }
  }
`;
export const GOOGLE_SHEET_QUERY = gql`
  query SpreadsheetModal($companyId: Int!) {
    company(id: $companyId) {
      id
      name
      googleSpreadsheets {
        ...GoogleSpreadsheetFields
        externalId
      }
    }
  }
  ${GOOGLE_SHEET_FIELDS}
`;

export const ALL_SHEETS_QUERY = gql`
  query SpreadsheetModal($companyId: Int!) {
    company(id: $companyId) {
      id
      name
      spreadsheets {
        id
        name
        lastModified
      }
      googleSpreadsheets {
        ...GoogleSpreadsheetFields
        externalId
      }
      datasheets {
        id
        name
        lastUpdated
      }
      libraryDatasheets {
        id
        name
        lastUpdated
        companyName
      }
      nightlyGoogleSheetSync
    }
  }
  ${GOOGLE_SHEET_FIELDS}
`;

export const LIBRARY_QUERY = gql`
  query DatasheetLibrary($companyId: Int!) {
    company(id: $companyId) {
      id
      libraryDatasheets {
        id
        name
        lastUpdated
        companyName
      }
    }
  }
`;

export const SYNC = gql`
  mutation SyncSpreadsheet($googleSpreadsheetId: Int!, $ignoreErrors: Boolean) {
    syncGoogleSpreadsheet(
      googleSpreadsheetId: $googleSpreadsheetId
      ignoreErrors: $ignoreErrors
    ) {
      success
      errors {
        missingColumns
        rowErrors {
          line
          actualData
          errors {
            column
            message
            actual
          }
        }
      }
      needsRefresh
      googleAccountEmail
      googleSpreadsheet {
        id
        name
        lastModified
      }
    }
  }
`;

export const CREATE_GOOGLE_SHEET = gql`
  mutation ConnectGoogleSpreadsheet(
    $companyId: Int!
    $googleAccountId: Int!
    $googleSpreadsheetExternalId: String!
    $ignoreErrors: Boolean
  ) {
    connectGoogleSpreadsheet(
      companyId: $companyId
      googleAccountId: $googleAccountId
      googleSpreadsheetExternalId: $googleSpreadsheetExternalId
      ignoreErrors: $ignoreErrors
    ) {
      success
      errors {
        missingColumns
        rowErrors {
          line
          actualData
          errors {
            column
            message
            actual
          }
        }
      }
      googleSpreadsheet {
        ...GoogleSpreadsheetFields
        externalId
      }
    }
  }
  ${GOOGLE_SHEET_FIELDS}
`;

export const CREATE_EXCEL_DATASHEET = gql`
  mutation CreateExcelDatasheet(
    $companyId: Int!
    $googleSpreadsheetId: Int
    $excelFile: Upload!
    $isExcel: Boolean
    $ignoreErrors: Boolean
  ) {
    createExcelDatasheet(
      companyId: $companyId
      googleSpreadsheetId: $googleSpreadsheetId
      excelFile: $excelFile
      isExcel: $isExcel
      ignoreErrors: $ignoreErrors
    ) {
      success
      errors {
        missingColumns
        rowErrors {
          line
          actualData
          errors {
            column
            message
            actual
          }
        }
        excelTitleError {
          oldName
          newName
        }
      }
      googleSpreadsheet {
        ...GoogleSpreadsheetFields
        externalId
      }
    }
  }
  ${GOOGLE_SHEET_FIELDS}
`;

export const DOWNLOAD_EXCEL_DATASHEET = gql`
  mutation DownloadExcel($googleSpreadsheetId: Int!) {
    downloadExcelDatasheet(googleSpreadsheetId: $googleSpreadsheetId)
  }
`;
