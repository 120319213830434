/**
 *
 * TrialBalanceValidation
 *
 */
import React from 'react';
import styled from 'styled-components';
import { CsvValidationResult } from './types';
import moment from 'moment';

const P = styled.p`
  color: ${props => props.theme.colors.danger};
  margin-bottom: 0.25rem;
`;

const Table = styled.table`
  margin-bottom: 1rem;
`;

const Scroll = styled.div`
  max-height: calc(100vh - 335px);
  overflow-y: auto;
`;

interface TrialBalanceValidationProps {
  validationResult: CsvValidationResult | null;
}

const TrialBalanceValidation: React.FunctionComponent<TrialBalanceValidationProps> = props => {
  const { validationResult } = props;

  if (!validationResult || validationResult.valid) return null;

  const { trialBalanceErrors, errors, missingColumns } = validationResult;

  if (
    trialBalanceErrors.length === 0 ||
    errors.length > 0 ||
    missingColumns.length > 0
  )
    return null;

  return (
    <div>
      <P>
        The trial balance for the following periods is out of balance. The
        period must sum to zero to be in balance.
      </P>
      <Scroll>
        <Table>
          <tbody>
            {trialBalanceErrors.map(({ date, balance }) => (
              <tr key={date}>
                <td>
                  {moment
                    .utc(date)
                    .endOf('month')
                    .format('M/D/YYYY')}
                </td>
                <td>{balance.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Scroll>
    </div>
  );
};

export default TrialBalanceValidation;
