/* global fetch Headers */
/**
 *
 * Masquerade
 *
 */

import React from 'react';
import styled from 'styled-components';
import { Query } from '@apollo/client/react/components';
import gql from 'graphql-tag';
import { Form } from 'informed';

import { API_URL } from 'environment';

import Modal from 'components/Modal';
import TextField from 'components/TextField';
import Button from 'components/Button';

const QUERY = gql`
  query Masquerade {
    currentUser {
      administrator
      adminMasqueradeOnly
      masquerade
    }
  }
`;

const Inner = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
`;

const ErrorMsg = styled.div`
  color: ${(props) => props.theme.colors.danger};
  font-size: 0.8rem;
`;

class Masquerade extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      error: null,
    };
  }
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeypress);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeypress);
  }
  handleKeypress = (e) => {
    if (e.keyCode === 77 && e.ctrlKey) {
      this.setState({
        open: true,
      });
    }
  };
  handleClose = () => {
    this.setState({
      open: false,
    });
  };
  handleSubmit = (values) => {
    fetch(`${API_URL}/auth/masquerade`, {
      method: 'POST',
      body: JSON.stringify({
        email: values.email,
      }),
      headers: new Headers({
        'content-type': 'application/json; charset=UTF-8',
      }),
      credentials: 'include',
    }).then((res) => {
      if (res.status !== 200) {
        this.setState({
          error: 'Invalid email',
        });
        return;
      }

      window.sessionStorage.removeItem('currentCompanyId');
      window.sessionStorage.removeItem('currentWorkspaceId');
      window.sessionStorage.removeItem('companiesSelectedGroup');
      window.sessionStorage.clear();
      window.location.pathname = '/';
    });
  };
  render() {
    const { open, error: submitError } = this.state;

    return (
      <Query query={QUERY}>
        {({ data, loading, error }) => {
          if (loading || error || !data || !data.currentUser) {
            return null;
          }
          if (
            !data.currentUser.administrator &&
            !data.currentUser.masquerade &&
            !data.currentUser.adminMasqueradeOnly
          ) {
            return null;
          }
          return (
            <Modal
              show={open}
              onClose={this.handleClose}
              width={400}
              title="Masquerade"
            >
              <Form onSubmit={this.handleSubmit}>
                <Inner>
                  <TextField placeholder="Email" field="email" type="text" />
                  <StyledButton color="success">Go</StyledButton>
                </Inner>
                {submitError && <ErrorMsg>{submitError}</ErrorMsg>}
              </Form>
            </Modal>
          );
        }}
      </Query>
    );
  }
}

export default Masquerade;
