/**
 *
 * SetCurrencySymbol
 *
 */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

import useCompanyId from 'hooks/useCompanyId';
import usePrevious from 'hooks/usePrevious';
import { currencyCountry, setCurrencyCode } from 'graphql/variables';

const QUERY = gql`
  query CurrencySymbol($companyId: Int!) {
    company(id: $companyId) {
      id
      currency
    }
  }
`;

const SetCurrencySymbol = ({ currencyCode }) => {
  const companyId = useCompanyId();

  const { data } = useQuery(QUERY, {
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  const currency =
    (data && data.company && data.company.currency) || currencyCode;
  const prevCurrency = usePrevious(currency);
  useEffect(() => {
    if (prevCurrency !== currency && currency) {
      currencyCountry(currency);
      setCurrencyCode(currency);
    }
  }, [currency, prevCurrency]);

  return null;
};

export default SetCurrencySymbol;
