import React, { useState, useEffect, useCallback, useMemo } from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import useCompanyId from 'hooks/useCompanyId';
import Mutation from 'components/Mutation';

import { DELETE_LIBRARY, LIBRARY_QUERY } from './mutations';

import {
  Table,
  LibraryBottom,
  LibraryCreateForm,
  Scroll,
  Tr,
} from './common-styled-components';
import Spinner from 'components/Spinner';
import produce from 'immer';
import { showConfirmDelete } from 'utils/popups';

import { TableStyle } from './styledComponents';
import { useTable, useSortBy } from 'react-table';

const STORAGE_KEY = 'dataSheetSortState';

const getColumns = (dateAccessor) => [
  {
    Header: '',
    accessor: 'radio',
    width: '3%',
    disableSortBy: true,
    // eslint-disable-next-line react/display-name
    Cell: ({ row }) => {
      if (row.original.id === row.original.librarySelected) {
        return (
          <input
            id={row.original.id}
            type="radio"
            style={{ accentColor: 'rgb(25, 159, 192)' }}
            checked
          />
        );
      }
      return <input id={row.original.id} type="radio" />;
    },
  },
  {
    Header: 'Name',
    accessor: 'name',
    width: '32%',
    // eslint-disable-next-line react/display-name
    Cell: ({ row }) => {
      return row.original.name;
    },
    sortType: (a, b) => {
      if (a.original.name.toLowerCase() < b.original.name.toLowerCase())
        return -1;
      if (a.original.name.toLowerCase() > b.original.name.toLowerCase())
        return 1;
      return 0;
    },
  },
  {
    Header: 'Created From',
    accessor: 'createdFrom',
    width: '30%',
    Cell: ({ row }) => {
      return row.original.companyName;
    },
  },
  {
    Header: 'Last Updated',
    accessor: dateAccessor,
    width: '32%',
    // eslint-disable-next-line react/display-name
    Cell: ({ row }) => {
      return (
        <span>
          {moment(row.original.lastUpdated).format('MM/DD/YYYY h:mm a')}
        </span>
      );
    },
    sortType: (a, b) => {
      const aa = moment(a.original.lastUpdated || a.original.lastModified);
      const bb = moment(b.original.lastUpdated || b.original.lastModified);
      if (aa.isBefore(bb)) return -1;
      if (bb.isBefore(aa)) return 1;
      return 0;
    },
  },
  {
    Header: '',
    accessor: 'trash',
    width: '3%',
    disableSortBy: true,
    // eslint-disable-next-line react/display-name
    Cell: ({ row }) => {
      return (
        <Mutation
          mutation={DELETE_LIBRARY}
          variables={{ dataSheetId: row.original.id }}
          update={(cache, result) =>
            row.original.handleUpdateCache(cache, result, row.original)
          }
        >
          {(onDelete) => (
            <button
              onClick={() => {
                showConfirmDelete().then((result) => {
                  if (result) {
                    return onDelete();
                  }
                });
              }}
            >
              <i
                className="zmdi zmdi-delete zmdi-hc-fw"
                style={{ color: 'rgb(251, 0, 6)', fontSize: '20px' }}
              />
            </button>
          )}
        </Mutation>
      );
    },
  },
];

const LibraryNew = (props) => {
  const { onClose } = props;
  const dateAccessor = 'lastUpdated';
  const storageKey = `${STORAGE_KEY}_${'library'}_${dateAccessor}`;
  const [sortState, setSortState] = useState(() => {
    const storedSortState = localStorage.getItem(storageKey);
    return storedSortState
      ? JSON.parse(storedSortState)
      : [
          {
            id: dateAccessor,
            desc: true,
          },
        ];
  });

  const companyId = useCompanyId();
  const { data, loading, error } = useQuery(LIBRARY_QUERY, {
    variables: {
      companyId,
    },
    fetchPolicy: 'network-only',
  });

  const [librarySelected, setLibrarySelected] = useState(null);

  const handleClickLibrary = (id) => {
    setLibrarySelected(id);
  };

  const handleUpdateCache = useCallback(
    (cache, { data: { deleteDataSheetFromLibrary } }, dataSheet) => {
      if (deleteDataSheetFromLibrary) {
        const cacheData = cache.readQuery({
          query: LIBRARY_QUERY,
          variables: {
            companyId,
          },
        });

        cache.writeQuery({
          query: LIBRARY_QUERY,
          variables: {
            companyId,
          },
          data: produce(cacheData, (draft) => {
            draft.company.libraryDatasheets =
              draft.company.libraryDatasheets.filter(
                (item) => item.id !== dataSheet.id
              );
          }),
        });

        if (librarySelected === dataSheet.id) {
          setLibrarySelected(null);
        }
      }
    },
    [companyId, librarySelected]
  );

  const columns = useMemo(() => getColumns(dateAccessor), [dateAccessor]);

  const rowData = useMemo(() => {
    if (data?.company?.libraryDatasheets) {
      return data.company.libraryDatasheets.map((item) => {
        return {
          ...item,
          librarySelected,
          handleUpdateCache,
        };
      });
    }
    return [];
  }, [data, librarySelected, handleUpdateCache]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data: rowData,
      initialState: {
        sortBy: sortState,
      },
    },
    useSortBy
  );

  useEffect(() => {
    setSortState(sortBy);
    localStorage.setItem(storageKey, JSON.stringify(sortBy));
  }, [dateAccessor, sortBy, storageKey]);

  if (loading || error) {
    return <Spinner />;
  }

  return (
    <Scroll style={{ flexGrow: '1' }}>
      {data.company.libraryDatasheets.length ? (
        <>
          <TableStyle>
            <Table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup, i) => (
                  <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, j) => {
                      return (
                        <th
                          key={j}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          style={{
                            width: column.width,
                          }}
                        >
                          {column.Header}
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <>
                                <strong>
                                  <i className="mdi-set mdi-chevron-down" />
                                </strong>
                              </>
                            ) : (
                              <>
                                <strong>
                                  <i className="mdi-set mdi-chevron-up" />
                                </strong>
                              </>
                            )
                          ) : (
                            ''
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  const sheet = row.original;
                  return (
                    <Tr
                      {...row.getRowProps()}
                      key={sheet.id}
                      onClick={() => {
                        handleClickLibrary(sheet.id);
                      }}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td key={cell.value} {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
            <LibraryBottom>
              <LibraryCreateForm
                onCompleted={onClose}
                libraryDatasheetId={
                  librarySelected || data.company.libraryDatasheets[0].id
                }
              />
            </LibraryBottom>
          </TableStyle>
        </>
      ) : (
        <div>You have no datasheets in your library.</div>
      )}
    </Scroll>
  );
};

export default LibraryNew;
