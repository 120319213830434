import React, { useCallback, useEffect, useState } from 'react';
import useDimensions from 'react-cool-dimensions';
import {
  ArrowIcon,
  Container,
  Drawer,
  DrawerContainer,
  EXPAND_TIME_MS,
  IconContainer,
  Text,
} from './styledComponents';

interface DropdownDrawerProps {
  expanded?: boolean;
  focused?: boolean;
  openState?: boolean;
  openHeight?: number;
  marginRight?: number;
  marginBottom?: number;
  attributes: {
    icon?: React.ReactElement;
    text: string;
    width?: string;
    content: React.ReactElement | React.ReactNode;
  };
  onClick?: <T>(t?: T) => void;
  noAnimation?: boolean;
  onToggle?: (state: boolean) => void;
}

const DropdownDrawer: React.FC<DropdownDrawerProps> = ({
  expanded: defaultExpanded,
  openState,
  focused = false,
  openHeight,
  marginRight,
  marginBottom,
  attributes,
  onClick,
  noAnimation = false,
  onToggle,
}) => {
  const drawerRef = React.createRef<HTMLDivElement>();
  const [expanded, setExpanded] = useState<boolean>(
    defaultExpanded || openState || false
  );
  const [showScrollbar, setShowScrollbar] = useState<boolean>(
    !!expanded && !!openHeight
  );
  const [showScrollArrow, setShowScrollArrow] = useState<boolean>(false);

  const { observe, height: contentHeight } = useDimensions();

  /**
   * Toggles the scroll arrow when certain conditions are met
   */
  const toggleScrollArrow = useCallback(() => {
    if (
      drawerRef &&
      drawerRef.current &&
      showScrollbar &&
      (openHeight || 0) < contentHeight
    ) {
      // If scrollbar should be displayed
      const hasOverflow =
        drawerRef.current.clientHeight < drawerRef.current.scrollHeight;
      const atBottomOfScroll =
        drawerRef.current.scrollHeight - drawerRef.current.scrollTop ===
        drawerRef.current.clientHeight;

      if (hasOverflow && !atBottomOfScroll && expanded) {
        // If scrollbar should be displayed, but scroll hasn't reached bottom, displaye scroll arrow
        setShowScrollArrow(true);
      } else if (hasOverflow && atBottomOfScroll) {
        // Else hide scroll arrow when bottom has been reached
        setShowScrollArrow(false);
      }
    }
  }, [drawerRef, showScrollbar, openHeight, contentHeight, expanded]);

  /**
   *
   */
  useEffect(() => {
    if (openState !== undefined) {
      setExpanded(openState);
    }
  }, [openState]);

  /**
   * Controls fade animations for scroll bar appearance and removal along with
   * toggling the scroll arrow
   */
  useEffect(() => {
    if (contentHeight && openHeight && openHeight < contentHeight) {
      // If drawer is open and passed height is less than the content height, start timeout for scrollbar fade in animation
      const toggleScrollbar = setTimeout(() => {
        setShowScrollbar(true);
        toggleScrollArrow();
      }, EXPAND_TIME_MS);

      return () => clearTimeout(toggleScrollbar);
    }
  }, [drawerRef, toggleScrollArrow, openHeight, contentHeight]);

  /**
   * Checks scroll position after scroll to toggle arrow
   * @returns
   */
  const handleScroll = (): void => toggleScrollArrow();

  /**
   * When drawer is clicked, expand or collapse drawer
   */
  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      onClick && onClick();
      if (expanded) {
        // If there's height (drawer is open), close drawer
        setExpanded(false);
        setShowScrollbar(false);
        setShowScrollArrow(false);
        onToggle && onToggle(false);
      } else {
        // Else open drawer
        setExpanded(true);
        onToggle && onToggle(true);
      }
    },
    [expanded, onClick, onToggle]
  );

  const text = <Text>{attributes.text}</Text>;
  return (
    <Container marginRight={marginRight || 0} marginBottom={marginBottom || 0}>
      <IconContainer
        expanded={expanded}
        focused={focused}
        width={attributes.width}
        onClick={(e) => handleClick(e)}
      >
        {attributes.icon ? attributes.icon : text}
        <i className={`mdi-set mdi-chevron-${expanded ? 'down' : 'right'}`} />
      </IconContainer>
      <DrawerContainer>
        <ArrowIcon showScrollArrow={showScrollArrow}>
          <i className="mdi-set mdi-arrow-down" />
        </ArrowIcon>
        <Drawer
          ref={drawerRef}
          height={expanded ? openHeight || contentHeight + 20 : 0}
          onScroll={handleScroll}
          showScrollbar={openHeight !== undefined}
          noAnimation={noAnimation}
        >
          <div ref={observe}>{attributes.content}</div>
        </Drawer>
      </DrawerContainer>
    </Container>
  );
};

export default DropdownDrawer;
