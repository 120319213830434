import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  div {
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const NameColumn = ({ user }) => {
  return (
    <Container>
      {user ? `${user.firstName} ${user.lastName}` : 'Deleted User'}
    </Container>
  );
};

export default NameColumn;
