/**
 *
 * ThemeToggle
 *
 */
import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/client';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import produce from 'immer';
import { User, Theme } from '../../interfaces/user';
import { setTheme } from 'utils/theme';

const QUERY = gql`
  query ThemeToggle {
    currentUser {
      theme
    }
  }
`;

const I = styled.i<{ workspace: boolean; minimal: boolean }>`
  font-size: 1.5rem;
  vertical-align: middle;
  position: relative;
  height: 48.8px;
  line-height: 48.8px;
  display: inline-block;
  width: 100%;
  transition: color 0.15s ease-out;
  color: ${(props) =>
    props.workspace || props.minimal
      ? props.theme.colors.gear
      : props.theme.header.color};
`;

const Button = styled.button<{ workspace: boolean; minimal: boolean }>`
  font-size: 0.875rem;
  color: ${(props) =>
    props.workspace || props.minimal
      ? props.theme.workspaceHeader.color
      : props.theme.header.color};
  font-weight: 300;
  padding: 0;
  text-align: center;
  border-radius: 5px;
  width: 48.8px;
  margin-right: 5px;
  &:hover {
    background-color: ${(props) =>
      props.theme.mode === 'light'
        ? props.theme.header.buttonHighlight
        : '#393E46'};
  }
`;

const SET_TO_THEME = gql`
  mutation SetAccountTheme($theme: Theme!) {
    setTheme(theme: $theme) {
      theme
    }
  }
`;

const ThemeToggle: React.FunctionComponent<{
  workspace?: boolean;
  minimal?: boolean;
}> = (props) => {
  const { workspace = false, minimal = false } = props;

  const loggedIn = useIsLoggedIn();

  const { data } = useQuery(QUERY, {
    skip: !loggedIn,
  });
  const [setToTheme] = useMutation<
    {
      __typename: 'Mutation';
      setTheme: { __typename: 'CurrentUser'; theme: 'LIGHT' | 'DARK' };
    },
    { theme: 'LIGHT' | 'DARK' }
  >(SET_TO_THEME, {
    variables: {
      theme:
        data && data.currentUser && data.currentUser.theme === 'DARK'
          ? 'LIGHT'
          : 'DARK',
    },
    optimisticResponse: {
      __typename: 'Mutation',
      setTheme: {
        __typename: 'CurrentUser',
        theme: data && data.currentUser.theme === 'DARK' ? 'LIGHT' : 'DARK',
      },
    },
    update: (cache, result) => {
      const themeData = cache.readQuery({
        query: QUERY,
      });

      const theme = result.data?.setTheme?.theme || 'LIGHT';

      setTheme(theme);
      cache.writeQuery({
        query: QUERY,
        data: produce(themeData, (draft: { currentUser: User }) => {
          if (theme.toLowerCase() === 'dark') {
            draft.currentUser.theme = Theme.DARK;
          } else {
            draft.currentUser.theme = Theme.LIGHT;
          }
        }),
      });
    },
  });

  if (!data || !loggedIn) return null;

  return (
    <Button
      onClick={() => setToTheme()}
      workspace={workspace}
      minimal={minimal}
      id="theme-toggle-button"
    >
      <I
        className={`mdi-set mdi-weather-${
          data.currentUser.theme === 'DARK' ? 'sunny' : 'night'
        }`}
        workspace={workspace}
        minimal={minimal}
      />
    </Button>
  );
};

export default ThemeToggle;
