import React from 'react';
import styled, { css } from 'styled-components';

interface Positions {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  fullWidth?: boolean;
  style?: React.CSSProperties;
  color?: string;
  text?: string;
  fontSize?: string;
}

const BetaContainer = styled.div<{ fullWidth?: boolean }>`
  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  height: fit-content;
`;

const Beta = styled.div<Positions>`
  position: absolute;
  ${({ top }) =>
    (!!top || top === 0) &&
    css`
      top: ${top}${isNaN(top) ? '' : 'px'};
    `}

  ${({ bottom }) =>
    (!!bottom || bottom === 0) &&
    css`
      bottom: ${bottom}${isNaN(bottom) ? '' : 'px'};
    `}

    ${({ left }) =>
    (!!left || left === 0) &&
    css`
      left: ${left && typeof left === 'string'
        ? left
        : `${left}${isNaN(left) ? '' : 'px'}`};
    `}

  ${({ right }) =>
    (!!right || right === 0) &&
    css`
      right: ${right}${isNaN(right) ? '' : 'px'};
    `}
  ${({ fontSize }) =>
    css`
      font-size: ${fontSize || '0.875rem'};
    `}

  background-color: ${({ color, theme }) => color || theme.colors.warning};
  color: ${(props) => props.theme.colors.white};
  padding: 0 4px;
  border-radius: 5px;
`;

const BetaBadge: React.FC<Positions> = ({
  children,
  top,
  bottom,
  left,
  right,
  fullWidth,
  style,
  color,
  fontSize,
  text,
}) => {
  if (
    top === undefined &&
    bottom === undefined &&
    left === undefined &&
    right === undefined
  ) {
    bottom = 0;
    right = 0;
  }

  return (
    <BetaContainer fullWidth={fullWidth}>
      <Beta
        top={top}
        bottom={bottom}
        left={left}
        right={right}
        color={color}
        fontSize={fontSize}
        style={style}
      >
        {text || 'Beta'}
      </Beta>
      {children}
    </BetaContainer>
  );
};

export default BetaBadge;
