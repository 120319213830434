/**
 *
 * Unpaid
 *
 */
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';

import LoadingIndicator from 'components/LoadingIndicator';
import { useQuery } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import useStripeUnpaid from 'hooks/useStripeUnpaid';
import Panel from 'components/Panel';
import StripePortalButton from 'components/StripePortalButton';

const Div = styled.div`
  margin: 10% auto;
`;

const H2 = styled.h2`
  font-size: 1.2rem;
  text-align: center;
  line-height: 2rem;
`;

const StyledStripeButton = styled(StripePortalButton)`
  text-decoration: underline;
`;

const Unpaid = props => {
  const { unpaid, isBillingUser } = useStripeUnpaid();

  if (unpaid === null) {
    return <LoadingIndicator />;
  }

  if (!unpaid) {
    return <Redirect to="/" />;
  }

  return (
    <Div>
      <H2>
        Billing information for this workspace needs to be updated. <br />
        {isBillingUser && (
          <StyledStripeButton text="Click here to update billing info and pay unpaid invoices" />
        )}
      </H2>
    </Div>
  );
};

export default Unpaid;
