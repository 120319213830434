/**
 *
 * Tree.tsx
 *
 */

import React from 'react';
import { Company } from 'interfaces/company';
import TimeAgo from 'react-timeago';
import {
  CenteredTd,
  CenteredTh,
  Checkbox,
  Tr,
  TruncatedTd,
} from './styledComponents';
import { MiddleContainer } from 'components/ItemSelectFlowStructure';

interface Props {
  /**
   * All the companies within a group / folder
   */
  companies: Company[];
  /**
   * The IDs of all selected companies
   */
  selectedIds: number[];
  /**
   * Callback invoked when a company is selected or deselected
   */
  onChange: (companyId: number, state: boolean) => void;
  /**
   * The accounting integration that will be assigned to the consolidated company
   */
  accountingIntegration?: string;
  /**
   * Display in view only mode
   */
  viewOnly?: boolean;

  consolidationOwner?: string | null;
}

const Tree: React.FC<Props> = ({
  companies,
  selectedIds,
  onChange,
  accountingIntegration,
  viewOnly = true,
  consolidationOwner,
}) => {
  const displayedCompanies = companies.filter((company) => {
    if (viewOnly) {
      return selectedIds.includes(company.id);
    } else {
      return company;
    }
  });
  return (
    <MiddleContainer viewOnly={viewOnly}>
      <table>
        <colgroup>
          <col span={1} style={{ width: '2%' }} />
          {!viewOnly && <col span={1} style={{ width: '5%' }} />}
          <col span={1} style={{ width: '48%' }} />
          <col span={1} style={{ width: '20%' }} />
          <col span={1} style={{ width: '25%' }} />
        </colgroup>
        <thead>
          <tr>
            <th />
            {!viewOnly && <th />}
            <th>Company Name</th>
            <CenteredTh>Integration</CenteredTh>
            <CenteredTh>Last Synced</CenteredTh>
          </tr>
        </thead>
        <tbody>
          {displayedCompanies.map((company) => (
            <Tr key={company.id} viewOnly={viewOnly}>
              <td />
              {!viewOnly && (
                <td>
                  <Checkbox
                    id={`company_${company.id}`}
                    checked={selectedIds.includes(company.id)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onChange(company.id, e.target.checked)
                    }
                    disabled={
                      !!accountingIntegration &&
                      company.accountingIntegration !== accountingIntegration
                    }
                  />
                </td>
              )}
              <TruncatedTd title={company.name}>
                <label
                  htmlFor={viewOnly ? '' : `company_${company.id}`}
                  style={{ cursor: viewOnly ? 'default' : 'pointer' }}
                >
                  {company.name}
                </label>
              </TruncatedTd>
              <CenteredTd style={{ textAlign: 'center' }}>
                {company.accountingIntegration === 'QB'
                  ? 'QuickBooks'
                  : company.accountingIntegration}
              </CenteredTd>
              <CenteredTd>
                <TimeAgo date={company.accounting.lastSynced} minPeriod={30} />
              </CenteredTd>
            </Tr>
          ))}
          {viewOnly && consolidationOwner && (
            <>
              <br />
              <tr>
                <td align="center" colSpan={4}>
                  Consolidation Created By: {consolidationOwner}
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </MiddleContainer>
  );
};

export default Tree;
