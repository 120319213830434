import React from 'react';
import styled from 'styled-components';
import theme from 'styled-theming';
import { useFormState } from 'informed';

const color = theme('mode', {
  dark: '#3a404f',
  light: '#617182',
});

export const Span = styled.span`
  color: ${color};
  font-size: 12px;
  font-weight: bold;
`;

function ErrorText({ field }) {
  const { errors, touched } = useFormState();

  if (!errors[field] || !touched[field]) {
    return null;
  }
  return <Span>{errors[field]}</Span>;
}

export default ErrorText;
