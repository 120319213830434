/**
 *
 * ManageUsers
 *
 */

import React from 'react';
import styled from 'styled-components';
import { Form } from 'informed';
import { Query, Mutation } from '@apollo/client/react/components';
import gql from 'graphql-tag';
import moment from 'moment';

import Modal from 'components/Modal';
import TextField from 'components/TextField';
import Button from 'components/Button';
import Table, { Tr } from 'components/Table';

const EmailTextField = styled(TextField)`
  width: auto;
  display: inline-block;
  margin-right: 1em;
`;

const StyledButton = styled(Button)`
  margin: 5px 0;
`;

const REMOVE_COMPANY_USER = gql`
  mutation RemoveCompanyUser($companyId: Int!, $userId: Int!) {
    removeClient(companyId: $companyId, userId: $userId)
  }
`;
const CREATE = gql`
  mutation CreateClient($companyId: Int!, $email: Email!) {
    addCompanyClient(companyId: $companyId, email: $email) {
      id
      lastLoggedIn
      email
    }
  }
`;

const QUERY = gql`
  query CreateClientUpdate($companyId: Int!) {
    company(id: $companyId) {
      id
      users {
        id
        lastLoggedIn
        email
      }
    }
  }
`;

const UPDATE_FRAGMENT = gql`
  fragment UpdateCompanyUsers on Company {
    id
    users {
      id
      lastLoggedIn
      email
    }
  }
`;

class ManageUsers extends React.PureComponent {
  getApi = (formApi) => {
    this.formApi = formApi;
  };
  handleUserInvited = () => {
    this.formApi.reset();
  };
  render() {
    const { companyId, show, onClose } = this.props;
    return (
      <Modal show={show} title="Manage Users" onClose={onClose} width={800}>
        <Query query={QUERY} variables={{ companyId: companyId }}>
          {({ data, loading, error }) => {
            if (error) {
              console.log(error);
            }
            if (loading || error) {
              return null;
            }
            return (
              <div>
                <Table striped>
                  <thead align="left">
                    <tr>
                      <th>Who has Access</th>
                      <th>Last logged In</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {data.company.users.map((user) => (
                      <Tr key={user.id}>
                        <td>{user.email}</td>
                        <td>
                          {user.lastLoggedIn
                            ? moment.utc(user.lastLoggedIn).format('MM/DD/YYYY')
                            : 'Never Logged In'}
                        </td>
                        <td>
                          <Mutation mutation={REMOVE_COMPANY_USER}>
                            {(removeCompanyUser) => (
                              <StyledButton
                                color="danger"
                                outline
                                onClick={() => {
                                  removeCompanyUser({
                                    variables: { companyId, userId: user.id },
                                    update: (
                                      cache,
                                      { data: { removeClient } }
                                    ) => {
                                      if (removeClient) {
                                        const data = cache.readFragment({
                                          id: `Company:${companyId}`,
                                          fragment: UPDATE_FRAGMENT,
                                        });
                                        data.users = data.users.filter(
                                          (filterUser) =>
                                            filterUser.id !== user.id
                                        );
                                        cache.writeFragment({
                                          id: `Company:${companyId}`,
                                          fragment: UPDATE_FRAGMENT,
                                          data,
                                        });
                                      }
                                    },
                                  });
                                }}
                              >
                                Remove
                              </StyledButton>
                            )}
                          </Mutation>
                        </td>
                      </Tr>
                    ))}
                  </tbody>
                </Table>
                <p>
                  <b>Add a team member</b>
                  <br />
                  Enter their email below and we will send them an invite.
                  <br />
                  <br />
                  They will be provided with a link to login as a shared user
                  for only this company.
                </p>
                <Mutation
                  mutation={CREATE}
                  onCompleted={this.handleUserInvited}
                >
                  {(create) => (
                    <Form
                      getApi={this.getApi}
                      onSubmit={({ email }) => {
                        create({
                          variables: { companyId, email },
                          update: (cache, { data: { addCompanyClient } }) => {
                            const data = cache.readFragment({
                              id: `Company:${companyId}`,
                              fragment: UPDATE_FRAGMENT,
                            });
                            data.users.push(addCompanyClient);
                            cache.writeFragment({
                              id: `Company:${companyId}`,
                              fragment: UPDATE_FRAGMENT,
                              data,
                            });
                          },
                        });
                      }}
                    >
                      <EmailTextField
                        type="text"
                        field="email"
                        placeholder="example@gmail.com"
                        autoFocus
                      />
                      <StyledButton type="submit" color="success">
                        Send Invite
                      </StyledButton>
                    </Form>
                  )}
                </Mutation>
              </div>
            );
          }}
        </Query>
      </Modal>
    );
  }
}

export default ManageUsers;
