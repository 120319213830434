import VerticalTabs from 'components/VerticalTabs';
import CheckboxBase from 'components/Checkbox';
import styled, { css } from 'styled-components';
import Button from 'components/Button';
import scrollbar from 'css/scrollbar';
/**
 * Categories.tsx
 */
export const CategoriesHeader = styled.span``;

export const Tabs = styled(VerticalTabs)``;

/**
 * Tree.tsx
 */
export const Row = styled.span`
  display: flex;
  justify-content: space-between;
`;

export const CheckboxContainer = styled.span``;

export const Checkbox = styled(CheckboxBase)`
  margin: unset;
  padding-left: 0.75rem;
`;

export const Tr = styled.tr<{ viewOnly: boolean }>`
  cursor: ${({ viewOnly }) => (viewOnly ? 'default' : 'pointer')};
  ${({ viewOnly }) =>
    !viewOnly &&
    css`
      &:hover {
        background-color: #f0f6ff;
      }
    `}

  padding: 5px;
`;

const centeredStyling = css`
  text-align: center;
`;

export const CenteredTh = styled.th`
  ${centeredStyling}
`;

export const CenteredTd = styled.td`
  ${centeredStyling}
`;

export const TruncatedTd = styled.td`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 1px;
`;

/**
 * Result.tsx
 */
export const CompanyList = styled.div`
  flex: 1;
  overflow: auto;
  ${({ theme }) => scrollbar(theme.scrollBar.thumb, theme.body.color)}
`;

export const I = styled.i<{ type: 'trash' | 'gear' }>`
  font-size: 18px;
  color: ${({ theme, type }) =>
    type === 'trash' ? theme.button.danger.base : undefined} !important;
`;

export const Actions = styled.td`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const DropdownHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 0.85rem;
  border-bottom: 1px solid #e5e5e5;
  text-align: center;
  padding: 0 10px 10px 10px;
  margin-bottom: 10px;
`;

export const IconButton = styled.button`
  width: 20px;
  padding: 0;
  svg > path {
    fill: ${({ theme, color }) =>
      color === 'danger' ? theme.button.danger.base : theme.colors.gear};
  }
`;

export const FiltersWarningIcon = styled.i`
  color: ${({ theme }) => theme.colors.warning}!important;
  margin-right: 5px;
`;

export const CurrencySelection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

export const LaunchCurrencyModal = styled(Button)<{
  children: string;
  disabled: boolean;
  onClick: () => void;
}>``;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

export const CurrencyConversion = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 10px;
`;

export const CurrencyCheckbox = styled(CheckboxBase)`
  margin: unset;
  padding-left: 1.5rem;
`;

export const ConsolidatedName = styled.div`
  display: flex;
`;

export const ConsolidatedNameInput = styled.input.attrs({
  type: 'text',
  autoComplete: 'off',
})`
  flex: 1;
  background-color: #fff !important;
`;

export const Submit = styled(Button)<{
  children: string;
  color: string;
  loading: boolean;
  disabled: boolean;
  onClick: () => void;
  id?: string;
}>`
  margin-left: 20px;
`;

export const Info = styled.div<{ hide: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.85rem;
  transition:
    opacity 0.2s ease-in-out,
    height 0.2s ease-in-out,
    margin-top 0.2s ease-in-out;
  opacity: ${({ hide }) => (hide ? 0 : 1)};

  ${({ hide }) =>
    hide
      ? css`
          height: 0px;
          margin-top: 0px;
        `
      : css`
          height: 10px;
          margin-top: 5px;
        `}

  #classes-and-departments-counter {
    margin-right: 45px;
  }
`;

export const AbbreviationInput = styled.input.attrs({
  type: 'text',
  autoComplete: 'off',
})<{
  viewOnly?: boolean;
}>`
  background-color: ${({ viewOnly }) =>
    viewOnly ? '#eee' : '#fff'} !important;
  width: 7rem;
`;
