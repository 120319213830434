/**
 *
 * QboButton
 *
 */
import React from 'react';
import styled from 'styled-components';

import image from './qbo-reconnect.png';
import hoverImage from './qbo-reconnect-hover.png';

const QbLogo = styled.div`
  width: 163px;
  height: 28px;
  background: url(${(props) => props.image});
  background-size: contain;
  background-repeat: no-repeat;
  &:hover {
    background: url(${(props) => props.hoverImage});
    background-size: contain;
    background-repeat: no-repeat;
  }
  &:after {
    content: '';
    background: url(${(props) => props.hoverImage});
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

const QboButton = (props) => {
  const { children, ...rest } = props;
  return (
    <button {...rest}>
      <QbLogo image={image.src} hoverImage={hoverImage.src} />
    </button>
  );
};

export default QboButton;
