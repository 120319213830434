/**
 *
 * GaAuthEvents
 *
 */
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import { useEffect } from 'react';
import { getUserIdentifier, pushEvent } from 'utils/ga';

const QUERY = gql`
  query GaAuthEvents {
    currentUser {
      firstName
      lastName
      businessType
      userId
      needsOnboarding
    }
  }
`;

const GaAuthEvents = () => {
  const isLoggedIn = useIsLoggedIn();
  const { data } = useQuery(QUERY, {
    skip: !isLoggedIn,
  });

  useEffect(() => {
    if (
      isLoggedIn &&
      data &&
      data.currentUser &&
      data.currentUser.userId &&
      !data.currentUser.needsOnboarding
    ) {
      const authEvent = window.localStorage.getItem('authEvent');
      const authSource = window.localStorage.getItem('authSource');
      if (authEvent && authSource) {
        window.localStorage.removeItem('authEvent');
        window.localStorage.removeItem('authSource');

        pushEvent(authEvent, {
          loginMethod: authSource,
          userId: getUserIdentifier(data.currentUser),
        });
      }
    }
  }, [data, isLoggedIn]);

  return null;
};

export default GaAuthEvents;
