import { createGlobalStyle } from 'styled-components';
import theme from 'styled-theming';

const inputBackground = theme('mode', {
  light: '#fff',
  dark: 'transparent',
});

const inputColor = theme('mode', {
  light: '#323a48',
  dark: 'hsla(0,0%,100%,.8)',
});

const gridDragHandleColor = theme('mode', {
  light: 'rgba(0, 0, 0, 0.4)',
  dark: 'hsla(0,0%,100%,.8)',
});

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

          html {
            min-height: 100%;
          }
          
          body {
            margin: 0;
            padding: 0;
            min-height: 100%;
            font-family: ${(props) => props.theme.body.fontFamily};
            background: ${(props) => props.theme.body.background};
            color: ${(props) => props.theme.body.color};
            font-size: ${(props) => props.theme.body.fontSize};
            line-height: ${(props) => props.theme.body.lineHeight};
          }

          @media screen and (max-device-width: 480px){
            body{
              -webkit-text-size-adjust: 100%;
            }
          }

          #dashboard {
            color: ${(props) => props.theme.body.color};
          }
          
          body, input, textarea, select, button {
            font-family: ${(props) => props.theme.body.fontFamily};
            font-size: 100%;
            letter-spacing: .02rem;
          }
          
          ::placeholder {
            color: #ccc;
          }
          
          h1, h2, h3, h4, h5, h6 {
            line-height: 1.2;
            font-weight: 400;
            letter-spacing: .02em;
          }
          body, h1, h2, h3, html {
            font-weight: 400;
          }
          h4, h5, h6, label {
            font-weight: 400;
          }
          label {
            cursor: pointer;
          }
          a {
            color: ${(props) => props.theme.colors.link};
          }
          dl, h1, h2, h3, h4, h5, h6, ol, p, pre, ul {
            margin-top: 0;
          }
          h6, ol li, p, ul li {
            font-size: .875rem;
          }
          :active, :focus, :hover {
            outline: 0;
          }
          a, a:link, a:visited {
            text-decoration: none;
            outline: 0;
            /*transition: all .3s ease;*/
          }
          
          button {
            transition: all .3s ease;
            border: 0;
            background: transparent;
            cursor: pointer;
            color: ${(props) => props.theme.body.color};
          }
          
          label {
            font-size: .875rem;
          }
          
          table {
            font-size: .875rem;
            width: 100%;
            max-width: 100%;
            border-spacing: 0;
          }
          th {
            vertical-align: bottom;
            text-align: inherit;
            font-weight: 500;
          }
          
          
          select {
            border: 1px solid #dfe7f3;
            color: #323a48;
            border-radius: .25rem;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            padding: .375rem .75rem;
            line-height: 1.5;
            font-size: .875rem;
          }
          select:focus {
            border-color: #736cc7;
          }
          
          .pace .pace-activity {
            display: none!important;
          }
          
          .react-grid-item.react-grid-placeholder {
            background: rgba(100, 100, 100, 0.35);
          }

          .rcs-custom-scroll .rcs-inner-handle {
            background-color: rgba(100, 100, 100, 0.7);
          }
          
          i {
            color: ${(props) => props.theme.colors.icon};
          }
          
          button:disabled,
          button[aria-disabled="true"] {
            cursor: default;
          }
          
          h1, h2, h3, h4, h5, h6 {
            color: inherit;
          }
        
          .highcharts-background {
            fill: transparent;
          }
          .highcharts-area {
            pointer-events: none;
          }
          .swal2-popup .swal2-styled.swal2-confirm {
            background-color: ${(props) => props.theme.colors.brand};
          }
          .swal2-popup .swal2-styled.swal2-cancel {
            background-color: ${(props) => props.theme.colors.secondary};
          }
          .swal2-icon.swal2-warning {
            color: ${(props) => props.theme.colors.warning};
            border-color: ${(props) => props.theme.colors.warning};
          }
          .swal2-popup .swal2-styled.swal2-cancel,
          .swal2-popup .swal2-styled.swal2-deny,
          .swal2-popup .swal2-styled.swal2-confirm, {
            font-size: 14px;
          }
          .swal2-popup .right-gap {
            margin-left: auto;
          }
          .swal2-popup .swal2-styled:focus {
            box-shadow: none;
          }
          .swal2-popup .swal2-title {
            font-size: 30px;
          }
          
          .highcharts-styled.highcharts-container {
            font-family: ${(props) => props.theme.body.fontFamily};
            position: static;
          }
          .highcharts-styled .highcharts-title {
            font-size: 14px;
          }
          .highcharts-tooltip-container {
            z-index: 100;
            font-size: 12px;
            font-family: ${(props) => props.theme.body.fontFamily};
            pointer-events: none;
          }
          
          .highcharts-legend-item {
            font-size: 10px;
          }
          
          ${(props) =>
            props.theme.chartColors
              .map(
                (color, index) => `
            .highcharts-styled .highcharts-color-${index} {
              fill: ${color}!important;
              stroke: ${color}!important;
              color: ${color}!important;
            }
            .highcharts-tooltip-container .highcharts-color-${index} {
              fill: ${color}!important;
              stroke: ${color}!important;
              color: ${color}!important;
            }
          `
              )
              .join('\n')}
            
          .highcharts-container .budget,
          .highcharts-tooltip .budget {
            fill: #ccc!important;
            stroke: #ccc!important;
            color: #ccc!important;
          }
          .highcharts-container .highcharts-spline-series.budget {
            fill: none!important;
            stroke-dasharray: 8, 6;
          }
          .highcharts-container .highcharts-point {
            cursor: default;
          }
          .highcharts-container .highcharts-point.budget {
            display: none;
          }
          
          .highcharts-legend-item text {
            font-weight: 400;
          }
          
          input[type="text"],input[type="password"] {
            min-width: 0;
          }
          input[type="text"],input[type="email"],input[type="password"],input[type="tel"],.StripeElement {
            background: ${inputBackground};
            border: 1px solid #dfe7f3;
            color: ${inputColor};
            font-size: .875rem;
            padding: .375rem .75rem;
            border-radius: .25rem;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
          }
          input[type="text"],input[type="password"]:focus {
            border: 1px solid #52badc;
          }
          textarea {
            color: ${inputColor};
            background: ${inputBackground};
            border: 1px solid #dfe7f3;
            font-size: .875rem;
            border-radius: .25rem;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
          }
          
          /* .react-resizable-handle {
            opacity: 0;
            transition: opacity 0.25s;
          }
          .react-grid-item:hover > .react-resizable-handle {
            opacity: 1;
          } */
          
          .react-grid-item > .react-resizable-handle:after {
            border-right-color: ${gridDragHandleColor};
            border-bottom-color: ${gridDragHandleColor};
          }
          
          .mce-content-body p {
            margin: 0;
          }
          
          div.mce-tinymce-inline {
            transform: translateY(-20px);
          }
          
          .mce-floatpanel {
            box-shadow:0 1px 7px rgba(112, 23, 169, 0.72)!important;
          }
          
          /* .modal-blur {
            filter: blur(0);
            transition: 0.25s filter linear;
            -webkit-transition: 0.25s -webkit-filter linear;
            -moz-transition: 0.25s -moz-filter linear;
            -ms-transition: 0.25s -ms-filter linear;
            -o-transition: 0.25s -o-filter linear;
          } */


          .ant-select-dropdown-menu .ant-select-dropdown-menu-item {
            white-space: normal;
            text-overflow: visible;
          }

          .phone-input.react-tel-input .form-control {
            width: 100%;
            border: 1px solid #dfe7f3;
            padding: .375rem .75rem;
            padding-left: 47px;
            line-height: 1.5;
            height: 41px;
          }
          .Toastify__progress-bar {
            background: #52badb;
          }
`;

export default GlobalStyles;
