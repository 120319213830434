import useCompanyId from './useCompanyId';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

const QUERY = gql`
  query IsConsolidation($companyId: Int!) {
    company(id: $companyId) {
      id
      isConsolidation
    }
  }
`;

export default function useIsConsolidation(_companyId) {
  const companyId = useCompanyId();
  const openCompanyId = _companyId || companyId;

  const { data, loading, error } = useQuery(QUERY, {
    variables: { companyId: openCompanyId },
    skip: !openCompanyId,
  });

  if (loading || error || !data || !data.company) return null;

  return data.company.isConsolidation;
}
