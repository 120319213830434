/**
 *
 * GustoConnectionModal
 *
 */
import React, { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Checkbox from 'components/Checkbox';
import Modal from 'components/Modal';
import Radio from 'components/Radio';
import gql from 'graphql-tag';
import { captureException } from 'utils/sentry';
import { showError } from 'utils/popups';
import styled from 'styled-components';

const QUERY = gql`
  query GustoConnectionModal {
    currentUser {
      gustoConnectionStatus {
        connecting
        companies {
          id
          name
          alreadyAttached
        }
        attachingCompanyId
      }
    }
  }
`;

const CONNECT = gql`
  mutation FinishGustoConnection($gustoCompanyIds: [Int!]!) {
    connectGustoCompanies(gustoCompanyIds: $gustoCompanyIds) {
      success
      error
    }
  }
`;

const CANCEL = gql`
  mutation CancelGustoConnection {
    cancelConnectGusto
  }
`;

export const Scroll = styled.div`
  max-height: calc(100vh - 200px);
  overflow-y: auto;
`;

const GustoConnectionModal = () => {
  const [selected, setSelected] = useState([]);
  const [canceled, setCanceled] = useState(false);

  const { data } = useQuery(QUERY, {
    fetchPolicy: 'network-only',
  });

  const allowMultiple =
    data &&
    data.currentUser &&
    data.currentUser.gustoConnectionStatus.attachingCompanyId === null;

  const [onConnect, { loading }] = useMutation(CONNECT);
  const [onCancel, { loading: loadingCancel }] = useMutation(CANCEL);

  const handleCancel = useCallback(async () => {
    try {
      await onCancel({
        variables: {
          gustoCompanyIds: selected,
        },
      });

      setCanceled(true);
    } catch (err) {
      captureException(err, true);
    }
  }, [onCancel, selected]);

  const handleConnect = useCallback(async () => {
    try {
      const { data: connectData } = await onConnect({
        variables: {
          gustoCompanyIds: selected,
        },
      });

      if (connectData.error) {
        showError({ title: 'Error', text: connectData.error });
      } else {
        window.location.reload();
      }
    } catch (err) {
      captureException(err, true);
    }
  }, [onConnect, selected]);

  const onChange = useCallback(
    (e) => {
      const value = parseInt(e.target.value, 10);

      if (!allowMultiple) {
        return setSelected([value]);
      }

      if (e.target.checked) {
        setSelected([...selected, value]);
      } else {
        setSelected(selected.filter((id) => id !== value));
      }
    },
    [allowMultiple, selected]
  );

  const buttons = useMemo(() => {
    return [
      {
        text: 'Cancel',
        color: 'default',
        outline: true,
        disabled: loading || loadingCancel,
        loading: loadingCancel,
        onClick: handleCancel,
      },
      {
        text: 'Connect',
        color: 'info',
        loading,
        disabled: loading || loadingCancel || !selected.length,
        onClick: handleConnect,
      },
    ];
  }, [handleCancel, handleConnect, loading, loadingCancel, selected.length]);

  if (
    canceled ||
    !data ||
    !data.currentUser ||
    !data.currentUser.gustoConnectionStatus ||
    !data.currentUser.gustoConnectionStatus.connecting
  ) {
    return null;
  }

  const Component = allowMultiple ? Checkbox : Radio;

  return (
    <Modal
      show
      title={`Choose Gusto ${
        allowMultiple ? 'Companies' : 'Company'
      } to Connect`}
      buttons={buttons}
      width={550}
    >
      <Scroll>
        {data.currentUser.gustoConnectionStatus.companies.map((company) => (
          <div key={company.id}>
            <Component
              value={company.id}
              checked={selected.includes(company.id)}
              onChange={onChange}
              disabled={company.alreadyAttached}
            >
              {company.name}
              {company.alreadyAttached ? ' (Already Connected)' : null}
            </Component>
          </div>
        ))}
      </Scroll>
    </Modal>
  );
};

export default GustoConnectionModal;
