import { getStartDate, getEndDate } from 'library/ranges';
import moment, { Moment } from 'moment';
import { RangeType } from 'types/ranges';

export const getDates = (
  range: RangeType,
  fiscalYearMonth: number
): { startDate: Moment; endDate: Moment } => {
  const now = moment.utc().startOf('month');

  // do one month ahead because the getStartDate/getEndDate functions subtract 1 month to get the "book month"
  now.add(1, 'month');

  const startDate = getStartDate(range, undefined, now, null, fiscalYearMonth);
  const endDate = getEndDate(
    range,
    undefined,
    undefined,
    now,
    null,
    fiscalYearMonth
  );
  return { startDate, endDate };
};
