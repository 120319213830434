import styled, { css } from 'styled-components';
import Checkbox from 'components/Checkbox';
import media from 'media';

export const ListWrapper = styled.div<{ halfWidth: boolean; width?: number }>`
  ${(props) =>
    !props.width &&
    css`
      flex-grow: 1;
    `};
  padding: 10px;
  overflow-y: auto;
  width: ${(props) =>
    props.width ? `${props.width}px` : props.halfWidth ? '50%' : 'auto'};
  display: flex;
  flex-direction: column;
`;

export const ListBox = styled.div`
  border: 1px solid #ccc;
  padding: 10px;
  overflow-y: auto;
  max-height: 300px;
  height: 100%;
  ${media.tablet`
    max-height: 300px;
    height: 100%;
  `}
`;

export const Input = styled.input`
  background: #f0f6ff;
  margin: 10px 0;
  width: 100%;
  border: none;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  font-size: ${(props) => props.theme.formControl.fontSize};
  &::-webkit-input-placeholder {
    color: ${(props) => props.theme.formControl.placeholder};
  }
`;

export const StyledCheckbox = styled(Checkbox)<{
  styleAsDisabled?: boolean;
  indent?: boolean;
  value?: string | number;
  partial?: boolean;
}>`
  & label {
    font-size: 11px;
  }
  margin-bottom: 0;

  ${({ indent }) =>
    indent &&
    css`
      margin-left: 20px;
    `}
`;

export const Top = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;
