const links = {
  'app.dev.startegy.com': '#',
  'app-stg.reachreporting.com':
    'https://s3.amazonaws.com/reach-desktop-connector-stg-2/ReachReportingSetup.exe',
  'app.reachreporting.com':
    'https://s3.amazonaws.com/reach-desktop-connector-2/ReachReportingSetup.exe',
};

const link =
  links[window.location.hostname] ||
  'https://s3.amazonaws.com/reach-desktop-connector-2/ReachReportingSetup.exe';

export default link;
