/**
 *
 * ClientPortalButton
 *
 */
import React from 'react';

import { ClientPortalPreviewLocation } from 'locations';
import useWorkspaceId from 'hooks/useWorkspaceId';
import useCompanyId from 'hooks/useCompanyId';
import IconButton from './IconButton';

const ClientPortalButton = () => {
  const workspaceId = useWorkspaceId();
  const companyId = useCompanyId();

  if (!workspaceId || !companyId) return null;

  return (
    <IconButton
      to={ClientPortalPreviewLocation.toUrl({ workspaceId, companyId })}
      text="Client Portal"
      iconClass="mdi mdi-account-multiple"
    />
  );
};

export default ClientPortalButton;
