import { ApolloCache, FetchResult, gql } from '@apollo/client';
import { Modifiers } from '@apollo/client/cache';
import { GROUP_COMPANY_LIST } from 'fragments';
import produce from 'immer';
import { Company } from 'interfaces/company';
import { Firm } from 'interfaces/firm';

export const CREATE = gql`
  mutation CreateConsolidatedCompany(
    $workspaceId: Int!
    $firmId: Int!
    $name: String!
    $companyIds: [Int!]!
    $abbreviations: [String]
    $currency: String!
    $applyCurrencyConversion: Boolean!
    $filters: [CompanyFilterInput!]
  ) {
    createConsolidatedCompany(
      workspaceId: $workspaceId
      firmId: $firmId
      name: $name
      companyIds: $companyIds
      abbreviations: $abbreviations
      currency: $currency
      applyCurrencyConversion: $applyCurrencyConversion
      filters: $filters
    ) {
      ...GroupCompanyListFields
    }
  }
  ${GROUP_COMPANY_LIST}
`;

export const UPDATE = gql`
  mutation UpdateConsolidatedCompany(
    $companyId: Int!
    $name: String!
    $companyIds: [Int!]!
    $abbreviations: [String]
    $currency: String!
    $applyCurrencyConversion: Boolean!
    $filters: [CompanyFilterInput!]
  ) {
    updateConsolidatedCompany(
      companyId: $companyId
      name: $name
      companyIds: $companyIds
      abbreviations: $abbreviations
      currency: $currency
      applyCurrencyConversion: $applyCurrencyConversion
      filters: $filters
    ) {
      id
      name
      nameOverride
      consolidationCompanies {
        id
        parentConsolidationId
        name
        abbreviation
      }
      currency
      applyCurrencyConversion
    }
  }
`;

const ADD_FRAGMENT = gql`
  fragment AddConsolidationToFirm on Firm {
    id
    companies {
      ...GroupCompanyListFields
    }
  }
  ${GROUP_COMPANY_LIST}
`;

export const handleInvalidate = (
  cache: ApolloCache<any>,
  companyId: number,
  filters?: {
    companyId: number;
    filters: {
      classes: Array<string | number>;
      departments: Array<string | number>;
    };
  }[]
): void => {
  const fields: Modifiers = { metricData: (_, { DELETE }) => DELETE };

  if (filters) {
    fields.filters = () => filters;
  }

  cache.modify({
    id: `Company:${companyId}`,
    fields,
  });
};

export const handleCreate = (
  cache: ApolloCache<any>,
  result: Omit<
    FetchResult<
      {
        createConsolidatedCompany: Company;
      },
      Record<string, any>,
      Record<string, any>
    >,
    'context'
  >,
  firmId: number
): void => {
  const cachedData = cache.readFragment<Firm>({
    id: `Firm:${firmId}`,
    fragment: ADD_FRAGMENT,
    fragmentName: 'AddConsolidationToFirm',
  });

  if (cachedData) {
    cache.writeFragment({
      id: `Firm:${firmId}`,
      fragment: ADD_FRAGMENT,
      fragmentName: 'AddConsolidationToFirm',
      data: produce(cachedData, (draft) => {
        if (result && result.data) {
          draft.companies = [
            ...draft.companies,
            result.data.createConsolidatedCompany,
          ];
        }
      }),
    });
  }
};
