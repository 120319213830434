/**
 *
 * BookMonth
 *
 */

import React, { useMemo } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import {
  BookMonthLauncher,
  BulkUpdateCheckboxDiv,
  BulkUpdateDiv,
  BulkUpdateText,
  CheckBox,
  FieldGroup,
  InputCol,
  LabelCol,
} from './styledComponents';
import DatePicker from 'components/DatePicker';

const defaultBookMonth = moment.utc().startOf('month');

const Div = styled.div`
  display: flex;
  align-items: center;
`;

const RevertButton = styled.button`
  font-size: 14px;
  color: ${(props) => props.theme.colors.link}!important;
`;

interface bookMonthProps {
  bookMonth: any;
  isBookMonthFixed: boolean | null;
  onChangeBookMonth: (Moment) => void;
  onRevertBookMonth: () => void;
  register: (any) => any;
  handleChange: () => void;
}

const BookMonth: React.FC<bookMonthProps> = ({
  bookMonth,
  isBookMonthFixed,
  onChangeBookMonth,
  onRevertBookMonth,
  register,
  handleChange,
}) => {
  const lastMonth = useMemo(
    () => moment.utc(bookMonth || defaultBookMonth).subtract(1, 'month'),
    [bookMonth]
  );

  return (
    <FieldGroup
      onClick={(e) => e.stopPropagation()}
      style={{
        marginTop: '5px',
      }}
    >
      <LabelCol>Book Month</LabelCol>
      <InputCol
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Div>
          <DatePicker
            initialDate={lastMonth}
            onChange={onChangeBookMonth}
            launcher={
              <BookMonthLauncher>
                {lastMonth.format('MMM YYYY')}
              </BookMonthLauncher>
            }
            portal
            selectorId={'company-settings-book-month'}
            changeOnClick
            hideCancel
          />
          {isBookMonthFixed && (
            <RevertButton onClick={onRevertBookMonth} type="button">
              Revert
            </RevertButton>
          )}
        </Div>
        <BulkUpdateDiv
          style={{
            marginLeft: 'auto',
          }}
        >
          <BulkUpdateCheckboxDiv>
            <CheckBox
              {...register('hideActualsAfterBm')}
              id="hideActualsAfterBm"
              name="hideActualsAfterBm"
              type={'checkbox'}
              onChange={() => handleChange()}
            />
          </BulkUpdateCheckboxDiv>
          <BulkUpdateText
            style={{ marginTop: '2px' }}
            htmlFor="hideActualsAfterBm"
          >
            Hide Actuals after Book Month
          </BulkUpdateText>
        </BulkUpdateDiv>
      </InputCol>
    </FieldGroup>
  );
};

export default BookMonth;
