/**
 *
 * DataPicker
 *
 */

import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import PubSub from 'pubsub-js';
import moment from 'moment';

import { arrayMove } from 'utils/arrayUtils';
import { ranges } from 'library/table/ranges';

import withBookMonth from 'hoc/withBookMonth';

import Consolidations from './Consolidations';
import Categories from './Categories';
import Result from './Result';
import { findValueIntegrationPath } from './utils';
import DropdownDrawer from 'components/DropdownDrawer';
import VerticalTabs from 'components/VerticalTabs';
import quantum from 'themes/quantum';
import {
  LeftContainer,
  MiddleContainer,
  ParentContainer,
} from 'components/ItemSelectFlowStructure';
import ItemTree from './ItemTree';

const SharedDataTabs = styled(VerticalTabs)`
  margin-right: 10px;
`;

const Container = styled(ParentContainer)`
  max-height: calc(100vh - 15rem);
  height: calc(100vh - 15rem);
`;

const eliminationTabs = ['Profit and Loss', 'Balance Sheet'];
const agingReports = new Set(['arAging', 'apAging']);

@withBookMonth
class DataPicker extends React.PureComponent {
  static defaultProps = {
    showExpandCollapseAll: true,
  };
  constructor(props) {
    super(props);
    let defaultSelected = [];
    if (props.value && Array.isArray(props.value)) {
      defaultSelected = props.value.slice(0);
    } else if (props.value) {
      defaultSelected = [props.value];
    }

    const { path, consolidationTab, currentIntegration } = this.getSelectedPath(
      defaultSelected[0],
      props.dataVariablesByIntegration,
      props.sharedDataVariables
    );

    const defaultTab = path && path[0];

    let defaultFromVariables;
    if (
      props.dataVariablesByIntegration &&
      props.dataVariablesByIntegration.length
    ) {
      defaultFromVariables = props.dataVariablesByIntegration[0].data[0].value;
    } else {
      defaultFromVariables = props.sharedDataVariables[0].value;
    }

    this.state = {
      currentIntegration: Math.max(currentIntegration || 0, 0),
      consolidationTab,
      defaultPath: path,
      tab: defaultTab || defaultFromVariables,
      selected: defaultSelected,
      live: true,
      range: 'LAST_MONTH',
      customStartDate: moment.utc().startOf('month').subtract(5, 'months'),
      customEndDate: moment.utc().startOf('month'),
      forecastStartDate: moment.utc().startOf('month').add(1, 'month'),
      forecastEndDate: moment.utc().startOf('month').add(12, 'month'),
      grouping: 'monthsSeparate',
      hasComparison: false,
      comparison: 'PREVIOUS_PERIOD',
      customComparisonStartDate: moment
        .utc()
        .startOf('month')
        .subtract(12, 'months'),
      change: false,
      percentChange: false,
      comparisonSide: 'LEFT',
      hasActuals: false,
      hasReferenceDates: false,
      referenceAverage: false,
      referenceTotal: false,
      referenceSeparate: false,
      referenceSide: 'LEFT',
      budgetId: -1,
      lastClickedItem: [null, false],
      prevClickedItem: [null, false],
    };
  }
  getSelectedPath = (
    defaultSelected,
    dataVariablesByIntegration,
    sharedDataVariables
  ) => {
    return findValueIntegrationPath(
      defaultSelected,
      dataVariablesByIntegration,
      sharedDataVariables
    );
  };
  onChangeTab = (tab) => {
    this.setState({
      tab,
    });
  };
  onChangeConsolidation = (index) => {
    let { tab, currentIntegration } = this.state;
    const { dataVariablesByIntegration } = this.props;

    const currentData = index
      ? dataVariablesByIntegration[currentIntegration].consolidationCompanies[
          index - 1
        ].customKpiVariables
      : dataVariablesByIntegration[currentIntegration].data;

    if (tab >= currentData.length) {
      tab = 0;
    }

    this.setState({
      consolidationTab: index,
      tab,
    });
  };
  onChange = (id, selected, rowIds, shiftKey = false) => {
    this.setState(
      (prevState) => {
        if (prevState.lastClickedItem === null) {
          return {
            prevClickedItem: [id, selected],
            lastClickedItem: [id, selected],
          };
        } else {
          return {
            lastClickedItem: [id, selected],
            prevClickedItem: prevState.lastClickedItem,
          };
        }
      },
      () => {
        if (!this.props.single && shiftKey && this.state.prevClickedItem[1]) {
          const prevId = this.state.prevClickedItem[0];
          const currentId = id;
          const prevIdIndex = rowIds.indexOf(prevId);
          const currentIdIndex = rowIds.indexOf(currentId);
          const start = Math.min(prevIdIndex, currentIdIndex);
          const end = Math.max(prevIdIndex, currentIdIndex);
          if (start === end) {
            this.setState({
              selected: this.state.selected.filter((item) => item !== id),
            });
          } else {
            let idsToSetTrue = rowIds.slice(start, end + 1);
            idsToSetTrue = idsToSetTrue.filter(
              (i) => this.state.selected.indexOf(i) === -1
            );
            this.setState({
              selected: this.state.selected.concat(idsToSetTrue),
            });
            this.setState({
              lastClickedItem: [null, false],
              prevClickedItem: [null, false],
            });
          }
        } else {
          if (this.props.single) {
            this.setState({
              selected: [id],
            });
          } else if (selected) {
            if (this.state.selected.indexOf(id) === -1) {
              this.setState({
                selected: this.state.selected.concat(id),
              });
            }
          } else {
            this.setState({
              selected: this.state.selected.filter((item) => item !== id),
            });
          }
        }
      }
    );
  };
  onChangeMultiple = (ids, selected) => {
    const currentSelected = new Set(this.state.selected);
    if (selected) {
      ids.forEach((id) => currentSelected.add(id));
    } else {
      ids.forEach((id) => currentSelected.delete(id));
    }
    this.setState({
      selected: Array.from(currentSelected),
    });
  };
  onRemove = (id) => {
    this.setState({
      selected: this.state.selected.filter((item) => item !== id),
    });
  };
  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      selected: arrayMove(this.state.selected, oldIndex, newIndex),
    });
  };
  getSelectedItems = (items, suffix = '') => {
    const { selected } = this.state;
    let result = [];
    items.forEach((item) => {
      if (item.value && selected.includes(item.value)) {
        result.push({
          ...item,
          name: item.name + suffix,
        });
      }
      if (
        (item.children && item.children.length) ||
        (item.data && item.data.length)
      ) {
        result = result.concat(
          this.getSelectedItems(item.children || item.data, suffix)
        );
      }
    });

    return result;
  };
  prepareResult = () => {
    const { dataVariablesByIntegration, sharedDataVariables } = this.props;
    const { selected } = this.state;
    let result = [];
    if (
      dataVariablesByIntegration[0] &&
      dataVariablesByIntegration[0].consolidationCompanies &&
      dataVariablesByIntegration[0].consolidationCompanies.length
    ) {
      dataVariablesByIntegration.forEach((entry) => {
        result.push(...this.getSelectedItems(entry.data));

        entry.consolidationCompanies.forEach((consolidationCompany) => {
          result.push(
            ...this.getSelectedItems(
              consolidationCompany.customKpiVariables,
              ` (${
                consolidationCompany.abbreviation || consolidationCompany.name
              })`
            )
          );
          if (consolidationCompany.sharedDataVariables) {
            result.push(
              ...this.getSelectedItems(
                consolidationCompany.sharedDataVariables,
                ` (${
                  consolidationCompany.abbreviation || consolidationCompany.name
                })`
              )
            );
          }
        });
      });

      if (sharedDataVariables) {
        const sharedResult = this.getSelectedItems(sharedDataVariables);
        if (sharedResult.length) {
          result.push(...sharedResult);
        }
      }
    } else {
      result = this.getSelectedItems(dataVariablesByIntegration);
      if (sharedDataVariables) {
        const sharedResult = this.getSelectedItems(sharedDataVariables);
        if (sharedResult.length) {
          result.push(...sharedResult);
        }
      }
    }

    const sorted = selected.map((value) => {
      const index = result.findIndex((r) => r.value === value);
      return result.splice(index, 1)[0];
    });

    return sorted;
  };
  onSave = () => {
    const { subscription, dateOptions } = this.props;

    const items = this.prepareResult(this.props.data);

    let dates = null;
    if (this.props.dates) {
      dates = dateOptions;
    }

    if (this.props.onSave) {
      this.props.onSave({
        items,
        dates,
      });
    } else {
      PubSub.publish(subscription || 'SELECT_DATA', {
        items,
        dates,
      });
      this.props.onClose();
    }
  };
  onChangeRange = (range) => {
    const { allowedGroupings } = ranges.find((r) => r.value === range);
    let grouping = this.state.grouping;
    if (allowedGroupings.indexOf(grouping) === -1) {
      grouping = allowedGroupings[0];
    }

    this.setState({
      range,
      grouping,
    });
  };
  onChangeLive = (live) => {
    this.setState({
      live,
    });
  };
  onChangeCustomDates = (customStartDate, customEndDate) => {
    this.setState({
      customStartDate,
      customEndDate,
    });
  };
  onChangeForecastDates = (forecastStartDate, forecastEndDate) => {
    this.setState({
      forecastStartDate,
      forecastEndDate,
    });
  };
  onChangeGrouping = (grouping) => {
    this.setState({
      grouping,
    });
  };
  onChangeHasComparison = (e) => {
    this.setState({
      hasComparison: e.target.checked,
    });
  };
  onChangeComparison = (comparison) => {
    this.setState({
      comparison,
    });
  };
  onChangeCustomComparisonStartDate = (customComparisonStartDate) => {
    this.setState({
      customComparisonStartDate,
    });
  };
  onChangeIncludeChange = (e) => {
    this.setState({
      change: e.target.checked,
    });
  };
  onChangeIncludePercentChange = (e) => {
    this.setState({
      percentChange: e.target.checked,
    });
  };
  onChangeComparisonSide = (comparisonSide) => {
    this.setState({
      comparisonSide,
    });
  };
  onChangeHasActuals = (e) => {
    this.setState({
      hasActuals: e.target.checked,
    });
  };
  onChangeHasReferenceDates = (e) => {
    this.setState({
      hasReferenceDates: e.target.checked,
    });
  };
  onChangeReferenceAverage = (e) => {
    this.setState({
      referenceAverage: e.target.checked,
      referenceTotal: e.target.checked ? true : this.state.referenceTotal,
    });
  };
  onChangeReferenceTotal = (e) => {
    this.setState({
      referenceTotal: e.target.checked,
      referenceAverage: !e.target.checked ? false : this.state.referenceAverage,
    });
  };
  onChangeReferenceSeparate = (e) => {
    this.setState({
      referenceSeparate: e.target.checked,
    });
  };
  onChangeReferenceSide = (referenceSide) => {
    this.setState({
      referenceSide,
    });
  };
  onChangeBudget = (budgetId, opt) => {
    this.setState({
      budgetId: opt.budgetId,
      budgetType: opt.budgetType,
    });
  };
  getTab = (tabs) => {
    const tabIndex = tabs.findIndex((s) => s.id === this.state.tab);
    if (tabIndex === -1) {
      this.setState({ tab: tabs[0].id });
    }
  };
  getIntegrationTabs = () => {
    const tabs = [];
    this.props.dataVariablesByIntegration.forEach((entry) => {
      const { data } = entry;
      const dataWithoutAgingReports = (data || []).filter(
        (d) => !!d && !agingReports.has(d.value)
      );

      if (this.props.elimination) {
        tabs.push(
          ...dataWithoutAgingReports
            .filter((item) => eliminationTabs.includes(item.name))
            .map((item) => ({ id: item.value, label: item.name }))
        );
      } else {
        tabs.push(
          ...dataWithoutAgingReports.map((item) => ({
            id: item.value,
            label: item.name,
          }))
        );
      }
    });
    return tabs;
  };
  getSharedDataTabs = (flattenedData) => {
    const { sharedDataVariables, dataVariablesByIntegration, liveText } =
      this.props;
    const { currentIntegration, consolidationTab } = this.state;
    const tabs = [];
    const consolidationCompanies =
      dataVariablesByIntegration[currentIntegration] &&
      dataVariablesByIntegration[currentIntegration].consolidationCompanies;

    if (consolidationCompanies && consolidationCompanies.length) {
      if (consolidationTab > 0) {
        tabs.push(
          ...consolidationCompanies[
            consolidationTab - 1
          ].sharedDataVariables.map((item) => ({
            id: item.value,
            label: item.name,
          }))
        );
      } else if (sharedDataVariables) {
        flattenedData.push(...sharedDataVariables);

        tabs.push(
          ...sharedDataVariables.map((item) => ({
            id: item.value,
            label: item.name,
          }))
        );
      }
    } else if (sharedDataVariables) {
      flattenedData.push(...sharedDataVariables);

      tabs.push(
        ...sharedDataVariables.reduce((acc, item) => {
          if (
            (item.value === 'other' || item.value === 'spreadsheets') &&
            liveText
          ) {
            return acc;
          }
          return [
            ...acc,
            {
              id: item.value,
              label:
                item.value === 'googleSpreadsheets'
                  ? 'Google/Excel'
                  : item.name,
            },
          ];
        }, [])
      );
    }

    return tabs;
  };
  render() {
    const {
      dataVariablesByIntegration,
      single,
      showExpandCollapseAll,
      company,
      dates,
      disableTotals,
      elimination,
      sharedDataVariables,
    } = this.props;

    const { currentIntegration, tab, selected, consolidationTab, defaultPath } =
      this.state;

    let currentData =
      dataVariablesByIntegration[currentIntegration] &&
      dataVariablesByIntegration[currentIntegration].data;

    const flattenedData = [];

    dataVariablesByIntegration.forEach((item) => {
      flattenedData.push(...item.data);
    });

    const sharedDataTabs = this.getSharedDataTabs(flattenedData);
    const integrationTabs = this.getIntegrationTabs();
    this.getTab([...sharedDataTabs, ...integrationTabs]);

    let currentTabValues;

    for (let i = 0; i < dataVariablesByIntegration.length; i++) {
      const entry = dataVariablesByIntegration[i].data.find(
        (item) => item.value === tab
      );
      if (entry) {
        currentTabValues = entry;
        break;
      }
    }
    if (!currentTabValues && sharedDataVariables) {
      for (let i = 0; i < sharedDataVariables.length; i++) {
        if (sharedDataVariables[i].value === tab) {
          currentTabValues = sharedDataVariables[i];
          break;
        }
      }
    }

    let companyId = null;

    const consolidationCompanies =
      dataVariablesByIntegration[currentIntegration]?.consolidationCompanies ||
      [];

    if (consolidationCompanies && consolidationCompanies.length) {
      if (consolidationTab > 0) {
        currentData =
          consolidationCompanies[consolidationTab - 1].customKpiVariables;
      }

      companyId =
        consolidationTab === 0
          ? null
          : consolidationCompanies[consolidationTab - 1].id;

      currentTabValues = currentData.find((item) => item.value === tab);

      if (!currentTabValues) {
        currentTabValues =
          consolidationTab === 0
            ? sharedDataVariables.find((item) => item.value === tab)
            : consolidationCompanies[
                consolidationTab - 1
              ].sharedDataVariables.find((item) => item.value === tab);
      }
    }

    const IntegrationsContainer =
      consolidationCompanies.length > 0 ? MiddleContainer : LeftContainer;

    return (
      <Container>
        {consolidationCompanies.length > 0 && (
          <Consolidations
            company={company}
            companies={consolidationCompanies}
            current={consolidationTab}
            onChange={this.onChangeConsolidation}
          />
        )}

        <IntegrationsContainer
          style={consolidationCompanies.length > 0 ? { flex: 1 } : {}}
        >
          <ThemeProvider theme={quantum}>
            {dataVariablesByIntegration.map((entry, index) => {
              return (
                <DropdownDrawer
                  key={entry.text}
                  expanded={index === 0}
                  marginRight={5}
                  attributes={{
                    icon: entry.icon,
                    text: entry.text,
                    width: '100%',
                    content: (
                      <Categories
                        data={entry.data}
                        current={tab}
                        onChange={this.onChangeTab}
                        onSave={this.onSave}
                        elimination={elimination}
                      />
                    ),
                  }}
                />
              );
            })}

            {!!sharedDataVariables && !elimination && (
              <SharedDataTabs
                tabs={sharedDataTabs}
                onChange={this.onChangeTab}
                current={tab}
              />
            )}
          </ThemeProvider>
        </IntegrationsContainer>

        <ItemTree
          items={currentTabValues && currentTabValues.children}
          allowSelectAll={currentTabValues && currentTabValues.allowSelectAll}
          value={selected}
          single={single}
          onChange={this.onChange}
          showExpandCollapseAll={showExpandCollapseAll}
          companyId={companyId}
          hasConsolidations={
            consolidationCompanies && !!consolidationCompanies.length
          }
          onChangeMultiple={this.onChangeMultiple}
          defaultPath={defaultPath}
          disableTotals={disableTotals}
        />
        <Result
          data={flattenedData}
          company={company}
          consolidationCompanies={consolidationCompanies}
          sharedDataVariables={sharedDataVariables}
          selected={selected}
          single={single}
          onRemove={this.onRemove}
          onSortEnd={this.onSortEnd}
          onSave={this.onSave}
          companyId={companyId}
          dates={dates}
        />
      </Container>
    );
  }
}

export default DataPicker;
