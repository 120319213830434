import gql from 'graphql-tag';

import { PRICING_PLAN } from 'fragments';
import { useQuery } from '@apollo/client';

const QUERY = gql`
  query PricingPlanById($pricingPlanId: Int!) {
    pricingPlan(id: $pricingPlanId) {
      ...PricingPlanFields
    }
  }
  ${PRICING_PLAN}
`;

const NAME_QUERY = gql`
  query PricingPlanByName($pricingPlanName: String!) {
    pricingPlan: pricingPlanByName(name: $pricingPlanName) {
      ...PricingPlanFields
    }
  }
  ${PRICING_PLAN}
`;

export default function usePricingPlan({ id, name, skip = false }) {
  const { data } = useQuery(id ? QUERY : NAME_QUERY, {
    variables: {
      [id ? 'pricingPlanId' : 'pricingPlanName']: id || name,
    },
    skip,
  });

  if (!data || !data.pricingPlan) return null;

  return data.pricingPlan;
}
