/**
 *
 * AppearanceBlock
 *
 */

import React from 'react';
import styled from 'styled-components';
import theme from 'styled-theming';

const bgColor = theme('mode', {
  light: '#dbdee2',
  dark: '#303644',
});

const Div = styled.div`
  background: ${bgColor};
  border-radius: 6px;
  padding: 7px 9px;
  width: 200px;
  height: 156px;
  text-align: center;
  &:first {
    margin-right: 14px;
  }
  img {
    max-width: 182px;
    max-height: 100px;
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
`;

function AppearanceBlock({
  title,
  iconImg,
  onClick,
  children,
  className,
  disabled,
}) {
  return (
    <Div className={className}>
      <Top>
        <span>{title}</span>
        {!disabled && (
          <button onClick={onClick} type="button">
            <img src={iconImg} />
          </button>
        )}
      </Top>
      {children}
    </Div>
  );
}

export default AppearanceBlock;
