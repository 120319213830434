/**
 *
 * QbdAttach
 *
 */
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';

import { useMutation, useSubscription } from '@apollo/client';
import { captureException } from 'utils/sentry';
import Spinner from 'components/Spinner';
import useUserEmail from 'hooks/useUserEmail';
import link from 'utils/desktopLink';

const CONNECT = gql`
  mutation AttachQbd($companyId: Int!) {
    attachQbdToExisting(companyId: $companyId) {
      success
      expires
      error
    }
  }
`;

const CONNECTING = gql`
  subscription QbdAttachConnecting($companyId: Int!) {
    attachingToQbd(companyId: $companyId) {
      id
      connecting
      conflictingEmail
      qbdCompanyName
    }
  }
`;

const CONNECTED = gql`
  subscription QbdAttachFinished($companyId: Int!) {
    attachedToQbd(companyId: $companyId) {
      id
      success
    }
  }
`;

const CANCEL = gql`
  mutation CancelAttachQbd($companyId: Int!) {
    cancelAttachQbdToExisting(companyId: $companyId)
  }
`;

const A = styled.a`
  display: block;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
`;

const Warning = styled.p`
  margin-top: 0.5rem;
  font-weight: 700;
`;

const QbdAttach = (props) => {
  const { companyId } = props;

  const [connecting, setConnecting] = useState(false);
  const [name, setName] = useState('');
  const [error, setError] = useState(null);

  const userEmail = useUserEmail();

  const handleSubscriptionData = useCallback(
    ({
      data: {
        data: { attachingToQbd },
      },
    }) => {
      setName(attachingToQbd.qbdCompanyName);
      setConnecting(true);
    },
    []
  );

  const handleFinished = useCallback(() => {
    window.location.reload();
  }, []);

  useSubscription(CONNECTING, {
    variables: {
      companyId,
    },
    onData: handleSubscriptionData,
  });

  useSubscription(CONNECTED, {
    variables: {
      companyId,
    },
    onData: handleFinished,
  });

  const [connect] = useMutation(CONNECT, {
    variables: {
      companyId,
    },
  });

  const [cancel] = useMutation(CANCEL, {
    variables: {
      companyId,
    },
  });

  const handleConnect = useCallback(async () => {
    try {
      const { data: connectData } = await connect();
      if (connectData.attachQbdToExisting.success) {
        setConnecting(true);
      } else if (connectData.attachQbdToExisting.error) {
        setError(connectData.attachQbdToExisting.error);
      }
    } catch (err) {
      captureException(err, true);
    }
  }, [connect]);

  useEffect(() => {
    handleConnect();
    return () => cancel();
  }, [cancel, handleConnect]);

  if (error) {
    return <p>{error}</p>;
  }

  if (!connecting) {
    return <Spinner />;
  }

  return (
    <div>
      <A href={link} target="_blank" rel="noreferrer">
        Click here to download desktop connector
      </A>
      <p>
        Add a new company in the QuickBooks Desktop connector. Make sure you are
        signed in to the connector with your email. ({userEmail})
      </p>
      <p>
        Status:{' '}
        {name ? 'Connected' : 'Awaiting connection from desktop connector'}
      </p>
      {name && <p>Connecting company: {name}</p>}
      <Warning>
        Don&apos;t close this popup until the syncing process has started.
      </Warning>
    </div>
  );
};

export default QbdAttach;
