import React, { useRef, useEffect } from 'react';

const useOutsideDetector = <T extends (...args: any[]) => any>(
  callback: T,
  ref: React.RefObject<HTMLElement>
) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
};

const OutsideClickDetector = <T extends (...args: any[]) => any>(props: {
  callback: T;
  children: React.ReactNode;
}): React.ReactElement => {
  const wrapperRef = useRef(null);
  useOutsideDetector(props.callback, wrapperRef);

  return <div ref={wrapperRef}>{props.children}</div>;
};

export default OutsideClickDetector;
