/**
 *
 * Modal
 *
 */

import React, { ErrorInfo, ReactElement, ReactNode, ReactPortal } from 'react';
import ReactDOM from 'react-dom';
import styled, {
  createGlobalStyle,
  DefaultTheme,
  StyledComponent,
  ThemeProvider,
  withTheme,
} from 'styled-components';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import CSSTransition from 'react-transition-group/CSSTransition';
import * as Sentry from '@sentry/browser';
import themeMode from 'styled-theming';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import quantum from 'themes/quantum';
import { modalZIndex } from 'utils/zIndex';
import HideHubspot from 'components/HideHubspot';

const HEADER_HEIGHT = '3.5rem';

const bgColor = themeMode('mode', {
  light: '#ffffff',
  dark: '#222532',
});
const Div = styled.div<{
  fixed?: boolean;
  zIndex?: number;
  passThrough: boolean;
}>`
  position: ${(props): string => (props.fixed ? 'fixed' : 'absolute')};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: ${(props) => (props.zIndex ? props.zIndex : modalZIndex)};
  text-align: center;
  pointer-events: ${(props) => (props.passThrough ? 'none' : 'auto')};
`;

const Background = styled.div<{ onClick: () => void | null }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  pointer-events: auto;

  .modal-enter & {
    opacity: 0;
  }

  .modal-enter.modal-enter-active & {
    opacity: 1;
    transition: opacity 300ms;
  }

  .modal-exit & {
    opacity: 1;
  }

  .modal-exit & {
    opacity: 0;
    transition: opacity 300ms;
  }
`;

const Content = styled.div<{
  width: number;
  fullscreen?: boolean;
  align: string;
}>`
  pointer-events: auto;
  color: ${(props) => props.theme.body.color};
  & input[type='text'],
  & input[type='tel'],
  & input[type='password'],
  & input[type='email'] {
    color: ${(props) => props.theme.body.color};
  }
  & textarea {
    color: ${(props) => props.theme.body.color};
  }
  & button {
    color: ${(props) => props.theme.body.color};
  }
  & i {
    color: ${(props) => props.theme.colors.icon};
  }
  position: relative;
  border-radius: 4px;
  width: ${(props) => {
    if (props.fullscreen) {
      return '100%';
    }
    return `${props.width}px`;
  }};
  max-width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: ${({ align }) => (align === 'left' ? '60px' : 'auto')};
  margin-right: ${({ align }) => (align === 'right' ? '0' : 'auto')};
  margin-top: ${(props) => (props.fullscreen ? 0 : 100)}px;
  max-height: ${(props) => (props.fullscreen ? '100%' : 'calc(100% - 100px)')};
  height: ${(props) => (props.fullscreen ? '100%' : 'auto')};
  background: ${bgColor};
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  text-align: left;

  .modal-enter & {
    opacity: 0;
    transform: translate(0, -25%);
  }

  .modal-enter.modal-enter-active & {
    opacity: 1;
    transform: translate(0, 0);
    transition:
      opacity 300ms,
      transform 300ms;
  }

  .modal-exit & {
    opacity: 1;
    transform: translate(0, 0);
  }

  .modal-exit & {
    opacity: 0;
    transform: translate(0, -25%);
    transition:
      opacity 300ms,
      transform 300ms;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  height: ${HEADER_HEIGHT};
  border-bottom: 1px solid #e9ecef;
  align-items: center;
  flex-shrink: 0;
`;

const Title = styled.span`
  display: flex;
  overflow: hidden;
`;

const TitleText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CloseButton = styled.button`
  color: #788db4;
  text-shadow: none;
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
  font-size: 1.5rem;
  opacity: 0.5;
  font-weight: 700;
  line-height: 1;
  &:hover {
    opacity: 0.75;
  }
`;

const StyledButton = styled(Button)`
  margin-left: 0.5rem;
`;

const Body = styled.div<{ fullscreen?: boolean; hideHeader?: boolean }>`
  padding: 1rem;
  height: ${({ fullscreen, hideHeader }) =>
    fullscreen
      ? `${hideHeader ? '100%' : `calc(100vh - ${HEADER_HEIGHT})`}`
      : 'auto'};
`;

const Blur = createGlobalStyle`
  .modal-blur {
    filter: blur(2px)!important;
  }
`;

export interface ModalButton {
  text: string;
  color: string;
  outline?: boolean;
  onClick?: (any) => any;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  testId?: string;
}

interface ModalState {
  error?: string | boolean;
}

export interface StandardModalProps {
  show: boolean;
  title?: string;
  onClose?: () => void;
  preventClickOut?: boolean;
}

export interface ModalProps extends StandardModalProps {
  icon?: JSX.Element;
  onExited?: () => void;
  fixed?: boolean;
  width?: number;
  className?: string;
  hideHeader?: boolean;
  marginTop?: string | number;
  titleDropdown?: ReactElement;
  titleDropdownWidth?: number;
  background?: boolean;
  blur?: boolean;
  align?: string;
  buttons?: Array<ModalButton> | undefined;
  style?: string;
  zIndex?: number;
  titleStyles?: any;
  useCurrentTheme?: boolean;
  theme?: any;
  bodyClass?: any;
  fullscreen?: boolean;
  stopPropagation?: boolean;
  testId?: string;
  children: ReactNode | (({ state }: { state: ModalState }) => ReactElement);
  BodyElement?: React.ElementType;
  hideCloseButton?: boolean;
}

class Modal extends React.PureComponent<ModalProps, ModalState> {
  static defaultProps = {
    fixed: true,
    width: 400,
    marginTop: 100,
    background: true,
    blur: true,
    stopPropagation: false,
    align: 'center',
  };
  el: HTMLDivElement;
  constructor(props: ModalProps) {
    super(props);
    this.el = document.createElement('div');
    this.state = { error: false };
  }
  static getDerivedStateFromError(error: Error): any {
    return { error };
  }
  componentDidCatch(error: Error, info: ErrorInfo): void {
    console.log(info);
    Sentry.captureException(error);
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  componentDidMount(): void {
    const portalRoot = document.getElementById('portals');
    portalRoot?.appendChild(this.el);
  }
  componentWillUnmount(): void {
    const portalRoot = document.getElementById('portals');
    portalRoot?.removeChild(this.el);
  }
  handleStopPropogation = (e: React.MouseEvent): void => {
    e.stopPropagation();
  };
  render(): ReactPortal {
    const {
      align,
      show,
      fixed,
      onClose,
      onExited,
      width,
      title,
      icon,
      children,
      className,
      hideHeader,
      marginTop,
      titleDropdown,
      titleDropdownWidth,
      titleStyles,
      background,
      buttons,
      blur,
      useCurrentTheme,
      theme,
      bodyClass,
      zIndex,
      preventClickOut = false,
      fullscreen,
      testId,
      BodyElement = Body,
      stopPropagation,
      hideCloseButton,
    } = this.props;
    const { error } = this.state;

    const Container = BodyElement;

    return ReactDOM.createPortal(
      <ThemeProvider theme={useCurrentTheme ? theme : quantum}>
        <TransitionGroup>
          {show && (
            <CSSTransition
              classNames="modal"
              timeout={{ enter: 300, exit: 300 }}
              onExited={onExited}
            >
              {(state) => (
                <Div
                  fixed={fixed}
                  className={className}
                  zIndex={zIndex}
                  passThrough={!background}
                  onMouseDown={
                    stopPropagation ? this.handleStopPropogation : undefined
                  }
                >
                  <HideHubspot />
                  {state !== 'exiting' && blur && <Blur />}
                  {background && (
                    <Background
                      onClick={() => {
                        if (!preventClickOut && onClose) onClose();
                      }}
                    />
                  )}
                  <Content
                    className={'modalContent'}
                    width={width || 400}
                    style={!fullscreen ? { marginTop } : undefined}
                    fullscreen={fullscreen}
                    data-testid={testId ? `${testId}-content` : undefined}
                    align={align}
                  >
                    {!hideHeader && (
                      <Header
                        data-testid={testId ? `${testId}-header` : undefined}
                      >
                        <Title>
                          <TitleText style={titleStyles}>
                            {icon}
                            {title}
                          </TitleText>
                          {!!titleDropdown && (
                            <Dropdown
                              align="left"
                              width={titleDropdownWidth}
                              trigger={
                                <button>
                                  <i className="mdi-set mdi-cog" />
                                </button>
                              }
                            >
                              {titleDropdown}
                            </Dropdown>
                          )}
                        </Title>
                        {buttons ? (
                          <div
                            style={{ display: 'flex' }}
                            data-testid={
                              testId ? `${testId}-header-buttons` : undefined
                            }
                          >
                            {buttons.map(
                              (
                                {
                                  text,
                                  color,
                                  outline,
                                  loading,
                                  disabled,
                                  onClick,
                                  className: buttonClassName,
                                  testId,
                                },
                                i
                              ) => (
                                <StyledButton
                                  key={i}
                                  color={color}
                                  outline={outline}
                                  loading={loading}
                                  disabled={disabled}
                                  onClick={onClick}
                                  className={buttonClassName}
                                  data-testid={testId}
                                >
                                  {text}
                                </StyledButton>
                              )
                            )}
                          </div>
                        ) : onClose && !hideCloseButton ? (
                          <CloseButton type="button" onClick={onClose}>
                            <span className="zmdi zmdi-close" />
                          </CloseButton>
                        ) : null}
                      </Header>
                    )}
                    <Container
                      style={bodyClass}
                      fullscreen={fullscreen}
                      hideHeader={hideHeader}
                    >
                      {!!error && (
                        <div>
                          An error occurred. Our team has been notified.
                        </div>
                      )}
                      {!error &&
                        (typeof children === 'function'
                          ? children({ state })
                          : children)}
                    </Container>
                  </Content>
                </Div>
              )}
            </CSSTransition>
          )}
        </TransitionGroup>
      </ThemeProvider>,
      this.el
    );
  }
}

export default withTheme(Modal);
