import React, { useCallback, useEffect, useMemo } from 'react';
import { TableStyle, ExpandedTableRow } from './styledComponents';

import { useTable, useExpanded, useRowSelect } from 'react-table';
import {
  DefaultColumn,
  ExcelColumn,
  IconColumn,
  LaunchColumn,
  SelectColumn,
  SettingsColumn,
  TagsColumn,
} from './columns';
import { MULTI_YEAR_GROUP_DIVIDER_ID } from '../constants';
import { sizes } from 'media';

const XLARGE = 1500;
const XXLARGE = 1800;

const WIDTH_PER_ICON_TABLET = 25;
const WIDTH_PER_ICON_DESKTOP = 22;
const WIDTH_PER_ICON_LARGE = 17;
const WIDTH_PER_ICON_XLARGE = 15;
const WIDTH_PER_ICON_XXLARGE = 12;

export const TableInner = ({
  dataColumns,
  data,
  renderRowSubComponent = undefined,
  expanded = false,
  onClose = undefined,
  onDelete = undefined,
  onDeleteBudgetGroup = undefined,
  onDuplicate = undefined,
  onRename = undefined,
  onClickUpdateExcel = undefined,
  downloadingId = undefined,
  minimal = false,
  canSelect = false,
  hiddenHeader = false,
  showExpand = false,
  dispatch = undefined,
  showGroupEmptyText = false,
  onHighlightGroupBudget = undefined,
  highlightedGroupBudgetId = undefined,
  onStartClientPortalFlow,
  defaults = {
    defaultBudgetId: undefined,
    defaultBudgetType: undefined,
    defaultForecastId: undefined,
    defaultForecastType: undefined,
  },
  currentUser,
  handleShowStatusHistoryModal,
  iconColumnWidth: _iconColumnWidth,
}) => {
  const {
    defaultBudgetId,
    defaultBudgetType,
    defaultForecastId,
    defaultForecastType,
  } = defaults;

  const [mediaSize, setMediaSize] = React.useState('tablet');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= XXLARGE) {
        setMediaSize('xxlarge');
      } else if (window.innerWidth >= XLARGE) {
        setMediaSize('xlarge');
      } else if (window.innerWidth >= sizes.large) {
        setMediaSize('large');
      } else if (window.innerWidth >= sizes.desktop) {
        setMediaSize('desktop');
      } else {
        setMediaSize('tablet');
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const hasExpandables = useMemo(() => {
    return showExpand || data.some((row) => row.canExpand);
  }, [data, showExpand]);

  const hasLockedBudgets = useMemo(() => {
    return data.some((row) => row.locked) && !canSelect;
  }, [canSelect, data]);

  const hasViewOnlyBudgets = useMemo(() => {
    return data.some((row) => row.viewOnly) && !canSelect;
  }, [canSelect, data]);

  const iconColumnWidth = useMemo(() => {
    if (_iconColumnWidth) {
      return _iconColumnWidth;
    }

    const widthPerIcon =
      mediaSize === 'tablet'
        ? WIDTH_PER_ICON_TABLET
        : mediaSize === 'desktop'
          ? WIDTH_PER_ICON_DESKTOP
          : mediaSize === 'large'
            ? WIDTH_PER_ICON_LARGE
            : mediaSize === 'xlarge'
              ? WIDTH_PER_ICON_XLARGE
              : WIDTH_PER_ICON_XXLARGE;
    let highestIconCount = 0;

    data.forEach((row) => {
      let iconCount = 0;
      if (row.locked) {
        iconCount++;
      }
      if (row.inClientPortal) {
        iconCount++;
      }
      if (row.subdivideEnabled) {
        iconCount++;
      }

      if (iconCount > highestIconCount) {
        highestIconCount = iconCount;
      }
    });

    if (highestIconCount === 0 && hasExpandables) {
      return widthPerIcon;
    }

    return highestIconCount * widthPerIcon;
  }, [
    hasExpandables,
    hasLockedBudgets,
    hasViewOnlyBudgets,
    data,
    canSelect,
    dataColumns,
    mediaSize,
  ]);

  const iconColumn = useMemo(
    () => ({
      Header: () => null,
      id: 'icon',
      width: iconColumnWidth,
      textAlign: 'right',
      Cell: function getIconColumn({ row }) {
        return <IconColumn row={row} />;
      },
    }),
    [iconColumnWidth]
  );

  const getRowBackgroundColor = useCallback(
    (row) => {
      if (row.original.id === 'groupDivider') {
        return '#EAEBEF';
      }
      if (highlightedGroupBudgetId === row.original.id) {
        return '#f4f4f7';
      }
      return undefined;
    },
    [highlightedGroupBudgetId]
  );

  const selectColumn = useMemo(
    () => ({
      Header: () => null,
      id: 'select',
      width: 5,
      Cell: function getSelectColumn({ row }) {
        return <SelectColumn row={row} />;
      },
    }),
    []
  );

  const tagsColumn = useMemo(
    () => ({
      Header: () => null,
      id: 'tags',
      width: 100,
      Cell: function getTagsColumn({ row }) {
        return <TagsColumn row={row} />;
      },
    }),
    []
  );

  const defaultColumn = useMemo(
    () => ({
      Header: () => null,
      id: 'default',
      width: 40,
      Cell: function getDefaultColumn({ row }) {
        return (
          <DefaultColumn
            row={row}
            defaultBudgetId={defaultBudgetId}
            defaultForecastId={defaultForecastId}
          />
        );
      },
    }),
    [defaultBudgetId, defaultForecastId]
  );

  const launchColumn = useMemo(
    () => ({
      Header: () => null,
      id: 'launch',
      width: 50,
      disableExpandOnClick: true,
      textAlign: 'right',
      Cell: function getLaunchColumn({ row }) {
        if (row.original.id === 'groupDivider') {
          return null;
        }

        return (
          <LaunchColumn
            row={row}
            onClickUpdateExcel={onClickUpdateExcel}
            downloadingId={downloadingId}
            onClose={onClose}
            dispatch={dispatch}
          />
        );
      },
    }),
    [downloadingId, onClickUpdateExcel, onClose, dispatch]
  );

  const settingsColumn = useMemo(
    () => ({
      Header: () => null,
      id: 'settings',
      width: 25,
      disableExpandOnClick: true,
      Cell: function getSettingsColumn({ row }) {
        if (row.original.id === 'groupDivider') {
          return null;
        }

        return (
          <SettingsColumn
            row={row}
            onDelete={onDelete}
            onDeleteBudgetGroup={onDeleteBudgetGroup}
            onDuplicate={onDuplicate}
            onRename={onRename}
            type={row.original.budgetsInGroup ? 'GROUP' : 'BUDGET'}
            onStartClientPortalFlow={onStartClientPortalFlow}
            defaultBudgetId={defaultBudgetId}
            defaultBudgetType={defaultBudgetType}
            defaultForecastId={defaultForecastId}
            defaultForecastType={defaultForecastType}
            currentUser={currentUser}
            handleShowStatusHistoryModal={handleShowStatusHistoryModal}
          />
        );
      },
    }),
    [
      defaultBudgetId,
      defaultBudgetType,
      defaultForecastId,
      defaultForecastType,
      onDelete,
      onDeleteBudgetGroup,
      onDuplicate,
      onRename,
      onStartClientPortalFlow,
      currentUser,
      handleShowStatusHistoryModal,
    ]
  );

  const excelColumn = useMemo(
    () => ({
      accessor: 'isExcel',
      width: 100,
      disableExpandOnClick: true,
      Cell: function getExcelColumn({ row, cell }) {
        return (
          <ExcelColumn row={row} cell={cell} downloadingId={downloadingId} />
        );
      },
    }),
    [downloadingId]
  );

  const _columns = useMemo(() => {
    let allColumns = [...dataColumns];

    if (hasExpandables || hasLockedBudgets || hasViewOnlyBudgets) {
      allColumns = [iconColumn, ...allColumns];
    }

    if (canSelect) {
      if (hasExpandables || hasLockedBudgets || hasViewOnlyBudgets) {
        allColumns.splice(1, 0, selectColumn);
      } else {
        allColumns = [selectColumn, ...allColumns];
      }
    }

    allColumns.push(excelColumn);
    allColumns.push(defaultColumn);
    allColumns.push(tagsColumn);

    if (onClose && onClickUpdateExcel && onRename && onDelete && onDuplicate) {
      allColumns = minimal
        ? [...allColumns, launchColumn]
        : [...allColumns, launchColumn, settingsColumn];
    }
    return allColumns;
  }, [
    dataColumns,
    hasExpandables,
    hasLockedBudgets,
    hasViewOnlyBudgets,
    canSelect,
    excelColumn,
    defaultColumn,
    tagsColumn,
    onClose,
    onClickUpdateExcel,
    onRename,
    onDelete,
    onDuplicate,
    iconColumn,
    selectColumn,
    minimal,
    launchColumn,
    settingsColumn,
  ]);

  const {
    getTableProps,
    getTableBodyProps,
    rows,
    prepareRow,
    toggleRowExpanded,
    headerGroups,
    toggleRowSelected,
  } = useTable(
    {
      columns: _columns,
      data,
      autoResetExpanded: false,
      initialState: {
        expanded,
      },
    },
    useExpanded,
    useRowSelect
  );
  return (
    <table {...getTableProps()}>
      {!hiddenHeader && (
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, j) => (
                <th
                  key={j}
                  {...column.getHeaderProps({
                    style: {
                      minWidth: column.minWidth,
                      width: column.width,
                      maxWidth: column.maxWidth,
                      textAlign: column.textAlign || 'center',
                    },
                  })}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
      )}
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <React.Fragment key={i}>
              <tr
                {...row.getRowProps({
                  style: {
                    cursor: row.original.canSelect ? 'pointer' : 'default',
                    backgroundColor: getRowBackgroundColor(row),
                  },
                  onMouseEnter: onHighlightGroupBudget
                    ? () => {
                        onHighlightGroupBudget(row.original.id);
                      }
                    : undefined,
                  onMouseLeave: onHighlightGroupBudget
                    ? () => {
                        onHighlightGroupBudget(null);
                      }
                    : undefined,
                })}
                className="company-table-row"
                onClick={() => {
                  if (row.original.canSelect) {
                    toggleRowSelected(row.id);
                    row.original.toggleSelectedRow();
                  }
                }}
              >
                {row.cells.map((cell, j) => {
                  return (
                    <td
                      key={j}
                      {...cell.getCellProps({
                        style: {
                          minWidth: cell.column.minWidth,
                          width: cell.column.width,
                          maxWidth: cell.column.maxWidth,
                          textAlign: cell.column.textAlign || 'center',
                        },
                      })}
                      {...((row.canExpand || row.original.canExpand) &&
                      !cell.column.disableExpandOnClick
                        ? row.getToggleRowExpandedProps({
                            style: {
                              minWidth: cell.column.minWidth,
                              width: cell.column.width,
                              maxWidth: cell.column.maxWidth,
                              textAlign: cell.column.textAlign || 'center',
                            },
                          })
                        : {})}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
              {row.isExpanded &&
                row.original.id !== MULTI_YEAR_GROUP_DIVIDER_ID && (
                  <ExpandedTableRow
                    className="expandable"
                    style={{ backgroundColor: 'transparent' }}
                  >
                    <td colSpan={_columns.length}>
                      {renderRowSubComponent({
                        row,
                        toggleRowExpanded,
                        iconColumnWidth,
                      })}
                    </td>
                  </ExpandedTableRow>
                )}
            </React.Fragment>
          );
        })}
      </tbody>
      {showGroupEmptyText && !rows.length && (
        <tfoot>
          <tr>
            <td
              colSpan={_columns.length}
              style={{
                textAlign: 'center',
                fontSize: '1rem',
                paddingTop: '2rem',
              }}
            >
              In order to group multiple years together, first create separate
              year based budgets or forecasts using Pro budgets.
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  );
};

const GroupBudget = ({
  data,
  dataColumns,
  onClose,
  onDelete,
  onDeleteBudgetGroup,
  onDuplicate,
  onRename,
  onClickUpdateExcel,
  downloadingId,
  onHighlightGroupBudget,
  iconColumnWidth,
}) => {
  if (!data) {
    return null;
  }

  return (
    <TableInner
      data={data}
      dataColumns={dataColumns}
      onClose={onClose}
      onDelete={onDelete}
      onDeleteBudgetGroup={onDeleteBudgetGroup}
      onDuplicate={onDuplicate}
      onRename={onRename}
      onClickUpdateExcel={onClickUpdateExcel}
      downloadingId={downloadingId}
      hiddenHeader
      showExpand
      onHighlightGroupBudget={onHighlightGroupBudget}
      iconColumnWidth={iconColumnWidth}
    />
  );
};

export const Table = ({
  data,
  dataColumns,
  expanded = false,
  onClose,
  onDelete,
  onDeleteBudgetGroup,
  onDuplicate,
  onRename,
  onClickUpdateExcel,
  downloadingId,
  dispatch,
  onHighlightGroupBudget,
  highlightedGroupBudgetId,
  onStartClientPortalFlow,
  defaults,
  currentUser,
  handleShowStatusHistoryModal,
}) => {
  const renderRowSubComponent = useCallback(
    ({ row, iconColumnWidth }) => (
      <GroupBudget
        data={row.original.budgetsInGroup}
        dataColumns={dataColumns}
        onClose={onClose}
        onDelete={onDelete}
        onDeleteBudgetGroup={onDeleteBudgetGroup}
        onDuplicate={onDuplicate}
        onRename={onRename}
        downloadingId={downloadingId}
        onHighlightGroupBudget={onHighlightGroupBudget}
        onClickUpdateExcel={onClickUpdateExcel}
        iconColumnWidth={iconColumnWidth}
      />
    ),
    [
      dataColumns,
      downloadingId,
      onClickUpdateExcel,
      onClose,
      onDelete,
      onDeleteBudgetGroup,
      onDuplicate,
      onHighlightGroupBudget,
      onRename,
    ]
  );
  return (
    <TableStyle>
      <TableInner
        data={data}
        dataColumns={dataColumns}
        expanded={expanded}
        renderRowSubComponent={renderRowSubComponent}
        onClose={onClose}
        onDelete={onDelete}
        onDeleteBudgetGroup={onDeleteBudgetGroup}
        onDuplicate={onDuplicate}
        onRename={onRename}
        onClickUpdateExcel={onClickUpdateExcel}
        downloadingId={downloadingId}
        dispatch={dispatch}
        highlightedGroupBudgetId={highlightedGroupBudgetId}
        onStartClientPortalFlow={onStartClientPortalFlow}
        defaults={defaults}
        currentUser={currentUser}
        handleShowStatusHistoryModal={handleShowStatusHistoryModal}
      ></TableInner>
    </TableStyle>
  );
};
