// @flow
/**
 *
 * MoneyValue
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import accounting from 'accounting';

const UpIcon = styled.i.attrs({
  className: 'fa fa-caret-up',
})`
  color: ${props => props.theme.colors.success};
  font-size: 26px;
  padding-right: 5px;
  position: relative;
  top: 3px;
`;

const DownIcon = styled.i.attrs({
  className: 'fa fa-caret-down',
})`
  color: ${props => props.theme.colors.danger};
  font-size: 26px;
  padding-right: 5px;
  position: relative;
  top: 3px;
`;

class MoneyValue extends React.Component {
  static defaultProps = {
    value: 0,
    symbol: '$',
    precision: 0,
    className: null,
    abbreviate: false,
    animate: false,
    diff: false,
  };
  render() {
    const value = accounting.formatMoney(
      this.props.value,
      this.props.symbol,
      this.props.precision
    );

    return (
      <span className={this.props.className}>
        {this.props.value >= 0 && this.props.diff && '+'}
        {this.props.colorDiff && (value >= 0 ? <UpIcon /> : <DownIcon />)}
        {value}
      </span>
    );
  }
}

export default MoneyValue;
