import React, { useCallback, useState, useRef, useMemo, memo } from 'react';

import useCompanyId from 'hooks/useCompanyId';
import useGroupId from 'hooks/useGroupId';
import useWorkspaceId from 'hooks/useWorkspaceId';

import HeaderButton from 'components/HeaderButton';
import KnowledgeButton from './KnowledgeButton';
import { Portal } from 'react-portal';
import RightSidebar from 'components/RightSidebar';
import ThemeToggle from 'components/ThemeToggle';
import TrainingButton from 'components/TrainingButton';
import WorkspaceHomeButton from 'components/WorkspaceHomeButton';

import { Container, Left, Right, Top, Bottom } from './styledComponents';
import { useLocation } from 'react-router-dom';
import Logo from 'components/Sidebar/Logo';
import CompanyDropdown from './CompanyDropdown';
import MaintenanceBanner from 'components/MaintenanceBanner';
import useMedia from 'hooks/useMedia';
import { sizes } from 'media';
import BetaBadge from 'components/BetaBadge';
import useMaintenanceMode from 'hooks/useMaintenanceMode';
import useWhitelabel from 'hooks/useWhitelabel';

interface HeaderProps {
  videoTours?: boolean;
  anonymous?: boolean;
  minimal?: boolean;
  sidebarExpanded: boolean;
  onMaintenanceBannerLoad: (displayBanner: boolean) => void;
  onMaintenanceBannerClose: () => void;
  hideHomeButton?: boolean;
  hideKnowledgeBaseButton?: boolean;
  hideVideoTutorialButton?: boolean;
}

const Header = React.forwardRef<HTMLDivElement, HeaderProps>(
  (
    {
      videoTours,
      anonymous,
      minimal = false,
      sidebarExpanded,
      onMaintenanceBannerLoad,
      onMaintenanceBannerClose,
      hideHomeButton,
      hideKnowledgeBaseButton,
      hideVideoTutorialButton,
    },
    ref
  ) => {
    const companyId = useCompanyId();
    const workspaceId = useWorkspaceId();
    const { whiteLabel } = useWhitelabel();
    const groupId = useGroupId();
    const location = useLocation();
    const tablet = useMedia([`(min-width: ${sizes.tablet}px)`], [false], true);
    const phone = useMedia([`(min-width: ${sizes.phone}px)`], [false], true);
    const rightSideBarButton = useRef<HTMLButtonElement>(null);
    const pathName = location.pathname
      .split('/')
      .slice(1, location.pathname.split('/').length);
    const clientPortal = pathName[0] === 'portal';
    const [rightSidebarOpen, setRightSidebarOpen] = useState<boolean>(false);
    const { downDate } = useMaintenanceMode();

    const closeSidebar = useCallback(() => setRightSidebarOpen(false), []);
    const toggleSidebar = useCallback(
      () => setRightSidebarOpen(!rightSidebarOpen),
      [rightSidebarOpen]
    );

    const hamburgerMenu = useMemo(
      () =>
        downDate ? (
          <BetaBadge
            text=" "
            style={{
              height: '15px',
              width: '15px',
              borderRadius: '50%',
              zIndex: 1,
            }}
            top={10}
            right={15}
          >
            <HeaderButton
              ref={rightSideBarButton}
              icon="mdi-set mdi-menu"
              onClick={toggleSidebar}
              minimal={minimal}
            />
          </BetaBadge>
        ) : (
          <HeaderButton
            ref={rightSideBarButton}
            icon="mdi-set mdi-menu"
            onClick={toggleSidebar}
            minimal={minimal}
          />
        ),
      [downDate, minimal, toggleSidebar]
    );

    return (
      <>
        <Container
          ref={ref}
          sidebarMargin={!clientPortal && !phone}
          minimal={minimal}
        >
          <Top>
            <Left>
              {(clientPortal || minimal || phone) && (
                <Logo
                  clientPortal={clientPortal}
                  minimal={minimal}
                  phone={phone}
                  style={{
                    width: phone ? '30px' : '200px',
                    marginLeft: '20px',
                    padding: whiteLabel ? '10px 0 10px 10px' : '0',
                  }}
                />
              )}
              {hideHomeButton ||
                (!anonymous && !!workspaceId && !phone && (
                  <WorkspaceHomeButton
                    style={{
                      marginLeft: '22.5px',
                      borderRadius: '5px',
                      height: '48.8px',
                      width: '48.8px',
                    }}
                  />
                ))}

              {!minimal && (
                <CompanyDropdown
                  workspaceId={workspaceId}
                  companyId={companyId}
                  groupId={groupId}
                  clientPortal={clientPortal}
                  sidebarExpanded={sidebarExpanded}
                />
              )}
            </Left>
            <Right>
              {!!videoTours && !hideKnowledgeBaseButton && (
                <KnowledgeButton minimal={minimal} />
              )}
              {!!videoTours && !hideVideoTutorialButton && !tablet && (
                <TrainingButton minimal={minimal} icon />
              )}
              {!anonymous && <ThemeToggle minimal={minimal} />}
              {!anonymous && hamburgerMenu}
            </Right>
          </Top>
          <Bottom>
            <MaintenanceBanner
              align={'none'}
              banner
              onLoad={onMaintenanceBannerLoad}
              onClose={onMaintenanceBannerClose}
            />
          </Bottom>
        </Container>
        {!anonymous && (
          <Portal>
            <RightSidebar
              open={rightSidebarOpen}
              trigger={rightSideBarButton}
              onClose={closeSidebar}
            />
          </Portal>
        )}
      </>
    );
  }
);

Header.displayName = 'Header';

export default memo(Header);
