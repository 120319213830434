/**
 *
 * Button
 *
 */

import styled, { keyframes } from 'styled-components';
import { fadeIn, bounce } from 'react-animations';
import { animationZIndex } from 'utils/zIndex';

const types = {
  noFadeInUp: keyframes`${{
    from: {
      transform: 'translate(0, 20px)',
    },
    to: {
      transform: 'translate(0, 1px)',
    },
  }}`.getName(),
  fadeInUp: keyframes`${{
    from: {
      opacity: 0,
      transform: 'translate(0, 50px)',
    },
    to: {
      opacity: 1,
      transform: 'none',
    },
  }}`.getName(),
  fadeIn,
  bounce,
};

const Animation = styled.div`
  animation: ${props => props.duration || '0.3s'}
    ${props => types[props.type || 'fadeInUp']} linear;
  transform-style: flat;
  z-index: ${animationZIndex};
  position: relative;
`;

export default Animation;
