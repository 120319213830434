// components/WhitelabelTitle.tsx

import React, { useEffect, useState, useContext } from 'react';
import useWhitelabel from 'hooks/useWhitelabel';
import { WhitelabelContext } from 'context/WhitelabelProvider';

const WhitelabelTitle: React.FC = () => {
  const { whiteLabel, loading, favicon, iphoneIcon } = useWhitelabel();
  const [faviconCreated, setFaviconCreated] = useState(false);
  const { setWhitelabelTitle } = useContext(WhitelabelContext);

  useEffect(() => {
    setWhitelabelTitle();
  }, [setWhitelabelTitle]);

  useEffect(() => {
    if (loading || faviconCreated) return;
    const link = document.createElement('link');

    link.rel = 'icon';
    link.type = 'image/png';
    if (whiteLabel) {
      link.href = favicon || '/file-chart-outline-32.png?v=1';
    } else {
      link.href = '/rr.png?v=LbyNWOx5Ab';
    }
    document.head.append(link);
    if (whiteLabel && iphoneIcon) {
      const iphoneLink = document.createElement('link');
      iphoneLink.rel = 'apple-touch-icon';
      iphoneLink.href = iphoneIcon;
      document.head.append(iphoneLink);
    }
    setFaviconCreated(true);
  }, [favicon, faviconCreated, iphoneIcon, loading, whiteLabel]);

  return null;
};

export default WhitelabelTitle;
