import Dropdown from 'components/Dropdown';
import DropdownButton from 'components/DropdownButton';
import { ManageLibraryDropdownButton } from 'modals/ManageLibrary';
import React from 'react';

const DATA_SHEETS_TYPE = 'Table';

const DropdownOption = ({ sheet, deleteButtonLabel, onDelete, onEditName }) => {
  return (
    <Dropdown
      width={200}
      align="left"
      portal
      offsetY={-120}
      offsetX={30}
      contentStyle={{ zIndex: 100000 }}
      trigger={
        <button>
          <i className="mdi-set mdi-cog" style={{ color: '#617182' }} />
        </button>
      }
    >
      {({ closeDropdown }) => (
        <>
          <DropdownButton
            onClick={() => {
              onEditName(sheet);
              closeDropdown();
            }}
          >
            Edit Name
          </DropdownButton>
          {sheet.__typename === DATA_SHEETS_TYPE && (
            <ManageLibraryDropdownButton
              itemType="TABLE"
              itemId={sheet.id}
              successMessage='Added to library. You can access your library by clicking "My Templates" in the Data Sheets menu.'
              zIndex={1002}
              itemName={sheet.name}
            />
          )}
          <DropdownButton
            onClick={() => onDelete(sheet.id)}
            style={{ color: '#FF0000' }}
          >
            {deleteButtonLabel}
          </DropdownButton>
        </>
      )}
    </Dropdown>
  );
};

export default DropdownOption;
