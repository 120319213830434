import React from 'react';

const ExcelFileIcon = ({
  color = 'white', //#617182
  width = '24px',
  height = '24px',
  cursor,
  onClick,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  cursor?: string;
  onClick?: (any) => void;
}) => (
  <svg width={width} height={height} viewBox="0 0 60.77 71.96">
    <path
      fill={color}
      d="M20.89,43.87a2.53,2.53,0,0,0-1-.6,4.07,4.07,0,0,0-1.37-.21H17.4V49h1.16a4.06,4.06,0,0,0,1.37-.22,2.64,2.64,0,0,0,1-.59,2.47,2.47,0,0,0,.57-.92,3.75,3.75,0,0,0,.2-1.24,3.71,3.71,0,0,0-.2-1.24A2.47,2.47,0,0,0,20.89,43.87Z"
    />
    <path
      fill={color}
      d="M10.53,2A6.55,6.55,0,0,0,4,8.53V60.74a6.55,6.55,0,0,0,6.53,6.52H49.68a6.55,6.55,0,0,0,6.53-6.52V21.58L36.63,2ZM23.67,48.16a4.63,4.63,0,0,1-1.08,1.67,5,5,0,0,1-1.71,1.08,6.28,6.28,0,0,1-2.23.38H15V40.76h3.64a6.14,6.14,0,0,1,2.23.39,4.64,4.64,0,0,1,2.79,2.76,6.13,6.13,0,0,1,0,4.25Zm11.47,0A5.4,5.4,0,0,1,34,49.92a5.34,5.34,0,0,1-1.73,1.17,5.87,5.87,0,0,1-4.36,0,5.5,5.5,0,0,1-1.74-1.17A5.38,5.38,0,0,1,25,48.17,5.44,5.44,0,0,1,27.89,41a5.77,5.77,0,0,1,4.36,0A5.32,5.32,0,0,1,34,42.13a5.51,5.51,0,0,1,1.16,1.75A5.71,5.71,0,0,1,35.55,46,5.62,5.62,0,0,1,35.14,48.17Zm10.51,1.42A5.4,5.4,0,0,1,43.81,51a5.51,5.51,0,0,1-2.36.49,5.61,5.61,0,0,1-2.18-.42,5.32,5.32,0,0,1-1.73-1.16,5.47,5.47,0,0,1-1.16-1.74,5.67,5.67,0,0,1,0-4.33,5.32,5.32,0,0,1,1.16-1.73,5.33,5.33,0,0,1,7.85.13l.16.18-1.69,1.64-.18-.2a3.32,3.32,0,0,0-1-.77,3.19,3.19,0,0,0-2.49,0,3,3,0,0,0-1,.64,2.91,2.91,0,0,0-.66,1A3.35,3.35,0,0,0,38.34,46a3.3,3.3,0,0,0,.25,1.32,3.05,3.05,0,0,0,.66,1,3,3,0,0,0,1,.63,3.25,3.25,0,0,0,2.6-.08A3.4,3.4,0,0,0,43.91,48l.17-.21,1.72,1.62ZM43.48,24.84H33.37V6.89l17.94,18Z"
    />
    <path
      fill={color}
      d="M32.27,43.71a3.09,3.09,0,0,0-5.06,1A3.52,3.52,0,0,0,27,46a3.43,3.43,0,0,0,.24,1.31,2.91,2.91,0,0,0,.66,1,3,3,0,0,0,1,.65,3.26,3.26,0,0,0,2.42,0,2.9,2.9,0,0,0,1.64-1.65A3.43,3.43,0,0,0,33.16,46a3.52,3.52,0,0,0-.24-1.32A3.14,3.14,0,0,0,32.27,43.71Z"
    />
  </svg>
);

export default ExcelFileIcon;
