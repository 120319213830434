import React from 'react';
import { StyledCheckbox as CheckboxBase } from './styledComponents';
import { FilterItem } from '.';

interface Props {
  indent?: boolean;
  item: FilterItem;
  selected: (string | number)[];
  partialSelected: (string | number)[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isConsolidation?: boolean;
  disabled?: boolean;
}

const Checkbox = ({
  indent,
  item,
  selected,
  partialSelected,
  onChange,
  isConsolidation = false,
  disabled,
}: Props): React.ReactElement => {
  return (
    <div>
      <CheckboxBase
        value={item.value || item.id}
        onChange={onChange}
        disabled={
          item.disabled ||
          partialSelected.includes(item.value || item.id) ||
          disabled
        }
        checked={
          selected?.some(
            // eslint-disable-next-line eqeqeq
            (value) => value === item.value || value === item.id
          ) ||
          (item.disabled && !isConsolidation) ||
          partialSelected.includes(item.value || item.id)
        }
        partial={partialSelected.includes(item.value || item.id)}
        indent={indent}
      >
        {item.label || item.name}
      </CheckboxBase>
    </div>
  );
};

export default React.memo(Checkbox);
