import logo from 'images/logo-white.png';

export default {
  id: 'lightThemeJ',
  name: 'theme-j',
  color: '#736cc7',
  colors: {
    primary: '#736cc7',
    secondary: '#788db4',
    accent: '#F64A91',
    success: '#2fbfa0',
    danger: '#ff5c75',
    warning: '#FFCE67',
    info: '#399AF2',
    link: '#337ab7',
    light: '#f0f6ff',
    dark: '#15182b',
    default: '#4E5E6A',
  },
  chartColors: [
    '#736cc7',
    '#F64A91',
    '#2fbfa0',
    '#ff5c75',
    '#FFCE67',
    '#399AF2',
  ],
  sidebar: {
    background: '#fff',
    logo,
    logoBackground: '#736cc7',
    color: '#718EB1',
    hoverColor: '#736cc7',
  },
  header: {
    background: '#736cc7',
    color: '#fff',
    logo,
  },
};
