/**
 *
 * VerticalTabs
 *
 */

import React, { useMemo } from 'react';
import Tab from './Tab';
import { List, Title } from './styledComponents';

function VerticalTabs(props) {
  const { tabs, current, onChange, className, title } = props;

  const tabComponents = useMemo(
    () =>
      tabs.map((tab, index) => (
        <Tab
          key={index}
          index={index}
          tab={tab}
          current={current}
          onChange={onChange}
        />
      )),
    [current, onChange, tabs]
  );

  return (
    <List className={className}>
      {!!title && <Title>{title}</Title>}
      {tabComponents}
    </List>
  );
}

export default VerticalTabs;
