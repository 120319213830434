import React, { ReactElement } from 'react';

const DashboardsIcon = ({
  color = '#f9fafc',
  width = '100%',
  height = '100%',
  cursor,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  cursor?: string;
  onClick?: (any) => void;
}): ReactElement => {
  return (
    <>
      <svg
        {...props}
        width={width}
        height={height}
        cursor={cursor}
        viewBox="0 0 41.58 43.1"
      >
        <path
          fill={color}
          d="M36.68,38.41h-7.4V26.92h11v7C40.33,36,38.7,38.41,36.68,38.41ZM29.28,2.15h7.4a3.68,3.68,0,0,1,3.65,3.72V25.28h-11ZM16.14,21.66H27.5V38.41H16.14ZM3.25,5.87c0-2.06,2.45-3.72,3.65-3.72H27.5V19.89H3.25ZM14.69,38.41H6.9c-1.2,0-3.65-2.44-3.65-4.49V21.66H14.69Z"
        />
      </svg>
    </>
  );
};

export default DashboardsIcon;
