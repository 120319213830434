import styled from 'styled-components';
import theme from 'styled-theming';

const bgColor = theme('mode', {
  light: '#dbdee2',
  dark: '#303644',
});

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${bgColor};
  border-radius: 6px;
  padding: 7px 9px;
  width: 200px;
  height: 156px;
  text-align: center;
  cursor: pointer;
  &:first {
    margin-right: 14px;
  }
  img {
    max-width: 182px;
    max-height: 100px;
    height: auto;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
`;
