import styled from 'styled-components';

export const Input = styled.input`
  background: ${(props) => props.theme.formControl.background};
  border-radius: ${(props) => props.theme.formControl.borderRadius};
  border: ${(props) => props.theme.formControl.border};
  box-shadow: ${(props) => props.theme.formControl.boxShadow};
  font-size: ${(props) => props.theme.formControl.fontSizeScaled(1)};
  padding: ${(props) => props.theme.formControl.paddingScaled(1)};
  transition: ${(props) => props.theme.formControl.transition};
  line-height: ${(props) => props.theme.formControl.lineHeight};
  color: ${(props) => props.theme.formControl.color};
  width: 400px;
  text-align: center;
  margin-right: 10px;
  &::-webkit-input-placeholder {
    color: ${(props) => props.theme.formControl.placeholder};
  }
  &:focus {
    border: ${(props) => props.theme.formControl.focus.border};
    background: ${(props) => props.theme.formControl.focus.background};
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;